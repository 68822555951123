import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { Link } from 'elements';
import { SupportedCountry, VISA_GCAS_PAGE_URL } from 'services/constants';
import { getPath } from 'services/utils';

const useCardBenefits = ({
  openInsuranceDetailsDialog,
}: {
  openInsuranceDetailsDialog: () => void;
}) => {
  const { t, i18n } = useTranslation();
  const {
    state: { organization, featureModules },
  } = useGlobalState();

  const commonBenefits = useMemo(
    () => [
      ...(featureModules.CASHBACK
        ? [
            {
              title: t('cardBenefitsSubpage.cashbacksTitle'),
              description: (
                <Trans
                  i18nKey="cardBenefitsSubpage.cashbacksDescription"
                  components={{
                    2: (
                      <RouterLink
                        to={generatePath(getPath('rewardsCashback'), {
                          orgId: organization!.id,
                        })}
                      />
                    ),
                  }}
                />
              ),
              isPackageActive: true,
            },
          ]
        : []),
      {
        title: t('cardBenefitsSubpage.hotelMerchantDiscountsTitle'),
        description: (
          <Trans
            i18nKey="cardBenefitsSubpage.hotelMerchantDiscountsDescription"
            components={{
              'partner-deals-link': (
                <RouterLink
                  to={generatePath(getPath('rewardsPartnerDeals'), {
                    orgId: organization!.id,
                  })}
                />
              ),
            }}
          />
        ),
        isPackageActive: true,
      },
      {
        title: t('cardBenefitsSubpage.emergencyTitle'),
        description: (
          <Trans
            i18nKey="cardBenefitsSubpage.emergencyDescription"
            components={{
              2: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href={VISA_GCAS_PAGE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        ),
        isPackageActive: true,
      },
    ],
    []
  );

  const premiumBenefits = useMemo(
    () => [
      {
        title: t('cardBenefitsSubpage.airportAccessTitle'),
        description: (
          <Trans
            i18nKey="cardBenefitsSubpage.airportAccessDescription"
            values={{ cardName: t('cardNames.PLIANT_BLACK') }}
            components={{
              'details-link': (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href={`https://www.loungekey.com/${i18n.language}/pliant`}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        ),
        isPackageActive: true,
      },
      {
        title: t('cardBenefitsSubpage.insurancePackageLabel'),
        description: (
          <Trans
            i18nKey="cardBenefitsSubpage.insurancePackageDescription_v2"
            components={{
              openDetails: (
                <Link component="button" onClick={openInsuranceDetailsDialog} />
              ),
            }}
          />
        ),
        isPackageActive: [SupportedCountry.de, SupportedCountry.at].includes(
          organization!.billingAddress?.country
        ),
      },
    ],
    [i18n.language, organization]
  );

  return { commonBenefits, premiumBenefits };
};

export default useCardBenefits;
