import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card, Project } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (card: Card) => void;
  card: Card;
  projects: Project[];
}

const EditCardProjectDialog = ({
  card,
  projects,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(card.projectId || '');

  const handleSubmit = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const data = await api.updateCardProject(
        card.cardId,
        value ? value : null
      );
      if (!mounted.current) return;
      onSuccess(data);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('editCardProjectDialog.title')}</DialogTitle>
      <DialogContent>
        <Box mb={4}>
          <Typography variant="body2">
            {t('editCardProjectDialog.description', card)}
          </Typography>
        </Box>
        <FormControl fullWidth disabled={isLoading}>
          <InputLabel id="card-project-select-label">
            {t('editCardProjectDialog.label')}
          </InputLabel>
          <Select<string>
            value={value}
            onChange={(e) => setValue(e.target.value)}
            renderValue={(selected) => {
              const project = projects.find((item) => item.id === selected);
              return project?.name;
            }}
            labelId="card-project-select-label"
          >
            <MenuItem value="">{t('editCardProjectDialog.none')}</MenuItem>
            {projects.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={isLoading} onClick={handleSubmit}>
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditCardProjectDialog);
