import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@mui/material';

export interface DialogProps extends Omit<MuiDialogProps, 'fullWidth'> {}

export const Dialog = (props: DialogProps) => <MuiDialog {...props} />;

export { DialogActions, DialogContent, DialogTitle } from '@mui/material';

export { withDialogWrapper } from './withDialogWrapper';
