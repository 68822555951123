import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';

export interface SelectProps<T = unknown>
  extends Omit<
    MuiSelectProps<T>,
    'variant' | 'label' | 'fullWidth' | 'notched'
  > {}

const Select = <T,>(props: SelectProps<T>) => <MuiSelect {...props} />;

export default Select;
