import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import AddPrivateCardDialog from 'domains/card/dialogs/AddPrivateCardDialog';
import { PrivatePaymentCard } from 'domains/member/components';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  TrashIcon,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import useUrls from 'hooks/useUrls';
import { PaymentMethod } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  paymentMethod: PaymentMethod | null;
  error: boolean;
  addCardDialogOpen: boolean;
  removeCardConfirmDialogOpen: boolean;
  isRemovingCard: boolean;
}

const PrivatePaymentCardSection = () => {
  const { t } = useTranslation();
  const {
    state: { member },
  } = useGlobalState();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const { HELP_CENTER_COMPANY_REIMBURSEMENT_URL } = useUrls();
  const [state, setState] = useState<State>({
    isLoading: true,
    paymentMethod: null,
    error: false,
    addCardDialogOpen: false,
    removeCardConfirmDialogOpen: false,
    isRemovingCard: false,
  });

  const getPaymentMethods = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await api.getPaymentMethods(member.id);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        paymentMethod: response.paymentMethods[0],
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        error: true,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const onAddCardSuccess = () => {
    setState((prevState) => ({
      ...prevState,
      addCardDialogOpen: false,
    }));

    getPaymentMethods();
  };

  const removePaymentMethod = async (paymentMethodId: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isRemovingCard: true,
      }));
      await api.removePaymentMethod(paymentMethodId);
      setState((prevState) => ({
        ...prevState,
        removeCardConfirmDialogOpen: false,
        isRemovingCard: false,
        paymentMethod: null,
      }));
      enqueueSnackbar(
        t('profilePage.privatePaymentCard.messages.cardRemovedSuccess'),
        {
          variant: 'success',
        }
      );
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isRemovingCard: false,
      }));
      enqueueSnackbar(
        t('profilePage.privatePaymentCard.messages.cardRemovedFailed'),
        { variant: 'error' }
      );
      logError(error);
    }
  };

  const renderContent = () => {
    if (state.isLoading) {
      return (
        <Box display="flex" alignItems="center">
          <CircularProgress size="small" />
        </Box>
      );
    }

    if (state.error) {
      return (
        <Typography variant="body1" color="error">
          {t('profilePage.privatePaymentCard.messages.cardFetchingFailed')}
        </Typography>
      );
    }

    if (state.paymentMethod) {
      return (
        <>
          <Typography variant="body1">
            {t('profilePage.privatePaymentCard.description')}
          </Typography>
          <Box maxWidth="50%" mt={3}>
            <PrivatePaymentCard
              showTooltip
              paymentMethod={state.paymentMethod}
              secondaryAction={
                <IconButton
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      removeCardConfirmDialogOpen: true,
                    }))
                  }
                >
                  <TrashIcon />
                </IconButton>
              }
            />
          </Box>
        </>
      );
    }

    return (
      <>
        <Typography variant="body1" mb={3}>
          <Trans
            i18nKey="profilePage.privatePaymentCard.noCardDescription"
            components={{
              a: (
                <Link
                  href={HELP_CENTER_COMPANY_REIMBURSEMENT_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </Typography>
        <Button
          onClick={() =>
            setState((prevState) => ({
              ...prevState,
              addCardDialogOpen: true,
            }))
          }
          size="large"
          variant="outlined"
        >
          {t('profilePage.privatePaymentCard.addCard')}
        </Button>
      </>
    );
  };

  return (
    <Box mb={8}>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography variant="h6" mr={1}>
          {t('profilePage.privatePaymentCard.title')}
        </Typography>
      </Box>

      {renderContent()}

      <AddPrivateCardDialog
        open={state.addCardDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            addCardDialogOpen: false,
          }))
        }
        onSuccess={onAddCardSuccess}
      />

      <ConfirmDialog
        title={t('profilePage.privatePaymentCard.removeCardDialog.title')}
        description={t(
          'profilePage.privatePaymentCard.removeCardDialog.description'
        )}
        loading={state.isRemovingCard}
        onSuccess={() => removePaymentMethod(state.paymentMethod!.id)}
        open={state.removeCardConfirmDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            removeCardConfirmDialogOpen: false,
          }))
        }
      />
    </Box>
  );
};

export default PrivatePaymentCardSection;
