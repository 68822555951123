import { useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { MemberDetailsMenu } from 'domains/member/components';
import { DotsThreeOutlineVerticalIcon } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MemberDetails } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  memberDetails: MemberDetails | null;
  isLoading: boolean;
}

interface Props {
  memberId: string;
  onUpdate: () => Promise<void>;
  onClose: () => void;
}

const MemberDetailsMenuWrapper = ({ memberId, onUpdate, onClose }: Props) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    memberDetails: null,
    isLoading: false,
  });

  const getMember = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const memberDetails = await api.getMember(organization!.id, memberId);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        memberDetails,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <MemberDetailsMenu
      isAsync
      isLoading={state.isLoading}
      member={state.memberDetails}
      getMember={() => getMember()}
      cards={[]}
      onCardIssue={() => {}}
      MenuIcon={DotsThreeOutlineVerticalIcon}
      onMenuItemClick={onClose}
      onUpdate={(member) => {
        setState((prevState) => ({
          ...prevState,
          memberDetails: member,
        }));
        onUpdate();
      }}
    />
  );
};

export default MemberDetailsMenuWrapper;
