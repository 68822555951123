import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Cropper, { Area, CropperProps, Point } from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import cropperBg from 'assets/images/cropper-bg.jpg';
import { Box, ImageIcon, Slider, Stack } from 'elements';
import useSnackbar from 'hooks/useSnackbar';
import { getImgBlob } from './utils';

const cropperStyles: CropperProps['style'] = {
  containerStyle: {
    height: '298px',
    width: '298px',
    borderRadius: '6px',
    position: 'relative',
    background: `url(${cropperBg}) repeat`,
  },
};

export interface LogoCropStepRef {
  getImgBlob: () => Promise<Blob | null>;
}

interface Props {
  file: File;
  onClose: () => void;
}

const LogoCropStep = (
  { file, onClose }: Props,
  ref: ForwardedRef<LogoCropStepRef>
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [readFile, setReadFile] = useState('');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setReadFile(e.target!.result as string);
      };
      reader.onerror = () => {
        enqueueSnackbar(t('merchantLogoEdit.imageProcessingError'), {
          variant: 'error',
        });
        onClose();
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useImperativeHandle(ref, () => ({
    getImgBlob() {
      return getImgBlob(readFile, croppedAreaPixels!, file.type);
    },
  }));

  return (
    <>
      <Box
        sx={{
          height: 298,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 3,
        }}
      >
        {readFile && (
          <Cropper
            showGrid={false}
            restrictPosition={false}
            style={cropperStyles}
            image={readFile}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={(crop) => setCrop(crop)}
            onCropComplete={onCropComplete}
            onZoomChange={(zoom) => setZoom(zoom)}
          />
        )}
      </Box>
      <Stack
        spacing={2}
        direction="row"
        sx={{ width: 298, mx: 'auto' }}
        alignItems="center"
      >
        <ImageIcon fontSize="small" />
        <Slider
          disabled={!readFile}
          min={1}
          max={3}
          step={0.1}
          value={zoom}
          onChange={(_, value) => setZoom(value as number)}
        />
        <ImageIcon />
      </Stack>
    </>
  );
};

export default forwardRef(LogoCropStep);
