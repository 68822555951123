import { ReactNode } from 'react';
import { Paper, SxProps, Theme, Typography } from '@mui/material';

const PaperLabeledValue = ({
  label,
  value,
  sx = [],
}: {
  label: string;
  value: ReactNode;
  sx?: SxProps<Theme>;
}) => (
  <Paper
    variant="outlined"
    sx={[
      {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        mb: 2,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <Typography variant="caption" component="div" color="text.secondary" noWrap>
      {label}
    </Typography>
    <Typography variant="body2" component="div" flexShrink={0} ml="auto">
      {value}
    </Typography>
  </Paper>
);

export default PaperLabeledValue;
