import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingAutocomplete } from 'domains/transaction/components';
import AutoMatchedSelectIndicator from 'domains/transaction/pages/TransactionDetailsPage/AutoMatchedSelectIndicator';
import { Box, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  CustomFieldOption,
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  UpdateAccTxnCustomFieldPayload,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { CustomFieldExtended } from './index';

const mapCustomFieldOptionToValue = (
  field: CustomFieldExtended
): CustomFieldOption | null => {
  return field.customFieldOptionId
    ? {
        id: field.customFieldOptionId,
        name: field.customFieldOptionName || '',
        transactionCustomFieldId: field.id,
        value: field.customFieldOptionValue || '',
        // The next information is not important and only dummy data is used
        source: 'PLIANT',
        status: AccountingItemStatus.active,
        externalReference: null,
        isDefault: false,
      }
    : null;
};

interface Props {
  isExportPage: boolean;
  field: CustomFieldExtended;
  disabled: boolean;
  organizationId: string;
  cardId: string;
  onUpdate: (
    data: UpdateAccTxnCustomFieldPayload,
    createNewField: boolean
  ) => void;
}

interface State {
  isLoading: boolean;
  customFieldOptions: CustomFieldOption[];
  isOpen: boolean;
  value: CustomFieldOption | null;
}

const CustomFieldSelect = ({
  isExportPage,
  field,
  disabled,
  cardId,
  organizationId,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const [state, setState] = useState<State>({
    customFieldOptions: [],
    isLoading: false,
    isOpen: false,
    value: mapCustomFieldOptionToValue(field),
  });
  const [inputValue, setInputValue] = useState(field.defaultValue);

  const getData = async () => {
    try {
      const { customFieldOptions } = await api.getCustomFieldOptions({
        transactionCustomFieldId: field.id,
        organizationId: field.organizationId,
        q: inputValue.trim(),
        status: AccountingItemStatus.active,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        customFieldOptions,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isOpen: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (!state.isOpen) return;
    let active = true;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);
    return () => {
      active = false;
    };
  }, [inputValue.trim(), state.isOpen]);

  useEffect(() => {
    if (field?.customFieldOptionName !== inputValue)
      setInputValue(field.customFieldOptionName || '');
    setState((prevState) => ({
      ...prevState,
      value: mapCustomFieldOptionToValue(field),
      customFieldOptions: [],
    }));
  }, [field]);

  const renderSelectedValue = () => {
    if (state.value)
      return (
        <Box overflow="hidden" pl={2}>
          <Typography variant="body2" component="div" noWrap>
            {state.value.name || ''}
          </Typography>
          {isExportPage && state.value.value && (
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              noWrap
            >
              {state.value.value}
            </Typography>
          )}
        </Box>
      );

    return '—';
  };

  return (
    <AccountingAutocomplete<CustomFieldOption, true>
      freeSolo
      label={
        <Box display="flex" alignItems="center">
          <Box overflow="hidden">
            <Typography variant="body2" noWrap>
              {field.label}
            </Typography>

            {isExportPage && (
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
              >
                {t('transactionDetails.valueLabel')}
              </Typography>
            )}
          </Box>

          <AutoMatchedSelectIndicator
            isAutoMatched={field.automatched}
            organizationId={organizationId}
            cardId={cardId}
          />
        </Box>
      }
      selectedValueComponent={renderSelectedValue()}
      disabled={disabled}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      value={state.value}
      options={state.customFieldOptions}
      open={state.isOpen}
      onOpen={() =>
        setState((prevState) => ({
          ...prevState,
          isOpen: true,
        }))
      }
      onClose={() =>
        setState((prevState) => ({
          ...prevState,
          isOpen: false,
        }))
      }
      onChange={(_, option) => {
        if (!option) setInputValue('');
        if (typeof option === 'string' || !option) return;
        onUpdate(
          {
            transactionCustomFieldId: field.id,
            value: '',
            customFieldOptionId: option?.id || null,
          },
          field.createNewField
        );
      }}
      onClear={() => {
        onUpdate(
          {
            transactionCustomFieldId: field.id,
            value: '',
            customFieldOptionId: null,
          },
          field.createNewField
        );
      }}
      loading={state.isLoading}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return '';
        return option.name || '';
      }}
      filterOptions={(x) => x}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Box overflow="hidden">
            <Typography noWrap>{option.name}</Typography>
            {isExportPage && option.value && (
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
              >
                {option.value}
              </Typography>
            )}
          </Box>
        </li>
      )}
    />
  );
};

export default CustomFieldSelect;
