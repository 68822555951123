import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import WidgetError from 'components/WidgetError';
import { useActiveTeams, useGlobalState } from 'context/GlobalState';
import {
  MemberDetailsCardsBlock,
  MemberDetailsContactBlock,
  MemberDetailsHeader,
  MemberDetailsMenu,
  MemberDetailsTransactionsBlock,
  TeamBadge,
} from 'domains/member/components';
import { Box, LoaderWithOverlay, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  Card,
  CardSimpleStatus,
  memberCardStatusesToRequest,
  MemberDetails,
  Transaction,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { filterAndSortMemberCards } from 'services/utils';

interface State {
  isLoading: boolean;
  member: MemberDetails | null;
  error: unknown;
  cards: Card[] | null;
  transactions: Transaction[] | null;
}

interface Props extends DetailsDrawerProps {
  teamId?: string;
  onUpdate: (member: MemberDetails, cards: Card[]) => void;
}

const MemberDetailsPage = ({ teamId, onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { memberId } = useParams<{ memberId: string }>();
  const {
    state: { isComplianceRiskWhiteLabelApp, organization, featureModules },
  } = useGlobalState();
  const teams = useActiveTeams();
  const managerTeam = useMemo(() => teams.find((item) => item.id === teamId), [
    teams,
    teamId,
  ]);
  const idRef = useRef(memberId);
  const [state, setState] = useState<State>({
    isLoading: true,
    member: null,
    error: null,
    cards: null,
    transactions: null,
  });

  useEffect(() => {
    if (state.member && state.cards) {
      const cards = state.cards.filter(
        (item) => item.simpleStatus !== CardSimpleStatus.requested
      );
      onUpdate(state.member, cards);
    }
  }, [state.member, state.cards]);

  const getData = async () => {
    try {
      setState((state) => ({
        ...state,
        isLoading: true,
      }));
      const member = await api.getMember(organization!.id, memberId);
      const [cards, transactions] = isComplianceRiskWhiteLabelApp
        ? [null, null]
        : await Promise.all([
            api
              .getCards({
                organizationId: organization!.id,
                teamId,
                memberId,
                status: memberCardStatusesToRequest.join(),
              })
              .then(
                (data) => filterAndSortMemberCards(data.cards),
                () => null
              ),
            api
              .getTransactions({
                organizationId: organization!.id,
                teamId,
                memberId,
                page: 0,
                limit: 3,
              })
              .then(
                (data) => data.transactions,
                () => null
              ),
          ]);
      if (!mounted.current || memberId !== idRef.current) return;
      setState((state) => ({
        ...state,
        member,
        cards,
        transactions,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || memberId !== idRef.current) return;
      setState((state) => ({
        ...state,
        error,
        member: null,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!memberId) return;
    idRef.current = memberId;
    getData();
  }, [memberId]);

  return (
    <DetailsDrawer
      {...props}
      actionsComponent={
        state.member && (
          <MemberDetailsMenu
            member={state.member}
            cards={state.cards}
            managerTeam={managerTeam}
            onCardIssue={getData}
            onUpdate={(member, cards) =>
              setState((prevState) => ({
                ...prevState,
                member,
                cards: cards || prevState.cards,
              }))
            }
          />
        )
      }
    >
      {state.member && (
        <>
          <DetailsDrawerHeader>
            <MemberDetailsHeader member={state.member} />
          </DetailsDrawerHeader>

          <DetailsDrawerContent>
            <MemberDetailsContactBlock member={state.member} />

            {!isComplianceRiskWhiteLabelApp && (
              <>
                {featureModules.TEAMS && (
                  <Box
                    p={3}
                    sx={(theme) => ({
                      borderTop: `1px solid ${theme.palette.divider}`,
                    })}
                  >
                    <Typography variant="overline" component="div" py={0.5}>
                      {t('memberDetailsPage.teams')}
                    </Typography>
                    {state.member.teams.length ? (
                      <Box lineHeight="32px">
                        {state.member.teams.map((item) => (
                          <TeamBadge
                            key={item.teamId}
                            name={item.name}
                            isManager={item.teamManager}
                            sx={{ verticalAlign: 'top' }}
                          />
                        ))}
                      </Box>
                    ) : (
                      <Typography color="text.secondary" my={1}>
                        {t('memberDetailsPage.noTeams')}
                      </Typography>
                    )}
                  </Box>
                )}

                <MemberDetailsCardsBlock
                  member={state.member}
                  cards={state.cards}
                  managerTeam={managerTeam}
                  onCardIssue={getData}
                  onRetry={getData}
                />

                <MemberDetailsTransactionsBlock
                  member={state.member}
                  transactions={state.transactions}
                  teamId={teamId}
                  onRetry={getData}
                />
              </>
            )}
          </DetailsDrawerContent>
        </>
      )}

      {state.error && <WidgetError onReload={getData} />}
      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(MemberDetailsPage);
