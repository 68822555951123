import { useTranslation } from 'react-i18next';
import HubspotLogo from 'assets/svg/hubspotLogo.svg?react';
import { useGlobalState } from 'context/GlobalState';
import { Box, IconButton, LinkBreakIcon, Tooltip } from 'elements';

const HubspotCompanyLink = () => {
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();

  const hasHubspotId =
    !!organization!.hubspotId && organization!.hubspotId !== '00000000';

  return hasHubspotId ? (
    <IconButton
      href={`https://app.hubspot.com/contacts/19883247/company/${
        organization!.hubspotId
      }`}
      rel="noopener noreferrer"
      size="small"
      target="_blank"
    >
      <HubspotLogo />
    </IconButton>
  ) : (
    <Tooltip title={t('int.hubspotCompanyLink.idMissing')}>
      <Box display="flex" alignItems="center">
        <LinkBreakIcon sx={{ color: 'white', opacity: 0.38 }} />
      </Box>
    </Tooltip>
  );
};

export default HubspotCompanyLink;
