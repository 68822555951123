import React, { useRef } from 'react';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CaretDownIcon,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  XIcon,
} from 'elements';
import { AutocompleteInfo, AutocompleteWrapper } from './style';

interface Props<T, FreeSolo extends boolean | undefined>
  extends AutocompleteProps<T, false, false, FreeSolo> {
  label: React.ReactNode;
  selectedValueComponent: React.ReactNode;
  disabled?: boolean;
  defaultShownValue?: React.ReactNode;
  onClear?: () => void;
}

const AccountingAutocomplete = <
  T,
  FreeSolo extends boolean | undefined = undefined
>({
  label,
  selectedValueComponent,
  defaultShownValue,
  disabled,
  onClear,
  ...props
}: Props<T, FreeSolo>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line jsx-a11y/aria-role
    <AutocompleteWrapper role="autocomplete">
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: '70%',
          left: (theme) => theme.spacing(1.5),
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box overflow="hidden">{label}</Box>
      </Box>

      {disabled && !props.value ? (
        <Paper
          sx={{
            px: 1.5,
            py: 2,
          }}
          variant="outlined"
        >
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Typography
              variant="body2"
              sx={{
                pl: 2,
              }}
            >
              {defaultShownValue || '—'}
            </Typography>
          </Box>
        </Paper>
      ) : (
        <Box position="relative" width="100%">
          <Autocomplete<T, false, false, FreeSolo>
            renderInput={(params) => {
              return (
                <TextField
                  inputRef={inputRef}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    ...(disabled
                      ? { endAdornment: undefined }
                      : {
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{
                                maxHeight: 'none',
                                height: 'auto',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                right: (theme) => theme.spacing(1.5),
                              }}
                            >
                              {onClear && props.value ? (
                                <IconButton onClick={onClear} size="small">
                                  <XIcon />
                                </IconButton>
                              ) : (
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    if (inputRef.current)
                                      inputRef.current.focus();
                                  }}
                                >
                                  <CaretDownIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }),
                  }}
                  inputProps={{
                    ...params.inputProps,
                    sx: {
                      textAlign: 'right',
                      maxWidth: (theme: Theme) =>
                        `calc(70% - ${theme.spacing(2)})`,
                      marginLeft: 'auto',
                    },
                  }}
                  placeholder="—"
                  size="medium"
                />
              );
            }}
            searchIcon={false}
            openOnFocus
            disabled={disabled}
            noOptionsText={t('common.nothingFound')}
            {...props}
          />
          <AutocompleteInfo
            pr={disabled ? 1.5 : 7.5}
            className="autocomplete-value"
          >
            {selectedValueComponent}
          </AutocompleteInfo>
        </Box>
      )}
    </AutocompleteWrapper>
  );
};

export default AccountingAutocomplete;
