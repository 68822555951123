import { useTranslation } from 'react-i18next';
import { StatusBadge } from 'elements';
import { CardControlRestriction } from 'services/constants';

interface Props {
  restriction: CardControlRestriction;
}

const RestrictionBadge = ({ restriction }: Props) => {
  const { t } = useTranslation();

  return (
    <StatusBadge
      label={t(`cardControlIndicator.restrictions.${restriction}`)}
      variant="filled"
      color={
        restriction === CardControlRestriction.allowed ? 'success' : 'error'
      }
    />
  );
};

export default RestrictionBadge;
