import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MemberDetails, NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  validateEmail,
} from 'services/utils';

type ChangeEmailDialogForm = {
  email: string;
};

interface Props extends DialogProps {
  onClose: () => void;
  member: MemberDetails;
  onSuccess: (member: MemberDetails) => void;
}

const ChangeEmailDialog = ({ member, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik<ChangeEmailDialogForm>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: member.email || '',
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const email = values.email.trim();
        await api.changeMemberEmail(member.id, {
          email,
          organizationId: member.organizationId,
        });
        if (!mounted.current) return;
        onSuccess({ ...member, email });
        enqueueSnackbar(
          t('changeEmailDialog.successMessage', {
            fullName: `${member.firstName} ${member.lastName}`,
            email,
          }),
          { autoHideDuration: 10000 }
        );
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (getNetworkErrorCode(error) === NetworkErrorCode.userAlreadyExists) {
          setErrors({ email: t('changeEmailDialog.userExistsError') });
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    },
  });

  const { invalidEmailError, emailTooLongError } = validateEmail(
    formik.values.email
  );

  const isSubmitDisabled =
    !formik.values.email.trim() ||
    formik.values.email === formik.initialValues.email ||
    !!invalidEmailError ||
    !!emailTooLongError ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('changeEmailDialog.heading')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="change-email-form" noValidate>
          <Typography variant="body2" mb={4}>
            {t('changeEmailDialog.subheading')}
          </Typography>
          <TextField
            {...formik.getFieldProps('email')}
            label={t('changeEmailDialog.email')}
            disabled={formik.isSubmitting}
            error={!!invalidEmailError || !!emailTooLongError}
            helperText={invalidEmailError || emailTooLongError}
            autoFocus
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="change-email-form"
          type="submit"
        >
          {t('common.button.update')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(ChangeEmailDialog);
