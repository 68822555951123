import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useVisibleCardAccounts } from 'context/GlobalState';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import { Box, Paper, Typography } from 'elements';

interface Props {
  cardAccountId: string;
}

const Account = ({ cardAccountId }: Props) => {
  const { t } = useTranslation();
  const visibleCardAccounts = useVisibleCardAccounts();
  const getCardAccountName = useCardAccountNameGetter();

  if (visibleCardAccounts.length < 2) return null;

  return (
    <Paper
      sx={{
        px: 1.5,
        py: 2,
        mt: 1,
      }}
      variant="outlined"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">
          {t('transactionDetails.account')}
        </Typography>

        <Box pl={2} overflow="hidden">
          <Typography variant="body2" noWrap>
            {getCardAccountName(cardAccountId)}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default memo(Account);
