import { SxProps, Theme } from '@mui/material';
import { useCardDesign } from 'context/GlobalState';
import { Box } from 'elements';

interface Props {
  cardDesignId: string;
  sx?: SxProps<Theme>;
}

const CardIcon = ({ cardDesignId, sx = [] }: Props) => {
  const cardDesign = useCardDesign(cardDesignId);

  return (
    <Box
      sx={[
        {
          flexShrink: 0,
          display: 'inline-flex',
          verticalAlign: 'top',
          position: 'relative',
          width: 26,
          height: 40,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: 36,
          height: 40,
          backgroundImage: `url("${cardDesign.smallImage}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      />
    </Box>
  );
};

export default CardIcon;
