import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { adminPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from 'elements';
import { ApiIntegrationStatus, ExportFormat } from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  isExported: boolean;
  format: ExportFormat;
  onSave: (format: ExportFormat) => void;
}

const DatevExportFormats = ({ isExported, format, onSave }: Props) => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings, generalInfo },
  } = useGlobalState();
  const canUser = useCanUser();

  const isDatevApiConnected =
    accountingSettings!.apiIntegrationStatus === ApiIntegrationStatus.connected;

  // use datev-api option for export queue tab
  if (
    !isExported &&
    accountingSettings?.exportFormats.includes(ExportFormat.api) &&
    canUser('transactions-datev-api:export')
  ) {
    return (
      <FormControl
        fullWidth
        error={format === ExportFormat.api && !isDatevApiConnected}
      >
        <InputLabel>{t('exportTransactionsDialog.exportFormat')}</InputLabel>
        <Select
          name="format"
          value={format}
          onChange={(e) => onSave(e.target.value as ExportFormat)}
        >
          <MenuItem value={ExportFormat.api} disabled={!isDatevApiConnected}>
            {t('exportTransactionsDialog.datevApiOptionLabel')}
          </MenuItem>
          <MenuItem value={ExportFormat.csv}>
            {t('exportTransactionsDialog.csvOptionLabelAlt')}
          </MenuItem>
          <MenuItem value={ExportFormat.xml}>
            {t('exportTransactionsDialog.xmlOptionLabelAlt')}
          </MenuItem>
        </Select>
        {format === ExportFormat.api && (
          <FormHelperText>
            {isDatevApiConnected ? (
              t('exportTransactionsDialog.datevApiTooltipTitle')
            ) : (
              <Trans
                i18nKey="exportTransactionsDialog.datevApiErrorTooltipTitle"
                components={{
                  linkTo: (
                    <Link
                      style={{ color: 'inherit' }}
                      to={adminPaths.settingsDatev}
                    />
                  ),
                  sup: (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      style={{ color: 'inherit' }}
                      href={'mailto:' + generalInfo.supportEmail}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                }}
              />
            )}
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  // Exported page for datev should look the same way as before
  if (
    accountingSettings!.exportFormats.length > 1 ||
    accountingSettings!.exportFormats.includes(ExportFormat.api)
  ) {
    return (
      <FormControl fullWidth>
        <InputLabel>{t('exportTransactionsDialog.exportFormat')}</InputLabel>
        <Select
          name="format"
          value={format}
          onChange={(e) => onSave(e.target.value as ExportFormat)}
        >
          <MenuItem value={ExportFormat.csv}>
            {t('exportTransactionsDialog.csvOptionLabel')}
          </MenuItem>
          <MenuItem value={ExportFormat.xml}>
            {t('exportTransactionsDialog.xmlOptionLabel')}
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  // this is default case, when accountingSettings!.exportFormats.length === 1
  return (
    <Table sx={{ marginTop: -2 }}>
      <TableBody>
        <TableRow>
          <TableCell sx={{ width: '48%' }}>
            <Typography variant="subtitle2">
              {t('exportTransactionsDialog.exportFormat')}
            </Typography>
          </TableCell>
          <TableCell>
            {format === ExportFormat.csv
              ? t('exportTransactionsDialog.datevCsv')
              : t('exportTransactionsDialog.datevXml')}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DatevExportFormats;
