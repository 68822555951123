import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isAxiosError } from 'axios';
import { camelCase, startCase } from 'lodash';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  DownloadSimpleIcon,
  FormControlLabel,
  Grid,
  IconButton,
  LoaderWithOverlay,
  QuestionIcon,
  Tooltip,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountGroup,
  BankingServicePartner,
  BankSubmission,
  BankSubmissionRawData,
  BankSubmissionStatus,
  NetworkErrorCode,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getMoneyObject,
  getNetworkErrorCode,
} from 'services/utils';
import ComplianceScreeningItem from './ComplianceScreeningItem';
import LabelValueItem from './LabelValueItem';
import RepresentativeSection from './RepresentativeSection';

interface Props extends DialogProps {
  onClose: () => void;
  submission: BankSubmission;
}

interface State {
  data: BankSubmissionRawData | null;
  fileUploadConfirmed: boolean;
  filesAreInFolderManualCheck: boolean;
  isLoading: boolean;
  isSubmissionConfirmDialogOpen: boolean;
  submission: BankSubmission;
  version: number | null;
}

const VARENGOLD_SUBMISSION_SUPPORTED_VERSION = 0;

const ReviewDialog = ({ submission, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, defaultCardAccount },
  } = useGlobalState();
  const { user } = useAuth0();
  const {
    actions: { fetchBankSubmissions, refetchComplianceCompany },
  } = useCreditAndComplianceContext();
  const { varengoldBankSubmissionDisabled } = useFlags();

  const isVarengoldBankSubmissionDisabled =
    [AccountGroup.vgPliantOy, AccountGroup.vgTpml].includes(
      defaultCardAccount!.accountGroup.value
    ) && varengoldBankSubmissionDisabled;

  const [state, setState] = useState<State>({
    data: null,
    fileUploadConfirmed: false,
    filesAreInFolderManualCheck: false,
    isLoading: true,
    isSubmissionConfirmDialogOpen: false,
    submission,
    version: null,
  });

  useEffect(() => {
    (async () => {
      try {
        const { version, rawData } = await api.getBankSubmissionRawData(
          organization!.id,
          state.submission.id
        );
        const data: BankSubmissionRawData = JSON.parse(rawData);

        setState((prevState) => ({
          ...prevState,
          data,
          isLoading: false,
          version,
        }));
      } catch (error) {
        if (!mounted.current) return;
        if (getNetworkErrorCode(error) === NetworkErrorCode.invalidLegalForm) {
          enqueueSnackbar(
            t('int.bankSubmissionReviewDialog.invalidLegalFormError', {
              name: organization!.name,
            }),
            {
              variant: 'error',
              persist: true,
            }
          );
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }

        setState((prevState) => ({ ...prevState, isLoading: false }));
        props.onClose();
      }
    })();
  }, [mounted.current]);

  const onSubmit = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      if (state.submission.status === BankSubmissionStatus.draft) {
        await api.createBankSubmissionRequest(
          organization!.id,
          state.submission.id
        );
      } else {
        await api.approveBankSubmissionRequest(
          organization!.id,
          state.submission.id,
          {
            requiredFilesUploaded: state.fileUploadConfirmed,
          }
        );

        if (state.submission.canBeSubmitted) {
          /* next monitoring date was set in BE */
          await refetchComplianceCompany();

          /* org onboarding status is updated after successful submission */
          const updatedOrg = await api.getOrganization(organization!.id);

          dispatch({
            type: 'SET_ORGANIZATION_DATA',
            payload: {
              organization: updatedOrg,
            },
          });
        }
      }

      await fetchBankSubmissions();
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      if (
        getNetworkErrorCode(error) ===
          NetworkErrorCode.varengoldValidationError &&
        isAxiosError(error) &&
        error.response?.data?.message
      ) {
        enqueueSnackbar(error.response.data.message, {
          variant: 'error',
          persist: true,
        });
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
      setState((prevState) => ({ ...prevState, isLoading: false }));
      props.onClose();
    }
  };

  const onReject = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      await api.rejectBankSubmission(organization!.id, state.submission.id);

      await fetchBankSubmissions();
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      props.onClose();
      logError(error);
    }
  };

  const downloadAsJson = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(state.data, null, 2)
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;

    link.download =
      [
        moment(
          state.submission.secondReviewAt
            ? state.submission.secondReviewAt
            : new Date()
        ).format('YYYYMMDD'),
        startCase(camelCase(state.data!.company.name)).replace(/ /g, ''),
        'JSON',
      ].join('_') + '.json';

    link.click();
  };

  const canUserApprove = state.submission.approvalRequestedBy !== user!.email;

  if (state.isSubmissionConfirmDialogOpen) {
    return (
      <ConfirmDialog
        {...props}
        loading={state.isLoading}
        title={t('int.bankSubmissionReviewDialog.submissionConfirmDialogTitle')}
        description={
          <Trans
            i18nKey="int.bankSubmissionReviewDialog.submissionConfirmDialogDescription"
            components={{
              b: <b />,
            }}
            values={{
              orgName: organization!.name,
              bank: t(
                `int.bankSubmissionReviewDialog.bankSubmissionType.${submission.type}`
              ),
            }}
          />
        }
        onSuccess={onSubmit}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isSubmissionConfirmDialogOpen: false,
          }))
        }
      />
    );
  }

  return (
    <Dialog {...props} maxWidth="lg">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <span>
            {t(
              `int.bankSubmissionReviewDialog.titleByState.${state.submission.status}`
            )}
          </span>

          <IconButton aria-label="download" onClick={downloadAsJson}>
            <DownloadSimpleIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {state.isLoading && !state.data && <Box minHeight={600} />}

        {state.data &&
          state.version !== VARENGOLD_SUBMISSION_SUPPORTED_VERSION && (
            <pre>{JSON.stringify(state.data, null, 2)}</pre>
          )}

        {state.data &&
          state.version === VARENGOLD_SUBMISSION_SUPPORTED_VERSION && (
            <>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography>
                  {t('int.bankSubmissionReviewDialog.organizationInformation')}
                </Typography>
                <Typography variant="caption" color="warning.main">
                  {t('int.bankSubmissionReviewDialog.secondaryDataAnnotation')}
                </Typography>
              </Box>

              <Grid container spacing={1}>
                <Grid container item direction="column" xs={6} spacing={1}>
                  <Grid item>
                    <Typography variant="body2" mb={0.5}>
                      {t('int.bankSubmissionReviewDialog.companyDetails')}
                    </Typography>
                  </Grid>
                  <LabelValueItem
                    label={t('int.bankSubmissionReviewDialog.companyNameLabel')}
                    value={state.data.company.name}
                  />
                  <LabelValueItem
                    label={t('int.bankSubmissionReviewDialog.legalFormLabel')}
                    value={
                      <Box display="flex">
                        <span>{state.data.company.legalForm}&nbsp;</span>
                        <Typography color="warning.main" variant="caption">{`(${
                          organization!.legalForm
                        }*)`}</Typography>
                      </Box>
                    }
                  />
                  <LabelValueItem
                    label={t(
                      'int.bankSubmissionReviewDialog.billingAddressLabel'
                    )}
                    value={
                      <>
                        <div>
                          {state.data.company.street}&nbsp;
                          {state.data.company.streetNumber}
                        </div>
                        <div>
                          {state.data.company.postalCode}&nbsp;
                          {state.data.company.city}
                        </div>
                        <div>{state.data.company.country}</div>
                      </>
                    }
                  />
                </Grid>

                <Grid container item direction="column" xs={6} spacing={1}>
                  <Grid item>
                    <Typography variant="body2" mb={0.5}>
                      {t(
                        'int.bankSubmissionReviewDialog.companyRegistrationData'
                      )}
                    </Typography>
                  </Grid>
                  <LabelValueItem
                    label={t(
                      'int.bankSubmissionReviewDialog.foundingDateLabel'
                    )}
                    value={
                      state.data.company.dateFounding &&
                      moment(state.data.company.dateFounding).format(
                        'DD.MM.YYYY'
                      )
                    }
                  />
                  <LabelValueItem
                    label={t(
                      'int.bankSubmissionReviewDialog.commercialRegisterIdLabel'
                    )}
                    value={state.data.company.nationalRegisterId}
                  />
                  <LabelValueItem
                    label={t('int.bankSubmissionReviewDialog.localCourt')}
                    value={state.data.company.nationalRegisterCourt}
                  />
                  <LabelValueItem
                    label={t(
                      'int.bankSubmissionReviewDialog.dateOfRegisterExcerpt'
                    )}
                    value={
                      state.data.company.nationalRegisterDate &&
                      moment(state.data.company.nationalRegisterDate).format(
                        'DD.MM.YYYY'
                      )
                    }
                  />
                  <LabelValueItem
                    label={t('int.bankSubmissionReviewDialog.vatIdLabel')}
                    value={state.data.company.vat}
                  />
                  <LabelValueItem
                    label={t('int.bankSubmissionReviewDialog.industryCode')}
                    value={state.data.company.economicSector}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ marginY: 2 }} />
                </Grid>

                <Grid container item direction="column" xs={6} spacing={1}>
                  <Grid item>
                    <Typography variant="body2" mb={0.5}>
                      {t('int.bankSubmissionReviewDialog.riskData')}
                    </Typography>
                  </Grid>
                  <LabelValueItem
                    label={t(
                      'int.bankSubmissionReviewDialog.dateOfEnterpriseSizeLabel'
                    )}
                    value={
                      state.data.company.enterpriseSize.dateEnterpriseSize &&
                      moment(
                        state.data.company.enterpriseSize.dateEnterpriseSize
                      ).format('DD.MM.YYYY')
                    }
                  />
                  <LabelValueItem
                    label={t(
                      'int.bankSubmissionReviewDialog.numberEmployeesLabel'
                    )}
                    value={state.data.company.enterpriseSize.numberEmployees}
                  />
                  <LabelValueItem
                    label={t(
                      'int.bankSubmissionReviewDialog.annualTurnoverLabel'
                    )}
                    value={
                      <FormatMoney
                        value={getMoneyObject(
                          state.data.company.enterpriseSize.annualTurnover * 100
                        )}
                      />
                    }
                  />
                  <LabelValueItem
                    label={t(
                      'int.bankSubmissionReviewDialog.balanceSheetTotalLabel'
                    )}
                    value={
                      <FormatMoney
                        value={getMoneyObject(
                          state.data.company.enterpriseSize.balanceTotal * 100
                        )}
                      />
                    }
                  />
                  <LabelValueItem
                    label={t(
                      'int.bankSubmissionReviewDialog.enterpriseSizeLabel'
                    )}
                    value={state.data.company.enterpriseSize.size}
                  />
                </Grid>
                <Grid container item direction="column" xs={6} spacing={1}>
                  <Grid item>
                    <Typography variant="body2" mb={0.5}>
                      {t('int.bankSubmissionReviewDialog.riskSettings')}
                    </Typography>
                  </Grid>
                  <LabelValueItem
                    label={t('int.bankSubmissionReviewDialog.creditLimitLabel')}
                    value={
                      <FormatMoney
                        value={getMoneyObject(state.data.accountLimit * 100)}
                      />
                    }
                  />
                  <LabelValueItem
                    label={t(
                      'int.bankSubmissionReviewDialog.creditRatingLabel'
                    )}
                    value={state.data.creditRating}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ marginY: 2 }} />
                </Grid>

                <Grid container item direction="column" xs={12} spacing={1}>
                  <Grid item>
                    <Typography variant="body2" mb={0.5}>
                      {t(
                        'int.bankSubmissionReviewDialog.organizationComplianceChecks'
                      )}
                    </Typography>
                  </Grid>
                  <ComplianceScreeningItem
                    amlRiskScoringDate={
                      state.data.company.kyc.amlRiskScoring.checkDate
                    }
                    amlRiskScoringResult={
                      state.data.company.kyc.amlRiskScoring.result
                    }
                    sanctionListCheckDate={
                      state.data.company.kyc.sanctionListCheck.checkDate
                    }
                    sanctionListCheckResult={
                      state.data.company.kyc.sanctionListCheck.result
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ marginY: 2 }} />
                </Grid>

                <Grid item xs={12}>
                  <Typography mb={0.5}>
                    {t('int.bankSubmissionReviewDialog.legalRepresentatives')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {state.data!.representatives.map((representative, index) => (
                    <div key={index}>
                      <RepresentativeSection data={representative} />
                      <Divider light sx={{ marginY: 2 }} />
                    </div>
                  ))}
                </Grid>

                <Grid item xs={12}>
                  <Typography mb={0.5}>
                    {t('int.bankSubmissionReviewDialog.ubos')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {state.data!.beneficialOwners.map((representative, index) => (
                    <div key={index}>
                      <RepresentativeSection data={representative} />
                      <Divider light sx={{ marginY: 2 }} />
                    </div>
                  ))}
                </Grid>
              </Grid>
            </>
          )}

        {state.data &&
          state.submission.isReadyForReview &&
          state.submission.status === BankSubmissionStatus.draft && (
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={<Checkbox />}
                label={t(
                  'int.bankSubmissionReviewDialog.filesAreInFolderManualCheck'
                )}
                onChange={(_, checked) =>
                  setState((prevState) => ({
                    ...prevState,
                    filesAreInFolderManualCheck: checked,
                  }))
                }
              />
              <Tooltip
                title={
                  <Trans
                    i18nKey="int.bankSubmissionReviewDialog.filesAreInFolderManualCheckTooltip"
                    components={{
                      b: <b />,
                      br: <br />,
                      li: <li />,
                      ul: <ul />,
                    }}
                  />
                }
              >
                <QuestionIcon />
              </Tooltip>
            </Box>
          )}

        {state.data &&
          canUserApprove &&
          state.submission.status === BankSubmissionStatus.pendingApproval && (
            <FormControlLabel
              control={<Checkbox />}
              label={t(
                `int.bankSubmissionReviewDialog.${
                  state.submission.type === BankingServicePartner.varengold
                    ? 'fileUploadToVgConfirmed'
                    : 'filesAreInFolderManualCheck'
                }`
              )}
              onChange={(_, checked) =>
                setState((prevState) => ({
                  ...prevState,
                  fileUploadConfirmed: checked,
                }))
              }
            />
          )}

        {isVarengoldBankSubmissionDisabled && (
          <Box display="flex" justifyContent="flex-end" mr={1}>
            <Typography color="error.main">
              {t(
                'int.bankSubmissionReviewDialog.varengoldBankSubmissionDisabled'
              )}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        {state.submission.status === BankSubmissionStatus.pendingApproval && (
          <Button disabled={state.isLoading} color="error" onClick={onReject}>
            {t('int.bankSubmissionReviewDialog.rejectButton')}
          </Button>
        )}
        {canUserApprove &&
          [
            BankSubmissionStatus.draft,
            BankSubmissionStatus.pendingApproval,
          ].includes(state.submission.status) && (
            <Button
              disabled={
                isVarengoldBankSubmissionDisabled ||
                state.isLoading ||
                (state.submission.status ===
                  BankSubmissionStatus.pendingApproval &&
                  !state.fileUploadConfirmed) ||
                (state.submission.status === BankSubmissionStatus.draft &&
                  !state.filesAreInFolderManualCheck)
              }
              onClick={() =>
                state.submission.status === BankSubmissionStatus.draft
                  ? onSubmit()
                  : setState((prevState) => ({
                      ...prevState,
                      isSubmissionConfirmDialogOpen: true,
                    }))
              }
            >
              {state.submission.status ===
                BankSubmissionStatus.pendingApproval &&
              state.submission.canBeSubmitted
                ? t('int.bankSubmissionReviewDialog.approveAndSubmitButton')
                : t('int.bankSubmissionReviewDialog.approveButton')}
            </Button>
          )}
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(ReviewDialog);
