import { OrganizationRepresentativeInternalStatus } from 'services/constants';
import { StatusBadge } from './StatusBadge';

export const OrganizationRepresentativeInternalStatusBadge = ({
  status,
}: {
  status: OrganizationRepresentativeInternalStatus;
}) => {
  const label = status.toLowerCase();

  switch (status) {
    case OrganizationRepresentativeInternalStatus.archived:
      return <StatusBadge type="secondary">{label}</StatusBadge>;
    case OrganizationRepresentativeInternalStatus.draft:
      return <StatusBadge type="dark">{label}</StatusBadge>;
    case OrganizationRepresentativeInternalStatus.ready:
      return <StatusBadge type="success">{label}</StatusBadge>;
    default:
      return null;
  }
};
