import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';
import { generatePath } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { cardholderPaths } from 'components/App';
import WidgetError from 'components/WidgetError';
import {
  useActiveTeams,
  useCardholderAppLink,
  useGlobalState,
  useVisibleCardAccounts,
} from 'context/GlobalState';
import {
  CardCategoryControlIndicator,
  CardCustomFields,
  CardCustomValidityPeriod,
  CardDateControlIndicator,
  CardDetailsMenu,
  CardHistory,
  CardInReplacementBox,
  CardLimit,
  CardLoadBasedBalance,
  CardLocationControlIndicator,
  CardMerchantControlIndicator,
  CardRequestDetails,
  CardStatusBadge,
  CardTile,
  CardTimeControlIndicator,
  CardTransactionLimit,
  COLLAPSED_CARD_HISTORY_HEIGHT,
  LimitChangeRequestDetails,
} from 'domains/card/components';
import { ChangeCardLimitsDialog } from 'domains/card/dialogs';
import {
  useAvailableVirtualTravelCardSubtypes,
  useCardAccountNameGetter,
} from 'domains/card/hooks';
import { getCardNameWithRefNum } from 'domains/card/utils';
import { IntegrationTypeIcon } from 'domains/partner/components';
import { LastTransactionsList } from 'domains/transaction/components';
import {
  Alert,
  ArrowsClockwiseIcon,
  BankIcon,
  Box,
  Button,
  Chip,
  Link,
  LoaderWithOverlay,
  Paper,
  PaperLabeledValue,
  ProjectorScreenIcon,
  Tooltip,
  Typography,
  UsersIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  Card,
  CardFundingType,
  CardSimpleStatus,
  CardType,
  CirculaConnectionType,
  IntegrationType,
  Transaction,
  TransactionStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';
import CardHint from './CardHint';

interface State {
  isLoading: boolean;
  card: Card | null;
  error: unknown;
  transactions: Transaction[] | null;
  isChangeCardLimitsDialogOpen: boolean;
  isLimitChangeRequestApprovalDialog: boolean;
}

interface Props extends DetailsDrawerProps {
  teamId?: string;
  onUpdate: (card: Card) => void;
  onCardReplace?: (newCard: Card) => void;
}

const CardDetailsPage = ({
  teamId,
  onUpdate,
  onCardReplace,
  ...props
}: Props) => {
  const { t, i18n } = useTranslation();
  const canUser = useCanUser();
  const {
    isPartnerOrgError,
    isCardholderAppDeactivated,
    cardholderAppLink,
  } = useCardholderAppLink();
  const { cardId } = useParams<{ cardId: string }>();
  const {
    state: { organization, organizationIntegrations, featureModules },
  } = useGlobalState();
  const api = useImperativeApi();
  const mounted = useMounted();
  const idRef = useRef(cardId);
  const { height: drawerContentHeight, ref: resizerRef } = useResizeDetector<
    HTMLDivElement
  >({
    refreshMode: 'debounce',
    refreshRate: 0,
  });
  const visibleCardAccounts = useVisibleCardAccounts();
  const getCardAccountName = useCardAccountNameGetter();
  const availableTravelCardSubtypes = useAvailableVirtualTravelCardSubtypes();
  const teams = useActiveTeams();
  const managerTeam = useMemo(() => teams.find((item) => item.id === teamId), [
    teams,
    teamId,
  ]);
  const [state, setState] = useState<State>({
    isLoading: true,
    card: null,
    error: null,
    transactions: null,
    isChangeCardLimitsDialogOpen: false,
    isLimitChangeRequestApprovalDialog: false,
  });

  useEffect(() => {
    if (state.card) onUpdate(state.card);
  }, [state.card]);

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [card, transactions] = await Promise.all([
        api.getCard(cardId),
        api
          .getTransactions({
            organizationId: organization!.id,
            teamId,
            cardId,
            page: 0,
            limit: 3,
          })
          .then(
            (data) => data.transactions,
            () => null
          ),
      ]);
      if (!mounted.current || cardId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        card,
        transactions,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || cardId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error,
        card: null,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!cardId) return;
    idRef.current = cardId;
    getData();
  }, [cardId]);

  const renderCardAccountBadge = (cardAccountId: string) => {
    const accountName = getCardAccountName(cardAccountId);
    if (!accountName) return null;
    return (
      <Box display="flex" alignItems="center" my={0.5} mr={2}>
        <BankIcon sx={{ color: 'text.secondary', mr: 1 }} />
        <Chip label={accountName} size="small" />
      </Box>
    );
  };

  const renderCardContent = () => {
    if (!state.card) return null;

    const hideRequestLimitChangeButton =
      state.card.type === CardType.singleUse &&
      state.transactions &&
      state.transactions.some(
        ({ status }) => status === TransactionStatus.pending
      );

    return (
      <>
        <DetailsDrawerHeader>
          <Box display="flex" alignItems="center">
            <Typography variant="h5" mr={1} noWrap>
              {getCardNameWithRefNum(state.card)}
            </Typography>
            <CardStatusBadge
              status={state.card.simpleStatus}
              sx={{ flexShrink: 0, mr: 1 }}
            />
            {state.card.integrationType === IntegrationType.circula &&
              organizationIntegrations?.syncSettings?.orgConnectionType ===
                CirculaConnectionType.partial && (
                <Tooltip title={t('tooltips.circulaSynced')}>
                  <Box flexShrink={0} mr={1}>
                    <IntegrationTypeIcon
                      integrationType={IntegrationType.circula}
                      sx={{ verticalAlign: 'top' }}
                    />
                  </Box>
                </Tooltip>
              )}
          </Box>

          {availableTravelCardSubtypes.length > 1 &&
            i18n.exists(`cardNamesForSubtypes.${state.card.cardConfig}`) && (
              <Typography variant="subtitle2" color="text.secondary">
                {t(`cardNamesForSubtypes.${state.card.cardConfig}`)}
              </Typography>
            )}

          {(visibleCardAccounts.length > 1 ||
            (featureModules.TEAMS && state.card.teamName) ||
            state.card.projectName) && (
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              mt={1.5}
              mb={-0.5}
            >
              {visibleCardAccounts.length > 1 &&
                renderCardAccountBadge(state.card.cardAccountId)}
              {featureModules.TEAMS && state.card.teamName && (
                <Box display="flex" alignItems="center" my={0.5} mr={2}>
                  <UsersIcon sx={{ color: 'text.secondary', mr: 1 }} />
                  <Chip label={state.card.teamName} size="small" />
                </Box>
              )}
              {state.card.projectName && (
                <Box display="flex" alignItems="center" my={0.5} mr={2}>
                  <ProjectorScreenIcon
                    sx={{ color: 'text.secondary', mr: 1 }}
                  />
                  <Chip label={state.card.projectName} size="small" />
                </Box>
              )}
            </Box>
          )}
        </DetailsDrawerHeader>

        <DetailsDrawerContent ref={resizerRef}>
          {state.card.limitChangeRequestId && (
            <LimitChangeRequestDetails
              card={state.card}
              managerTeam={managerTeam}
              onUpdate={(card: Card) =>
                setState((prevState) => ({
                  ...prevState,
                  card,
                }))
              }
            />
          )}

          {state.card.simpleStatus === CardSimpleStatus.requested && (
            <CardRequestDetails
              card={state.card}
              managerTeam={managerTeam}
              onUpdate={(card: Card) =>
                setState((prevState) => ({
                  ...prevState,
                  card,
                }))
              }
            />
          )}

          <Box p={3}>
            <CardInReplacementBox card={state.card} />
            <CardHint card={state.card} />

            <Typography variant="overline" component="div" py={0.5}>
              {t('cardDetails.cardDetails')}
            </Typography>

            <Box display="flex" py={1}>
              <Box>
                <CardTile
                  card={state.card}
                  pan={`**** **** **** ${state.card.cardRefNum || '****'}`}
                />
              </Box>

              <Box flexGrow={1} minWidth={0} pl={2}>
                {state.card.fundingType === CardFundingType.charge ? (
                  <>
                    {state.card.type !== CardType.singleUse && (
                      <Box pb={2}>
                        <CardLimit card={state.card} />
                      </Box>
                    )}
                    <Box pb={2}>
                      <CardTransactionLimit card={state.card} />
                    </Box>
                  </>
                ) : (
                  <Box pb={2}>
                    <CardLoadBasedBalance card={state.card} />
                  </Box>
                )}

                {state.card.simpleStatus === CardSimpleStatus.requested &&
                  state.card.expirationInMonthsFromIssuance !== null &&
                  !state.card.cardValidity && (
                    <PaperLabeledValue
                      label={t('cardDetails.validityPeriod')}
                      value={`${state.card.expirationInMonthsFromIssuance} ${t(
                        'cardDetails.months'
                      )}`}
                    />
                  )}

                {state.card.type === CardType.singleUse &&
                  state.card.simpleStatus !== CardSimpleStatus.requested && (
                    <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                      <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                        mt={-0.5}
                      >
                        {t('cardDetails.purpose')}
                      </Typography>
                      <Typography variant="body2">
                        {state.card.purpose || '-'}
                      </Typography>
                    </Paper>
                  )}

                {canUser('card-limits:change', state.card, managerTeam) &&
                  !hideRequestLimitChangeButton && (
                    <Tooltip
                      title={
                        state.card.limitChangeRequestId
                          ? t('cardDetails.changeLimitsDisabledTooltip')
                          : null
                      }
                    >
                      <div>
                        <Button
                          disabled={!!state.card.limitChangeRequestId}
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              isChangeCardLimitsDialogOpen: true,
                            }))
                          }
                          fullWidth
                          size="large"
                          variant="outlined"
                          startIcon={<ArrowsClockwiseIcon />}
                          data-test-id="card-change-limits-btn"
                        >
                          {t(
                            state.card.type === CardType.singleUse
                              ? 'cardDetails.changeLimit_one'
                              : 'cardDetails.changeLimit_other'
                          )}
                        </Button>
                      </div>
                    </Tooltip>
                  )}
              </Box>
            </Box>

            <CardCustomValidityPeriod cardValidity={state.card.cardValidity} />
          </Box>

          {(state.card.cardControls.categories ||
            state.card.cardControls.merchants ||
            state.card.cardControls.dates ||
            state.card.cardControls.times ||
            state.card.cardControls.locations) && (
            <Box
              p={3}
              sx={(theme) => ({
                borderTop: `1px solid ${theme.palette.divider}`,
              })}
            >
              <Typography variant="overline" component="div" py={0.5}>
                {t('cardDetails.cardControls')}
              </Typography>
              <CardCategoryControlIndicator
                control={state.card.cardControls.categories}
              />
              <CardMerchantControlIndicator
                control={state.card.cardControls.merchants}
              />
              <CardDateControlIndicator
                control={state.card.cardControls.dates}
              />
              <CardTimeControlIndicator
                control={state.card.cardControls.times}
              />
              <CardLocationControlIndicator
                control={state.card.cardControls.locations}
              />
            </Box>
          )}

          <CardCustomFields card={state.card} />

          {state.card.simpleStatus !== CardSimpleStatus.requested && (
            <Box
              p={3}
              sx={(theme) => ({
                borderTop: `1px solid ${theme.palette.divider}`,
              })}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="overline" component="div" py={0.5}>
                  {t('cardDetails.lastTransactions')}
                </Typography>
                {!!state.transactions?.length && (
                  <Link
                    variant="body2"
                    component={RouterLink}
                    to={
                      teamId
                        ? cardholderPaths.teamTransactions
                        : generatePath(getPath('transactions'), {
                            orgId: state.card.organizationId,
                          }) + `?cardId=${state.card.cardId}`
                    }
                  >
                    {t('cardDetails.seeAll')}
                  </Link>
                )}
              </Box>
              {!!state.transactions?.length && (
                <LastTransactionsList
                  transactions={state.transactions}
                  teamId={teamId}
                />
              )}
              {state.transactions?.length === 0 && (
                <Typography color="text.secondary" my={1}>
                  {t('cardDetails.noTransactions')}
                </Typography>
              )}
              {!state.transactions && (
                <Alert
                  sx={{ my: (theme) => theme.spacing(1) }}
                  severity="error"
                  action={
                    <Button
                      variant="text"
                      color="error"
                      size="small"
                      onClick={getData}
                    >
                      {t('common.retry')}
                    </Button>
                  }
                >
                  {t('errors.loadData')}
                </Alert>
              )}
            </Box>
          )}
        </DetailsDrawerContent>

        {!!drawerContentHeight && (
          <CardHistory
            key={state.card!.cardId}
            card={state.card!}
            contentHeight={drawerContentHeight}
          />
        )}

        <ChangeCardLimitsDialog
          open={state.isChangeCardLimitsDialogOpen}
          card={state.card}
          managerTeam={managerTeam}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isChangeCardLimitsDialogOpen: false,
            }))
          }
          onSuccess={(card: Card) =>
            setState((prevState) => ({
              ...prevState,
              card,
              isChangeCardLimitsDialogOpen: false,
            }))
          }
        />
      </>
    );
  };

  return (
    <DetailsDrawer
      {...props}
      actionsComponent={
        state.card && (
          <>
            {canUser('card:view-in-another-app', state.card) &&
              !isPartnerOrgError &&
              !isCardholderAppDeactivated && (
                <Link
                  variant="body2"
                  component={RouterLink}
                  to={`${cardholderPaths.wallet}?cardId=${state.card.cardId}`}
                  sx={{ mr: 'auto' }}
                >
                  {t('cardDetails.viewInWallet')}
                </Link>
              )}
            {canUser('card:view-in-another-app', state.card) &&
              !isPartnerOrgError &&
              isCardholderAppDeactivated && (
                <Link
                  variant="body2"
                  href={cardholderAppLink}
                  sx={{ mr: 'auto' }}
                >
                  {t('cardDetails.viewInWallet')}
                </Link>
              )}
            {state.card.simpleStatus !== CardSimpleStatus.requested && (
              <CardDetailsMenu
                card={state.card}
                onUpdate={(card: Card) =>
                  setState((prevState) => ({ ...prevState, card }))
                }
                onCardReplace={onCardReplace}
                managerTeam={managerTeam}
              />
            )}
          </>
        )
      }
      PaperProps={{
        sx: {
          pb: COLLAPSED_CARD_HISTORY_HEIGHT + 'px',
        },
      }}
    >
      {renderCardContent()}
      {state.error && <WidgetError onReload={getData} />}
      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(CardDetailsPage);
