import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Label, Pie, PieChart } from 'recharts';
import styled, { useTheme } from 'styled-components';
import { OrganizationEmission } from 'services/constants';

const Wrapper = styled.div`
  margin: 0 0 20px;
  padding: 30px 30px 0;
`;

const PercentageLabel = styled.text`
  fill: ${({ theme }) => theme.colors.earthRoyalBlue};
  font-size: 24px;
  line-height: 24px;
  text-anchor: middle;
`;

const SubLabel = styled.text`
  fill: ${({ theme }) => theme.colors.grey100};
  font-size: 10px;
  line-height: 14px;
  text-anchor: middle;
`;

const CustomizedLabel = ({ viewBox, percentage }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <PercentageLabel x={viewBox.cx} y={viewBox.cy + 2}>
        {`${percentage}%`}
      </PercentageLabel>

      <SubLabel x={viewBox.cx} y={viewBox.cy + 14}>
        {t('pliantEarthPage.dashboard.pieChart.compensated')}
      </SubLabel>
    </>
  );
};

interface Props {
  orgEmission: OrganizationEmission | null;
}

const CustomPieChart = ({ orgEmission }: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const percentageCompensated = useMemo(() => {
    const emission = orgEmission?.lifetimeCarbonEmission ?? 0;
    const offset = orgEmission?.lifetimeCarbonOffset ?? 0;

    if (offset === 0) return 0;
    if (emission === 0) return 100;

    const percentage = Math.round(Math.abs(offset / emission) * 100);

    if (percentage > 999) return 999;

    return percentage;
  }, [orgEmission]);

  return (
    <Wrapper>
      <PieChart width={144} height={144}>
        <Pie
          data={[
            {
              name: t('pliantEarthPage.dashboard.pieChart.emission'),
              value: 100 - percentageCompensated,
            },
            {
              name: t('pliantEarthPage.dashboard.pieChart.compensation'),
              value: percentageCompensated,
            },
          ]}
          innerRadius={56}
          outerRadius={72}
          dataKey="value"
          startAngle={90}
          endAngle={450}
          stroke=""
          animationBegin={0}
          animationDuration={500}
        >
          <Label
            content={<CustomizedLabel percentage={percentageCompensated} />}
          />

          <Cell fill={colors.earthGrey} />

          <Cell fill={colors.earthRoyalBlue} />
        </Pie>
      </PieChart>
    </Wrapper>
  );
};

export default CustomPieChart;
