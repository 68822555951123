import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationAutocompleteSearch } from 'domains/organization/components';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  OrganizationGroupCreatePayload,
  OrganizationGroupType,
  organizationGroupTypes,
  OrganizationSearchItem,
  supportedCountries,
  SupportedCountry,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertUKtoGBCountryCode,
  getGenericErrorMsg,
  getMoneyObject,
} from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: () => Promise<void>;
}

interface State {
  birthdate: string;
  country: SupportedCountry;
  isLoading: boolean;
  maxLimit: number | null;
  name: string;
  selectedOrg: OrganizationSearchItem | null;
  type: OrganizationGroupType;
}

const CreateGroupDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState<State>({
    birthdate: '',
    country: SupportedCountry.de,
    isLoading: false,
    maxLimit: null,
    name: '',
    selectedOrg: null,
    type: OrganizationGroupType.gccLegalEntity,
  });

  const handleSubmit = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      let payload: OrganizationGroupCreatePayload = {
        country: state.country,
        name: '',
        type: state.type,
      };
      if (state.type === OrganizationGroupType.gccNaturalPerson) {
        payload = { ...payload, name: state.name, birthDate: state.birthdate };
      }
      if (state.type === OrganizationGroupType.gccLegalEntity) {
        payload = {
          ...payload,
          companyId: state.selectedOrg!.companyId,
          companyIdType: state.selectedOrg!.companyIdType,
          name: state.selectedOrg!.companyName,
        };
      }
      if (state.type === OrganizationGroupType.ru) {
        payload = {
          ...payload,
          companyId: state.selectedOrg!.companyId,
          companyIdType: state.selectedOrg!.companyIdType,
          maxLimit: getMoneyObject(state.maxLimit! * 100),
          name: state.selectedOrg!.companyName,
        };
      }
      await api.createOrganizationGroup(payload);
      await onSuccess();
      if (!mounted.current) return;
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const canSubmit = (() => {
    switch (state.type) {
      case OrganizationGroupType.gccLegalEntity:
        return state.country !== null && state.selectedOrg !== null;
      case OrganizationGroupType.gccNaturalPerson:
        return (
          state.country !== null &&
          state.name.length > 0 &&
          state.birthdate.length > 0
        );
      case OrganizationGroupType.ru:
        return (
          state.country !== null &&
          state.selectedOrg !== null &&
          state.maxLimit !== null
        );
      default:
        return false;
    }
  })();

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.createGroupDialog.title')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>{t('int.filters.organizationGroupType')}</InputLabel>
              <Select
                size="small"
                value={state.type}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    maxLimit: null,
                    name: '',
                    selectedOrg: null,
                    type: e.target.value as State['type'],
                  }))
                }
                renderValue={(selected) =>
                  t(`int.organizationGroupType.${selected}`)
                }
              >
                {organizationGroupTypes.map((item) => (
                  <MenuItem key={item} value={item}>
                    {t(`int.organizationGroupType.${item}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete<SupportedCountry, false, true, false>
              value={state.country}
              onChange={(_, option) =>
                setState((prevState) => ({
                  ...prevState,
                  country: option,
                  selectedOrg: null,
                }))
              }
              options={supportedCountries}
              disableClearable
              disabled={state.isLoading}
              label={t('int.createGroupDialog.country')}
              placeholder={t('int.createGroupDialog.country')}
              getOptionLabel={(option: SupportedCountry) =>
                t(`countries.${option}`)
              }
              noOptionsText={t('common.nothingFound')}
            />
          </Grid>
          <Grid item xs={12}>
            {state.type === OrganizationGroupType.gccNaturalPerson ? (
              <TextField
                label={t('int.createGroupDialog.name')}
                onChange={(event) =>
                  setState((prevState) => ({
                    ...prevState,
                    name: event.target.value,
                  }))
                }
                value={state.name}
              />
            ) : (
              <OrganizationAutocompleteSearch
                country={convertUKtoGBCountryCode(state.country)}
                value={state.selectedOrg}
                onChange={(selectedOrg) =>
                  setState((prevState) => ({
                    ...prevState,
                    selectedOrg,
                  }))
                }
              />
            )}
          </Grid>

          {state.type === OrganizationGroupType.gccNaturalPerson && (
            <Grid item xs={12}>
              <TextField
                label={t('int.createGroupDialog.birthdate')}
                onChange={(event) =>
                  setState((prevState) => ({
                    ...prevState,
                    birthdate: event.target.value,
                  }))
                }
                type="date"
                value={state.birthdate}
              />
            </Grid>
          )}
          {state.type === OrganizationGroupType.ru && (
            <Grid item xs={12}>
              <MoneyField
                onValueChange={({ floatValue = null }) =>
                  setState((prevState) => ({
                    ...prevState,
                    maxLimit: floatValue,
                  }))
                }
                label={t('int.createGroupDialog.maxLimit')}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={!canSubmit || state.isLoading}
          type="submit"
          onClick={handleSubmit}
        >
          {t('int.createGroupDialog.addGroup')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(CreateGroupDialog);
