import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState, useVisibleCardAccounts } from 'context/GlobalState';
import { CardIconWithDetails, CardStatusBadge } from 'domains/card/components';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import { IntegrationTypeIcon } from 'domains/partner/components';
import {
  DataGridCell,
  drawerPlaceholderColumn,
  GridColDef,
  GridRenderCellParams,
  gridUtils,
  Tooltip,
  WarningCircleIcon,
} from 'elements';
import {
  Card,
  CardSimpleStatus,
  CirculaConnectionType,
  IntegrationType,
} from 'services/constants';
import { getFullName } from 'services/utils';
import AvailableCell from './AvailableCell';
import LimitFrequencyCell from './LimitFrequencyCell';
import TransactionLimitCell from './TransactionLimitCell';

const useColumns = () => {
  const { t, i18n } = useTranslation();
  const {
    state: { organization, organizationIntegrations },
  } = useGlobalState();
  const visibleCardAccounts = useVisibleCardAccounts();
  const getCardAccountName = useCardAccountNameGetter();

  return useMemo(() => {
    const columns: GridColDef<Card>[] = [
      {
        headerName: t('cardsPage.card'),
        field: 'type',
        flex: 150,
        renderCell: ({ row }) => (
          <DataGridCell>
            <CardIconWithDetails
              cardConfig={row.cardConfig}
              cardDesignId={row.cardDesignId}
              cardRefNum={row.cardRefNum}
              cardName={row.cardName}
              showCardNameTooltip
            />
          </DataGridCell>
        ),
      },
      {
        headerName: '',
        field: 'request',
        sortable: false,
        flex: 50,
        renderCell: ({ row }) => {
          if (row.simpleStatus === CardSimpleStatus.requested) {
            return (
              <Tooltip title={t('cardsPage.requestLabels.cardRequest')!}>
                <WarningCircleIcon
                  fontSize="small"
                  sx={{ color: 'error.main' }}
                />
              </Tooltip>
            );
          }

          if (row.limitChangeRequestId) {
            return (
              <Tooltip title={t('cardsPage.requestLabels.limitRequest')!}>
                <WarningCircleIcon
                  fontSize="small"
                  sx={{ color: 'error.main' }}
                />
              </Tooltip>
            );
          }

          return null;
        },
      },
      ...(organization!.integrations.includes(IntegrationType.circula) &&
      organizationIntegrations?.syncSettings?.orgConnectionType ===
        CirculaConnectionType.partial
        ? [
            {
              headerName: '',
              field: 'integrationType',
              sortable: false,
              flex: 40,
              align: 'center' as const,
              renderCell: ({ row }: GridRenderCellParams<Card>) =>
                row.integrationType === IntegrationType.circula ? (
                  <Tooltip title={t('tooltips.circulaSynced')}>
                    <IntegrationTypeIcon
                      integrationType={row.integrationType}
                    />
                  </Tooltip>
                ) : null,
            },
          ]
        : []),
      {
        headerName: t('cardsPage.name'),
        field: 'chFirstName',
        flex: 150,
        valueGetter: ({ row }) =>
          getFullName(row.chFirstName, row.chLastName) || '-',
      },
      {
        headerName: t('cardsPage.status'),
        field: 'status',
        flex: 120,
        renderCell: ({ row }) => <CardStatusBadge status={row.simpleStatus} />,
      },
      {
        headerName: t('cardsPage.issued'),
        field: 'issuingDate',
        flex: 120,
        valueGetter: ({ value }) =>
          value ? moment.utc(value).format('D MMM YYYY') : '-',
      },
      {
        headerName: t('cardsPage.validUntil'),
        field: 'expiryDate',
        flex: 120,
        renderCell: ({ row }) => {
          if (!row.expiryDate) {
            return <DataGridCell>-</DataGridCell>;
          }

          return (
            <DataGridCell>
              {row.cardValidity
                ? moment(row.cardValidity.validTo).format('D MMM YYYY')
                : moment.utc(row.expiryDate).format('D MMM YYYY')}
            </DataGridCell>
          );
        },
      },
      ...(visibleCardAccounts.length > 1
        ? [
            {
              headerName: t('cardsPage.account'),
              field: 'cardAccountName',
              flex: 80,
              sortable: false,
              valueGetter: ({ row }: GridRenderCellParams<Card>) =>
                getCardAccountName(row.cardAccountId),
            },
          ]
        : []),
      {
        headerName: t('cardsPage.transactionLimit'),
        field: 'transactionLimit',
        flex: 120,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ row }) => <TransactionLimitCell card={row} />,
      },
      {
        headerName: t('cardsPage.balanceLimit'),
        field: 'limit',
        flex: 160,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ row }) => <AvailableCell card={row} />,
      },
      {
        headerName: t('cardsPage.limitFrequency'),
        field: 'limitRenewFrequency',
        sortable: false,
        flex: 100,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ row }) => <LimitFrequencyCell card={row} />,
      },
      drawerPlaceholderColumn,
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language, getCardAccountName]);
};

export default useColumns;
