import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FormatDecimal from 'components/FormatDecimal';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  CircularProgress,
  GlobeHemisphereWestIcon,
  InfoIcon,
  LinearProgress,
  Tooltip,
  TravelIcon,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import { OrganizationEmission } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

const calcCompensatedPercentage = (
  carbonOffset: number,
  carbonEmission: number
) => {
  if (carbonOffset === 0) return 0;
  if (carbonEmission === 0) return 100;
  return (carbonOffset / carbonEmission) * 100;
};

interface State {
  isLoading: boolean;
  orgEmission: OrganizationEmission | null;
}

const EmissionSummary = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const partnerName = usePartnerName();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    orgEmission: null,
  });

  const getData = async () => {
    try {
      const orgEmission = await api.getTotalOrgEmissionsByYear(
        organization!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        orgEmission,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const emission = state.orgEmission?.lifetimeCarbonEmission ?? 0;
  const offset = state.orgEmission?.lifetimeCarbonOffset ?? 0;
  const compensatedPercentage = calcCompensatedPercentage(offset, emission);

  return (
    <Box
      flexGrow={1}
      p={2}
      sx={(theme) => ({
        borderTop: `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
      })}
    >
      <Box display="flex" alignItems="center" mb={1}>
        <Typography
          variant="caption2"
          component="div"
          color="text.secondary"
          flexGrow={1}
          noWrap
        >
          {t('dashboardPage.co2Compensation')}
        </Typography>
        <Tooltip title={t('dashboardPage.emissionTooltip', { partnerName })}>
          <InfoIcon
            fontSize="small"
            sx={{
              width: '16px',
              height: '16px',
              mx: 0.5,
              color: 'text.secondary',
            }}
          />
        </Tooltip>
        <Typography
          variant="caption2"
          component="div"
          color="text.secondary"
          flexShrink={0}
        >
          {t('dashboardPage.travelCo2')}
        </Typography>
      </Box>

      {!state.isLoading && state.orgEmission && (
        <>
          <Box display="flex" alignItems="flex-end" mb={1}>
            <Box display="flex" alignItems="center" flexShrink={0}>
              <GlobeHemisphereWestIcon sx={{ mr: 0.5 }} />
              <Typography variant="h6">
                <FormatDecimal value={offset / 1000000} unit="t" />
              </Typography>
            </Box>
            <Typography
              variant="caption"
              component="div"
              textAlign="center"
              color="text.secondary"
              noWrap
              flexGrow={1}
              mx={1}
              mb="2px"
            >
              {!!emission && (
                <Trans
                  i18nKey="dashboardPage.emissionCompensationPercentLabel"
                  components={{
                    percent: (
                      <FormatPercent
                        fractionalPart={false}
                        value={compensatedPercentage}
                      />
                    ),
                  }}
                />
              )}
            </Typography>
            <Box display="flex" alignItems="center" flexShrink={0}>
              <Typography variant="h6">
                <FormatDecimal value={emission / 1000000} unit="t" />
              </Typography>
              <TravelIcon sx={{ ml: 0.5 }} />
            </Box>
          </Box>

          <LinearProgress
            value={Math.min(compensatedPercentage, 100)}
            size="small"
          />
        </>
      )}

      {!state.isLoading && !state.orgEmission && (
        <Typography variant="caption" color="error.main">
          {t('errors.loadData')}
        </Typography>
      )}

      {state.isLoading && (
        <Box minHeight={32} display="flex" alignItems="center">
          <CircularProgress size="small" />
        </Box>
      )}
    </Box>
  );
};

export default EmissionSummary;
