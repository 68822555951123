import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from 'elements';
import { ApiIntegrationStatus, ExportFormat } from 'services/constants';

interface Props {
  isExported: boolean;
  format: ExportFormat;
  onSave: (format: ExportFormat) => void;
}

const LexwareOfficeExportFormats = ({ isExported, format, onSave }: Props) => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings, generalInfo },
  } = useGlobalState();

  const isLexwareOfficeConnected =
    accountingSettings!.apiIntegrationStatus === ApiIntegrationStatus.connected;

  if (!isExported && isLexwareOfficeConnected) {
    return (
      <Table sx={{ marginTop: -2 }}>
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: '48%' }}>
              <Typography variant="subtitle2">
                {t('exportTransactionsDialog.exportFormat')}
              </Typography>
            </TableCell>
            <TableCell>
              {t('exportTransactionsDialog.accSystemApiLabel', {
                name: accountingSettings!.accountingSystemName,
              })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  if (!isExported && !isLexwareOfficeConnected) {
    return (
      <FormControl fullWidth error={format === ExportFormat.api}>
        <InputLabel>{t('exportTransactionsDialog.exportFormat')}</InputLabel>
        <Select
          name="format"
          value={format}
          onChange={(e) => onSave(e.target.value as ExportFormat)}
        >
          <MenuItem value={ExportFormat.api} disabled>
            {t('exportTransactionsDialog.accSystemApiLabel', {
              name: accountingSettings!.accountingSystemName,
            })}
          </MenuItem>
          <MenuItem value={ExportFormat.csv}>CSV</MenuItem>
        </Select>
        {format === ExportFormat.api && (
          <FormHelperText>
            <Trans
              i18nKey="exportTransactionsDialog.apiErrorTooltipTitle"
              components={{
                sup: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    style={{ color: 'inherit' }}
                    href={'mailto:' + generalInfo.supportEmail}
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  // on Export page - default CSV
  return (
    <Table sx={{ marginTop: -2 }}>
      <TableBody>
        <TableRow>
          <TableCell sx={{ width: '48%' }}>
            <Typography variant="subtitle2">
              {t('exportTransactionsDialog.exportFormat')}
            </Typography>
          </TableCell>
          <TableCell>CSV</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default LexwareOfficeExportFormats;
