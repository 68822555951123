import { useEffect, useRef, useState } from 'react';
import xor from 'lodash/xor';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BulkUploadItemsDialog from 'components/BulkUploadItemsDialog';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { useActiveTeams, useGlobalState } from 'context/GlobalState';
import { CreateTeamMultistepDialog } from 'domains/member/dialogs';
import { AddNewItemMenu } from 'domains/settings/components/AddNewItemMenu';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  LoaderWithOverlay,
  Switch,
  UsersIcon,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import {
  PageContent,
  PageFilters,
  PageHeader,
  PageSearchInput,
  PageTitle,
  PageTitleActions,
} from 'layout';
import {
  DEFAULT_PAGE_LIMIT,
  FeatureModuleKey,
  GetTeamsParams,
  Team,
  TeamStatus,
  teamStatuses,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import TeamItem from './TeamItem';

const getQueryParams = (qs: string) => {
  const { q, status } = Object.fromEntries(new URLSearchParams(qs).entries());

  return {
    q: q ? q.trim() : '',
    status:
      status && xor(status.split(','), teamStatuses).length === 0
        ? teamStatuses
        : [TeamStatus.active],
  };
};

const getRequestParams = (
  params: ReturnType<typeof getQueryParams>
): Pick<GetTeamsParams, 'q' | 'status'> => {
  const { q, status } = params;
  return {
    q: q.length ? q : undefined,
    status:
      status.length === 1 && status[0] === TeamStatus.active
        ? status[0]
        : undefined,
  };
};

interface State {
  teams: Team[];
  hasNextPage: boolean;
  isLoading: boolean;
  isCreateTeamMultistepDialogOpen: boolean;
  isUploadTeamsDialogOpen: boolean;
}

const TeamsPage = () => {
  const pageRef = useRef(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const location = useLocation();
  const setQueryParam = useSetQueryParam();
  const canUser = useCanUser();
  const showPageError = useShowPageError();
  const { state: globalState } = useGlobalState();
  const activeTeams = useActiveTeams();
  const paramsRef = useRef(getQueryParams(location.search));
  const [state, setState] = useState<State>({
    teams: [],
    hasNextPage: false,
    isLoading: true,
    isCreateTeamMultistepDialogOpen: false,
    isUploadTeamsDialogOpen: false,
  });
  const areFiltersApplied = !!paramsRef.current.q.length;
  const isEmptyState =
    !state.isLoading && !state.teams.length && !areFiltersApplied;

  const getData = async (page: number, isLoadMore = false) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const params = getRequestParams(paramsRef.current);
      const { teams, hasNextPage } = await api.getTeams({
        organizationId: globalState.organization!.id,
        page,
        limit: DEFAULT_PAGE_LIMIT,
        sortBy: '+name',
        ...params,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        teams: isLoadMore ? [...prevState.teams, ...teams] : teams,
        hasNextPage,
        isLoading: false,
      }));
      if (!isLoadMore) contentRef.current?.scrollTo({ top: 0 });
    } catch (error) {
      if (!mounted.current) return;
      showPageError(error);
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    paramsRef.current = getQueryParams(location.search);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, true);
  };

  const onRefetch = () => {
    paramsRef.current = getQueryParams(location.search);
    pageRef.current = 0;
    getData(pageRef.current);
  };

  const onTeamUpdate = (team: Team) => {
    setState((prevState) => ({
      ...prevState,
      teams: prevState.teams.map((item) => {
        if (item.id === team.id) return team;
        return item;
      }),
    }));
  };

  const renderPageTitleActions = () => {
    if (canUser('team:bulk-upload'))
      return (
        <AddNewItemMenu
          format="XLSX"
          title={t('int.teamsPage.addTeam')}
          onAdd={() =>
            setState((prevState) => ({
              ...prevState,
              isCreateTeamMultistepDialogOpen: true,
            }))
          }
          onUpload={() =>
            setState((prevState) => ({
              ...prevState,
              isUploadTeamsDialogOpen: true,
            }))
          }
        />
      );

    if (canUser('team:create'))
      return (
        <PageTitleActions>
          <Button
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                isCreateTeamMultistepDialogOpen: true,
              }))
            }
            data-intercom-target="create-team-button"
            startIcon={<UsersIcon />}
          >
            {t('teamsPage.createTeam')}
          </Button>
        </PageTitleActions>
      );

    return null;
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('teamsPage.title')}>
          {renderPageTitleActions()}
        </PageTitle>

        <PageFilters
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pb: 2,
            mb: -2,
            borderBottom: `1px solid ${theme.palette.divider}`,
          })}
        >
          <PageSearchInput
            initialValue={paramsRef.current.q}
            onChange={(value) => setQueryParam('q', value)}
            disabled={isEmptyState}
          />

          {activeTeams.length !== globalState.teams.length && (
            <FormControlLabel
              checked={paramsRef.current.status.length !== 1}
              onChange={(e, checked) =>
                setQueryParam('status', checked ? teamStatuses : [])
              }
              control={<Switch />}
              label={t('teamsPage.deactivatedTeams')}
            />
          )}
        </PageFilters>
      </PageHeader>

      <PageContent pt={2} overflow="hidden">
        <LoaderWithOverlay loading={state.isLoading} />
        {isEmptyState && (
          <NoData isNewDesign Icon={UsersIcon} label={t('teamsPage.noTeams')} />
        )}
        {!state.isLoading && !state.teams.length && areFiltersApplied && (
          <NothingFound />
        )}

        <Box overflow="auto" height="100%" ref={contentRef}>
          <Grid container spacing={4}>
            {state.teams.map((team) => {
              return (
                <TeamItem
                  key={team.id}
                  team={team}
                  onUpdate={onTeamUpdate}
                  onRefetch={onRefetch}
                />
              );
            })}
          </Grid>

          {state.hasNextPage && (
            <Box my={3} textAlign="center">
              <Button
                sx={{ minWidth: 240 }}
                onClick={loadMoreItems}
                disabled={state.isLoading}
              >
                {t('common.button.loadMore')}
              </Button>
            </Box>
          )}
        </Box>
      </PageContent>

      <CreateTeamMultistepDialog
        open={state.isCreateTeamMultistepDialogOpen}
        onRefetch={onRefetch}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isCreateTeamMultistepDialogOpen: false,
          }))
        }
      />

      {canUser('team:bulk-upload') && (
        <BulkUploadItemsDialog
          open={state.isUploadTeamsDialogOpen}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isUploadTeamsDialogOpen: false,
            }))
          }
          context="teams"
        />
      )}
    </>
  );
};

export default withPageConfig(TeamsPage, {
  permission: 'teams-page:visit',
  featureModule: FeatureModuleKey.teams,
});
