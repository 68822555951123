import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamsData } from 'domains/transaction/hooks';
import { Chip, Tooltip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { getFilterChipText } from 'layout';

interface Props {
  teamData: TeamsData;
  teamsIdsParams: string[];
  setParam: SetQueryParam;
}

const TeamFilterChip = ({ teamData, teamsIdsParams, setParam }: Props) => {
  const { t } = useTranslation();

  const teamValidChipText = useMemo(() => {
    if (teamData.isLoading) return t('common.loading');
    return teamData.value.length ? teamData.value[0].name : '';
  }, [teamsIdsParams, teamData]);

  const teamValidChipTooltip = useMemo(() => {
    if (teamData.value.length < 2) return '';
    return teamData.value.map((team) => team.name).join(', ');
  }, [teamsIdsParams, teamData]);

  return (
    <>
      {(teamData.isLoading || !!teamData.value.length) && (
        <Tooltip title={teamValidChipTooltip}>
          <Chip
            label={getFilterChipText(
              teamValidChipText,
              teamData.isLoading ? 0 : teamData.value.length,
              t('filters.team')
            )}
            onDelete={() => setParam('teamId', [])}
          />
        </Tooltip>
      )}

      {teamData.isError && (
        <Chip
          label={getFilterChipText(
            t('filters.notFound'),
            teamData.invalid.length,
            t('filters.team')
          )}
          color="error"
          onDelete={() =>
            setParam(
              'teamId',
              teamData.value.map((items) => items.id)
            )
          }
        />
      )}
    </>
  );
};

export default TeamFilterChip;
