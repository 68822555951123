import { ProjectsData, useProjectFilter } from 'domains/transaction/hooks';
import {
  TeamsData,
  useTeamFilter,
} from 'domains/transaction/hooks/useTeamFilter';
import { QueryParams as ChTeamsTransactionsPageQueryParams } from 'domains/transaction/pages/CardholderTeamTransactionsPage';
import { QueryParams as TransactionsPageQueryParams } from 'domains/transaction/pages/TransactionsPage';

type QueryParams = Pick<
  TransactionsPageQueryParams | ChTeamsTransactionsPageQueryParams,
  'projectIds' | 'teamId'
>;

const useFilters = (params: QueryParams) => {
  const { projectData, setProjectFilter } = useProjectFilter(params.projectIds);
  const { teamData, setTeamFilter } = useTeamFilter(params.teamId);

  const setDataFilters = ({
    projects,
    teams,
  }: {
    projects: Pick<ProjectsData, 'value'>;
    teams: Pick<TeamsData, 'value'>;
  }) => {
    setProjectFilter(projects);
    setTeamFilter(teams);
  };

  return {
    projectData,
    teamData,
    areFiltersLoading: projectData.isLoading || teamData.isLoading,
    setDataFilters,
  };
};

export default useFilters;
export type { ProjectsData, TeamsData };
