import { useCallback } from 'react';
import { useGlobalState } from 'context/GlobalState';
import {
  CardAccount,
  CardAccountStatus,
  OrganizationAccountType,
  OrganizationProductType,
} from 'services/constants';

const useIsAllowedToTopUpCardAccount = () => {
  const {
    state: { organization },
  } = useGlobalState();

  return useCallback(
    (cardAccount: CardAccount) => {
      if (
        cardAccount.benefitCardAccount.value ||
        cardAccount.status.value !== CardAccountStatus.active
      )
        return false;

      if (cardAccount.accountType.value === OrganizationAccountType.prefunded) {
        return true;
      }

      return (
        cardAccount.accountType.value === OrganizationAccountType.credit &&
        [OrganizationProductType.MDESD, OrganizationProductType.CCROY].includes(
          organization!.productType
        )
      );
    },
    [organization?.productType]
  );
};

export default useIsAllowedToTopUpCardAccount;
