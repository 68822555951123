import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'elements';
import usePartnerName from 'hooks/usePartnerName';

interface Props {
  hasOffsetPayout: boolean;
  hasCashPayout: boolean;
  onClose: () => void;
}

const SuccessStep = ({ hasOffsetPayout, hasCashPayout, onClose }: Props) => {
  const { t } = useTranslation();
  const partnerName = usePartnerName();
  const {
    state: { organization },
  } = useGlobalState();

  return (
    <>
      <DialogTitle>{t('redeemCashBackDialog.successDialog.title')}</DialogTitle>
      <DialogContent>
        {hasCashPayout && (
          <Typography>
            <Trans
              i18nKey="redeemCashBackDialog.successDialog.cashMessage"
              components={{ b: <b /> }}
              values={{
                email: organization?.billingEmail,
              }}
            />
          </Typography>
        )}

        {hasOffsetPayout && (
          <Typography mt={3}>
            <Trans
              i18nKey="redeemCashBackDialog.successDialog.offsetMessage"
              components={{ b: <b /> }}
              values={{
                partnerName,
              }}
            />
          </Typography>
        )}

        {hasCashPayout && hasOffsetPayout && (
          <Typography mt={3}>
            <Trans
              i18nKey="redeemCashBackDialog.successDialog.mixedEmail"
              components={{ b: <b /> }}
              values={{
                email: organization?.billingEmail,
              }}
            />
          </Typography>
        )}

        {!hasCashPayout && hasOffsetPayout && (
          <Typography mt={3}>
            <Trans
              i18nKey="redeemCashBackDialog.successDialog.offsetOnlyEmail"
              components={{ b: <b /> }}
              values={{
                email: organization?.billingEmail,
              }}
            />
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.button.close')}</Button>
      </DialogActions>
    </>
  );
};

export default SuccessStep;
