import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import {
  Box,
  GridColDef,
  IconButton,
  Link,
  PencilSimpleIcon,
  StatusBadge,
  Tooltip,
} from 'elements';
import { CardAccountSettings } from 'services/constants';
import { getPath } from 'services/utils';

const useColumns = (onEdit: (item: CardAccountSettings) => void) => {
  const { t, i18n } = useTranslation();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();
  const getCardAccountName = useCardAccountNameGetter();
  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);

  return useMemo(() => {
    const columns: GridColDef<CardAccountSettings>[] = [
      {
        field: 'cardAccountName',
        headerName: t('cardAccountsSubPage.cardAccountNameLabel'),
        flex: 1,
        valueGetter: ({ row }) => getCardAccountName(row.cardAccountId),
      },
      {
        field: 'pliantCreditCardAccount',
        headerName: isCodatConnected
          ? t('cardAccountsSubPage.linkedAccountLabel')
          : t('cardAccountsSubPage.accountNumberLabel'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'accountType',
        headerName: '',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row.defaultAccount ? (
            <Tooltip
              title={
                <Trans
                  i18nKey="cardAccountsSubPage.defaultBadgeTooltip"
                  components={{
                    linkTo: (
                      <Link
                        component={RouterLink}
                        to={generatePath(getPath('cardAccounts'), {
                          orgId: organization!.id,
                        })}
                        color="inherit"
                      />
                    ),
                  }}
                />
              }
            >
              <Box overflow="hidden">
                <StatusBadge
                  label={t('cardAccountsSubPage.defaultBadge')}
                  color="primary"
                />
              </Box>
            </Tooltip>
          ) : null,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 72,
        getActions: ({ row }) => [
          <IconButton onClick={() => onEdit(row)}>
            <PencilSimpleIcon />
          </IconButton>,
        ],
      },
    ];

    return {
      columns,
    };
  }, [i18n.language, getCardAccountName, isCodatConnected]);
};

export default useColumns;
