import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { doubleDigitFormat } from 'domains/card/utils';
import {
  Box,
  ClockIcon,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from 'elements';

interface Props {
  startTime: string | null;
  endTime: string | null;
  onTimesChange: (startTime: string | null, endTime: string | null) => void;
  disabled?: boolean;
}

export const TIME_FORMAT = 'HH:mm';

const TIMES = Array.from(Array(48).keys()).map(
  (item) =>
    `${doubleDigitFormat(Math.floor(item / 2).toString())}:${doubleDigitFormat(
      ((item % 2) * 30).toString()
    )}`
);

export const TimeRangePicker = ({
  startTime,
  endTime,
  onTimesChange,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<{
    from: string | null;
    to: string | null;
  }>({
    from: startTime || null,
    to: endTime || null,
  });

  const renderTime = (time: string | null) => {
    if (!time) {
      return TIME_FORMAT;
    }
    return time;
  };

  const isTimeDisabled = (time: string) => {
    return (
      !!state.from &&
      moment(time, TIME_FORMAT).isSameOrBefore(moment(state.from, TIME_FORMAT))
    );
  };

  const handleStartTimeClick = (time: string) => {
    const updatedTo = moment(time, TIME_FORMAT).isBefore(
      moment(state.from, TIME_FORMAT)
    )
      ? state.to
      : '';
    setState({ from: time, to: updatedTo });
    onTimesChange(time, updatedTo);
  };

  const handleEndTimeClick = (time: string) => {
    setState((prevState) => ({ ...prevState, to: time }));
    onTimesChange(state.from as string, time);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box flex={1} mr={1}>
        <FormControl fullWidth disabled={disabled}>
          <InputLabel>{t('common.from')}</InputLabel>
          <Select
            startAdornment={
              <InputAdornment position="start">
                <ClockIcon fontSize="small" />
              </InputAdornment>
            }
            value={startTime || ''}
            renderValue={renderTime}
            onChange={(e) => handleStartTimeClick(e.target.value)}
            displayEmpty
          >
            {TIMES.map((item) => {
              return (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box flex={1} ml={1}>
        <FormControl fullWidth disabled={disabled || !startTime}>
          <InputLabel>{t('common.to')}</InputLabel>
          <Select
            startAdornment={
              <InputAdornment position="start">
                <ClockIcon fontSize="small" />
              </InputAdornment>
            }
            value={endTime || ''}
            renderValue={renderTime}
            onChange={(e) => handleEndTimeClick(e.target.value)}
            displayEmpty
          >
            {TIMES.map((item) => {
              return (
                <MenuItem
                  key={item}
                  value={item}
                  disabled={isTimeDisabled(item)}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
