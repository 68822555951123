import { IconButton, IconButtonProps } from '@mui/material';
import { PencilSimpleIcon } from 'elements/TemporaryIcon';

export interface EditButtonProps extends IconButtonProps {}

const EditButton = (props: IconButtonProps) => (
  <IconButton aria-label="edit" {...props}>
    <PencilSimpleIcon />
  </IconButton>
);

export { EditButton };
