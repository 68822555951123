import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { PARTNER_OWN_AUTH_SCOPES } from 'domains/partner/constants';
import { PartnerAuthStatus } from 'services/constants';
import { useCanUser } from 'services/rbac';

export const usePartnerAuthFlowRedirect = () => {
  const canUser = useCanUser();
  const {
    state: { partnerConfig, partnerOrgAuthDetails },
  } = useGlobalState();

  const isPartnerAuthFlowRedirectAllowed = useMemo(
    () =>
      canUser('partners:change') &&
      !!partnerConfig &&
      PARTNER_OWN_AUTH_SCOPES.includes(partnerConfig.partnerScope) &&
      partnerOrgAuthDetails?.status === PartnerAuthStatus.inactive,
    [partnerConfig, partnerOrgAuthDetails]
  );

  return { isPartnerAuthFlowRedirectAllowed };
};
