import { FormEvent, useEffect, useState } from 'react';
import { Box, ListItemText, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Button';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { Select } from 'components/Form';
import { LoaderWithOverlay } from 'components/Loader';
import OnboardingBlockWrapper from 'components/OnboardingPage/OnboardingBlockWrapper';
import { MailDescription } from 'components/OnboardingPage/style';
import { useOnboardingContext } from 'components/OnboardingPage/useOnboardingContext';
import { useGlobalState } from 'context/GlobalState';
import { IbanInput } from 'domains/billing/components';
import { ConfirmSepaDirectDebitDialog } from 'domains/billing/dialogs';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  BankAccount,
  BankAccountNumberType,
  LegalRepresentativeType,
  NetworkErrorCode,
  OrganizationStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import { StyledButtonLink } from './style';

interface State {
  bankAccounts: BankAccount[];
  selectedBankAccount: BankAccount | null | undefined;
  isLoading: boolean;
  iban: string;
  isIbanValid: boolean;
  isAddingBankAccount: boolean;
  isResettingSdd: boolean;
}

const SepaDirectDebitBlock = () => {
  const { t } = useTranslation();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    state: { member },
  } = useGlobalState();
  const showPageError = useShowPageError();
  const {
    actions: { updateOnboarding },
    state: onboardingState,
  } = useOnboardingContext();
  const onboarding = onboardingState.onboarding!;
  const canUser = useCanUser();
  const [isConfirmSddDialogOpen, setIsConfirmSddDialogOpen] = useState(false);
  const [isResetSddDialogOpen, setIsResetSddDialogOpen] = useState(false);
  const [state, setState] = useState<State>({
    bankAccounts: [],
    selectedBankAccount: null,
    isLoading: true,
    iban: '',
    isIbanValid: false,
    isAddingBankAccount: false,
    isResettingSdd: false,
  });
  const didUserConfirmSDDMandate = !!onboarding.directDebitActivationConfirmations?.some(
    (confirmation) => confirmation.memberId === member.id
  );
  const isFirstToConfirmSDDMandate = !onboarding
    .directDebitActivationConfirmations?.length;

  const getData = async () => {
    try {
      const { bankAccounts } = await api.getBankAccounts(
        onboarding.organizationId
      );
      if (!mounted.current) return;
      const selectedBankAccount = onboarding.directDebitIban
        ? bankAccounts.find(
            (account) =>
              account.accountNumber.number === onboarding.directDebitIban
          )
        : bankAccounts[0];
      setState((prevState) => ({
        ...prevState,
        bankAccounts,
        selectedBankAccount,
        iban: selectedBankAccount?.accountNumber.number || '',
        isIbanValid: false,
        isLoading: false,
      }));
    } catch (error) {
      showPageError();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (
      [LegalRepresentativeType.none, null].includes(
        member.legalRepresentative
      ) &&
      onboarding?.riskAssessmentCompleted &&
      !onboarding.directDebitCompleted
    ) {
      enqueueSnackbar(t('onboardingPage.noneLegalRepToast'), {
        autoHideDuration: null,
      });

      return () => closeSnackbar();
    }
  }, [onboarding?.status]);

  const addBankAccount = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (!state.isIbanValid) {
        // Trigger the blur event on IbanInput to show the validation error
        // when users submit a form by pressing the enter key
        if (document.activeElement instanceof HTMLInputElement)
          document.activeElement.blur();
        return;
      }

      setState((prevState) => ({ ...prevState, isAddingBankAccount: true }));
      const bankAccount = await api.addBankAccountInOnboarding(
        onboarding.organizationId,
        { number: state.iban, type: BankAccountNumberType.iban }
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        bankAccounts: [bankAccount],
        selectedBankAccount: bankAccount,
        isAddingBankAccount: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isAddingBankAccount: false }));
      if (getNetworkErrorCode(error) === NetworkErrorCode.ibanIsAlreadyInUse) {
        enqueueSnackbar(t('sepaDirectDebitBlock.ibanInUseError'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    }
  };

  const resetSddMandate = async () => {
    try {
      setState((prevState) => ({ ...prevState, isResettingSdd: true }));
      const updatedOnboarding = await api.resetSddMandate(
        onboarding.organizationId
      );

      if (!mounted.current) return;
      updateOnboarding(updatedOnboarding);
      setState((prevState) => ({ ...prevState, isResettingSdd: false }));
      setIsResetSddDialogOpen(false);
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isResettingSdd: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const getSddBlockState = () => {
    if (state.isLoading) return 'disabled';

    if (onboarding.directDebitCompleted || didUserConfirmSDDMandate) {
      return 'completed';
    }

    if (onboarding.riskAssessmentCompleted) return 'active';

    return 'disabled';
  };

  const canEditIban = canUser('onboarding-step-sdd:edit-iban');

  return (
    <OnboardingBlockWrapper
      title={t('sepaDirectDebitBlock.title')}
      state={getSddBlockState()}
      testId="onboarding-sdd-block"
      textButton={
        canUser('onboarding:revert') &&
        [
          OrganizationStatus.onboardingDirectDebit,
          OrganizationStatus.onboardingCompliance,
        ].includes(onboarding.status) &&
        !!onboarding.directDebitActivationConfirmations?.length && (
          <StyledButtonLink
            onClick={(e) => {
              e.stopPropagation();
              setIsResetSddDialogOpen(true);
            }}
          >
            {t('int.onboardingResetSddStepDialog.openButton')}
          </StyledButtonLink>
        )
      }
    >
      <MailDescription $mb={20}>
        {onboarding.bankConnectionRequired
          ? t('sepaDirectDebitBlock.bankConnectionRequiredDescription')
          : t('sepaDirectDebitBlock.description')}
      </MailDescription>

      {!state.isLoading &&
        (onboarding.bankConnectionRequired ? (
          <Box width="570px">
            <Select
              label={t('sepaDirectDebitBlock.chooseAccount')}
              disabled={
                onboarding.directDebitCompleted ||
                !isFirstToConfirmSDDMandate ||
                !canEditIban
              }
              value={state.selectedBankAccount?.id || ''}
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  selectedBankAccount: state.bankAccounts.find(
                    (item) => item.id === e.target.value
                  ),
                }));
              }}
              renderValue={() =>
                state.selectedBankAccount
                  ? `${state.selectedBankAccount.accountNumber.number} (${state.selectedBankAccount.bankName})`
                  : ''
              }
            >
              {state.bankAccounts.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <ListItemText
                    primary={item.accountNumber.number}
                    secondary={item.bankName}
                  />
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          <form onSubmit={addBankAccount}>
            <Box display="flex" alignItems="flex-start" minHeight="100px">
              <Box maxWidth="430px">
                <IbanInput
                  value={state.iban}
                  onChange={(iban, isIbanValid) =>
                    setState((prevState) => ({
                      ...prevState,
                      iban,
                      isIbanValid,
                    }))
                  }
                  disabled={
                    !isFirstToConfirmSDDMandate ||
                    onboarding.directDebitCompleted ||
                    state.isAddingBankAccount ||
                    !canEditIban
                  }
                />
              </Box>
              <Box position="relative" m="24px 0 0 14px">
                {isFirstToConfirmSDDMandate && (
                  <Button
                    disabled={
                      onboarding.directDebitCompleted ||
                      state.isAddingBankAccount ||
                      !state.iban ||
                      state.iban ===
                        state.selectedBankAccount?.accountNumber.number ||
                      !canEditIban
                    }
                    type="submit"
                  >
                    {t('common.button.save')}
                  </Button>
                )}
                {state.isAddingBankAccount && (
                  <LoaderWithOverlay size={22} thickness={3} />
                )}
              </Box>
            </Box>
          </form>
        ))}

      <Box textAlign="right">
        <Box position="relative" display="inline-block">
          <Button
            disabled={
              !canUser('onboarding-step-sdd:pass') ||
              !state.selectedBankAccount ||
              didUserConfirmSDDMandate ||
              onboarding.directDebitCompleted
            }
            onClick={() => setIsConfirmSddDialogOpen(true)}
            data-test-id="confirm-sepa-mandate-btn"
          >
            {t('sepaDirectDebitBlock.confirmButton')}
          </Button>
        </Box>
      </Box>

      <ConfirmDialog
        title={t('int.onboardingResetSddStepDialog.title')}
        description={t('int.onboardingResetSddStepDialog.description')}
        open={isResetSddDialogOpen}
        onClose={() => setIsResetSddDialogOpen(false)}
        onSuccess={resetSddMandate}
        loading={state.isResettingSdd}
      />

      <ConfirmSepaDirectDebitDialog
        open={isConfirmSddDialogOpen}
        isInOnboarding={true}
        bankAccount={state.selectedBankAccount!}
        onClose={() => setIsConfirmSddDialogOpen(false)}
        onSuccess={(onboarding) => {
          updateOnboarding(onboarding!);
          setIsConfirmSddDialogOpen(false);
        }}
      />
    </OnboardingBlockWrapper>
  );
};

export default SepaDirectDebitBlock;
