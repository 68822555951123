import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  OnboardingTaskActions,
  TaskTitle,
} from 'domains/onboarding/components';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  OnboardingGroup,
  OnboardingItemStatus,
  OnboardingTaskPepFatca,
  TaskNavigationPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg } from 'services/utils';

export interface Props extends TaskNavigationPropsBase {
  task: OnboardingTaskPepFatca;
  group: OnboardingGroup | null;
  onUpdate: (newTask: OnboardingTaskPepFatca) => void;
}

const PepFatcaTask = ({
  isReadOnly,
  task,
  group,
  taskNavigationItems,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { useUpdatePepFatca } = useTanstackQuery();
  const { mutate: orgPepFatcaMutate } = useUpdatePepFatca({
    onSuccess: (response) => {
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });

  const {
    data: { fatca, pep },
  } = task;

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      fatca: fatca.value,
      pep: pep.value,
    },
    onSubmit: (values) => {
      orgPepFatcaMutate({
        taskId: task.id,
        data: {
          fatca: values.fatca!,
          pep: values.pep!,
        },
      });
    },
  });

  return (
    <>
      <PageTitle pt={0} title={<TaskTitle task={task} />} />

      <Box mb={5} display="flex" alignItems="center">
        <Typography variant="body2" color="textSecondary">
          {t('orgOnboardingPepFatcaTask.description')}
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl
            disabled={isReadOnly || formik.isSubmitting || !pep.editable}
          >
            <FormLabel>
              {t('orgOnboardingPepFatcaTask.pepLabel', {
                name: group?.name || '',
              })}
            </FormLabel>
            <RadioGroup
              row
              {...formik.getFieldProps('pep')}
              onChange={(_, value) =>
                formik.setFieldValue('pep', value === 'true')
              }
            >
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={t('common.no')}
              />
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={t('common.yes')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            disabled={isReadOnly || formik.isSubmitting || !fatca.editable}
          >
            <FormLabel>
              {t('orgOnboardingPepFatcaTask.fatcaLabel', {
                name: group?.name || '',
              })}
            </FormLabel>
            <RadioGroup
              row
              {...formik.getFieldProps('fatca')}
              onChange={(_, value) =>
                formik.setFieldValue('fatca', value === 'true')
              }
            >
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={t('common.no')}
              />
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={t('common.yes')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Box mt={4}>
        <OnboardingTaskActions
          isReadOnly={isReadOnly || !pep.editable || !fatca.editable}
          taskNavigationItems={taskNavigationItems}
          disabled={
            formik.isSubmitting ||
            (task.status !== OnboardingItemStatus.requiresAction &&
              !formik.dirty) ||
            typeof formik.values.fatca !== 'boolean' ||
            typeof formik.values.pep !== 'boolean'
          }
          onSuccess={formik.handleSubmit}
        />
      </Box>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default PepFatcaTask;
