import { useTranslation } from 'react-i18next';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { internalOrgPaths } from 'components/App';
import { PageContent, PageNavItem } from 'components/Page';
import { useGlobalState } from 'context/GlobalState';
import { CreditAndCompliancePageHeader } from 'domains/creditAndCompliance/components';
import useHeaderBorderOnScroll from 'hooks/useBorderOnScroll';
import BankSubmissionSubPage from './BankSubmissionSubPage';
import CompanySubPage from './CompanySubPage';
import ComplianceAssessmentSubPage from './ComplianceAssessmentSubPage';
import ComplianceQaSubPage from './ComplianceQaSubPage';
import IndividualsSubPage from './IndividualsSubPage';

const CompliancePage = () => {
  const { t } = useTranslation();
  const { onContentScroll } = useHeaderBorderOnScroll();
  const { path } = useRouteMatch();
  const {
    state: { organization },
  } = useGlobalState();

  return (
    <>
      <CreditAndCompliancePageHeader>
        <PageNavItem
          to={generatePath(internalOrgPaths.complianceCompany, {
            orgId: organization!.id,
          })}
        >
          {t('int.compliancePage.company')}
        </PageNavItem>
        <PageNavItem
          to={generatePath(internalOrgPaths.complianceIndividuals, {
            orgId: organization!.id,
          })}
        >
          {t('int.compliancePage.individuals')}
        </PageNavItem>
        <PageNavItem
          to={generatePath(internalOrgPaths.complianceQa, {
            orgId: organization!.id,
          })}
        >
          {t('int.compliancePage.qa')}
        </PageNavItem>
        <PageNavItem
          to={generatePath(internalOrgPaths.complianceAssessment, {
            orgId: organization!.id,
          })}
        >
          {t('int.compliancePage.assessment')}
        </PageNavItem>
        <PageNavItem
          to={generatePath(internalOrgPaths.complianceBankSubmission, {
            orgId: organization!.id,
          })}
        >
          {t('int.compliancePage.bankSubmission')}
        </PageNavItem>
      </CreditAndCompliancePageHeader>

      <PageContent onScroll={onContentScroll}>
        <Switch>
          <Route path={`${path}/company`} component={CompanySubPage} />
          <Route path={`${path}/individuals`} component={IndividualsSubPage} />
          <Route path={`${path}/qa`} component={ComplianceQaSubPage} />
          <Route
            path={`${path}/assessment`}
            component={ComplianceAssessmentSubPage}
          />
          <Route
            path={`${path}/bank-submission`}
            component={BankSubmissionSubPage}
          />

          <Redirect to={`${path}/company`} />
        </Switch>
      </PageContent>
    </>
  );
};

export default CompliancePage;
