import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { cardholderPaths } from 'components/App';
import withPageErrorWrapper from 'hoc/withPageErrorWrapper';
import { PageContent, PageHeader, PageTitle, Tab, Tabs } from 'layout';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';
import ChCardBenefitsSubpage from './ChCardBenefitsSubpage';
import PartnerDealsSubpage from './PartnerDealsSubpage';

const useTabs = () => {
  const { t, i18n } = useTranslation();
  const { partnerDealsEnabled } = useFlags();
  const canUser = useCanUser();

  return useMemo(() => {
    const tabs = [];

    if (canUser('ch-card-benefits-page:visit')) {
      tabs.push({
        label: t('chRewardsPage.cardBenefits'),
        path: cardholderPaths.rewardsCardBenefits,
        Component: ChCardBenefitsSubpage,
      });
    }

    if (partnerDealsEnabled) {
      tabs.push({
        label: t('chRewardsPage.partnerDeals'),
        path: cardholderPaths.rewardsPartnerDeals,
        Component: PartnerDealsSubpage,
      });
    }

    return tabs;
  }, [i18n.language, canUser, partnerDealsEnabled]);
};

const CardholderRewardsPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const tabs = useTabs();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    contentRef.current?.scrollTo({ top: 0 });
  }, [pathname]);

  if (!tabs.length) return <Redirect to={cardholderPaths.wallet} />;

  return (
    <>
      <PageHeader>
        <PageTitle title={t('chRewardsPage.title')} />
      </PageHeader>

      <Tabs
        value={tabs.some((item) => item.path === pathname) ? pathname : false}
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.divider}`,
          mx: 5,
        })}
      >
        {tabs.map(({ path, label }) => (
          <Tab
            key={path}
            component={Link}
            value={path}
            to={path}
            label={label}
          />
        ))}
      </Tabs>

      <PageContent ref={contentRef} pt={5}>
        <Switch>
          {tabs.map(({ path, Component }) => (
            <Route key={path} path={path} component={Component} />
          ))}
          <Redirect to={tabs[0].path} />
        </Switch>
      </PageContent>
    </>
  );
};

export default withPageErrorWrapper(CardholderRewardsPage);
