import { ReactNode } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getStripeCardStatus } from 'domains/member/utils';
import {
  Box,
  CreditCardIcon,
  ListItem,
  ListItemIcon,
  ListItemTextReversed,
  StatusBadge,
  Tooltip,
  Typography,
} from 'elements';
import {
  PaymentMethod,
  StripeCardFunding,
  StripeCardStatus,
} from 'services/constants';

interface Props {
  paymentMethod: PaymentMethod;
  secondaryAction?: ReactNode;
  showTooltip?: boolean;
}

const PrivatePaymentCard = ({
  paymentMethod,
  secondaryAction,
  showTooltip = false,
}: Props) => {
  const { t } = useTranslation();

  const status = getStripeCardStatus(paymentMethod);

  return (
    <ListItem secondaryAction={secondaryAction} component="div" disableGutters>
      <ListItemIcon>
        <CreditCardIcon sx={{ color: 'text.primary' }} />
      </ListItemIcon>
      <ListItemTextReversed
        sx={{ flexGrow: 0 }}
        primary={
          <>
            <Typography variant="inherit" component="span">
              {t(`privatePaymentCard.brands.${paymentMethod.brand}`)} ****{' '}
              {paymentMethod.lastFourDigits}
            </Typography>{' '}
            <Typography
              variant="inherit"
              component="span"
              color="text.secondary"
            >
              ({moment.utc(paymentMethod.expiryDate).format('MM/YY')})
            </Typography>
          </>
        }
        secondary={t(
          `privatePaymentCard.fundings.${
            paymentMethod.funding || StripeCardFunding.credit
          }`
        )}
      />
      {status && (
        <Tooltip
          title={
            showTooltip &&
            status === StripeCardStatus.declined &&
            t('profilePage.privatePaymentCard.declinedCardTooltip', {
              count: paymentMethod.failedPaymentAttemptsAmount,
            })
          }
        >
          <Box ml={3} lineHeight="1">
            <StatusBadge
              color="error"
              variant="filled"
              label={t(`privatePaymentCard.statuses.${status}`)}
              sx={{ verticalAlign: 'top' }}
            />
          </Box>
        </Tooltip>
      )}
    </ListItem>
  );
};

export default PrivatePaymentCard;
