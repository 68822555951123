import { Fragment, ReactNode, useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MerchantCategoryIcon } from 'domains/merchant/components';
import { AccountingAutocomplete } from 'domains/transaction/components';
import AutoMatchedSelectIndicator from 'domains/transaction/pages/TransactionDetailsPage/AutoMatchedSelectIndicator';
import { Box, Typography } from 'elements';
import {
  AccountingItemStatus,
  merchantCategories,
  MerchantCategory,
} from 'services/constants';

const RenderedMainCategoryValue = ({
  selectedCategory,
  label,
}: {
  selectedCategory: MerchantCategory;
  label: ReactNode;
}) => {
  return (
    <Box
      overflow="hidden"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      <MerchantCategoryIcon sx={{ fontSize: 20 }} category={selectedCategory} />
      <Typography
        variant="body2"
        noWrap
        component="div"
        data-test-id="category-name"
        ml={1}
      >
        {label}
      </Typography>
    </Box>
  );
};

interface MappedOption {
  id: string;
  category: MerchantCategory;
  isSubcategory: boolean;
  name: string;
  number: string | null;
}

interface Props {
  isAutoMatched: boolean;
  organizationId: string;
  merchantId?: string;
  hideSubcategoryNotSelected?: boolean;
  isExportPage?: boolean;
  onChange: (
    category: MerchantCategory | null,
    subcategoryId: string | null
  ) => void;
  selectedSubcategoryIdProp: string | null;
  selectedCategoryProp?: MerchantCategory;
  disabled: boolean;
}

const SubCategoriesAutocomplete = ({
  isAutoMatched,
  organizationId,
  merchantId,
  hideSubcategoryNotSelected = false,
  isExportPage = false,
  selectedSubcategoryIdProp,
  selectedCategoryProp,
  onChange,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { subcategories, accountingSettings },
  } = useGlobalState();
  const [inputValue, setInputValue] = useState('');
  const canSelectCategory =
    !isExportPage || !accountingSettings?.subcategoryEnabled;

  const selectedSubcategory = useMemo(() => {
    if (!accountingSettings?.subcategoryEnabled) return null;
    return subcategories?.find(
      (item) => item?.id === selectedSubcategoryIdProp
    );
  }, [selectedSubcategoryIdProp, subcategories]);

  const selectedCategory = useMemo(() => {
    return selectedSubcategory?.category || selectedCategoryProp;
  }, [selectedCategoryProp, selectedSubcategory]);

  const getSelectOptions = () => {
    const mappedCategories = merchantCategories.map((category) => ({
      id: '',
      category,
      isSubcategory: false,
      name: t(`merchantCategories.${category}`),
      number: null,
    }));
    const visibleSubcategories =
      (accountingSettings?.subcategoryEnabled &&
        subcategories?.filter(
          (item) =>
            item.status === AccountingItemStatus.active ||
            item?.id === selectedSubcategoryIdProp
        )) ||
      [];
    const mappedSubcategories = visibleSubcategories.map((subcategory) => ({
      id: subcategory?.id,
      category: subcategory.category,
      isSubcategory: true,
      name: subcategory.name,
      number: subcategory.number,
    }));

    const sortedSubcategories = sortBy(mappedSubcategories, (v) =>
      v.name.toLowerCase()
    );
    const initialResult: MappedOption[] = [];

    return mappedCategories.reduce((result: MappedOption[], category) => {
      // need to order categories and subcategories for correct grouping
      const groupedSubcategories = sortedSubcategories.filter(
        (subcategory) => subcategory.category === category.category
      );
      return [
        ...result,
        ...(groupedSubcategories.length
          ? [category, ...groupedSubcategories]
          : [category]),
      ];
    }, initialResult);
  };

  const options = useMemo(() => getSelectOptions(), [
    selectedSubcategoryIdProp,
    subcategories,
  ]);

  const selectedOption = useMemo(() => {
    return (
      options.find((item) =>
        selectedSubcategory
          ? item?.id === selectedSubcategory?.id
          : item.category === selectedCategory
      ) || null
    );
  }, [selectedSubcategory, selectedCategory, options]);

  const renderSelectedValue = () => {
    if (!selectedOption) return '—';

    if (accountingSettings?.subcategoryEnabled && isExportPage) {
      return (
        <Box pl={2}>
          <RenderedMainCategoryValue
            label={selectedSubcategory?.name || '—'}
            selectedCategory={selectedCategory!}
          />
          {selectedSubcategory?.number && (
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              noWrap
              ml="auto"
            >
              {selectedSubcategory.number}
            </Typography>
          )}
        </Box>
      );
    }
    if (accountingSettings?.subcategoryEnabled) {
      return (
        <Box pl={2}>
          <RenderedMainCategoryValue
            label={
              selectedSubcategory?.name ||
              t(`merchantCategories.${selectedCategory}`)
            }
            selectedCategory={selectedCategory!}
          />
          {!hideSubcategoryNotSelected && !selectedSubcategory && (
            <>
              {options.find(
                (option) =>
                  option.category === selectedCategory && option.isSubcategory
              ) ? (
                <Typography
                  component="div"
                  variant="caption"
                  color="textSecondary"
                  noWrap
                >
                  {t('transactionDetails.subcategoryNotSelected')}
                </Typography>
              ) : (
                !disabled && (
                  <Typography
                    component="div"
                    variant="caption"
                    color="textSecondary"
                    noWrap
                  >
                    {t('transactionDetails.missingSubcategory')}
                  </Typography>
                )
              )}
            </>
          )}
        </Box>
      );
    }
    return (
      <Box pl={2}>
        <RenderedMainCategoryValue
          label={t(`merchantCategories.${selectedCategory}`)}
          selectedCategory={selectedCategory!}
        />
      </Box>
    );
  };

  return (
    <Box mb={1}>
      <AccountingAutocomplete<MappedOption>
        label={
          <Box display="flex" alignItems="center">
            <Box overflow="hidden">
              {isExportPage && accountingSettings?.subcategoryEnabled ? (
                <>
                  <Typography variant="body2" noWrap>
                    {t('transactionDetails.subcategory')}
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    variant="caption"
                    color="textSecondary"
                  >
                    {t('transactionDetails.glAccount')}
                  </Typography>
                </>
              ) : (
                <Typography variant="body2" noWrap>
                  {t('transactionDetails.category')}
                </Typography>
              )}
            </Box>

            <AutoMatchedSelectIndicator
              isAutoMatched={isAutoMatched}
              organizationId={organizationId}
              merchantId={merchantId}
            />
          </Box>
        }
        data-test-id="categories-autocomplete"
        placeholder=""
        selectedValueComponent={renderSelectedValue()}
        disabled={disabled}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        value={selectedOption}
        groupBy={!canSelectCategory ? (option) => option.category : undefined}
        renderGroup={
          !canSelectCategory
            ? (params) => {
                return (
                  <Fragment key={params.group}>
                    <Typography
                      component="div"
                      display="flex"
                      alignItems="center"
                      my={1}
                      mx={2}
                      color={
                        params.group === selectedOption?.category &&
                        !selectedOption.isSubcategory
                          ? 'textSecondary'
                          : 'textPrimary'
                      }
                    >
                      <MerchantCategoryIcon
                        category={params.group as MerchantCategory}
                      />
                      <Typography ml={2} noWrap>
                        {t(`merchantCategories.${params.group}`)}
                      </Typography>
                    </Typography>
                    {params.children}
                  </Fragment>
                );
              }
            : undefined
        }
        options={options}
        onChange={(_, option: MappedOption | null) => {
          if (!option) {
            return;
          } else if (
            merchantCategories.includes(option.category) &&
            !option.isSubcategory
          ) {
            onChange(option.category, null);
          } else {
            onChange(option.category, option.id);
          }
        }}
        getOptionLabel={(option: MappedOption) => option.name || ''}
        filterOptions={(options, { inputValue }) =>
          options.filter((option) => {
            const name = option.name.toLowerCase();
            const glNumber = option.number?.toLowerCase();
            const val = inputValue?.toLowerCase();
            return name.includes(val) || glNumber?.includes(val);
          })
        }
        renderOption={(props, option) => {
          // need to avoid case with no subcategory
          if (!canSelectCategory && !option.isSubcategory) return null;
          if (!option.isSubcategory) {
            const InnerPart = (
              <Typography
                component="div"
                display="flex"
                alignItems="center"
                my={1}
                overflow="hidden"
              >
                <MerchantCategoryIcon category={option.category} />
                <Typography ml={2} noWrap>
                  {t(`merchantCategories.${option.category}`)}
                </Typography>
              </Typography>
            );
            return canSelectCategory ? (
              <li {...props}>{InnerPart}</li>
            ) : (
              <Box mx={2}>{InnerPart}</Box>
            );
          }
          let glNumber: string | undefined;
          if (option.number && isExportPage) glNumber = option.number;

          return (
            <li {...props} key={option.id}>
              <Box pl={5} overflow="hidden">
                <Typography noWrap>{option.name}</Typography>
                {glNumber && (
                  <Typography
                    component="div"
                    variant="caption"
                    color="textSecondary"
                    noWrap
                  >
                    {glNumber}
                  </Typography>
                )}
              </Box>
            </li>
          );
        }}
      />
    </Box>
  );
};

export default SubCategoriesAutocomplete;
