import { datadogRum, RumEvent } from '@datadog/browser-rum';
import { AxiosError } from 'axios';
import { env } from 'env';
import { getDatadogContextTags } from './datadogHelpers';

const maskCardSensitiveDataEventParams = (event: RumEvent) => {
  if (event.type === 'resource') {
    if (event.resource.url.includes('meawallet')) {
      event.resource.url = event.resource.url.replace(
        /data=[^&]*/,
        'data=*****'
      );
    }
    if (event.resource.url.includes('pci-api')) {
      event.resource.url = event.resource.url.replace(
        /params=[^&]*/,
        'params=*****'
      );
    }
  }
};

const getPartnerScopes = () =>
  env.REACT_APP_PARTNER_SCOPE ? `.${env.REACT_APP_PARTNER_SCOPE}` : '';

const getServiceName = () =>
  `Web-app.${env.REACT_APP_BUILD_TARGET}${getPartnerScopes()}`;

const envs =
  env.REACT_APP_TARGET_ENV === 'development' ? 'dev' : env.REACT_APP_TARGET_ENV;

export const initializeMonitoring = () => {
  if (env.MODE !== 'production') return;

  datadogRum.init({
    applicationId: '5d2038ef-a6df-43f2-8e18-7459d912947c',
    clientToken: 'pubbe0a1c3159376d29f0766317f91553f6',
    site: 'datadoghq.eu',
    service: getServiceName(),
    env: envs,
    defaultPrivacyLevel: 'mask',
    version: env.REACT_APP_COMMIT,
    allowedTracingOrigins: [
      /https:\/\/api(\.(dev|staging)){0,1}\.(infinnitytest|getpliant)\.com/,
    ],
    beforeSend: (event: RumEvent) => {
      maskCardSensitiveDataEventParams(event);

      if (event?.view) {
        event.context = {
          ...event.context,
          ...getDatadogContextTags(event),
        };
      }
    },
  });
};

export const logError = (error: unknown, context: object = {}) => {
  if (env.MODE !== 'production') return;

  if (error instanceof AxiosError && error.response) {
    // Log more information about network errors
    const {
      request: { responseURL: responseUrl } = {},
      config: { url: configUrl, method },
      status,
      data,
    } = error.response;
    const url = responseUrl || configUrl;
    error.message = `XHR error ${method?.toUpperCase()} ${url}`;
    context = {
      ...context,
      url,
      method,
      status,
      data,
    };
  }

  datadogRum.addError(error, context);
};
