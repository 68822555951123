import { useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { DataItemRow } from 'domains/creditAndCompliance/components';
import { getLatestUpdateMetaOfValues } from 'domains/creditAndCompliance/utils';
import { FormControlLabel, Grid, Switch, Typography } from 'elements';
import { RelatedPerson, UboType } from 'services/constants';

interface Props {
  rep: RelatedPerson;
}

const View = ({ rep }: Props) => {
  const { t } = useTranslation();
  const [showAllLogs, setShowAllLogs] = useState(false);

  return (
    <Grid container direction="column" spacing={2} py={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" marginTop={1}>
          {t('int.organizationRepresentativeSection.businessBlockTitle')}
        </Typography>
      </Grid>
      <DataItemRow
        label={t('int.organizationRepresentativeSection.legalRepLabel')}
        updatedAt={rep.legalRepType.updatedAt}
        updatedBy={rep.legalRepType.updatedBy}
        value={
          rep.legalRepType.value &&
          t(
            `int.organizationRepresentativeSection.legalRepType.${rep.legalRepType.value}`
          )
        }
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.uboLabel')}
        updatedAt={
          getLatestUpdateMetaOfValues([rep.uboType, rep.companyShare]).updatedAt
        }
        updatedBy={
          getLatestUpdateMetaOfValues([rep.uboType, rep.companyShare]).updatedBy
        }
        value={
          rep.uboType.value && (
            <Trans
              i18nKey={`int.organizationRepresentativeSection.uboTypeView.${rep.uboType.value}`}
              components={{
                ...(rep.uboType.value === UboType.shareholding && {
                  percent:
                    typeof rep.companyShare.value === 'number' ? (
                      <FormatPercent value={rep.companyShare.value * 100} />
                    ) : (
                      <span>-</span>
                    ),
                }),
              }}
            />
          )
        }
      />

      <Grid item xs={12} display="flex" justifyContent="space-between">
        <Typography variant="subtitle1" marginTop={1}>
          {t('int.organizationRepresentativeSection.personalBlockTitle')}
        </Typography>
        <FormControlLabel
          checked={showAllLogs}
          control={<Switch size="medium" />}
          label={t('int.organizationRepresentativeSection.showLogs')}
          labelPlacement="start"
          onChange={() => setShowAllLogs((prevShowAllLogs) => !prevShowAllLogs)}
        />
      </Grid>
      <DataItemRow
        label={t('int.organizationRepresentativeSection.addressLabel')}
        updatedAt={
          getLatestUpdateMetaOfValues([
            rep.city,
            rep.countryCode,
            rep.postalCode,
            rep.street,
            rep.streetNumber,
          ]).updatedAt
        }
        updatedBy={
          getLatestUpdateMetaOfValues([
            rep.city,
            rep.countryCode,
            rep.postalCode,
            rep.street,
            rep.streetNumber,
          ]).updatedBy
        }
        value={
          <span>
            {rep.street.value} {rep.streetNumber.value}
            <br />
            {rep.postalCode.value} {rep.city.value} <br />
            {rep.countryCode.value && t(`countries.${rep.countryCode.value}`)}
          </span>
        }
        alwaysShowMetaInfo={showAllLogs}
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.citizenshipLabel')}
        updatedAt={rep.citizenships.updatedAt}
        updatedBy={rep.citizenships.updatedBy}
        value={rep.citizenships.value.join(',')}
        alwaysShowMetaInfo={showAllLogs}
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.dateOfBirthLabel')}
        updatedAt={rep.dateOfBirth.updatedAt}
        updatedBy={rep.dateOfBirth.updatedBy}
        value={
          rep.dateOfBirth.value &&
          moment(rep.dateOfBirth.value).format('DD.MM.YYYY')
        }
        alwaysShowMetaInfo={showAllLogs}
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.placeOfBirthLabel')}
        updatedAt={rep.placeOfBirth.updatedAt}
        updatedBy={rep.placeOfBirth.updatedBy}
        value={rep.placeOfBirth.value}
        alwaysShowMetaInfo={showAllLogs}
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.countryOfBirthLabel')}
        updatedAt={rep.countryOfBirth.updatedAt}
        updatedBy={rep.countryOfBirth.updatedBy}
        value={
          rep.countryOfBirth.value && t(`countries.${rep.countryOfBirth.value}`)
        }
        alwaysShowMetaInfo={showAllLogs}
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.fatcaLabel')}
        updatedAt={rep.isFatca.updatedAt}
        updatedBy={rep.isFatca.updatedBy}
        value={
          typeof rep.isFatca.value !== 'boolean'
            ? null
            : rep.isFatca.value
            ? 'Yes'
            : 'No'
        }
        alwaysShowMetaInfo={showAllLogs}
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.pepLabel')}
        updatedAt={rep.isPep.updatedAt}
        updatedBy={rep.isPep.updatedBy}
        value={
          typeof rep.isPep.value !== 'boolean'
            ? null
            : rep.isPep.value
            ? 'Yes'
            : 'No'
        }
        alwaysShowMetaInfo={showAllLogs}
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.tinLabel')}
        updatedAt={
          getLatestUpdateMetaOfValues(
            rep.taxResidences.flatMap((item) => Object.values(item))
          ).updatedAt
        }
        updatedBy={
          getLatestUpdateMetaOfValues(
            rep.taxResidences.flatMap((item) => Object.values(item))
          ).updatedBy
        }
        value={rep.taxResidences.map((item) => (
          <span key={item.taxId.value}>
            {`${item.taxId.value} (${item.taxResidence.value})`}
            <br />
          </span>
        ))}
        alwaysShowMetaInfo={showAllLogs}
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.emailLabel')}
        updatedAt={rep.email.updatedAt}
        updatedBy={rep.email.updatedBy}
        value={rep.email.value}
        alwaysShowMetaInfo={showAllLogs}
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.mobileLabel')}
        updatedAt={rep.mobileNumber.updatedAt}
        updatedBy={rep.mobileNumber.updatedBy}
        value={rep.mobileNumber.value}
        alwaysShowMetaInfo={showAllLogs}
      />

      <Grid item xs={12}>
        <Typography variant="subtitle1" marginTop={1}>
          {t('int.organizationRepresentativeSection.legitimationBlockTitle')}
        </Typography>
      </Grid>
      <DataItemRow
        label={t('int.organizationRepresentativeSection.legitimationDateLabel')}
        updatedAt={rep.legitimation.updatedAt}
        updatedBy={rep.legitimation.updatedBy}
        value={
          rep.legitimation.value.date &&
          moment(rep.legitimation.value.date).format('DD.MM.YYYY')
        }
      />
      <DataItemRow
        label={t(
          'int.organizationRepresentativeSection.legitimationProviderLabel'
        )}
        updatedAt={rep.legitimation.updatedAt}
        updatedBy={rep.legitimation.updatedBy}
        value={
          rep.legitimation.value.provider &&
          t(
            `int.organizationRepresentativeSection.legitimationProvider.${rep.legitimation.value.provider}`
          )
        }
      />
      <DataItemRow
        label={t(
          'int.organizationRepresentativeSection.legitimationDocumentLabel'
        )}
        updatedAt={rep.legitimation.updatedAt}
        updatedBy={rep.legitimation.updatedBy}
        value={
          rep.legitimation.value.document &&
          t(
            `int.organizationRepresentativeSection.legitimationDocument.${rep.legitimation.value.document}`
          )
        }
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.documentIdLabel')}
        updatedAt={rep.legitimation.updatedAt}
        updatedBy={rep.legitimation.updatedBy}
        value={rep.legitimation.value.identificationDocument.documentId}
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.issuingOfficeLabel')}
        updatedAt={rep.legitimation.updatedAt}
        updatedBy={rep.legitimation.updatedBy}
        value={rep.legitimation.value.identificationDocument.issuingOffice}
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.issueDateLabel')}
        updatedAt={rep.legitimation.updatedAt}
        updatedBy={rep.legitimation.updatedBy}
        value={
          rep.legitimation.value.identificationDocument.issuanceDate &&
          moment(
            rep.legitimation.value.identificationDocument.issuanceDate
          ).format('DD.MM.YYYY')
        }
      />
      <DataItemRow
        label={t('int.organizationRepresentativeSection.validityDateLabel')}
        updatedAt={rep.legitimation.updatedAt}
        updatedBy={rep.legitimation.updatedBy}
        value={
          rep.legitimation.value.identificationDocument.validityDate &&
          moment(
            rep.legitimation.value.identificationDocument.validityDate
          ).format('DD.MM.YYYY')
        }
      />

      <Grid item xs={12}>
        <Typography variant="subtitle1" marginTop={1}>
          {t(
            'int.organizationRepresentativeSection.complianceScreeningBlockTitle'
          )}
        </Typography>
      </Grid>
      <DataItemRow
        label={t('int.complianceScreening.sanctionLabel')}
        updatedAt={rep.kyc.updatedAt}
        updatedBy={rep.kyc.updatedBy}
        value={
          <>
            {rep.kyc.value.sanctionListCheck.result && (
              <span>{rep.kyc.value.sanctionListCheck.result}</span>
            )}
            {rep.kyc.value.sanctionListCheck.checkDate && (
              <span>
                {` - ${moment(rep.kyc.value.sanctionListCheck.checkDate).format(
                  'DD.MM.YYYY'
                )}`}
              </span>
            )}
          </>
        }
      />
      <DataItemRow
        label={t('int.complianceScreening.amlLabel')}
        updatedAt={rep.kyc.updatedAt}
        updatedBy={rep.kyc.updatedBy}
        value={
          <>
            {rep.kyc.value.amlRiskScoring.result && (
              <span>{rep.kyc.value.amlRiskScoring.result}</span>
            )}
            {rep.kyc.value.amlRiskScoring.checkDate && (
              <span>
                {` - ${moment(rep.kyc.value.amlRiskScoring.checkDate).format(
                  'DD.MM.YYYY'
                )}`}
              </span>
            )}
          </>
        }
      />
    </Grid>
  );
};

export default View;
