import { useState } from 'react';
import { camelCase } from 'lodash';
import { FileError, FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Avatar,
  FilesIcon,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  LoaderWithOverlay,
  Paper,
  TrashIcon,
  Typography,
  XIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OnboardingDocumentFile } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { downloadResponseAsFile, getGenericErrorMsg } from 'services/utils';
import formatBytes from 'services/utils/formatBytes';

type SelectedFileProps = {
  version: 'selected';
  file: FileWithPath;
  documentId: string;
  downloadEnabled?: boolean;
  isLoading: boolean;
  uploadingProgress?: number;
  disabled: boolean;
  onDelete: () => void;
};

interface UploadedFileProps {
  version: 'uploaded';
  file: OnboardingDocumentFile;
  documentId: string;
  downloadEnabled?: boolean;
  isLoading: boolean;
  disabled: boolean;
  onDelete: () => void;
}

interface RejectedFileProps {
  version: 'error';
  errors: FileError[];
  file: FileWithPath;
  documentId: string;
  downloadEnabled?: boolean;
  isLoading: boolean;
  disabled: boolean;
  maxFileSizeInBytes: number;
}

const FileRow = ({
  version,
  file,
  documentId,
  isLoading,
  disabled,
  ...props
}: UploadedFileProps | SelectedFileProps | RejectedFileProps) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [isDownloading, setIsDownloading] = useState(false);
  const {
    state: { organization },
  } = useGlobalState();
  const canUser = useCanUser();

  const handleDownloadFile = async () => {
    if (
      version !== 'uploaded' ||
      !canUser('org-onboarding-document-files:manage')
    ) {
      return;
    }

    try {
      setIsDownloading(true);

      const response = await api.downloadOnboardingDocumentFile(
        organization!.id,
        documentId,
        file.id
      );

      if (!mounted) return;
      downloadResponseAsFile(response);
      setIsDownloading(false);
    } catch (error) {
      if (!mounted) return;
      setIsDownloading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <ListItem
      component={Paper}
      variant="outlined"
      sx={{ mt: 1 }}
      secondaryAction={
        !disabled &&
        'onDelete' in props && (
          <IconButton
            edge="end"
            aria-label="delete"
            disabled={isLoading}
            onClick={props.onDelete}
          >
            {version === 'uploaded' ? (
              <TrashIcon fontSize="small" />
            ) : (
              <XIcon fontSize="small" />
            )}
          </IconButton>
        )
      }
    >
      <Link
        onClick={handleDownloadFile}
        underline="none"
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          marginRight: 'auto',
          pointerEvents:
            !canUser('org-onboarding-document-files:manage') || isDownloading
              ? 'none'
              : 'auto',
          position: 'relative',
        }}
      >
        <ListItemAvatar>
          <Avatar variant="rounded">
            <FilesIcon />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={file.name}
          secondary={formatBytes(
            version === 'uploaded' ? file.sizeInBytes : file.size
          )}
        />

        <LoaderWithOverlay loading={isDownloading} />
      </Link>

      {isLoading &&
        version === 'selected' &&
        'uploadingProgress' in props &&
        typeof props.uploadingProgress === 'number' && (
          <Typography
            component="span"
            variant="body2"
            color="textSecondary"
          >{` (${props.uploadingProgress}%)`}</Typography>
        )}

      {version === 'error' && 'errors' in props && (
        <Typography color="error.main" variant="caption">
          {props.errors.map((e) => (
            <span key={e.code}>
              {t(`errors.${camelCase(e.code)}`, {
                maxFileSize: formatBytes(props.maxFileSizeInBytes),
              })}
            </span>
          ))}
        </Typography>
      )}
    </ListItem>
  );
};

export default FileRow;
