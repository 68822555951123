import { forwardRef, useRef } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box, Divider, FilePdfIcon, IconButton, Typography } from 'elements';
import KycInformationSection from './kycInformationSection';
import QandASection from './qAndASection';
import SddMandateSection from './sddMandateSection';
import TermsAndConditionsSection from './termsAndConditionsSection';

const CustomerDeclarations = forwardRef(function CustomerDeclarations(_, ref) {
  const { t } = useTranslation();
  const {
    state: { complianceCompany },
  } = useCreditAndComplianceContext();
  const masterData = complianceCompany!.masterData;

  return (
    <Box
      ref={ref}
      sx={{
        height: 'initial !important',
        overflow: 'initial !important',
        maxWidth: '1240px',
      }}
    >
      <Typography variant="h5" mb={0.5}>
        {t('int.customerDeclarations.title')}
      </Typography>
      <Typography mb={2}>
        {t('int.customerDeclarations.description')}
      </Typography>
      <Box display="flex" mb={0.5}>
        <Typography width="148px">
          {`${t('int.customerDeclarations.companyName')}:`}
        </Typography>
        <Typography>{masterData.legalName.value}</Typography>
      </Box>
      <Box display="flex" mb={0.5}>
        <Typography width="148px">
          {`${t('int.customerDeclarations.registration')}:`}
        </Typography>
        <Typography>
          {[
            masterData.tradeRegisterNumber.value,
            masterData.nationalRegisterCourt.value,
            masterData.country.value,
          ]
            .filter(Boolean)
            .join(', ')}
        </Typography>
      </Box>
      <Box display="flex" mb={0.5}>
        <Typography width="148px">
          {`${t('int.customerDeclarations.billingAddress')}:`}
        </Typography>
        <Typography>
          {masterData.billingAddress.streetName.value}{' '}
          {masterData.billingAddress.streetNumber.value}
          <br />
          {!!masterData.billingAddress.streetAdditional.value && (
            <>
              {masterData.billingAddress.streetAdditional.value} <br />
            </>
          )}
          {masterData.billingAddress.postalCode.value}{' '}
          {masterData.billingAddress.city.value} <br />
          {t(`countries.${masterData.billingAddress.country.value}`)}
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />

      <QandASection />
      <Divider sx={{ my: 2 }} />

      <KycInformationSection />
      <Divider sx={{ my: 2 }} />

      <TermsAndConditionsSection />
      <Divider sx={{ my: 2 }} />

      <SddMandateSection />
    </Box>
  );
});

const CustomerDeclarationsSection = () => {
  const { t } = useTranslation();
  const customerDeclarationsRef = useRef<HTMLDivElement>(null);
  const {
    state: { organization },
  } = useGlobalState();
  const handlePrint = useReactToPrint({
    content: () => customerDeclarationsRef.current,
    documentTitle: `${moment().format('YYYYMMDD')}_${organization!.name.replace(
      /[\s|.]/g,
      ''
    )}_CustomerDeclarations`,
    pageStyle:
      '@media print { html, body { height: initial !important; overflow: initial !important; }}',
  });

  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        marginBottom={3}
        py={1}
      >
        <Typography mr={1} variant="h6">
          {t('int.customerDeclarations.title')}
        </Typography>

        <IconButton aria-label="print-pdf" onClick={handlePrint}>
          <FilePdfIcon />
        </IconButton>
      </Box>

      <Box display="none">
        <CustomerDeclarations ref={customerDeclarationsRef} />
      </Box>
    </>
  );
};

export default CustomerDeclarationsSection;
