import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from 'elements';
import { CardMerchantControl } from 'services/constants';
import RestrictionBadge from './RestrictionBadge';
import { Header, StyledChip, Wrapper } from './style';

interface Props {
  control: CardMerchantControl | null;
}

const CardMerchantControlIndicator = ({ control }: Props) => {
  const { t } = useTranslation();

  if (!control || !control.values.length) return null;

  const { restriction, type, values } = control;

  return (
    <Wrapper>
      <Header>
        <Typography variant="subtitle2">
          {t(`cardControlIndicator.merchant.titles.${type}`)}
        </Typography>
        <RestrictionBadge restriction={restriction} />
      </Header>

      {values.map((item) => {
        return (
          <Tooltip key={item} title={item}>
            <StyledChip label={item} />
          </Tooltip>
        );
      })}
    </Wrapper>
  );
};

export default memo(CardMerchantControlIndicator);
