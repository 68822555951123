import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { InsuranceDetailsDialog } from 'domains/reward/dialogs';
import { Box, Link, StatusBadge, Typography } from 'elements';

const InsuranceSection = () => {
  const { t } = useTranslation();
  const {
    state: { member },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Box mb={8}>
        <Box mb={1} display="flex" alignItems="center">
          <Typography variant="h6">
            {t('profilePage.insuranceSection.title')}
          </Typography>
          {member.insured && (
            <StatusBadge
              label={t('profilePage.insuranceSection.active')}
              variant="filled"
              color="success"
              sx={{ ml: 1 }}
            />
          )}
        </Box>

        <Typography>
          {member.insured ? (
            <Trans
              i18nKey="profilePage.insuranceSection.enabledDescription"
              components={{
                button: (
                  <Link
                    component="button"
                    onClick={() => setIsDialogOpen(true)}
                  />
                ),
              }}
            />
          ) : (
            t('profilePage.insuranceSection.disabledDescription')
          )}
        </Typography>
      </Box>

      <InsuranceDetailsDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default InsuranceSection;
