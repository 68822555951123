export enum SupportedCountry {
  at = 'AT',
  be = 'BE',
  bg = 'BG',
  ch = 'CH',
  cy = 'CY',
  cz = 'CZ',
  de = 'DE',
  dk = 'DK',
  ee = 'EE',
  es = 'ES',
  fi = 'FI',
  fr = 'FR',
  gb = 'GB',
  gr = 'GR',
  hr = 'HR',
  hu = 'HU',
  ie = 'IE',
  is = 'IS',
  it = 'IT',
  li = 'LI',
  lt = 'LT',
  lu = 'LU',
  lv = 'LV',
  mc = 'MC',
  mt = 'MT',
  nl = 'NL',
  no = 'NO',
  pl = 'PL',
  pt = 'PT',
  ro = 'RO',
  se = 'SE',
  si = 'SI',
  sk = 'SK',
  sl = 'SL',
  tr = 'TR',
  uk = 'UK',
  us = 'US',
}

// sorted asc by english name
export const supportedCountries = [
  SupportedCountry.at,
  SupportedCountry.be,
  SupportedCountry.bg,
  SupportedCountry.hr,
  SupportedCountry.cy,
  SupportedCountry.cz,
  SupportedCountry.dk,
  SupportedCountry.ee,
  SupportedCountry.fi,
  SupportedCountry.fr,
  SupportedCountry.de,
  SupportedCountry.gr,
  SupportedCountry.hu,
  SupportedCountry.is,
  SupportedCountry.ie,
  SupportedCountry.it,
  SupportedCountry.lv,
  SupportedCountry.li,
  SupportedCountry.lt,
  SupportedCountry.lu,
  SupportedCountry.mt,
  SupportedCountry.mc,
  SupportedCountry.nl,
  SupportedCountry.no,
  SupportedCountry.pl,
  SupportedCountry.pt,
  SupportedCountry.ro,
  SupportedCountry.sk,
  SupportedCountry.si,
  SupportedCountry.es,
  SupportedCountry.se,
  SupportedCountry.ch,
  SupportedCountry.uk,
  SupportedCountry.tr,
  SupportedCountry.us,
];
