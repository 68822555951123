import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlagTransactionDialog } from 'domains/transaction/dialogs';
import { isTxFlaggingFrozenByExport } from 'domains/transaction/utils';
import {
  ActionBox,
  ActionBoxActions,
  ActionBoxTitle,
  Button,
  Divider,
} from 'elements';
import { Transaction, TransactionReviewStatus } from 'services/constants';

interface Props {
  transaction: Transaction;
  onUpdate: (reviewStatus: TransactionReviewStatus) => void;
  onApproveTransaction: () => void;
}

const ReviewTransaction = ({
  transaction,
  onApproveTransaction,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const [
    isFlagTransactionDialogOpen,
    setIsFlagTransactionDialogOpen,
  ] = useState(false);
  const {
    transactionId,
    hasMultipleAccountingTransactions,
    memberId: transactionMemberId,
  } = transaction;

  return (
    <>
      <ActionBox>
        <ActionBoxTitle>
          {t('transactionDetails.reviewTransaction.title')}
        </ActionBoxTitle>

        {t('transactionDetails.reviewTransaction.description')}

        <ActionBoxActions>
          <Button
            color="error"
            onClick={() => setIsFlagTransactionDialogOpen(true)}
          >
            {t('transactionDetails.reviewTransaction.flagButton')}
          </Button>
          <Button color="success" onClick={() => onApproveTransaction()}>
            {t('transactionDetails.reviewTransaction.approveButton')}
          </Button>
        </ActionBoxActions>
      </ActionBox>
      <Divider />
      <FlagTransactionDialog
        open={isFlagTransactionDialogOpen}
        isTxFrozenByExport={isTxFlaggingFrozenByExport(transaction)}
        transactionIds={[transactionId]}
        transactionMemberId={transactionMemberId}
        hasMultipleAccountingTransactions={hasMultipleAccountingTransactions}
        onClose={() => setIsFlagTransactionDialogOpen(false)}
        onSuccess={() => {
          onUpdate(TransactionReviewStatus.flagged);
          setIsFlagTransactionDialogOpen(false);
        }}
      />
    </>
  );
};

export default ReviewTransaction;
