import { useCallback, useEffect, useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { MISSING_TEAM_OPTION } from 'domains/transaction/pages/ExportPage/utils';
import {
  isMultiValueMismatched,
  isValueMismatched,
} from 'domains/transaction/utils';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Team, TeamCheck } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

export interface TeamData {
  value: Team | TeamCheck | typeof MISSING_TEAM_OPTION | null;
  invalid: string[] | [];
  isLoading: boolean;
  isError: boolean;
}

export interface TeamsData {
  value: Team[];
  invalid: string[] | [];
  isLoading: boolean;
  isError: boolean;
}

const getInitialState = <T extends string | string[]>(
  isSingleSelect: boolean
) => {
  return (isSingleSelect
    ? { value: null, invalid: [], isLoading: false, isError: false }
    : {
        value: [],
        invalid: [],
        isLoading: false,
        isError: false,
      }) as T extends string ? TeamData : TeamsData;
};

export const useTeamFilter = <T extends string | string[]>(teamParam: T) => {
  const isSingleSelect =
    teamParam === undefined || typeof teamParam === 'string';
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [teamData, setTeamData] = useState(getInitialState<T>(isSingleSelect));

  const getData = async () => {
    if (teamParam === MISSING_TEAM_OPTION) {
      setTeamData((prevState) => ({
        ...prevState,
        value: MISSING_TEAM_OPTION,
      }));
      return;
    }

    setTeamData((prevState) => ({ ...prevState, isLoading: true }));
    try {
      if (!mounted.current) return;
      const { valid, invalid } = await api.checkTeams({
        organizationId: organization!.id,
        teamIds: isSingleSelect ? [teamParam] : teamParam,
      });
      setTeamData((prevState) => ({
        ...prevState,
        value: isSingleSelect ? valid[0] || null : valid,
        invalid: invalid,
        isError: !!invalid.length,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setTeamData((prevState) => ({
        ...prevState,
        isLoading: false,
        isError: true,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), {
        variant: 'error',
      });
      logError(error);
    }
  };

  useEffect(() => {
    // automatically reset state when no param
    if (!teamParam || (!isSingleSelect && !teamParam.length)) {
      setTeamData((prevState) => ({
        ...prevState,
        value: isSingleSelect ? null : [],
        invalid: [],
        isError: false,
      }));
      return;
    }
    // automatically reset error when valid params exist
    else if (
      !isSingleSelect &&
      teamData.invalid.length &&
      !teamParam.includes(teamData.invalid[0])
    ) {
      setTeamData((prevState) => ({
        ...prevState,
        invalid: [],
        isError: false,
      }));
      return;
    }

    const isValueChanged = isSingleSelect
      ? isValueMismatched(teamParam, (teamData as TeamData).value)
      : isMultiValueMismatched(teamParam, (teamData as TeamsData).value);
    if (isValueChanged) getData();
  }, [JSON.stringify(teamParam)]);

  const setTeamFilter = useCallback(
    (data: Pick<TeamData | TeamsData, 'value'>) => {
      setTeamData((prevState) => ({
        ...prevState,
        ...data,
        isError: false,
        invalid: [],
      }));
    },
    []
  );

  return {
    teamData,
    setTeamFilter,
  };
};
