import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { ALL_LANGUAGES } from 'domains/organization/hooks';
import { Box, MenuItem, Select, SelectChangeEvent } from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { SupportedWebAppLanguage } from 'services/constants';
import { updateHtmlLangAttribute } from 'services/i18n';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { isPortalApp } = useCurrentApp();
  const {
    state: { member },
  } = useGlobalState();
  const shownLanguages = isPortalApp
    ? ALL_LANGUAGES.filter((lang) =>
        [SupportedWebAppLanguage.en, SupportedWebAppLanguage.de].includes(
          lang.code
        )
      )
    : ALL_LANGUAGES;

  const onChange = async (e: SelectChangeEvent) => {
    try {
      const languageCode = e.target.value as SupportedWebAppLanguage;
      moment.locale(languageCode);
      await i18n.changeLanguage(languageCode);
      const changeLanguageEndpoint = isPortalApp
        ? api.changePartnerMemberLanguage
        : api.changeMemberLanguage;

      await changeLanguageEndpoint(member.id, languageCode);
      updateHtmlLangAttribute(languageCode);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Box width={220}>
      <Select
        value={i18n.language}
        onChange={onChange}
        renderValue={(selected) =>
          shownLanguages.find((language) => language.code === selected)!.label
        }
      >
        {shownLanguages.map((language) => (
          <MenuItem key={language.code} value={language.code}>
            {language.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default LanguageSelect;
