import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CaretDownIcon,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import {
  PrivateExpenseStatus,
  ReviewFlagReason,
  reviewFlagReasons,
} from 'services/constants';
import { useFlags } from 'services/featureflags';

type Value = ReviewFlagReason | '';

interface Props {
  value: Value;
  onChange: (value: Value) => void;
  privateExpenseStatusesValue: PrivateExpenseStatus[];
  onPrivateExpenseStatusChange: (statuses: PrivateExpenseStatus[]) => void;
}

const privateExpenseStatusesToShow = [
  PrivateExpenseStatus.reimbursementOutstanding,
  PrivateExpenseStatus.reimbursementPending,
  PrivateExpenseStatus.reimbursed,
];

const FlagReasonFilter = ({
  value,
  onChange,
  privateExpenseStatusesValue,
  onPrivateExpenseStatusChange,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();
  const { companyReimbursementEnabled } = useFlags();

  if (!featureModules.PRIVATE_EXPENSE) return null;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-review-flag-reason"
        id="panel-review-flag-reason-header"
      >
        <Typography>{t('filters.flagReason')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<Value>
            size="medium"
            displayEmpty
            value={value}
            onChange={(e) => onChange(e.target.value as Value)}
            renderValue={(selected) => {
              if (!selected)
                return (
                  <Typography color="text.disabled">
                    {t('filters.flagReason')}
                  </Typography>
                );
              return t(`reviewFlagReasons.${selected}`);
            }}
          >
            <MenuItem value="">
              <Typography color="text.disabled">
                {t('filters.flagReason')}
              </Typography>
            </MenuItem>
            {reviewFlagReasons.map((reason) => (
              <MenuItem key={reason} value={reason}>
                {t(`reviewFlagReasons.${reason}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {value === ReviewFlagReason.privateExpense &&
          featureModules.COMPANY_REIMBURSEMENT &&
          companyReimbursementEnabled && (
            <Box mt={2}>
              <FormControl variant="standard">
                <FormGroup>
                  {privateExpenseStatusesToShow.map((status) => (
                    <FormControlLabel
                      key={status}
                      control={
                        <Checkbox
                          value={status}
                          checked={privateExpenseStatusesValue.includes(status)}
                          onChange={(e) => {
                            const values = [
                              e.target.value as PrivateExpenseStatus,
                            ];
                            if (
                              e.target.value === PrivateExpenseStatus.reimbursed
                            ) {
                              values.push(
                                PrivateExpenseStatus.reimbursedOffline
                              );
                            } else if (
                              e.target.value ===
                              PrivateExpenseStatus.reimbursementOutstanding
                            ) {
                              values.push(
                                PrivateExpenseStatus.reimbursementNotSupported
                              );
                            }
                            onPrivateExpenseStatusChange(values);
                          }}
                        />
                      }
                      label={t(`privateExpenseStatuses.${status}`)}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
          )}
      </AccordionDetails>
    </Accordion>
  );
};

export default FlagReasonFilter;
