import {
  CardConfigSetting,
  CardConfigSettingList,
  CardMerchantControlType,
} from 'services/constants';

// Hardcoded logic for Telekom
const addValidityPeriod = (
  config: CardConfigSetting,
  requestCardForAnotherMemberEnabled: boolean
): CardConfigSetting => {
  if (requestCardForAnotherMemberEnabled) {
    return {
      ...config,
      ...(config.maxUsage === 1 && {
        validityPeriodEnabled: true,
        validityPeriodDefaultInDays: 14,
        validityPeriodMaxInDays: 14,
        cardControls: {
          ...config.cardControls,
          merchants: {
            [CardMerchantControlType.mid]: {
              enabled: false,
              editable: false,
              required: false,
            },
            [CardMerchantControlType.matchTerm]: {
              enabled: true,
              editable: true,
              required: true,
            },
          },
        },
      }),
    };
  }

  return config;
};

export const transformCardConfigs = (
  data: CardConfigSettingList,
  requestCardForAnotherMemberEnabled: boolean
): CardConfigSettingList => {
  return {
    ...data,
    cardConfigs: data.cardConfigs.map((config) =>
      addValidityPeriod(config, requestCardForAnotherMemberEnabled)
    ),
  };
};
