import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { env } from 'env';
import { PartnerIds } from 'services/constants';

const partnerBaseUrl = `${env.REACT_APP_ASSETS_URL}/images/partners/`;

export const getFaviconFolderUrl = (partnerId: string) => {
  switch (partnerId) {
    case PartnerIds.bizaway:
      return partnerBaseUrl + 'bizzypay';

    case PartnerIds.bonago:
      return partnerBaseUrl + 'bonago';

    case PartnerIds.dzbank:
    case PartnerIds.vrbank:
      return partnerBaseUrl + 'dz';

    case PartnerIds.intertours:
      return partnerBaseUrl + 'intertours';

    default: {
      if (env.REACT_APP_TARGET_ENV === 'development') {
        return `${env.REACT_APP_ASSETS_URL}/images/dev`;
      }

      if (env.REACT_APP_TARGET_ENV === 'staging') {
        return `${env.REACT_APP_ASSETS_URL}/images/staging`;
      }

      return `${env.REACT_APP_ASSETS_URL}/images/v2`;
    }
  }
};

interface Props {
  title: string;
  partnerId: string;
}

// This is a hacky workaround to dynamically change favicons at runtime.
// This logic should be moved to the build process.
// Favicons must be updated directly in the <head> of index.html during build.
const HelmetTitleAndFavicons = ({ title, partnerId }: Props) => {
  const faviconFolderUrl = getFaviconFolderUrl(partnerId);

  return (
    <Helmet>
      <title>{title}</title>
      <link
        type="image/x-icon"
        rel="shortcut icon"
        href={`${faviconFolderUrl}/favicon.ico`}
      />
      <link
        type="image/png"
        sizes="16x16"
        rel="icon"
        href={`${faviconFolderUrl}/favicon-16x16.png`}
      />
      <link
        type="image/png"
        sizes="32x32"
        rel="icon"
        href={`${faviconFolderUrl}/favicon-32x32.png`}
      />
      <link
        type="image/png"
        sizes="96x96"
        rel="icon"
        href={`${faviconFolderUrl}/favicon-96x96.png`}
      />
    </Helmet>
  );
};

export default memo(HelmetTitleAndFavicons);
