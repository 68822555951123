import { useFormik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  DEFAULT_CUTOFF_DAY_BY_FREQUENCY,
  PAYMENT_FREQUENCY_CUTOFF_VISIBLE_DAYS,
} from 'domains/creditAndCompliance/constants';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  PaymentFrequency,
  PaymentFrequencyType,
  paymentFrequencyTypes,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const getDisabledOptions = (minVal: PaymentFrequencyType) => {
  return paymentFrequencyTypes.slice(0, paymentFrequencyTypes.indexOf(minVal));
};

interface FormValues {
  paymentFrequency: PaymentFrequencyType;
  paymentFrequencyCutoffDay: number | null;
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (paymentFrequency: PaymentFrequency) => void;
  paymentFrequency: PaymentFrequency;
}

const ChangeCurrPaymentFrequencyDialog = ({
  paymentFrequency,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();

  const disabledOptions = getDisabledOptions(paymentFrequency.min.value);

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      paymentFrequency: paymentFrequency.current.value,
      paymentFrequencyCutoffDay: paymentFrequency.current.cutoffDay,
    },
    onSubmit: async (values) => {
      try {
        const paymentFrequency = await api.updateCurrPaymentFrequency(
          organization!.id,
          values.paymentFrequency,
          values.paymentFrequencyCutoffDay
        );
        if (!mounted.current) return;
        onSuccess(paymentFrequency);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('int.changeCurrPaymentFrequencyDialog.title')}
      </DialogTitle>
      <DialogContent>
        <form
          id="change-payment-frequency-form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <FormControl fullWidth disabled={formik.isSubmitting}>
            <InputLabel id="payment-frequency-select-label">
              {t('int.changeCurrPaymentFrequencyDialog.paymentFrequencyLabel')}
            </InputLabel>
            <Select<PaymentFrequencyType>
              labelId="payment-frequency-select-label"
              value={formik.values.paymentFrequency}
              onChange={(e) => {
                const val = e.target.value as PaymentFrequencyType;
                formik.setFieldValue('paymentFrequency', val);
                formik.setFieldValue(
                  'paymentFrequencyCutoffDay',
                  DEFAULT_CUTOFF_DAY_BY_FREQUENCY[val]
                );
              }}
            >
              {paymentFrequencyTypes.map((value) => (
                <MenuItem
                  key={value}
                  value={value}
                  disabled={disabledOptions.includes(value)}
                >
                  {t(`paymentFrequencyTypes.${value}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {[
            PaymentFrequencyType.MONTHLY,
            PaymentFrequencyType.SEMI_MONTHLY,
          ].includes(formik.values.paymentFrequency) && (
            <FormControl
              fullWidth
              disabled={formik.isSubmitting}
              sx={{ mt: 3 }}
            >
              <InputLabel id="payment-frequency-cutoff-day-select-label">
                {t(
                  'int.changeCurrPaymentFrequencyDialog.paymentFrequencyCutoffDayLabel'
                )}
              </InputLabel>
              <Select<number>
                labelId="payment-frequency-cutoff-day-select-label"
                value={formik.values.paymentFrequencyCutoffDay ?? 0}
                onChange={(e) => {
                  formik.setFieldValue(
                    'paymentFrequencyCutoffDay',
                    e.target.value
                  );
                }}
              >
                {formik.values.paymentFrequency ===
                  PaymentFrequencyType.MONTHLY && [
                  ...Array.from({ length: 28 }, (_, i) => i + 1).map(
                    (value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    )
                  ),
                  <MenuItem key={0} value={0}>
                    {t('int.changeCurrPaymentFrequencyDialog.endOfMonth')}
                  </MenuItem>,
                ]}
                {formik.values.paymentFrequency ===
                  PaymentFrequencyType.SEMI_MONTHLY && [
                  Array.from({ length: 14 }, (_, i) => i + 1).map((value) => (
                    <MenuItem key={value} value={value}>
                      {value} - {value + 15}
                    </MenuItem>
                  )),
                  <MenuItem key={15} value={15}>
                    {`15 - ${t(
                      'int.changeCurrPaymentFrequencyDialog.endOfMonth'
                    )}`}
                  </MenuItem>,
                ]}
              </Select>
            </FormControl>
          )}

          {(formik.values.paymentFrequency === PaymentFrequencyType.BI_WEEKLY ||
            formik.values.paymentFrequency === PaymentFrequencyType.WEEKLY) && (
            <FormControl
              fullWidth
              disabled={formik.isSubmitting}
              sx={{ mt: 3 }}
            >
              <InputLabel>
                {t(
                  'int.changeCurrPaymentFrequencyDialog.paymentFrequencyCutoffDayLabel'
                )}
              </InputLabel>
              <ButtonGroup
                color="primary"
                variant="outlined"
                fullWidth
                sx={{ mt: 3 }}
              >
                {PAYMENT_FREQUENCY_CUTOFF_VISIBLE_DAYS.map((day) => (
                  <Button
                    key={day}
                    variant={
                      formik.values.paymentFrequencyCutoffDay === day
                        ? 'contained'
                        : undefined
                    }
                    onClick={() =>
                      formik.setFieldValue('paymentFrequencyCutoffDay', day)
                    }
                  >
                    {moment().isoWeekday(day).format('ddd')}
                  </Button>
                ))}
              </ButtonGroup>
            </FormControl>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={formik.isSubmitting}
          form="change-payment-frequency-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(ChangeCurrPaymentFrequencyDialog);
