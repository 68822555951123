import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Overlay, { OverlayProps } from 'components/Overlay';

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey100};
`;

const Results = styled.div`
  margin: 0 0 11px;
  font-size: 12px;
  line-height: 14px;
`;

const NothingFound = (props: OverlayProps) => {
  const { t } = useTranslation();

  return (
    <Overlay {...props}>
      <Container>
        <Results>{t('common.results')}</Results>
        <div>{t('common.nothingFound')}</div>
      </Container>
    </Overlay>
  );
};

export default NothingFound;
