import { useFormik } from 'formik';
import { uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  OnboardingTaskActions,
  TaskTitle,
} from 'domains/onboarding/components';
import { Box, LoaderWithOverlay, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  OnboardingItemStatus,
  OnboardingTaskCitizenships,
  TaskNavigationPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg } from 'services/utils';
import CitizenshipsGroup from './CitizenshipsGroup';

export interface Props extends TaskNavigationPropsBase {
  task: OnboardingTaskCitizenships;
  onUpdate: (newTask: OnboardingTaskCitizenships) => void;
  hideAddButton?: boolean;
}

const CitizenshipsTask = ({
  isReadOnly,
  task,
  taskNavigationItems,
  onUpdate,
  hideAddButton = true,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { useUpdateCitizenships } = useTanstackQuery();
  const { mutate: orgCitizenshipsMutate } = useUpdateCitizenships({
    onSuccess: (response) => {
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      formik.setSubmitting(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    },
  });

  const {
    data: { citizenships },
  } = task;

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      citizenships: citizenships.value?.length ? citizenships.value : [''],
    },
    onSubmit: (values) => {
      orgCitizenshipsMutate({
        taskId: task.id,
        data: {
          citizenships: uniq(values.citizenships),
        },
      });
    },
  });

  const actionBtnDisabled = formik.values.citizenships.some((item) => !item);

  return (
    <>
      <PageTitle pt={0} title={<TaskTitle task={task} />} />

      <Box mb={5} display="flex" alignItems="center">
        <Typography variant="body2" color="textSecondary">
          {t('orgOnboardingCitizenshipsTask.description')}
        </Typography>
      </Box>

      <CitizenshipsGroup
        hideAddButton={hideAddButton}
        disabled={isReadOnly || !citizenships.editable}
        addFieldDisabled={actionBtnDisabled}
        isLoading={formik.isSubmitting}
        errors={formik.errors?.citizenships ?? []}
        citizenships={formik.values.citizenships}
        onFieldsNumberChange={(citizenships) =>
          formik.setFieldValue('citizenships', citizenships)
        }
        onChange={(index, value) => {
          formik.setFieldValue(`citizenships.${[index]}`, value);
          formik.setFieldError(`citizenships.${[index]}`, '');
        }}
        onErrorChange={(errors) => formik.setErrors({ citizenships: errors })}
      />

      <Box mt={4}>
        <OnboardingTaskActions
          isReadOnly={isReadOnly || !citizenships.editable}
          taskNavigationItems={taskNavigationItems}
          disabled={
            formik.isSubmitting ||
            (task.status !== OnboardingItemStatus.requiresAction &&
              !formik.dirty) ||
            actionBtnDisabled
          }
          onSuccess={formik.handleSubmit}
        />
      </Box>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default CitizenshipsTask;
