import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { IssueCardDialog } from 'domains/card/dialogs';
import { InviteMemberButtonWithDialog } from 'domains/member/components';
import { getIssuerStatement } from 'domains/organization/utils';
import { Box, Button, Grid, Typography, VerticalCardsIcon } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { PageContent, PageHeader, PageTitle, PageTitleActions } from 'layout';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';
import ActiveCards from './ActiveCards';
import ActiveMembers from './ActiveMembers';
import FinancialOverview from './FinancialOverview';
import LastTransactions from './LastTransactions';
import TopSpenders from './TopSpenders';

const DashboardPage = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { requestCardForAnotherMemberEnabled } = useFlags();
  const {
    state: { defaultCardAccount },
  } = useGlobalState();
  const [memberCountRefreshIndex, setMemberCountRefreshIndex] = useState(0);
  const [isIssueCardDialogOpen, setIsIssueCardDialogOpen] = useState(false);
  const [cardCountRefreshIndex, setCardCountRefreshIndex] = useState(0);

  return (
    <>
      <PageHeader>
        <PageTitle title={t('dashboardPage.dashboard')}>
          <PageTitleActions>
            <InviteMemberButtonWithDialog
              dataIntercomTarget="invite-member-button"
              onSuccess={() => {
                setMemberCountRefreshIndex((prevState) => prevState + 1);
              }}
              variant="outlined"
              isNewDesign
            />
            {canUser('card:create', requestCardForAnotherMemberEnabled) && (
              <Button
                onClick={() => setIsIssueCardDialogOpen(true)}
                startIcon={<VerticalCardsIcon />}
                sx={{ ml: 1 }}
              >
                {t('dashboardPage.issueCard')}
              </Button>
            )}
          </PageTitleActions>
        </PageTitle>
      </PageHeader>

      <PageContent>
        <Box maxWidth={1240}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FinancialOverview />
            </Grid>
            <Grid item xs={3}>
              <ActiveMembers refreshIndex={memberCountRefreshIndex} />
            </Grid>
            <Grid item xs={3}>
              <ActiveCards refreshIndex={cardCountRefreshIndex} />
            </Grid>
            <Grid item xs={6}>
              <TopSpenders />
            </Grid>
            <Grid item xs={6}>
              <LastTransactions />
            </Grid>
          </Grid>
          <Box maxWidth={750} mt={3} mx="auto" textAlign="center">
            <Typography
              variant="caption2"
              component="div"
              color="text.secondary"
            >
              {getIssuerStatement(defaultCardAccount!.accountGroup.value)}
            </Typography>
          </Box>
        </Box>
      </PageContent>

      <IssueCardDialog
        open={isIssueCardDialogOpen}
        onClose={() => setIsIssueCardDialogOpen(false)}
        onSuccess={() => {
          setIsIssueCardDialogOpen(false);
          setCardCountRefreshIndex((prevState) => prevState + 1);
        }}
      />
    </>
  );
};

export default withPageConfig(DashboardPage, {
  permission: 'dashboard-page:visit',
});
