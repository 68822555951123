import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import styled from 'styled-components';

export const ClearFilterButton = styled.button.attrs({ tabIndex: -1 })`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey10};
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  transform-origin: 100% 50%;
  transform: translate(-14px, 14px);
  transition: transform 0.2s;

  .MuiFormLabel-filled + & {
    transform: translate(0, -24px) scale(0.75);
  }
`;

export const StyledInputLabel = styled((props: InputLabelProps) => (
  <InputLabel shrink={false} focused={false} {...props}>
    {props.children}
  </InputLabel>
))`
  z-index: 1;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey100};
  transform-origin: 0 50%;
  transform: translate(14px, 14px);
  transition: transform 0.2s;
  pointer-events: none;

  &.MuiFormLabel-filled {
    transform: translate(0, -24px) scale(0.75);
  }
`;
