import { useEffect, useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { useCodatStepperContext } from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/useCodatStepperContext';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CodatDataItemStatus, CodatSupplierItem } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  isInnerLoading: boolean;
  isError: boolean;
  suppliers: CodatSupplierItem[] | null;
  selectedIds: string[];
}

const useSuppliersSync = () => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    actions: { onNext, onClose },
  } = useCodatStepperContext();
  const [state, setState] = useState<State>({
    isLoading: true,
    isInnerLoading: false,
    isError: false,
    suppliers: null,
    selectedIds: [],
  });

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isError: false,
        isLoading: true,
      }));

      const suppliers = await api.getCodatSuppliers(organization!.id);

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        suppliers,
        selectedIds: suppliers
          .filter((item) => item.status === CodatDataItemStatus.selected)
          .map((item) => item.id),
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isError: true,
        isLoading: false,
      }));
      logError(error);
    }
  };

  const onItemsSelect = (selectedIds: string[]) => {
    setState((prevState) => ({ ...prevState, selectedIds }));
  };

  const onSuppliersSave = async (onSaveCallback?: () => void) => {
    try {
      // There is a case when no suppliers are found.
      // In this case proceed to the next step without API calls
      if (state.suppliers!.length) {
        setState((prevState) => ({
          ...prevState,
          isInnerLoading: true,
        }));

        await api.updateCodatSuppliers({
          organizationId: organization!.id,
          selectedSuppliers: state.suppliers!.map((supplier) => ({
            codatSupplierId: supplier.id,
            status: state.selectedIds.includes(supplier.id)
              ? CodatDataItemStatus.selected
              : CodatDataItemStatus.unselected,
          })),
        });

        await api.mapCodatSuppliers({
          organizationId: organization!.id,
          codatSuppliers: state
            .suppliers!.filter((supplier) =>
              state.selectedIds.includes(supplier.id)
            )
            .map((supplier) => ({
              id: supplier.id,
              supplierName: supplier.name,
            })),
        });

        if (!mounted.current) return;
      }

      if (onSaveCallback) onSaveCallback();
      else onNext();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isInnerLoading: false,
      }));
      logError(error);
    }
  };

  // get the latest Codat data
  const fetchCodatData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isInnerLoading: true,
        // reset selected items once user clicks Sync Now button
        selectedIds: [],
      }));

      await api.getSyncedCodatMappingOptionsSummary(organization!.id);
      const suppliers = await api.getCodatSuppliers(organization!.id);

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        suppliers,
        selectedIds: suppliers
          .filter((item) => item.status === CodatDataItemStatus.selected)
          .map((item) => item.id),
        isInnerLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isInnerLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    ...state,
    getData,
    onItemsSelect,
    onSuppliersSave,
    fetchCodatData,
    onClose,
  };
};

export default useSuppliersSync;
