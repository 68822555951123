import clsx from 'clsx';
import { Box, BoxProps } from 'elements';

interface Props extends BoxProps {
  className?: string;
  code: string;
}

const CountryFlag = ({ className, code, sx = [], ...props }: Props) => {
  if (!code) return null;

  // UK is used as country code for United Kingdom in BE
  const flagIconsCountryCode =
    code.toLowerCase() === 'uk' ? 'gb' : code.toLowerCase();

  return (
    <Box
      component="span"
      className={clsx(
        'flag-icon',
        `flag-icon-${flagIconsCountryCode}`,
        className
      )}
      sx={[
        {
          lineHeight: '17px',
          height: (theme) => theme.spacing(2),
          width: (theme) => theme.spacing(2.75),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  );
};

export default CountryFlag;
