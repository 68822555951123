import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import ArrowsButtonDownIcon from 'assets/icons/interface/arrowsButtonDown.svg?react';
import { Onboarding, OrganizationStatus } from 'services/constants';
import { didOrgOnboardingStepPassed } from 'services/utils/onboarding';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledValidationCheckIcon,
} from './style';

type State = 'active' | 'completed' | 'disabled';

interface OnboardingBlockWrapperProps {
  title: string;
  state: State;
  testId: string;
  children?: React.ReactNode;
  textButton?: React.ReactNode;
}

const expansionPanelSummaryPropsMap = {
  active: {
    $isExpandable: false,
  },
  completed: {
    $isExpandable: true,
    expandIcon: <ArrowsButtonDownIcon width={16} height={16} />,
  },
  disabled: {
    $isExpandable: false,
  },
};

const OnboardingBlockWrapper = ({
  title,
  state,
  testId,
  children,
  textButton,
}: OnboardingBlockWrapperProps) => {
  const [expanded, setExpanded] = useState(false);

  const expansionPanelSummaryProps = expansionPanelSummaryPropsMap[state];

  useEffect(() => {
    if (state === 'active') setExpanded(true);
    if (state === 'completed' || state === 'disabled') setExpanded(false);
  }, [state]);

  const onChange = (e: React.ChangeEvent<{}>, newVal: boolean) => {
    if (state === 'completed') setExpanded(newVal);
  };

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={onChange}
      data-test-id={testId}
    >
      <StyledAccordionSummary {...expansionPanelSummaryProps}>
        {state === 'completed' && <StyledValidationCheckIcon />}
        <Box ml={state === 'active' ? '9px' : 0}>{title}</Box>
        {textButton}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export const getBlockState = (
  onboarding: Onboarding | null,
  requestedStatus: OrganizationStatus
) => {
  if (!onboarding) return 'disabled';
  if (onboarding.status === requestedStatus) return 'active';
  return didOrgOnboardingStepPassed(onboarding.status, requestedStatus)
    ? 'completed'
    : 'disabled';
};

export default OnboardingBlockWrapper;
