import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  PercentField,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  AccountingSystem,
  NetworkErrorCode,
  VAT_RATE,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
} from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
}

const AddVatRateDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, accountingSettings },
  } = useGlobalState();
  const codeIsNonNumeric =
    accountingSettings!.accountingSystem !== AccountingSystem.datev;

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: '',
      rate: '',
      code: '',
      status: AccountingItemStatus.active,
      organizationId: organization!.id,
    },
    validate(values) {
      const errors: { [key in keyof typeof values]?: string } = {};
      const rate = +values.rate;
      if (rate < VAT_RATE.rateMinVal) {
        errors.rate = t('addVatRateDialog.vatRateTooLowError', {
          min: VAT_RATE.rateMinVal,
        });
      }
      if (rate > VAT_RATE.rateMaxVal) {
        errors.rate = t('addVatRateDialog.vatRateTooBigError', {
          max: VAT_RATE.rateMaxVal,
        });
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        const data = await api.addVatRate({
          ...trimObjValues(values),
          rate: +values.rate,
          code: values.code.trim() || null,
        });
        dispatch({ type: 'ADD_VAT_RATE', payload: data });
        if (!mounted.current) return;
        onSuccess();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (getNetworkErrorCode(error) === NetworkErrorCode.invalidVatName) {
          setFieldError('name', t('addVatRateDialog.invalidVatNameError'));
          return;
        }
        if (getNetworkErrorCode(error) === NetworkErrorCode.invalidVatCode) {
          setFieldError('code', t('addVatRateDialog.invalidVatCodeError'));
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const hasEmptyField = !formik.values.name.trim() || !formik.values.rate;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('addVatRateDialog.title')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="add-vat-rate-form" noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('addVatRateDialog.nameTitle')}
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue(e.target.name, e.target.value, false);
                  formik.setFieldError(e.target.name, undefined);
                }}
                disabled={formik.isSubmitting}
                inputProps={{
                  maxLength: VAT_RATE.nameMaxLength,
                }}
                error={!!formik.errors.name}
                helperText={formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <PercentField
                isNumericString
                label={t('addVatRateDialog.rateTitle')}
                decimalScale={2}
                name="rate"
                value={formik.values.rate}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                onValueChange={(values) => {
                  formik.setFieldValue('rate', values.value, false);
                  formik.setFieldError('rate', undefined);
                }}
                error={!!formik.errors.rate}
                helperText={formik.errors.rate && formik.errors.rate}
              />
            </Grid>
            <Grid item xs={12}>
              {codeIsNonNumeric ? (
                <TextField
                  {...formik.getFieldProps('code')}
                  label={t('addVatRateDialog.codeTitle')}
                  disabled={formik.isSubmitting}
                  inputProps={{
                    maxLength: VAT_RATE.codeMaxLength,
                  }}
                />
              ) : (
                <NumberFormat
                  customInput={TextField}
                  allowNegative={false}
                  label={t('addVatRateDialog.codeTitle')}
                  decimalScale={0}
                  name="code"
                  value={formik.values.code}
                  disabled={formik.isSubmitting}
                  onBlur={formik.handleBlur}
                  onValueChange={(values) => {
                    formik.setFieldValue('code', values.value, false);
                    formik.setFieldError('code', undefined);
                  }}
                  inputProps={{
                    maxLength: VAT_RATE.codeMaxLength,
                  }}
                  error={!!formik.errors.code}
                  helperText={formik.errors.code && formik.errors.code}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={hasEmptyField || formik.isSubmitting}
          type="submit"
          form="add-vat-rate-form"
        >
          {t('addVatRateDialog.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(AddVatRateDialog);
