import { useMemo } from 'react';
import { toDecimal } from 'dinero.js';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import moment from 'moment';
import { useGlobalState } from 'context/GlobalState';
import { DIALOG_STEPPER_WIDTH } from 'domains/card/components';
import { useCardCreationCustomFields } from 'domains/card/hooks';
import {
  Dialog,
  DialogProps,
  LoaderWithOverlay,
  withDialogWrapper,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Card,
  CardCategoryControl,
  CardCategoryControlType,
  CardConfigGroup,
  CardConfigSetting,
  CardControlRestriction,
  CardDateControl,
  CardFundingType,
  CardLimitRenewFrequency,
  CardLoadFrequency,
  CardLocationControl,
  CardMerchantControl,
  CardTimeControl,
  CustomField,
  CustomFieldOption,
  DEFAULT_TIMEZONE,
  Member,
  MemberDetails,
  MerchantCategory,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getCurrencyByCode,
  getGenericErrorMsg,
} from 'services/utils';
import AttributesStep from './AttributesStep';
import ControlsStep from './ControlsStep';
import LimitsAndValidityStep from './LimitsAndValidityStep';
import SummaryStep from './SummaryStep';
import TypeStep from './TypeStep';

export type Step =
  | 'type'
  | 'limitsAndValidity'
  | 'controls'
  | 'attributes'
  | 'summary';

export interface FormValues {
  step: Step;
  member: Member | MemberDetails | null;
  cardConfigSetting: CardConfigSetting | null;
  cardAccountId: string;
  cardDesignId: string;
  expiryPeriodMonths: number;
  validFrom: string;
  validTo: string;
  limitRenewFrequency: CardLimitRenewFrequency;
  limit: string;
  transactionLimit: string;
  loadAmount: string;
  loadFrequency: CardLoadFrequency;
  cardName: string;
  comment: string;
  purpose: string;
  teamId: string;
  projectId: string;
  categoryControl: CardCategoryControl | null;
  merchantControl: CardMerchantControl | null;
  dateControl: CardDateControl | null;
  timeControl: CardTimeControl | null;
  locationControl: CardLocationControl | null;
  customFirstName: string;
  customLastName: string;
  customFields: (CustomFieldOption | string | null)[];
  customFieldsResponse: CustomField[];
}

export const getInitialValues = ({
  member,
  cardConfigSetting,
  customFields,
  customFieldsResponse,
}: {
  member?: Member | MemberDetails | null;
  cardConfigSetting?: CardConfigSetting | null;
  customFields: (CustomFieldOption | string | null)[];
  customFieldsResponse: CustomField[];
}): FormValues => {
  const expiryPeriodMonths = cardConfigSetting
    ? cardConfigSetting.expiryPeriodsInMonths[
        cardConfigSetting.expiryPeriodsInMonths.length - 1
      ]
    : 0;

  return {
    step: 'type',
    member: member || null,
    cardConfigSetting: cardConfigSetting || null,
    cardAccountId: cardConfigSetting?.cardAccounts[0] || '',
    cardDesignId: cardConfigSetting?.cardDesignIds[0] || '',
    expiryPeriodMonths,
    validFrom: moment().format('YYYY-MM-DD'),
    validTo:
      typeof cardConfigSetting?.validityPeriodDefaultInDays === 'number'
        ? moment()
            .add(cardConfigSetting?.validityPeriodDefaultInDays, 'days')
            .format('YYYY-MM-DD')
        : moment().add(expiryPeriodMonths, 'months').format('YYYY-MM-DD'),
    limitRenewFrequency:
      cardConfigSetting?.maxUsage === 1 ||
      cardConfigSetting?.fundingType === CardFundingType.loadBasedAccruing ||
      cardConfigSetting?.cardConfigGroup === CardConfigGroup.pliantVirtualTravel
        ? CardLimitRenewFrequency.total
        : CardLimitRenewFrequency.monthly,
    limit:
      cardConfigSetting?.fundingType === CardFundingType.loadBasedAccruing
        ? '0'
        : '',
    transactionLimit:
      cardConfigSetting?.fundingType === CardFundingType.loadBasedAccruing
        ? '0'
        : '',
    loadAmount: cardConfigSetting?.loadBasedSettings?.fixedAmount
      ? toDecimal(
          dineroFromMoney(cardConfigSetting.loadBasedSettings.fixedAmount)
        )
      : '',
    loadFrequency:
      cardConfigSetting?.loadBasedSettings?.fixedFrequency ||
      CardLoadFrequency.monthly,
    cardName: '',
    comment: '',
    purpose: '',
    teamId: '',
    projectId: '',
    categoryControl:
      cardConfigSetting?.cardConfigGroup === CardConfigGroup.pliantVirtualTravel
        ? {
            restriction: CardControlRestriction.allowed,
            type: CardCategoryControlType.category,
            values: [MerchantCategory.travelAndAccommodation],
            displayValues: null,
          }
        : null,
    merchantControl: null,
    dateControl: null,
    timeControl: null,
    locationControl: null,
    customFirstName: '',
    customLastName: '',
    customFields, // custom fields for user manipulations
    customFieldsResponse, // actual custom fields from BE
  };
};

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (card: Card) => void;
}

const CardRequestDialog = ({ onSuccess, ...props }: Props) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { isAdminApp } = useCurrentApp();
  const {
    state: {
      organization,
      cardAccounts,
      member: currentMember,
      featureModules,
    },
  } = useGlobalState();
  const member = isAdminApp ? null : (currentMember as MemberDetails);
  const {
    customFields,
    customFieldsResponse,
    isLoading,
  } = useCardCreationCustomFields(props.onClose);
  const initialValues = useMemo(() => {
    return getInitialValues({ member, customFields, customFieldsResponse });
  }, [member, customFields, customFieldsResponse]);

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      const account = cardAccounts.find(
        (item) => item.id === values.cardAccountId
      )!;
      const currency = getCurrencyByCode(account.currency.value);

      const { cardId } = await api.createCardRequest({
        organizationId: organization!.id,
        memberId: values.member!.id,
        cardConfig: values.cardConfigSetting!.cardConfig,
        cardAccountId: values.cardAccountId,
        cardDesignId: values.cardDesignId,
        transactionLimit: convertDineroToMoney(
          dineroFromFloat(values.transactionLimit, currency)
        ),
        limit: convertDineroToMoney(dineroFromFloat(values.limit, currency)),
        limitRenewFrequency: values.limitRenewFrequency,
        loadAmount:
          values.cardConfigSetting!.fundingType ===
          CardFundingType.loadBasedAccruing
            ? convertDineroToMoney(dineroFromFloat(values.loadAmount, currency))
            : undefined,
        loadFrequency:
          values.cardConfigSetting!.fundingType ===
          CardFundingType.loadBasedAccruing
            ? values.loadFrequency
            : undefined,
        comment: values.comment.trim(),
        expiryPeriodMonths: values.expiryPeriodMonths,
        ...(values.cardConfigSetting!.validityPeriodEnabled && {
          validFrom: values.validFrom,
          validTo: values.validTo,
          validTimezone: DEFAULT_TIMEZONE,
        }),
        cardName: values.cardName.trim() || undefined,
        purpose: values.purpose.trim() || undefined,
        teamId: values.teamId || undefined,
        projectId: values.projectId || undefined,
        cardControls: featureModules.CARD_CONTROLS
          ? {
              ...(!!values.categoryControl?.values.length && {
                categories: values.categoryControl,
              }),
              ...(!!values.merchantControl?.values.length && {
                merchants: values.merchantControl,
              }),
              ...(!!values.dateControl?.values.length && {
                dates: values.dateControl,
              }),
              ...(!!values.timeControl?.values.length && {
                times: values.timeControl,
              }),
              ...(!!values.locationControl?.values.length && {
                locations: values.locationControl,
              }),
            }
          : {},
        customFirstName: values.customFirstName || undefined,
        customLastName: values.customLastName || undefined,
      });

      if (values.customFields.length > 0) {
        await api.updateCardsCardTxCustomFields({
          cardTransactionCustomFieldRequests: values.customFields.map(
            (customField, index) => ({
              cardId: cardId,
              organizationId: values.customFieldsResponse[index].organizationId,
              transactionCustomFieldId: values.customFieldsResponse[index].id,
              defaultValueForCard:
                typeof customField === 'string' ? customField : '',
              customFieldOptionId:
                typeof customField !== 'string' && customField
                  ? customField.id
                  : null,
            })
          ),
        });
      }

      const card = await api.getCard(cardId);
      if (!mounted.current) return;
      setSubmitting(false);
      onSuccess(card);
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      setSubmitting(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const renderStep = (formikProps: FormikProps<FormValues>) => {
    return (
      <Dialog
        {...props}
        PaperProps={{
          sx: (theme) => ({
            minHeight: '600px',
            maxWidth: theme.breakpoints.values.sm + DIALOG_STEPPER_WIDTH + 'px',
            paddingLeft: DIALOG_STEPPER_WIDTH + 'px',
          }),
        }}
      >
        {formikProps.values.step === 'type' && (
          <TypeStep onClose={props.onClose} />
        )}
        {formikProps.values.step === 'limitsAndValidity' && (
          <LimitsAndValidityStep onClose={props.onClose} />
        )}
        {formikProps.values.step === 'controls' && (
          <ControlsStep onClose={props.onClose} />
        )}
        {formikProps.values.step === 'attributes' && (
          <AttributesStep onClose={props.onClose} />
        )}
        {formikProps.values.step === 'summary' && (
          <SummaryStep onClose={props.onClose} />
        )}

        <LoaderWithOverlay loading={isLoading} />
      </Dialog>
    );
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {renderStep}
    </Formik>
  );
};

export default withDialogWrapper(CardRequestDialog);
