import { useTranslation } from 'react-i18next';
import { DataGridCell, Typography } from 'elements';
import {
  ProjectStatus,
  ReviewFlagReason,
  TransactionReviewStatus,
} from 'services/constants';

interface Props {
  projectStatus: ProjectStatus;
  projectName: string | null;
  costUnit: string | null;
  reviewStatus: TransactionReviewStatus | null;
  reviewFlagReason: ReviewFlagReason | null;
}

const ProjectCell = ({
  projectStatus,
  projectName,
  costUnit,
  reviewStatus,
  reviewFlagReason,
}: Props) => {
  const { t } = useTranslation();

  if (
    reviewStatus === TransactionReviewStatus.flagged &&
    reviewFlagReason === ReviewFlagReason.privateExpense
  ) {
    return <DataGridCell>—</DataGridCell>;
  }

  if (projectStatus === ProjectStatus.multiple)
    return (
      <DataGridCell sx={{ textTransform: 'uppercase' }}>
        {t('exportPage.multiple')}
      </DataGridCell>
    );
  if (projectStatus === ProjectStatus.missing)
    return <DataGridCell>—</DataGridCell>;

  return (
    <DataGridCell>
      {projectName}
      <Typography
        component="div"
        variant="caption"
        color="textSecondary"
        noWrap
      >
        {costUnit}
      </Typography>
    </DataGridCell>
  );
};

export default ProjectCell;
