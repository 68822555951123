import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MissingReceiptButton } from 'domains/transaction/components';
import { QueryParams } from 'domains/transaction/pages/AccountEntriesPage';
import { Box, Divider, Typography } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { PageFiltersComponent } from 'layout';
import FilterChips from './FilterChips';
import FiltersDrawer from './FiltersDrawer';
import useFilters from './useFilters';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  disabled: boolean;
  missingReceiptsCount: number;
  transactionsCount: number;
  renderMenu: () => React.ReactNode;
}

const Filters = ({
  params,
  selectedFiltersCount,
  setParam,
  disabled,
  missingReceiptsCount,
  transactionsCount,
  renderMenu,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { organization, featureModules },
  } = useGlobalState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const {
    projectData,
    teamData,
    areFiltersLoading,
    setDataFilters,
  } = useFilters({
    projectIds: params.projectIds,
    teamId: params.teamId,
  });

  return (
    <>
      <Box mt={2} display="flex" alignItems="center">
        <PageFiltersComponent
          count={selectedFiltersCount}
          q={params.q}
          setQParam={(value) => setParam('q', value)}
          onOpen={() => setIsFilterOpen(true)}
          disabled={disabled || areFiltersLoading}
          sx={{ mt: 0 }}
        >
          <Typography variant="body2" color="textSecondary" ml={2}>
            {t('common.resultsTable', { count: transactionsCount })}
          </Typography>

          {featureModules.RECEIPT_MANAGEMENT &&
            organization?.missingReceiptNotificationEnabled && (
              <>
                {missingReceiptsCount > 0 && (
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ mx: 2 }}
                  />
                )}
                <MissingReceiptButton
                  count={missingReceiptsCount}
                  receiptFilter={params.receipt}
                  setParam={setParam}
                />
              </>
            )}
        </PageFiltersComponent>

        {renderMenu()}
      </Box>

      <FilterChips
        params={params}
        selectedFiltersCount={selectedFiltersCount}
        setParam={setParam}
        projectData={projectData}
        teamData={teamData}
      />

      <FiltersDrawer
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        params={params}
        setFilters={setDataFilters}
        teamData={teamData}
        projectData={projectData}
        setParam={setParam}
      />
    </>
  );
};

export default Filters;
