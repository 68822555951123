import { StepIconProps } from '@material-ui/core/StepIcon';
import { useTheme } from 'styled-components';
import EditSelectAreaCircleDashIcon from 'assets/icons/interface/editSelectAreaCircleDash.svg?react';
import GeometricCircleIcon from 'assets/icons/interface/geometricCircle.svg?react';
import ValidationCheckIcon from 'assets/icons/interface/validationCheck.svg?react';

const useIconAndColor = ({ active, completed }: StepIconProps) => {
  const { colors } = useTheme();

  if (completed)
    return { Icon: ValidationCheckIcon, color: colors.financeGreen10 };
  if (active) return { Icon: GeometricCircleIcon, color: colors.grey10 };
  return { Icon: EditSelectAreaCircleDashIcon, color: colors.neutralVariant50 };
};

const StepIcon = (props: StepIconProps) => {
  const { Icon, color } = useIconAndColor(props);

  return <Icon width={16} height={16} fill={color} />;
};

export default StepIcon;
