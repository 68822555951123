import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { CardIconWithDetails } from 'domains/card/components';
import {
  AccountEntryMerchantCell,
  AmountCell,
  IntegrationCell,
  MerchantCell,
  TransactionAmountCell,
  TransactionDisputeIcon,
  TransactionMissingReceiptCell,
} from 'domains/transaction/components';
import { DataGridCell, drawerPlaceholderColumn, GridColDef } from 'elements';
import { AccountEntry } from 'services/constants';
import { getFullName } from 'services/utils';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<AccountEntry>[] = [
      {
        headerName: t('accountingPage.merchant'),
        field: 'merchant',
        sortable: false,
        flex: 1.5,
        minWidth: 60,
        renderCell: ({ row }) =>
          row.transactionInfo ? (
            <MerchantCell
              transaction={row.transactionInfo}
              showReceiptIcon
              showCommentIcon
            />
          ) : (
            <AccountEntryMerchantCell accountEntry={row} />
          ),
      },
      {
        headerName: '',
        field: 'receiptNeeded',
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        flex: 0.2,
        renderCell: ({ row }) =>
          !!row.transactionInfo && (
            <TransactionMissingReceiptCell transaction={row.transactionInfo} />
          ),
      },
      {
        headerName: '',
        field: 'disputed',
        align: 'center',
        headerAlign: 'center',
        flex: 0.2,
        renderCell: ({ row }) =>
          row.transactionInfo?.disputed ? <TransactionDisputeIcon /> : null,
      },
      {
        headerName: t('accountingPage.bookingDate'),
        field: 'bookingDate',
        sortable: false,
        flex: 0.8,
        valueGetter: ({ row }) => moment(row.bookingDate).format('D MMM YYYY'),
      },
      {
        headerName: t('accountingPage.card'),
        field: 'card',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) =>
          row.transactionInfo ? (
            <CardIconWithDetails
              cardConfig={row.transactionInfo!.cardConfig}
              cardDesignId={row.transactionInfo!.cardDesignId}
              cardRefNum={row.transactionInfo!.cardRefNum}
              cardName={row.transactionInfo!.cardName}
              showCardNameTooltip
            />
          ) : null,
      },
      {
        headerName: t('accountingPage.member'),
        field: 'member',
        sortable: false,
        flex: 1,
        valueGetter: ({ row }) =>
          row.transactionInfo || row.reimbursementInfo
            ? getFullName(
                row.transactionInfo?.memberFirstName ||
                  row.reimbursementInfo?.memberFirstName ||
                  null,
                row.transactionInfo?.memberLastName ||
                  row.reimbursementInfo?.memberLastName ||
                  null
              ) || '-'
            : null,
      },
      {
        headerName: t('accountingPage.amount'),
        field: 'amount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        renderCell: ({ row }) => (
          <DataGridCell>
            {row.transactionInfo ? (
              <TransactionAmountCell
                transaction={row.transactionInfo!}
                bookingDate={row.bookingDate}
              />
            ) : (
              <AmountCell accountEntry={row} />
            )}
          </DataGridCell>
        ),
      },
      {
        headerName: t('accountingPage.balance'),
        field: 'balanceAfter',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        renderCell: ({ row }) =>
          !!row.balanceAfter && (
            <DataGridCell>
              <FormatMoney
                value={row.balanceAfter}
                fractionalPart
                positiveSign
              />
            </DataGridCell>
          ),
      },
      {
        headerName: t('accountingPage.export'),
        field: '_integration_',
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        flex: 0.4,
        minWidth: 100,
        renderCell: ({ row }) =>
          row.transactionInfo ? (
            <IntegrationCell
              status={row.transactionInfo.status}
              exportStatus={row.transactionInfo.exportStatus}
              integrationType={row.transactionInfo.integrationType}
            />
          ) : null,
      },
      drawerPlaceholderColumn,
    ];

    return columns;
  }, [i18n.language]);
};

export default useColumns;
