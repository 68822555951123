import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { Paper, Typography } from 'elements';
import { Card, CardStatus } from 'services/constants';

interface Props {
  card: Card;
}

const CardLoadBasedBalance = ({ card }: Props) => {
  const { t } = useTranslation();

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Typography
        variant="caption"
        component="div"
        color="text.secondary"
        mb={0.5}
      >
        {t('cardLoadBasedBalance.balance')}
      </Typography>
      <Typography variant="h6">
        <FormatMoney value={card.availableLimit} fractionalPart />
      </Typography>
      {card.status === CardStatus.active &&
        card.loadAmount &&
        card.nextLoadDate && (
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            mt={1}
          >
            <Trans
              i18nKey={'cardLoadBasedBalance.nextTopUp'}
              components={{
                amount: <FormatMoney value={card.loadAmount} fractionalPart />,
              }}
              values={{
                date: moment.utc(card.nextLoadDate).format('DD.MM.YYYY'),
              }}
            />
          </Typography>
        )}
    </Paper>
  );
};

export default CardLoadBasedBalance;
