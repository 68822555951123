import {
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import PdfViewer from 'components/PdfViewer';
import { useGlobalState } from 'context/GlobalState';
import { isTransactionReadonly } from 'domains/transaction/utils';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CaretLeftIcon,
  CaretRightIcon,
  Dialog,
  DialogProps,
  FileXIcon,
  Link,
  LoaderWithOverlay,
  ReceiptAttentionIcon,
  Typography,
  withDialogWrapper,
  XIcon,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Receipt,
  ReceiptMimeType,
  ReceiptStatus,
  Transaction,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  downloadFileUsingAnchorTag,
  getFileNameFromHeader,
  getGenericErrorMsg,
} from 'services/utils';
import ImagePreview from './ImagePreview';
import PreviewDialogHeader from './PreviewDialogHeader';
import RejectReceiptDialog from './RejectReceiptDialog';
import ReuploadReceiptDropzone from './ReuploadReceiptDropzone';
import {
  CloseButton,
  ConfirmationOverlay,
  FakeImage,
  GridWrapper,
  NavButton,
  ReceiptContent,
  StyledGrid,
  ViewerWrap,
} from './style';

export const DEFAULT_SCALE = 1;
const DEFAULT_PAGE = 1;
const DEFAULT_NUM_PAGES = 1;

const getSelectedReceiptIndex = (receipts: Receipt[], id?: string | null) => {
  if (!id) return 0;
  const selectedReceiptIndex = receipts.findIndex(
    (receipt) => receipt.receiptId === id
  );
  if (selectedReceiptIndex === -1) return 0;
  return selectedReceiptIndex;
};

interface State {
  selectedIndex: number;
  imagesByIds: {
    [id: string]: {
      isLoading: boolean;
      data: string | null;
      hasError: boolean;
    };
  };
  isRemoveConfirmationVisible: boolean;
  isDeleting: boolean;
  isReplacing: boolean;
  receiptPageNumber: number;
  receiptNumPages: number;
  receiptScale: number;
  isRejectReceiptDialogOpen: boolean;
}

interface Props extends DialogProps {
  transaction: Transaction;
  receipts: Receipt[];
  onClose: () => void;
  onRemove: (id: string) => void;
  isExportPage?: boolean;
  children: ReactNode;
  onReceiptReplaced?: (
    transaction: Transaction,
    oldReceipt: Receipt,
    replacedReceipt: Receipt
  ) => void;
  onReceiptRejected?: (transaction: Transaction, receipt: Receipt) => void;
  selectedReceiptId?: string | null;
  onAccountingSystemReceiptUpdated?: (
    receipts: Receipt[],
    shouldTriggerUpdate: boolean
  ) => void;
}

const ReceiptPreviewDialog = ({
  transaction,
  receipts,
  onClose,
  onRemove,
  isExportPage,
  children,
  onReceiptReplaced,
  onReceiptRejected,
  selectedReceiptId,
  onAccountingSystemReceiptUpdated,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const listRef = useRef<FixedSizeList>(null);
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { isPortalAppMirrorView, isAdminApp } = useCurrentApp();
  const {
    state: { featureModules },
  } = useGlobalState();

  const [state, setState] = useState<State>({
    selectedIndex: getSelectedReceiptIndex(receipts, selectedReceiptId),
    imagesByIds: {},
    isRemoveConfirmationVisible: false,
    isDeleting: false,
    isReplacing: false,
    receiptPageNumber: DEFAULT_PAGE,
    receiptNumPages: DEFAULT_NUM_PAGES,
    receiptScale: DEFAULT_SCALE,
    isRejectReceiptDialogOpen: false,
  });

  const selectedReceipt = useMemo(() => receipts[state.selectedIndex], [
    receipts,
    state.selectedIndex,
  ]);

  const selectedReceiptData = useMemo(
    () =>
      selectedReceipt ? state.imagesByIds[selectedReceipt.receiptId] : null,
    [selectedReceipt, state.imagesByIds]
  );

  const setImageOrPdfLoadError = (id: string) => {
    if (!mounted.current) return;
    setState((prevState) => ({
      ...prevState,
      imagesByIds: {
        ...prevState.imagesByIds,
        [id]: {
          data: null,
          isLoading: false,
          hasError: true,
        },
      },
    }));
  };

  const getImageOrPdf = async ({ receiptId: id, mimeType }: Receipt) => {
    try {
      setState((state) => ({
        ...state,
        imagesByIds: {
          ...state.imagesByIds,
          [id]: {
            ...(state.imagesByIds[id] || { hasError: false, data: null }),
            isLoading: true,
          },
        },
      }));
      const data =
        mimeType === ReceiptMimeType.pdf
          ? (await api.getReceiptOriginal(id)).data
          : await api.getReceiptThumbnail(id);
      if (!mounted.current) return;
      setState((state) => ({
        ...state,
        imagesByIds: {
          ...state.imagesByIds,
          [id]: { isLoading: false, hasError: false, data },
        },
      }));
    } catch (error) {
      setImageOrPdfLoadError(id);
      logError(error);
    }
  };

  const downloadOriginal = async () => {
    try {
      const response = await api.getReceiptOriginal(selectedReceipt.receiptId);
      downloadFileUsingAnchorTag(
        getFileNameFromHeader(response.headers),
        response.data
      );
    } catch (error) {
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const downloadEReceiptOriginal = async () => {
    try {
      const response = await api.getEReceiptOriginal(selectedReceipt.receiptId);
      downloadFileUsingAnchorTag(
        getFileNameFromHeader(response.headers),
        response.data
      );
    } catch (error) {
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (!receipts.length) return;
    const selectedReceipt = receipts[state.selectedIndex];
    if (!selectedReceipt) return;
    if (state.imagesByIds[selectedReceipt.receiptId]?.isLoading) return;
    if (state.imagesByIds[selectedReceipt.receiptId]?.data) return;
    getImageOrPdf(receipts[state.selectedIndex]);
  }, [state.selectedIndex, receipts]);

  const handleDelete = async () => {
    try {
      setState((state) => ({ ...state, isDeleting: true }));
      await api.deleteReceipt(selectedReceipt.receiptId);
      if (!mounted.current) return;
      setState((state) => {
        if (state.selectedIndex === 0) return state;
        if (state.selectedIndex === receipts.length - 1)
          return { ...state, selectedIndex: state.selectedIndex - 1 };
        return state;
      });
      onRemove(selectedReceipt.receiptId);
      if (receipts.length === 1) {
        onClose();
        return;
      }
      setState((state) => ({
        ...state,
        isDeleting: false,
        receiptPageNumber: DEFAULT_PAGE,
        receiptNumPages: DEFAULT_NUM_PAGES,
        receiptScale: DEFAULT_SCALE,
        isRemoveConfirmationVisible: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((state) => ({
        ...state,
        isDeleting: false,
        isRemoveConfirmationVisible: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const changeSelectedImage = useCallback(
    (value: 'next' | 'prev') => {
      setState((state) => {
        if (state.isReplacing) return state;
        const { selectedIndex } = state;
        let nextIndex: number;
        if (value === 'next') {
          nextIndex = selectedIndex + 1;
          if (nextIndex === receipts.length) return state;
        }
        if (value === 'prev') {
          if (selectedIndex === 0) return state;
          nextIndex = selectedIndex - 1;
        }
        return {
          ...state,
          selectedIndex: nextIndex!,
          receiptScale: DEFAULT_SCALE,
          receiptPageNumber: DEFAULT_PAGE,
          receiptNumPages: DEFAULT_NUM_PAGES,
          isRemoveConfirmationVisible: false,
        };
      });
    },
    [receipts.length]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        changeSelectedImage('prev');
      } else if (event.key === 'ArrowRight') {
        changeSelectedImage('next');
      }
    };
    window.addEventListener('keydown', handleKeyDown, true);
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [changeSelectedImage]);

  const onChange = (newPage?: number, newScale?: number) => {
    if (listRef?.current?.scrollToItem && newPage) {
      listRef.current.scrollToItem(newPage - 1, 'start');
    }

    setState((prevState) => ({
      ...prevState,
      receiptPageNumber: newPage || prevState.receiptPageNumber,
      receiptScale: newScale || prevState.receiptScale,
    }));
  };

  const receiptsAreReadOnly =
    isTransactionReadonly(transaction, isAdminApp, isPortalAppMirrorView) ||
    !featureModules.RECEIPT_MANAGEMENT;

  return (
    <Dialog {...props} onClose={onClose} fullScreen>
      <CloseButton onClick={onClose}>
        <XIcon />
      </CloseButton>
      <GridWrapper container>
        <ReceiptContent item xs={8}>
          <Box position="relative" flexShrink={0}>
            <PreviewDialogHeader
              isLoading={
                !selectedReceiptData ||
                selectedReceiptData?.isLoading ||
                state.isDeleting ||
                (selectedReceiptData?.hasError &&
                  !selectedReceiptData.isLoading)
              }
              transaction={transaction}
              selectedReceipt={selectedReceipt}
              page={state.receiptPageNumber}
              numPages={state.receiptNumPages}
              scale={state.receiptScale}
              downloadOriginal={downloadOriginal}
              downloadEReceiptOriginal={downloadEReceiptOriginal}
              onChange={onChange}
              onDelete={() =>
                setState((prevState) => ({
                  ...prevState,
                  isRemoveConfirmationVisible: true,
                }))
              }
              canEditTransaction={!receiptsAreReadOnly}
              onReject={() =>
                setState((prevState) => ({
                  ...prevState,
                  isRejectReceiptDialogOpen: true,
                }))
              }
              onReceiptReplaced={onReceiptReplaced}
            />
            {state.isRemoveConfirmationVisible && (
              <ConfirmationOverlay>
                <Typography flexGrow={1}>
                  {t('previewDialog.deleteReceiptConfirmMessage')}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ mx: 2 }}
                  disabled={state.isDeleting}
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      isRemoveConfirmationVisible: false,
                    }))
                  }
                >
                  {t('common.button.cancel')}
                </Button>
                <Button disabled={state.isDeleting} onClick={handleDelete}>
                  {t('previewDialog.delete')}
                </Button>
              </ConfirmationOverlay>
            )}
            <RejectReceiptDialog
              open={state.isRejectReceiptDialogOpen}
              onClose={() => {
                setState((prevState) => ({
                  ...prevState,
                  isRejectReceiptDialogOpen: false,
                }));
              }}
              receiptId={selectedReceipt?.receiptId}
              onRejected={(receipt: Receipt) => {
                if (onReceiptRejected) onReceiptRejected(transaction, receipt);
              }}
            />
          </Box>
          {isExportPage &&
            selectedReceipt?.status ===
              ReceiptStatus.rejectedByAccountingSystem && (
              <ReuploadReceiptDropzone
                receipt={selectedReceipt}
                onSuccess={(receipt) => {
                  const updatedReceipts = receipts.map((item) =>
                    item.receiptId === selectedReceipt?.receiptId
                      ? receipt
                      : item
                  );
                  const nextSelectedIndex = updatedReceipts.findIndex(
                    (receipt) =>
                      receipt.status ===
                      ReceiptStatus.rejectedByAccountingSystem
                  );
                  onAccountingSystemReceiptUpdated?.(
                    updatedReceipts,
                    nextSelectedIndex === -1
                  );
                  setState((prevState) => ({
                    ...prevState,
                    isReplacing: false,
                    selectedIndex:
                      nextSelectedIndex === -1
                        ? state.selectedIndex
                        : nextSelectedIndex,
                  }));
                }}
                onUploadStart={() =>
                  setState((prevState) => ({ ...prevState, isReplacing: true }))
                }
                onUploadError={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isReplacing: false,
                  }))
                }
              />
            )}
          <ViewerWrap>
            {selectedReceiptData?.data &&
              (selectedReceipt.mimeType === ReceiptMimeType.pdf ? (
                <Suspense fallback={<LoaderWithOverlay loading />}>
                  <PdfViewer
                    // key prop fixes issue with the SAME pdf file in a row.
                    // react-pdf doesn't load data if the base64 string dones't change
                    key={state.selectedIndex}
                    ref={listRef}
                    selectedPage={state.receiptPageNumber}
                    numPages={state.receiptNumPages}
                    scale={state.receiptScale}
                    onChange={(receiptPageNumber, receiptNumPages) =>
                      setState((prevState) => ({
                        ...prevState,
                        receiptPageNumber,
                        receiptNumPages,
                      }))
                    }
                    data={selectedReceiptData.data}
                    onError={() =>
                      setImageOrPdfLoadError(selectedReceipt.receiptId)
                    }
                  />
                </Suspense>
              ) : (
                <ImagePreview
                  key={selectedReceiptData.data}
                  data={selectedReceiptData.data}
                  scale={state.receiptScale}
                />
              ))}
            {selectedReceiptData?.hasError && !selectedReceiptData.isLoading && (
              <FakeImage>
                <FileXIcon fontSize="large" />
                <Typography mt={2}>
                  {t('previewDialog.previewErrorMessage')}
                </Typography>
                {selectedReceipt?.mimeType !== ReceiptMimeType.pdf && (
                  <Link
                    component="button"
                    variant="body1"
                    onClick={() => {
                      getImageOrPdf(receipts[state.selectedIndex]);
                    }}
                  >
                    {t('common.retry')}
                  </Link>
                )}
              </FakeImage>
            )}
            {receipts.length > 1 && (
              <>
                {state.selectedIndex !== 0 && (
                  <NavButton
                    size="large"
                    sx={{ left: '30px' }}
                    onClick={() => changeSelectedImage('prev')}
                    disabled={state.isReplacing}
                    data-test-id="preview-prev"
                  >
                    <CaretLeftIcon fontSize="large" />
                  </NavButton>
                )}
                {state.selectedIndex !== receipts.length - 1 && (
                  <NavButton
                    size="large"
                    sx={{ right: '30px' }}
                    onClick={() => changeSelectedImage('next')}
                    disabled={state.isReplacing}
                    data-test-id="preview-next"
                  >
                    <CaretRightIcon fontSize="large" />
                  </NavButton>
                )}
              </>
            )}
            {(!selectedReceiptData ||
              selectedReceiptData.isLoading ||
              state.isDeleting) && <LoaderWithOverlay loading />}

            {selectedReceipt?.status === ReceiptStatus.rejected && (
              <Alert
                severity="error"
                icon={<ReceiptAttentionIcon fontSize="inherit" />}
                sx={{
                  position: 'absolute',
                  bottom: 'calc(122px + 16px)',
                }}
              >
                {selectedReceipt.comment ? (
                  <>
                    <AlertTitle>
                      {t(
                        `transactionReceipts.rejectReasons.${selectedReceipt.rejectionReason}`
                      )}
                    </AlertTitle>
                    {selectedReceipt.comment}
                  </>
                ) : (
                  t(
                    `transactionReceipts.rejectReasons.${selectedReceipt.rejectionReason}`
                  )
                )}
              </Alert>
            )}
          </ViewerWrap>
        </ReceiptContent>
        <StyledGrid item xs={4}>
          {children}
        </StyledGrid>
      </GridWrapper>
    </Dialog>
  );
};

export default withDialogWrapper(ReceiptPreviewDialog);
