import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import { FormLabel, Grid, Switch } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OrganizationAccountType } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isConfirmDialogOpen: boolean;
  isLoading: boolean;
}

const Psd2BankConnection = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    dispatch,
    state: { organization, defaultCardAccount },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isConfirmDialogOpen: false,
    isLoading: false,
  });

  const updateBankConnectionRequired = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const updatedOrganization = await api.setBankConnectionRequired(
        organization!.id,
        !organization!.bankConnectionRequired
      );
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { organization: updatedOrganization },
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isConfirmDialogOpen: false,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item display="flex" alignItems="center">
          <FormLabel component="span">
            {t('int.informationRequestSection.psd2BankConnectionRequiredLabel')}
          </FormLabel>
        </Grid>
        <Grid item>
          <Switch
            checked={organization!.bankConnectionRequired}
            disabled={
              defaultCardAccount!.accountType.value ===
              OrganizationAccountType.prefunded
            }
            onChange={() =>
              setState((prevState) => ({
                ...prevState,
                isConfirmDialogOpen: true,
              }))
            }
            size="medium"
          />
        </Grid>
      </Grid>

      <ConfirmDialog
        loading={state.isLoading}
        onSuccess={updateBankConnectionRequired}
        open={state.isConfirmDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isConfirmDialogOpen: false,
          }))
        }
        description={t(
          !organization!.bankConnectionRequired
            ? 'int.informationRequestSection.psd2BankConnectionRequiredDescription'
            : 'int.informationRequestSection.psd2BankConnectionNotRequiredDescription'
        )}
        title={t(
          !organization!.bankConnectionRequired
            ? 'int.informationRequestSection.psd2BankConnectionRequiredTitle'
            : 'int.informationRequestSection.psd2BankConnectionNotRequiredTitle'
        )}
      />
    </>
  );
};

export default Psd2BankConnection;
