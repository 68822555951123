import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { commonPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import useIdTokenCustomData from 'hooks/useIdTokenCustomData';
import useQueryParams from 'hooks/useQueryParams';
import {
  Membership,
  MembershipStatus,
  SupportedWebAppLanguage,
} from 'services/constants';
import { updateHtmlLangAttribute } from 'services/i18n';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

/**
 * This HOC serves several purposes:
 * 1. Load an organization, memberships and cardAccounts into the global state.
 *    The organization has to be loaded before everything else because
 *    it's used for initializing feature flags.
 * 2. Check the `organizationId` URL param and switch member's organization
 *    to the target organization if it exists. This is done to support
 *    deep links for multi-org users. Our current setup supports only
 *    one organization at a time so "switching" here means making a call
 *    to the BE which will change the organizationId and permissions in the JWT.
 * 3. Check whether the current membership is activated and activate it if it's not.
 *    The activation is required for every new member to accept the terms and conditions
 *    load the user's phone number from Auth0 to our database, etc.
 * 4. Enrich Datadog logs with user data.
 */
const withInitialLogic = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => (props: P): JSX.Element | null => {
  const api = useImperativeApi();
  const { i18n } = useTranslation();
  const { dispatch } = useGlobalState();
  const { user } = useAuth0();
  const { organizationId, memberId, lang, roles } = useIdTokenCustomData();
  const { organizationId: targetOrganizationId } = useQueryParams();
  const [isLoading, setIsLoading] = useState(true);

  const switchOrganization = async (targetMembership: Membership) => {
    if (targetMembership.status === MembershipStatus.invited) {
      window.location.replace(
        generatePath(commonPaths.membershipActivation, {
          orgId: targetOrganizationId,
        })
      );
      return;
    }

    await api.switchOrganization(targetOrganizationId);
    // Reload the app to get an updated token, member, organization, etc.
    window.location.reload();
  };

  useEffect(() => {
    datadogRum.setUser({
      id: memberId,
      iamId: user!.sub,
      organizationId,
      roles,
    });
  }, [user]);

  useEffect(() => {
    // Temporary block APIs from firing.
    // Ideally user without memberId/orgId should not be able to access this place.
    if (!memberId) {
      logError('No user found while initializing the app');
      throw new Error('No user found while initializing the app');
    }

    (async () => {
      try {
        const languageCode = lang.substring(0, 2) as SupportedWebAppLanguage;
        moment.locale(languageCode);
        await i18n.changeLanguage(languageCode);
        updateHtmlLangAttribute(languageCode);

        const [
          organization,
          { cardAccounts },
          { memberships },
        ] = await Promise.all([
          api.getOrganization(organizationId),
          api.getCardAccounts(organizationId),
          api
            .getMemberships(
              memberId,
              [MembershipStatus.active, MembershipStatus.invited].join(',')
            )
            .catch((error) => {
              logError(error);
              return { memberships: [] };
            }),
        ]);

        if (targetOrganizationId && organizationId !== targetOrganizationId) {
          const targetMembership = memberships.find(
            (item) => item.organizationId === targetOrganizationId
          );
          if (targetMembership) {
            await switchOrganization(targetMembership);
            return;
          }
        }

        const currentMembership = memberships.find(
          (item) => item.organizationId === organizationId
        );

        if (currentMembership?.status === MembershipStatus.invited) {
          await api.activateMember(memberId, { organizationId });
          window.location.reload();
          return;
        }

        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: {
            organization,
            memberships,
            cardAccounts,
            defaultCardAccount: cardAccounts.find(
              (item) => item.defaultAccount.value
            )!,
          },
        });
        setIsLoading(false);
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: error });
        logError(error);
      }
    })();
  }, [memberId]);

  return isLoading ? null : <Component {...props} />;
};

export default withInitialLogic;
