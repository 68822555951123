import { ComponentType, FC, useMemo } from 'react';
import { ThemeProvider } from '@mui/material';
import { createPartnerTheme } from 'components/App/style/themeMui5';
import HelmetTitleAndFavicons from 'components/HelmetTitleAndFavicons';
import { PartnerIds } from 'services/constants';

const withTheme = <P extends object>(Component: ComponentType<P>): FC<P> => (
  props: P
): JSX.Element | null => {
  // set pliant theme by default for now. Will be changed later.
  const theme = useMemo(() => createPartnerTheme(PartnerIds.pliant), []);

  return (
    <ThemeProvider theme={theme}>
      <HelmetTitleAndFavicons title="Portal" partnerId={PartnerIds.pliant} />
      <Component {...props} />
    </ThemeProvider>
  );
};
export default withTheme;
