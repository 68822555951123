import { ComponentType, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { adminPaths, orgOnboardingPaths } from 'components/App';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import {
  PARTNER_ANY_AUTH_SCOPES,
  PARTNER_OWN_AUTH_SCOPES,
} from 'domains/partner/constants';
import { isPartnerBasedSource } from 'domains/partner/utils';
import { getStaticIntegrationsData } from 'domains/settings/utils';
import { LoaderWithOverlay } from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useUrls from 'hooks/useUrls';
import {
  PartnerAuthStatus,
  PartnerConfig,
  PartnerIds,
  PartnerIdType,
  PartnerOrgAuthDetails,
  SubscriptionPlanType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

// all circula partner instances should use the same translations.
// the fallback is the main circula partner id
const getCirculaId = (orgPartnerId: PartnerIdType) => {
  if (
    ([
      PartnerIds.circula,
      PartnerIds.circulaDemo,
      PartnerIds.circulaEmbedded,
    ] as PartnerIdType[]).includes(orgPartnerId)
  )
    return PartnerIds.circula;
};

const getRedirectionUrl = (
  integrationPageFMEnabled: boolean,
  orgHasNoSourcePartner: boolean,
  isOrgInOnboardingNew: boolean
) => {
  const isEmbeddedPartnerPageUsed = !integrationPageFMEnabled;
  const basicRedirectionUrl =
    orgHasNoSourcePartner || !isEmbeddedPartnerPageUsed
      ? adminPaths.settingsIntegrations
      : adminPaths.partner;

  return isOrgInOnboardingNew
    ? orgOnboardingPaths.orgOnboarding
    : basicRedirectionUrl;
};

interface State {
  partnerOrgDetails: PartnerOrgAuthDetails | null;
  partnerDetails: PartnerConfig | null;
  isLoading: boolean;
}

const withPartnerCheck = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P) => {
  const showPageError = useShowPageError();
  const history = useHistory();
  const { partnerId } = useParams<{ partnerId: string }>();
  const { t, i18n } = useTranslation();
  const partnerName = usePartnerName();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { isOrgInOnboarding, isOrgInOnboardingNew } = useIsOrgInOnboarding();
  const { HELP_CENTER_EXPORTING_TRANSACTIONS_LEXWARE_OFFICE_URL } = useUrls();
  const {
    state: {
      organization,
      subscriptionPlan,
      featureModules,
      partnerConfig,
      generalInfo,
    },
  } = useGlobalState();
  const partnerStaticData = getStaticIntegrationsData({
    t,
    i18n,
    articles: {
      HELP_CENTER_EXPORTING_TRANSACTIONS_LEXWARE_OFFICE_URL,
    },
    supportEmail: generalInfo.supportEmail,
    partnerName,
  });
  const [state, setState] = useState<State>({
    isLoading: true,
    partnerOrgDetails: null,
    partnerDetails: null,
  });

  const orgHasNoSourcePartner = !isPartnerBasedSource(organization!.partnerId);
  const appRedirectionUrl = getRedirectionUrl(
    featureModules.INTEGRATIONS_PAGE,
    orgHasNoSourcePartner,
    isOrgInOnboardingNew
  );

  // forbid the page view for pliant source orgs with
  // starter subscription plan for premium integrations
  const integrationForbiddenForPliant =
    orgHasNoSourcePartner &&
    (!subscriptionPlan ||
      subscriptionPlan.type === SubscriptionPlanType.starter) &&
    partnerStaticData[
      (getCirculaId(organization!.partnerId) ||
        partnerId) as keyof typeof partnerStaticData
    ]?.isPremium;

  const sourcePartnerCanConnectAnyPartner =
    !isOrgInOnboarding &&
    partnerConfig?.partnerScope &&
    PARTNER_ANY_AUTH_SCOPES.includes(partnerConfig.partnerScope);

  const sourcePartnerCanConnectHimself =
    partnerConfig?.partnerId === partnerId &&
    PARTNER_OWN_AUTH_SCOPES.includes(partnerConfig.partnerScope);

  const isPartnerFlowAllowed =
    partnerId &&
    (!integrationForbiddenForPliant ||
      sourcePartnerCanConnectAnyPartner ||
      sourcePartnerCanConnectHimself);

  const getData = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      const [partnerOrgDetails, partnerDetails] = await Promise.all([
        api.getPartnerOrgAuthDetails(partnerId, organization!.id),
        api.getPartnerConfig(partnerId),
      ]);
      if (!mounted.current) return;

      setState((prevState) => ({
        ...prevState,
        partnerOrgDetails,
        partnerDetails,
        isLoading: false,
      }));
    } catch (error) {
      showPageError();
      logError(error);
    }
  };

  useEffect(() => {
    if (!isPartnerFlowAllowed) {
      if (isOrgInOnboarding && partnerId)
        logError('User is not allowed to access PartnerAuthPage', {
          organizationId: organization!.id,
          partnerId: partnerConfig?.partnerId,
          parterScope: partnerConfig?.partnerScope,
        });
      return;
    }

    getData();
  }, [isPartnerFlowAllowed]);

  if (!isPartnerFlowAllowed)
    return isOrgInOnboarding ? (
      <WidgetError
        title={t('partnersPage.errorWidget.title')}
        description={t('partnersPage.errorWidget.description')}
        ctaText={t('partnersPage.errorWidget.actionBtn')}
        onReload={() => history.push(appRedirectionUrl)}
      />
    ) : (
      <Redirect to={appRedirectionUrl} />
    );

  if (state.isLoading) return <LoaderWithOverlay loading />;

  if (
    !state.partnerOrgDetails ||
    !state.partnerDetails ||
    state.partnerOrgDetails.status === PartnerAuthStatus.active ||
    state.partnerOrgDetails.status === PartnerAuthStatus.pending
  )
    return <Redirect to={appRedirectionUrl} />;

  return (
    <Component
      {...props}
      partnerDetails={state.partnerDetails!}
      partnerOrgDetails={state.partnerOrgDetails!}
      appRedirectionUrl={appRedirectionUrl}
    />
  );
};

export default withPartnerCheck;
