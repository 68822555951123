import { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Dialog, DialogProps, withDialogWrapper } from 'elements';
import { MemberDetails, Team } from 'services/constants';
import EditMemberStep from './EditMemberStep';

interface Props extends DialogProps {
  member: MemberDetails;
  managerTeam?: Team | undefined;
  onClose: () => void;
  onSuccess: (member: MemberDetails) => void;
}

export interface AllFormikValues {
  title: string;
  firstName: string;
  lastName: string;
  teamIds: string[];
}

export interface FormikHandlers {
  onSubmit: (
    values: AllFormikValues,
    helpers: FormikHelpers<AllFormikValues>
  ) => Promise<any> | void;
}

const EditMemberDialog = ({
  member,
  managerTeam,
  onSuccess,
  ...props
}: Props) => {
  const [formikHandlers, setFormikHandlers] = useState<FormikHandlers>({
    onSubmit: () => {},
  });
  const initialValues: AllFormikValues = {
    title: member.title || '',
    firstName: member.firstName,
    lastName: member.lastName,
    teamIds: member.teams.map((item) => item.teamId),
  };

  return (
    <Dialog {...props} maxWidth="sm">
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={formikHandlers.onSubmit}
      >
        <EditMemberStep
          member={member}
          managerTeam={managerTeam}
          onClose={props.onClose}
          onSuccess={onSuccess}
          setFormikHandlers={setFormikHandlers}
        />
      </Formik>
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditMemberDialog);
