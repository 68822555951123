import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { CardIconWithDetails } from 'domains/card/components';
import { ChangeCardLimitsDialog } from 'domains/card/dialogs';
import { LastTransactionsList } from 'domains/transaction/components';
import {
  ArrowsClockwiseIcon,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import { ContentContainer, SettingsGroupBox } from 'layout';
import { Card, Transaction } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';
import ManagePlatformFeeCardButton from './ManagePlatformFeeCardButton';

interface State {
  isLoading: boolean;
  platformFeeCard: Card | null;
  transactions: Transaction[] | null;
  totalTransactionCount: number;
  error: boolean;
  isDetailsDialogOpen: boolean;
  isChangeLimitsDialogOpen: boolean;
  isManagingLimitDialogOpen: boolean;
  isReplaceCardDialogOpen: boolean;
}

const MAX_TRANSACTIONS_FEE_COUNT = 3;

const PaymentDetailsAndLastFeeTransactionsSection = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const partnerName = usePartnerName();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    platformFeeCard: null,
    transactions: null,
    totalTransactionCount: 0,
    error: false,
    isDetailsDialogOpen: false,
    isChangeLimitsDialogOpen: false,
    isManagingLimitDialogOpen: false,
    isReplaceCardDialogOpen: false,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const platformFeeCard = await api.getPlatformFeeCard(organization!.id);
      const { transactions, totalCount } = await api.getTransactions({
        organizationId: organization!.id,
        cardId: platformFeeCard!.cardId,
        limit: MAX_TRANSACTIONS_FEE_COUNT,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        platformFeeCard,
        transactions,
        totalTransactionCount: totalCount,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        platformFeeCard: null,
        transactions: null,
        totalTransactionCount: 0,
        error: true,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <SettingsGroupBox>
        <ContentContainer>
          <Typography variant="h6" mb={2}>
            {t('subscriptionPlanSettingsSubPage.paymentDetailsSection.title')}
          </Typography>

          {state.isLoading && <CircularProgress size="small" />}

          {!state.isLoading && state.error && (
            <Box display="flex" alignItems="center">
              <Typography component="span" variant="body2" color="error.main">
                {t('errors.loadData')}
              </Typography>
              <Button
                size="small"
                variant="text"
                onClick={getData}
                sx={{ ml: 1 }}
              >
                {t('common.retry')}
              </Button>
            </Box>
          )}

          {!state.isLoading && !state.error && !!state.platformFeeCard && (
            <>
              <CardIconWithDetails
                cardConfig={state.platformFeeCard.cardConfig}
                cardDesignId={state.platformFeeCard.cardDesignId}
                cardRefNum={state.platformFeeCard.cardRefNum}
                cardName={state.platformFeeCard.cardName}
                cardSimpleStatus={state.platformFeeCard.simpleStatus}
              />

              {canUser('platform-fee-card:manage') && (
                <Grid
                  mt={2}
                  container
                  p={2}
                  sx={{
                    background: (theme) => theme.palette.secondary.light,
                    borderRadius: 1,
                  }}
                >
                  <Grid item xs={4} pr={2}>
                    <Typography variant="overline">
                      {t(
                        'int.subscriptionPlanSettingsSubPage.paymentDetailsSection.limit'
                      )}
                    </Typography>
                    <Box display="flex" alignItems="flex-end">
                      <Typography variant="h5">
                        <FormatMoney value={state.platformFeeCard.limit} />
                      </Typography>
                      <Typography>
                        &nbsp;/&nbsp;
                        {t(
                          'int.subscriptionPlanSettingsSubPage.paymentDetailsSection.month'
                        )}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3} pr={2}>
                    <Typography variant="overline">
                      {t(
                        'int.subscriptionPlanSettingsSubPage.paymentDetailsSection.transactionLimit'
                      )}
                    </Typography>
                    <Box display="flex" alignItems="flex-end">
                      <Typography variant="h5">
                        <FormatMoney
                          value={state.platformFeeCard.transactionLimit}
                          fractionalPart={
                            state.platformFeeCard.transactionLimit.value %
                              100 !==
                            0
                          }
                        />
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item display="flex" alignItems="center" pr={2}>
                    <Button
                      variant="outlined"
                      startIcon={<ArrowsClockwiseIcon />}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isChangeLimitsDialogOpen: true,
                        }))
                      }
                    >
                      {t(
                        'int.subscriptionPlanSettingsSubPage.paymentDetailsSection.changeLimits'
                      )}
                    </Button>

                    <ManagePlatformFeeCardButton
                      card={state.platformFeeCard}
                      onUpdate={(card: Card) =>
                        setState((prevState) => ({
                          ...prevState,
                          platformFeeCard: card,
                        }))
                      }
                    />
                  </Grid>
                </Grid>
              )}

              <Typography mt={3}>
                {t(
                  'subscriptionPlanSettingsSubPage.paymentDetailsSection.description',
                  { partnerName }
                )}
              </Typography>
            </>
          )}
        </ContentContainer>
      </SettingsGroupBox>

      {!!state.platformFeeCard && (
        <>
          <SettingsGroupBox>
            <ContentContainer>
              <Typography variant="h6" mb={2}>
                {t(
                  'subscriptionPlanSettingsSubPage.lastFeeTransactionsSection.title'
                )}
              </Typography>

              {state.transactions?.length ? (
                <Box width={500}>
                  <LastTransactionsList transactions={state.transactions} />
                </Box>
              ) : (
                <Typography>
                  {t(
                    'subscriptionPlanSettingsSubPage.lastFeeTransactionsSection.noTransactions'
                  )}
                </Typography>
              )}

              {state.totalTransactionCount > MAX_TRANSACTIONS_FEE_COUNT && (
                <Link
                  sx={{ mt: (theme) => theme.spacing(1) }}
                  variant="body1"
                  href={
                    generatePath(getPath('transactions'), {
                      orgId: organization!.id,
                    }) + `?cardId=${state.platformFeeCard.cardId}`
                  }
                >
                  {t(
                    'subscriptionPlanSettingsSubPage.lastFeeTransactionsSection.seeAll'
                  )}
                </Link>
              )}
            </ContentContainer>
          </SettingsGroupBox>
        </>
      )}

      <ChangeCardLimitsDialog
        card={state.platformFeeCard!}
        open={state.isChangeLimitsDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isChangeLimitsDialogOpen: false,
          }))
        }
        onSuccess={(card: Card) => {
          setState((prevState) => ({
            ...prevState,
            platformFeeCard: card,
            isChangeLimitsDialogOpen: false,
          }));
        }}
      />
    </>
  );
};

export default PaymentDetailsAndLastFeeTransactionsSection;
