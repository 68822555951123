import { matchPath, useHistory } from 'react-router-dom';
// imported directly from the paths.ts file because of a circular dependency issue
import { cardholderPaths } from 'components/App/ExternalApp/paths';
import { useGlobalState } from 'context/GlobalState';
import { env } from 'env';

const cardholderPathsArray = Object.values(cardholderPaths);

const useCurrentApp = () => {
  const history = useHistory();
  const {
    state: { organization },
  } = useGlobalState();

  const isCardholderAppPath = cardholderPathsArray.some((path) =>
    matchPath(history.location.pathname, {
      path,
    })
  );

  const isInternalApp =
    env.REACT_APP_BUILD_TARGET === 'internal' &&
    env.REACT_APP_PARTNER_SCOPE !== 'portal';

  return {
    isInternalApp:
      env.REACT_APP_BUILD_TARGET === 'internal' &&
      env.REACT_APP_PARTNER_SCOPE !== 'portal',
    isPortalApp: env.REACT_APP_PARTNER_SCOPE === 'portal',
    isPortalAppMirrorView:
      env.REACT_APP_PARTNER_SCOPE === 'portal' && !!organization,
    isExternalApp: env.REACT_APP_BUILD_TARGET === 'external',
    isAdminApp: !isCardholderAppPath,
    isCardholderApp: isCardholderAppPath && !isInternalApp, // some paths are the same for internal and cardholder apps
  };
};

export default useCurrentApp;
