import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'elements';
import { PrivateExpenseStatus, ReviewFlagReason } from 'services/constants';

interface Props {
  reviewFlagReason: ReviewFlagReason;
  privateExpenseStatus: PrivateExpenseStatus | null;
}

const getColorFromPrivateExpenseStatus = (
  privateExpenseStatus: PrivateExpenseStatus | null
) => {
  switch (privateExpenseStatus) {
    case PrivateExpenseStatus.reimbursementOutstanding:
      return 'warning.main';
    case PrivateExpenseStatus.reimbursementPending:
      return 'info.main';
    default:
      return 'text.secondary';
  }
};

const TransactionReviewFlagReasonCell = ({
  reviewFlagReason,
  privateExpenseStatus,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box textAlign="right" overflow="hidden">
      {reviewFlagReason === ReviewFlagReason.privateExpense &&
        privateExpenseStatus &&
        [
          PrivateExpenseStatus.reimbursementOutstanding,
          PrivateExpenseStatus.reimbursementPending,
        ].includes(privateExpenseStatus) && (
          <Typography
            variant="caption2"
            color={getColorFromPrivateExpenseStatus(privateExpenseStatus)}
            noWrap
            sx={[
              {
                textTransform: 'uppercase',
                display: 'block',
              },
            ]}
            title={t(`privateExpenseStatuses.${privateExpenseStatus}`)}
          >
            {t(`privateExpenseStatuses.${privateExpenseStatus}`)}
          </Typography>
        )}
      <Typography textAlign="left">
        {t(`reviewFlagReasons.${reviewFlagReason}`)}
      </Typography>
    </Box>
  );
};

export default TransactionReviewFlagReasonCell;
