import { useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import AppLogo from 'components/AppLogo';
import { Button } from 'components/Button';
import { LoaderWithOverlay } from 'components/Loader';
import {
  Paper,
  PaperDescription,
  PaperHeading,
  PaperHolder,
} from 'components/Paper';
import { useGlobalState } from 'context/GlobalState';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MembershipStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import AcceptTermsSection from './AcceptTermsSection';

const MembershipActivationPage = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { orgId } = useParams<{ orgId: string }>();
  const {
    state: { member, memberships },
  } = useGlobalState();
  const [didAcceptTac, setDidAcceptTac] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const membershipToActivate = memberships.find(
    (item) => item.organizationId === orgId
  );

  const activateMembership = async () => {
    try {
      setIsLoading(true);
      await api.activateMember(member.id, { organizationId: orgId });
      // The user's organization will be switched to the activated one.
      // Reload the app to get an updated token, member, organization, etc.
      window.location.replace('/');
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  if (
    !membershipToActivate ||
    membershipToActivate.status !== MembershipStatus.invited
  )
    return <Redirect to="/" />;

  return (
    <PaperHolder>
      <Paper $width="930px">
        <AppLogo />
        <PaperHeading>{t('membershipActivationPage.heading')}</PaperHeading>
        <PaperDescription>
          {t('membershipActivationPage.description')}
        </PaperDescription>
        <AcceptTermsSection
          value={didAcceptTac}
          onChange={(e, checked) => setDidAcceptTac(checked)}
        />
        <Box position="absolute" right="48px" bottom="48px">
          <Button
            onClick={activateMembership}
            disabled={!didAcceptTac || isLoading}
            $px={80}
          >
            {t('common.button.submit')}
          </Button>
        </Box>
        {isLoading && <LoaderWithOverlay size={32} thickness={3} />}
      </Paper>
    </PaperHolder>
  );
};

export default MembershipActivationPage;
