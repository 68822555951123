import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  CardCategoryControlFormElement,
  CardDateControlFormElement,
  CardLocationControlFormElement,
  CardMerchantControlFormElement,
  CardTimeControlFormElement,
  DialogStepper,
} from 'domains/card/components';
import { FormValues, Step } from 'domains/card/dialogs/CardRequestDialog';
import useStepManager from 'domains/card/dialogs/CardRequestDialog/useStepManager';
import { useAvailableCardControlConfigs } from 'domains/card/hooks';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'elements';

interface Props {
  onClose: () => void;
}

const ControlsStep = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const stepManager = useStepManager();
  const formik = useFormikContext<FormValues>();
  const configs = useAvailableCardControlConfigs(
    formik.values.cardConfigSetting!
  );

  // Support "required" only for the location region
  // and merchant match term controls for now.
  const isDisabled =
    (!!configs.locations.REGION?.required &&
      !formik.values.locationControl?.values.length) ||
    (!!configs.merchants.MATCH_TERM?.required &&
      !formik.values.merchantControl?.values.length);

  return (
    <>
      <DialogStepper<Step>
        currentStep={stepManager.currentStep}
        steps={stepManager.steps}
        goTo={stepManager.goTo}
        getStepLabel={stepManager.getStepLabel}
      />
      <DialogTitle>{t('cardRequestDialog_v2.controlsStep.title')}</DialogTitle>
      <DialogContent>
        <CardCategoryControlFormElement
          mccGroup={formik.values.cardConfigSetting!.mccGroup}
          configs={configs.categories}
          control={formik.values.categoryControl}
          onChange={(categoryControl) =>
            formik.setValues((prevValues) => ({
              ...prevValues,
              categoryControl,
            }))
          }
        />
        <CardMerchantControlFormElement
          configs={configs.merchants}
          control={formik.values.merchantControl}
          onChange={(merchantControl) =>
            formik.setValues((prevValues) => ({
              ...prevValues,
              merchantControl,
            }))
          }
        />
        <CardDateControlFormElement
          configs={configs.dates}
          control={formik.values.dateControl}
          onChange={(dateControl) =>
            formik.setValues((prevValues) => ({
              ...prevValues,
              dateControl,
            }))
          }
        />
        <CardTimeControlFormElement
          configs={configs.times}
          control={formik.values.timeControl}
          onChange={(timeControl) =>
            formik.setValues((prevValues) => ({
              ...prevValues,
              timeControl,
            }))
          }
        />
        <CardLocationControlFormElement
          configs={configs.locations}
          control={formik.values.locationControl}
          onChange={(locationControl) =>
            formik.setValues((prevValues) => ({
              ...prevValues,
              locationControl,
            }))
          }
        />
      </DialogContent>
      <DialogActions>
        <Box flexGrow="1">
          <Button variant="text" onClick={stepManager.goPrev}>
            {t('common.button.back')}
          </Button>
        </Box>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button onClick={stepManager.goNext} disabled={isDisabled}>
          {t('common.button.continue')}
        </Button>
      </DialogActions>
    </>
  );
};

export default ControlsStep;
