import { Radio as MuiRadio, RadioProps as MuiRadioProps } from '@mui/material';

export interface RadioProps extends MuiRadioProps {
  /**
   * The color of the component.
   * @default 'primary'
   */
  color?: 'primary' | 'default';
}

const Radio = (props: RadioProps) => <MuiRadio {...props} />;

export default Radio;
