import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import {
  DownloadPainFileButton,
  PaymentStatusBadge,
} from 'domains/billing/components';
import { Box, Stack, Tooltip, Typography } from 'elements';
import {
  PaymentStatus,
  PaymentType,
  ProgramSettlement,
  ProgramSettlementPaymentsFile,
} from 'services/constants';

const visiblePaymentStatuses = [
  PaymentStatus.readyForPmt,
  PaymentStatus.pending,
  PaymentStatus.paid,
  PaymentStatus.failed,
] as const;

interface Props {
  data: ProgramSettlementPaymentsFile;
  dateTooltip: string;
  settlement: ProgramSettlement;
}

const ProgramSettlementPaymentsFileDetails = ({
  data,
  dateTooltip,
  settlement,
}: Props) => {
  const { t } = useTranslation();
  const date = moment(data.createdAt);

  if (data.paymentType === PaymentType.orgSetlPmt) {
    return (
      <Box
        display="flex"
        alignItems="center"
        p={2}
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <Box flex={350}>
          <Typography variant="caption" color="text.secondary" noWrap>
            {t(`paymentTypes.${data.paymentType}`).toUpperCase()}
          </Typography>
          <Tooltip title={dateTooltip}>
            <Typography variant="body2">
              {date.format('YYYY-MM-DD')}{' '}
              <Typography
                variant="inherit"
                component="span"
                color="text.secondary"
              >
                {date.format('HH:mm')}
              </Typography>
            </Typography>
          </Tooltip>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2" noWrap>
              {t('int.programSettlementDetailsPage.osps', {
                count: visiblePaymentStatuses
                  .map((key) => data.paymentsStatuses[key] || 0)
                  .reduce((s, v) => s + v),
              })}
            </Typography>
            {visiblePaymentStatuses
              .filter((paymentStatus) => !!data.paymentsStatuses[paymentStatus])
              .map((paymentStatus) => (
                <PaymentStatusBadge
                  key={paymentStatus}
                  status={paymentStatus}
                  count={data.paymentsStatuses[paymentStatus]}
                />
              ))}
          </Stack>
        </Box>
        <Box flex={120} textAlign="right">
          <Typography variant="body2" noWrap>
            <FormatMoney value={data.amount} fractionalPart />
          </Typography>
        </Box>
        <Box flex={50} textAlign="right">
          {data.paymentFileName && (
            <DownloadPainFileButton painFileName={data.paymentFileName} />
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      p={2}
      borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
    >
      <Box flex={230}>
        <Typography variant="caption" color="text.secondary" noWrap>
          {[PaymentType.programSetlPmt, PaymentType.programSetlFeePmt].includes(
            data.paymentType
          )
            ? settlement.settlementPaymentType.toUpperCase() + ' '
            : ''}
          {t(`paymentTypes.${data.paymentType}`).toUpperCase()}
        </Typography>
        <Tooltip title={dateTooltip}>
          <Typography variant="body2">
            {date.format('YYYY-MM-DD')}{' '}
            <Typography
              variant="inherit"
              component="span"
              color="text.secondary"
            >
              {date.format('HH:mm')}
            </Typography>
          </Typography>
        </Tooltip>
      </Box>
      <Box flex={120}>
        {visiblePaymentStatuses
          .filter((paymentStatus) => !!data.paymentsStatuses[paymentStatus])
          .map((paymentStatus) => (
            <PaymentStatusBadge key={paymentStatus} status={paymentStatus} />
          ))}
      </Box>
      <Box flex={120} textAlign="right">
        <Typography variant="body2" noWrap>
          <FormatMoney value={data.amount} fractionalPart />
        </Typography>
      </Box>
      <Box flex={50} textAlign="right">
        {data.paymentFileName && (
          <DownloadPainFileButton painFileName={data.paymentFileName} />
        )}
      </Box>
    </Box>
  );
};

export default ProgramSettlementPaymentsFileDetails;
