import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, FormControl, FormHelperText } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DEFAULT_AUTOCOMPLETE_DELAY,
  NaceIndustryCode,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  error?: string;
  value: NaceIndustryCode | null;
  onChange: (value: NaceIndustryCode | null) => void;
}

interface State {
  isLoading: boolean;
  naceIndustries: NaceIndustryCode[];
}

const NaceIndustryCodeAutoComplete = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [inputValue, setInputValue] = useState('');
  const [state, setState] = useState<State>({
    naceIndustries: [],
    isLoading: false,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const inputValueCode = /^([\d.]+)/.exec(inputValue)![0]; // input value includes description
      const { naceIndustries } = await api.getNaceIndustryCodeSearch(
        inputValueCode
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        naceIndustries,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (!inputValue.trim()) {
      setState((prevState) => ({ ...prevState, naceIndustries: [] }));
      return;
    }

    let active = true;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);

    return () => {
      active = false;
    };
  }, [inputValue.trim()]);

  return (
    <FormControl fullWidth error={!!props.error}>
      <Autocomplete<NaceIndustryCode, false, false, false>
        error={!!props.error}
        inputValue={inputValue}
        onInputChange={(_, inputValue) => {
          setInputValue(inputValue);
        }}
        value={props.value}
        onChange={(_, value) => props.onChange(value)}
        options={state.naceIndustries}
        loading={state.isLoading}
        filterOptions={(x) => x}
        isOptionEqualToValue={(option, value) => option.code === value?.code}
        getOptionLabel={(option) => `${option.code} - ${option.description}`}
        label={t('int.editCustomerSummaryDialog.industryCode')}
        loadingText={t('common.loading')}
        noOptionsText={t('common.nothingFound')}
      />
      <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
  );
};

export default NaceIndustryCodeAutoComplete;
