import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { FormControl, InputLabel, MenuItem, Select } from 'elements';
import { PartnerIds } from 'services/constants';

const supportedThemes = [
  { label: 'Mobilexpense Declaree', id: PartnerIds.mobilexpenseDeclaree },
  { label: 'Circula', id: PartnerIds.circula },
  { label: 'Candis', id: PartnerIds.candis },
  { label: 'Intertours', id: PartnerIds.intertours },
  { label: 'BBVA', id: PartnerIds.bbva },
  { label: 'Bizaway', id: PartnerIds.bizaway },
  { label: 'DZ Bank', id: PartnerIds.dzbank },
  { label: 'VR Bank', id: PartnerIds.vrbank },
  { label: 'Commerzbank SME & FK', id: PartnerIds.commerzbankSme },
  { label: 'CO2MOVE', id: PartnerIds.co2move },
  { label: 'Pliant', id: PartnerIds.pliant },
  { label: 'Pliant Internal', id: 'PLIANT_INTERNAL_APP' },
];

const ThemeSelect = () => {
  const {
    state: { themeId },
    dispatch,
  } = useGlobalState();
  const supportedThemeId = useMemo(
    () =>
      supportedThemes.some((item) => item.id === themeId)
        ? themeId
        : PartnerIds.pliant,
    [themeId]
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="qa-theme-select-label">Theme</InputLabel>
      <Select<string>
        value={supportedThemeId}
        onChange={(e) =>
          dispatch({ type: 'SET_THEME_ID', payload: e.target.value })
        }
        renderValue={(selected) =>
          supportedThemes.find((item) => item.id === selected)?.label
        }
        labelId="qa-theme-select-label"
      >
        {supportedThemes.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ThemeSelect;
