import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Auth0Logo from 'assets/svg/auth0Logo.svg?react';
import AwsLogo from 'assets/svg/awsLogo.svg?react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  StatusBadge,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  MemberDetails,
  SmsLog,
  SmsLogSource,
  SmsLogStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  member: MemberDetails;
  onClose: () => void;
}

const SmsLogsDialog = ({ member, ...props }: Props) => {
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [logs, setLogs] = useState<SmsLog[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const smsLogs = await api.getSmsLogs(member.id);
        if (!mounted.current) return;
        setLogs(smsLogs);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      } finally {
        if (mounted.current) setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Dialog {...props}>
      <DialogTitle>{t('int.smsLogsDialog.title')}</DialogTitle>
      <DialogContent>
        <TableContainer sx={{ height: 400 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t('int.smsLogsDialog.date')}</TableCell>
                <TableCell>{t('int.smsLogsDialog.service')}</TableCell>
                <TableCell>{t('int.smsLogsDialog.status')}</TableCell>
                <TableCell>{t('int.smsLogsDialog.message')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log) => {
                const date = new Date(log.timestamp);
                return (
                  <TableRow key={log.id}>
                    <TableCell>
                      <Typography variant="body2" color="textSecondary">
                        {date.toLocaleDateString()}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {date.toLocaleTimeString()}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        svg: {
                          verticalAlign: 'middle',
                          color: 'primary.main',
                        },
                      }}
                    >
                      {log.source === SmsLogSource.AWS && (
                        <AwsLogo height="24px" width="24px" />
                      )}
                      {log.source === SmsLogSource.auth0 && (
                        <Auth0Logo height="24px" width="24px" />
                      )}
                    </TableCell>
                    <TableCell>
                      <StatusBadge
                        label={log.status}
                        color={
                          log.status === SmsLogStatus.FALIURE
                            ? 'error'
                            : 'success'
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {log.message}
                      <Typography variant="body2" color="textSecondary">
                        {log.phoneNumber}
                      </Typography>
                      {log.source === SmsLogSource.auth0 && (
                        <Typography variant="body2" color="textSecondary">
                          {log.location || log.ip}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t('common.button.close')}</Button>
      </DialogActions>
      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(SmsLogsDialog);
