import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import { DialogProps, withDialogWrapper } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MemberDetails, NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
  member: MemberDetails;
}

const ResetPhoneNumberDialog = ({ onSuccess, member, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { logout } = useAuth0();
  const {
    state: { member: currentMember },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const isOwnNumber = currentMember.id === member.id;

  const resetPhoneNumber = async () => {
    try {
      setIsLoading(true);
      await api.updateSecurityKey(member.organizationId, member.id);
      if (isOwnNumber) {
        return logout({
          returnTo: window.location.origin,
        });
      }
      if (!mounted.current) return;
      setIsLoading(false);
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      if (
        getNetworkErrorCode(error) ===
        NetworkErrorCode.changeMfaRequestAlreadyExists
      ) {
        enqueueSnackbar(
          t('resetPhoneNumberDialog.changeMfaRequestAlreadyExistsError'),
          {
            persist: true,
            variant: 'error',
          }
        );
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    }
  };

  return (
    <ConfirmDialog
      {...props}
      loading={isLoading}
      onSuccess={resetPhoneNumber}
      title={
        isOwnNumber
          ? t('resetPhoneNumberDialog.ownNumberHeading')
          : t('resetPhoneNumberDialog.heading')
      }
      description={
        isOwnNumber
          ? t('resetPhoneNumberDialog.ownNumberDescription')
          : t('resetPhoneNumberDialog.description', {
              fullName: `${member.firstName} ${member.lastName}`,
            })
      }
    />
  );
};

export default withDialogWrapper<Props>(ResetPhoneNumberDialog);
