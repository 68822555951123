import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { HeaderContainer, HeaderTitle } from 'domains/settings/layout';
import { Typography } from 'elements';
import { ContentContainer } from 'layout';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';
import ExportSettingsGroup from './ExportSettingsGroup';

// Consider adding general acc page for some acc systems.
// For now BMD page should partially look like DATEV.
// We don't have specific requirements for a general acc system page yet.
const BmdSubPage = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { accountingSettings, organization },
  } = useGlobalState();

  const canViewPage = canUser('bmd-sub-page:visit', accountingSettings);

  if (!canViewPage)
    return (
      <Redirect
        to={generatePath(getPath('settingsAccounting'), {
          orgId: organization!.id,
        })}
      />
    );

  return (
    <ContentContainer>
      <HeaderContainer mb={4}>
        <HeaderTitle>
          {t('bmdSubPage.mainTitle', {
            name: accountingSettings!.accountingSystemName,
          })}
        </HeaderTitle>
        <Typography>
          {t('bmdSubPage.title', {
            name: accountingSettings!.accountingSystemName,
          })}
        </Typography>
      </HeaderContainer>

      <ExportSettingsGroup />
    </ContentContainer>
  );
};

export default BmdSubPage;
