import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  MemberRoleOverline,
  MemberStatusBadge,
  MemberTypeOverline,
} from 'domains/member/components';
import {
  DataGridCell,
  drawerPlaceholderColumn,
  GridColDef,
  gridUtils,
} from 'elements';
import { Member } from 'services/constants';
import { getFullName } from 'services/utils';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<Member>[] = [
      {
        field: 'firstName',
        headerName: t('globalMembersPage.name'),
        flex: 150,
        renderCell: ({ row }) => (
          <DataGridCell>
            {getFullName(row.firstName, row.lastName)}
            <MemberRoleOverline
              roles={row.roles}
              ml={1}
              color="textSecondary"
              sx={{
                verticalAlign: 'top',
              }}
            />
            {row.embeddedPartnerMembership && (
              <MemberTypeOverline
                isNewDesign
                roles={row.roles}
                color="textSecondary"
                sx={{
                  verticalAlign: 'top',
                }}
              />
            )}
          </DataGridCell>
        ),
      },
      {
        field: 'organizationName',
        headerName: t('globalMembersPage.org'),
        flex: 150,
      },
      {
        field: 'status',
        headerName: t('globalMembersPage.status'),
        flex: 120,
        renderCell: ({ row }) => <MemberStatusBadge status={row.status} />,
      },
      {
        field: 'lastActiveAt',
        headerName: t('globalMembersPage.lastActive'),
        flex: 100,
        valueGetter: ({ row }) => moment(row.lastActiveAt).format('D MMM YYYY'),
      },
      {
        field: 'registeredAt',
        headerName: t('globalMembersPage.registeredSince'),
        flex: 100,
        valueGetter: ({ row }) => moment(row.registeredAt).format('D MMM YYYY'),
      },
      drawerPlaceholderColumn,
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language]);
};

export default useColumns;
