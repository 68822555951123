import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import { Table, TableBody, TableCell, TableRow, Typography } from 'elements';
import { AccountingSystem } from 'services/constants';
import { isAccSystemDownloadVisible } from 'services/utils';

interface Props {
  ReceiptsToggle: JSX.Element;
}

const CommonExportFormats = ({ ReceiptsToggle }: Props) => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings },
  } = useGlobalState();

  const isBmdEnabled = isAccSystemDownloadVisible(
    accountingSettings,
    AccountingSystem.bmd
  );
  const isLexwareEnabled = isAccSystemDownloadVisible(
    accountingSettings,
    AccountingSystem.lexware
  );
  const isCamtEnabled = isAccSystemDownloadVisible(
    accountingSettings,
    AccountingSystem.camt
  );
  const isDemyEnabled = isAccSystemDownloadVisible(
    accountingSettings,
    AccountingSystem.customDemy
  );
  const isCustomManningTravelEnabled = isAccSystemDownloadVisible(
    accountingSettings,
    AccountingSystem.customManningTravel
  );
  const isCustomKtsEnabled = isAccSystemDownloadVisible(
    accountingSettings,
    AccountingSystem.customKts
  );
  const isAeromonEnabled = isAccSystemDownloadVisible(
    accountingSettings,
    AccountingSystem.aeromon
  );

  const getFormatLabel = () => {
    if (isBmdEnabled) return t('exportTransactionsDialog.bmdCsv');
    if (isCamtEnabled)
      return t('exportTransactionsDialog.accSystemXml', {
        name: accountingSettings!.accountingSystemName,
      });
    if (isLexwareEnabled)
      return t('exportTransactionsDialog.accSystemCsv', {
        name: accountingSettings!.accountingSystemName,
      });
    if (isCodatAccSystemConnected(accountingSettings))
      return t('exportTransactionsDialog.accSystemApiLabel', {
        name: accountingSettings!.accountingSystemName,
      });
    if (
      isDemyEnabled ||
      isCustomManningTravelEnabled ||
      isCustomKtsEnabled ||
      isAeromonEnabled
    )
      return t('exportTransactionsDialog.accSystemCustomCsv');

    return 'CSV';
  };

  return (
    <>
      <Table sx={{ marginTop: -2 }}>
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: '48%' }}>
              <Typography variant="subtitle2">
                {t('exportTransactionsDialog.exportFormat')}
              </Typography>
            </TableCell>
            <TableCell>{getFormatLabel()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {!isBmdEnabled && !isLexwareEnabled && !isCamtEnabled && ReceiptsToggle}
    </>
  );
};

export default CommonExportFormats;
