import { capitalize } from 'lodash';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { decodeValuesToDates } from 'domains/card/utils';
import { Tooltip, Typography } from 'elements';
import { CardDateControl, CardDateControlType } from 'services/constants';
import RestrictionBadge from './RestrictionBadge';
import { Header, StyledChip, Wrapper } from './style';

interface Dates {
  startDate?: string | Moment;
  endDate?: string | Moment;
}

interface Props {
  control: CardDateControl | null;
}

const CardDateControlIndicator = ({ control }: Props) => {
  const { t } = useTranslation();

  if (!control || !control.values.length) return null;

  const { restriction, type, values } = control;
  const dates = decodeValuesToDates(type, values);

  const toOrdinalNumber = (day: string | null) => {
    if (!day) return day;

    if (day === 'last') return t('common.ordinalNumbers.endOfMonth');

    const dayNumber = parseInt(day, 10);
    if (dayNumber > 3 && dayNumber < 21)
      return `${dayNumber}${t('common.ordinalNumbers.suffixes.generic')}`;

    switch (dayNumber % 10) {
      case 1:
        return `${dayNumber}${t('common.ordinalNumbers.suffixes.first')}`;
      case 2:
        return `${dayNumber}${t('common.ordinalNumbers.suffixes.second')}`;
      case 3:
        return `${dayNumber}${t('common.ordinalNumbers.suffixes.third')}`;
      default:
        return `${dayNumber}${t('common.ordinalNumbers.suffixes.generic')}`;
    }
  };

  const renderValues = () => {
    switch (type) {
      case CardDateControlType.fixed: {
        const { startDate, endDate } = dates as Dates;

        const formattedStartDate = moment(startDate, 'DD MMM YYYY').format(
          'DD MMM YYYY'
        );
        const formattedEndDate = moment(endDate, 'DD MMM YYYY').format(
          'DD MMM YYYY'
        );
        const text =
          formattedStartDate === formattedEndDate
            ? formattedStartDate
            : `${formattedStartDate} - ${formattedEndDate}`;

        return (
          <Tooltip title={text}>
            <StyledChip label={text} />
          </Tooltip>
        );
      }

      case CardDateControlType.yearly: {
        const { startDate, endDate } = dates as Dates;

        const formattedStartDate = moment(startDate, 'DD.MM').format('DD MMM');
        const formattedEndDate = moment(endDate, 'DD.MM').format('DD MMM');
        const text =
          formattedStartDate === formattedEndDate
            ? formattedStartDate
            : `${formattedStartDate} - ${formattedEndDate}`;

        return (
          <Tooltip title={text}>
            <StyledChip label={text} />
          </Tooltip>
        );
      }

      case CardDateControlType.monthly: {
        const { startDate, endDate } = dates as Dates;

        const text =
          startDate === endDate
            ? toOrdinalNumber(startDate as string)
            : `${toOrdinalNumber(startDate as string)} - ${toOrdinalNumber(
                endDate as string
              )}`;

        return (
          <Tooltip title={text || ''}>
            <StyledChip label={text} />
          </Tooltip>
        );
      }

      case CardDateControlType.weekly: {
        const weeklyDates = dates as string[];

        return weeklyDates.map((item) => {
          return (
            <Tooltip key={item} title={capitalize(item)}>
              <StyledChip label={capitalize(item).substring(0, 3)} />
            </Tooltip>
          );
        });
      }

      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <Header>
        <Typography variant="subtitle2">
          {t(`cardControlIndicator.date.titles.${type}`)}
        </Typography>
        <RestrictionBadge restriction={restriction} />
      </Header>

      {renderValues()}
    </Wrapper>
  );
};

export default CardDateControlIndicator;
