import styled from 'styled-components';
import AlertWarningTriangleIcon from 'assets/icons/interface/alertWarningTriangle.svg?react';
import { CircleQuestionMarkIcon } from 'components/Tooltip';

export const Group = styled.div<{ $alignItems?: string }>`
  display: flex;
  align-items: ${({ $alignItems = 'normal' }) => $alignItems};
  margin: 0 0 24px;
`;

export const GroupLabel = styled.div<{ $lineHeight?: number }>`
  width: 280px;
  font-size: 14px;
  line-height: ${({ $lineHeight = 18 }) => $lineHeight}px;
`;

export const GroupDescription = styled.div<{ $pt?: number }>`
  padding-top: ${({ $pt = 0 }) => $pt}px;
  font-size: 14px;
  line-height: 18px;
`;

export const StyledCircleQuestionMarkIcon = styled(CircleQuestionMarkIcon)`
  margin: 0 0 0 10px;
`;

export const ItemParagraph = styled.p<{ $pt?: number }>`
  margin: 0;
  padding: ${({ $pt = 0 }) => `${$pt}px 0 0`};
  font-size: 14px;
  line-height: 17px;
`;

export const StyledAlertWarningTriangleIcon = styled(AlertWarningTriangleIcon)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.red10};
  margin: auto 0 auto 8px;
`;
