import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import NoData from 'components/NoData';
import { useGlobalState } from 'context/GlobalState';
import {
  MerchantCell,
  TransactionAmount,
} from 'domains/transaction/components';
import {
  ArrowsLeftRightIcon,
  Box,
  Link,
  LoaderWithOverlay,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { TransactionPageableList } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getPath } from 'services/utils';

interface State {
  isLoading: boolean;
  data: TransactionPageableList | null;
}

const LastTransactions = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    data: null,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const transactionsData = await api.getTransactions({
        organizationId: organization!.id,
        page: 0,
        limit: 5,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        data: transactionsData,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const { isLoading, data } = state;

  return (
    <Paper variant="outlined" sx={{ position: 'relative', height: '100%' }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        <Typography variant="subtitle1">
          {t('dashboardPage.lastTransactions')}
        </Typography>
        {!!data?.transactions.length && (
          <Link
            variant="body2"
            component={RouterLink}
            to={generatePath(getPath('transactions'), {
              orgId: organization!.id,
            })}
            sx={{ mr: 1 }}
          >
            {t('dashboardPage.seeAll')}
          </Link>
        )}
      </Box>
      <TableContainer
        sx={(theme) => ({
          padding: theme.spacing(0, 2, 2),
        })}
      >
        <Table component="div" size="small">
          <TableBody component="div">
            {data?.transactions.map((transaction) => {
              const transactionPath = generatePath(
                getPath('transactionDetails'),
                {
                  orgId: organization!.id,
                  transactionId: transaction.transactionId,
                }
              );

              return (
                <TableRow
                  key={transaction.transactionId}
                  component={RouterLink}
                  to={transactionPath}
                  sx={(theme) => ({
                    textDecoration: 'none',
                    '&:hover': {
                      background: theme.palette.action.hover,
                    },
                    '.MuiTableCell-root': { px: 1, border: 0 },
                  })}
                >
                  <TableCell
                    component="div"
                    sx={{ overflow: 'hidden', maxWidth: 200 }}
                  >
                    <MerchantCell transaction={transaction} />
                  </TableCell>
                  <TableCell
                    component="div"
                    sx={{ overflow: 'hidden', maxWidth: 120 }}
                  >
                    <Typography variant="inherit" noWrap>
                      {transaction.memberFirstName} {transaction.memberLastName}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                      noWrap
                    >
                      {moment.utc(transaction.createdAt).format('D MMM YYYY')}
                    </Typography>
                  </TableCell>
                  <TableCell component="div" align="right">
                    <TransactionAmount
                      status={transaction.status}
                      simpleType={transaction.simpleType}
                    >
                      <FormatMoney
                        value={transaction.transactionAmount}
                        fractionalPart
                        positiveSign
                      />
                    </TransactionAmount>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {!isLoading && !data?.transactions.length && (
        <NoData
          Icon={ArrowsLeftRightIcon}
          label={t('dashboardPage.noTransactions')}
          isNewDesign
          sx={{ position: 'absolute', top: 0, left: 0 }}
        />
      )}

      <LoaderWithOverlay loading={isLoading} size="small" />
    </Paper>
  );
};

export default LastTransactions;
