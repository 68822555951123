import { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Paper,
  TrashIcon,
} from 'elements';
import { countries } from 'services/constants';

export interface CitizenshipsGroupProps {
  disabled: boolean;
  addFieldDisabled: boolean;
  isLoading: boolean;
  onFieldsNumberChange: (citizenships: string[]) => void;
  onChange: (index: number, value: string) => void;
  onErrorChange: (errors: FormikErrors<string>[]) => void;
  citizenships: string[];
  errors: string | string[] | FormikErrors<string>[];
  hideAddButton?: boolean;
}

const CitizenshipsGroup = ({
  hideAddButton,
  disabled,
  addFieldDisabled,
  isLoading,
  citizenships,
  onFieldsNumberChange,
  onChange,
}: CitizenshipsGroupProps) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={4}>
      {citizenships.map((_, index) => (
        <Grid key={index} item xs={12}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={11}>
                <Autocomplete<
                  { countryName: string; countryCode: string },
                  false,
                  false,
                  false
                >
                  searchIcon={false}
                  disabled={isLoading || disabled}
                  onChange={(_, option) =>
                    onChange(index, option?.countryCode ?? '')
                  }
                  value={
                    citizenships[index]
                      ? countries.find(
                          (c) => c.countryCode === citizenships[index]
                        )
                      : null
                  }
                  options={countries}
                  getOptionLabel={(option) =>
                    `${option.countryName} (${option.countryCode})`
                  }
                  getOptionDisabled={(option) =>
                    citizenships.includes(option.countryCode)
                  }
                  id={`countryCode-${index}`}
                  label={t('orgOnboardingCitizenshipsTask.citizenshipLabel')}
                  noOptionsText={t('common.nothingFound')}
                />
              </Grid>
              <Grid
                item
                xs={1}
                display="flex"
                alignItems="flex-end"
                justifyContent="center"
              >
                {!!index && (
                  <IconButton
                    disabled={isLoading || disabled}
                    onClick={() =>
                      onFieldsNumberChange([
                        ...citizenships.slice(0, index),
                        ...citizenships.slice(index + 1),
                      ])
                    }
                    aria-label="Delete"
                  >
                    <TrashIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}

      {!disabled && !hideAddButton && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            disabled={isLoading || addFieldDisabled}
            onClick={() => onFieldsNumberChange([...citizenships, ''])}
          >
            {t('orgOnboardingCitizenshipsTask.addCitizenshipBtn')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default CitizenshipsGroup;
