import { useEffect, useRef, useState } from 'react';
import intersection from 'lodash/intersection';
import { useTranslation } from 'react-i18next';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  InviteMemberButtonWithDialog,
  MembersPageFilters,
} from 'domains/member/components';
import { PartnerMemberDetailsPage } from 'domains/member/pages';
import {
  DataGrid,
  gridUtils,
  LoaderWithOverlay,
  useGridApiRef,
  UsersIcon,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import {
  PageHeader,
  PageTableContent,
  PageTitle,
  PageTitleActions,
} from 'layout';
import {
  DEFAULT_PAGE_LIMIT,
  memberStatuses,
  PartnerMember,
  PartnerMemberMappedDetails,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { PARTNER_PORTAL_ROLES } from 'services/rbac';
import { isSortValid } from 'services/utils';
import useColumns from './useColumns';

const getQueryParams = (qs: string, allowedSortKeys: string[]) => {
  const { sort, q, role, status } = Object.fromEntries(
    new URLSearchParams(qs).entries()
  );

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '+status',
    q: q ? q.trim() : '',
    role: role ? intersection(role.split(','), PARTNER_PORTAL_ROLES) : [],
    status: status ? intersection(status.split(','), memberStatuses) : [],
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({ role, status }: QueryParams) =>
  +!!role.length + +!!status.length;

interface State {
  isLoading: boolean;
  members: PartnerMember[];
  hasNextPage: boolean;
  totalCount: number;
}

const PartnerMembersPage = () => {
  const { t } = useTranslation();
  const { url, path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { member },
  } = useGlobalState();
  const { detailsParams } = useIsDetailsPageOpen('/:memberId', true);
  const showPageError = useShowPageError();
  const { allowedSortKeys, columns } = useColumns();
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(getQueryParams(location.search, allowedSortKeys));
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const [state, setState] = useState<State>({
    isLoading: true,
    members: [],
    hasNextPage: false,
    totalCount: 0,
  });
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const { sort, q, role, status } = paramsRef.current;
      const { members, hasNextPage, totalCount } = await api.getPartnerMembers({
        partnerCompanyId: (member as PartnerMemberMappedDetails)
          .partnerCompanyId,
        page,
        limit,
        sort,
        q: q || undefined,
        roles: role.length ? role.join() : undefined,
        status: status.length ? status.join() : undefined,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        isLoading: false,
        members: isLoadMore ? [...prevState.members, ...members] : members,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });

    paramsRef.current = getQueryParams(location.search, allowedSortKeys);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const onMemberUpdate = (member: PartnerMember) => {
    setState((prevState) => ({
      ...prevState,
      members: prevState.members.map((item) =>
        item.id === member.id ? { ...item, ...member } : item
      ),
    }));
  };

  const onMemberInvite = () => {
    pageRef.current = 0;
    getData(pageRef.current);
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('portal.partnerMembersPage.title')}>
          <PageTitleActions>
            <InviteMemberButtonWithDialog
              isPartnerMember
              label={t('portal.partnerMembersPage.inviteUser')}
              dataTestId="invite-member-btn"
              onSuccess={onMemberInvite}
              isNewDesign
            />
          </PageTitleActions>
        </PageTitle>
        <MembersPageFilters
          shownRoles={PARTNER_PORTAL_ROLES}
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          membersCount={state.totalCount}
        />
      </PageHeader>

      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid<PartnerMember>
          apiRef={dataGridRef}
          rowHeight={72}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          rowCount={state.totalCount}
          rowSelectionModel={
            detailsParams?.memberId ? [detailsParams.memberId] : []
          }
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(paramsRef.current.sort),
            },
          }}
          loading={state.isLoading}
          rows={state.members}
          columns={columns}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id, row }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${row.id}${location.search}`);
          }}
          onSortModelChange={(sort) => {
            if (state.isLoading || !state.members.length) return;
            setQueryParam('sort', gridUtils.getNewSortParam(sort));
          }}
          slots={{
            noRowsOverlay: () =>
              areFiltersApplied ? (
                <NothingFound />
              ) : (
                <NoData
                  isNewDesign
                  Icon={UsersIcon}
                  label={t('portal.partnerMembersPage.notFound')}
                />
              ),
            loadingOverlay: () => null,
          }}
        />

        <Route
          path={`${path}/:memberId`}
          children={({ match }) => (
            <PartnerMemberDetailsPage
              open={!!match}
              onUpdate={onMemberUpdate}
            />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(PartnerMembersPage, {
  permission: 'partner-members-page:visit',
});
