import { styled } from '@mui/material';
import { Avatar, Card } from 'elements';

export const Tile = styled(Card)(() => ({
  width: 348,
  position: 'relative',
  height: '100%',
  minHeight: 272,
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  border: `1px solid ${theme.variables.states.primary.outlinedBorder}`,
  width: 64,
  height: 64,
}));
