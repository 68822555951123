import { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
  VerificationCodeField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card, CardManufacturer, NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import SuccessStep from './SuccessStep';

interface FormValues {
  accessCode: string;
}

interface Props extends DialogProps {
  card: Card;
  onClose: () => void;
  onSuccess: (card: Card) => void;
}

const ActivateCardDialog = ({ card, onSuccess, ...props }: Props) => {
  const { t, i18n } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = useState<'activateCard' | 'success'>('activateCard');
  const { description, errorMessage, codeLength } = useMemo(
    () =>
      card.manufacturer === CardManufacturer.exceet
        ? {
            description: t('activateCardDialog.enterCode'),
            errorMessage: t('activateCardDialog.invalidCodeError'),
            codeLength: 6,
          }
        : {
            description: t('activateCardDialog.enterLastDigits'),
            errorMessage: t('activateCardDialog.invalidLastDigitsError'),
            codeLength: 4,
          },
    [card, i18n.language]
  );
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      accessCode: '',
    },
    onSubmit: async ({ accessCode }, { setSubmitting, setErrors }) => {
      try {
        const data = await api.activateCard(card.cardId, accessCode);
        if (!mounted.current) return;
        onSuccess(data);
        setStep('success');
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (getNetworkErrorCode(error) === NetworkErrorCode.invalidAccessCode) {
          setErrors({
            accessCode: errorMessage,
          });
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    },
  });

  return (
    <Dialog {...props} maxWidth={step === 'activateCard' ? 'xs' : 'sm'}>
      {step === 'activateCard' ? (
        <>
          <DialogTitle>{t('activateCardDialog.title')}</DialogTitle>
          <DialogContent>
            <form
              onSubmit={formik.handleSubmit}
              id="activate-card-form"
              noValidate
            >
              <Typography variant="body2" mb={4}>
                {description}
              </Typography>
              <VerificationCodeField
                onChange={(value) => formik.setFieldValue('accessCode', value)}
                fields={codeLength}
                disabled={formik.isSubmitting}
                error={!!formik.errors.accessCode}
                helperText={formik.errors.accessCode}
                autoFocus
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={props.onClose}>
              {t('common.button.cancel')}
            </Button>
            <Button
              disabled={
                formik.values.accessCode.length < codeLength ||
                formik.isSubmitting
              }
              form="activate-card-form"
              type="submit"
            >
              {t('common.button.submit')}
            </Button>
          </DialogActions>
          <LoaderWithOverlay loading={formik.isSubmitting} />
        </>
      ) : (
        <SuccessStep card={card} onClose={props.onClose} />
      )}
    </Dialog>
  );
};

export default withDialogWrapper<Props>(ActivateCardDialog);
