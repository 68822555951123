import { useCallback, useState } from 'react';
import { FileError, FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { OnboardingTaskActions } from 'domains/onboarding/components';
import {
  Alert,
  AlertTitle,
  Box,
  Dropzone,
  FileRejection,
  LoaderWithOverlay,
  TextField,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  NetworkErrorCode,
  OnboardingDocumentFile,
  OnboardingDocumentStatus,
  OnboardingItemStatus,
  OnboardingTaskQA,
  TaskNavigationPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import formatBytes from 'services/utils/formatBytes';
import FileRow from './FileRow';
import { getActionDetails } from './utils';

export enum ACTION_MODE {
  saveAndUpload = 'SAVE_AND_UPLOAD',
  save = 'SAVE',
  completeTask = 'COMPLETE_TASK',
  upload = 'UPLOAD',
}

export interface Props extends TaskNavigationPropsBase {
  task: OnboardingTaskQA;
  onUpdate: (newTask: OnboardingTaskQA) => void;
  onInvalidate: () => Promise<void>;
}

interface State {
  answerLocal: string;
  acceptedFiles: FileWithPath[];
  fileRejections: FileRejection[];
  isLoading: boolean;
  uploadedFiles: OnboardingDocumentFile[];
  filePathUploadingProgressMap: { [key: string]: number };
}

const OnboardingDocsComponent = ({
  isReadOnly,
  task,
  taskNavigationItems,
  onUpdate,
  onInvalidate,
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: {
      canBeCompleted: { value: taskCanBeCompleted },
      onboardingDocument: { value, editable: docEditable },
    },
  } = task;
  const docValue = value!;
  const [state, setState] = useState<State>({
    answerLocal: docValue.answerLocal || '',
    acceptedFiles: [],
    fileRejections: [],
    isLoading: false,
    uploadedFiles: docValue.files || [],
    filePathUploadingProgressMap: {},
  });

  const { useUpdateQA } = useTanstackQuery();
  const {
    mutate: orgUpdateQAMutate,
    isLoading: isUpdateQATaskLoading,
  } = useUpdateQA({
    onSuccess: (response) => {
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    },
  });

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) =>
      setState((prevState) => {
        const alreadyAddedFilePaths = prevState.acceptedFiles.map(
          (file) => file.path
        );
        const newAcceptedFiles = acceptedFiles.filter(
          (file) => !alreadyAddedFilePaths.includes(file.path)
        );
        return {
          ...prevState,
          acceptedFiles: [...prevState.acceptedFiles, ...newAcceptedFiles],
          fileRejections,
        };
      }),
    []
  );

  const onSelectedFileRemove = (fileIndex: number) =>
    setState((prevState) => ({
      ...prevState,
      acceptedFiles: [
        ...prevState.acceptedFiles.slice(0, fileIndex),
        ...prevState.acceptedFiles.slice(fileIndex + 1),
      ],
    }));

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      let isFileTooLargeError = false;
      let tooManyFilesError = false;

      fileRejections.forEach((file) => {
        isFileTooLargeError = !!file.errors.find(
          (err) => err.code === 'file-too-large'
        );
        tooManyFilesError = !!file.errors.find(
          (err) => err.code === 'too-many-files'
        );
      });

      if (isFileTooLargeError) {
        enqueueSnackbar(
          t('dropzone.fileIsTooBigErrorMessage', {
            size: formatBytes(docValue.type.maxFileSizeInBytes),
          }),
          {
            variant: 'error',
          }
        );
        return;
      }
      if (tooManyFilesError) {
        enqueueSnackbar(t('dropzone.tooManyFilesErrorMessage'), {
          variant: 'error',
        });
        return;
      }
    },
    [enqueueSnackbar]
  );

  const onAnswerSave = async () => {
    try {
      await api.submitOnboardingAnswer(docValue.organizationId, docValue.id, {
        answerLocal: state.answerLocal,
      });
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(t('errors.general'), { variant: 'error' });
      logError(error);
    }
  };

  const onUpload = async () => {
    for (const currentFile of state.acceptedFiles) {
      try {
        const response = await api.uploadOnboardingDocumentFile(
          docValue.organizationId,
          docValue.id,
          currentFile as File,
          (progress) => {
            if (!mounted.current) return;
            setState((prevState) => ({
              ...prevState,
              filePathUploadingProgressMap: {
                ...prevState.filePathUploadingProgressMap,
                [currentFile.path!]: progress,
              },
            }));
          }
        );
        if (!mounted.current) return;
        setState((prevState) => ({
          ...prevState,
          acceptedFiles: prevState.acceptedFiles.filter(
            (file) => file.path !== currentFile.path
          ),
          uploadedFiles: [...prevState.uploadedFiles, response],
        }));
      } catch (error) {
        const fileRejection: FileRejection = {
          file: currentFile as File,
          errors: [],
        };

        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.onboardingDocumentFileTooBigError
        ) {
          fileRejection.errors.push({
            message: '',
            code: 'file-too-large',
          });
        }
        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.onboardingDocumentFileTypeNotAllowedError
        ) {
          fileRejection.errors.push({
            message: '',
            code: 'file-invalid-type',
          });
        }
        if (!mounted.current) return;
        if (fileRejection.errors.length > 0) {
          setState((prevState) => ({
            ...prevState,
            acceptedFiles: prevState.acceptedFiles.filter(
              (file) => file.path !== currentFile.path
            ),
            fileRejections: [...prevState.fileRejections, fileRejection],
          }));
        } else {
          if (!mounted.current) return;
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    }
  };

  const onSave = async (mode: ACTION_MODE) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    if ([ACTION_MODE.saveAndUpload, ACTION_MODE.save].includes(mode)) {
      await onAnswerSave();
    }

    if ([ACTION_MODE.saveAndUpload, ACTION_MODE.upload].includes(mode))
      await onUpload();

    await onInvalidate();

    if (!mounted.current) return;
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
  };

  const onUploadedFileDelete = async (fileId: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      await api.deleteOnboardingDocumentFile(
        docValue.organizationId,
        docValue.id,
        fileId
      );

      await onInvalidate();

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        uploadedFiles: prevState.uploadedFiles.filter(
          (file) => file.id !== fileId
        ),
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const { mode, isSubmitDisabled, successBtnText } = getActionDetails(
    t,
    taskCanBeCompleted,
    docValue,
    state.acceptedFiles,
    state.uploadedFiles,
    state.answerLocal,
    task.status
  );
  const maxNumberOfFilesToUpload =
    docValue.type.maxNumberOfFiles -
    state.acceptedFiles.length -
    state.uploadedFiles.length;

  return (
    <>
      {task.status === OnboardingItemStatus.submitted && (
        <Alert severity="info" sx={{ mb: 3 }}>
          <AlertTitle>
            {t('orgOnboardingTaskPage.verificationAlert.title')}
          </AlertTitle>
          {docValue.type.question
            ? t('orgOnboardingTaskPage.verificationAlert.questionDescription')
            : t('orgOnboardingTaskPage.verificationAlert.docDescription')}
        </Alert>
      )}

      {task.status === OnboardingItemStatus.requiresAction &&
        docValue.status === OnboardingDocumentStatus.REQUESTED &&
        task.comment && (
          <Alert severity="warning" sx={{ mb: 3 }}>
            <AlertTitle>
              {docValue.type.question
                ? t('orgOnboardingTaskPage.rejectedAlert.questionTitle')
                : t('orgOnboardingTaskPage.rejectedAlert.docTitle')}
            </AlertTitle>
            {task.comment}
          </Alert>
        )}

      {docValue.type.question && (
        <Box mb={5}>
          <TextField
            disabled={
              isReadOnly ||
              !docEditable ||
              state.isLoading ||
              isUpdateQATaskLoading
            }
            label={
              docValue.customLabelLocal || t('orgOnboardingTaskPage.answer')
            }
            multiline
            value={state.answerLocal}
            onChange={(e) =>
              setState((prevState) => ({
                ...prevState,
                answerLocal: e.target.value,
              }))
            }
          />
        </Box>
      )}

      <Dropzone
        file={null}
        isLoading={state.isLoading || isUpdateQATaskLoading}
        disabled={
          isReadOnly ||
          isUpdateQATaskLoading ||
          state.isLoading ||
          maxNumberOfFilesToUpload <= 0 ||
          !docEditable
        }
        onDrop={onDrop}
        onDropRejected={onDropRejected}
        dropzoneIdleProps={{
          description: t('orgOnboardingQATask.dropzoneLabel'),
        }}
        multiple
        accept={docValue.type.allowedMediaTypes}
        maxSize={docValue.type.maxFileSizeInBytes}
        maxFiles={maxNumberOfFilesToUpload}
        dataTestId="qa-docs-dropzone"
      />

      <Box mt={3}>
        {state.uploadedFiles.map((file, index) => (
          <FileRow
            key={file.name + index}
            version="uploaded"
            file={file}
            documentId={docValue.id}
            disabled={isReadOnly || !docEditable}
            isLoading={state.isLoading || isUpdateQATaskLoading}
            onDelete={() => onUploadedFileDelete(file.id)}
          />
        ))}

        {state.acceptedFiles.map((file, index) => (
          <FileRow
            key={file.path}
            version="selected"
            file={file}
            documentId={docValue.id}
            disabled={isReadOnly || !docEditable}
            isLoading={state.isLoading || isUpdateQATaskLoading}
            uploadingProgress={state.filePathUploadingProgressMap[file.path!]}
            onDelete={() => onSelectedFileRemove(index)}
          />
        ))}

        {state.fileRejections.map(
          ({ file, errors }: { file: FileWithPath; errors: FileError[] }) => (
            <FileRow
              key={file.path}
              version="error"
              file={file}
              documentId={docValue.id}
              disabled={isReadOnly || !docEditable}
              errors={errors}
              isLoading={state.isLoading || isUpdateQATaskLoading}
              maxFileSizeInBytes={docValue.type.maxFileSizeInBytes}
            />
          )
        )}
      </Box>

      <Box mt={4}>
        <OnboardingTaskActions
          isReadOnly={isReadOnly}
          taskNavigationItems={taskNavigationItems}
          disabled={
            isUpdateQATaskLoading || state.isLoading || isSubmitDisabled
          }
          onSuccess={() => {
            if (mode === ACTION_MODE.completeTask) {
              orgUpdateQAMutate({
                taskId: task.id,
              });
              return;
            }
            onSave(mode);
          }}
          successBtnText={successBtnText}
        />
      </Box>

      <LoaderWithOverlay loading={state.isLoading || isUpdateQATaskLoading} />
    </>
  );
};

export default OnboardingDocsComponent;
