import { useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  DialogContent,
  LoaderWithOverlay,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import { getGenericErrorMsg } from 'services/utils';
import usePaymentIntentFinalStateHandler from './usePaymentIntentFinalStateHandler';

interface Props {
  transactionId: string;
  showReimburseLaterText: boolean;
  onClose: () => void;
}

const ConfirmPaymentWithNewDetails = ({
  transactionId,
  showReimburseLaterText,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const handlePaymentIntentFinalState = usePaymentIntentFinalStateHandler(
    transactionId
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      if (!stripe || !elements) return;

      setIsLoading(true);

      const result = await stripe.confirmPayment({
        elements: elements,
        confirmParams: {
          return_url: window.location.origin,
        },
        redirect: 'if_required',
      });

      handlePaymentIntentFinalState(result, onClose, () => setIsLoading(false));
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <DialogContent sx={{ minHeight: 100 }}>
        <PaymentElement />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {showReimburseLaterText
            ? t('transactionDetails.reimbursementDialog.reimburseLater')
            : t('common.button.cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!stripe || !elements || isLoading}
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={isLoading} />
    </>
  );
};

export default ConfirmPaymentWithNewDetails;
