import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useDownloadReport from 'domains/creditAndCompliance/pages/CreditPage/ProfileSubPage/AgencyProfileSection/CreditReports/useDownloadReport';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FilePdfIcon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withDialogWrapper,
} from 'elements';
import { CrefoReport, ExternalReport } from 'services/constants';

interface Props extends DialogProps {
  onClose: () => void;
  reports: (CrefoReport | ExternalReport)[];
}

const ArchivedCreditReportsDialog = ({ reports, ...props }: Props) => {
  const { t } = useTranslation();
  const downloadReport = useDownloadReport();

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('int.archivedCreditReportsDialog.title')}</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('int.archivedCreditReportsDialog.reportName')}
                </TableCell>
                <TableCell>
                  {t('int.archivedCreditReportsDialog.reportDate')}
                </TableCell>
                <TableCell>
                  {t('int.archivedCreditReportsDialog.downloaded')}
                </TableCell>
                <TableCell>
                  {t('int.archivedCreditReportsDialog.report')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {reports.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '.MuiTableCell-root': { border: 0 },
                  }}
                >
                  <TableCell>{item.fileName}</TableCell>
                  <TableCell>
                    {moment(item.reportCreatedAt).format('D MMM YYYY, HH:mm')}
                  </TableCell>
                  <TableCell>
                    {moment(item.downloadedAt).format('D MMM YYYY, HH:mm')}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => downloadReport(item)}
                      size="medium"
                    >
                      <FilePdfIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} variant="outlined">
          {t('int.archivedCreditReportsDialog.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withDialogWrapper<Props>(ArchivedCreditReportsDialog);
