import { createSvgIcon } from '@mui/material';
import BankPaymentFee from 'assets/icons/phosphorCustom/BankPaymentFee.svg?react';
import BankReversal from 'assets/icons/phosphorCustom/BankReversal.svg?react';
import BankTopUp from 'assets/icons/phosphorCustom/BankTopUp.svg?react';
import BankWithdrawal from 'assets/icons/phosphorCustom/BankWithdrawal.svg?react';
import CalendarBank from 'assets/icons/phosphorCustom/CalendarBank.svg?react';
import CalendarReversal from 'assets/icons/phosphorCustom/CalendarReversal.svg?react';
import CardBasedAutomation from 'assets/icons/phosphorCustom/CardBasedAutomation.svg?react';
import Computing from 'assets/icons/phosphorCustom/Computing.svg?react';
import ManagerRoundFill from 'assets/icons/phosphorCustom/ManagerRoundFill.svg?react';
import MerchantBasedAutomation from 'assets/icons/phosphorCustom/MerchantBasedAutomation.svg?react';
import ReceiptAdd from 'assets/icons/phosphorCustom/ReceiptAdd.svg?react';
import ReceiptAttention from 'assets/icons/phosphorCustom/ReceiptAttention.svg?react';
import ReceiptExternalLink from 'assets/icons/phosphorCustom/ReceiptExternalLink.svg?react';
import Travel from 'assets/icons/phosphorCustom/Travel.svg?react';
import VerticalCardCheck from 'assets/icons/phosphorCustom/VerticalCardCheck.svg?react';
import VerticalCardExpired from 'assets/icons/phosphorCustom/VerticalCardExpired.svg?react';
import VerticalCardReplaced from 'assets/icons/phosphorCustom/VerticalCardReplaced.svg?react';
import VerticalCardRequest from 'assets/icons/phosphorCustom/VerticalCardRequest.svg?react';
import VerticalCardShipped from 'assets/icons/phosphorCustom/VerticalCardShipped.svg?react';
import VerticalCardTerminated from 'assets/icons/phosphorCustom/VerticalCardTerminated.svg?react';
import VerticalCards from 'assets/icons/phosphorCustom/VerticalCards.svg?react';
import VerticalCardsRequest from 'assets/icons/phosphorCustom/VerticalCardsRequest.svg?react';
import VssMissing from 'assets/icons/phosphorCustom/VssMissing.svg?react';
import Withdraw from 'assets/icons/phosphorCustom/Withdraw.svg?react';

// Custom icons which look like phosphor icons.
// This is a temporary file structure
// which will be updated when we replace all old icons.

export const BankPaymentFeeIcon = createSvgIcon(
  <BankPaymentFee />,
  'BankPaymentFee'
);

export const BankReversalIcon = createSvgIcon(<BankReversal />, 'BankReversal');

export const BankTopUpIcon = createSvgIcon(<BankTopUp />, 'BankTopUp');

export const BankWithdrawalIcon = createSvgIcon(
  <BankWithdrawal />,
  'BankWithdrawal'
);

export const CalendarBankIcon = createSvgIcon(<CalendarBank />, 'CalendarBank');

export const CalendarReversalIcon = createSvgIcon(
  <CalendarReversal />,
  'CalendarReversal'
);

export const ComputingIcon = createSvgIcon(<Computing />, 'Computing');

export const VerticalCardShippedIcon = createSvgIcon(
  <VerticalCardShipped />,
  'VerticalCardShipped'
);

export const VerticalCardTerminatedIcon = createSvgIcon(
  <VerticalCardTerminated />,
  'VerticalCardTerminated'
);

export const VerticalCardCheckIcon = createSvgIcon(
  <VerticalCardCheck />,
  'VerticalCardCheck'
);

export const VerticalCardExpiredIcon = createSvgIcon(
  <VerticalCardExpired />,
  'VerticalCardExpired'
);

export const VerticalCardReplacedIcon = createSvgIcon(
  <VerticalCardReplaced />,
  'VerticalCardReplaced'
);

export const VerticalCardRequestIcon = createSvgIcon(
  <VerticalCardRequest />,
  'VerticalCardRequest'
);

export const VerticalCardsIcon = createSvgIcon(
  <VerticalCards />,
  'VerticalCards'
);

export const CardBasedAutomationIcon = createSvgIcon(
  <CardBasedAutomation />,
  'CardBasedAutomation'
);

export const MerchantBasedAutomationIcon = createSvgIcon(
  <MerchantBasedAutomation />,
  'MerchantBasedAutomation'
);

export const ReceiptAttentionIcon = createSvgIcon(
  <ReceiptAttention />,
  'ReceiptAttention'
);

export const ManagerRoundFillIcon = createSvgIcon(
  <ManagerRoundFill />,
  'ManagerRoundFill'
);

export const ReceiptAddIcon = createSvgIcon(<ReceiptAdd />, 'ReceiptAdd');

export const ReceiptExternalLinkIcon = createSvgIcon(
  <ReceiptExternalLink />,
  'ReceiptExternalLink'
);

export const TravelIcon = createSvgIcon(<Travel />, 'Travel');

export const VerticalCardsRequestIcon = createSvgIcon(
  <VerticalCardsRequest />,
  'VerticalCardsRequest'
);

export const VssMissingIcon = createSvgIcon(<VssMissing />, 'VssMissing');

export const WithdrawIcon = createSvgIcon(<Withdraw />, 'Withdraw');
