import { ComponentProps } from 'react';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import MuiIconButton from '@material-ui/core/IconButton';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import styled from 'styled-components';
import EditPencilIcon from 'assets/icons/interface/editPencil.svg?react';

interface ButtonExtraProps {
  $px?: number;
  $m?: string;
  $minWidth?: string;
  $isIconButton?: boolean;
}

export const Button = styled((props: ButtonProps) => (
  <MuiButton variant="contained" {...props}>
    {props.children}
  </MuiButton>
))<ButtonExtraProps>`
  height: 46px;
  min-width: ${({ $minWidth = 'auto', $isIconButton }) =>
    $isIconButton ? '46px' : $minWidth};
  font-size: 16px;
  line-height: 28px;
  border-radius: 4px;
  padding: 0 ${({ $px = 30, $isIconButton }) => ($isIconButton ? 0 : $px)}px;
  margin: ${({ $m = '0' }) => $m};
  text-transform: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.MuiButton-text {
    border: 1px solid transparent;
    color: ${({ theme }) => theme.colors.grey40};

    &:hover {
      background: none;
      color: ${({ theme }) => theme.colors.grey10};
    }

    &:focus {
      color: ${({ theme }) => theme.colors.grey10};
      border-color: ${({ theme }) => theme.colors.grey100};
    }

    &:active {
      color: ${({ theme }) => theme.colors.grey10};
      border-color: ${({ theme }) => theme.colors.neutralVariant50};
      background: ${({ theme }) => theme.colors.neutralVariant50};
    }

    &.Mui-disabled {
      opacity: 0.4;
    }
  }

  &.MuiButton-textSecondary {
    color: ${({ theme }) => theme.colors.financeRed40};

    &:hover {
      color: ${({ theme }) => theme.colors.financeRed10};
    }

    &:focus {
      color: ${({ theme }) => theme.colors.financeRed10};
      border-color: ${({ theme }) => theme.colors.financeRed10};
    }

    &:active {
      color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.financeRed10};
      background: ${({ theme }) => theme.colors.financeRed10};
    }

    &.Mui-disabled {
      color: ${({ theme }) => theme.colors.financeRed100};
      opacity: 1;
    }
  }

  &.MuiButton-contained {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.grey10};

    &:hover {
      background: ${({ theme }) => theme.colors.neutral30};
    }

    &:focus {
      background: ${({ theme }) => theme.colors.neutral20};
      outline: ${({ theme }) => theme.colors.neutral80} solid 2px;
    }

    &:active {
      background: ${({ theme }) => theme.colors.neutral40};
      outline: none;
    }

    &.Mui-disabled {
      background: ${({ theme }) => theme.colors.neutral70};
      color: rgba(255, 255, 255, 0.38);
    }
  }

  &.MuiButton-containedSecondary {
    background: ${({ theme }) => theme.colors.financeRed40};

    &:hover,
    &:active,
    &:focus {
      background: ${({ theme }) => theme.colors.financeRed10};
      outline: none;
    }

    &.Mui-disabled {
      background: ${({ theme }) => theme.colors.financeRed100};
      color: ${({ theme }) => theme.colors.white};
    }
  }

  &.MuiButton-outlined {
    color: ${({ theme }) => theme.colors.grey10};
    border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};

    &:hover,
    &:focus {
      background: none;
      border-color: ${({ theme }) => theme.colors.grey10};
    }

    &:active {
      background: ${({ theme }) => theme.colors.grey10};
      border-color: ${({ theme }) => theme.colors.grey10};
      color: ${({ theme }) => theme.colors.white};
    }

    &.Mui-disabled {
      color: ${({ theme }) => alpha(theme.colors.grey10, 0.38)};
      border-color: rgba(31, 31, 31, 0.12);
    }
  }

  &.MuiButton-outlinedSecondary {
    color: ${({ theme }) => theme.colors.financeRed40};
    border: 1px solid ${({ theme }) => theme.colors.financeRed40};

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.financeRed10};
      border: 1px solid ${({ theme }) => theme.colors.financeRed10};
    }

    &:active {
      background: ${({ theme }) => theme.colors.financeRed10};
      border-color: ${({ theme }) => theme.colors.financeRed10};
      color: ${({ theme }) => theme.colors.white};
    }

    &.Mui-disabled {
      color: ${({ theme }) => theme.colors.financeRed100};
      border: 1px solid ${({ theme }) => theme.colors.financeRed100};
    }
  }

  &.MuiButton-sizeSmall {
    height: 35px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 3px;
    padding: 0 ${({ $px = 12 }) => $px}px;
  }

  .MuiButton-label {
    margin-top: 1px;
  }

  svg {
    margin-top: -1px;
    width: 16px;
    height: 16px;
  }
`;

/**@deprecated In new design, use <Link component="button"> (Link is imported from 'elements') */
export const ButtonLink = styled.button.attrs((props) => ({
  type: props.type || 'button',
}))<{
  disabled?: boolean;
  $fontSize?: number;
}>`
  flex: 0 0 auto;
  padding: 0;
  font-size: ${({ $fontSize = 14 }) => $fontSize}px;
  line-height: 1.4;
  color: ${({ disabled = false }) =>
    disabled
      ? 'inherit'
      : '#111111'}; // hardcoded primary main from the new MUI5 theme
  border: none;
  background-color: transparent;
  cursor: ${({ disabled = false }) => (disabled ? 'default' : 'pointer')};
  text-decoration: underline;
  outline: none;
`;

export const IconButton = styled(MuiIconButton)<{ $p?: string; $m?: string }>`
  padding: ${({ $p = '8px' }) => $p};
  margin: ${({ $m = '0' }) => $m};
  color: ${({ theme }) => theme.colors.grey10};

  &:hover,
  &.active {
    background: ${({ theme }) => theme.colors.neutral90};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const DownloadFileButton = styled(
  (props: ComponentProps<typeof Button>) => <Button {...props} variant="text" />
)`
  min-width: auto;
  height: auto;
  padding: 2px 6px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const EditButton = (props: ComponentProps<typeof IconButton>) => (
  <IconButton {...props}>
    <EditPencilIcon />
  </IconButton>
);

export const PageFilterButton = styled((props: ButtonProps) => (
  <MuiButton {...props} variant="outlined" />
))`
  height: 46px;
  min-width: auto;
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
  padding: 0 14px;

  &:hover,
  &:focus,
  &.active {
    background: none;
    border-color: ${({ theme }) => theme.colors.grey10};
  }

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.red10};
  }

  &.Mui-disabled {
    border-color: rgba(31, 31, 31, 0.12);

    * {
      opacity: 0.8;
    }
  }

  .MuiButton-label {
    display: flex;
    justify-content: space-evenly;
    text-transform: none;
  }
`;
