import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingStatusBadge } from 'domains/onboarding/components';
import {
  getNavigationPath,
  getUrlFromOnboardingKeyType,
  SectionIconByType,
} from 'domains/onboarding/utils';
import {
  Avatar,
  CaretRightIcon,
  IconButton,
  Paper,
  PaperProps,
  Tooltip,
  Typography,
} from 'elements';
import { OnboardingSection } from 'services/constants';

export interface Props extends PaperProps {
  section: OnboardingSection;
}

const SectionItem = ({ children, section, ...props }: Props) => {
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();

  const Icon = SectionIconByType[section.type];

  return (
    <Paper
      variant="outlined"
      sx={{ p: 2, display: 'flex', alignItems: 'center', '&+&': { mt: 2 } }}
      {...props}
    >
      <Avatar
        variant="rounded"
        sx={{ bgcolor: (theme) => theme.variables.warmGrey[200] }}
      >
        {Icon && <Icon color="primary" />}
      </Avatar>

      <Typography variant="h6" component="div" ml={2} flex={1}>
        {children}
      </Typography>

      <OnboardingStatusBadge status={section.status} />

      <Tooltip
        title={
          section.restricted && t('portal.partnerPortal.informationRestricted')
        }
      >
        <div>
          <IconButton
            component={Link}
            sx={{ ml: 2 }}
            aria-label="navigation-button"
            disabled={section.restricted}
            to={{
              pathname: getNavigationPath(
                organization!.id,
                getUrlFromOnboardingKeyType(section.type)
              ),
            }}
          >
            <CaretRightIcon />
          </IconButton>
        </div>
      </Tooltip>
    </Paper>
  );
};

export default SectionItem;
