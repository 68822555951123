import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CardIcon } from 'domains/card/components';
import { getCardName } from 'domains/card/utils';
import {
  Box,
  CheckCircleIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  XCircleIcon,
} from 'elements';
import { CardConfigSetting } from 'services/constants';

export const CardBenefitAvailableCell = () => (
  <TableCell align="center">
    <CheckCircleIcon color="success" />
  </TableCell>
);

interface CardBenefit {
  title: ReactNode;
  description: ReactNode;
  isPackageActive: boolean;
}

interface Props {
  commonBenefits: CardBenefit[];
  premiumBenefits: CardBenefit[];
  virtualCardConfigSetting: CardConfigSetting | undefined;
  physicalCardConfigSetting: CardConfigSetting | undefined;
  physicalPremiumCardConfigSetting: CardConfigSetting | undefined;
  hasVirtual: boolean;
  hasPhysical: boolean;
  hasPhysicalPremium: boolean;
}

const CardBenefitsContent = ({
  commonBenefits,
  premiumBenefits,
  virtualCardConfigSetting,
  physicalCardConfigSetting,
  physicalPremiumCardConfigSetting,
  hasVirtual,
  hasPhysical,
  hasPhysicalPremium,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('cardBenefitsContent.tableHead.benefit')}
              </TableCell>
              {(hasPhysical || hasVirtual) && (
                <TableCell
                  sx={{
                    width: '25%',
                    textWrap: 'nowrap',
                    '& > *': { verticalAlign: 'middle' },
                  }}
                  align="center"
                >
                  {hasPhysical && (
                    <>
                      <CardIcon
                        cardDesignId={
                          physicalCardConfigSetting!.cardDesignIds[0]
                        }
                      />
                      &nbsp;&nbsp;&nbsp;
                    </>
                  )}
                  {hasVirtual && (
                    <>
                      <CardIcon
                        cardDesignId={
                          virtualCardConfigSetting!.cardDesignIds[0]
                        }
                      />
                      &nbsp;&nbsp;&nbsp;
                    </>
                  )}
                  <Box
                    display="inline-flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <div>
                      {hasPhysical &&
                        getCardName(
                          physicalCardConfigSetting!.cardConfig,
                          true
                        )}
                      {hasVirtual && (
                        <>
                          {hasPhysical && ' / '}
                          {getCardName(
                            virtualCardConfigSetting!.cardConfig,
                            true
                          )}
                        </>
                      )}
                    </div>
                    <Typography
                      variant="inherit"
                      color="text.secondary"
                      mt={-0.5}
                    >
                      {t('cardBenefitsContent.visaPlatinumBusiness')}
                    </Typography>
                  </Box>
                </TableCell>
              )}
              {hasPhysicalPremium && (
                <TableCell
                  sx={{ width: '25%', '& > *': { verticalAlign: 'middle' } }}
                  align="center"
                >
                  <CardIcon
                    cardDesignId={
                      physicalPremiumCardConfigSetting!.cardDesignIds[0]
                    }
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Box
                    display="inline-flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <div>
                      {getCardName(
                        physicalPremiumCardConfigSetting!.cardConfig,
                        true
                      )}
                    </div>
                    <Typography
                      variant="inherit"
                      color="text.secondary"
                      mt={-0.5}
                    >
                      {t('cardBenefitsContent.visaInfiniteBusiness')}
                    </Typography>
                  </Box>
                </TableCell>
              )}
              <TableCell
                sx={{
                  width:
                    (hasPhysical || hasVirtual) && hasPhysicalPremium
                      ? '25%'
                      : 'auto',
                }}
              >
                {t('cardBenefitsContent.tableHead.details')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {commonBenefits.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  {item.title}{' '}
                  {!item.isPackageActive && <b>({t('common.comingSoon')})</b>}
                </TableCell>
                {(hasVirtual || hasPhysical) && <CardBenefitAvailableCell />}
                {hasPhysicalPremium && <CardBenefitAvailableCell />}
                <TableCell>{item.description}</TableCell>
              </TableRow>
            ))}

            {hasPhysicalPremium &&
              premiumBenefits.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {item.title}{' '}
                    {!item.isPackageActive && <b>({t('common.comingSoon')})</b>}
                  </TableCell>
                  {(hasVirtual || hasPhysical) && (
                    <TableCell align="center">
                      <XCircleIcon sx={{ color: 'text.disabled' }} />
                    </TableCell>
                  )}
                  <CardBenefitAvailableCell />
                  <TableCell>{item.description}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CardBenefitsContent;
