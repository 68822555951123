import { useState } from 'react';
import { dinero, toDecimal } from 'dinero.js';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountCurrency } from 'domains/card/hooks';
import {
  Box,
  Button,
  EditButton,
  FormControlLabel,
  FormControlLabelTooltipIcon,
  Grid,
  MoneyField,
  Switch,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { FeatureModuleByKeyMap, FeatureModuleKey } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getGenericErrorMsg,
} from 'services/utils';

interface State {
  isLoading: boolean;
  isEditingLimit: boolean;
  limit: string;
  settings: FeatureModuleByKeyMap[FeatureModuleKey.cardRequests]['settings'];
}

const CardRequestsSettings = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, featureModulesWithData },
  } = useGlobalState();
  const currency = useCardAccountCurrency();
  const module = featureModulesWithData.CARD_REQUESTS;
  const [state, setState] = useState<State>({
    isLoading: false,
    isEditingLimit: false,
    limit: '',
    settings: module.settings,
  });

  const updateSettings = async (
    settings: FeatureModuleByKeyMap[FeatureModuleKey.cardRequests]['settings']
  ) => {
    if (state.isLoading) return;
    try {
      setState((prevState) => ({ ...prevState, isLoading: true, settings }));
      const updatedModule = await api.updateFeatureModuleSettings<
        FeatureModuleKey.cardRequests
      >(organization!.id, {
        featureName: FeatureModuleKey.cardRequests,
        settings,
      });
      dispatch({
        type: 'SET_FEATURE_MODULES',
        payload: {
          featureModules: {},
          featureModulesWithData: {
            [updatedModule.name]: updatedModule,
          },
        },
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isEditingLimit: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isEditingLimit: false,
        settings: module.settings,
      }));
      logError(error);
    }
  };

  return (
    <Box mt={1}>
      <FormControlLabel
        control={<Switch />}
        label={
          <Box display="flex" alignItems="center">
            {t('manageFeatureModuleDialog.cardRequestAutoApprove')}
            <FormControlLabelTooltipIcon
              title={t(
                'manageFeatureModuleDialog.cardRequestAutoApproveTooltip'
              )}
            />
          </Box>
        }
        disabled={!module.enabled}
        checked={state.settings.CARD_REQUESTS_AUTO_APPROVAL_ENABLED}
        onChange={(_, checked) =>
          updateSettings({
            CARD_REQUESTS_AUTO_APPROVAL_ENABLED: checked,
            CARD_REQUESTS_AUTO_APPROVAL_LIMIT: convertDineroToMoney(
              dinero({ amount: 0, currency: currency })
            ),
          })
        }
      />

      {state.settings.CARD_REQUESTS_AUTO_APPROVAL_ENABLED && (
        <Box mt={1}>
          {state.isEditingLimit ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updateSettings({
                  ...state.settings,
                  CARD_REQUESTS_AUTO_APPROVAL_LIMIT: convertDineroToMoney(
                    dineroFromFloat(state.limit, currency)
                  ),
                });
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <MoneyField
                    value={state.limit}
                    onValueChange={({ value }) => {
                      setState((prevState) => ({
                        ...prevState,
                        limit: value,
                      }));
                    }}
                    currency={currency.code}
                    decimalScale={currency.exponent}
                    autoFocus
                    onFocus={(e) => e.target.select()}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isEditingLimit: false,
                      }))
                    }
                  >
                    {t('common.button.cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit">{t('common.button.save')}</Button>
                </Grid>
              </Grid>
            </form>
          ) : (
            <Box display="flex" alignItems="center">
              <Typography>
                <FormatMoney
                  value={state.settings.CARD_REQUESTS_AUTO_APPROVAL_LIMIT}
                  fractionalPart
                />
              </Typography>
              <EditButton
                sx={{ ml: 1 }}
                disabled={!module.enabled}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isEditingLimit: true,
                    limit: toDecimal(
                      dineroFromMoney(
                        prevState.settings.CARD_REQUESTS_AUTO_APPROVAL_LIMIT
                      )
                    ),
                  }))
                }
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CardRequestsSettings;
