import { convert, greaterThan, haveSameCurrency, toDecimal } from 'dinero.js';
import { useFormik } from 'formik';
import omit from 'lodash/omit';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { MAX_TELEKOM_SINGLE_USE_CARD_LIMIT } from 'domains/card/constants';
import {
  useCardAccountCurrency,
  useMaxAllowedCardLimit,
} from 'domains/card/hooks';
import { getCardNameWithRefNum } from 'domains/card/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  Select,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Card,
  cardLimitRenewFrequencies,
  CardLimitRenewFrequency,
  CardType,
  ChangeCardLimitsPayload,
  NetworkErrorCode,
  Team,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getDineroScaledAmount,
  getGenericErrorMsg,
  getNetworkErrorCode,
} from 'services/utils';

interface FormValues {
  limit: string;
  transactionLimit: string;
  limitRenewFrequency: CardLimitRenewFrequency;
}

interface Props extends DialogProps {
  card: Card;
  managerTeam?: Team;
  onClose: () => void;
  onSuccess: (card: Card) => void;
}

const ChangeCardLimitsDialog = ({
  card,
  managerTeam,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const currency = useCardAccountCurrency(card.cardAccountId);
  const { requestCardForAnotherMemberEnabled } = useFlags();
  const {
    maxAllowedCardLimit,
    getMaxAllowedCardLimitExceededErrorMessage,
  } = useMaxAllowedCardLimit(managerTeam);
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      limit: toDecimal(dineroFromMoney(card.limit)),
      transactionLimit: toDecimal(dineroFromMoney(card.transactionLimit)),
      limitRenewFrequency: card.limitRenewFrequency,
    },
    validate: async (values) => {
      const dLimit = dineroFromFloat(values.limit, currency);
      const dTransactionLimit = dineroFromFloat(
        values.transactionLimit,
        currency
      );

      if (
        card.type !== CardType.singleUse &&
        greaterThan(dTransactionLimit, dLimit)
      )
        return {
          transactionLimit: t('errors.cardTransactionLimitHigherThanLimit'),
        };

      if (maxAllowedCardLimit) {
        const dMaxAllowedCardLimit = dineroFromMoney(maxAllowedCardLimit);
        const dLimitToValidate =
          card.type === CardType.singleUse ? dTransactionLimit : dLimit;

        // Validate limits only if they have the same currency, otherwise let the BE
        // validate it and handle the "MAX_CARD_LIMIT_EXCEEDED" error code
        if (
          haveSameCurrency([dLimitToValidate, dMaxAllowedCardLimit]) &&
          greaterThan(dLimitToValidate, dMaxAllowedCardLimit)
        ) {
          return {
            [card.type === CardType.singleUse
              ? 'transactionLimit'
              : 'limit']: getMaxAllowedCardLimitExceededErrorMessage(),
          };
        }
      }

      if (
        requestCardForAnotherMemberEnabled &&
        card.type === CardType.singleUse
      ) {
        let dMaxCardLimit = dineroFromMoney(MAX_TELEKOM_SINGLE_USE_CARD_LIMIT);
        const dLimitToValidate =
          card.type === CardType.singleUse ? dTransactionLimit : dLimit;

        if (!haveSameCurrency([dLimitToValidate, dMaxCardLimit])) {
          try {
            const { fxRates } = await api.getFxRates({
              source: MAX_TELEKOM_SINGLE_USE_CARD_LIMIT.currency,
              target: currency.code,
            });
            dMaxCardLimit = convert(dMaxCardLimit, currency, {
              [currency.code]: getDineroScaledAmount(fxRates[0].rate),
            });
          } catch (error) {
            logError(error);
          }
        }

        if (
          haveSameCurrency([dLimitToValidate, dMaxCardLimit]) &&
          greaterThan(dLimitToValidate, dMaxCardLimit)
        ) {
          return {
            [card.type === CardType.singleUse ? 'transactionLimit' : 'limit']: (
              <Trans
                i18nKey="errors.cardMaxLimitExceeded"
                components={{
                  limit: (
                    <FormatMoney
                      value={convertDineroToMoney(dMaxCardLimit)}
                      fractionalPart
                    />
                  ),
                }}
              />
            ),
          };
        }
      }
    },
    onSubmit: async (
      { limit, transactionLimit, limitRenewFrequency },
      { setSubmitting, setErrors }
    ) => {
      try {
        const payload: ChangeCardLimitsPayload = {
          limit: convertDineroToMoney(
            dineroFromFloat(
              card.type === CardType.singleUse ? transactionLimit : limit,
              currency
            )
          ),
          transactionLimit: convertDineroToMoney(
            dineroFromFloat(transactionLimit, currency)
          ),
          limitRenewFrequency,
        };
        const data = card.platformFee
          ? await api.changePlatformFeeCardLimits(card.cardId, payload)
          : await api.changeCardLimits(card.cardId, payload);
        if (!mounted.current) return;
        setSubmitting(false);
        onSuccess(data);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        switch (getNetworkErrorCode(error)) {
          case NetworkErrorCode.maxCardLimitExceeded:
            setErrors({
              [card.type === CardType.singleUse
                ? 'transactionLimit'
                : 'limit']: getMaxAllowedCardLimitExceededErrorMessage(error),
            });
            break;
          case NetworkErrorCode.orgLimitExceeded:
            setErrors({
              [card.type === CardType.singleUse
                ? 'transactionLimit'
                : 'limit']: t('errors.cardTotalLimitsHigherThanOrgLimit'),
            });
            break;
          default:
            enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
            logError(error);
        }
      }
    },
  });

  const isSubmitDisabled =
    !formik.values.limit ||
    !formik.values.transactionLimit ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t(
          card.type === CardType.singleUse
            ? 'changeCardLimitsDialog.title_one'
            : 'changeCardLimitsDialog.title_other'
        )}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="change-card-limits-form"
          noValidate
        >
          <Box mb={4}>
            <Typography variant="body2">
              <Trans
                i18nKey={
                  card.type === CardType.singleUse
                    ? 'changeCardLimitsDialog.selectLimit_one'
                    : 'changeCardLimitsDialog.selectLimit_other'
                }
                values={{
                  name: getCardNameWithRefNum(card),
                }}
                components={{ b: <b /> }}
              />
            </Typography>
          </Box>
          <Grid container columnSpacing={3} rowSpacing={2}>
            {card.type !== CardType.singleUse && (
              <>
                <Grid item xs={12}>
                  <FormControl fullWidth disabled={formik.isSubmitting}>
                    <InputLabel id="limit-frequency-select-label">
                      {t('changeCardLimitsDialog.limitFrequency')}
                    </InputLabel>
                    <Select<CardLimitRenewFrequency>
                      {...formik.getFieldProps('limitRenewFrequency')}
                      renderValue={(selected) =>
                        t(`cardLimitFrequency.frequencies.${selected}`)
                      }
                      labelId="limit-frequency-select-label"
                    >
                      {cardLimitRenewFrequencies.map((frequency) => (
                        <MenuItem key={frequency} value={frequency}>
                          {t(`cardLimitFrequency.frequencies.${frequency}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <MoneyField
                    {...omit(formik.getFieldProps('limit'), 'onChange')}
                    onValueChange={({ value }) =>
                      formik.setFieldValue('limit', value)
                    }
                    isNumericString
                    currency={currency.code}
                    decimalScale={currency.exponent}
                    label={t('changeCardLimitsDialog.limit')}
                    error={!!formik.errors.limit}
                    helperText={formik.errors.limit}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <MoneyField
                {...omit(formik.getFieldProps('transactionLimit'), 'onChange')}
                onValueChange={({ value }) =>
                  formik.setFieldValue('transactionLimit', value)
                }
                isNumericString
                currency={currency.code}
                decimalScale={currency.exponent}
                label={t('changeCardLimitsDialog.transactionLimit')}
                error={!!formik.errors.transactionLimit}
                helperText={formik.errors.transactionLimit}
                disabled={formik.isSubmitting}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="change-card-limits-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(ChangeCardLimitsDialog);
