import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadButton2Icon from 'assets/icons/interface/downloadButton2.svg?react';
import { ListItemText, MenuButton, MenuContainer } from 'components/Menu';
import { AccountingExportDownloadButtons } from 'domains/billing/components';
import useDownloadFile from 'hooks/useDownloadFile';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Bill, TransactionsDownloadFileType } from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { downloadResponseAsFile, getGenericErrorMsg } from 'services/utils';
import {
  StyledCircularProgress,
  StyledMenuButtonCircularProgress,
  StyledMenuItem,
} from './style';

interface Props {
  bill: Bill;
}

const StatementItemMenu = ({ bill }: Props) => {
  const { accountEntriesOnExportPageEnabled } = useFlags();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [isZipLoading, setIsZipLoading] = useState(false);
  const handleDownloadPdf = useDownloadFile(
    `/documents/${bill.billingStatementDocumentId}/content`,
    {},
    { responseType: 'arraybuffer' }
  );
  const isLoading = isCsvLoading || isZipLoading;

  const getCsv = async () => {
    try {
      setIsCsvLoading(true);
      const response = await api.getTransactionsDownloadFile({
        organizationId: bill.organizationId,
        billId: bill.billId,
        includeBills: true,
        format: TransactionsDownloadFileType.defaultCsv,
        useAccountEntries: accountEntriesOnExportPageEnabled,
      });
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setIsCsvLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsCsvLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const getZip = async () => {
    try {
      setIsZipLoading(true);
      const response = await api.getBillReceipts(bill.billId);
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setIsZipLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsZipLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <MenuContainer
      button={
        <MenuButton
          $px={24}
          startIcon={<DownloadButton2Icon />}
          variant="text"
          disabled={
            !(
              bill.numberOfTransactionsIncluded ||
              bill.billingStatementDocumentId
            )
          }
          loading={isLoading}
        >
          {isLoading && (
            <StyledMenuButtonCircularProgress size={22} thickness={3} />
          )}
          {t('statementItemMenu.download')}
        </MenuButton>
      }
    >
      {!!bill.billingStatementDocumentId && (
        <StyledMenuItem onClick={handleDownloadPdf}>
          <ListItemText primary={t('statementItemMenu.pdf')} />
        </StyledMenuItem>
      )}
      {!!bill.numberOfTransactionsIncluded && (
        <div>
          <StyledMenuItem disabled={isCsvLoading} onClick={getCsv}>
            <ListItemText
              primary={
                <>
                  {t('statementItemMenu.csv')}
                  {isCsvLoading && (
                    <StyledCircularProgress size={22} thickness={3} />
                  )}
                </>
              }
            />
          </StyledMenuItem>
          <StyledMenuItem disabled={isZipLoading} onClick={getZip}>
            <ListItemText
              primary={
                <>
                  {t('statementItemMenu.zip')}
                  {isZipLoading && (
                    <StyledCircularProgress size={22} thickness={3} />
                  )}
                </>
              }
            />
          </StyledMenuItem>
          <AccountingExportDownloadButtons
            organizationId={bill.organizationId}
            billId={bill.billId}
          />
        </div>
      )}
    </MenuContainer>
  );
};

export default StatementItemMenu;
