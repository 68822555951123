import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { useGetTasks } from 'domains/onboarding/hooks';
import { getOnboardingKeyTypeFromUrl } from 'domains/onboarding/utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OnboardingSectionType } from 'services/constants';
import { logError } from 'services/monitoring';
import { onboardingKeys } from 'services/network/queryKeys';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  comment: string;
}

interface Props extends DialogProps {
  onClose: () => void;
}

const OnboardingResetTaskDialog = (props: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const {
    state: { organization },
  } = useGlobalState();
  const { sectionKey, taskId, groupId = '' } = useParams<{
    sectionKey: string;
    taskId: string;
    groupId?: string;
  }>();

  const { tasks } = useGetTasks();
  const taskType = tasks.find((t) => t.id === taskId)!.type;
  const currentSectionType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingSectionType>(sectionKey),
    [sectionKey]
  );

  const onTaskInvalidate = async () => {
    await Promise.all([
      queryClient.invalidateQueries(
        onboardingKeys.tasks(organization!.id, currentSectionType)
      ),
      groupId &&
        queryClient.invalidateQueries(
          onboardingKeys.groupTasks(organization!.id, groupId)
        ),
      queryClient.invalidateQueries(onboardingKeys.sections(organization!.id)),
    ]);
  };

  const { useResetTask } = useTanstackQuery();
  const { mutate: resetTaskMutate } = useResetTask({
    onSuccess: async () => {
      await onTaskInvalidate();
      if (!mounted.current) return;
      props.onClose();
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      comment: '',
    },
    onSubmit: (values) => {
      if (!values.comment) return;
      resetTaskMutate({
        taskId: taskId,
        data: {
          comment: values.comment,
        },
      });
    },
  });

  const isCommentTooLong = formik.values.comment.length > 255;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.onboardingResetTaskDialog.title')}</DialogTitle>

      <DialogContent>
        <Typography variant="body2" mb={4}>
          <Trans
            i18nKey="int.onboardingResetTaskDialog.description"
            values={{ name: t(`orgOnboardingTaskTitle.${taskType}`) }}
            components={{ b: <b /> }}
          />
        </Typography>
        <TextField
          {...formik.getFieldProps('comment')}
          label={t('int.onboardingResetTaskDialog.commentLabel')}
          multiline
          minRows={3}
          maxRows={5}
          error={isCommentTooLong}
          helperText={
            isCommentTooLong &&
            t('int.onboardingResetTaskDialog.commentTooLongError')
          }
          autoFocus
        />
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={
            formik.isSubmitting || !formik.values.comment || isCommentTooLong
          }
          onClick={formik.submitForm}
        >
          {t('common.button.reset')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(OnboardingResetTaskDialog);
