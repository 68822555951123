import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  withDialogWrapper,
} from 'elements';
import { env } from 'env';
import useLocoiaMappedName from 'hooks/useLocoiaMappedName';
import usePartnerName from 'hooks/usePartnerName';
import useSnackbar from 'hooks/useSnackbar';
import { AccountingSystem, ApiIntegrationStatus } from 'services/constants';

const locoiaStatus = {
  flowEnabled: 'locoia:flowEnabled',
  flowDisabled: 'locoia:flowDisabled',
  allIntegrationsDeleted: 'locoia:allIntegrationsDeleted',
};

const getIframeSrc = (embedId: string, generatedJwt: string, lang: string) => {
  const language = lang === 'de' ? 'de' : 'en';
  const isDebugMode = env.MODE !== 'production' || env.REACT_APP_LOCOIA_DEBUG;
  return `https://api.locoia.com/embedded?embeddedId=${embedId}&token=${generatedJwt}&lang=${language}&debug=${isDebugMode}`;
};

interface DataType {
  data: string | object;
}

interface Props extends DialogProps {
  embedId: string;
  generatedJwt: string;
  onStatusChange: () => void;
  onClose: () => void;
}

const ConnectionDialog = ({
  embedId,
  generatedJwt,
  onStatusChange,
  ...props
}: Props) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const partnerName = usePartnerName();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const locoiaMappedName = useLocoiaMappedName();

  const onIframeMessage = ({ data }: DataType) => {
    if (typeof data !== 'string') return;
    if (data === locoiaStatus.flowEnabled) {
      const keyBase =
        accountingSettings?.accountingSystem === AccountingSystem.lexOffice
          ? 'locoiaIntegrationSubPage.connectedMsg'
          : 'locoiaIntegrationSubPage.activatedMsg';

      enqueueSnackbar(
        t(keyBase, {
          name: accountingSettings?.accountingSystemName,
          partnerName,
        })
      );
      onStatusChange();
    }
    if (
      data === locoiaStatus.flowDisabled ||
      data === locoiaStatus.allIntegrationsDeleted
    ) {
      const keyBase =
        accountingSettings?.accountingSystem === AccountingSystem.lexOffice
          ? 'locoiaIntegrationSubPage.disconnectedMsg'
          : 'locoiaIntegrationSubPage.deactivatedMsg';

      enqueueSnackbar(
        t(keyBase, {
          name: accountingSettings?.accountingSystemName,
          partnerName,
        })
      );
      onStatusChange();
    }
  };

  useEffect(() => {
    window.addEventListener('message', onIframeMessage);
    return () => window.removeEventListener('message', onIframeMessage);
  }, []);

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>
        {accountingSettings!.accountingSystem === AccountingSystem.lexOffice
          ? t(
              accountingSettings!.apiIntegrationStatus ===
                ApiIntegrationStatus.connected
                ? 'locoiaIntegrationSubPage.connectionDialogTitleDisconnect'
                : 'locoiaIntegrationSubPage.connectionDialogTitle',
              {
                name: locoiaMappedName,
                partnerName,
              }
            )
          : t(
              accountingSettings!.apiIntegrationStatus ===
                ApiIntegrationStatus.connected
                ? 'locoiaIntegrationSubPage.connectionDialogTitleDeactivate'
                : 'locoiaIntegrationSubPage.connectionDialogTitleActivate',
              {
                name: locoiaMappedName,
              }
            )}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            height:
              accountingSettings!.apiIntegrationStatus ===
              ApiIntegrationStatus.connected
                ? '300px'
                : '400px',
          }}
        >
          {embedId && generatedJwt && (
            <iframe
              style={{ border: 0 }}
              title="integration-iframe"
              src={getIframeSrc(embedId, generatedJwt, i18n.language)}
              width="100%"
              height="100%"
            ></iframe>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default withDialogWrapper(ConnectionDialog);
