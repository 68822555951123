import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from 'elements';
import { CardType } from 'services/constants';

interface Props extends Omit<SelectProps, 'renderValue' | 'labelId'> {
  selectedCardType: CardType;
  expiryPeriods: number[];
}

const ExpiryPeriodMonthsSelect = ({
  selectedCardType,
  expiryPeriods,
  disabled,
  ...props
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id="expiry-period-months-select-label">
        {t('replaceCardDialog.chooseValidity')}
      </InputLabel>
      <Select
        {...props}
        renderValue={(selected) =>
          `${selected as number} ${t('replaceCardDialog.months')}`
        }
        labelId="expiry-period-months-select-label"
      >
        {expiryPeriods.map((period) => (
          <MenuItem key={period} value={period}>
            {`${period} ${t('replaceCardDialog.months')}`}
          </MenuItem>
        ))}
      </Select>
      {selectedCardType === CardType.virtual && (
        <FormHelperText>
          {t('replaceCardDialog.virtualValidityLabel')}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default ExpiryPeriodMonthsSelect;
