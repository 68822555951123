import { Trans, useTranslation } from 'react-i18next';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import { CardHistoryCommonEntry } from 'services/constants';
import { formatMoney } from 'services/utils';

interface Props {
  entry: CardHistoryCommonEntry;
}

const CreatedIssuedRequestedEventTitle = ({ entry }: Props) => {
  const { t, i18n } = useTranslation();
  const currencyDisplay = useCurrencyDisplayMode();

  return (
    <Trans
      i18nKey={`cardHistory.titleByType_v2.${entry.type}`}
      values={{
        limitWithFrequency: t(
          `cardLimitFrequency.limitWithFrequencies.${entry.limitRenewFrequency}`,
          {
            limit: formatMoney(entry.limit, i18n.language, { currencyDisplay }),
          }
        ),
        transactionLimit: formatMoney(entry.transactionLimit, i18n.language, {
          currencyDisplay,
        }),
      }}
    />
  );
};

export default CreatedIssuedRequestedEventTitle;
