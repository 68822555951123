import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState, useVisibleCardAccounts } from 'context/GlobalState';
import { CardAccount, CURRENCY_NAME_BY_CODE } from 'services/constants';

const useCardAccountNameGetter = () => {
  const { t, i18n } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();
  const visibleCardAccounts = useVisibleCardAccounts();

  return useCallback(
    (accountOrId: CardAccount | string): string => {
      let account: CardAccount | undefined;

      if (typeof accountOrId === 'string') {
        account = visibleCardAccounts.find((item) => item.id === accountOrId);
      } else {
        account = accountOrId;
      }

      if (!account) return '';

      if (featureModules.MULTI_CURRENCY_BILLING) {
        let name = account.name.value;
        const currency = account.currency.value;

        if (account.defaultAccount.value) {
          name = t('cardAccount.defaultName');
        } else if (name === currency) {
          name = CURRENCY_NAME_BY_CODE[currency];
        }

        return `${name} (${currency})`;
      }

      return account.defaultAccount.value
        ? t('cardAccount.defaultName')
        : account.name.value;
    },
    [featureModules.MULTI_CURRENCY_BILLING, visibleCardAccounts, i18n.language]
  );
};

export default useCardAccountNameGetter;
