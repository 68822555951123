import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MemberStatusBadge } from 'domains/member/components';
import { GridColDef, gridUtils } from 'elements';
import { PartnerMember } from 'services/constants';
import { getFullName } from 'services/utils';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<PartnerMember>[] = [
      {
        headerName: t('portal.partnerMembersPage.name'),
        field: 'firstName',
        flex: 150,
        valueGetter: ({ row }) => getFullName(row.firstName, row.lastName),
      },
      {
        headerName: t('portal.partnerMembersPage.email'),
        field: 'email',
        flex: 150,
        valueGetter: ({ row }) => row.email || '',
        sortable: false,
      },
      {
        headerName: t('portal.partnerMembersPage.role'),
        field: 'roles',
        flex: 100,
        valueGetter: ({ row }) =>
          row.roles.map((role) => t(`roles.${role}`)).join(', '),
        sortable: false,
      },
      {
        headerName: t('portal.partnerMembersPage.status'),
        field: 'status',
        flex: 100,
        renderCell: ({ row }) => <MemberStatusBadge status={row.status} />,
      },
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language]);
};

export default useColumns;
