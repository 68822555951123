import { useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { LoaderWithOverlay } from 'components/Loader';
import {
  Button,
  DialogActions,
  DialogContent,
  Link,
  Typography,
} from 'elements';
import useIdTokenCustomData from 'hooks/useIdTokenCustomData';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OpenActiveOptIn } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

export interface Props {
  isLoading: boolean;
  openActiveOptIn?: OpenActiveOptIn;
  onSuccess: () => void;
}

const LegalRepInternal = ({ isLoading, openActiveOptIn, onSuccess }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const { organizationId } = useIdTokenCustomData();
  const [state, setState] = useState({
    isAccepting: false,
  });

  if (!openActiveOptIn) return null;

  const acceptActiveOptIn = async () => {
    try {
      setState((prevState) => ({ ...prevState, isAccepting: true }));
      await api.acceptOpenActiveOptIn(
        organizationId,
        openActiveOptIn!.termsAndConditionsType
      );
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    } finally {
      if (mounted.current) {
        setState((prevState) => ({ ...prevState, isAccepting: false }));
        onSuccess();
      }
    }
  };

  return (
    <>
      <DialogContent>
        <Typography>
          <Trans
            i18nKey="updatedTermsAndConditionsDialog.documentType"
            components={{
              a: (
                <Link
                  href={openActiveOptIn.documentUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              u: <u />,
            }}
            values={{
              type: t(
                `updatedTermsAndConditionsDialog.types.${openActiveOptIn.termsAndConditionsType}`
              ),
            }}
          />

          <br />
          <br />

          <Trans
            i18nKey="updatedTermsAndConditionsDialog.legalRepDescription"
            components={{
              b: <b />,
            }}
            values={{
              date: moment(openActiveOptIn.documentVersion).format(
                'DD.MM.YYYY'
              ),
              phone: openActiveOptIn.customerSupportPhone,
            }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button disabled={state.isAccepting} onClick={acceptActiveOptIn}>
          {t('updatedTermsAndConditionsDialog.buttons.accept')}
        </Button>
      </DialogActions>
      {(isLoading || state.isAccepting) && (
        <LoaderWithOverlay size={32} thickness={3} />
      )}
    </>
  );
};

export default LegalRepInternal;
