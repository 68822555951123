import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  FilePlusIcon,
  ListItemIcon,
  LoaderWithOverlay,
  Menu,
  MenuItem,
  PlusIcon,
  UserIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CreateNewRepresentative, RelatedIndividual } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  onSelect: (representative: CreateNewRepresentative) => void;
}

interface State {
  inputValue: string;
  isLoading: boolean;
  relatedIndividuals: RelatedIndividual[];
}

const AddRepresentativeButton = ({ onSelect }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    inputValue: '',
    isLoading: true,
    relatedIndividuals: [],
  });

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    (async () => {
      try {
        const { relatedIndividuals } = await api.getRelatedIndividuals(
          organization!.id
        );
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          relatedIndividuals: relatedIndividuals.filter(
            // external datasource with incomplete datasets
            (item) => item.firstName && item.lastName
          ),
        }));
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setState((prevState) => ({ ...prevState, isLoading: false }));
        logError(error);
      }
    })();
  }, []);

  return (
    <Box position="relative" width="fit-content">
      <LoaderWithOverlay loading={state.isLoading} />

      <Button
        disabled={state.isLoading}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        variant="outlined"
        startIcon={<PlusIcon />}
      >
        {t('int.representativeSection.addRepresentativeButton')}
      </Button>

      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={() => setMenuAnchorEl(null)}
      >
        {state.relatedIndividuals.map((relatedIndividual) => (
          <MenuItem
            key={relatedIndividual.id}
            value={relatedIndividual.id}
            onClick={() => {
              onSelect(
                state.relatedIndividuals.find(
                  (item) => item.id === relatedIndividual.id
                )!
              );
              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <UserIcon />
            </ListItemIcon>
            <Box component="span">
              {relatedIndividual.firstName} {relatedIndividual.lastName}
            </Box>
          </MenuItem>
        ))}

        <MenuItem
          value="manual"
          onClick={() => {
            onSelect({
              firstName: '',
              lastName: '',
              id: 'manual',
              idType: null,
            });
            setMenuAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <FilePlusIcon />
          </ListItemIcon>
          <Box component="span">
            {t('int.representativeSection.addManually')}
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AddRepresentativeButton;
