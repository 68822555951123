import { useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import CompletedMessageBlock from 'components/CompletedMessageBlock';
import { LoaderWithOverlay } from 'components/Loader';
import OnboardingBlockWrapper from 'components/OnboardingPage/OnboardingBlockWrapper';
import { MailDescription } from 'components/OnboardingPage/style';
import { useOnboardingContext } from 'components/OnboardingPage/useOnboardingContext';
import { useGlobalState } from 'context/GlobalState';
import {
  BankAccountsTable,
  BankAccountsTableRef,
} from 'domains/billing/components';
import { FIN_API_SUPPORTED_COUNTRIES } from 'domains/billing/constants';
import { ConnectBankAccountsThroughFinApiDialog } from 'domains/billing/dialogs';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useSnackbar from 'hooks/useSnackbar';
import { OrganizationStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import { ConnectButton, DescriptionBlock } from './style';

const RiskAssessmentBlock = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const mounted = useMounted();
  const partnerName = usePartnerName();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    actions: { updateOnboarding },
    state: onboardingState,
  } = useOnboardingContext();
  const onboarding = onboardingState.onboarding!;
  const {
    state: { organization },
  } = useGlobalState();
  const bankAccountsTableRef = useRef<BankAccountsTableRef>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [
    isConnectBankAccountDialogOpen,
    setIsConnectBankAccountDialogOpen,
  ] = useState(false);

  const confirmBankAccounts = async () => {
    try {
      setIsLoading(true);
      const data = await api.confirmBankAccountsInOnboarding(
        onboarding.organizationId
      );
      if (!mounted.current) return;
      setIsLoading(false);
      updateOnboarding(data);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const getRiskAssessmentBlockState = () => {
    if (
      [
        OrganizationStatus.onboardingRiskData,
        OrganizationStatus.onboardingRiskReview,
      ].includes(onboarding.status)
    ) {
      return 'active';
    }

    if (onboarding.riskAssessmentCompleted) return 'completed';

    return 'disabled';
  };

  return (
    <OnboardingBlockWrapper
      title={t('riskAssessmentBlock.title')}
      state={getRiskAssessmentBlockState()}
      testId="onboarding-risk-block"
    >
      {onboarding.bankConnectionRequired ? (
        <>
          {onboarding.status === OrganizationStatus.onboardingRiskData && (
            <>
              <Box display="flex" alignItems="flex-end">
                <DescriptionBlock>
                  {t('riskAssessmentBlock.bankConnectionDescriptionA')}
                  <Box pt="26px" mb="16px">
                    <Trans
                      i18nKey="riskAssessmentBlock.bankConnectionDescriptionB"
                      components={{ bold: <b /> }}
                      values={{
                        partnerName,
                      }}
                    />
                  </Box>
                </DescriptionBlock>
                {FIN_API_SUPPORTED_COUNTRIES.includes(
                  organization!.country
                ) && (
                  <ConnectButton
                    onClick={() => setIsConnectBankAccountDialogOpen(true)}
                    disabled={!canUser('bank-account:connect')}
                  >
                    {t('riskAssessmentBlock.connectBusinessAccount')}
                  </ConnectButton>
                )}
              </Box>
            </>
          )}
          {onboarding.status === OrganizationStatus.onboardingRiskReview && (
            <CompletedMessageBlock $margin="12px 0 24px">
              {t('riskAssessmentBlock.connectionCompletedMessage')}
            </CompletedMessageBlock>
          )}
          {onboarding.riskAssessmentCompleted && (
            <CompletedMessageBlock $margin="12px 0 24px">
              {t('riskAssessmentBlock.completedMessage')}
            </CompletedMessageBlock>
          )}
          <BankAccountsTable
            ref={bankAccountsTableRef}
            onConfirm={
              onboarding.status === OrganizationStatus.onboardingRiskData
                ? confirmBankAccounts
                : undefined
            }
          />
          <ConnectBankAccountsThroughFinApiDialog
            open={isConnectBankAccountDialogOpen}
            onClose={() => setIsConnectBankAccountDialogOpen(false)}
          />
        </>
      ) : (
        <>
          {onboarding.status === OrganizationStatus.onboardingRiskReview && (
            <MailDescription $mb={3}>
              {t('riskAssessmentBlock.description')}
            </MailDescription>
          )}
          {onboarding.riskAssessmentCompleted && (
            <CompletedMessageBlock $margin="12px 0 8px">
              {t('riskAssessmentBlock.completedMessage')}
            </CompletedMessageBlock>
          )}
        </>
      )}

      {isLoading && <LoaderWithOverlay size={32} thickness={3} />}
    </OnboardingBlockWrapper>
  );
};

export default RiskAssessmentBlock;
