import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from '@mui/material';

type Size = 'small' | 'medium' | 'large';

const MUI_NUMERIC_SIZE_BY_SIZE: Record<Size, number> = {
  small: 18,
  medium: 32,
  large: 64,
};

export interface CircularProgressProps extends MuiCircularProgressProps {
  /**
   * The size of the component.
   * @default 'medium'
   */
  size?: Size;
}

export const CircularProgress = ({
  size = 'medium',
  ...props
}: CircularProgressProps) => (
  <MuiCircularProgress
    size={MUI_NUMERIC_SIZE_BY_SIZE[size]}
    thickness={3}
    {...props}
  />
);

export * from './LoaderWithOverlay';
