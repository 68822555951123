import { useTranslation } from 'react-i18next';
import { Typography } from 'elements';
import { TransactionStatus } from 'services/constants';

const getColor = (status: TransactionStatus) => {
  if (status === TransactionStatus.pending) return 'info.main';
  if (status === TransactionStatus.declined) return 'error.main';
  if (status === TransactionStatus.reversed) return 'action.active';
  return 'textPrimary';
};

interface Props {
  status: TransactionStatus;
}

const TransactionStatusLabel = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <Typography variant="overline" color={getColor(status)}>
      {t(`transactionStatuses.${status}`)}
    </Typography>
  );
};

export default TransactionStatusLabel;
