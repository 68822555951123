import { MouseEvent } from 'react';
import { CardIconWithTooltip } from 'domains/card/components';
import { Typography } from 'elements';
import { Member } from 'services/constants';
import { filterAndSortMemberCardsInMember } from 'services/utils';

interface Props {
  cards: Member['cards'];
  onCardClick?: (e: MouseEvent<HTMLSpanElement>, cardId: string) => void;
}

const CardsCell = ({ cards, onCardClick }: Props) => {
  const trailingNum = cards.length > 4 ? cards.length - 4 : 0;
  const visibleCards = filterAndSortMemberCardsInMember(cards);

  return (
    <>
      {visibleCards.slice(0, 4).map((card) => (
        <CardIconWithTooltip
          key={card.cardId}
          cardId={card.cardId}
          cardDesignId={card.cardDesignId}
          onClick={onCardClick}
        />
      ))}

      {!!trailingNum && (
        <Typography variant="subtitle2">+{trailingNum}</Typography>
      )}
    </>
  );
};

export default CardsCell;
