import { useMemo } from 'react';
import { useFormik } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { useTranslation } from 'react-i18next';
import { useGlobalState, useVisibleCardAccounts } from 'context/GlobalState';
import { CodatEntitySearchSelect } from 'domains/settings/components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  InputLabelTooltipIcon,
  LoaderWithOverlay,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useSnackbar from 'hooks/useSnackbar';
import {
  CodatCompanyBankAccount,
  CodatCompanyConfig,
  CodatCompanyCustomer,
  CodatCompanySupplier,
  UpdateCodatCompanyConfigData,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  bankAccount: CodatCompanyBankAccount | null;
  supplier: CodatCompanySupplier | null;
  customer: CodatCompanyCustomer | null;
}

const getFormikInitialValues = (
  codatCompanyConfig: CodatCompanyConfig
): FormValues => {
  return {
    bankAccount: codatCompanyConfig.bankAccount.id
      ? (codatCompanyConfig.bankAccount as CodatCompanyBankAccount)
      : null,
    supplier: codatCompanyConfig.supplier.id
      ? (codatCompanyConfig.supplier as CodatCompanySupplier)
      : null,
    customer: codatCompanyConfig.customer.id
      ? (codatCompanyConfig.customer as CodatCompanyCustomer)
      : null,
  };
};

interface Props extends DialogProps {
  codatCompanyConfig: CodatCompanyConfig;
  onUpdate: (codatCompanyConfig: CodatCompanyConfig) => void;
  onClose: () => void;
}

const CodatGeneralAccountsChangeDialog = ({
  codatCompanyConfig,
  onUpdate,
  ...props
}: Props) => {
  const partnerName = usePartnerName();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const visibleCardAccounts = useVisibleCardAccounts();
  const initialValues = useMemo(
    () => getFormikInitialValues(codatCompanyConfig),
    [codatCompanyConfig]
  );

  const formik = useFormik<FormValues>({
    validateOnMount: true,
    validateOnChange: true,
    initialValues,
    validate(values) {
      const errors: FormikErrors<FormValues> = {};
      if (!values.bankAccount) errors.bankAccount = t('errors.required');
      if (!values.supplier) errors.supplier = t('errors.required');
      if (!values.customer) errors.customer = t('errors.required');
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const updatedCodatCompanyConfig = await api.updateCodatCompanyConfig(
          organization!.id,
          values as UpdateCodatCompanyConfigData
        );
        if (!mounted.current) return;
        onUpdate(updatedCodatCompanyConfig);
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('datevExportSettingsGroup.generalAccounts')}</DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="codat-accounts-change-form"
          noValidate
        >
          <Grid container rowSpacing={2}>
            {visibleCardAccounts.length < 2 && (
              <Grid item xs={12}>
                <CodatEntitySearchSelect<CodatCompanyBankAccount>
                  label={
                    <>
                      {t('datevExportSettingsGroup.pliantCreditCardAccount', {
                        partnerName,
                      })}
                      <InputLabelTooltipIcon
                        title={t(
                          'datevExportSettingsGroup.pliantCreditCardAccountTitle',
                          {
                            partnerName,
                          }
                        )}
                      />
                    </>
                  }
                  placeholder={t(
                    'datevExportSettingsGroup.bankAccountPlaceholder'
                  )}
                  value={formik.values.bankAccount}
                  onChange={(value) =>
                    formik.setFieldValue('bankAccount', value)
                  }
                  getPageableList={({ q, page, limit }) =>
                    api
                      .getCodatCompanyBankAccounts(organization!.id, {
                        name: q || undefined,
                        page,
                        limit,
                      })
                      .then((data) => ({
                        items: data.bankAccounts,
                        hasNextPage: data.hasNextPage,
                      }))
                  }
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <CodatEntitySearchSelect<CodatCompanySupplier>
                label={
                  <>
                    {t('datevExportSettingsGroup.defaultSupplierAccount')}
                    <InputLabelTooltipIcon
                      title={t(
                        'datevExportSettingsGroup.defaultSupplierAccountTitle',
                        {
                          partnerName,
                        }
                      )}
                    />
                  </>
                }
                placeholder={t('datevExportSettingsGroup.supplierPlaceholder')}
                value={formik.values.supplier}
                onChange={(value) => formik.setFieldValue('supplier', value)}
                getPageableList={({ q, page, limit }) =>
                  api
                    .getCodatCompanySuppliers(organization!.id, {
                      name: q || undefined,
                      page,
                      limit,
                    })
                    .then((data) => ({
                      items: data.suppliers,
                      hasNextPage: data.hasNextPage,
                    }))
                }
              />
            </Grid>

            <Grid item xs={12}>
              <CodatEntitySearchSelect<CodatCompanyCustomer>
                label={
                  <>
                    {t('datevExportSettingsGroup.pliantCustomer', {
                      partnerName,
                    })}
                    <InputLabelTooltipIcon
                      title={t('datevExportSettingsGroup.pliantCustomerTitle', {
                        partnerName,
                      })}
                    />
                  </>
                }
                placeholder={t('datevExportSettingsGroup.customerPlaceholder')}
                value={formik.values.customer}
                onChange={(value) => formik.setFieldValue('customer', value)}
                getPageableList={({ q, page, limit }) =>
                  api
                    .getCodatCompanyCustomers(organization!.id, {
                      name: q || undefined,
                      page,
                      limit,
                    })
                    .then((data) => ({
                      items: data.customers,
                      hasNextPage: data.hasNextPage,
                    }))
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          form="codat-accounts-change-form"
          disabled={formik.isSubmitting || !formik.isValid}
          type="submit"
        >
          {t('datevExportSettingsGroup.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(CodatGeneralAccountsChangeDialog);
