import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryParams as GlobalMembersPageQueryParams } from 'domains/member/pages/GlobalMembersPage';
import { QueryParams as MembersPageQueryParams } from 'domains/member/pages/MembersPage';
import { Button, Chip, FunnelSimpleIcon, Tooltip, Typography } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import {
  getFilterChipText,
  PageFilterChips,
  PageFilters,
  PageSearchInput,
} from 'layout';
import { Team } from 'services/constants';
import { Role } from 'services/rbac';
import FiltersDrawer from './FiltersDrawer';

interface Props {
  // `shownRoles` is used when possible user's roles are different
  shownRoles?: Role[];
  params: GlobalMembersPageQueryParams | MembersPageQueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  teams?: Team[];
  membersCount: number;
}

const MembersPageFilters = ({
  shownRoles,
  params,
  selectedFiltersCount,
  setParam,
  teams = [],
  membersCount,
}: Props) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const roleChipTooltip = useMemo(() => {
    if (params.role.length < 2) return '';
    return params.role.map((item) => t(`roles.${item}`)).join(', ');
  }, [params.role]);

  const statusChipTooltip = useMemo(() => {
    if (params.status.length < 2) return '';
    return params.status
      .map((item) => item[0] + item.slice(1).toLowerCase())
      .join(', ');
  }, [params.status]);

  const teamIdsChipTooltip = useMemo(() => {
    if ('teamIds' in params && params.teamIds.length < 2) return '';
    // teamIds are validated, so it's ok to use non-null assertion here
    return ('teamIds' in params ? params.teamIds : [])
      .map((teamId) => teams.find((item) => item.id === teamId)!.name)
      .join(', ');
  }, [params, teams]);

  return (
    <>
      <PageFilters>
        <PageSearchInput
          initialValue={params.q}
          onChange={(value) => setParam('q', value)}
        />
        <Button
          sx={{ ml: 2 }}
          variant={selectedFiltersCount ? 'contained' : 'text'}
          startIcon={<FunnelSimpleIcon />}
          onClick={() => setIsFilterOpen(true)}
        >
          {t('filterDrawer.title')}
          {!!selectedFiltersCount && ` (${selectedFiltersCount})`}
        </Button>
        <Typography variant="body2" color="textSecondary" ml={2}>
          {t('common.resultsTable', { count: membersCount })}
        </Typography>
      </PageFilters>

      {!!selectedFiltersCount && (
        <PageFilterChips>
          {!!params.status.length && (
            <Tooltip title={statusChipTooltip}>
              <Chip
                label={getFilterChipText(
                  params.status[0][0] + params.status[0].slice(1).toLowerCase(),
                  params.status.length
                )}
                onDelete={() => setParam('status', [])}
              />
            </Tooltip>
          )}

          {!!params.role.length && (
            <Tooltip title={roleChipTooltip}>
              <Chip
                label={getFilterChipText(
                  t(`roles.${params.role[0]}`),
                  params.role.length
                )}
                onDelete={() => setParam('role', [])}
              />
            </Tooltip>
          )}

          {'teamIds' in params && !!params.teamIds.length && (
            <Tooltip title={teamIdsChipTooltip}>
              <Chip
                label={getFilterChipText(
                  teams.find((team) => team.id === params.teamIds[0])?.name ||
                    '',
                  params.teamIds.length,
                  t('filters.team')
                )}
                onDelete={() => setParam('teamIds', [])}
              />
            </Tooltip>
          )}
        </PageFilterChips>
      )}

      <FiltersDrawer
        shownRoles={shownRoles}
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        params={params}
        setParam={setParam}
        teams={teams}
      />
    </>
  );
};

export default MembersPageFilters;
