import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Paper, Typography } from 'elements';
import { Card } from 'services/constants';

interface Props {
  cardValidity: Card['cardValidity'];
}

const CardCustomValidityPeriod = ({ cardValidity }: Props) => {
  const { t } = useTranslation();

  if (!cardValidity) return null;

  return (
    <Paper
      variant="outlined"
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 1.5, 2, 2),
        mt: 1,
      })}
    >
      <Typography variant="caption" color="text.secondary" noWrap>
        {t('cardDetails.validityPeriod')}
      </Typography>
      <Typography variant="body2" flexShrink={0} ml="auto">
        {`${moment(cardValidity.validFrom).format('D MMM YYYY')} - ${moment(
          cardValidity.validTo
        ).format('D MMM YYYY')}`}
      </Typography>
    </Paper>
  );
};

export default CardCustomValidityPeriod;
