import React, { useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import flowRight from 'lodash/flowRight';
import withLoader from 'components/App/withLoader';
import { useGlobalState } from 'context/GlobalState';
import { withFeatureFlagsProvider } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import withInitialLogic from './withInitialLogic';
import withTheme from './withTheme';

const PortalUserData = ({ children }: { children: React.ReactNode }) => {
  const api = useImperativeApi();
  const {
    dispatch,
    state: { isLoading, error },
  } = useGlobalState();

  const initialize = async () => {
    try {
      const {
        currencies: cardAccountCurrencies,
      } = await api.getCardAccountCurrencies();

      dispatch({
        type: 'SET_CARD_ACCOUNT_CURRENCIES',
        payload: { cardAccountCurrencies },
      });
      dispatch({ type: 'SET_IS_LOADING', payload: false });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error });
      logError(error);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  if (isLoading) return null;
  if (error) return null;

  return children;
};

export default flowRight([
  withLoader,
  withAuthenticationRequired,
  withInitialLogic,
  withFeatureFlagsProvider,
  withTheme,
])(PortalUserData);
