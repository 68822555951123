import { useFormik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DatePicker,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MemberDetails } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  dateOfBirth: string | null;
}

interface Props extends DialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (member: MemberDetails) => void;
  member: MemberDetails;
}

const DateOfBirthDialog = ({ member, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    initialValues: {
      dateOfBirth: member.dateOfBirth,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await api.updateMemberDateOfBirth(member.id, {
          dateOfBirth: values.dateOfBirth!,
        });
        if (!mounted.current) return;
        enqueueSnackbar(t('editDateOfBirthDialog.success'));
        onSuccess({
          ...member,
          ...values,
        });
      } catch (error) {
        logError(error);
        enqueueSnackbar(getGenericErrorMsg(error), {
          variant: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('editDateOfBirthDialog.title')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="date-of-birth-form" noValidate>
          <DatePicker
            label={t('editDateOfBirthDialog.dateOfBirthLabel')}
            disableFuture
            value={member.dateOfBirth ? moment(member.dateOfBirth) : null}
            onChange={(newDate) =>
              formik.setFieldValue(
                'dateOfBirth',
                moment(newDate).format('YYYY-MM-DD')
              )
            }
            maxDate={moment().subtract(12, 'years')}
            slotProps={{
              field: {
                format: 'DD.MM.YYYY',
              },
              textField: {
                InputProps: { notched: false },
                size: 'medium',
                error: !!formik.errors.dateOfBirth,
                helperText: formik.errors.dateOfBirth,
              },
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={formik.isSubmitting || !formik.values.dateOfBirth}
          form="date-of-birth-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateOfBirthDialog;
