import { Accordion } from '@material-ui/core';
import styled from 'styled-components';
import ValidationCheckIcon from 'assets/icons/interface/validationCheck.svg?react';

export const StyledAccordion = styled(Accordion)`
  border-radius: unset;
  border: unset;
  border-color: ${({ theme }) => theme.colors.neutral90};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral90};
  box-shadow: none;

  &.Mui-expanded {
    border-color: ${({ theme }) => theme.colors.neutral90};
  }

  &.MuiAccordion-root:before {
    position: initial;
  }

  &.MuiPaper-root.MuiAccordion-root {
    margin: 0;
  }

  .MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionSummary-content {
    align-items: center;
    margin: 0;
  }

  .MuiAccordionSummary-expandIcon {
    color: initial;
  }
`;

export const Heading = styled.h3`
  font-size: 18px;
  line-height: 24px;
  margin: 32px 0;
`;

export const StyledValidationCheckIcon = styled(ValidationCheckIcon)`
  width: 16px;
  height: 16px;
  margin-right: 16px;
`;

export const ReasonInfoRequestWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.yellow90};
  border: 1px solid ${({ theme }) => theme.colors.yellow40};
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  padding: 8px;
`;

export const Form = styled.form`
  width: 100%;
`;
