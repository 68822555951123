import { ChangeEvent, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { getCardNameWithRefNum } from 'domains/card/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withDialogWrapper,
} from 'elements';
import {
  Card,
  CardReplacementReason,
  CardType,
  physicalCardReplacementReasons,
  virtualCardReplacementReasons,
} from 'services/constants';
import SecondStep from './SecondStep';

interface Props extends DialogProps {
  card: Card;
  onClose: () => void;
  onSuccess: (newCard: Card) => void;
}

const ReplaceCardDialog = ({ card, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState<CardReplacementReason | ''>('');
  const [step, setStep] = useState<1 | 2>(1);

  const replacementReasons =
    card.type === CardType.virtual
      ? virtualCardReplacementReasons
      : physicalCardReplacementReasons;

  const cardType = card.type === CardType.virtual ? 'virtual' : 'physical';

  const isSubmitDisabled = !reason;

  const isExpiringSoon =
    moment(card.expiryDate).diff(moment().utc(), 'months') <= 3;

  const renderFirstStep = () => {
    if (step === 1) {
      return (
        <>
          <DialogTitle>{t('replaceCardDialog.replaceCardHeading')}</DialogTitle>
          <DialogContent>
            <Box mb={4}>
              <Typography variant="body2">
                <Trans
                  i18nKey="replaceCardDialog.selectReason"
                  components={{
                    b: <b />,
                  }}
                  values={{ cardName: getCardNameWithRefNum(card) }}
                />
              </Typography>
            </Box>
            <RadioGroup
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setReason(e.target.value as CardReplacementReason)
              }
              value={reason}
            >
              {replacementReasons.map((reason) => {
                if (
                  reason === CardReplacementReason.expiringSoon &&
                  !isExpiringSoon
                ) {
                  return null;
                }
                return (
                  <FormControlLabel
                    key={reason}
                    value={reason}
                    control={<Radio />}
                    label={t(`replaceCardReasons.${cardType}.${reason}`)}
                  />
                );
              })}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={props.onClose}>
              {t('common.button.cancel')}
            </Button>
            <Button disabled={isSubmitDisabled} onClick={() => setStep(2)}>
              {t('common.button.continue')}
            </Button>
          </DialogActions>
        </>
      );
    }
    return null;
  };

  return (
    <Dialog {...props} maxWidth={step === 1 ? 'xs' : 'sm'}>
      {renderFirstStep()}
      {step === 2 && (
        <SecondStep
          onClose={props.onClose}
          goToPrevStep={() => setStep(1)}
          reason={reason as CardReplacementReason}
          card={card}
          onSuccess={onSuccess}
        />
      )}
    </Dialog>
  );
};

export default withDialogWrapper<Props>(ReplaceCardDialog);
