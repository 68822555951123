import {
  ProjectData,
  SubcategoryData,
  SupplierData,
  TeamData,
  useProjectFilter,
  useSubcategoryFilter,
  useSupplierFilter,
  useTeamFilter,
  useVatRateFilter,
  VatRateData,
} from 'domains/transaction/hooks';
import { QueryParams } from 'domains/transaction/pages/ExportPage/utils';

const useFilters = (
  params: Pick<
    QueryParams,
    'vatRate' | 'supplier' | 'project' | 'team' | 'subcategory'
  >
) => {
  const { vatRateData, setVatRateFilter } = useVatRateFilter(params.vatRate);
  const { supplierData, setSupplierFilter } = useSupplierFilter(
    params.supplier
  );
  const { projectData, setProjectFilter } = useProjectFilter(params.project);
  const { teamData, setTeamFilter } = useTeamFilter(params.team);
  const { subcategoryData, setSubcategoryFilter } = useSubcategoryFilter(
    params.subcategory
  );

  const setDataFilters = ({
    vatRate,
    supplier,
    project,
    team,
    subcategory,
  }: {
    vatRate: Pick<VatRateData, 'value'>;
    supplier: Pick<SupplierData, 'value'>;
    project: Pick<ProjectData, 'value'>;
    team: Pick<TeamData, 'value'>;
    subcategory: Pick<SubcategoryData, 'value'>;
  }) => {
    setVatRateFilter(vatRate);
    setSupplierFilter(supplier);
    setProjectFilter(project);
    setTeamFilter(team);
    setSubcategoryFilter(subcategory);
  };

  return {
    vatRateData,
    supplierData,
    projectData,
    teamData,
    subcategoryData,
    areFiltersLoading:
      vatRateData.isLoading ||
      supplierData.isLoading ||
      projectData.isLoading ||
      teamData.isLoading ||
      subcategoryData.isLoading,
    setDataFilters,
  };
};

export default useFilters;
export type {
  SupplierData,
  VatRateData,
  ProjectData,
  TeamData,
  SubcategoryData,
};
