import { memo, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { env } from 'env';

interface MessageEventData {
  eventType:
    | 'CARD_DATA_LOADING_SUCCESS'
    | 'CARD_DATA_CLEARED'
    | 'CARD_DATA_LOADING_FAILED'
    | 'CARD_DATA_PAN_COPIED'
    | 'CARD_DATA_CVV_COPIED';
  frameId: string;
}

interface Props {
  type: 'pan' | 'cvv';
  cardId: string;
  traceId: string;
  totp: string;
  textAlign?: 'center' | 'right';
  textColor?: string; // hex
  onLoadSuccess: () => void;
  onLoadError: () => void;
  onClear: () => void;
  onCopy?: () => void;
}

const CardSensitiveDataValuePci = ({
  type,
  cardId,
  traceId,
  totp,
  textAlign,
  textColor,
  onLoadSuccess,
  onLoadError,
  onClear,
  onCopy,
}: Props) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const encodedData = useMemo(() => {
    const data = JSON.stringify({
      token: totp,
      cardId: cardId,
      styleUrl: window.location.origin + '/css/pci-widget-v2.css',
      cssClass: clsx(
        !!onCopy && 'clickable',
        !!textAlign && `text-align-${textAlign}`,
        !!textColor && `color-${textColor.slice(1)}`
      ),
      pan: {
        display: type === 'pan',
        clickToCopy: type === 'pan' && !!onCopy,
      },
      cardholderName: { display: false },
      expiryDate: { display: false },
      cvv: {
        display: type === 'cvv',
        clickToCopy: type === 'cvv' && !!onCopy,
      },
    });

    return btoa(data);
  }, []);

  useEffect(() => {
    const onMessage = (e: MessageEvent<MessageEventData>) => {
      if (e.source === iframeRef.current?.contentWindow) {
        switch (e.data.eventType) {
          case 'CARD_DATA_LOADING_SUCCESS':
            onLoadSuccess();
            break;
          case 'CARD_DATA_LOADING_FAILED':
            onLoadError();
            break;
          case 'CARD_DATA_CLEARED':
            onClear();
            break;
          case 'CARD_DATA_PAN_COPIED':
            if (type === 'pan' && onCopy) onCopy();
            break;
          case 'CARD_DATA_CVV_COPIED':
            if (type === 'cvv' && onCopy) onCopy();
        }
      }
    };

    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, []);

  return (
    <iframe
      ref={iframeRef}
      src={`${env.REACT_APP_PCI_API_URL}/card-details/widget?traceId=${traceId}&params=${encodedData}`}
      allow={`clipboard-write`}
      title={type}
      width={type === 'pan' ? 165 : 40}
      height="20px"
      style={{ display: 'block', border: 'none' }}
    />
  );
};

export default memo(CardSensitiveDataValuePci);
