import { memo } from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 6px;
  font-size: 24px;
  line-height: 46px;
  color: ${(props) => props.theme.colors.grey10};
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant80};
  text-align: center;
`;

interface Props {
  bankName: string;
}

// todo: remove this component in scope of onboarding redesign
const BankAvatar = ({ bankName }: Props) => {
  const label = bankName
    .split(' ')
    .map((str) => str[0])
    .concat('B')
    .slice(0, 2)
    .join('');

  return <Wrapper>{label}</Wrapper>;
};

export default memo(BankAvatar);
