import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CardPin } from 'domains/card/components';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  pin: string;
  isLoading: boolean;
}

interface Props {
  onClose: () => void;
  card: Card;
}

export const SuccessStep = ({ onClose, card }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({ pin: '', isLoading: true });

  const getData = async () => {
    try {
      const { pin } = await api.getCardPin(card.cardId);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, pin, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <DialogTitle>{t('activateCardDialog.successStep.title')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          <Trans
            i18nKey="activateCardDialog.successStep.description"
            components={{ b: <b /> }}
          />
        </Typography>
        <Box mt={4} mb={4}>
          <CardPin pin={state.pin} />
        </Box>
        <Alert severity="warning">
          <AlertTitle>
            {t('activateCardDialog.successStep.warningTitle')}
          </AlertTitle>
          {t('activateCardDialog.successStep.warningDescription')}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.button.close')}</Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </>
  );
};

export default SuccessStep;
