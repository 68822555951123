import { useState } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState, useVisibleCardAccounts } from 'context/GlobalState';
import { Box, EditButton, Typography } from 'elements';
import { CardAccount } from 'services/constants';
import DataItemCell from './DataItemCell';
import EditPaymentSettingsDialog from './EditPaymentSettingsDialog';
import { CustomTable } from './style';

interface State {
  cardAccountOpenInDialog: CardAccount | null;
  isEditPaymentSettingsDialogOpen: boolean;
}

const PaymentSettingsSection = () => {
  const { t } = useTranslation();
  const {
    state: { organizationsStaticData },
  } = useGlobalState();
  const visibleCardAccounts = useVisibleCardAccounts();
  const [state, setState] = useState<State>({
    cardAccountOpenInDialog: null,
    isEditPaymentSettingsDialogOpen: false,
  });

  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        justifyContent="space-between"
        py={1}
      >
        <Typography variant="h6">
          {t('int.paymentSettingsSection.title')}
        </Typography>
      </Box>

      <CustomTable>
        <thead>
          <tr>
            <th />
            <Typography
              color="textSecondary"
              variant="caption"
              component="th"
              maxWidth="200px"
            >
              {t('int.paymentSettingsSection.minimumFrequency')}
            </Typography>
            <Typography color="textSecondary" variant="caption" component="th">
              {t('int.paymentSettingsSection.currentFrequency')}
            </Typography>
            <Typography color="textSecondary" variant="caption" component="th">
              {t('int.paymentSettingsSection.paymentTerms')}
            </Typography>
            <th />
          </tr>
        </thead>
        <tbody>
          {sortBy(visibleCardAccounts, ['createdAt']).map((cardAccount) => (
            <tr key={cardAccount.id}>
              <DataItemCell
                updatedAt={cardAccount.name.updatedAt}
                updatedBy={cardAccount.name.updatedBy}
                value={cardAccount.name.value}
              />
              <DataItemCell
                updatedAt={cardAccount.minimumPaymentFrequency.updatedAt}
                updatedBy={cardAccount.minimumPaymentFrequency.updatedBy}
                value={
                  organizationsStaticData!.paymentFrequencies.find(
                    (item) =>
                      item.name === cardAccount.minimumPaymentFrequency.value
                  )?.label
                }
              />
              <DataItemCell
                updatedAt={cardAccount.paymentFrequency.updatedAt}
                updatedBy={cardAccount.paymentFrequency.updatedBy}
                value={
                  organizationsStaticData!.paymentFrequencies.find(
                    (item) => item.name === cardAccount.paymentFrequency.value
                  )?.label
                }
              />
              <DataItemCell
                updatedAt={cardAccount.paymentDefermentInDays.updatedAt}
                updatedBy={cardAccount.paymentDefermentInDays.updatedBy}
                value={
                  typeof cardAccount.paymentDefermentInDays.value ===
                    'number' && cardAccount.paymentDefermentInDays.value === 0
                    ? t('int.paymentSettingsSection.immediate')
                    : t('int.paymentSettingsSection.days', {
                        count: cardAccount.paymentDefermentInDays.value,
                      })
                }
              />
              <td>
                <Box position="absolute" right={0} top={-10}>
                  <EditButton
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        cardAccountOpenInDialog: cardAccount,
                        isEditPaymentSettingsDialogOpen: true,
                      }))
                    }
                  />
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTable>

      <EditPaymentSettingsDialog
        cardAccountOpenInDialog={state.cardAccountOpenInDialog!}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isEditPaymentSettingsDialogOpen: false,
          }))
        }
        open={state.isEditPaymentSettingsDialogOpen}
      />
    </>
  );
};

export default PaymentSettingsSection;
