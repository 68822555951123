import {
  adminAllPaths,
  AdminAllPathsKeys,
  internalOrgPaths,
  InternalOrgPathsKeys,
  portalOrgPaths,
  PortalOrgPathsKeys,
} from 'components/App';
import { env } from 'env';

export type PathsKeys = AdminAllPathsKeys | InternalOrgPathsKeys;

export const getPath = (pathId: PathsKeys) => {
  if (env.REACT_APP_BUILD_TARGET === 'external')
    return adminAllPaths[pathId as AdminAllPathsKeys];

  if (env.REACT_APP_PARTNER_SCOPE === 'portal')
    return portalOrgPaths[pathId as PortalOrgPathsKeys];

  return internalOrgPaths[pathId as InternalOrgPathsKeys];
};
