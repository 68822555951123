import { useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  CreditCardIcon,
  FlagPennantIcon,
  Tooltip,
  Typography,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  NetworkErrorCode,
  PrivateExpenseStatus,
  Transaction,
  TxPrivateExpenseReimbursement,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import ReimbursePrivateExpenseDialog from './ReimbursePrivateExpenseDialog';

const getAlertSeverity = (
  privateExpenseStatus: PrivateExpenseStatus | null,
  lastReimbursementFailed: boolean
) => {
  switch (privateExpenseStatus) {
    case PrivateExpenseStatus.reimbursementOutstanding:
      return lastReimbursementFailed ? 'error' : 'warning';
    case PrivateExpenseStatus.reimbursedOffline:
      return 'success';
    default:
      return 'error';
  }
};

const getAlertDescription = (
  { privateExpenseStatus, reviewerFullName, reviewComment }: Transaction,
  lastReimbursementFailed: boolean
) => {
  if (privateExpenseStatus === PrivateExpenseStatus.reimbursedOffline) {
    return reviewComment;
  } else if (
    privateExpenseStatus === PrivateExpenseStatus.reimbursementOutstanding &&
    lastReimbursementFailed
  ) {
    return (
      <Trans
        i18nKey="transactionDetails.reviewComment.descriptions.REIMBURSEMENT_OUTSTANDING_WITH_ERROR"
        values={{
          name: reviewerFullName,
          comment: reviewComment,
        }}
        components={{ b: <b /> }}
      />
    );
  }

  return (
    <Trans
      i18nKey={`transactionDetails.reviewComment.descriptions.${privateExpenseStatus}`}
      values={{
        comment: reviewComment,
      }}
      components={{ b: <b /> }}
    />
  );
};

const getAlertReviewInfo = (
  { privateExpenseStatus, reviewerFullName, reviewedAt }: Transaction,
  lastReimbursementFailed: boolean,
  t: TFunction
) => {
  if (
    privateExpenseStatus === PrivateExpenseStatus.reimbursedOffline ||
    (privateExpenseStatus === PrivateExpenseStatus.reimbursementOutstanding &&
      !lastReimbursementFailed)
  ) {
    return t(`transactionDetails.reviewComment.info`, {
      name: reviewerFullName,
      date: moment(reviewedAt).format('DD.MM.YYYY'),
    });
  } else if (
    privateExpenseStatus === PrivateExpenseStatus.reimbursementOutstanding &&
    lastReimbursementFailed
  ) {
    return moment(reviewedAt).format('DD.MM.YYYY');
  }
};

interface Props {
  transaction: Transaction;
  onRefresh: () => void;
}

interface State {
  isDialogOpen: boolean;
  isLoading: boolean;
  txPrivateExpenseReimbursement: TxPrivateExpenseReimbursement | null;
}

const PrivateExpenseReviewComment = ({ transaction, onRefresh }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const { isAdminApp } = useCurrentApp();
  const {
    state: { member },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isDialogOpen: false,
    isLoading: false,
    txPrivateExpenseReimbursement: null,
  });
  const canInitiateReimbursement =
    transaction.memberId === member?.id &&
    transaction.privateExpenseStatus ===
      PrivateExpenseStatus.reimbursementOutstanding;

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const txPrivateExpenseReimbursement = await api.getTxPrivateExpenseReimbursement(
        transaction.transactionId
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        txPrivateExpenseReimbursement,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      if (
        getNetworkErrorCode(error) ===
        NetworkErrorCode.privateExpenseReimbursementNotFound
      ) {
        setState((prevState) => ({
          ...prevState,
          txPrivateExpenseReimbursement: null,
        }));
        return;
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (
      transaction.privateExpenseStatus ===
      PrivateExpenseStatus.reimbursementOutstanding
    )
      getData();
  }, [transaction.transactionId]);

  if (state.isLoading)
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Alert
        severity={getAlertSeverity(
          transaction.privateExpenseStatus,
          !!state.txPrivateExpenseReimbursement?.error
        )}
        icon={<FlagPennantIcon fontSize="inherit" />}
        sx={canInitiateReimbursement ? { borderRadius: 0 } : { my: 2, mx: 3 }}
      >
        <AlertTitle>
          {t(
            `transactionDetails.reviewComment.titles.${transaction.privateExpenseStatus}`
          )}
        </AlertTitle>

        <Typography variant="body2">
          {getAlertDescription(
            transaction,
            !!state.txPrivateExpenseReimbursement?.error
          )}
        </Typography>

        {transaction.privateExpenseStatus ===
          PrivateExpenseStatus.reimbursementOutstanding &&
          !!state.txPrivateExpenseReimbursement?.error && (
            <Box mt={4}>
              <AlertTitle>
                {t(
                  'transactionDetails.reviewComment.reimbursementAttemptFailed'
                )}
              </AlertTitle>
              <Typography variant="body2">
                {state.txPrivateExpenseReimbursement.error}
              </Typography>
            </Box>
          )}

        {canInitiateReimbursement && (
          <Box mt={2} textAlign="right">
            <Button
              color="primary"
              onClick={() =>
                setState((prevState) => ({ ...prevState, isDialogOpen: true }))
              }
            >
              <CreditCardIcon sx={{ mr: 1 }} />
              {state.txPrivateExpenseReimbursement?.clientSecret
                ? t('transactionDetails.reviewComment.completeReimbursement')
                : t('transactionDetails.reviewComment.initiateReimbursement')}
            </Button>
          </Box>
        )}

        {transaction.reviewedAt &&
          !canInitiateReimbursement &&
          (isAdminApp ||
            transaction.privateExpenseStatus ===
              PrivateExpenseStatus.reimbursedOffline) && (
            <Typography
              mt={0.5}
              variant="overline2"
              component="div"
              textAlign="right"
            >
              <Tooltip title={transaction.reviewerEmail}>
                <span>
                  {getAlertReviewInfo(
                    transaction,
                    !!state.txPrivateExpenseReimbursement?.error,
                    t
                  )}
                </span>
              </Tooltip>
            </Typography>
          )}
      </Alert>

      <ReimbursePrivateExpenseDialog
        open={state.isDialogOpen}
        transaction={transaction}
        txPrivateExpenseReimbursement={state.txPrivateExpenseReimbursement}
        onClose={() => {
          onRefresh();
          setState((prevState) => ({ ...prevState, isDialogOpen: false }));
        }}
      />
    </>
  );
};

export default PrivateExpenseReviewComment;
