import { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { CardIcon } from 'domains/card/components';
import { useAvailableCardConfigSettings } from 'domains/card/hooks';
import {
  Box,
  Link,
  LoaderWithOverlay,
  Paper,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CardConfigSetting,
  CardCountAndLimitByConfigMap,
  CardNewType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getPath } from 'services/utils';

const getTooltipKey = (cardConfigSetting: CardConfigSetting) => {
  const base = 'dashboardPage.activeCardsWidget.tooltips.';
  const customKey = `${base}customByConfig.${cardConfigSetting.cardConfigGroup}`;

  if (i18next.exists(customKey)) return customKey;

  if (cardConfigSetting.cardType === CardNewType.physical)
    return base + 'physical';

  if (cardConfigSetting.cardType === CardNewType.virtual) {
    return (
      base + (cardConfigSetting.maxUsage === 1 ? 'virtualSingleUse' : 'virtual')
    );
  }

  return null;
};

interface Props {
  refreshIndex: number;
}

const ActiveCards = ({ refreshIndex }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const availableCardConfigSettings = useAvailableCardConfigSettings();
  const [isLoading, setIsLoading] = useState(true);
  const [
    cardCountsAndLimits,
    setCardCountsAndLimits,
  ] = useState<CardCountAndLimitByConfigMap | null>(null);
  const cardsPath = generatePath(getPath('cards'), {
    orgId: organization!.id,
  });
  const totalCardCount = useMemo<null | number>(() => {
    if (!cardCountsAndLimits) return null;
    return availableCardConfigSettings.reduce(
      (acc, curr) =>
        acc + (cardCountsAndLimits[curr.cardConfigGroup]?.issued || 0),
      0
    );
  }, [availableCardConfigSettings, cardCountsAndLimits]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const data = await api.getCardCountsAndLimits({
        organizationId: organization!.id,
        totalIssued: true,
      });
      if (!mounted.current) return;
      setCardCountsAndLimits(data.countsAndLimits);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, [refreshIndex]);

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 180,
        p: 2,
      }}
    >
      <Typography variant="subtitle1">
        {t('dashboardPage.activeCardsWidget.title')}
      </Typography>
      <Box
        flexGrow={1}
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Link
          variant="h4"
          textAlign="center"
          underline="none"
          color="inherit"
          component={RouterLink}
          to={cardsPath}
        >
          {totalCardCount}
        </Link>

        {cardCountsAndLimits && (
          <Box display="flex" justifyContent="space-around" mt={2} mx={-2}>
            {availableCardConfigSettings.map((item) => {
              const { canBeIssued, cardConfigGroup, cardDesignIds } = item;
              const tooltipKey = getTooltipKey(item);

              if (!canBeIssued && !cardCountsAndLimits[cardConfigGroup]?.issued)
                return null;

              return (
                <Tooltip
                  key={cardConfigGroup}
                  title={
                    tooltipKey ? (
                      <Trans
                        i18nKey={tooltipKey}
                        values={{
                          count:
                            cardCountsAndLimits[cardConfigGroup]?.max ??
                            t('dashboardPage.activeCardsWidget.unlimited'),
                        }}
                        components={{ b: <b /> }}
                      />
                    ) : (
                      ''
                    )
                  }
                >
                  <div>
                    <CardIcon cardDesignId={cardDesignIds[0]} />
                    <Typography
                      variant="caption2"
                      component="div"
                      textAlign="center"
                      color="text.secondary"
                      mt={0.5}
                    >
                      {cardCountsAndLimits[cardConfigGroup]?.issued || 0}
                    </Typography>
                  </div>
                </Tooltip>
              );
            })}
          </Box>
        )}

        <LoaderWithOverlay loading={isLoading} size="small" />
      </Box>
    </Paper>
  );
};

export default ActiveCards;
