import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import FileDoubleIcon from 'assets/icons/interface/fileDouble.svg?react';
import { internalOrgPaths } from 'components/App';
import { IconButton } from 'components/Button';
import Tooltip from 'components/Tooltip';
import {
  CheckCircleIcon,
  InputAdornment,
  LoadingButton,
  TextField,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { HubspotCompany } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import {
  ErrorDescription,
  Label,
  NameDiffWrapper,
  NameValue,
  StepsList,
} from './style';

export const TEST_HUBSPOT_ID = '00000000';

enum HsConnectionErrorType {
  noHsCompanyFound = 'NO_HUBSPOT_COMPANY_FOUND',
  hsCompanyNameNotMatching = 'HUBSPOT_COMPANY_NAME_NOT_MATCHING',
  hsIdAlreadyExists = 'HUBSPOT_ID_ALREADY_EXISTS',
}

export interface OrganizationToHubspotLinkFieldProps {
  disabled?: boolean;
  initialHsIdOfExistingOrg?: string;
  onSuccess: (hubspotId: string) => void;
  orgId?: string;
  resetOnChange: () => void;
  validHsOrgNames: string[];
}

interface State {
  errorType: HsConnectionErrorType | null;
  hsCompany: HubspotCompany | null;
  hsId: string;
  isLoading: boolean;
}

const OrganizationToHubspotLinkField = ({
  disabled = false,
  initialHsIdOfExistingOrg = '',
  onSuccess,
  orgId,
  validHsOrgNames,
  resetOnChange,
}: OrganizationToHubspotLinkFieldProps) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState<State>({
    errorType: null,
    hsCompany: null,
    hsId: initialHsIdOfExistingOrg,
    isLoading: false,
  });

  const fetchHubspotCompany = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const hubspotCompany = await api.checkHubspotId(state.hsId);
      if (!mounted.current) return;

      if (!hubspotCompany) {
        return setState((prevState) => ({
          ...prevState,
          errorType: HsConnectionErrorType.noHsCompanyFound,
          isLoading: false,
        }));
      }

      if (
        (!orgId && hubspotCompany.alreadyLinkedOrganizationId) ||
        (hubspotCompany.alreadyLinkedOrganizationId &&
          orgId !== hubspotCompany.alreadyLinkedOrganizationId)
      ) {
        return setState((prevState) => ({
          ...prevState,
          errorType: HsConnectionErrorType.hsIdAlreadyExists,
          hsCompany: hubspotCompany,
          isLoading: false,
        }));
      }

      if (
        hubspotCompany.hubspotId !== TEST_HUBSPOT_ID &&
        !validHsOrgNames.includes(hubspotCompany.name)
      ) {
        return setState((prevState) => ({
          ...prevState,
          errorType: HsConnectionErrorType.hsCompanyNameNotMatching,
          hsCompany: hubspotCompany,
          isLoading: false,
        }));
      }

      setState((prevState) => ({
        ...prevState,
        errorType: null,
        hsCompany: hubspotCompany,
        isLoading: false,
        isValid: true,
      }));
      onSuccess(hubspotCompany.hubspotId);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (initialHsIdOfExistingOrg) {
      fetchHubspotCompany();
    }
  }, [initialHsIdOfExistingOrg]);

  useEffect(() => {
    if (!initialHsIdOfExistingOrg) {
      setState((prevState) => ({
        ...prevState,
        errorType: null,
        hsCompany: null,
        hsId: '',
      }));
    }
  }, [JSON.stringify(validHsOrgNames), initialHsIdOfExistingOrg]);

  return (
    <Box>
      <Box display="flex" alignItems="end">
        <Box flexGrow="1" mr={1} position="relative">
          <TextField
            disabled={disabled || state.isLoading}
            label={t('int.organizationToHubspotLinkField.hubspotCompanyId')}
            placeholder={t(
              'int.organizationToHubspotLinkField.hubspotCompanyId'
            )}
            name="hubspotCompanyId"
            value={state.hsId}
            onChange={({ target: { value } }) => {
              if (/^[0-9]*$/.test(value)) {
                setState((prevState) => ({
                  ...prevState,
                  errorType: null,
                  hsCompany: null,
                  hsId: value,
                }));
                if (resetOnChange) resetOnChange();
              }
            }}
            InputProps={{
              endAdornment: state.hsCompany && !state.errorType && (
                <InputAdornment position="end">
                  <CheckCircleIcon
                    color="success"
                    data-test-id="validation-success"
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <LoadingButton
          loading={state.isLoading}
          disabled={disabled || !state.hsId}
          onClick={fetchHubspotCompany}
          variant="text"
        >
          {t('int.organizationToHubspotLinkField.fetchButton')}
        </LoadingButton>
      </Box>

      {/*TODO: update the following code to use the new components*/}
      {state.errorType && (
        <ErrorDescription>
          {state.errorType === HsConnectionErrorType.noHsCompanyFound && (
            <span>
              <Trans
                i18nKey="int.organizationToHubspotLinkField.errorNotFound"
                components={{
                  b: <b />,
                }}
                values={{
                  value: state.hsId,
                }}
              />
            </span>
          )}

          {state.hsCompany &&
            state.errorType ===
              HsConnectionErrorType.hsCompanyNameNotMatching && (
              <>
                <span>
                  {t('int.organizationToHubspotLinkField.errorNotMatching')}
                </span>
                <NameDiffWrapper>
                  <Box flexGrow="1">
                    <Box display="flex">
                      <Label>
                        {t('int.organizationToHubspotLinkField.hubspotLabel')}
                      </Label>
                      <NameValue>{state.hsCompany.name}</NameValue>
                    </Box>
                    <Box display="flex">
                      <Label>
                        {t('int.organizationToHubspotLinkField.expectedLabel')}
                      </Label>
                      <Box display="flex" flexDirection="column">
                        {validHsOrgNames.map((name) => (
                          <NameValue key={name}>{name}</NameValue>
                        ))}
                      </Box>
                    </Box>
                  </Box>

                  <CopyToClipboard text={validHsOrgNames[0]!}>
                    <Tooltip
                      title={t(
                        'int.organizationToHubspotLinkField.copyToClipboardLabel'
                      )}
                    >
                      <IconButton $p="12px" $m="10px 0 0">
                        <FileDoubleIcon />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                </NameDiffWrapper>
                <span>{t('int.organizationToHubspotLinkField.howToFix')}</span>
                <StepsList>
                  <li>
                    {t(
                      'int.organizationToHubspotLinkField.verifyCorrectHubspotidStep'
                    )}
                  </li>
                  <li>
                    <span>
                      {t('int.organizationToHubspotLinkField.notMatchingStep')}
                    </span>
                    <br />
                    <a
                      href={`https://app.hubspot.com/contacts/19883247/company/${state.hsId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t(
                        'int.organizationToHubspotLinkField.hubspotCompanyLink'
                      )}
                    </a>
                  </li>
                  <li>
                    {t('int.organizationToHubspotLinkField.fetchAgainStep')}
                  </li>
                </StepsList>
              </>
            )}

          {state.hsCompany &&
            state.errorType === HsConnectionErrorType.hsIdAlreadyExists && (
              <>
                <span>
                  <Trans
                    i18nKey="int.organizationToHubspotLinkField.errorAlreadyLinked"
                    components={{
                      b: <b />,
                    }}
                    values={{
                      value: state.hsCompany.alreadyLinkedOrganizationName!,
                    }}
                  />
                </span>
                <br />
                <span>{t('int.organizationToHubspotLinkField.howToFix')}</span>
                <StepsList>
                  <li>
                    <span>
                      {t(
                        'int.organizationToHubspotLinkField.alreadyLinkedStep'
                      )}
                    </span>
                    <br />
                    <a
                      href={generatePath(
                        internalOrgPaths.settingsOrganization,
                        {
                          orgId: state.hsCompany.alreadyLinkedOrganizationId!,
                        }
                      )}
                    >
                      {state.hsCompany.alreadyLinkedOrganizationName!}
                    </a>
                  </li>
                  <li>
                    {t('int.organizationToHubspotLinkField.fetchAgainStep')}
                  </li>
                </StepsList>
              </>
            )}
        </ErrorDescription>
      )}
    </Box>
  );
};

export default OrganizationToHubspotLinkField;
