import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { parseAndReformatDateStr } from 'domains/creditAndCompliance/utils/parseAndReformatDateStr';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getMoneyObject } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues {
  annualRevenue: number | null;
  annualRevenueSource: string | null;
  balanceSheet: number | null;
  balanceSheetSource: string | null;
  firmographicsDate: string | null;
  numberOfEmployees: number | null;
  numberOfEmployeesSource: string | null;
}

const EditFirmographicsSizeDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, organizationsStaticData },
  } = useGlobalState();
  const {
    actions: { refetchCompleteContext },
    state: { creditProfile },
  } = useCreditAndComplianceContext();
  const firmographics = creditProfile!.firmographics;

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      annualRevenue:
        typeof firmographics.annualRevenue.value?.value === 'number'
          ? firmographics.annualRevenue.value.value / 100
          : null,
      annualRevenueSource: firmographics.annualRevenueSource.value ?? '',
      balanceSheet:
        typeof firmographics.balanceSheet.value?.value === 'number'
          ? firmographics.balanceSheet.value.value / 100
          : null,
      balanceSheetSource: firmographics.balanceSheetSource.value ?? '',
      firmographicsDate:
        typeof firmographics.firmographicsDate.value === 'string'
          ? parseAndReformatDateStr(firmographics.firmographicsDate.value)
          : null,
      numberOfEmployees: firmographics.numberOfEmployees.value ?? null,
      numberOfEmployeesSource:
        firmographics.numberOfEmployeesSource.value ?? '',
    },
    onSubmit: async (values) => {
      try {
        const payload = {
          ...(Object.entries(values).reduce(
            (cum, [key, value]) => ({
              ...cum,
              [key]: value !== '' ? value : null,
            }),
            {}
          ) as FormValues),
          annualRevenue:
            typeof values.annualRevenue === 'number'
              ? getMoneyObject(values.annualRevenue * 100)
              : null,
          balanceSheet:
            typeof values.balanceSheet === 'number'
              ? getMoneyObject(values.balanceSheet * 100)
              : null,
        };

        await api.updateFirmographicsSize(organization!.id, payload);
        await refetchCompleteContext();

        if (!mounted) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const getCustomFieldProps = <T extends keyof FormValues>(name: T) => {
    return {
      ...(formik.errors[name] && {
        error: true,
        helperText: formik.errors[name],
      }),
      name,
      value: formik.values[name],
    };
  };
  const onChange = <T extends keyof FormValues>(
    name: T,
    value: FormValues[T]
  ) => {
    if (formik.errors[name]) {
      formik.setFieldError(name, undefined);
    }
    formik.setFieldValue(name, value);
  };

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('int.editFirmographicsSizeDialog.title')}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('firmographicsDate')}
              onChange={(event) =>
                onChange('firmographicsDate', event.target.value)
              }
              label={t('int.editFirmographicsSizeDialog.firmographicsDate')}
              type="date"
            />
          </Grid>
          <Grid item xs={6} />

          <Grid item xs={6}>
            <MoneyField
              {...getCustomFieldProps('annualRevenue')}
              onValueChange={({ floatValue = null }) => {
                formik.setFieldValue('annualRevenue', floatValue);
              }}
              label={t('int.editFirmographicsSizeDialog.annualRevenue')}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.annualRevenueSource}>
              <InputLabel>
                {t('int.editFirmographicsSizeDialog.source')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('annualRevenueSource'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('annualRevenueSource', event.target.value)
                }
              >
                {organizationsStaticData!.firmographicsSources.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.annualRevenueSource}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <MoneyField
              {...getCustomFieldProps('balanceSheet')}
              onValueChange={({ floatValue = null }) => {
                formik.setFieldValue('balanceSheet', floatValue);
              }}
              label={t('int.editFirmographicsSizeDialog.balanceSheetTotal')}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.balanceSheetSource}>
              <InputLabel>
                {t('int.editFirmographicsSizeDialog.source')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('balanceSheetSource'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('balanceSheetSource', event.target.value)
                }
              >
                {organizationsStaticData!.firmographicsSources.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.balanceSheetSource}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('numberOfEmployees')}
              onChange={({ target: { value } }) => {
                if (/^[0-9]*$/.test(value)) {
                  onChange('numberOfEmployees', value.length ? +value : null);
                }
              }}
              label={t('int.editFirmographicsSizeDialog.numberOfEmployees')}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={!!formik.errors.numberOfEmployeesSource}
            >
              <InputLabel>
                {t('int.editFirmographicsSizeDialog.source')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('numberOfEmployeesSource'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('numberOfEmployeesSource', event.target.value)
                }
              >
                {organizationsStaticData!.firmographicsSources.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.numberOfEmployeesSource}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={formik.isSubmitting} onClick={formik.submitForm}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditFirmographicsSizeDialog);
