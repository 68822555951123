import RepresentativeSection from './RepresentativeSection';

const IndividualsSubPage = () => {
  return (
    <>
      <RepresentativeSection />
    </>
  );
};

export default IndividualsSubPage;
