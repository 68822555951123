import { Trans, useTranslation } from 'react-i18next';
import AddressDetails from 'components/AddressDetails';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from 'elements';
import {
  BankingServicePartner,
  DirectDebitInfo,
  MandateStatus,
} from 'services/constants';

const CREDITOR_DETAILS = {
  [BankingServicePartner.bankingCircle]: {
    address:
      'Pliant OY, Kaivokatu 12 A c/o Spacent, 8th floor, 00100 Helsinki, Finland',
    identifier: 'FI37ZZZ32669139',
    name: 'Pliant OY',
  },
  [BankingServicePartner.varengold]: {
    address: 'Varengold Bank AG, Grosse Elbstrasse 39, 22767 Hamburg',
    identifier: 'DE36IFI00001339182',
    name: 'Varengold Bank AG',
  },
};

interface Props {
  directDebitInfo: DirectDebitInfo;
}

const SepaDirectDebit = ({ directDebitInfo }: Props) => {
  const { t } = useTranslation();
  const {
    state: { member, organization },
  } = useGlobalState();

  const billingAddress = organization!.billingAddress;
  const legalName = organization!.name;
  const { bankingServicePartner } = directDebitInfo;

  return (
    <>
      <Box mb={3}>
        <Typography variant="body2">
          {t('sepaDirectDebit.description')}
        </Typography>
      </Box>
      <TableContainer sx={{ mb: 3 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: '50%' }}>
                {t('sepaDirectDebit.creditor')}
              </TableCell>
              <TableCell>
                {CREDITOR_DETAILS[bankingServicePartner].address}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('sepaDirectDebit.creditorIdentifier')}</TableCell>
              <TableCell>
                {CREDITOR_DETAILS[bankingServicePartner].identifier}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0 }}>
                {t('sepaDirectDebit.debtor')}
              </TableCell>
              <TableCell sx={{ border: 0 }}>{legalName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <AddressDetails address={billingAddress} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0 }}>
                {t('sepaDirectDebit.debtorBank')}
              </TableCell>
              <TableCell sx={{ border: 0 }}>
                {directDebitInfo.bankName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{directDebitInfo.bankBic}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('sepaDirectDebit.debtorBillingAccountIban')}
              </TableCell>
              <TableCell>{directDebitInfo.accountNumber.number}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('sepaDirectDebit.uniqueMandateReference')}
              </TableCell>
              <TableCell>{directDebitInfo.mandateReference}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('sepaDirectDebit.mandateType')}</TableCell>
              <TableCell>{t('sepaDirectDebit.mandateTypeValue')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="body2" mb={3}>
        {directDebitInfo.mandateStatus === MandateStatus.prepared && (
          <Trans
            i18nKey="sepaDirectDebit.paragraphASubjectOnePerson"
            values={{
              memberName: `${member.firstName} ${member.lastName}`,
            }}
            components={{ bold: <b /> }}
          />
        )}
        {directDebitInfo.mandateStatus === MandateStatus.confirmedPartially && (
          <Trans
            i18nKey="sepaDirectDebit.paragraphASubjectOnePerson"
            values={{
              memberName: `${member.firstName} ${member.lastName}`,
            }}
            components={{ bold: <b /> }}
          />
        )}
        {directDebitInfo.mandateStatus === MandateStatus.confirmed &&
          (directDebitInfo.activationConfirmations.length < 2 ? (
            <Trans
              i18nKey="sepaDirectDebit.paragraphASubjectOnePerson"
              values={{
                memberName: directDebitInfo.activationConfirmations[0].name,
              }}
              components={{ bold: <b /> }}
            />
          ) : (
            <Trans
              i18nKey="sepaDirectDebit.paragraphASubjectTwoPerson"
              values={{
                memberOneName: directDebitInfo.activationConfirmations[0].name,
                memberTwoName: directDebitInfo.activationConfirmations[1].name,
              }}
              components={{ bold: <b /> }}
            />
          ))}{' '}
        <Trans
          i18nKey="sepaDirectDebit.paragraphA_v2"
          values={{
            organizationName: legalName,
            debtorBankName: directDebitInfo.bankName,
            bankingServicePartner: CREDITOR_DETAILS[bankingServicePartner].name,
          }}
          components={{ bold: <b /> }}
        />
      </Typography>
      <Typography variant="body2" mb={3}>
        {t('sepaDirectDebit.paragraphB')}
      </Typography>
      <Typography variant="body2">
        {t('sepaDirectDebit.paragraphC', {
          count: Math.max(directDebitInfo.activationConfirmations.length, 1),
        })}
      </Typography>
    </>
  );
};

export default SepaDirectDebit;
