import { Box, LinearProgress } from 'elements';
import { Money } from 'services/constants';

interface Props {
  balance: Money | null;
  availableLimit: Money | null;
  limit: Money | null;
  expectedMonthlySpend?: Money;
}

const CustomBar = ({
  balance,
  availableLimit,
  limit,
  expectedMonthlySpend,
}: Props) => {
  if (!balance || !availableLimit || !limit) {
    return null;
  }

  // prefund org with expected monthly spend
  if (expectedMonthlySpend) {
    const percent = (balance.value / expectedMonthlySpend.value) * 100;
    return (
      <LinearProgress
        value={percent}
        color={percent > 20 ? 'success' : 'warning'}
      />
    );
  }

  // credit org with prefund balance
  if (balance.value > 0) {
    const percent = (100 * balance.value) / availableLimit.value;
    return (
      <Box display="flex" height={8} borderRadius="4px" overflow="hidden">
        <Box width={`calc(${100 - percent}% - 1px)`} bgcolor="info.main" />
        <Box width="2px" bgcolor="common.white" />
        <Box width={`calc(${percent}% - 1px)`} bgcolor="success.main" />
      </Box>
    );
  }

  const getPercentValue = () => {
    if (limit.value === 0) return 0;

    const limitPercentage = (availableLimit.value / limit.value) * 100;
    return limitPercentage > 100 ? 100 : limitPercentage;
  };

  // credit org
  const percent = getPercentValue();
  return (
    <LinearProgress value={percent} color={percent > 20 ? 'info' : 'warning'} />
  );
};

export default CustomBar;
