import { Trans } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AlertWarningTriangleIcon from 'assets/icons/interface/alertWarningTriangle.svg?react';
import Tooltip from 'components/Tooltip';
import { useGlobalState } from 'context/GlobalState';
import { getPath } from 'services/utils';

const StyledAlertIcon = styled(AlertWarningTriangleIcon)`
  vertical-align: middle;
  pointer-events: auto;
  margin-left: 10px;
  width: 16px;
  height: 16px;
  position: relative;
  top: -1px;
  color: ${({ theme }) => theme.colors.red10};
`;

/** @deprecated will be deleted along with statements page */
const CustomCsvWarningTooltip = () => {
  const {
    state: { organization, generalInfo },
  } = useGlobalState();

  return (
    <Tooltip
      interactive
      title={
        <Trans
          i18nKey="customCsvWarningTooltip.title"
          components={{
            linkTo: (
              <Link
                to={generatePath(getPath('settingsLocoia'), {
                  orgId: organization!.id,
                })}
              />
            ),
            sup: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a href={'mailto:' + generalInfo.supportEmail} rel="noreferrer" />
            ),
          }}
        />
      }
    >
      <StyledAlertIcon />
    </Tooltip>
  );
};

export default CustomCsvWarningTooltip;
