import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import CompleteCreditAssessmentSection from './CompleteCreditAssessmentSection';
import CreditAssessmentSummarySection from './CreditAssessmentSummarySection';
import LimitSettingsSection from './LimitSettingsSection';
import PaymentSettingsSection from './PaymentSettingsSection';
import UnderwritingCaseSection from './UnderwritingCaseSection';

const AssessmentSubPage = () => {
  const {
    state: { isNonCustomer },
  } = useCreditAndComplianceContext();
  return (
    <>
      <CreditAssessmentSummarySection />
      <UnderwritingCaseSection />
      {!isNonCustomer && <LimitSettingsSection />}
      {!isNonCustomer && <PaymentSettingsSection />}
      {!isNonCustomer && <CompleteCreditAssessmentSection />}
    </>
  );
};

export default AssessmentSubPage;
