import { Drawer, DrawerProps, styled } from '@mui/material';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Box, BoxProps, IconButton, XIcon } from 'elements';
import { logError } from 'services/monitoring';

export interface DetailsDrawerProps
  extends Pick<DrawerProps, 'children' | 'open' | 'SlideProps' | 'PaperProps'> {
  actionsComponent?: React.ReactNode;
}

const DetailsDrawer = ({
  children,
  PaperProps,
  actionsComponent,
  ...props
}: DetailsDrawerProps) => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  return (
    <Drawer
      {...props}
      elevation={8}
      sx={{
        left: 'auto',
        zIndex: 1300,
      }}
      PaperProps={{
        ...PaperProps,
        sx: [
          {
            width: '100%',
            maxWidth: 600,
          },
          ...(PaperProps?.sx
            ? Array.isArray(PaperProps.sx)
              ? PaperProps.sx
              : [PaperProps.sx]
            : []),
        ],
      }}
      anchor="right"
      hideBackdrop
      // allow inputs to be focused correctly
      disableEnforceFocus
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="right"
        pt={3}
        px={3}
        gap={0.5}
      >
        {actionsComponent}
        <IconButton
          aria-label="details-close"
          onClick={() => {
            // shouldn't happen, but seems there are such cases.
            if (!match) {
              logError('useRouteMatch returned null', { location });
              return;
            }
            history.push({
              pathname: match.url.substring(0, match.url.lastIndexOf('/')),
              search: location?.search,
            });
          }}
        >
          <XIcon />
        </IconButton>
      </Box>

      <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
        {children}
      </Box>
    </Drawer>
  );
};

export default DetailsDrawer;

export const DetailsDrawerHeader = ({ children, ...props }: BoxProps) => (
  <Box
    pt={2}
    px={3}
    pb={4}
    borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
    {...props}
  >
    {children}
  </Box>
);

export const DetailsDrawerContent = styled(Box)({
  flex: 1,
  overflow: 'auto',
});
