import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import { Link, Typography } from 'elements';
import {
  Cashback,
  OrganizationAccountType,
  SupportedCountry,
} from 'services/constants';

interface Props {
  cashback: Cashback;
}

const Footnote = ({ cashback }: Props) => {
  const { t } = useTranslation();
  const {
    state: { organization, defaultCardAccount },
  } = useGlobalState();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Typography variant="body2" color="text.secondary" mt={3} mb={8}>
      {isExpanded ? (
        <>
          <Trans
            i18nKey={
              organization!.billingAddress?.country === SupportedCountry.es
                ? 'cashbackSubpage.footnote.shortSpanish'
                : 'cashbackSubpage.footnote.short'
            }
            components={{
              'cashback-rate': (
                <FormatPercent value={cashback.generalRate * 100} />
              ),
            }}
          />
          {defaultCardAccount!.accountType.value ===
            OrganizationAccountType.credit &&
            t('cashbackSubpage.footnote.general')}
          <Trans
            i18nKey="cashbackSubpage.footnote.foreignCurrencies"
            components={{
              'fx-rate': <FormatPercent value={cashback.fxRate * 100} />,
            }}
          />
          <Trans
            i18nKey="cashbackSubpage.footnote.mccLow"
            components={{
              'mcc-low-rate': (
                <FormatPercent value={cashback.mccLowRate * 100} />
              ),
            }}
          />
          <Trans
            i18nKey="cashbackSubpage.footnote.mccLowTwo"
            components={{
              'mcc-low-two-rate': (
                <FormatPercent value={cashback.mccLowTwoRate * 100} />
              ),
            }}
          />
          {t(
            organization!.billingAddress?.country === SupportedCountry.es
              ? 'cashbackSubpage.footnote.noCashbackSpanish'
              : 'cashbackSubpage.footnote.noCashback'
          )}
          <Trans
            i18nKey="cashbackSubpage.footnote.threshold"
            components={{
              threshold: (
                <FormatMoney value={cashback.cashbackMonthlyThreshold} />
              ),
            }}
          />
          {' ' + t('cashbackSubpage.footnote.reversed')}
        </>
      ) : (
        <Trans
          i18nKey="cashbackSubpage.footnote.short"
          components={{
            'cashback-rate': (
              <FormatPercent value={cashback.generalRate * 100} />
            ),
          }}
        />
      )}{' '}
      <Link
        component="button"
        onClick={() => setIsExpanded((prevState) => !prevState)}
      >
        {isExpanded
          ? t('cashbackSubpage.viewLess')
          : t('cashbackSubpage.viewMore')}
      </Link>
    </Typography>
  );
};

export default Footnote;
