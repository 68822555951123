import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import {
  CreateEditTeamBudgetDialog,
  CreateEditTeamDialog,
  EditTeamPermissionsDialog,
  UpdateTeamMembersDialog,
} from 'domains/member/dialogs';
import {
  GearSixIcon,
  HandCoinsIcon,
  IconButton,
  ListItemIcon,
  MenuContainer,
  MenuItem,
  PencilSimpleIcon,
  UserGearIcon,
  UsersThreeIcon,
  XCircleIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Team } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  teamToEdit: Team | null;
  teamToUpdateMembers: Team | null;
  teamToEditPermissions: Team | null;
  teamToUpdateBudget: Team | null;
  teamToDeactivate: Team | null;
  isTeamDeactivating: boolean;
  teamToDelete: Team | null;
  isTeamDeleting: boolean;
}

interface Props {
  team: Team;
  onUpdate: (team: Team) => void;
  onRefetch: () => void;
}

const TeamMenu = ({ team, onUpdate, onRefetch }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    dispatch,
    state: { organization, featureModules },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    teamToEdit: null,
    teamToUpdateMembers: null,
    teamToEditPermissions: null,
    teamToUpdateBudget: null,
    teamToDeactivate: null,
    isTeamDeactivating: false,
    teamToDelete: null,
    isTeamDeleting: false,
  });

  const isEditPermissionsVisible = canUser('team-permissions:change', team);
  const isEditBudgetVisible =
    featureModules.TEAM_BUDGETS && canUser('team-budget:change');
  const isMenuVisible =
    canUser('team-details:change', team) ||
    canUser('team-members:change', team) ||
    isEditPermissionsVisible ||
    isEditBudgetVisible ||
    canUser('team:deactivate', team) ||
    canUser('team:delete', team);

  if (!isMenuVisible) return null;

  const deactivateTeam = async () => {
    try {
      setState((prevState) => ({ ...prevState, isTeamDeactivating: true }));
      const data = await api.deactivateTeam(state.teamToDeactivate!.id);
      dispatch({ type: 'UPDATE_TEAM', payload: data });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        teamToDeactivate: null,
        isTeamDeactivating: false,
      }));
      onUpdate(data);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isTeamDeactivating: false }));
      logError(error);
    }
  };

  const requestTeamDeletion = async (team: Team) => {
    try {
      if (state.isTeamDeleting) return;
      setState((prevState) => ({ ...prevState, isTeamDeleting: true }));
      const { assignments } = await api.getTeamTransactionAssignments(
        organization!.id,
        team.id
      );
      if (!mounted.current) return;
      if (assignments[0]?.hasAssignedTransactions) {
        setState((prevState) => ({ ...prevState, isTeamDeleting: false }));
        return enqueueSnackbar(t('teamsPage.teamCannotBeDeletedError'), {
          variant: 'error',
        });
      }
      setState((prevState) => ({
        ...prevState,
        teamToDelete: team,
        isTeamDeleting: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isTeamDeleting: false }));
      logError(error);
    }
  };

  const deleteTeam = async () => {
    try {
      setState((prevState) => ({ ...prevState, isTeamDeleting: true }));
      await api.deleteTeam(state.teamToDelete!.id);
      dispatch({ type: 'DELETE_TEAM', payload: state.teamToDelete! });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        teamToDelete: null,
        isTeamDeleting: false,
      }));
      onRefetch();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isTeamDeleting: false }));
      logError(error);
    }
  };

  return (
    <>
      <MenuContainer
        button={
          <IconButton sx={{ ml: 1, my: 0.5 }}>
            <GearSixIcon />
          </IconButton>
        }
      >
        {canUser('team-details:change', team) && (
          <MenuItem
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                teamToEdit: team,
              }));
            }}
          >
            <ListItemIcon>
              <PencilSimpleIcon />
            </ListItemIcon>
            {t('teamsPage.editTeamDetails')}
          </MenuItem>
        )}
        {canUser('team-members:change', team) && (
          <MenuItem
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                teamToUpdateMembers: team,
              }));
            }}
          >
            <ListItemIcon>
              <UsersThreeIcon />
            </ListItemIcon>
            {t('teamsPage.editTeamMembers')}
          </MenuItem>
        )}
        {isEditPermissionsVisible && (
          <MenuItem
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                teamToEditPermissions: team,
              }));
            }}
          >
            <ListItemIcon>
              <UserGearIcon />
            </ListItemIcon>
            {t('teamsPage.editPermissions')}
          </MenuItem>
        )}
        {isEditBudgetVisible && (
          <MenuItem
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                teamToUpdateBudget: team,
              }));
            }}
          >
            <ListItemIcon>
              <HandCoinsIcon />
            </ListItemIcon>
            {t('teamsPage.editTeamBudget')}
          </MenuItem>
        )}
        {canUser('team:deactivate', team) && (
          <MenuItem
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                teamToDeactivate: team,
              }))
            }
          >
            <ListItemIcon>
              <XCircleIcon />
            </ListItemIcon>
            {t('teamsPage.deactivateTeam')}
          </MenuItem>
        )}
        {canUser('team:delete', team) && (
          <MenuItem onClick={() => requestTeamDeletion(team)}>
            <ListItemIcon>
              <XCircleIcon />
            </ListItemIcon>
            {t('teamsPage.deleteTeam')}
          </MenuItem>
        )}
      </MenuContainer>

      <CreateEditTeamDialog
        team={state.teamToEdit}
        open={!!state.teamToEdit}
        onSuccess={onRefetch}
        onClose={() =>
          setState((prevState) => ({ ...prevState, teamToEdit: null }))
        }
      />
      <UpdateTeamMembersDialog
        team={state.teamToUpdateMembers}
        isEditing={true}
        open={!!state.teamToUpdateMembers}
        onSuccess={(team) => {
          setState((prevState) => ({
            ...prevState,
            teamToUpdateMembers: null,
          }));
          onUpdate(team);
        }}
        onClose={() =>
          setState((prevState) => ({ ...prevState, teamToUpdateMembers: null }))
        }
      />
      <EditTeamPermissionsDialog
        team={state.teamToEditPermissions}
        isEditing={true}
        open={!!state.teamToEditPermissions}
        onSuccess={(team) => {
          setState((prevState) => ({
            ...prevState,
            teamToEditPermissions: null,
          }));
          onUpdate(team);
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            teamToEditPermissions: null,
          }))
        }
      />
      <CreateEditTeamBudgetDialog
        open={!!state.teamToUpdateBudget}
        team={state.teamToUpdateBudget}
        onSuccess={onUpdate}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            teamToUpdateBudget: null,
          }))
        }
        isEditing={true}
      />
      <ConfirmDialog
        open={!!state.teamToDeactivate}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            teamToDeactivate: null,
          }));
        }}
        onSuccess={deactivateTeam}
        loading={state.isTeamDeactivating}
        title={t('teamsPage.deactivateTeamConfirmationTitle')}
        description={t('teamsPage.deactivateTeamConfirmationDescription')}
      />
      <ConfirmDialog
        open={!!state.teamToDelete}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            teamToDelete: null,
          }));
        }}
        onSuccess={deleteTeam}
        loading={state.isTeamDeleting}
        title={t('teamsPage.deleteTeamConfirmationTitle')}
        description={
          <Trans
            i18nKey="teamsPage.deleteTeamConfirmationDescription"
            values={state.teamToDelete || { name: '' }}
          />
        }
      />
    </>
  );
};

export default TeamMenu;
