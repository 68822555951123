import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { internalRootPaths } from 'components/App';
import {
  ArrowsLeftRightIcon,
  Box,
  BuildingsIcon,
  ListItemIcon,
  ListItemText,
  UsersIcon,
} from 'elements';
import { useCanUser } from 'services/rbac';
import NotificationsMenu from './NotificationsMenu';
import PlatformAdminMenu from './PlatformAdminMenu';
import ReceiptInboxButton from './ReceiptInboxButton';
import UserMenu from './UserMenu';
import { MenuSection, StyledListItemButton, StyledMainHeader } from './style';
import withLightNavigationModeTheme from './withLightNavigationModeTheme';

const InternalAdminMainHeader = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();

  return (
    <StyledMainHeader>
      <Box display="flex" alignItems="center" flexGrow={1}>
        {canUser('organizations-page:visit') && (
          <StyledListItemButton
            component={NavLink}
            to={internalRootPaths.organizations}
            exact
          >
            <ListItemIcon>
              <BuildingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('int.headerNav.organizations')} />
          </StyledListItemButton>
        )}
        {canUser('global-members-page:visit') && (
          <StyledListItemButton
            component={NavLink}
            to={internalRootPaths.globalMembers}
          >
            <ListItemIcon>
              <UsersIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('int.headerNav.members')} />
          </StyledListItemButton>
        )}
        {canUser('global-transactions-page:visit') && (
          <StyledListItemButton
            component={NavLink}
            to={internalRootPaths.globalTransactions}
          >
            <ListItemIcon>
              <ArrowsLeftRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('int.headerNav.transactions')} />
          </StyledListItemButton>
        )}
        <PlatformAdminMenu />
      </Box>
      <MenuSection>
        <ReceiptInboxButton />
        <NotificationsMenu />
        <UserMenu />
      </MenuSection>
    </StyledMainHeader>
  );
};

export default withLightNavigationModeTheme(InternalAdminMainHeader);
