import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Alert,
  AlertTitle,
  CheckCircleIcon,
  FlagPennantIcon,
  Tooltip,
  Typography,
} from 'elements';
import {
  PrivateExpenseStatus,
  ReviewFlagReason,
  Transaction,
  TransactionReviewStatus,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import PrivateExpenseReviewComment from './PrivateExpenseReviewComment';

const ReimbursementPendingOrDoneComment = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const { t } = useTranslation();
  return (
    <Alert
      severity={
        transaction.privateExpenseStatus ===
        PrivateExpenseStatus.reimbursementPending
          ? 'info'
          : 'success'
      }
      icon={<FlagPennantIcon fontSize="inherit" />}
      sx={{ my: 2, mx: 3 }}
    >
      <AlertTitle>
        {t(
          `transactionDetails.reviewComment.titles.${transaction.privateExpenseStatus}`
        )}
      </AlertTitle>
      <Typography variant="body2">
        {t(
          `transactionDetails.reviewComment.descriptions.${transaction.privateExpenseStatus}`
        )}
      </Typography>
      {transaction.privateExpenseStatus ===
        PrivateExpenseStatus.reimbursementPending &&
        transaction.reviewedAt && (
          <Typography
            mt={0.5}
            variant="overline2"
            component="div"
            textAlign="right"
          >
            {moment(transaction.reviewedAt).format('DD.MM.YYYY')}
          </Typography>
        )}
    </Alert>
  );
};

interface Props {
  transaction: Transaction;
  onRefresh: () => void;
}

const TransactionReviewComment = ({ transaction, onRefresh }: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();
  const { companyReimbursementEnabled } = useFlags();

  if (
    transaction.reviewFlagReason === ReviewFlagReason.privateExpense &&
    transaction.privateExpenseStatus &&
    transaction.privateExpenseStatus !==
      PrivateExpenseStatus.reimbursementNotSupported
  ) {
    if (
      [
        PrivateExpenseStatus.reimbursementPending,
        PrivateExpenseStatus.reimbursed,
      ].includes(transaction.privateExpenseStatus)
    ) {
      return <ReimbursementPendingOrDoneComment transaction={transaction} />;
    }

    if (companyReimbursementEnabled && featureModules.COMPANY_REIMBURSEMENT) {
      return (
        <PrivateExpenseReviewComment
          transaction={transaction}
          onRefresh={onRefresh}
        />
      );
    }
  }

  if (transaction.reviewStatus === TransactionReviewStatus.flagged) {
    return (
      <Alert
        severity="error"
        icon={<FlagPennantIcon fontSize="inherit" />}
        sx={{ my: 2, mx: 3 }}
      >
        <AlertTitle>
          {transaction.reviewFlagReason
            ? t(`transactionDetails.reviewComment.flaggedAs`, {
                reviewFlagReason: t(
                  `reviewFlagReasons.${transaction.reviewFlagReason}`
                ),
              })
            : t(`transactionReviewStatus.${transaction.reviewStatus}`)}
        </AlertTitle>
        <Typography variant="body2">{transaction.reviewComment}</Typography>
        {transaction.reviewedAt && (
          <Typography
            mt={0.5}
            variant="overline2"
            component="div"
            textAlign="right"
          >
            <Tooltip title={transaction.reviewerEmail}>
              <span>
                {t(`transactionDetails.reviewComment.info`, {
                  name: transaction.reviewerFullName,
                  date: moment(transaction.reviewedAt).format('DD.MM.YYYY'),
                })}
              </span>
            </Tooltip>
          </Typography>
        )}
      </Alert>
    );
  }

  if (transaction.reviewStatus === TransactionReviewStatus.resolved)
    return (
      <Alert
        severity="success"
        icon={<CheckCircleIcon fontSize="inherit" />}
        sx={{ my: 2, mx: 3 }}
      >
        <AlertTitle>
          {t(`transactionDetails.reviewComment.markedAsResolved`, {
            reviewFlagReason: t(
              `reviewFlagReasons.${transaction.reviewFlagReason}`
            ),
          })}
        </AlertTitle>
        <Typography variant="body2">{transaction.reviewComment}</Typography>
        {transaction.reviewedAt && (
          <Typography
            mt={0.5}
            variant="overline2"
            component="div"
            textAlign="right"
          >
            <Tooltip title={transaction.reviewerEmail}>
              <span>
                {t(`transactionDetails.reviewComment.info`, {
                  name: transaction.reviewerFullName,
                  date: moment(transaction.reviewedAt).format('DD.MM.YYYY'),
                })}
              </span>
            </Tooltip>
          </Typography>
        )}
      </Alert>
    );

  return null;
};

export default TransactionReviewComment;
