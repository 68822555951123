import Tooltip from 'components/Tooltip';
import { useGlobalState } from 'context/GlobalState';
import { OrganizationStatusBadge } from 'domains/organization/components';
import { isPartnerBasedSource } from 'domains/partner/utils';
import { Box, Typography } from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import { useCanUser } from 'services/rbac';
import HubspotCompanyLink from './HubspotCompanyLink';
import PartnerIndicator from './PartnerIndicator';

const OrganizationName = () => {
  const {
    state: { organization },
  } = useGlobalState();

  return (
    <Tooltip title={organization!.name} enterDelay={666}>
      <Typography
        variant="subtitle2"
        noWrap
        sx={(theme) => ({
          color: theme.variables.navigation[theme.navigationMode].text.primary,
        })}
      >
        {organization!.name}
      </Typography>
    </Tooltip>
  );
};

const OrganizationSection = () => {
  const { isInternalApp, isPortalAppMirrorView } = useCurrentApp();
  const canUser = useCanUser();
  const {
    state: { organization, partnerOrgAuthDetails },
  } = useGlobalState();

  if (!organization || (!isInternalApp && !isPortalAppMirrorView)) return null;

  if (isPortalAppMirrorView)
    return (
      <Box py={1} px={2} display="flex">
        <OrganizationName />
      </Box>
    );

  const isPartnerVisible =
    canUser('partners:view') && isPartnerBasedSource(organization.partnerId);

  return (
    <Box py={1}>
      <Box
        py={1}
        px={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <OrganizationName />
        <HubspotCompanyLink />
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          mx: 1.5,
          '& > div': {
            flexShrink: 0,
            maxWidth: `calc(100% - ${theme.spacing(1)}px)`,
            m: 0.5,
          },
        })}
      >
        <OrganizationStatusBadge status={organization.status} />
        {isPartnerVisible && (
          <PartnerIndicator
            name={organization.partnerName}
            status={partnerOrgAuthDetails?.status}
          />
        )}
      </Box>
    </Box>
  );
};

export default OrganizationSection;
