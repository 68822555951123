import { useTranslation } from 'react-i18next';
import { TransactionAvatar } from 'components/Avatar';
import { useGlobalState } from 'context/GlobalState';
import { Box, ChatCircleTextIcon, ReceiptIcon, Typography } from 'elements';
import {
  AccountEntryTransactionInfo,
  ExportFlowTransaction,
  ReviewFlagReason,
  Transaction,
} from 'services/constants';
import TransactionReviewStatusBadge from './TransactionReviewStatusBadge';

interface Props {
  transaction:
    | Transaction
    | AccountEntryTransactionInfo
    | ExportFlowTransaction;
  isGlobalPage?: boolean;
  showReceiptIcon?: boolean;
  showComment?: boolean;
  showCommentIcon?: boolean;
  showReviewFlagReason?: boolean;
  showSupplierAccountNumber?: boolean;
  supplierAccountNumber?: string;
}

const MerchantCell = ({
  transaction,
  isGlobalPage = false,
  showReceiptIcon,
  showComment,
  showCommentIcon,
  showReviewFlagReason,
  showSupplierAccountNumber,
  supplierAccountNumber,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings, featureModules },
  } = useGlobalState();

  const { merchantName, category, comment, receiptsCount } = transaction;
  const commentsEnabled = isGlobalPage || accountingSettings?.commentEnabled;
  const reviewStatus =
    ('reviewStatus' in transaction && transaction.reviewStatus) || null;
  const reviewFlagReason =
    ('reviewFlagReason' in transaction && transaction.reviewFlagReason) || null;

  const renderFooter = () => {
    const footerText = (() => {
      if (showSupplierAccountNumber) {
        return supplierAccountNumber || '';
      }
      if (showReviewFlagReason && 'reviewFlagReason' in transaction) {
        return t(`reviewFlagReasons.${transaction.reviewFlagReason}`);
      }
      if (!showComment) {
        return t(`merchantCategories.${category}`);
      }
      if (commentsEnabled && comment) return comment;
      return null;
    })();

    if (footerText)
      return (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
        >
          {footerText}
        </Typography>
      );

    return null;
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      overflow="hidden"
      // avoid avatar cut off
      minHeight={43}
    >
      <TransactionReviewStatusBadge reviewStatus={reviewStatus}>
        <TransactionAvatar transaction={transaction} />
      </TransactionReviewStatusBadge>

      <Box pl={2} minWidth={0}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" noWrap>
            {merchantName}
          </Typography>
          {showReceiptIcon &&
            featureModules.RECEIPT_MANAGEMENT &&
            reviewFlagReason !== ReviewFlagReason.privateExpense &&
            receiptsCount > 0 && (
              <ReceiptIcon fontSize="small" sx={{ ml: 0.5 }} />
            )}
          {commentsEnabled && showCommentIcon && !!comment && (
            <ChatCircleTextIcon fontSize="small" sx={{ ml: 0.5 }} />
          )}
        </Box>
        {renderFooter()}
      </Box>
    </Box>
  );
};

export default MerchantCell;
