import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import { LoaderWithOverlay, Typography } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import { ContentContainer } from 'layout';
import {
  Cashback,
  CurrencyCashbackByCurrencyMap,
  FeatureModuleKey,
  MonthlyCashbackProgressList,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import CashbackDashboard from './CashbackDashboard';
import { CompanyTile, TextTile, TileList } from './style';

interface State {
  isLoading: boolean;
  cashback: Cashback | null;
  cashbackByCurrency: CurrencyCashbackByCurrencyMap | null;
  monthlyCashbackProgress: MonthlyCashbackProgressList | null;
}

const CashbackSubpage = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    cashback: null,
    cashbackByCurrency: null,
    monthlyCashbackProgress: null,
  });
  const cashbackRate = state.cashback ? state.cashback.generalRate * 100 : 0;

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [
        cashback,
        cashbackByCurrency,
        monthlyCashbackProgress,
      ] = await Promise.all([
        api.getCashback(organization!.id),
        api.getCashbackByCurrency(organization!.id),
        api.getMonthlyCashbackProgress(organization!.id),
      ]);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        cashback,
        cashbackByCurrency,
        monthlyCashbackProgress,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {state.cashback &&
      state.cashbackByCurrency &&
      state.monthlyCashbackProgress ? (
        <ContentContainer>
          <CashbackDashboard
            cashback={state.cashback}
            cashbackByCurrency={state.cashbackByCurrency}
            monthlyCashbackProgress={state.monthlyCashbackProgress}
            onUpdate={() => getData()}
          />

          <Typography variant="h5" mb={1}>
            {t('cashbackSubpage.subheader')}
          </Typography>
          <Typography variant="body2" mb={4}>
            <Trans
              i18nKey="cashbackSubpage.blockLabel1"
              components={{
                percent: <FormatPercent value={cashbackRate} />,
                highlight: <b />,
              }}
            />
          </Typography>
          <TileList>
            <CompanyTile logo="google" />
            <CompanyTile logo="facebook" />
            <CompanyTile logo="linkedin" />
            <CompanyTile logo="twitter" />
            <TextTile>{t('cashbackSubpage.moreLabel')}</TextTile>
          </TileList>

          <Typography variant="body2" mb={4}>
            <Trans
              i18nKey="cashbackSubpage.blockLabel2"
              components={{
                percent: <FormatPercent value={cashbackRate} />,
                highlight: <b />,
              }}
            />
          </Typography>
          <TileList>
            <CompanyTile logo="azure" />
            <CompanyTile logo="googlecloud" />
            <CompanyTile logo="ibmcloud" />
            <CompanyTile logo="heroku" />
            <TextTile>{t('cashbackSubpage.moreLabel')}</TextTile>
          </TileList>

          <Typography variant="body2" mb={4}>
            <Trans
              i18nKey="cashbackSubpage.blockLabel3"
              components={{
                percent: <FormatPercent value={cashbackRate} />,
                highlight: <b />,
              }}
            />
          </Typography>
          <TileList>
            <CompanyTile logo="adobe" />
            <CompanyTile logo="atlassian" />
            <CompanyTile logo="zenkesk" />
            <CompanyTile logo="hubspot" />
            <TextTile>{t('cashbackSubpage.moreLabel')}</TextTile>
          </TileList>

          <Typography variant="body2" mb={4}>
            <Trans
              i18nKey="cashbackSubpage.blockLabel4"
              components={{
                percent: <FormatPercent value={cashbackRate} />,
                highlight: <b />,
              }}
            />
          </Typography>
          <TileList>
            <CompanyTile logo="apple" />
            <CompanyTile logo="ikea" />
            <CompanyTile logo="dell" />
            <CompanyTile logo="staples" />
            <TextTile>{t('cashbackSubpage.moreLabel')}</TextTile>
          </TileList>
        </ContentContainer>
      ) : (
        !state.isLoading && <WidgetError onReload={getData} />
      )}

      <LoaderWithOverlay loading={state.isLoading} />
    </>
  );
};

export default withPageConfig(CashbackSubpage, {
  permission: 'cashbacks-subpage:visit',
  featureModule: FeatureModuleKey.cashback,
});
