import { FormEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import PartnerSelect, { PartnerSelectType } from 'components/PartnerSelect';
import { useGlobalState } from 'context/GlobalState';
import {
  AccountGroupSelect,
  OrganizationAutocompleteSearch,
  OrganizationToHubspotLinkField,
} from 'domains/organization/components';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Switch,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountGroup,
  CardAccountCurrency,
  NetworkErrorCode,
  Organization,
  OrganizationSearchItem,
  supportedCountries,
  SupportedCountry,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertUKtoGBCountryCode,
  getGenericErrorMsg,
  getNetworkErrorCode,
} from 'services/utils';

interface State {
  selectedOrg: OrganizationSearchItem | null;
  isCreateOrgLoading: boolean;
  accountGroup: AccountGroup | null;
  hubspotId: string;
  partnersOrganizationId: string;
  country: SupportedCountry;
  isConfirmationModalOpen: boolean;
  currency: CardAccountCurrency;
  newOnboardingFlow: boolean;
}

export interface Props extends DialogProps {
  isNonCustomerOrganizationsPage: boolean;
  isSourcePartnerSelected: boolean;
  selectedPartner: PartnerSelectType;
  selectedPartnerRevenueShare: PartnerSelectType;
  onClose: () => void;
  onSuccess: (organization: Organization) => void;
  onPartnerChange: (
    partner: PartnerSelectType,
    partnerRevenueShare: PartnerSelectType
  ) => void;
  openManualOrgCreationDialog: () => void;
}

const CreateOrgDialog = ({
  isNonCustomerOrganizationsPage,
  isSourcePartnerSelected,
  selectedPartner,
  selectedPartnerRevenueShare,
  onSuccess,
  onPartnerChange,
  openManualOrgCreationDialog,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { newOnboardingEnabled } = useFlags();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { cardAccountCurrencies },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    selectedOrg: null,
    isCreateOrgLoading: false,
    accountGroup: null,
    hubspotId: '',
    partnersOrganizationId: '',
    country: SupportedCountry.de,
    isConfirmationModalOpen: false,
    currency: CardAccountCurrency.EUR,
    newOnboardingFlow: false,
  });

  const createOrganization = async () => {
    try {
      setState((prevState) => ({ ...prevState, isCreateOrgLoading: true }));
      const partnerOrgIdTrimmed = state.partnersOrganizationId.trim();
      const payload = {
        accountGroup: state.accountGroup!,
        partnerId: selectedPartner.partnerId,
        partnerRevenueShareId: selectedPartnerRevenueShare.partnerId,
        companyId: state.selectedOrg!.companyId,
        companyIdType: state.selectedOrg!.companyIdType,
        hubspotId: state.hubspotId,
        partnersOrganizationId:
          isSourcePartnerSelected && partnerOrgIdTrimmed
            ? partnerOrgIdTrimmed
            : null,
        currency: state.currency,
        newOnboardingFlow: state.newOnboardingFlow,
      };

      const organization = isNonCustomerOrganizationsPage
        ? await api.createNonCustomerOrganization(payload)
        : await api.createOrganization(payload);
      if (!mounted.current) return;
      onSuccess(organization);
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isCreateOrgLoading: false,
        isConfirmationModalOpen: false,
      }));
      if (getNetworkErrorCode(error) === NetworkErrorCode.alreadyExists) {
        enqueueSnackbar(t('int.createOrgDialog.orgExistsError'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isSourcePartnerSelected || state.newOnboardingFlow)
      setState((prevState) => ({
        ...prevState,
        isConfirmationModalOpen: true,
      }));
    else createOrganization();
  };

  if (state.isConfirmationModalOpen)
    return (
      <ConfirmDialog
        {...props}
        loading={state.isCreateOrgLoading}
        title={t(
          `int.createOrgConfirmationDialog.${
            isSourcePartnerSelected
              ? 'partnerRevenueShare'
              : 'newOnboardingFlow'
          }.title`
        )}
        description={
          <>
            {isSourcePartnerSelected && (
              <Trans
                i18nKey="int.createOrgConfirmationDialog.partnerRevenueShare.description"
                components={{
                  item: <Box mt={2} />,
                  partner: <Box style={{ fontWeight: 'bold' }} />,
                }}
                values={{
                  revenueSharePartner: selectedPartnerRevenueShare.name,
                  configurationPartner: selectedPartner.name,
                }}
              />
            )}

            {isSourcePartnerSelected && state.newOnboardingFlow && (
              <Box py={2}>
                <Divider />
              </Box>
            )}

            {state.newOnboardingFlow && (
              <Trans
                i18nKey="int.createOrgConfirmationDialog.newOnboardingFlow.description"
                components={{
                  b: <b />,
                  br: <br />,
                }}
              />
            )}
          </>
        }
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isConfirmationModalOpen: false,
          }))
        }
        onSuccess={createOrganization}
      />
    );

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.createOrgDialog.addNewOrg')}</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit} noValidate id="create-org-form">
          <Grid container spacing={2}>
            {!isNonCustomerOrganizationsPage && (
              <>
                <Grid item xs={12}>
                  <PartnerSelect
                    name="partnerRevenueShareId"
                    label={t('int.partnerSelect.revenueLabel')}
                    tooltipLabel={t('int.partnerSelect.revenueTooltip')}
                    value={selectedPartnerRevenueShare}
                    onChange={(partnerValue, overrideConfigValue) => {
                      onPartnerChange(
                        overrideConfigValue ? partnerValue : selectedPartner,
                        partnerValue
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PartnerSelect
                    name="partnerId"
                    label={t('int.partnerSelect.configLabel')}
                    tooltipLabel={t('int.partnerSelect.configTooltip')}
                    value={selectedPartner}
                    isPartnerConfig
                    onChange={(partnerValue) =>
                      onPartnerChange(partnerValue, selectedPartnerRevenueShare)
                    }
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Autocomplete<SupportedCountry, false, true, false>
                value={state.country}
                onChange={(_, option) =>
                  setState((prevState) => ({
                    ...prevState,
                    country: option,
                  }))
                }
                options={supportedCountries}
                disableClearable
                disabled={state.isCreateOrgLoading}
                label={t('int.createOrgDialog.country')}
                placeholder={t('int.createOrgDialog.country')}
                getOptionLabel={(option: SupportedCountry) =>
                  t(`countries.${option}`)
                }
                noOptionsText={t('common.nothingFound')}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth disabled={state.isCreateOrgLoading}>
                <InputLabel id="main-card-account-currency-select-label">
                  {t('int.createOrgDialog.currency')}
                </InputLabel>
                <Select
                  value={state.currency}
                  onChange={(event) =>
                    setState((prevState) => ({
                      ...prevState,
                      currency: event.target.value as CardAccountCurrency,
                    }))
                  }
                  labelId="main-card-account-currency-select-label"
                >
                  {cardAccountCurrencies.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <AccountGroupSelect
                country={state.country}
                currency={state.currency}
                partnerId={selectedPartner.partnerId}
                value={state.accountGroup}
                onChange={(accountGroup) =>
                  setState((prevState) => ({ ...prevState, accountGroup }))
                }
              />
            </Grid>

            <Grid item xs={12}>
              <OrganizationAutocompleteSearch
                country={convertUKtoGBCountryCode(state.country)}
                value={state.selectedOrg}
                onChange={(selectedOrg) =>
                  setState((prevState) => ({
                    ...prevState,
                    selectedOrg,
                    hubspotId: '',
                  }))
                }
                helperText={
                  <>
                    {t('int.createOrgDialog.orgNotListed')}{' '}
                    <Link
                      component="button"
                      type="button"
                      onClick={openManualOrgCreationDialog}
                    >
                      {t('int.createOrgDialog.addOrgManually')}
                    </Link>
                  </>
                }
              />
            </Grid>

            {!isNonCustomerOrganizationsPage && (
              <Grid item xs={12}>
                <OrganizationToHubspotLinkField
                  disabled={!state.selectedOrg}
                  validHsOrgNames={
                    state.selectedOrg?.companyName
                      ? [state.selectedOrg?.companyName]
                      : []
                  }
                  onSuccess={(hsId) =>
                    setState((prevState) => ({
                      ...prevState,
                      hubspotId: hsId,
                    }))
                  }
                  resetOnChange={() => {
                    if (state.hubspotId) {
                      setState((prevState) => ({
                        ...prevState,
                        hubspotId: '',
                      }));
                    }
                  }}
                />
              </Grid>
            )}

            {isSourcePartnerSelected && (
              <Grid item xs={12}>
                <TextField
                  value={state.partnersOrganizationId}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      partnersOrganizationId: e.target.value,
                    }))
                  }
                  inputProps={{ maxLength: 100 }}
                  label={t('int.createOrgDialog.partnersOrganizationId')}
                  placeholder={t('int.createOrgDialog.partnersOrganizationId')}
                />
              </Grid>
            )}

            {newOnboardingEnabled && (
              <Grid item xs={12}>
                <Box mt={2}>
                  <FormControlLabel
                    checked={state.newOnboardingFlow}
                    onChange={() =>
                      setState((prevState) => ({
                        ...prevState,
                        newOnboardingFlow: !prevState.newOnboardingFlow,
                      }))
                    }
                    control={<Switch />}
                    label={t('int.createOrgDialog.newOnboardingFlowLabel')}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={
            !state.accountGroup ||
            !state.selectedOrg ||
            (!isNonCustomerOrganizationsPage && !state.hubspotId) ||
            state.isCreateOrgLoading
          }
          form="create-org-form"
          type="submit"
        >
          {t('int.createOrgDialog.addOrg')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isCreateOrgLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(CreateOrgDialog);
