import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CardTileProps } from 'domains/card/components/CardTile/index';
import CardSensitiveData from './CardSensitiveData';
import { CardholderName, CardName } from './style';

const VirtualLayout = (props: CardTileProps) => {
  const { t } = useTranslation();
  const { card } = props;

  const cardholderName = card.customFirstName
    ? `${card.customFirstName} ${card.customLastName}`
    : `${card.chFirstName} ${card.chLastName}`;

  return (
    <>
      <CardName>
        {card.cardName
          ? card.cardName
          : t('cardTile.usersVirtualCard', {
              name: card.customFirstName || card.chFirstName,
            })}
      </CardName>
      <Box position="absolute" top={106} left={24}>
        <CardholderName>{cardholderName}</CardholderName>
      </Box>
      <Box position="absolute" top={202} left={24}>
        <CardSensitiveData {...props} />
      </Box>
    </>
  );
};

export default VirtualLayout;
