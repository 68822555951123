export enum Role {
  cardholder = 'CARDHOLDER',
  teamManager = 'TEAM_MANAGER',
  accountant = 'ACCOUNTANT',
  accountingSettingsManager = 'ACCOUNTING_SETTINGS_MANAGER',
  accountingExportManager = 'ACCOUNTING_EXPORT_MANAGER',
  orgAdmin = 'ORG_ADMIN',
  orgSettingsManager = 'ORG_SETTINGS_MANAGER',
  orgModulesManager = 'ORG_MODULES_MANAGER',
  orgIntegrationManager = 'ORG_INTEGRATION_MANAGER',
  accountOwner = 'ACCOUNT_OWNER',
  auditor = 'AUDITOR', // IN-10439
  legalRep = 'LEGAL_REP',
  creditOps = 'CREDIT_OPS',
  sales = 'SALES',
  platformAdmin = 'PLATFORM_ADMIN',
  platformReadOnly = 'PLATFORM_READ_ONLY',
  customerSupport = 'CUSTOMER_SUPPORT',
  paymentOps = 'PAYMENT_OPS',
  partnerManager = 'PARTNER_MANAGER', //partner role in internal app
  // only valid in combination with the PLATFORM_ADMIN role
  superPlatformAdmin = 'SUPER_PLATFORM_ADMIN',
  // partner portal roles
  partnerAdmin = 'PARTNER_ADMIN',
  partnerOps = 'PARTNER_OPS',
}

export const CARDHOLDER_ROLES = [Role.cardholder];
export const EXTERNAL_ADMIN_ROLES = [
  Role.accountant,
  Role.orgAdmin,
  Role.accountOwner,
  Role.auditor,
];
export const INTERNAL_ADMIN_ROLES = [
  Role.customerSupport,
  Role.paymentOps,
  Role.creditOps,
  Role.sales,
  Role.platformAdmin,
  Role.platformReadOnly,
];
export const ADMIN_ROLES = [...EXTERNAL_ADMIN_ROLES, ...INTERNAL_ADMIN_ROLES];
export const PARTNER_PORTAL_ROLES = [Role.partnerAdmin, Role.partnerOps];
