import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  ActiveFilterValue,
  StatusFilterSelect,
} from 'domains/settings/components';
import { AddNewItemMenu } from 'domains/settings/components/AddNewItemMenu';
import {
  AddSubCategoryDialog,
  CodatSyncSeparatedDialog,
  EditSubCategoryDialog,
  SyncNameEnum,
  UploadGLAccountsDialog,
} from 'domains/settings/dialogs';
import {
  FiltersContainer,
  HeaderContainer,
  HeaderTitle,
} from 'domains/settings/layout';
import withTabPermission from 'domains/settings/pages/AccountingPage/withTabPermission';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import {
  Badge,
  Box,
  Button,
  DataGrid,
  LoaderWithOverlay,
  Typography,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, PageSearchInput } from 'layout';
import {
  AccountingItemStatus,
  AccountingSystem,
  CodatMappingOptionsSummary,
  DEFAULT_PAGE_LIMIT,
  merchantCategories,
  Subcategory,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import useCanUser from 'services/rbac/useCanUser';
import { getGenericErrorMsg, getPath } from 'services/utils';
import useColumns from './useColumns';

// create hash map based on hardcoded merchant category
const createCategoriesHashMap = () => {
  const categoriesHashMap: Record<string, Subcategory[]> = {};
  merchantCategories.forEach((category) => {
    categoriesHashMap[category] = [
      {
        id: category,
        name: '',
        number: null,
        status: AccountingItemStatus.active,
        category: category,
        // keep organizationId empty for parent category detection
        organizationId: '',
      },
    ];
  });
  return categoriesHashMap;
};

const mapSubcategories = (
  categoriesHashMap: Record<string, Subcategory[]> = {},
  subcategories: Subcategory[]
) => {
  const hashMap = { ...categoriesHashMap };
  // fill the hash map with subcategories
  subcategories.forEach((subcategory) => {
    if (hashMap[subcategory.category])
      hashMap[subcategory.category].push(subcategory);
  });
  return { hashMap, mappedSubcategories: Object.values(hashMap).flat() };
};

interface State {
  categories: Subcategory[];
  categoriesHashMap: Record<string, Subcategory[]>;
  hasNextPage: boolean;
  totalCount: number;
  isLoading: boolean;
  activeFilter: ActiveFilterValue | '';
  isAddSubcategoryDialogOpen: boolean;
  isEditSubcategoryDialogOpen: boolean;
  isUploadSubcategoryDialogOpen: boolean;
  editableSubcategory: Subcategory | null;
  codatMappingOptionsSummary: CodatMappingOptionsSummary | null;
  search: string;
  codatSyncDialogOpen: boolean;
}

const CategoriesSubPage = () => {
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const history = useHistory();
  const location = useLocation<{ isCodatFlow: boolean }>();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    categories: [],
    categoriesHashMap: createCategoriesHashMap(),
    hasNextPage: false,
    totalCount: 0,
    isLoading: true,
    activeFilter: AccountingItemStatus.active,
    isAddSubcategoryDialogOpen: false,
    isEditSubcategoryDialogOpen: false,
    isUploadSubcategoryDialogOpen: false,
    editableSubcategory: null,
    codatMappingOptionsSummary: null,
    search: '',
    codatSyncDialogOpen: false,
  });

  const columns = useColumns((item) =>
    setState((prevState) => ({
      ...prevState,
      isEditSubcategoryDialogOpen: true,
      editableSubcategory: item,
    }))
  );

  useEffect(() => {
    if (location.state?.isCodatFlow && canUser('codat-sync:change')) {
      setState((prevState) => ({
        ...prevState,
        codatSyncDialogOpen: true,
      }));
      history.replace({ state: null });
    }
  }, [location.state]);

  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);
  const areItemsReadOnly =
    (accountingSettings!.accountingSystem === AccountingSystem.lexOffice &&
      accountingSettings!.useAccountingExport) ||
    isCodatConnected;

  const getData = async (isLoadMore = false, fetchCodatSummary = false) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [
        { subcategories, hasNextPage, totalCount },
        codatMappingOptionsSummary,
      ] = await Promise.all([
        api.getSubcategories({
          organizationId: organization!.id,
          page: pageRef.current,
          limit: DEFAULT_PAGE_LIMIT,
          q: state.search || undefined,
          sortBy: '+category,+name',
          status: (state.activeFilter as AccountingItemStatus) || undefined,
        }),
        fetchCodatSummary && isCodatConnected && canUser('codat-sync:view')
          ? api.getCodatMappingOptionsSummary(organization!.id)
          : null,
      ]);
      if (!mounted.current) return;
      setState((prevState) => {
        const { hashMap, mappedSubcategories } = mapSubcategories(
          isLoadMore ? prevState.categoriesHashMap : createCategoriesHashMap(),
          subcategories
        );
        return {
          ...prevState,
          categories: mappedSubcategories,
          categoriesHashMap: hashMap,
          hasNextPage,
          totalCount,
          codatMappingOptionsSummary,
          isLoading: false,
        };
      });
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    pageRef.current = 0;
    getData(false, !state.codatMappingOptionsSummary);
  }, [state.activeFilter, state.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(true);
  };

  const renderTitle = () => {
    if (isCodatConnected)
      return (
        <Trans
          i18nKey="categoriesSubPage.codatTitle"
          values={{ name: accountingSettings!.accountingSystemName }}
          components={{
            a: (
              <Link
                to={generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })}
              />
            ),
          }}
        />
      );
    return t('categoriesSubPage.title');
  };

  const renderCustomButton = () => {
    if (isCodatConnected && state.codatMappingOptionsSummary) {
      if (!state.codatMappingOptionsSummary.lastSynced) {
        return (
          <Button
            onClick={() =>
              history.push(
                generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })
              )
            }
          >
            {t('categoriesSubPage.setupSyncBtn')}
          </Button>
        );
      }

      return (
        <Badge
          color="secondary"
          badgeContent={state.codatMappingOptionsSummary?.accounts.newItems}
        >
          <Button
            disabled={!canUser('codat-sync:change')}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                codatSyncDialogOpen: true,
              }))
            }
          >
            {t('categoriesSubPage.editAccSyncBtn')}
          </Button>
        </Badge>
      );
    }

    if (canUser('subcategory:create') && !areItemsReadOnly) {
      return (
        <AddNewItemMenu
          title={t('categoriesSubPage.addButtonTitle')}
          onAdd={() =>
            setState((prevState) => ({
              ...prevState,
              isAddSubcategoryDialogOpen: true,
            }))
          }
          onUpload={() =>
            setState((prevState) => ({
              ...prevState,
              isUploadSubcategoryDialogOpen: true,
            }))
          }
        />
      );
    }

    return null;
  };

  const noSearchResults =
    !state.isLoading && !!state.search && !state.totalCount;

  return (
    <>
      <HeaderContainer>
        <ContentContainer>
          <HeaderTitle>
            {t('categoriesSubPage.mainTitle')}

            {state.codatMappingOptionsSummary?.lastSynced && (
              <Typography
                color="textSecondary"
                variant="body2"
                component="span"
                ml={1}
              >
                (
                {t('categoriesSubPage.lastSync', {
                  date: moment(
                    state.codatMappingOptionsSummary.lastSynced
                  ).format('DD MMM YYYY, HH:mm'),
                })}
                )
              </Typography>
            )}
          </HeaderTitle>
          <Typography>{renderTitle()}</Typography>
        </ContentContainer>
      </HeaderContainer>

      <FiltersContainer>
        <PageSearchInput
          sx={{ mr: 2 }}
          initialValue={state.search}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, search: value }))
          }
          disabled={!state.search && !state.totalCount}
        />

        <StatusFilterSelect
          filterValue={state.activeFilter}
          disabled={state.isLoading}
          onFilterChange={(activeFilter) =>
            setState((prevState) => ({
              ...prevState,
              activeFilter,
            }))
          }
        />

        <Box ml="auto">{renderCustomButton()}</Box>
      </FiltersContainer>

      <Box
        position="relative"
        flex={1}
        maxHeight={(theme) => `calc(100% + ${theme.spacing(5)})`}
      >
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid
          sx={{ '.MuiDataGrid-cell': { borderBottom: 0 } }}
          rowHeight={60}
          apiRef={dataGridRef}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          loading={state.isLoading}
          rows={noSearchResults ? [] : state.categories}
          columns={columns}
          columnVisibilityModel={{
            actions: canUser('subcategory:update') && !areItemsReadOnly,
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => <NothingFound $top={0} $bottom={0} />,
            loadingOverlay: () => null,
          }}
        />
      </Box>

      <AddSubCategoryDialog
        open={state.isAddSubcategoryDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isAddSubcategoryDialogOpen: false,
          }))
        }
        onSuccess={() => {
          pageRef.current = 0;
          getData();
          setState((prevState) => ({
            ...prevState,
            isAddSubcategoryDialogOpen: false,
          }));
        }}
      />

      <EditSubCategoryDialog
        open={state.isEditSubcategoryDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isEditSubcategoryDialogOpen: false,
          }))
        }
        onSuccess={() => {
          pageRef.current = 0;
          getData();
          setState((prevState) => ({
            ...prevState,
            isEditSubcategoryDialogOpen: false,
          }));
        }}
        subcategory={state.editableSubcategory}
      />

      <UploadGLAccountsDialog
        open={state.isUploadSubcategoryDialogOpen}
        onSuccess={(uploadedCategories) => {
          setState((prevState) => ({
            ...prevState,
            isUploadSubcategoryDialogOpen: false,
          }));
          pageRef.current = 0;
          getData();
          enqueueSnackbar(
            t('categoriesSubPage.uploadSuccessMsg', {
              count: uploadedCategories.length,
            })
          );
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isUploadSubcategoryDialogOpen: false,
          }))
        }
      />

      <CodatSyncSeparatedDialog
        name={SyncNameEnum.accounts}
        open={state.codatSyncDialogOpen}
        onSuccess={() => {
          pageRef.current = 0;
          getData(false, true);
        }}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            codatSyncDialogOpen: false,
          }));
        }}
      />
    </>
  );
};

export default withPageConfig(
  withTabPermission(CategoriesSubPage, 'subcategoryEnabled'),
  { permission: 'categories-sub-page:visit' }
);
