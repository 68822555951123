import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Grid, LabeledValue } from 'elements';
import { useCanUser } from 'services/rbac';
import OrganizationTypeChangeDialog from './OrganizationTypeChangeDialog';

const OrganizationTypeFormGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <LabeledValue
          label={t(
            'int.generalTermsPage.onboardingSettingsSection.organizationType'
          )}
          value={t(`int.organizationType.${organization!.type}`)}
          onEdit={
            canUser('org-type:update') ? () => setIsDialogOpen(true) : undefined
          }
        />
      </Grid>

      {canUser('org-type:update') && (
        <OrganizationTypeChangeDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </>
  );
};

export default OrganizationTypeFormGroup;
