import {
  AnchorHTMLAttributes,
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  MouseEvent,
  ReactNode,
  useState,
} from 'react';
import { CircularProgress } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import MuiDivider from '@material-ui/core/Divider';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiMenu, { MenuProps as MuiMenuProps } from '@material-ui/core/Menu';
import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import styled, { css } from 'styled-components';
import { Button } from 'components/Button';

interface MenuContainerProps {
  button: JSX.Element;
  onClose?: () => void;
  menuProps?: Omit<
    MuiMenuProps,
    'anchorEl' | 'open' | 'onClose' | 'keepMounted'
  >;
  children: ReactNode;
  className?: string;
}

// @deprecated
export const MenuContainer = ({
  button,
  onClose,
  menuProps,
  children,
  className = '',
}: MenuContainerProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onClose) onClose();
  };

  return (
    <>
      {cloneElement(button, {
        onClick: handleClick,
        className: clsx(anchorEl ? 'active' : '', button.props.className),
      })}
      <Menu
        className={className}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        {...menuProps}
      >
        {Children.map(children, (child) => {
          if (!isValidElement<{ onClick: () => void }>(child)) return null;
          return cloneElement(child, {
            onClick: () => {
              if (typeof child.props.onClick === 'function') {
                child.props.onClick();
              }
              handleClose();
            },
          });
        })}
      </Menu>
    </>
  );
};

export const Menu = styled((props: MuiMenuProps) => (
  <MuiMenu
    getContentAnchorEl={null}
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))`
  & .MuiMenu-paper {
    overflow: visible;
    margin-top: 2px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
  }

  & .MuiMenu-list {
    padding: 12px 0;
  }
`;

const menuItemMixin = css`
  padding: 6px 20px 6px 12px;
  margin: 0 12px;
  border-radius: 7px;
  color: ${({ theme }) => theme.colors.grey10};

  &:hover,
  &.active {
    background: ${({ theme }) => theme.colors.neutralVariant80};
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  ${menuItemMixin}
`;

const ForwardMuiMenuItem = forwardRef((props, ref) => (
  <MuiMenuItem component="a" innerRef={ref} {...props} />
));

export const MenuItemLink = styled(ForwardMuiMenuItem)<
  MuiMenuItemProps & AnchorHTMLAttributes<HTMLAnchorElement>
>`
  ${menuItemMixin}
`;

export const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 28px;
  color: inherit;

  & svg {
    width: 16px;
    height: 16px;
  }
`;

export const ListItemText = styled(MuiListItemText)`
  & .MuiTypography-root {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Divider = styled(MuiDivider)`
  margin: 12px 20px;
`;

export const MenuButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 13px;
  left: 23px;

  svg {
    width: 22px;
    height: 22px;
    margin: 0;
    color: ${({ theme }) => theme.colors.white};
  }
`;

interface MenuButtonProps extends ButtonProps {
  loading?: boolean;
}

export const MenuButton = styled(({ loading = false, className, ...props }) => (
  <Button
    className={clsx(loading && 'menu-button-loading', className)}
    {...props}
  />
))<MenuButtonProps>`
  position: relative;

  .MuiButton-label {
    white-space: nowrap;
  }

  &.menu-button-loading:not(.active) .MuiButton-startIcon {
    visibility: hidden;
  }
  &.active ${MenuButtonCircularProgress} {
    visibility: hidden;
  }
`;
