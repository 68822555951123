import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  AddNewItemMenu,
  StatusFilterSelect,
} from 'domains/settings/components';
import {
  CodatSyncSeparatedDialog,
  SyncNameEnum,
} from 'domains/settings/dialogs';
import {
  FiltersContainer,
  HeaderContainer,
  HeaderTitle,
} from 'domains/settings/layout';
import withTabPermission from 'domains/settings/pages/AccountingPage/withTabPermission';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import {
  Badge,
  Box,
  Button,
  DataGrid,
  GridSortModel,
  gridUtils,
  LoaderWithOverlay,
  Typography,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, PageSearchInput } from 'layout';
import {
  AccountingItemStatus,
  CodatMappingOptionsSummary,
  DEFAULT_PAGE_LIMIT,
  Supplier,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getPath } from 'services/utils';
import AddSupplierDialog from './AddSupplierDialog';
import EditSupplierDialog from './EditSupplierDialog';
import UploadSuppliersDialog from './UploadSuppliersDialog';
import useColumns from './useColumns';

const INITIAL_SORT = '+name';

interface State {
  suppliers: Supplier[];
  hasNextPage: boolean;
  isLoading: boolean;
  activeFilter: AccountingItemStatus | '';
  isAddSupplierDialogOpen: boolean;
  isEditSupplierDialogOpen: boolean;
  isUploadSuppliersDialogOpen: boolean;
  editableSupplier: Supplier | null;
  search: string;
  sort: string;
  codatMappingOptionsSummary: CodatMappingOptionsSummary | null;
  codatSyncDialogOpen: boolean;
}

const SuppliersSubPage = () => {
  const history = useHistory();
  const location = useLocation<{ isCodatFlow: boolean }>();
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    suppliers: [],
    hasNextPage: false,
    isLoading: true,
    activeFilter: AccountingItemStatus.active,
    isAddSupplierDialogOpen: false,
    isEditSupplierDialogOpen: false,
    isUploadSuppliersDialogOpen: false,
    editableSupplier: null,
    search: '',
    sort: INITIAL_SORT,
    codatMappingOptionsSummary: null,
    codatSyncDialogOpen: false,
  });
  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);

  const columns = useColumns((item) =>
    setState((prevState) => ({
      ...prevState,
      editableSupplier: item,
      isEditSupplierDialogOpen: true,
    }))
  );

  useEffect(() => {
    if (location.state?.isCodatFlow && canUser('codat-sync:change')) {
      setState((prevState) => ({
        ...prevState,
        codatSyncDialogOpen: true,
      }));
      history.replace({ state: null });
    }
  }, [location.state]);

  const getData = async (isLoadMore = false, fetchCodatSummary = false) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [
        { suppliers, hasNextPage },
        codatMappingOptionsSummary,
      ] = await Promise.all([
        api.getSuppliers({
          organizationId: organization!.id,
          page: pageRef.current,
          limit: DEFAULT_PAGE_LIMIT,
          q: state.search || undefined,
          sortBy: state.sort,
          status: state.activeFilter || undefined,
        }),
        fetchCodatSummary && isCodatConnected && canUser('codat-sync:view')
          ? api.getCodatMappingOptionsSummary(organization!.id)
          : state.codatMappingOptionsSummary,
      ]);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        suppliers: isLoadMore ? [...state.suppliers, ...suppliers] : suppliers,
        codatMappingOptionsSummary,
        hasNextPage,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    pageRef.current = 0;
    getData(false, !state.codatMappingOptionsSummary);
  }, [state.activeFilter, state.search, state.sort]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(true);
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.suppliers.length) return;
    setState((prevState) => ({
      ...prevState,
      sort: gridUtils.getNewSortParam(sort) || INITIAL_SORT,
    }));
  };

  const renderTitle = () => {
    if (isCodatConnected)
      return (
        <Trans
          i18nKey="suppliersSubPage.codatTitle"
          values={{ name: accountingSettings!.accountingSystemName }}
          components={{
            a: (
              <Link
                to={generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })}
              />
            ),
          }}
        />
      );
    return t('suppliersSubPage.title');
  };

  const renderCustomButton = () => {
    if (isCodatConnected && state.codatMappingOptionsSummary) {
      if (!state.codatMappingOptionsSummary.lastSynced) {
        return (
          <Button
            onClick={() =>
              history.push(
                generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })
              )
            }
          >
            {t('suppliersSubPage.setupSyncBtn')}
          </Button>
        );
      }

      return (
        <Badge
          color="secondary"
          badgeContent={state.codatMappingOptionsSummary?.suppliers.newItems}
        >
          <Button
            disabled={!canUser('codat-sync:change')}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                codatSyncDialogOpen: true,
              }))
            }
          >
            {t('suppliersSubPage.editAccSyncBtn')}
          </Button>
        </Badge>
      );
    }

    if (canUser('supplier:create') && !isCodatConnected) {
      return (
        <AddNewItemMenu
          title={t('suppliersSubPage.addButtonTitle')}
          onAdd={() =>
            setState((prevState) => ({
              ...prevState,
              isAddSupplierDialogOpen: true,
            }))
          }
          onUpload={() =>
            setState((prevState) => ({
              ...prevState,
              isUploadSuppliersDialogOpen: true,
            }))
          }
        />
      );
    }

    return null;
  };

  return (
    <>
      <HeaderContainer>
        <ContentContainer>
          <HeaderTitle>
            {t('suppliersSubPage.mainTitle')}

            {state.codatMappingOptionsSummary?.lastSynced && (
              <Typography
                color="textSecondary"
                variant="body2"
                component="span"
                ml={1}
              >
                (
                {t('suppliersSubPage.lastSync', {
                  date: moment(
                    state.codatMappingOptionsSummary.lastSynced
                  ).format('DD MMM YYYY, HH:mm'),
                })}
                )
              </Typography>
            )}
          </HeaderTitle>
          <Typography>{renderTitle()}</Typography>
        </ContentContainer>
      </HeaderContainer>

      <FiltersContainer>
        <PageSearchInput
          sx={{ mr: 2 }}
          initialValue={state.search}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, search: value }))
          }
          disabled={!state.search && !state.suppliers.length}
        />

        <StatusFilterSelect
          filterValue={state.activeFilter}
          disabled={state.isLoading}
          onFilterChange={(activeFilter) =>
            setState((prevState) => ({
              ...prevState,
              activeFilter: activeFilter as AccountingItemStatus | '',
            }))
          }
        />

        <Box ml="auto">{renderCustomButton()}</Box>
      </FiltersContainer>

      <Box
        position="relative"
        flex={1}
        maxHeight={(theme) => `calc(100% + ${theme.spacing(5)})`}
      >
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid
          apiRef={dataGridRef}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          loading={state.isLoading}
          rows={state.suppliers}
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(INITIAL_SORT),
            },
          }}
          onSortModelChange={handleSortModelChange}
          columns={columns}
          columnVisibilityModel={{
            actions: canUser('supplier:update') && !isCodatConnected,
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => <NothingFound $top={0} $bottom={0} />,
            loadingOverlay: () => null,
          }}
        />
      </Box>

      <AddSupplierDialog
        open={state.isAddSupplierDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isAddSupplierDialogOpen: false,
          }))
        }
        onSuccess={() => {
          if (dataGridRef.current && !state.isLoading)
            dataGridRef.current.scroll({ left: 0, top: 0 });
          pageRef.current = 0;
          getData();
          setState((prevState) => ({
            ...prevState,
            isAddSupplierDialogOpen: false,
          }));
        }}
      />

      <EditSupplierDialog
        open={state.isEditSupplierDialogOpen}
        supplier={state.editableSupplier}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isEditSupplierDialogOpen: false,
          }))
        }
        onSuccess={() => {
          if (dataGridRef.current && !state.isLoading)
            dataGridRef.current.scroll({ left: 0, top: 0 });
          pageRef.current = 0;
          getData();
          setState((prevState) => ({
            ...prevState,
            isEditSupplierDialogOpen: false,
          }));
        }}
      />

      <UploadSuppliersDialog
        open={state.isUploadSuppliersDialogOpen}
        onSuccess={(uploadedSuppliersLength) => {
          if (dataGridRef.current && !state.isLoading)
            dataGridRef.current.scroll({ left: 0, top: 0 });
          pageRef.current = 0;
          getData();
          setState((prevState) => ({
            ...prevState,
            isUploadSuppliersDialogOpen: false,
          }));
          enqueueSnackbar(
            t('suppliersSubPage.uploadSuccessMsg', {
              count: uploadedSuppliersLength,
            })
          );
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isUploadSuppliersDialogOpen: false,
          }))
        }
      />

      <CodatSyncSeparatedDialog
        name={SyncNameEnum.suppliers}
        open={state.codatSyncDialogOpen}
        onSuccess={() => {
          pageRef.current = 0;
          getData(false, true);
        }}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            codatSyncDialogOpen: false,
          }));
        }}
      />
    </>
  );
};

export default withPageConfig(
  withTabPermission(SuppliersSubPage, 'supplierEnabled'),
  {
    permission: 'suppliers-sub-page:visit',
    featureFlag: 'suppliersEnabled',
  }
);
