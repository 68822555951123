import { ReactNode } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import {
  MerchantCell,
  TransactionAmount,
} from 'domains/transaction/components';
import {
  Box,
  Link,
  Paper,
  ReceiptIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from 'elements';
import {
  AccountEntry,
  AccountEntryBillPaymentType,
  AccountEntryType,
  Transaction,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { getFullName, getPath } from 'services/utils';
import ThumbnailPreview from './ThumbnailPreview';

const InfoBox = ({ label, value }: { label: ReactNode; value: ReactNode }) => (
  <Paper
    sx={{
      px: 1.5,
      py: 2,
      mt: 1,
      minHeight: 56,
    }}
    variant="outlined"
  >
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flex={1}
    >
      <Typography component="div" variant="body2" flexShrink={0} noWrap>
        {label}
      </Typography>
      <Typography component="div" noWrap variant="body2" ml={2}>
        {value}
      </Typography>
    </Box>
  </Paper>
);

interface Props {
  accountEntry: AccountEntry;
  transaction: Transaction | null;
  isExportPage?: boolean;
  onPreviewDialogOpen?: () => void;
}

const ReimbursementEntry = ({
  accountEntry,
  transaction,
  isExportPage,
}: Props) => {
  const { t } = useTranslation();
  const reimbursementInfo = accountEntry.reimbursementInfo!;
  const tx = transaction!;

  return (
    <>
      <Box p={3} borderBottom={(theme) => `1px solid ${theme.palette.divider}`}>
        <Typography variant="overline" component="div" mb={1.5}>
          {t('accountingPage.reimbursementDetails')}
        </Typography>

        {isExportPage && (
          <InfoBox
            label={
              <>
                {t('accountingEntryDetailsPage.employee')}
                <Typography
                  component="div"
                  variant="caption"
                  color="text.secondary"
                >
                  {t('accountingEntryDetailsPage.employeeAccount')}
                </Typography>
              </>
            }
            value={
              <>
                <Typography variant="body2" textAlign="right" noWrap>
                  {accountEntry.reimbursementInfo?.employeeCreditorAccount}
                </Typography>
                <Typography
                  component="div"
                  variant="caption"
                  color="text.secondary"
                  noWrap
                  textAlign="right"
                >
                  {accountEntry.reimbursementInfo?.employeeCreditorAccountName}
                </Typography>
              </>
            }
          />
        )}

        <InfoBox
          label={t('accountingEntryDetailsPage.counterparty')}
          value={
            <Link
              variant="inherit"
              component={RouterLink}
              to={generatePath(getPath('memberDetails'), {
                orgId: accountEntry.organizationId,
                memberId: reimbursementInfo.memberId,
              })}
              sx={{ textDecoration: 'none' }}
              noWrap
            >
              {getFullName(
                reimbursementInfo.memberFirstName,
                reimbursementInfo.memberLastName
              )}
            </Link>
          }
        />
      </Box>
      <Box p={3}>
        <Typography variant="overline" component="div">
          {t('accountingEntryDetailsPage.relatedTransactions')}
        </Typography>

        <Table component="div">
          <TableBody component="div">
            <TableRow
              component={RouterLink}
              to={generatePath(getPath('transactionDetails'), {
                orgId: tx.organizationId,
                transactionId: tx.transactionId,
              })}
              sx={(theme) => ({
                textDecoration: 'none',
                '&:hover': {
                  background: theme.palette.action.hover,
                },
              })}
            >
              <TableCell
                component="div"
                sx={{ overflow: 'hidden', maxWidth: 300 }}
              >
                <MerchantCell transaction={tx} showReviewFlagReason />
              </TableCell>
              <TableCell component="div" align="right">
                <TransactionAmount
                  status={tx.status}
                  simpleType={tx.simpleType}
                >
                  <FormatMoney
                    value={tx.transactionAmount}
                    fractionalPart
                    positiveSign
                  />
                </TransactionAmount>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component="div"
                >
                  {moment.utc(tx.createdAt).format('D MMM YYYY')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

const BillEntry = ({ accountEntry, onPreviewDialogOpen }: Props) => {
  const { billPaymentReportDownloadEnabled } = useFlags();
  const { t } = useTranslation();
  const billPaymentInfo = accountEntry.billPaymentInfo!;

  return (
    <Box p={3}>
      <Typography variant="overline" component="div" mb={1.5}>
        {t('transactionDetails.txDetailsBlockTitle')}
      </Typography>

      <InfoBox
        label={t('accountingEntryDetailsPage.reference')}
        value={billPaymentInfo.publicId}
      />

      {billPaymentReportDownloadEnabled && billPaymentInfo.reportId && (
        <>
          <Typography variant="overline" component="div" mt={2.5} mb={1.5}>
            {t('accountingEntryDetailsPage.report')}
          </Typography>
          <ThumbnailPreview
            reportId={billPaymentInfo.reportId}
            onClick={onPreviewDialogOpen}
          />
        </>
      )}
    </Box>
  );
};

const PrefundingEntry = ({ accountEntry }: Props) => {
  const { t } = useTranslation();
  const billPaymentInfo = accountEntry.billPaymentInfo!;

  return (
    <Box p={3}>
      <Typography variant="overline" component="div" mb={1.5}>
        {t('transactionDetails.txDetailsBlockTitle')}
      </Typography>

      <InfoBox
        label={t('accountingEntryDetailsPage.reference')}
        value={billPaymentInfo.publicId}
      />
      <InfoBox
        label={t('accountingEntryDetailsPage.bank')}
        value={billPaymentInfo.counterpartyBankName}
      />
      <InfoBox
        label={t('accountingEntryDetailsPage.sender')}
        value={billPaymentInfo.counterparty}
      />
      <InfoBox
        label={t('accountingEntryDetailsPage.senderIban')}
        value={billPaymentInfo.counterpartyAccountNumber}
      />
    </Box>
  );
};

const BillPaymentReversalEntry = ({ accountEntry }: Props) => {
  const { t } = useTranslation();
  const billPaymentInfo = accountEntry.billPaymentInfo!;

  return (
    <Box p={3}>
      <Typography variant="overline" component="div" mb={1.5}>
        {t('transactionDetails.txDetailsBlockTitle')}
      </Typography>

      <InfoBox
        label={t('accountingEntryDetailsPage.reference')}
        value={billPaymentInfo.publicId}
      />
      <InfoBox
        label={
          <>
            {t('accountingEntryDetailsPage.originalPayment')}
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              noWrap
            >
              {t('accountingEntryDetailsPage.originalDate')}
            </Typography>
          </>
        }
        value={
          <Box display="flex" alignItems="center">
            <Box overflow="hidden" minWidth={0} textAlign="right">
              <Typography variant="body2" noWrap>
                {billPaymentInfo.originalPaymentPublicId}
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
                textAlign="right"
              >
                {billPaymentInfo.originalPaymentCutoffDate}
              </Typography>
            </Box>
            <Box flexShrink={0} ml={2} lineHeight={0}>
              <ReceiptIcon color="action" />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

const DirectDebitReversalFeeEntry = ({ accountEntry }: Props) => {
  const { t } = useTranslation();
  const billPaymentInfo = accountEntry.billPaymentInfo!;

  return (
    <Box p={3}>
      <Typography variant="overline" component="div" mb={1.5}>
        {t('transactionDetails.txDetailsBlockTitle')}
      </Typography>

      <InfoBox
        label={t('accountingEntryDetailsPage.reference')}
        value={billPaymentInfo.publicId}
      />
      <InfoBox
        label={
          <>
            {t('accountingEntryDetailsPage.originalPayment')}
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              noWrap
            >
              {t('accountingEntryDetailsPage.originalDate')}
            </Typography>
          </>
        }
        value={
          <Box display="flex" alignItems="center">
            <Box overflow="hidden" minWidth={0} textAlign="right">
              <Typography variant="body2" noWrap>
                {billPaymentInfo.originalPaymentPublicId}
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
                textAlign="right"
              >
                {billPaymentInfo.originalPaymentCutoffDate}
              </Typography>
            </Box>
            <Box flexShrink={0} ml={2} lineHeight={0}>
              <ReceiptIcon color="action" />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

const PayoutEntry = ({ accountEntry }: Props) => {
  const { t } = useTranslation();
  const billPaymentInfo = accountEntry.billPaymentInfo!;

  return (
    <Box p={3}>
      <Typography variant="overline" component="div" mb={1.5}>
        {t('transactionDetails.txDetailsBlockTitle')}
      </Typography>

      <InfoBox
        label={t('accountingEntryDetailsPage.reference')}
        value={billPaymentInfo.publicId}
      />
      <InfoBox
        label={t('accountingEntryDetailsPage.bank')}
        value={billPaymentInfo.counterpartyBankName}
      />
      <InfoBox
        label={t('accountingEntryDetailsPage.receiver')}
        value={billPaymentInfo.counterparty}
      />
      <InfoBox
        label={t('accountingEntryDetailsPage.receiverIban')}
        value={billPaymentInfo.counterpartyAccountNumber}
      />
    </Box>
  );
};

const InternalTransferEntry = ({ accountEntry }: Props) => {
  const { t } = useTranslation();
  const billPaymentInfo = accountEntry.billPaymentInfo!;

  return (
    <Box p={3}>
      <Typography variant="overline" component="div" mb={1.5}>
        {t('transactionDetails.txDetailsBlockTitle')}
      </Typography>

      <InfoBox
        label={t('accountingEntryDetailsPage.reference')}
        value={billPaymentInfo.publicId}
      />
    </Box>
  );
};

const AccountEntryDetailsPageContent = (props: Props) => {
  if (props.accountEntry.type === AccountEntryType.reimbursement) {
    return <ReimbursementEntry {...props} />;
  }

  const billPaymentType = props.accountEntry.billPaymentInfo?.billPaymentType;
  switch (billPaymentType) {
    case AccountEntryBillPaymentType.bill:
      return <BillEntry {...props} />;
    case AccountEntryBillPaymentType.prefunding:
      return <PrefundingEntry {...props} />;
    case AccountEntryBillPaymentType.billPaymentReversal:
      return <BillPaymentReversalEntry {...props} />;
    case AccountEntryBillPaymentType.directDebitReversalFee:
      return <DirectDebitReversalFeeEntry {...props} />;
    case AccountEntryBillPaymentType.payout:
      return <PayoutEntry {...props} />;
    case AccountEntryBillPaymentType.internalTransfer:
      return <InternalTransferEntry {...props} />;
    default:
      return null;
  }
};

export default AccountEntryDetailsPageContent;
