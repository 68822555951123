import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AddressDetails from 'components/AddressDetails';
import { useGlobalState } from 'context/GlobalState';
import { CardRequestDialog } from 'domains/card/dialogs';
import { getIssuerStatement } from 'domains/organization/utils';
import { FxRatesCalculatorDialog } from 'domains/reward/dialogs';
import {
  Box,
  Button,
  Grid,
  Link,
  LoaderWithOverlay,
  Typography,
  VerticalCardsIcon,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import useQueryParams from 'hooks/useQueryParams';
import useSnackbar from 'hooks/useSnackbar';
import { PageContent, PageHeader, PageTitle, PageTitleActions } from 'layout';
import { Card, memberCardStatusesToRequest } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  filterAndSortMemberCards,
  getGenericErrorMsg,
  isCardVoid,
} from 'services/utils';
import CardCarousel from './CardCarousel';
import CardDetailsPaper from './CardDetailsPaper';
import { RequestCardTile } from './RequestCardTile';

interface State {
  availableCards: Card[] | null;
  cardIndex: number;
}

const WalletPage = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = useQueryParams();
  const {
    state: { defaultCardAccount, organization, member, featureModules },
  } = useGlobalState();
  const org = organization!;
  const [state, setState] = useState<State>({
    availableCards: null,
    cardIndex: 0,
  });
  const [isFxRateDialogOpen, setIsFxRateDialogOpen] = useState(false);
  const [isCardRequestDialogOpen, setIsCardRequestDialogOpen] = useState(false);

  const getData = async () => {
    try {
      const { cards } = await api.getCards({
        organizationId: org.id,
        memberId: member.id,
        status: memberCardStatusesToRequest.join(),
      });

      if (!mounted.current) return;

      const filteredCards = filterAndSortMemberCards(cards);

      if (queryParams.cardId) {
        const index = filteredCards.findIndex(
          (item: Card) => item.cardId === queryParams.cardId
        );

        if (index !== -1) {
          setState((prevState) => ({
            ...prevState,
            availableCards: filteredCards,
            cardIndex: index,
          }));
        } else {
          try {
            const data = await api.getCard(queryParams.cardId);
            if (!mounted.current) return;
            const filteredCardWithMissingCard = [...filteredCards, data];
            setState((prevState) => ({
              ...prevState,
              availableCards: filteredCardWithMissingCard,
              cardIndex: filteredCardWithMissingCard.length - 1,
            }));
          } catch (error) {
            if (!mounted.current) return;
            setState((prevState) => ({
              ...prevState,
              availableCards: filteredCards,
            }));
            logError(error);
          }
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          availableCards: filteredCards,
        }));
      }
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onCardUpdated = (updatedCard: Card) => {
    setState((prevState) => ({
      ...prevState,
      availableCards: isCardVoid(updatedCard)
        ? prevState.availableCards!.filter(
            (card) => card.cardId !== updatedCard.cardId
          )
        : prevState.availableCards!.map((card) =>
            card.cardId === updatedCard.cardId ? updatedCard : card
          ),
    }));
  };

  const onCardRequestCreated = (card: Card) => {
    setState((prevState) => {
      const newAvailableCards = prevState.availableCards
        ? [...prevState.availableCards, card]
        : [card];

      return {
        ...prevState,
        availableCards: newAvailableCards,
        cardIndex: newAvailableCards.length - 1,
      };
    });
  };

  const handleShowFxRatesCalculatorDialog = () => {
    setIsFxRateDialogOpen(true);
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('walletPage.wallet')}>
          {featureModules.CARD_REQUESTS && (
            <PageTitleActions>
              <Button
                data-intercom-target="request-new-card-button"
                startIcon={<VerticalCardsIcon />}
                onClick={() => setIsCardRequestDialogOpen(true)}
              >
                {t('walletPage.requestNewCard')}
              </Button>
            </PageTitleActions>
          )}
        </PageTitle>
      </PageHeader>

      <PageContent>
        <Grid container spacing={10}>
          <Grid item xs={7} xl={6}>
            {state.availableCards ? (
              <>
                {state.availableCards.length ? (
                  <CardCarousel
                    cards={state.availableCards}
                    cardIndex={state.cardIndex}
                    onCardIndexChange={(cardIndex) =>
                      setState((prevState) => ({ ...prevState, cardIndex }))
                    }
                  />
                ) : (
                  <RequestCardTile />
                )}
                <CardDetailsPaper
                  card={state.availableCards[state.cardIndex]}
                  onCardUpdated={onCardUpdated}
                />
              </>
            ) : (
              <Box position="relative" minHeight={400}>
                <LoaderWithOverlay loading />
              </Box>
            )}
          </Grid>

          <Grid item xs={5} xl={6}>
            <Box maxWidth={640} mx="auto">
              <Typography variant="h5" mb={2}>
                {t('walletPage.billingDetails')}
              </Typography>
              <Typography variant="subtitle2" mb={1}>
                {t('walletPage.billingAddress')}
              </Typography>
              <Typography variant="body2" mb={2}>
                {org.name} <br />
                <AddressDetails address={org.billingAddress} />
              </Typography>
              <Typography variant="subtitle2" mb={1}>
                {t('walletPage.vat')}
              </Typography>
              <Typography variant="body2" mb={2}>
                {org.vatId}
              </Typography>

              <Typography variant="h5" mt={7} mb={2}>
                {t('walletPage.fxRates')}
              </Typography>
              <Typography variant="body2" mb={2}>
                <Trans
                  i18nKey="walletPage.fxRatesDescription"
                  components={{
                    1: (
                      <Link
                        component="button"
                        onClick={handleShowFxRatesCalculatorDialog}
                      />
                    ),
                  }}
                />
              </Typography>

              <Typography
                variant="caption2"
                component="div"
                color="text.secondary"
                mt={24}
              >
                {getIssuerStatement(defaultCardAccount!.accountGroup.value)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </PageContent>

      <FxRatesCalculatorDialog
        open={isFxRateDialogOpen}
        onClose={() => setIsFxRateDialogOpen(false)}
      />
      <CardRequestDialog
        open={isCardRequestDialogOpen}
        onClose={() => setIsCardRequestDialogOpen(false)}
        onSuccess={onCardRequestCreated}
      />
    </>
  );
};

export default withPageConfig(WalletPage, {
  permission: 'ch-wallet-page:visit',
});
