import { useTranslation } from 'react-i18next';
import withPageConfig from 'hoc/withPageConfig';
import useHeaderBorderOnScroll from 'hooks/useBorderOnScroll';
import { FeatureModuleKey } from 'services/constants';
import CompensationPortfolio from './CompensationPortfolio';
import CozeroBanner from './CozeroBanner';
import Dashboard from './Dashboard';
import Faq from './Faq';
import HeroImage from './HeroImage';
import KeyFeatures from './KeyFeatures';
import {
  BottomAnnotation,
  Header,
  HeroContentContainer,
  PageContent,
} from './style';

const PliantEarthPage = () => {
  const { t } = useTranslation();
  const { onContentScroll } = useHeaderBorderOnScroll();

  return (
    <>
      <PageContent onScroll={onContentScroll}>
        <HeroImage>
          <HeroContentContainer>
            <Header>{t('pliantEarthPage.header')}</Header>
            <Dashboard />
          </HeroContentContainer>
        </HeroImage>

        <KeyFeatures />

        <CompensationPortfolio />

        <CozeroBanner />

        <Faq />

        <BottomAnnotation>
          {t('pliantEarthPage.bottomAnnotation')}
        </BottomAnnotation>
      </PageContent>
    </>
  );
};

export default withPageConfig(PliantEarthPage, {
  permission: 'pliant-earth-page:visit',
  featureModule: [FeatureModuleKey.pliantEarth, FeatureModuleKey.cashback],
});
