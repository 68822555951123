import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { HeaderContainer, HeaderTitle } from 'domains/settings/layout';
import { isCustomLocoiaAccountingSystem } from 'domains/settings/utils';
import { LoaderWithOverlay, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer } from 'layout';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getPath } from 'services/utils';
import ConnectionDialog from './ConnectionDialog';
import LocoiaConnectionBlock from './LocoiaConnectionBlock';
import LocoiaExportSettings from './LocoiaExportSettings';

interface State {
  isConnectionDialogOpen: boolean;
  generatedJwt: string;
  locoiaEmbedId: string;
  isLoading: boolean;
}

// most probably this page should be rewritten to handle dynamic
// AccountingSystems for Locoia integration
const LocoiaSubPage = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isConnectionDialogOpen: false,
    generatedJwt: '',
    locoiaEmbedId: '',
    isLoading: false,
  });
  const isCustomAccSystem = isCustomLocoiaAccountingSystem(accountingSettings);

  const canViewPage = canUser('locoia-sub-page:visit', accountingSettings);

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const { locoiaEmbedSettings } = await api.getLocoiaEmbedSettings({
        externalSystem: accountingSettings!.accountingSystem!,
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        locoiaEmbedId: locoiaEmbedSettings[0].locoiaEmbedId,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const refetchAccountingSettings = async () => {
    try {
      const accountingSettings = await api.getAccountingSettings(
        organization!.id
      );
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { accountingSettings },
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const onConnectionChange = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const { jwt } = await api.getLocoiaGeneratedJwt(state.locoiaEmbedId);

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        generatedJwt: jwt,
        isConnectionDialogOpen: true,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    if (canViewPage && canUser('locoia-settings:change')) getData();
  }, []);

  if (!canViewPage)
    return (
      <Redirect
        to={generatePath(getPath('settingsAccounting'), {
          orgId: organization!.id,
        })}
      />
    );

  return (
    <>
      <ContentContainer>
        <HeaderContainer>
          <HeaderTitle>{accountingSettings?.accountingSystemName}</HeaderTitle>
          <Typography>
            {isCustomAccSystem
              ? t('locoiaIntegrationSubPage.customSystem.title')
              : t('locoiaIntegrationSubPage.lexwareOffice.title')}
          </Typography>
        </HeaderContainer>

        <LocoiaConnectionBlock onConnectionChange={onConnectionChange} />

        {!isCustomAccSystem && <LocoiaExportSettings />}
      </ContentContainer>

      <ConnectionDialog
        open={state.isConnectionDialogOpen}
        embedId={state.locoiaEmbedId}
        generatedJwt={state.generatedJwt}
        onStatusChange={() => {
          setState((prevState) => ({
            ...prevState,
            isConnectionDialogOpen: false,
            generatedJwt: '',
            isLoading: true,
          }));
          refetchAccountingSettings();
        }}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            isConnectionDialogOpen: false,
            generatedJwt: '',
          }));
        }}
      />

      <LoaderWithOverlay loading={state.isLoading} />
    </>
  );
};

export default LocoiaSubPage;
