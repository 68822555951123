import { ComponentType, FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useIdTokenCustomData from 'hooks/useIdTokenCustomData';
import { MemberStatus, SupportedWebAppLanguage } from 'services/constants';
import { updateHtmlLangAttribute } from 'services/i18n';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

const withInitialLogic = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element | null => {
  const api = useImperativeApi();
  const { i18n } = useTranslation();
  const { dispatch } = useGlobalState();
  const { user } = useAuth0();
  const { memberId, lang, partnerCompanyId } = useIdTokenCustomData();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    datadogRum.setUser({
      id: memberId,
      iamId: user!.sub,
      partnerCompanyId: partnerCompanyId,
    });
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        const languageCode = lang.substring(0, 2) as SupportedWebAppLanguage;
        moment.locale(languageCode);
        await i18n.changeLanguage(languageCode);
        updateHtmlLangAttribute(languageCode);

        const member = await api.getPartnerMember(memberId);

        if (member.status === MemberStatus.invited) {
          await api.activatePartnerMember(member.partnerCompanyId, memberId);
          window.location.reload();
          return;
        }

        dispatch({
          type: 'SET_USER_DATA',
          payload: { member },
        });

        setIsLoading(false);
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: error });
        logError(error);
      }
    })();
  }, [memberId]);

  return isLoading ? null : <Component {...props} />;
};

export default withInitialLogic;
