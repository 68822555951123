import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import {
  DownloadPaymentFileCell,
  SettlementGroupPaymentCell,
} from 'domains/billing/components';
import {
  Box,
  DataGridCell,
  GridColDef,
  gridUtils,
  Tooltip,
  Typography,
  WarningCircleIcon,
} from 'elements';
import {
  SettlementTransaction,
  SettlementTransactionPaymentType,
} from 'services/constants';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<SettlementTransaction>[] = [
      {
        renderHeader: () => (
          <DataGridCell>
            {t('int.processingAccountPage.postingDate')}
            <Typography
              noWrap
              variant="caption2"
              color="text.secondary"
              component="div"
            >
              {t('int.processingAccountPage.valueDate')}
            </Typography>
          </DataGridCell>
        ),
        field: 'postingDate',
        renderCell: ({ row }) => (
          <Box overflow="hidden">
            <Typography variant="body2" color="text.primary">
              {row.postingDate}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {row.valueDate}
            </Typography>
          </Box>
        ),
        flex: 125,
        minWidth: 137,
      },
      {
        headerName: '',
        field: 'errorMessage',
        renderCell: ({ row }) =>
          row.errorMessage ? (
            <Tooltip title={row.errorMessage}>
              <WarningCircleIcon color="error" />
            </Tooltip>
          ) : null,
        sortable: false,
        width: 44,
        minWidth: 44,
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('int.processingAccountPage.purpose')}
            <Typography
              noWrap
              variant="caption2"
              color="text.secondary"
              component="div"
            >
              {t('int.processingAccountPage.transactionType')}
            </Typography>
          </DataGridCell>
        ),
        field: 'purpose',
        renderCell: ({ row }) => (
          <DataGridCell>
            <Tooltip title={row.purpose}>
              <Typography variant="inherit" noWrap>
                {row.purpose}
              </Typography>
            </Tooltip>
            <Tooltip title={row.transactionType}>
              <Typography variant="inherit" noWrap color="text.disabled">
                {row.transactionType}
              </Typography>
            </Tooltip>
          </DataGridCell>
        ),
        flex: 210,
        sortable: false,
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('int.processingAccountPage.counterparty')}
            <Typography
              noWrap
              variant="caption2"
              color="text.secondary"
              component="div"
            >
              {t('int.processingAccountPage.iban')}
            </Typography>
          </DataGridCell>
        ),
        field: 'counterpartyName',
        renderCell: ({ row }) => {
          const {
            counterpartyName,
            counterpartyAccountNumber,
            counterpartyBic,
          } = row;
          return (
            <DataGridCell>
              <Tooltip title={counterpartyName}>
                <Typography variant="inherit" noWrap>
                  {counterpartyName || '-'}
                </Typography>
              </Tooltip>
              <Tooltip title={counterpartyBic ? 'BIC: ' + counterpartyBic : ''}>
                <Typography variant="inherit" noWrap color="text.disabled">
                  {counterpartyAccountNumber || '-'}
                </Typography>
              </Tooltip>
            </DataGridCell>
          );
        },
        flex: 185,
        sortable: false,
      },
      {
        headerName: t('int.processingAccountPage.amount'),
        field: 'amount',
        renderCell: ({ row }) => (
          <Box textAlign="right" overflow="hidden">
            <Typography variant="inherit" noWrap>
              <FormatMoney value={row.amount} fractionalPart positiveSign />
            </Typography>
            {row.paymentType ===
              SettlementTransactionPaymentType.billPmtReversal && (
              <Typography variant="caption" color="text.disabled">
                {t('int.processingAccountPage.inclReversalFee')}
              </Typography>
            )}
          </Box>
        ),
        flex: 135,
        minWidth: 125,
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.processingAccountPage.balance'),
        field: 'balance',
        renderCell: ({ row }) => (
          <Typography variant="inherit" noWrap>
            <FormatMoney value={row.balance} fractionalPart positiveSign />
          </Typography>
        ),
        flex: 135,
        minWidth: 125,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.processingAccountPage.paymentTypeId'),
        field: 'paymentType',
        renderCell: ({ row }) => (
          <SettlementGroupPaymentCell
            organizationId={row.organizationId}
            settlementId={row.originatorId}
            publicId={row.publicId}
            type={row.paymentType}
          />
        ),
        flex: 135,
        minWidth: 145,
        sortable: false,
      },
      {
        headerName: t('int.processingAccountPage.statementId'),
        field: 'transactionId',
        renderCell: ({ row }) => (
          <DownloadPaymentFileCell transactionId={row.transactionId}>
            {row.statementId}
          </DownloadPaymentFileCell>
        ),
        flex: 105,
        minWidth: 125,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language]);
};

export default useColumns;
