import { useGlobalState } from 'context/GlobalState';
import { DataGridCell, Typography } from 'elements';
import { ReviewFlagReason, TransactionReviewStatus } from 'services/constants';

interface Props {
  teamName: string | null;
  costCenter: string | null;
  reviewStatus: TransactionReviewStatus | null;
  reviewFlagReason: ReviewFlagReason | null;
}

const TeamCell = ({
  teamName = '—',
  costCenter,
  reviewStatus,
  reviewFlagReason,
}: Props) => {
  const {
    state: { accountingSettings },
  } = useGlobalState();

  if (
    reviewStatus === TransactionReviewStatus.flagged &&
    reviewFlagReason === ReviewFlagReason.privateExpense
  ) {
    return <DataGridCell>—</DataGridCell>;
  }

  return (
    <DataGridCell>
      {teamName}
      {!!accountingSettings?.costCenterEnabled && (
        <Typography
          component="div"
          variant="caption"
          color="textSecondary"
          noWrap
        >
          {costCenter}
        </Typography>
      )}
    </DataGridCell>
  );
};

export default TeamCell;
