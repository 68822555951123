import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  DataGridCell,
  GridColDef,
  IconButton,
  PencilSimpleIcon,
  StatusBadge,
} from 'elements';
import { AccountingItemStatus, VatRate } from 'services/constants';

const useColumns = (onEdit: (item: VatRate) => void) => {
  const { t, i18n } = useTranslation();
  const {
    state: { teams },
  } = useGlobalState();

  return useMemo(() => {
    const columns: GridColDef<VatRate>[] = [
      {
        field: 'name',
        headerName: t('vatRatesSubPage.nameLabel'),
        flex: 1,
        renderCell: ({ row }) => (
          <Box display="flex" alignItems="center" overflow="hidden">
            <DataGridCell>{row.name}</DataGridCell>
            {row.status === AccountingItemStatus.inactive && (
              <StatusBadge
                color="error"
                sx={{ ml: 1, flexShrink: 0 }}
                label={t('vatRatesSubPage.inactiveBadgeLabel')}
              />
            )}
          </Box>
        ),
      },
      {
        field: 'rate',
        sortable: false,
        headerName: t('vatRatesSubPage.rateLabel'),
        flex: 1,
        renderCell: ({ row }) => (
          <FormatPercent
            value={row.rate}
            options={{
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }}
          />
        ),
      },
      {
        field: 'code',
        headerName: t('vatRatesSubPage.codeLabel'),
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 72,
        getActions: ({ row }) => [
          <IconButton onClick={() => onEdit(row)}>
            <PencilSimpleIcon />
          </IconButton>,
        ],
      },
    ];

    return columns;
  }, [i18n.language, teams]);
};

export default useColumns;
