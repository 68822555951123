import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import {
  SettlementTransactionPaymentType,
  SettlementTransactionType,
} from 'services/constants';
import { paymentTypes, QueryParams, transactionTypes } from '.././utils';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  fromDate: Moment | null;
  toDate: Moment | null;
  paymentType: SettlementTransactionPaymentType[];
  transactionType: SettlementTransactionType[];
}

const initialState: State = {
  fromDate: null,
  toDate: null,
  paymentType: [],
  transactionType: [],
};

const FiltersDrawer = ({ params, setParam, ...props }: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>(initialState);

  const areFiltersSelected = !!(
    state.fromDate ||
    state.toDate ||
    state.paymentType.length ||
    state.transactionType.length
  );

  useEffect(() => {
    if (props.open)
      setState({
        fromDate: params.fromDate,
        toDate: params.toDate,
        paymentType: params.paymentType,
        transactionType: params.transactionType,
      });
  }, [props.open]);

  const onFiltersApply = () => {
    setParam(
      Object.entries({
        ...state,
        fromDate: state.fromDate?.format('YYYY-MM-DD'),
        toDate: state.toDate?.format('YYYY-MM-DD'),
      })
    );
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.entries(initialState));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <DateFilter
        label={t('filters.period')}
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate,
            toDate,
          }))
        }
      />

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-payment-type"
          id="panel-payment-type-header"
        >
          <Typography>{t('filters.paymentType')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <FormGroup>
              {paymentTypes.map((type) => (
                <FormControlLabel
                  key={type}
                  checked={state.paymentType.includes(type)}
                  control={<Checkbox />}
                  onChange={() =>
                    setState((prevState) => ({
                      ...prevState,
                      paymentType: xor(prevState.paymentType, [type]),
                    }))
                  }
                  label={
                    <Typography variant="overline">
                      {type.replace(/_/g, ' ')}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-transaction-type"
          id="panel-transaction-type-header"
        >
          <Typography>{t('filters.transactionType')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <FormGroup>
              {transactionTypes.map((type) => (
                <FormControlLabel
                  key={type}
                  checked={state.transactionType.includes(type)}
                  control={<Checkbox />}
                  onChange={() =>
                    setState((prevState) => ({
                      ...prevState,
                      transactionType: xor(prevState.transactionType, [type]),
                    }))
                  }
                  label={
                    <Typography variant="overline">
                      {type.replace(/_/g, ' ')}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </FilterDrawer>
  );
};

export default FiltersDrawer;
