import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Divider, ListItemText, MenuItem } from 'components/Menu';
import { useGlobalState } from 'context/GlobalState';
import useGetAccountingExportDownloadConfig, {
  MenuButtonDownloadFormat,
} from 'domains/billing/components/AccountBillStatementBlockMenu/useGetAccountingExportDownloadConfig';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { GetBillPaymentsCsvParams, NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  downloadZipFile,
  getFileNameFromHeader,
  getGenericErrorMsg,
  getNetworkErrorCode,
} from 'services/utils';

const StyledMenuItem = styled(MenuItem)<{ $isDisabled?: boolean }>`
  min-width: 134px;
  padding-right: 40px;

  ${({ theme, $isDisabled }) =>
    $isDisabled &&
    `
  color: ${theme.colors.grey100};
  cursor: default;
  &:hover{
    background: none;
  }
  `}
`;

interface Props {
  organizationId: string;
  billId?: string;
  organizationAccountStatementId?: string;
}

/** @deprecated will be deleted along with statements page */
const AccountingExportDownloadButtons = ({
  organizationId,
  billId,
  organizationAccountStatementId,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings, organization },
  } = useGlobalState();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const buttonsList = useGetAccountingExportDownloadConfig(organization!.id);

  const getDocument = (
    accountingSystem: GetBillPaymentsCsvParams['accountingSystem'],
    format: MenuButtonDownloadFormat
  ) => {
    const params = { organizationId, billId, organizationAccountStatementId };
    if (format === MenuButtonDownloadFormat.csv)
      return api.getBillPaymentsCsv({ ...params, accountingSystem });
    return api.getBillPaymentsXml(params);
  };

  const downloadBillPaymentViaAccSystem = async (
    accountingSystem: GetBillPaymentsCsvParams['accountingSystem'],
    format: MenuButtonDownloadFormat
  ) => {
    try {
      const response = await getDocument(accountingSystem, format);
      if (!mounted.current || !response) return;
      const fileName = getFileNameFromHeader(response.headers);
      downloadZipFile(fileName, response.data);
    } catch (error) {
      if (!mounted.current) return;
      if (getNetworkErrorCode(error) === NetworkErrorCode.noTransactionsFound) {
        enqueueSnackbar(t('statementItemMenu.noBillPaymentsFound'), {
          variant: 'error',
        });
        return;
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  if (!buttonsList.length) return null;

  return (
    <>
      <Divider />
      {buttonsList.map((item) => (
        <StyledMenuItem
          // the button can't be disabled via
          // disabled prop, as we need to enable hover event
          $isDisabled={!!item.disabled}
          key={item.key}
          onClick={() => {
            if (item.disabled) return;

            downloadBillPaymentViaAccSystem(
              accountingSettings!
                .accountingSystem as GetBillPaymentsCsvParams['accountingSystem'],
              item.format
            );
          }}
        >
          <ListItemText primary={item.label} />
        </StyledMenuItem>
      ))}
    </>
  );
};

export default AccountingExportDownloadButtons;
