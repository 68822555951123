import { useCallback, useEffect, useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { isValueMismatched } from 'domains/transaction/utils';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { VatRate, VatRateStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

export interface VatRateData {
  value: VatRate | VatRateStatus.missing | null;
  isLoading: boolean;
  isError: boolean;
}

export const useVatRateFilter = (vatRateParam: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [vatRateData, setVatRateData] = useState<VatRateData>({
    value: null,
    isLoading: false,
    isError: false,
  });

  const getVatRate = async () => {
    if (vatRateParam === VatRateStatus.missing) {
      setVatRateData((prevState) => ({
        ...prevState,
        value: VatRateStatus.missing,
      }));
      return;
    }

    setVatRateData((prevState) => ({ ...prevState, isLoading: true }));
    try {
      if (!mounted.current) return;
      const { valid, invalid } = await api.checkVatRates({
        organizationId: organization!.id,
        vatRateIds: [vatRateParam],
      });
      setVatRateData((prevState) => ({
        ...prevState,
        value: valid[0] || null,
        isError: !!invalid.length,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setVatRateData((prevState) => ({
        ...prevState,
        isLoading: false,
        isError: true,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), {
        variant: 'error',
      });
      logError(error);
    }
  };

  useEffect(() => {
    // automatically reset state when no param
    if (!vatRateParam) {
      setVatRateData((prevState) => ({
        ...prevState,
        value: null,
        isError: false,
      }));
      return;
    }
    if (isValueMismatched(vatRateParam, vatRateData.value)) getVatRate();
  }, [vatRateParam]);

  const setVatRateFilter = useCallback((data: Pick<VatRateData, 'value'>) => {
    setVatRateData((prevState) => ({
      ...prevState,
      ...data,
      isError: false,
    }));
  }, []);

  return {
    vatRateData,
    setVatRateFilter,
  };
};
