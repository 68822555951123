import React from 'react';
import styled from 'styled-components';
import Earth from 'assets/svg/earth.svg?react';
import Moon from 'assets/svg/moon.svg?react';

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 500px;
  margin-bottom: 40px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.earthLightBlue};
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledEarth = styled(Earth)`
  position: absolute;
  right: -1px;
  bottom: 0;
`;

const StyledMoon = styled(Moon)`
  position: absolute;
  top: 28px;
  left: 170px;
`;

interface Props {
  children: React.ReactNode;
}

const HeroImage = ({ children }: Props) => {
  return (
    <Container>
      <StyledMoon />
      <StyledEarth />
      <Content>{children}</Content>
    </Container>
  );
};

export default HeroImage;
