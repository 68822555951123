import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MISSING_SUPPLIER_OPTION } from 'domains/transaction/pages/ExportPage/utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  CaretDownIcon,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  Supplier,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { SupplierData } from './useFilters';

const MissingInputValue = '—';

const getSupplierName = (supplier: Supplier) =>
  supplier.name +
  (supplier.accountNumber ? ` (${supplier.accountNumber})` : '');

type Value = SupplierData['value'];

interface Props {
  value: Value;
  supplierData: SupplierData;
  onChange: (value: Value) => void;
}

interface State {
  isLoading: boolean;
  suppliers: (typeof MISSING_SUPPLIER_OPTION | Supplier)[];
}

const SupplierFilter = ({ value, supplierData, onChange }: Props) => {
  const isFetchAllowed = useRef(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    suppliers: [],
  });
  const [inputValue, setInputValue] = useState('');

  const getData = async () => {
    try {
      const { suppliers } = await api.getSuppliers({
        organizationId: organization!.id,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
        q: inputValue === MissingInputValue ? '' : inputValue.trim(),
        sortBy: '+name',
        status: AccountingItemStatus.active,
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        suppliers: [MISSING_SUPPLIER_OPTION, ...suppliers],
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (!isFetchAllowed.current) return;

    let active = true;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);
    return () => {
      active = false;
    };
  }, [inputValue.trim()]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-supplier"
        id="panel-supplier-header"
      >
        <Typography>{t('filters.supplier')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Autocomplete<
          Supplier | typeof MISSING_SUPPLIER_OPTION,
          false,
          false,
          false
        >
          onOpen={() => {
            if (!isFetchAllowed.current) {
              setState((prevState) => ({ ...prevState, isLoading: true }));
              getData();
              isFetchAllowed.current = true;
            }
          }}
          inputValue={inputValue}
          loading={state.isLoading}
          size="medium"
          onInputChange={(e, inputValue) => {
            setInputValue(inputValue);
          }}
          error={supplierData.isError}
          value={value}
          onChange={(e, value) => {
            onChange(value);
          }}
          options={state.suppliers}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => {
            if (
              option === MISSING_SUPPLIER_OPTION ||
              value === MISSING_SUPPLIER_OPTION
            )
              return value === option;
            return option?.id === value?.id;
          }}
          getOptionLabel={(option) => {
            if (!option) return '';
            if (option === MISSING_SUPPLIER_OPTION) return MissingInputValue;
            return option.name;
          }}
          renderOption={(props, option) => {
            if (!option) return '';
            if (option === MISSING_SUPPLIER_OPTION)
              return (
                <li {...props} key={option}>
                  —
                </li>
              );
            return (
              <li {...props} key={option.id}>
                <Box
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {getSupplierName(option)}
                </Box>
              </li>
            );
          }}
          placeholder={
            supplierData.isError ? t('filters.notFound') : t('filters.supplier')
          }
          loadingText={t('common.loading')}
          noOptionsText={t('common.nothingFound')}
          searchIcon={false}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default SupplierFilter;
