import { useTranslation } from 'react-i18next';

interface Props {
  value: number;
  fractionalPart?: boolean;
  percentSign?: boolean;
  options?: Intl.NumberFormatOptions;
}

const FormatPercent = ({
  value,
  fractionalPart = true,
  percentSign = true,
  options = {},
}: Props) => {
  const { i18n } = useTranslation();

  return (
    <>
      {value.toLocaleString(i18n.language, {
        minimumFractionDigits: fractionalPart ? 2 : 0,
        maximumFractionDigits: fractionalPart ? 2 : 0,
        ...options,
      })}
      {percentSign && '%'}
    </>
  );
};

export default FormatPercent;
