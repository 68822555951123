import { useTranslation } from 'react-i18next';
import { Address } from 'services/constants';

const AddressDetails = ({ address }: { address: Address }) => {
  const { t } = useTranslation();

  return (
    <>
      {address.streetName} {address.streetNumber}
      <br />
      {!!address.streetAdditional && (
        <>
          {address.streetAdditional} <br />
        </>
      )}
      {address.postalCode} {address.city} <br />
      {t(`countries.${address.country}`)}
    </>
  );
};

export default AddressDetails;
