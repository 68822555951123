import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { DataItemRow } from 'domains/creditAndCompliance/components';
import WebsiteValueWithValidation from 'domains/creditAndCompliance/components/WebsiteValueWithValidation';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  generateLabelFromSlug,
  getLatestUpdateMetaOfValues,
  parseAndReformatDateStr,
} from 'domains/creditAndCompliance/utils';
import { Box, EditButton, Grid, Typography } from 'elements';
import { SupportedCountry } from 'services/constants';
import EditMasterDataDialog from './EditMasterDataDialog';

const MasterDataSectionSection = () => {
  const { t } = useTranslation();
  const {
    state: { organization, organizationsStaticData },
  } = useGlobalState();
  const {
    state: { complianceCompany, spanishProvinces },
  } = useCreditAndComplianceContext();
  const masterData = complianceCompany!.masterData;

  const [isEditMasterDataDialog, setIsEditMasterDataDialog] = useState(false);

  const latestBillingAddressUpdate = useMemo(
    () => getLatestUpdateMetaOfValues(Object.values(masterData.billingAddress)),
    [masterData]
  );

  const latestRegistrationUpdate = useMemo(
    () =>
      getLatestUpdateMetaOfValues([
        masterData.country,
        masterData.nationalRegisterCourt,
        masterData.tradeRegisterNumber,
      ]),
    [masterData]
  );

  const latestIndustryUpdate = useMemo(
    () =>
      getLatestUpdateMetaOfValues([
        masterData.industryCode,
        masterData.industryCodeSource,
      ]),
    [masterData]
  );

  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        py={1}
      >
        <Typography mr={1} variant="h6">
          {t('int.complianceCompanyMasterDataSection.title')}
        </Typography>

        <EditButton onClick={() => setIsEditMasterDataDialog(true)} />
      </Box>

      <Grid container direction="column" spacing={2} py={3}>
        <DataItemRow
          label={t(
            'int.complianceCompanyMasterDataSection.registerExcerptDate'
          )}
          updatedAt={masterData.registerExcerptDate.updatedAt}
          updatedBy={masterData.registerExcerptDate.updatedBy}
          value={
            [
              !!masterData.registerExcerptDate.value &&
                parseAndReformatDateStr(
                  masterData.registerExcerptDate.value,
                  'DD.MM.YYYY'
                ),
              typeof masterData.registerExcerptAge.value === 'number' &&
                `(${t('common.duration.month', {
                  count: masterData.registerExcerptAge.value,
                })})`,
            ]
              .filter(Boolean)
              .join(' ') || null
          }
        />
        <DataItemRow
          label={t('int.complianceCompanyMasterDataSection.legalName')}
          updatedAt={masterData.legalName.updatedAt}
          updatedBy={masterData.legalName.updatedBy}
          value={masterData.legalName.value}
        />
        <DataItemRow
          label={t('int.complianceCompanyMasterDataSection.riskUnitGroup')}
          updatedAt={masterData.riskUnitGroupName?.updatedAt}
          updatedBy={masterData.riskUnitGroupName?.updatedBy}
          value={masterData.riskUnitGroupName?.value}
        />
        <DataItemRow
          label={t('int.complianceCompanyMasterDataSection.legalForm')}
          updatedAt={masterData.legalForm.updatedAt}
          updatedBy={masterData.legalForm.updatedBy}
          value={masterData.legalForm.value}
        />
        <DataItemRow
          label={t('int.complianceCompanyMasterDataSection.tradeName')}
          updatedAt={masterData.tradeName.updatedAt}
          updatedBy={masterData.tradeName.updatedBy}
          value={masterData.tradeName.value}
        />
        <DataItemRow
          label={t('int.complianceCompanyMasterDataSection.registration')}
          updatedAt={latestRegistrationUpdate.updatedAt}
          updatedBy={latestRegistrationUpdate.updatedBy}
          value={[
            masterData.country.value,
            masterData.nationalRegisterCourt.value,
            masterData.tradeRegisterNumber.value,
          ]
            .filter(Boolean)
            .join(' - ')}
        />
        <DataItemRow
          label={t('int.complianceCompanyMasterDataSection.billingAddress')}
          updatedAt={latestBillingAddressUpdate.updatedAt}
          updatedBy={latestBillingAddressUpdate.updatedBy}
          value={
            <span>
              {masterData.billingAddress.streetName.value}{' '}
              {masterData.billingAddress.streetNumber.value}
              <br />
              {!!masterData.billingAddress.streetAdditional.value && (
                <>
                  {masterData.billingAddress.streetAdditional.value} <br />
                </>
              )}
              {masterData.billingAddress.postalCode.value}{' '}
              {masterData.billingAddress.city.value} <br />
              {t(`countries.${masterData.billingAddress.country.value}`)}
            </span>
          }
        />
        {organization!.billingAddress.country === SupportedCountry.es && (
          <DataItemRow
            label={t('int.complianceCompanyMasterDataSection.province')}
            updatedAt={masterData.spanishProvinceCode.updatedAt}
            updatedBy={masterData.spanishProvinceCode.updatedBy}
            value={
              masterData.spanishProvinceCode.value
                ? `${
                    spanishProvinces!.find(
                      (item) =>
                        item.code === masterData.spanishProvinceCode.value
                    )!.name
                  } (${String(masterData.spanishProvinceCode.value).padStart(
                    2,
                    '0'
                  )})`
                : null
            }
          />
        )}
        <DataItemRow
          label={t('int.complianceCompanyMasterDataSection.foundationDate')}
          updatedAt={masterData.foundationDate.updatedAt}
          updatedBy={masterData.foundationDate.updatedBy}
          value={
            [
              !!masterData.foundationDate.value &&
                parseAndReformatDateStr(
                  masterData.foundationDate.value,
                  'DD.MM.YYYY'
                ),
              typeof masterData.organizationAge.value === 'number' &&
                `(${t('common.duration.year', {
                  count: masterData.organizationAge.value,
                })})`,
            ]
              .filter(Boolean)
              .join(' ') || null
          }
        />
        <DataItemRow
          label={t('int.complianceCompanyMasterDataSection.industry')}
          updatedAt={latestIndustryUpdate.updatedAt}
          updatedBy={latestIndustryUpdate.updatedBy}
          value={
            [
              masterData.industryCode.value,
              (masterData.industryCodeDescription ||
                masterData.industryCodeSource.value) &&
                '-',
              masterData.industryCodeDescription &&
                masterData.industryCodeDescription,
              masterData.industryCodeSource.value &&
                `(${
                  organizationsStaticData!.industryCodeSources.find(
                    (item) => item.name === masterData.industryCodeSource.value
                  )?.label ?? '-'
                })`,
              masterData.industryCodeType.value &&
                `\n${masterData.industryCodeType.value}`,
            ]
              .filter(Boolean)
              .join(' ') || null
          }
        />
        <DataItemRow
          label={t('int.complianceCompanyMasterDataSection.industryInternal')}
          updatedAt={masterData.internalIndustrySubtype.updatedAt}
          updatedBy={masterData.internalIndustrySubtype.updatedBy}
          value={
            masterData.internalIndustryCode &&
            masterData.internalIndustryType.value &&
            masterData.internalIndustrySubtype.value &&
            `${masterData.internalIndustryCode} - ${generateLabelFromSlug(
              masterData.internalIndustrySubtype.value
            )}`
          }
        />
        <DataItemRow
          label={t('int.complianceCompanyMasterDataSection.vatId')}
          updatedAt={masterData.vatId.updatedAt}
          updatedBy={masterData.vatId.updatedBy}
          value={masterData.vatId.value}
        />
        <DataItemRow
          label={t('int.complianceCompanyMasterDataSection.website')}
          updatedAt={masterData.website.updatedAt}
          updatedBy={masterData.website.updatedBy}
          value={
            masterData.website.value && (
              <WebsiteValueWithValidation value={masterData.website.value} />
            )
          }
        />
      </Grid>

      <EditMasterDataDialog
        open={isEditMasterDataDialog}
        onClose={() => setIsEditMasterDataDialog(false)}
      />
    </>
  );
};

export default MasterDataSectionSection;
