import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import { AccountingAutocomplete } from 'domains/transaction/components';
import AutoMatchedSelectIndicator from 'domains/transaction/pages/TransactionDetailsPage/AutoMatchedSelectIndicator';
import { Box, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  AccountingTransaction,
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  VatRate,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const mapVatRateToValue = (
  accountingTransaction: AccountingTransaction,
  organizationId: string
) => {
  return accountingTransaction.vatRateId
    ? ({
        id: accountingTransaction.vatRateId,
        name: accountingTransaction.vatRateName,
        rate: accountingTransaction.vatRate,
        code: accountingTransaction.vatRateCode,
        organizationId,
        // status doesn't matter, as we only map the value to show in Autocomplete
        status: AccountingItemStatus.active,
      } as VatRate)
    : null;
};

interface Props {
  isAutoMatched: boolean;
  organizationId: string;
  merchantId?: string;
  isExportPage: boolean;
  onChange: (vatRate: VatRate | null) => void;
  accountingTransaction: AccountingTransaction;
  disabled: boolean;
}

interface State {
  isLoading: boolean;
  vatRates: VatRate[];
  value: VatRate | null;
}

const VatRatesSelect = ({
  isAutoMatched,
  organizationId,
  merchantId,
  isExportPage,
  accountingTransaction,
  onChange,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    vatRates: [],
    isLoading: true,
    value: mapVatRateToValue(accountingTransaction, organization!.id),
  });
  const [inputValue, setInputValue] = useState(
    accountingTransaction.vatRateName ?? ''
  );

  const getData = async () => {
    try {
      const { vatRates } = await api.getVatRates({
        organizationId: organization!.id,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
        q: inputValue.trim(),
        sortBy: '+name',
        status: AccountingItemStatus.active,
      });
      if (!mounted.current) return;

      setState((prevState) => ({
        ...prevState,
        vatRates,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (disabled) return;
    let active = true;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);
    return () => {
      active = false;
    };
  }, [inputValue.trim()]);

  useEffect(() => {
    if (accountingTransaction.vatRateName !== inputValue)
      setInputValue(accountingTransaction.vatRateName ?? '');
    setState((prevState) => ({
      ...prevState,
      value: mapVatRateToValue(accountingTransaction, organization!.id),
    }));
  }, [accountingTransaction.vatRateId]);

  return (
    <Box mb={1}>
      <AccountingAutocomplete<VatRate, true>
        // remove MUI warning about missing value in options, as it's expected
        freeSolo
        label={
          <Box display="flex" alignItems="center">
            <Box overflow="hidden">
              <Typography variant="body2" noWrap>
                {t('transactionDetails.vatRateSelectLabel')}
              </Typography>
              {isExportPage && (
                <Typography
                  noWrap
                  component="div"
                  variant="caption"
                  color="textSecondary"
                >
                  {t('transactionDetails.vatCode')}
                </Typography>
              )}
            </Box>

            <AutoMatchedSelectIndicator
              isAutoMatched={isAutoMatched}
              organizationId={organizationId}
              merchantId={merchantId}
            />
          </Box>
        }
        selectedValueComponent={
          state.value ? (
            <Box overflow="hidden" pl={2}>
              <Typography variant="body2" component="div" noWrap>
                {state.value.name || ''}
                <FormatPercent
                  value={state.value.rate}
                  options={{
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }}
                />
              </Typography>
              {isExportPage && state.value.code && (
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                  noWrap
                >
                  {state.value.code}
                </Typography>
              )}
            </Box>
          ) : (
            '—'
          )
        }
        data-test-id="vat-rates-autocomplete"
        disabled={disabled}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        value={state.value}
        options={state.vatRates}
        onChange={(_, option) => {
          if (!option) setInputValue('');
          if (typeof option === 'string') return;
          onChange(option as VatRate);
        }}
        onClear={() => {
          setInputValue('');
          if (state.value) onChange(null);
          setState((prevState) => ({ ...prevState, value: null }));
        }}
        loading={state.isLoading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (option as VatRate).name || ''}
        filterOptions={(x) => x}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <Box overflow="hidden">
                <Typography noWrap>
                  {option.name} (
                  <FormatPercent
                    value={option.rate}
                    options={{
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    }}
                  />
                  )
                </Typography>
                {isExportPage && option.code && (
                  <Typography
                    noWrap
                    component="div"
                    variant="caption"
                    color="textSecondary"
                  >
                    {option.code}
                  </Typography>
                )}
              </Box>
            </li>
          );
        }}
      />
    </Box>
  );
};

export default VatRatesSelect;
