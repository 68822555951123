import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryParams } from 'domains/transaction/pages/ExportPage/utils';
import { Typography } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { PageFiltersComponent } from 'layout';
import FilterChips from './FilterChips';
import FiltersDrawer from './FiltersDrawer';
import useFilters from './useFilters';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  disabled: boolean;
  transactionsCount: number;
}

const Filters = ({
  params,
  selectedFiltersCount,
  setParam,
  disabled,
  transactionsCount,
}: Props) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const {
    vatRateData,
    supplierData,
    projectData,
    teamData,
    subcategoryData,
    areFiltersLoading,
    setDataFilters,
  } = useFilters({
    vatRate: params.vatRate,
    supplier: params.supplier,
    project: params.project,
    team: params.team,
    subcategory: params.subcategory,
  });

  return (
    <>
      <PageFiltersComponent
        count={selectedFiltersCount}
        q={params.q}
        setQParam={(value) => setParam('q', value)}
        onOpen={() => setIsFilterOpen(true)}
        disabled={disabled || areFiltersLoading}
      >
        <Typography variant="body2" color="textSecondary" ml={2}>
          {t('common.resultsTable', { count: transactionsCount })}
        </Typography>
      </PageFiltersComponent>

      <FilterChips
        params={params}
        selectedFiltersCount={selectedFiltersCount}
        setParam={setParam}
        supplierData={supplierData}
        vatRateData={vatRateData}
        projectData={projectData}
        subcategoryData={subcategoryData}
        teamData={teamData}
      />

      <FiltersDrawer
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        params={params}
        supplierData={supplierData}
        vatRateData={vatRateData}
        projectData={projectData}
        teamData={teamData}
        subcategoryData={subcategoryData}
        setParam={setParam}
        setFilters={setDataFilters}
      />
    </>
  );
};

export default Filters;
