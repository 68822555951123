import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import InfiniteScroll from 'components/InfiniteScroll';
import { CaretDownIcon, CircularProgress, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Card,
  CardHistoryEntry,
  cardHistoryEntryTypes,
  CardNewType,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import Entry from './Entry';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './style';

export const COLLAPSED_CARD_HISTORY_HEIGHT = 48;

// Filter out entries with not supported types in order not to break UI if BE is deployed first
const filterEntries = (entries: CardHistoryEntry[], cardType: CardNewType) =>
  entries
    .filter((item) => cardHistoryEntryTypes.includes(item.type))
    .map((item) => ({ ...item, cardType }));

interface State {
  isExpanded: boolean;
  isLoading: boolean;
  hasNextPage: boolean;
  entries: CardHistoryEntry[];
}

interface Props {
  card: Card;
  contentHeight: number;
  isCardholderApp?: boolean;
}

const CardHistory = ({ card, contentHeight, isCardholderApp }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const scrollableListRef = useRef<FixedSizeList>(null);
  const isAnimatingRef = useRef(false);
  const pageRef = useRef(0);
  const [state, setState] = useState<State>({
    isExpanded: false,
    entries: [],
    hasNextPage: false,
    isLoading: false,
  });

  const getData = async (page: number, isLoadMore = false) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
      }
      const { entries, hasNextPage } = await api.getCardHistory(card.cardId, {
        page,
        limit: DEFAULT_PAGE_LIMIT,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        entries: isLoadMore
          ? [...prevState.entries, ...filterEntries(entries, card.cardType)]
          : filterEntries(entries, card.cardType),
        hasNextPage,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: true }));
      logError(error);
    }
  };

  const loadInitialItems = () => {
    if (scrollableListRef.current) scrollableListRef.current.scrollTo(0);
    pageRef.current = 0;
    getData(pageRef.current);
  };

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, true);
  };

  useEffect(() => {
    if (state.isExpanded) loadInitialItems();
  }, [card]);

  return (
    <StyledAccordion
      expanded={state.isExpanded}
      onChange={(e, isExpanded) => {
        if (isAnimatingRef.current || state.isLoading) return;
        isAnimatingRef.current = true;
        setState((prevState) => ({ ...prevState, isExpanded }));
        if (isExpanded) loadInitialItems();
      }}
      TransitionProps={{
        onEntered: () => (isAnimatingRef.current = false),
        onExited: () => (isAnimatingRef.current = false),
      }}
      className={isCardholderApp ? 'card-history-cardholder-app' : ''}
      square={true}
    >
      <StyledAccordionSummary
        expandIcon={<CaretDownIcon />}
        sx={{ height: COLLAPSED_CARD_HISTORY_HEIGHT }}
      >
        <Typography mr={2}>{t('cardHistory.title')}</Typography>
        {state.isLoading && <CircularProgress size="small" />}
      </StyledAccordionSummary>
      <StyledAccordionDetails sx={{ height: contentHeight }}>
        <InfiniteScroll<CardHistoryEntry>
          data={state.entries}
          hasNextPage={state.hasNextPage}
          loadNextPage={loadMoreItems}
          height={contentHeight}
          itemHeight={72}
          scrollableListRef={scrollableListRef}
        >
          {Entry}
        </InfiniteScroll>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default CardHistory;
