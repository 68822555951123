import { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  InputLabelTooltipIcon,
  Link,
  ListItemText,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Switch,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import useUrls from 'hooks/useUrls';
import {
  BookingTextMode,
  DatevCostMode,
  ReceiptDateExportMode,
  receiptDateExportModes,
  ReceiptNumberExportMode,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues {
  datevCostMode: DatevCostMode;
  receiptNumberExportMode: ReceiptNumberExportMode;
  receiptDateExportMode: ReceiptDateExportMode;
  datevUsePaymentBookingTextPrefix: boolean;
  datevBookingTextMode: BookingTextMode;
  datevUseInformationField: boolean;
}

const DatevExportSettingsDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, accountingSettings },
  } = useGlobalState();
  const {
    HELP_CENTER_RECEIPT_NUMBER_IN_DATEV_EXPORT_URL,
    HELP_CENTER_RECEIPT_DATE_IN_EXPORT_URL,
  } = useUrls();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(
    false
  );

  const receiptDateExportModesArray = useMemo(
    () =>
      accountingSettings!.useSupplierAccount
        ? receiptDateExportModes
        : receiptDateExportModes.filter(
            (item) => item !== ReceiptDateExportMode.dynamic
          ),
    [accountingSettings!.useSupplierAccount]
  );

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      datevCostMode: accountingSettings!.datevCostMode,
      receiptNumberExportMode: accountingSettings!.receiptNumberExportMode,
      receiptDateExportMode: accountingSettings!.receiptDateExportMode,
      datevUsePaymentBookingTextPrefix: accountingSettings!
        .datevUsePaymentBookingTextPrefix,
      datevBookingTextMode: accountingSettings!.datevBookingTextMode,
      datevUseInformationField: accountingSettings!.datevUseInformationField,
    },
    onSubmit: async (
      {
        datevCostMode,
        datevBookingTextMode,
        datevUsePaymentBookingTextPrefix,
        datevUseInformationField,
        receiptNumberExportMode,
        receiptDateExportMode,
      },
      { setSubmitting }
    ) => {
      try {
        const accountingSettings = await api.updateDatevAccountingSettings({
          organizationId: organization!.id,
          datevCostMode,
          datevBookingTextMode,
          datevUsePaymentBookingTextPrefix,
          datevUseInformationField,
          receiptNumberExportMode,
          receiptDateExportMode,
        });

        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { accountingSettings },
        });
        if (!mounted.current) return;
        setIsConfirmationDialogOpen(false);
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        setIsConfirmationDialogOpen(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  if (isConfirmationDialogOpen)
    return (
      <ConfirmDialog
        open
        loading={formik.isSubmitting}
        onClose={() => setIsConfirmationDialogOpen(false)}
        onSuccess={() => formik.handleSubmit()}
        title={t('datevSubPage.updateReceiptDateConfirmDialog.title')}
        description={t(
          'datevSubPage.updateReceiptDateConfirmDialog.description'
        )}
        confirmButtonProps={{ children: t('common.button.update') }}
      />
    );

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('datevExportSettingsGroup.datevSettings')}</DialogTitle>

      <DialogContent>
        <form
          onSubmit={(e) =>
            formik.values.receiptDateExportMode !==
            accountingSettings!.receiptDateExportMode
              ? setIsConfirmationDialogOpen(true)
              : formik.handleSubmit(e)
          }
          id="datev-export-settings-change"
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth disabled={formik.isSubmitting}>
                <InputLabel id="kost-select-label">
                  {t('datevSubPage.datevExportSettings.kost1')}
                </InputLabel>
                <Select
                  labelId="kost-select-label"
                  renderValue={(v) => t(`datevCostModes.${v}`)}
                  {...formik.getFieldProps('datevCostMode')}
                >
                  {Object.values(DatevCostMode).map((v) => (
                    <MenuItem key={v} value={v}>
                      {t(`datevCostModes.${v}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={t('datevSubPage.datevExportSettings.kost2')}
                disabled
                value={
                  formik.values.datevCostMode === DatevCostMode.standard
                    ? t(`datevCostModes.SWAP`)
                    : t(`datevCostModes.STANDARD`)
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth disabled={formik.isSubmitting}>
                <InputLabel id="auto-gen-receipt-label">
                  {t(
                    'datevSubPage.datevExportSettings.autoGenerateReceiptNumber'
                  )}
                  <InputLabelTooltipIcon
                    title={
                      <Trans
                        i18nKey="datevSubPage.datevExportSettings.autoGenerateReceiptNumberTooltip"
                        components={{
                          linkTo: (
                            <Link
                              href={
                                HELP_CENTER_RECEIPT_NUMBER_IN_DATEV_EXPORT_URL
                              }
                              rel="noopener noreferrer"
                              target="_blank"
                              color="inherit"
                            />
                          ),
                        }}
                      />
                    }
                  />
                </InputLabel>
                <Select
                  labelId="auto-gen-receipt-label"
                  renderValue={(v) =>
                    t(
                      `datevSubPage.datevExportSettings.autoGenerateReceiptNumberModes.${v}`
                    )
                  }
                  {...formik.getFieldProps('receiptNumberExportMode')}
                >
                  {Object.values(ReceiptNumberExportMode).map((v) => (
                    <MenuItem key={v} value={v}>
                      {t(
                        `datevSubPage.datevExportSettings.autoGenerateReceiptNumberModes.${v}`
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth disabled={formik.isSubmitting}>
                <InputLabel id="receipt-date-label">
                  {t('datevSubPage.datevExportSettings.receiptDateExport')}
                  <InputLabelTooltipIcon
                    title={
                      <Trans
                        i18nKey="datevSubPage.datevExportSettings.receiptDateExportTooltip"
                        components={{
                          linkTo: (
                            <Link
                              href={HELP_CENTER_RECEIPT_DATE_IN_EXPORT_URL}
                              rel="noopener noreferrer"
                              target="_blank"
                              color="inherit"
                            />
                          ),
                        }}
                      />
                    }
                  />
                </InputLabel>
                <Select
                  labelId="receipt-date-label"
                  renderValue={(v) =>
                    t(
                      `datevSubPage.datevExportSettings.receiptDateExportMode.${v}`
                    )
                  }
                  {...formik.getFieldProps('receiptDateExportMode')}
                >
                  {receiptDateExportModesArray.map((v) => (
                    <MenuItem key={v} value={v}>
                      {t(
                        `datevSubPage.datevExportSettings.receiptDateExportMode.${v}`
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth disabled={formik.isSubmitting}>
                <InputLabel id="booking-text-label">
                  {t('datevSubPage.datevExportSettings.bookingText')}
                </InputLabel>
                <Select
                  labelId="booking-text-label"
                  renderValue={(v) =>
                    t(`datevSubPage.datevExportSettings.bookingTextMode.${v}`)
                  }
                  {...formik.getFieldProps('datevBookingTextMode')}
                >
                  {[
                    BookingTextMode.SUPPLIER_OR_MERCHANT_AND_COMMENT,
                    BookingTextMode.SUPPLIER_OR_MERCHANT,
                    BookingTextMode.COMMENT,
                  ].map((v) => (
                    <MenuItem key={v} value={v}>
                      <ListItemText
                        primary={t(
                          `datevSubPage.datevExportSettings.bookingTextMode.${v}`
                        )}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                disabled={formik.isSubmitting}
                checked={formik.values.datevUsePaymentBookingTextPrefix}
                {...formik.getFieldProps('datevUsePaymentBookingTextPrefix')}
                control={<Switch />}
                label={t(
                  'datevSubPage.datevExportSettings.paymentBookingTextPrefix'
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                disabled={formik.isSubmitting}
                checked={formik.values.datevUseInformationField}
                {...formik.getFieldProps('datevUseInformationField')}
                control={<Switch />}
                label={t(
                  'datevSubPage.datevExportSettings.useInformationField'
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          form="datev-export-settings-change"
          type="submit"
          disabled={!formik.dirty || formik.isSubmitting}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(DatevExportSettingsDialog);
