import { PartnerScope } from 'services/constants';

export const PARTNER_AUTH_SESSION_KEY = 'PARTNER_AUTH_SESSION_KEY';
export const PARTNER_AUTH_SESSION_VALUE = 'IN_PROGRESS';

// Scopes for source partners, which allow partners to authorize
// any partner, including themselves.
export const PARTNER_ANY_AUTH_SCOPES = [
  PartnerScope.customer,
  PartnerScope.whitelabel,
];

// Scopes for source partners, which allow partners to authorize
// themselves. E.g. {PARTNER_NAME} with scope embeddedWallet
// can navigate to PartherAuthPage and connect {PARTNER_NAME}.
export const PARTNER_OWN_AUTH_SCOPES = [
  PartnerScope.embeddedWallet,
  PartnerScope.fullyEmbedded,
];
