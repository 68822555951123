import MicrosoftDynamics365Logo from 'assets/svg/integrations/microsoftDynamics365Logo.svg?react';
import OracleNetsuiteLogo from 'assets/svg/integrations/oracleNetsuiteLogo.svg?react';
import QuickbooksLogo from 'assets/svg/integrations/quickbooksLogo.svg?react';
import XeroLogo from 'assets/svg/integrations/xeroLogo.svg?react';
import { useGlobalState } from 'context/GlobalState';
import { AccountingSystem } from 'services/constants';

export const AccountingSystemLogo = () => {
  const {
    state: { accountingSettings },
  } = useGlobalState();

  // logos should come from BE in future
  if (accountingSettings!.accountingSystem === AccountingSystem.quickbooks)
    return <QuickbooksLogo />;

  if (
    accountingSettings!.accountingSystem ===
    AccountingSystem.microsoftDynamics365BusinessCentral
  )
    return <MicrosoftDynamics365Logo />;

  if (accountingSettings!.accountingSystem === AccountingSystem.xero)
    return <XeroLogo />;

  if (accountingSettings!.accountingSystem === AccountingSystem.oracleNetSuite)
    return <OracleNetsuiteLogo />;

  return null;
};
