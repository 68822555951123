import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { BillPaymentType } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface State {
  isLoading: boolean;
  billPaymentTransferType: BillPaymentType;
}

const EditBillPaymentTransferTypeDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, defaultCardAccount },
    dispatch,
  } = useGlobalState();
  const {
    actions: { refetchCompleteContext },
  } = useCreditAndComplianceContext();

  const [state, setState] = useState<State>({
    isLoading: false,
    billPaymentTransferType: defaultCardAccount!.billPaymentTransferType.value,
  });

  const updateBillPaymentTransferType = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const updatedOrganization = await api.updateBillPaymentTransferType(
        organization!.id,
        state.billPaymentTransferType
      );
      const { cardAccounts: updatedCardAccounts } = await api.getCardAccounts(
        organization!.id
      );

      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          organization: updatedOrganization,
          cardAccounts: updatedCardAccounts,
          defaultCardAccount: updatedCardAccounts.find(
            (item) => item.defaultAccount.value
          )!,
        },
      });

      await refetchCompleteContext();

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const canBeSubmitted =
    state.billPaymentTransferType !==
      defaultCardAccount!.billPaymentTransferType.value && !state.isLoading;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t(
          'int.creditAndCompliancePageHeader.billPaymentTransferTypeSection.title'
        )}
      </DialogTitle>

      <DialogContent>
        <FormControl variant="standard">
          <FormLabel>
            {t(
              'int.creditAndCompliancePageHeader.billPaymentTransferTypeSection.selectBillPaymentTransferType'
            )}
          </FormLabel>
          <RadioGroup
            row
            value={state.billPaymentTransferType}
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                billPaymentTransferType: e.target.value as BillPaymentType,
              }));
            }}
          >
            <FormControlLabel
              control={<Radio />}
              disabled={state.isLoading}
              label={t(
                `int.creditAndCompliancePageHeader.billPaymentTransferTypeSection.${BillPaymentType.directDebit}`
              )}
              value={BillPaymentType.directDebit}
            />
            <FormControlLabel
              control={<Radio />}
              disabled={state.isLoading}
              label={t(
                `int.creditAndCompliancePageHeader.billPaymentTransferTypeSection.${BillPaymentType.wireTransfer}`
              )}
              value={BillPaymentType.wireTransfer}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={!canBeSubmitted}
          onClick={updateBillPaymentTransferType}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditBillPaymentTransferTypeDialog);
