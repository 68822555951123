import { PaymentIntent, PaymentIntentResult } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import useSnackbar from 'hooks/useSnackbar';
import { PrivateExpenseStatus } from 'services/constants';
import useImperativeApi from 'services/network/useImperativeApi';

const STRIPE_SUCCESS_STATUSES: PaymentIntent.Status[] = [
  'succeeded',
  'requires_capture',
];
const POLL_INITIAL_INTERVAL = 1000;
const POLL_MAX_ATTEMPTS = 5;

const usePaymentIntentFinalStateHandler = (transactionId: string) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();

  // The status is updated asynchronously by Stripe hooks
  const waitForTxPrivateExpenseStatusUpdate = async () => {
    let attempts = 0;
    let interval = POLL_INITIAL_INTERVAL;

    while (attempts < POLL_MAX_ATTEMPTS) {
      attempts++;
      try {
        const transaction = await api.getTransaction(transactionId);
        if (
          transaction.privateExpenseStatus !==
          PrivateExpenseStatus.reimbursementOutstanding
        )
          return;
      } catch {
        // Retry or finish polling silently in case of an error
      }

      if (attempts < POLL_MAX_ATTEMPTS) {
        await new Promise((resolve) => setTimeout(resolve, interval));
        interval *= 2;
      }
    }
  };

  return async (
    { paymentIntent, error }: PaymentIntentResult,
    onSuccess: () => void,
    onError?: () => void
  ) => {
    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      if (onError) onError();
    } else if (
      paymentIntent &&
      STRIPE_SUCCESS_STATUSES.includes(paymentIntent.status)
    ) {
      await waitForTxPrivateExpenseStatusUpdate();
      enqueueSnackbar(t('transactionDetails.reimbursementDialog.successMsg'), {
        variant: 'success',
      });
      onSuccess();
    } else {
      enqueueSnackbar(t('errors.general'), { variant: 'error' });
      if (onError) onError();
    }
  };
};

export default usePaymentIntentFinalStateHandler;
