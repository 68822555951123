import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { TransactionSimpleTypeLabel } from 'domains/transaction/components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from 'elements';
import {
  AccountEntryBillPaymentType,
  AccountEntryType,
  TransactionSimpleType,
} from 'services/constants';

enum AccountEntrySubType {
  billPayment = 'BILL_PAYMENT',
  prefundingPayment = 'PREFUNDING_PAYMENT',
}

export const visibleAccountEntryTypes = [
  TransactionSimpleType.purchase,
  TransactionSimpleType.refund,
  TransactionSimpleType.chargeback,
  TransactionSimpleType.recharge,
  AccountEntryType.reimbursement,
  // AccountEntryResponse.billPaymentInfo.billPaymentType uses old historical values(BILL, PREFUNDING)
  // but in subType request param we use new values(BILL_PAYMENT, PREFUNDING_PAYMENT)
  AccountEntrySubType.billPayment,
  AccountEntrySubType.prefundingPayment,
  AccountEntryBillPaymentType.payout,
  AccountEntryBillPaymentType.internalTransfer,
  AccountEntryBillPaymentType.billPaymentReversal,
  AccountEntryBillPaymentType.directDebitReversalFee,
  AccountEntryType.other,
];

export type AccountEntriesSubTypeFilterOption = typeof visibleAccountEntryTypes[number];

export const getLabelByType = (
  type: AccountEntriesSubTypeFilterOption,
  t: TFunction
) => {
  if (type === AccountEntrySubType.billPayment) {
    return t(`accountEntryBillPaymentType.${AccountEntryBillPaymentType.bill}`);
  }
  if (type === AccountEntrySubType.prefundingPayment) {
    return t(
      `accountEntryBillPaymentType.${AccountEntryBillPaymentType.prefunding}`
    );
  }
  if (type === AccountEntryBillPaymentType.payout) {
    return t(
      `accountEntryBillPaymentType.${AccountEntryBillPaymentType.payout}`
    );
  }
  if (type === AccountEntryBillPaymentType.internalTransfer) {
    return t(
      `accountEntryBillPaymentType.${AccountEntryBillPaymentType.internalTransfer}`
    );
  }
  if (type === AccountEntryBillPaymentType.billPaymentReversal) {
    return t(
      `accountEntryBillPaymentType.${AccountEntryBillPaymentType.billPaymentReversal}`
    );
  }
  if (type === AccountEntryBillPaymentType.directDebitReversalFee) {
    return t(
      `accountEntryBillPaymentType.${AccountEntryBillPaymentType.directDebitReversalFee}`
    );
  }
  if (type === AccountEntryType.reimbursement) {
    return t('common.badge.companyReimbursement');
  }
  if (type === AccountEntryType.other) {
    return t('accountingPage.otherPayment');
  }

  return t(`transactionSimpleTypes.${type}`);
};

interface Props {
  value: AccountEntriesSubTypeFilterOption[];
  onChange: (
    value: AccountEntriesSubTypeFilterOption,
    checked: boolean
  ) => void;
}

const AccountEntriesSubTypeFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-type"
        id="panel-type-header"
      >
        <Typography>{t('filters.type')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl>
          <FormGroup>
            {[
              TransactionSimpleType.purchase,
              TransactionSimpleType.refund,
              TransactionSimpleType.chargeback,
              TransactionSimpleType.recharge,
            ].map((type) => (
              <FormControlLabel
                key={type}
                checked={value.includes(type)}
                control={<Checkbox />}
                onChange={(_, checked) => onChange(type, checked)}
                label={<TransactionSimpleTypeLabel type={type} />}
              />
            ))}

            {[
              featureModules.COMPANY_REIMBURSEMENT
                ? AccountEntryType.reimbursement
                : null,
              AccountEntrySubType.billPayment,
              AccountEntrySubType.prefundingPayment,
              AccountEntryBillPaymentType.payout,
              featureModules.MULTI_CARD_ACCOUNT
                ? AccountEntryBillPaymentType.internalTransfer
                : null,
              AccountEntryBillPaymentType.billPaymentReversal,
              AccountEntryBillPaymentType.directDebitReversalFee,
              AccountEntryType.other,
            ]
              .filter((v) => !!v)
              .map((type) => (
                <FormControlLabel
                  key={type}
                  checked={value.includes(type!)}
                  control={<Checkbox />}
                  onChange={(_, checked) => onChange(type!, checked)}
                  label={
                    <Typography variant="overline">
                      {getLabelByType(type!, t)}
                    </Typography>
                  }
                />
              ))}
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccountEntriesSubTypeFilter;
