import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { cardholderPaths } from 'components/App';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import {
  MerchantCell,
  TransactionAmount,
} from 'domains/transaction/components';
import { isTransactionReadonly } from 'domains/transaction/utils';
import {
  IconButton,
  PlugsConnectedIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import { Transaction } from 'services/constants';
import { getPath } from 'services/utils';

const getTransactionPath = (transaction: Transaction, isAdminApp: boolean) => {
  return isAdminApp
    ? generatePath(getPath('transactionDetails'), {
        orgId: transaction.organizationId,
        transactionId: transaction.transactionId,
      })
    : generatePath(cardholderPaths.teamTransactionDetails, {
        transactionId: transaction.transactionId,
      });
};

interface Props {
  transactions: Transaction[];
  onUnmatch: (transactionId: string) => void;
}

const MatchedTransactionsList = ({ transactions, onUnmatch }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    dispatch,
    state: { receiptInbox },
  } = useGlobalState();
  const { isAdminApp, isPortalAppMirrorView } = useCurrentApp();

  return (
    <Table component="div" sx={{ tableLayout: 'fixed' }}>
      <TableBody component="div">
        {transactions.map((transaction) => {
          const isTxReadOnly = isTransactionReadonly(
            transaction,
            isAdminApp,
            isPortalAppMirrorView
          );

          return (
            <TableRow
              key={transaction.transactionId}
              component="div"
              onClick={() => {
                dispatch({ type: 'RESET_RECEIPT_INBOX_DATA' });
                history.push(getTransactionPath(transaction, isAdminApp));
              }}
              sx={(theme) => ({
                cursor: 'pointer',
                '&:last-child': { '.MuiTableCell-root': { borderBottom: 0 } },
                '&:hover': {
                  background: theme.palette.action.hover,
                },
              })}
            >
              <TableCell
                component="div"
                sx={{ overflow: 'hidden', width: 180 }}
              >
                <MerchantCell transaction={transaction} />
              </TableCell>
              <TableCell component="div" align="center" sx={{ width: 120 }}>
                <Typography variant="body2" noWrap>
                  {moment.utc(transaction.createdAt).format('D MMM YYYY')}
                </Typography>
              </TableCell>
              <TableCell component="div" align="right">
                <TransactionAmount
                  status={transaction.status}
                  simpleType={transaction.simpleType}
                >
                  <FormatMoney
                    value={transaction.transactionAmount}
                    fractionalPart
                    positiveSign
                  />
                </TransactionAmount>
              </TableCell>
              <TableCell
                component="div"
                sx={{ px: 0, width: (theme) => theme.spacing(6) }}
              >
                <Tooltip
                  placement="top"
                  title={
                    isTxReadOnly
                      ? isAdminApp
                        ? t('receiptInboxDetails.unmatchBtnDisabledTooltip')
                        : t('receiptInboxDetails.unmatchBtnDisabledCHTooltip')
                      : t('receiptInboxDetails.unmatchBtn')
                  }
                >
                  <div>
                    <IconButton
                      size="large"
                      disabled={!!receiptInbox.flow || isTxReadOnly}
                      onClick={(e) => {
                        e.stopPropagation();
                        onUnmatch(transaction.transactionId);
                      }}
                    >
                      <PlugsConnectedIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default MatchedTransactionsList;
