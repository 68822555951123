import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { RolePicker } from 'domains/member/components';
import { useAvailableRoles } from 'domains/member/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PartnerMember } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getPartnerMemberRole, Role } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

export interface FormValues {
  role: Role;
}

interface Props extends DialogProps {
  member: PartnerMember;
  onClose: () => void;
  onSuccess: (member: PartnerMember) => void;
}

const EditPartnerMemberRolesDialog = ({
  member,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const { allowedRoles } = useAvailableRoles({ isPartnerMember: true });
  const formik = useFormik<FormValues>({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      role: getPartnerMemberRole(member.roles),
    },
    onSubmit: async ({ role }, { setSubmitting }) => {
      try {
        const updatedMember = await api.updatePartnerMemberRoles(member.id, {
          partnerCompanyId: member.partnerCompanyId,
          roles: [role],
        });

        if (!mounted.current) return;
        enqueueSnackbar(
          t('portal.editPartnerMemberPermissionsDialog.successToast')
        );
        onSuccess(updatedMember);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('portal.editPartnerMemberPermissionsDialog.title')}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="edit-partner-member-roles-form"
          noValidate
        >
          <Box>
            <Typography variant="subtitle2">
              {t('portal.editPartnerMemberPermissionsDialog.roleLabel')}
            </Typography>
            <RolePicker
              showLabel={false}
              value={formik.values.role}
              onChange={(role) => formik.setFieldValue('role', role)}
              allowedRoles={allowedRoles}
              isDisabled={formik.isSubmitting}
              showHelperText={false}
            />
          </Box>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={formik.isSubmitting || !formik.dirty}
          form="edit-partner-member-roles-form"
          type="submit"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(EditPartnerMemberRolesDialog);
