import { useMemo } from 'react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import InternalIndustryTypeSelectGroup from 'domains/creditAndCompliance/components/InternalIndustryTypeSelectGroup';
import NaceIndustryCodeAutoComplete from 'domains/creditAndCompliance/components/NaceIndustryCodeAutoComplete';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import OrganizationGroupAutocompleteSearch from 'domains/organization/components/OrganizationGroupAutocompleteSearch';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  MasterDataPayload,
  OrganizationGroupType,
  PartialOrganizationGroup,
  SpanishProvince,
  SupportedCountry,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  validateAddress,
  validateVatIdByCountry,
} from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues
  extends Omit<
    MasterDataPayload,
    | 'legalForm'
    | 'billingAddress'
    | 'industryCodeSource'
    | 'industryCodeType'
    | 'spanishProvinceCode'
    | 'vatIdRequired'
    | 'riskUnitGroupId'
    | 'riskUnitGroupName'
  > {
  city: string;
  country: string;
  industryCodeDescription: string;
  industryCodeSource: string | null;
  industryCodeType: string | null;
  postalCode: string;
  spanishProvinceCode: number | null;
  streetAdditional: string;
  streetName: string;
  streetNumber: string;
  legalForm: string | null;
  riskUnitGroup: PartialOrganizationGroup | null;
  vatIdRequired: boolean;
}

const EditMasterDataDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, organizationsStaticData, platformLegalForms },
  } = useGlobalState();
  const {
    actions: { refetchCompleteContext },
    state: { complianceCompany, spanishProvinces },
  } = useCreditAndComplianceContext();
  const masterData = complianceCompany!.masterData;

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      city: masterData.billingAddress.city.value ?? '',
      country: masterData.billingAddress.country.value ?? '',
      postalCode: masterData.billingAddress.postalCode.value ?? '',
      streetAdditional: masterData.billingAddress.streetAdditional.value ?? '',
      streetName: masterData.billingAddress.streetName.value ?? '',
      streetNumber: masterData.billingAddress.streetNumber.value ?? '',
      foundationDate: masterData.foundationDate.value ?? '',
      industryCode: masterData.industryCode.value ?? '',
      industryCodeDescription: masterData.industryCodeDescription ?? '',
      industryCodeSource: masterData.industryCodeSource.value,
      industryCodeType: masterData.industryCodeType.value,
      internalIndustryType: masterData.internalIndustryType.value ?? '',
      internalIndustrySubtype: masterData.internalIndustrySubtype.value ?? '',
      legalForm: masterData.legalForm.value,
      legalName: masterData.legalName.value ?? '',
      nationalRegisterCourt: masterData.nationalRegisterCourt.value ?? '',
      registerExcerptDate: masterData.registerExcerptDate.value ?? '',
      riskUnitGroup: masterData.riskUnitGroupId.value
        ? ({
            id: masterData.riskUnitGroupId.value,
            name: masterData.riskUnitGroupName.value,
          } as PartialOrganizationGroup)
        : null,
      spanishProvinceCode: masterData.spanishProvinceCode.value,
      tradeName: masterData.tradeName.value ?? '',
      tradeRegisterNumber: masterData.tradeRegisterNumber.value ?? '',
      vatId: masterData.vatId.value ?? '',
      vatIdRequired: masterData.vatIdRequired.value,
      website: masterData.website.value ?? '',
    },
    validate: (values) => {
      let errors: { [k in keyof FormValues]?: string } = {};

      if (!values.registerExcerptDate) {
        errors['registerExcerptDate'] = t('errors.fieldRequired');
      }
      if (!values.legalName) {
        errors['legalName'] = t('errors.fieldRequired');
      }
      if (!values.legalForm) {
        errors['legalForm'] = t('errors.fieldRequired');
      }
      if (!values.tradeRegisterNumber) {
        errors['tradeRegisterNumber'] = t('errors.fieldRequired');
      }
      if (
        [SupportedCountry.de].includes(organization!.billingAddress.country) &&
        !values.nationalRegisterCourt
      ) {
        errors['nationalRegisterCourt'] = t('errors.fieldRequired');
      }
      if (values.vatIdRequired) {
        const vatIdFormatError = validateVatIdByCountry(
          organization!.billingAddress.country,
          values.vatId!,
          t
        );
        if (vatIdFormatError) {
          errors['vatId'] = vatIdFormatError;
        }
      }

      errors = {
        ...errors,
        ...validateAddress(
          {
            city: values.city,
            country: values.country as SupportedCountry,
            postalCode: values.postalCode,
            streetAdditional: values.streetAdditional,
            streetName: values.streetName,
            streetNumber: values.streetNumber,
          },
          t
        ),
      };
      if (
        organization!.billingAddress.country === SupportedCountry.es &&
        !values.spanishProvinceCode
      ) {
        errors['spanishProvinceCode'] = t('errors.fieldRequired');
      }
      if (!values.foundationDate) {
        errors['foundationDate'] = t('errors.fieldRequired');
      }
      if (!values.industryCode) {
        errors['industryCode'] = t('errors.fieldRequired');
      }
      if (!values.industryCodeSource) {
        errors['industryCodeSource'] = t('errors.fieldRequired');
      }
      if (!values.industryCodeType) {
        errors['industryCodeType'] = t('errors.fieldRequired');
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const payload: MasterDataPayload = {
          billingAddress: {
            city: values.city,
            country: values.country,
            postalCode: values.postalCode,
            streetAdditional: values.streetAdditional,
            streetName: values.streetName,
            streetNumber: values.streetNumber,
          },
          foundationDate: values.foundationDate,
          industryCode: values.industryCode,
          industryCodeSource: values.industryCodeSource!,
          industryCodeType: values.industryCodeType!,
          internalIndustryType: values.internalIndustryType ?? null,
          internalIndustrySubtype: values.internalIndustrySubtype ?? null,
          legalForm: values.legalForm!,
          legalName: values.legalName,
          nationalRegisterCourt: values.nationalRegisterCourt,
          registerExcerptDate: values.registerExcerptDate,
          riskUnitGroupId: values.riskUnitGroup
            ? values.riskUnitGroup.id
            : null,
          riskUnitGroupName: values.riskUnitGroup
            ? values.riskUnitGroup.name
            : null,
          tradeName: values.tradeName,
          tradeRegisterNumber: values.tradeRegisterNumber,
          vatId: values.vatId,
          vatIdRequired: values.vatIdRequired,
          website: values.website,
          ...(organization!.billingAddress.country === SupportedCountry.es && {
            spanishProvinceCode: values.spanishProvinceCode!,
          }),
        };

        await api.updateMasterData(organization!.id, payload);
        await refetchCompleteContext();

        if (!mounted) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const platformLegalFormsByCountry = useMemo(
    () =>
      platformLegalForms
        .filter((item) => item.country === masterData.country.value)
        .map((item) => item.abbreviation)
        .sort(),
    [masterData.country.value, platformLegalForms]
  );

  const getCustomFieldProps = <T extends keyof FormValues>(name: T) => {
    return {
      ...(formik.errors[name] && {
        error: true,
        helperText: formik.errors[name],
      }),
      name,
      value: formik.values[name],
    };
  };
  const onChange = <T extends keyof FormValues>(
    name: T,
    value: FormValues[T]
  ) => {
    if (formik.errors[name]) {
      formik.setFieldError(name, undefined);
    }
    formik.setFieldValue(name, value);
  };

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('int.editMasterDataDialog.title')}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('registerExcerptDate')}
              onChange={(event) =>
                onChange('registerExcerptDate', event.target.value)
              }
              label={t('int.editMasterDataDialog.registerExcerptDate')}
              type="date"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('legalName')}
              onChange={(event) => onChange('legalName', event.target.value)}
              label={t('int.editMasterDataDialog.legalName')}
            />
          </Grid>
          <Grid item xs={6}>
            <OrganizationGroupAutocompleteSearch
              label={t('int.organizationGroupType.GCC')}
              onChange={(orgGroup) => onChange('riskUnitGroup', orgGroup)}
              organizationGroupType={[
                OrganizationGroupType.gccLegalEntity,
                OrganizationGroupType.gccNaturalPerson,
              ].join(',')}
              value={formik.values.riskUnitGroup}
            />
          </Grid>

          <Grid item xs={6}>
            <Autocomplete<string, false, false, false>
              {...getCustomFieldProps('legalForm')}
              onChange={(_, option) => onChange('legalForm', option)}
              options={platformLegalFormsByCountry}
              label={t('int.editMasterDataDialog.legalForm')}
              placeholder="Placeholder"
              noOptionsText="Nothing found"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('tradeName')}
              onChange={(event) => onChange('tradeName', event.target.value)}
              label={t('int.editMasterDataDialog.tradeName')}
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('nationalRegisterCourt')}
              onChange={(event) =>
                onChange('nationalRegisterCourt', event.target.value)
              }
              label={t('int.editMasterDataDialog.nationalRegisterCourt')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('tradeRegisterNumber')}
              onChange={(event) =>
                onChange('tradeRegisterNumber', event.target.value)
              }
              label={t('int.editMasterDataDialog.tradeRegisterNumber')}
            />
          </Grid>

          <Grid
            item
            xs={12}
            borderBottom="1px solid"
            borderColor={(theme) => theme.palette.divider}
            marginLeft={2}
          />

          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('streetName')}
              onChange={(event) => onChange('streetName', event.target.value)}
              label={t('int.editMasterDataDialog.streetName')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('streetNumber')}
              onChange={(event) => onChange('streetNumber', event.target.value)}
              label={t('int.editMasterDataDialog.streetNumber')}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...getCustomFieldProps('streetAdditional')}
              onChange={(event) =>
                onChange('streetAdditional', event.target.value)
              }
              label={t('int.editMasterDataDialog.streetAdditional')}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              {...getCustomFieldProps('postalCode')}
              onChange={(event) => onChange('postalCode', event.target.value)}
              label={t('int.editMasterDataDialog.postalCode')}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              {...getCustomFieldProps('city')}
              onChange={(event) => onChange('city', event.target.value)}
              label={t('int.editMasterDataDialog.city')}
            />
          </Grid>

          {organization!.billingAddress.country === SupportedCountry.es && (
            <Grid item xs={6}>
              <Autocomplete<SpanishProvince, false, false, false>
                {...getCustomFieldProps('spanishProvinceCode')}
                value={
                  formik.values.spanishProvinceCode
                    ? spanishProvinces.find(
                        (item) =>
                          item.code === formik.values.spanishProvinceCode
                      )
                    : null
                }
                onChange={(_, option) =>
                  onChange('spanishProvinceCode', option?.code ?? null)
                }
                options={spanishProvinces}
                label={t('int.editMasterDataDialog.province')}
                getOptionLabel={(option) =>
                  `${option.name} (${String(option.code).padStart(2, '0')})`
                }
                placeholder="Placeholder"
                noOptionsText="Nothing found"
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <TextField
              {...omit(getCustomFieldProps('country'), 'value')}
              value={t(`countries.${masterData.billingAddress.country.value}`)}
              onChange={(event) => onChange('country', event.target.value)}
              label={t('int.editMasterDataDialog.country')}
              disabled
            />
          </Grid>

          <Grid
            item
            xs={12}
            borderBottom="1px solid"
            borderColor={(theme) => theme.palette.divider}
            marginLeft={2}
          />

          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('foundationDate')}
              onChange={(event) =>
                onChange('foundationDate', event.target.value)
              }
              label={t('int.editMasterDataDialog.foundationDate')}
              type="date"
            />
          </Grid>

          <Grid
            item
            xs={12}
            borderBottom="1px solid"
            borderColor={(theme) => theme.palette.divider}
            marginLeft={2}
          />

          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.industryCodeSource}>
              <InputLabel>
                {t('int.editCustomerSummaryDialog.industryCodeType')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('industryCodeType'), 'helperText')}
                onChange={({ target: { value } }) =>
                  onChange('industryCodeType', value)
                }
              >
                {organizationsStaticData!.industryCodeTypes.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.industryCodeType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.industryCodeSource}>
              <InputLabel>
                {t('int.editCustomerSummaryDialog.industryCodeSource')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('industryCodeSource'),
                  'helperText'
                )}
                onChange={({ target: { value } }) =>
                  onChange('industryCodeSource', value)
                }
              >
                {organizationsStaticData!.industryCodeSources.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.industryCodeSource}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <NaceIndustryCodeAutoComplete
              error={formik.errors.industryCode}
              value={
                formik.values.industryCode
                  ? {
                      code: formik.values.industryCode,
                      description: formik.values.industryCodeDescription,
                    }
                  : null
              }
              onChange={(values) => {
                if (formik.errors.industryCode) {
                  formik.setFieldError('industryCode', undefined);
                }
                formik.setValues((prev) => ({
                  ...prev,
                  industryCode: values ? values.code : '',
                  industryCodeDescription: values ? values.description : '',
                }));
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            borderBottom="1px solid"
            borderColor={(theme) => theme.palette.divider}
            marginLeft={2}
          />

          <Grid item xs={12}>
            <InternalIndustryTypeSelectGroup
              internalIndustrySubtype={formik.values.internalIndustrySubtype}
              internalIndustryType={formik.values.internalIndustryType}
              onChange={onChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            borderBottom="1px solid"
            borderColor={(theme) => theme.palette.divider}
            marginLeft={2}
          />

          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('vatId')}
              onChange={(event) => {
                onChange('vatId', event.target.value);
                formik.setFieldValue('vatIdRequired', !!event.target.value);
              }}
              label={t('int.editMasterDataDialog.vatId')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('website')}
              onChange={(event) => onChange('website', event.target.value)}
              label={t('int.editMasterDataDialog.website')}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={formik.isSubmitting} onClick={formik.submitForm}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditMasterDataDialog);
