import { useTranslation } from 'react-i18next';
import { LegitimationStatus } from 'services/constants';
import { StatusBadge } from './StatusBadge';

export const LegitimationStatusBadge = ({
  status,
}: {
  status: LegitimationStatus;
}) => {
  const { t } = useTranslation();

  switch (status) {
    case LegitimationStatus.COMPLETED:
      return (
        <StatusBadge type="success">
          {t('identificationStatus.COMPLETED')}
        </StatusBadge>
      );
    case LegitimationStatus.FRAUD:
      return (
        <StatusBadge type="warn">{t('identificationStatus.FRAUD')}</StatusBadge>
      );
    case LegitimationStatus.ONGOING:
      return (
        <StatusBadge type="dark">
          {t('identificationStatus.ONGOING')}
        </StatusBadge>
      );
    default:
      return null;
  }
};
