import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Box,
  SuitcaseSimpleIcon,
  UsersThreeIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DEFAULT_AUTOCOMPLETE_DELAY,
  PartialOrganization,
  PartialOrganizationGroup,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface InternalPartialOrganization {
  id: string;
  name: string;
}

const parsePartialOrganizationForInternalUsage = (
  partialOrg: PartialOrganization
): InternalPartialOrganization => ({
  id: partialOrg.organizationId,
  name: partialOrg.name,
});

const MIN_ALLOWED_CHARS = 3;

interface Props {
  label: string;
  onChange: (org: PartialOrganizationGroup | null) => void;
  organizationGroupType: string;
  value: PartialOrganizationGroup | null;
}

interface State {
  inputValueOrg: string;
  isLoading: boolean;
  isOpen: boolean;
  orgGroupSearchResults: PartialOrganizationGroup[];
  orgSearchResults: InternalPartialOrganization[];
}

const OrganizationGroupAutocompleteSearch = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState<State>({
    inputValueOrg: '',
    isLoading: false,
    isOpen: false,
    orgGroupSearchResults: [],
    orgSearchResults: [],
  });

  const searchOrganizationGroup = async (name: string) => {
    try {
      const [
        { organizationGroups: orgGroupSearchResults },
        { organizations: orgSearchResults },
      ] = await Promise.all([
        api.getOrganizationGroups({
          q: name,
          type: props.organizationGroupType,
        }),
        api.getOrganizations({
          q: name,
          page: 0,
          limit: 50,
          sort: '+name',
        }),
      ]);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        orgGroupSearchResults,
        orgSearchResults: orgSearchResults.map(
          parsePartialOrganizationForInternalUsage
        ),
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isOpen: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    let active = true;

    if (state.inputValueOrg.length < MIN_ALLOWED_CHARS) return;

    setState((prevState) => ({ ...prevState, isLoading: true }));

    setTimeout(() => {
      if (active) searchOrganizationGroup(state.inputValueOrg);
    }, DEFAULT_AUTOCOMPLETE_DELAY);

    return () => {
      active = false;
    };
  }, [state.inputValueOrg]);

  return (
    <Autocomplete<
      PartialOrganizationGroup | InternalPartialOrganization,
      false,
      false,
      false
    >
      inputValue={state.inputValueOrg}
      onInputChange={(e, value) =>
        setState((prevState) => ({
          ...prevState,
          inputValueOrg: value,
        }))
      }
      value={props.value}
      onChange={(e, selectedOrg) =>
        props.onChange(selectedOrg as PartialOrganizationGroup)
      }
      options={[...state.orgGroupSearchResults, ...state.orgSearchResults]}
      open={state.isOpen}
      onOpen={() =>
        setState((prevState) => ({
          ...prevState,
          isOpen: prevState.inputValueOrg.length >= MIN_ALLOWED_CHARS,
        }))
      }
      onClose={() =>
        setState((prevState) => ({
          ...prevState,
          orgGroupSearchResults: props.value
            ? prevState.orgGroupSearchResults
            : [],
          orgSearchResults: props.value ? prevState.orgSearchResults : [],
          isOpen: false,
        }))
      }
      loading={state.isLoading}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      getOptionDisabled={(option) => !('type' in option)}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {!('type' in option) ? <SuitcaseSimpleIcon /> : <UsersThreeIcon />}
            <Box ml={2}>{option.name}</Box>
          </li>
        );
      }}
      forcePopupIcon={false}
      label={props.label}
      placeholder={props.label}
      noOptionsText={t('common.nothingFound')}
    />
  );
};

export default OrganizationGroupAutocompleteSearch;
