import { SupportedCountry } from 'services/constants';

/**
 * Converts 'UK' country code to the ISO-compliant 'GB' code
 * @param countryCode - The ISO country code to normalize
 * @returns The normalized country code
 */
export function convertUKtoGBCountryCode(
  countryCode: SupportedCountry
): SupportedCountry {
  return countryCode === SupportedCountry.uk
    ? SupportedCountry.gb
    : countryCode;
}
