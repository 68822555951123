import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useCardDesign } from 'context/GlobalState';
import { CardSensitiveDataValuePci, CardTile } from 'domains/card/components';
import { EyeIcon, Link } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card, CardSimpleStatus, CardStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  CardTileOverlay,
  PlaceholderValue,
  StyledWalletCardTile,
} from './style';

interface State {
  traceId: string | null;
  totp: string | null;
  isLoading: boolean;
  isPanLoaded: boolean;
  isCvvLoaded: boolean;
}

const initialState: State = {
  traceId: null,
  totp: null,
  isLoading: false,
  isPanLoaded: false,
  isCvvLoaded: false,
};

interface Props {
  card: Card;
  isActive: boolean;
}

const WalletCardTile = ({ card, isActive }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const cardDesign = useCardDesign(card.cardDesignId);
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    if (card.status === CardStatus.locked || !isActive) setState(initialState);
  }, [card, isActive]);

  const showCardDetails = async () => {
    if (state.isLoading) return;
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const traceId = uuidv4();
      const totp = await api.getTotpPci(card.cardId, traceId);
      setState((prevState) => ({ ...prevState, traceId, totp }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(t('cardDetails.cardNumberError'), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const isOverlayHidden =
    (card.simpleStatus === CardSimpleStatus.pending &&
      card.status !== CardStatus.pendingValidity) ||
    card.simpleStatus === CardSimpleStatus.requested ||
    (state.isPanLoaded && state.isCvvLoaded);

  return (
    <StyledWalletCardTile>
      <CardTile
        card={card}
        pan={
          <>
            {!state.isPanLoaded && !state.isCvvLoaded && (
              <PlaceholderValue>
                **** **** **** {card.cardRefNum || '****'}
              </PlaceholderValue>
            )}
            {state.totp && state.traceId && (
              <CardSensitiveDataValuePci
                type="pan"
                cardId={card.cardId}
                traceId={state.traceId}
                totp={state.totp}
                textColor={cardDesign.textColor}
                onLoadSuccess={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isPanLoaded: true,
                  }))
                }
                onLoadError={() => {
                  enqueueSnackbar(t('cardDetails.cardNumberError'), {
                    variant: 'error',
                  });
                  setState(initialState);
                }}
                onClear={() => setState(initialState)}
                onCopy={() =>
                  enqueueSnackbar(t('cardDetails.numberCopied'), {
                    autoHideDuration: 1500,
                  })
                }
              />
            )}
          </>
        }
        cvv={
          <>
            {!state.isPanLoaded && !state.isCvvLoaded && (
              <PlaceholderValue>***</PlaceholderValue>
            )}
            {state.totp && state.traceId && (
              <CardSensitiveDataValuePci
                type="cvv"
                cardId={card.cardId}
                traceId={state.traceId}
                totp={state.totp}
                textColor={cardDesign.textColor}
                onLoadSuccess={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isCvvLoaded: true,
                  }))
                }
                onLoadError={() => {
                  enqueueSnackbar(t('cardDetails.cardNumberError'), {
                    variant: 'error',
                  });
                  setState(initialState);
                }}
                onClear={() => setState(initialState)}
              />
            )}
          </>
        }
        areDetailsVisible={state.isPanLoaded && state.isCvvLoaded}
      />
      {!isOverlayHidden && (
        <CardTileOverlay>
          <Link
            component="button"
            variant="body2"
            underline="none"
            onClick={showCardDetails}
            data-test-id="show-card-details-btn"
            sx={(theme) => ({
              display: 'inline-flex',
              alignItems: 'center',
              color: theme.palette.common.white,
              '&:hover': {
                opacity: 0.7,
              },
            })}
          >
            <EyeIcon fontSize="small" sx={{ mr: 1 }} />
            <span>
              <Trans
                i18nKey="cardDetails.showDetails"
                components={{ br: <br /> }}
              />
            </span>
          </Link>
        </CardTileOverlay>
      )}
    </StyledWalletCardTile>
  );
};

export default WalletCardTile;
