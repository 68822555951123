import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import NothingFound from 'components/NothingFound';
import CancelApplicationButton from 'components/OnboardingPage/CancelApplicationButton';
import { useGlobalState } from 'context/GlobalState';
import CreditApplicationEnabledToggle from 'domains/creditAndCompliance/components/CreditApplicationEnabledToggle';
import ResumeApplicationButton from 'domains/creditAndCompliance/components/ResumeApplicationButton';
import { InviteMemberButtonWithMenu } from 'domains/member/components';
import { SectionItem } from 'domains/onboarding/components';
import { PartnerLegalDisclaimer } from 'domains/partner/components';
import { Box, Divider, Grid, Typography } from 'elements';
import { ContentContainer, PageContent, PageHeader, PageTitle } from 'layout';
import { OnboardingSection, OrganizationStatus } from 'services/constants';
import { useFlags } from 'services/featureflags';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { useCanUser } from 'services/rbac';
import CreditLineApplication from './CreditLineApplication';

interface Props {
  sections: OnboardingSection[] | null;
}

const OrganizationOnboardingPage = ({ sections }: Props) => {
  const { t } = useTranslation();
  const { creditApplicationNewOnboardingEnabled } = useFlags();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const queryClient = useQueryClient();
  const { useGetAdditionalInfo } = useTanstackQuery();
  const { data: additionalInfo, isLoading } = useGetAdditionalInfo();

  return (
    <>
      <PageHeader>
        <ContentContainer
          mx="auto"
          size="sm"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PageTitle title={t('orgOnboardingPage.title')} />
            <Typography mt={1} variant="body2" color="textSecondary">
              {t('orgOnboardingPage.description')}
            </Typography>
          </Box>

          <Box ml={2}>
            <InviteMemberButtonWithMenu />
          </Box>
        </ContentContainer>
      </PageHeader>

      <PageContent display="flex" flexDirection="column">
        {sections && (
          <ContentContainer mx="auto" size="sm" width="100%" flex={1}>
            {sections.map((section) => (
              <SectionItem key={section.type} section={section}>
                {t(`orgOnboardingSectionTitle.${section.type}`)}
              </SectionItem>
            ))}
            {!isLoading && additionalInfo?.creditApplicationEnabled && (
              <>
                <Divider sx={{ my: 2 }} />
                <CreditLineApplication />
              </>
            )}
          </ContentContainer>
        )}

        {sections && sections.length === 0 && <NothingFound />}

        <PartnerLegalDisclaimer />

        <Grid
          alignItems="center"
          container
          direction="column"
          mt={4}
          rowSpacing={2}
        >
          <Grid item display="flex" alignItems="center">
            {canUser('credit-application-enabled:update') &&
              creditApplicationNewOnboardingEnabled &&
              additionalInfo && (
                <CreditApplicationEnabledToggle
                  creditApplicationEnabled={
                    additionalInfo.creditApplicationEnabled
                  }
                  onSuccess={() =>
                    queryClient.invalidateQueries([
                      'getAdditionalInfo',
                      organization!.id,
                    ])
                  }
                />
              )}
          </Grid>

          <Grid item>
            {organization!.status === OrganizationStatus.onboarding && (
              <CancelApplicationButton />
            )}

            {organization!.status === OrganizationStatus.cancelled && (
              <ResumeApplicationButton />
            )}
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
};

export default OrganizationOnboardingPage;
