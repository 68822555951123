import { generatePath, Redirect } from 'react-router-dom';
import { portalOrgPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import { OrganizationOnboardingForbiddenPage } from 'domains/onboarding/pages';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { useCanUser } from 'services/rbac';
import OrganizationOnboardingRoutes from './OrganizationOnboardingRoutes';

const PortalOrganizationOnboarding = () => {
  const canUser = useCanUser();
  const {
    state: { organization, partnerOrgAuthDetails },
  } = useGlobalState();
  const { isOrgInOnboarding, isOrgInOnboardingNew } = useIsOrgInOnboarding();

  if (!isOrgInOnboarding)
    return (
      <Redirect
        to={generatePath(portalOrgPaths.dashboard, {
          orgId: organization!.id,
        })}
      />
    );

  if (
    !isOrgInOnboardingNew ||
    !canUser('org-onboarding-page:visit') ||
    !partnerOrgAuthDetails?.shareOnboardingStatus
  )
    return <OrganizationOnboardingForbiddenPage />;

  return <OrganizationOnboardingRoutes />;
};

export { PortalOrganizationOnboarding };
export default OrganizationOnboardingRoutes;
