import React, { FC, useEffect, useRef, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Delete1Icon from 'assets/icons/interface/delete1.svg?react';
import SearchIcon from 'assets/icons/interface/search.svg?react';
import { Button, IconButton } from 'components/Button';
import { StyledInputBase } from 'components/Form/StyledInputBase';

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: 15px;
  left: 15px;
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.colors.grey10};
`;

export const ClearButton = styled(IconButton)`
  position: absolute;
  top: calc(50% - 18px);
  right: 6px;
  padding: 10px;
`;

const ESC_KEY_CODE = 27;

interface Props {
  initialValue: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
}

export const PageSearchInput: FC<Props> = (props) => {
  const { initialValue = '', onChange, disabled = false, onOpenChange } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (initialValue !== value.trim()) {
      setValue(initialValue);
      setIsOpen(!!initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (typeof onOpenChange === 'function') onOpenChange(isOpen);
  }, [isOpen]);

  const onValueChange = (value: string) => {
    setValue(value);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => onChange(value.trim()), 500);
  };

  if (!isOpen) {
    return (
      <Button
        $isIconButton
        variant="outlined"
        disabled={disabled}
        onClick={() => setIsOpen(true)}
        data-test-id="search-btn"
      >
        <SearchIcon />
      </Button>
    );
  }

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (
          !value.trim() &&
          // @ts-ignore
          !e.path?.some((el) => el?.classList?.contains('ignore-click-away'))
        )
          setIsOpen(false);
      }}
    >
      <StyledInputBase
        autoFocus
        type="text"
        name="search"
        placeholder={t('filters.search')}
        disabled={disabled}
        value={value}
        onKeyDown={(e) => {
          if (e.keyCode === ESC_KEY_CODE && !value) {
            e.preventDefault();
            setIsOpen(false);
          }
        }}
        onChange={(e) => onValueChange(e.target.value)}
        startAdornment={<StyledSearchIcon />}
        endAdornment={
          <ClearButton
            onClick={() => {
              if (value) onValueChange('');
              setIsOpen(false);
            }}
            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
            }}
          >
            <Delete1Icon />
          </ClearButton>
        }
      />
    </ClickAwayListener>
  );
};
