import { useMemo } from 'react';
import { useGlobalState } from './index';

const useVisibleCardAccounts = () => {
  const {
    state: { cardAccounts },
  } = useGlobalState();

  return useMemo(
    () => cardAccounts.filter((item) => !item.benefitCardAccount.value),
    [cardAccounts]
  );
};

export default useVisibleCardAccounts;
