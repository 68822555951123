import { generatePath, Redirect } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { portalOrgPaths } from './paths';

const OnboardingRedirect = ({ children }: { children: React.ReactNode }) => {
  const {
    state: { organization },
  } = useGlobalState();
  const { isOrgInOnboarding, isOrgInOnboardingNew } = useIsOrgInOnboarding();

  if (isOrgInOnboardingNew)
    return (
      <Redirect
        to={generatePath(portalOrgPaths.orgOnboarding, {
          orgId: organization!.id,
        })}
      />
    );

  if (isOrgInOnboarding)
    return (
      <Redirect
        to={generatePath(portalOrgPaths.onboarding, {
          orgId: organization!.id,
        })}
      />
    );

  return <>{children}</>;
};

export default OnboardingRedirect;
