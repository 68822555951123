import {
  ReceiptsAutoMatchingStatus,
  ReceiptsAutoMatchingTagGroup,
} from 'services/constants';

export const TabGroups = {
  [ReceiptsAutoMatchingTagGroup.unmatched]: [
    ReceiptsAutoMatchingStatus.noTransactionFound,
    ReceiptsAutoMatchingStatus.waitingForRetry,
    ReceiptsAutoMatchingStatus.processing,
    ReceiptsAutoMatchingStatus.externalLink,
    ReceiptsAutoMatchingStatus.notReadable,
  ],
  [ReceiptsAutoMatchingTagGroup.matched]: [
    ReceiptsAutoMatchingStatus.matchedAuto,
    ReceiptsAutoMatchingStatus.matchedManually,
  ],
  [ReceiptsAutoMatchingTagGroup.trash]: [
    ReceiptsAutoMatchingStatus.deletedByCustomer,
    ReceiptsAutoMatchingStatus.notPaidWithPliant,
    ReceiptsAutoMatchingStatus.duplicate,
    ReceiptsAutoMatchingStatus.alreadyExported,
    ReceiptsAutoMatchingStatus.invalidAttachment,
    ReceiptsAutoMatchingStatus.notAReceipt,
  ],
};

export const getTabGroupFromStatus = (status: ReceiptsAutoMatchingStatus) => {
  return Object.entries(TabGroups).find(([, value]) =>
    value.includes(status)
  )?.[0] as ReceiptsAutoMatchingTagGroup | undefined;
};
