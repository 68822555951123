import { useMemo } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  FormControlLabelTooltipIcon,
  Grid,
  LoaderWithOverlay,
  Switch,
  TextField,
  Tooltip,
  withDialogWrapper,
} from 'elements';
import useInputSelection from 'hooks/useInputSelection';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { NetworkErrorCode, Team } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';

interface Props extends DialogProps {
  team?: Team | null;
  onSuccess?: (team: Team) => void;
  onClose: () => void;
}

const CreateEditTeamDialog = ({
  team: teamNullable,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const inputSelection = useInputSelection();
  const {
    dispatch,
    state: {
      organization,
      accountingSettings,
      featureModules,
      featureModulesWithData,
    },
  } = useGlobalState();

  const isToggleManagerReviewVisible =
    featureModules.MANAGER_TX_REVIEWS && canUser('team-review-tx:change');

  const team = useMemo(() => teamNullable, []);

  const formik = useFormik({
    initialValues: {
      name: team?.name ?? '',
      costCenter: team?.costCenter ?? '',
      managerTxReviewRequired: isToggleManagerReviewVisible
        ? team?.managerTxReviewRequired ?? true
        : undefined,
    },
    onSubmit: async (
      { name, costCenter, managerTxReviewRequired },
      { setSubmitting, setFieldError }
    ) => {
      try {
        if (team) {
          const data = await api.updateTeam(team.id, {
            name: name.trim(),
            costCenter: costCenter.trim() || undefined,
            managerTxReviewRequired,
          });
          dispatch({ type: 'UPDATE_TEAM', payload: data });
          if (!mounted.current) return;
          enqueueSnackbar(t('createEditTeamDialog.successEditMessage'));
          if (onSuccess) onSuccess(data);
          props.onClose();
        } else {
          const data = await api.createTeam({
            name: name.trim(),
            costCenter: costCenter.trim() || undefined,
            managerTxReviewRequired,
            organizationId: organization!.id,
          });
          dispatch({ type: 'ADD_TEAM', payload: data });
          if (!mounted.current) return;
          if (onSuccess) onSuccess(data);
          else props.onClose();
        }
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (getNetworkErrorCode(error) === NetworkErrorCode.alreadyExists) {
          setFieldError(
            'name',
            t('createEditTeamDialog.nameAlreadyExistsError')
          );
          return;
        }
        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.illegalArgumentsProvided
        ) {
          setFieldError('costCenter', t('errors.invalidCharacters'));
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const isSubmitDisabled = !formik.values.name.trim() || formik.isSubmitting;
  const hideTeamEdit = !featureModules.TEAMS;
  const isToggleManagerReviewDisabled =
    featureModulesWithData.MANAGER_TX_REVIEWS.settings
      .REVIEW_FOR_EXPORT_REQUIRED && formik.values.managerTxReviewRequired;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {team
          ? t('createEditTeamDialog.editTitle')
          : t('createEditTeamDialog.createTitle')}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="create-edit-team-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('createEditTeamDialog.teamName')}
                {...formik.getFieldProps('name')}
                inputProps={{ maxLength: 25 }}
                disabled={formik.isSubmitting}
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                data-intercom-target="create-team-name-input"
                autoFocus
              />
            </Grid>
            {accountingSettings?.costCenterEnabled && !hideTeamEdit && (
              <Grid item xs={12}>
                <TextField
                  label={t('createEditTeamDialog.costCenter')}
                  name="costCenter"
                  value={formik.values.costCenter}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.setFieldValue('costCenter', e.target.value);
                    inputSelection.onChange(e);
                  }}
                  error={!!formik.errors.costCenter}
                  helperText={formik.errors.costCenter}
                  inputProps={{ maxLength: 36 }}
                  disabled={formik.isSubmitting}
                />
              </Grid>
            )}
          </Grid>
          {isToggleManagerReviewVisible && (
            <Box mt={4}>
              <Tooltip
                title={
                  isToggleManagerReviewDisabled &&
                  t('createEditTeamDialog.managerTxReviewDisabledTooltip')
                }
                placement="bottom"
              >
                <FormControlLabel
                  control={
                    <Switch
                      disabled={
                        isToggleManagerReviewDisabled || formik.isSubmitting
                      }
                      checked={formik.values.managerTxReviewRequired}
                      onChange={(e, checked) =>
                        formik.setFieldValue('managerTxReviewRequired', checked)
                      }
                    />
                  }
                  label={
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      component="span"
                    >
                      {t('createEditTeamDialog.managerTxReview')}
                      <FormControlLabelTooltipIcon
                        title={t('createEditTeamDialog.managerTxReviewTooltip')}
                        placement={
                          isToggleManagerReviewDisabled ? 'top' : undefined
                        }
                      />
                    </Box>
                  }
                />
              </Tooltip>
            </Box>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          type="submit"
          form="create-edit-team-form"
          data-intercom-target="create-team-save-button"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(CreateEditTeamDialog);
