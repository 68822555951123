import { alpha, Box, Theme } from '@mui/material';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import DropzoneIdleComponent, {
  DropzoneIdleProps,
} from './DropzoneIdleComponent';
import DropzoneStatusComponent from './DropzoneStatusComponent';

export type Variant = 'default' | 'error';

const addCustomStyles = (
  theme: Theme,
  isDragAccept: boolean,
  isDragReject: boolean,
  variant: Variant
) => {
  if (variant === 'error') {
    if (isDragAccept)
      return {
        border: `1px dashed ${alpha(theme.palette.error.contrastText, 0.5)}`,
        color: alpha(theme.palette.error.contrastText, 0.8),
      };

    return {
      border: `1px dashed ${theme.palette.error.contrastText}`,
      color: theme.palette.error.contrastText,
    };
  }

  if (isDragAccept)
    return {
      border: `1px dashed ${theme.palette.primary.main}`,
      backgroundColor: `${alpha(theme.palette.primary.main, 0.08)}`,
    };

  if (isDragReject)
    return {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: `${alpha(theme.palette.error.main, 0.04)}`,
    };

  return { border: `1px dashed ${alpha(theme.palette.primary.main, 0.4)}` };
};

export interface DropzoneProps extends DropzoneOptions {
  variant?: Variant;
  // use progress for linear loading bar
  progress?: number;
  file: File | null;
  dataTestId?: string;
  isLoading?: boolean;
  error?: string;
  dropzoneIdleProps?: Omit<DropzoneIdleProps, 'isDragReject'>;
  // pass onErrorReset to show XIcon and reset the error
  onErrorReset?: () => void;
}

// todo: variant error handling should be improved later
// when we have all designs.
const Dropzone = ({
  onErrorReset,
  variant = 'default',
  progress,
  file,
  dataTestId = 'upload-input',
  dropzoneIdleProps,
  isLoading,
  error,
  ...options
}: DropzoneProps) => {
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone(options);

  return (
    <Box
      px={2}
      py={3}
      {...getRootProps()}
      sx={(theme) => ({
        borderRadius: '4px',
        cursor: options.disabled ? 'default' : 'pointer',
        ...(file
          ? {}
          : addCustomStyles(theme, isDragAccept, isDragReject, variant)),
      })}
    >
      <input data-test-id={dataTestId} {...getInputProps()} />

      {(!file || isDragAccept || isDragReject) && (
        <DropzoneIdleComponent
          {...dropzoneIdleProps}
          variant={variant}
          isDragReject={isDragReject}
        />
      )}

      {!!file && (
        <DropzoneStatusComponent
          variant={variant}
          progress={progress}
          file={file}
          isLoading={isLoading}
          error={error}
          onErrorReset={onErrorReset}
        />
      )}
    </Box>
  );
};

export default Dropzone;

export { ErrorCode } from 'react-dropzone';
export type { FileRejection } from 'react-dropzone';
