import styled from 'styled-components';
import VoidLabel from 'assets/svg/voidLabel.svg?react';

export const StyledCardTile = styled.div`
  display: inline-flex;
  position: relative;
  vertical-align: top;
  text-align: left;
`;

export const CardTileOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
`;

export const IconHolder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 50%;
  transform: translate(-50%, calc(-50% - 6px));
  background: ${({ theme }) => theme.colors.grey10};

  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledVoidLabel = styled(VoidLabel)`
  position: absolute;
  bottom: 18px;
  left: 18px;
  fill: ${({ theme }) => theme.colors.plasticCard};
`;
