import { generatePath } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { getPath } from 'services/utils';

const ExportRouteRedirect = () => {
  return (
    <>
      {/** not exported tab */}
      {/* not exported: transaction - /export/transactions => /export */}
      <Route
        exact
        path={getPath('exportTransaction')}
        render={({ match, location }) => (
          <Redirect
            to={`${generatePath(getPath('export'), {
              orgId: match.params.orgId,
            })}${location.search}`}
          />
        )}
      />
      {/* not exported: entry - /export/entries => /export */}
      <Route
        exact
        path={getPath('exportEntry')}
        render={({ match, location }) => (
          <Redirect
            to={`${generatePath(getPath('export'), {
              orgId: match.params.orgId,
            })}${location.search}`}
          />
        )}
      />
      {/** export queue tab */}
      {/* export queue: transaction - /export-queue/transactions => /export-queue */}
      <Route
        exact
        path={getPath('exportQueueTransaction')}
        render={({ match, location }) => (
          <Redirect
            to={`${generatePath(getPath('exportQueue'), {
              orgId: match.params.orgId,
            })}${location.search}`}
          />
        )}
      />
      {/* export queue: entry - /export-queue/entries => /export-queue */}
      <Route
        exact
        path={getPath('exportQueueEntry')}
        render={({ match, location }) => (
          <Redirect
            to={`${generatePath(getPath('exportQueue'), {
              orgId: match.params.orgId,
            })}${location.search}`}
          />
        )}
      />
      {/** exported tab */}
      {/* exported: transaction - /exported/transactions => /exported */}
      <Route
        exact
        path={getPath('exportedTransaction')}
        render={({ match, location }) => (
          <Redirect
            to={`${generatePath(getPath('exported'), {
              orgId: match.params.orgId,
            })}${location.search}`}
          />
        )}
      />
      {/* exported: entry - /exported/entries => /exported */}
      <Route
        exact
        path={getPath('exportedEntry')}
        render={({ match, location }) => (
          <Redirect
            to={`${generatePath(getPath('exported'), {
              orgId: match.params.orgId,
            })}${location.search}`}
          />
        )}
      />
    </>
  );
};

export default ExportRouteRedirect;
