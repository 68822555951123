import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import {
  ActionBox,
  ActionBoxActions,
  ActionBoxTitle,
  ArrowsClockwiseIcon,
  Button,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card, LimitChangeRequest, Team } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

export interface Props {
  card: Card;
  managerTeam?: Team;
  isCardholderApp?: boolean;
  onUpdate: (card: Card) => void;
}

const LimitChangeRequestDetails = ({
  card,
  managerTeam,
  isCardholderApp,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [
    limitChangeRequest,
    setLimitChangeRequest,
  ] = useState<LimitChangeRequest | null>(null);
  const confirmDialogRef = useRef({
    title: '',
    description: '',
    onSuccess: () => {},
  });

  const getLimitChangeRequest = async () => {
    try {
      setIsLoading(true);
      const data = await api.getLimitChangeRequest(card.limitChangeRequestId!);
      if (!mounted.current) return;
      setLimitChangeRequest(data);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const cancelRequest = async () => {
    try {
      setIsConfirming(true);
      await api.cancelLimitChangeRequest(card.limitChangeRequestId!);

      if (!mounted.current) return;
      onUpdate({
        ...card,
        limitChangeRequestId: null,
        requestedLimitRenewFrequency: null,
        requestedLimit: null,
        requestedTransactionLimit: null,
      });
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsConfirming(false);
      logError(error);
    }
  };

  const rejectRequest = async () => {
    try {
      setIsConfirming(true);
      await api.rejectLimitChangeRequest(card.limitChangeRequestId!);

      if (!mounted.current) return;
      onUpdate({
        ...card,
        limitChangeRequestId: null,
        requestedLimitRenewFrequency: null,
        requestedLimit: null,
        requestedTransactionLimit: null,
      });
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsConfirming(false);
      logError(error);
    }
  };

  const approveRequest = async () => {
    try {
      setIsConfirming(true);
      await api.approveLimitChangeRequest(card.limitChangeRequestId!, {
        approvedLimitRenewFrequency:
          card.requestedLimitRenewFrequency ?? card.limitRenewFrequency,
        approvedLimit: card.requestedLimit ?? card.limit,
        approvedTransactionLimit:
          card.requestedTransactionLimit ?? card.transactionLimit,
      });
      const data = await api.getCard(card.cardId);

      if (!mounted.current) return;
      onUpdate(data);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsConfirming(false);
      logError(error);
    }
  };

  useEffect(() => {
    getLimitChangeRequest();
  }, [card.limitChangeRequestId]);

  const canApproveRequest =
    limitChangeRequest &&
    card &&
    canUser(
      'card-limit:approve-change-request',
      card,
      Math.max(card.limit.value, limitChangeRequest.requestedLimit?.value ?? 0),
      managerTeam
    );

  const renderActions = () => {
    if (isCardholderApp)
      return (
        <Button
          onClick={() => {
            confirmDialogRef.current = {
              title: t('limitChangeRequestDetails.cancelConfirmDialog.title'),
              description: t(
                'limitChangeRequestDetails.cancelConfirmDialog.description'
              ),
              onSuccess: cancelRequest,
            };
            setIsConfirmDialogOpen(true);
          }}
          color="error"
        >
          {t('limitChangeRequestDetails.cancelRequest')}
        </Button>
      );

    if (canApproveRequest)
      return (
        <>
          <Button
            onClick={() => {
              confirmDialogRef.current = {
                title: t('limitChangeRequestDetails.rejectConfirmDialog.title'),
                description: t(
                  'limitChangeRequestDetails.rejectConfirmDialog.description'
                ),
                onSuccess: rejectRequest,
              };
              setIsConfirmDialogOpen(true);
            }}
            color="error"
          >
            {t('common.button.reject')}
          </Button>
          <Button
            onClick={() => {
              confirmDialogRef.current = {
                title: t(
                  'limitChangeRequestDetails.approveConfirmDialog.title'
                ),
                description: t(
                  'limitChangeRequestDetails.approveConfirmDialog.description'
                ),
                onSuccess: approveRequest,
              };
              setIsConfirmDialogOpen(true);
            }}
            color="success"
          >
            {t('common.button.approve')}
          </Button>
        </>
      );

    return (
      <Typography variant="body2" color="text.secondary">
        {t('limitChangeRequestDetails.missingPermission')}
      </Typography>
    );
  };

  return (
    <>
      <ActionBox
        icon={
          (!isLoading || !!limitChangeRequest) && (
            <ArrowsClockwiseIcon fontSize="inherit" />
          )
        }
        sx={{
          position: 'relative',
          minHeight: 110,
          borderRadius: isCardholderApp ? 1 : 0,
        }}
      >
        {limitChangeRequest && (
          <>
            <ActionBoxTitle sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="inherit" flexGrow={1} noWrap>
                {t('limitChangeRequestDetails.title')}
              </Typography>
              <Typography variant="caption" flexShrink={0} ml={0.5}>
                {moment(limitChangeRequest.createdAt).format(
                  'D MMM YYYY HH:mm'
                )}
              </Typography>
            </ActionBoxTitle>
            {limitChangeRequest.creatorComment}
            <ActionBoxActions>{renderActions()}</ActionBoxActions>
          </>
        )}
        <LoaderWithOverlay loading={isLoading} size="small" />
      </ActionBox>

      <ConfirmDialog
        open={isConfirmDialogOpen}
        loading={isConfirming}
        onClose={() => setIsConfirmDialogOpen(false)}
        onSuccess={confirmDialogRef.current.onSuccess}
        title={confirmDialogRef.current.title}
        description={confirmDialogRef.current.description}
      />
    </>
  );
};

export default LimitChangeRequestDetails;
