import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'elements';

interface Props {
  onClose: () => void;
}

const ChangePinSuccessStep = ({ onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle>{t('pinDialog.finalizePinChangeAtAtm')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {t('pinDialog.confirmChangeAtAtmRequest')}
        </Typography>
        <Box mt={4} mb={4}>
          <Alert severity="warning">
            <AlertTitle>{t('pinDialog.confirmChangeAtAtmWarning')}</AlertTitle>
            <ol>
              <li>{t('pinDialog.confirmChangeFirstStep')}</li>
              <li>{t('pinDialog.confirmChangeSecondStep')}</li>
            </ol>
          </Alert>
        </Box>
        <Typography variant="body2">
          {t('pinDialog.atmSystemErrorHint')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.button.close')}</Button>
      </DialogActions>
    </>
  );
};

export default ChangePinSuccessStep;
