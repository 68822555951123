import { ComponentType, FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TransactionExportStatus } from 'services/constants';
import { getPath } from 'services/utils';

const withInitialData = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element => {
  const { path } = useRouteMatch();

  const exportStatus = useMemo(() => {
    if (path === getPath('exportQueue')) {
      return TransactionExportStatus.inExportQueue;
    }
    if (path === getPath('exported')) return TransactionExportStatus.exported;
    return TransactionExportStatus.notExported;
  }, [path]);

  return (
    <Component
      {...props}
      // as we silently change route, we should be sure
      // all states are reset
      key={exportStatus}
      exportStatus={exportStatus}
    />
  );
};

export default withInitialData;
