import { FC, ReactNode, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, EditButton } from 'components/Button';
import { useOnboardingContext } from 'components/OnboardingPage/useOnboardingContext';
import { useGlobalState } from 'context/GlobalState';
import { VatIdChangeDialog } from 'domains/organization/dialogs';
import { StatusBadge } from 'elements';
import { Onboarding, Organization } from 'services/constants';
import { Group, GroupLabel, ItemParagraph } from './style';

interface VatIdFormGroupProps {
  onboarding: Onboarding;
  label: ReactNode;
}

const VatIdFormGroup: FC<VatIdFormGroupProps> = ({ onboarding, label }) => {
  const { t } = useTranslation();
  const {
    actions: { updateOnboarding },
    state: { vatIdRequired },
  } = useOnboardingContext();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onUpdated = (
    updatedOrganizationData: Organization,
    vatIdRequired: boolean
  ) => {
    dispatch({
      type: 'SET_ORGANIZATION_DATA',
      payload: { organization: updatedOrganizationData },
    });
    updateOnboarding({ vatId: updatedOrganizationData.vatId }, vatIdRequired);
    setIsDialogOpen(false);
  };

  const onCancelEdit = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Group $alignItems="center">
        <GroupLabel $lineHeight={undefined}>{label}</GroupLabel>

        <Box display="flex" alignItems="center">
          {vatIdRequired && !organization!.vatId ? (
            <>
              <Button variant="outlined" onClick={() => setIsDialogOpen(true)}>
                {t('onboardingPage.addInfoBtn')}
              </Button>
              <StatusBadge
                sx={{ ml: 2 }}
                label={t('common.badge.informationRequired')}
                color="warning"
              />
            </>
          ) : (
            <>
              <ItemParagraph>{onboarding.vatId || '-'}</ItemParagraph>
              <EditButton
                $m="0 0 0 10px"
                onClick={() => setIsDialogOpen(true)}
              />
            </>
          )}
        </Box>
      </Group>

      <VatIdChangeDialog
        isVatIdRequired={vatIdRequired}
        open={isDialogOpen}
        onSuccess={onUpdated}
        onClose={onCancelEdit}
      />
    </>
  );
};

export default VatIdFormGroup;
