import { useTranslation } from 'react-i18next';
import { Box, FileXIcon, Typography } from 'elements';
import { ContentContainer, PageContent, PageHeader, PageTitle } from 'layout';

const OrganizationOnboardingForbiddenPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader>
        <ContentContainer mx="auto" size="sm">
          <PageTitle title={t('orgOnboardingPage.title')} />
        </ContentContainer>
      </PageHeader>

      <PageContent display="flex" flexDirection="column">
        <ContentContainer mx="auto" size="sm" width="100%" flex={1}>
          <Box
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            color="text.disabled"
          >
            <FileXIcon color="inherit" fontSize="large" />
            <Typography
              color="text.disabled"
              variant="subtitle1"
              mt={1}
              maxWidth={255}
              textAlign="center"
            >
              {t('portal.orgOnboardingPage.onboardingNotAllowed')}
            </Typography>
          </Box>
        </ContentContainer>
      </PageContent>
    </>
  );
};

export default OrganizationOnboardingForbiddenPage;
