import { useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  DialogActions,
  DialogContent,
  LoaderWithOverlay,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

interface State {
  isLoading: boolean;
  errorMessage?: string;
  incompleteForm: boolean;
}

const Step2 = (props: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const stripe = useStripe();
  const elements = useElements();
  const [state, setState] = useState<State>({
    isLoading: false,
    errorMessage: '',
    incompleteForm: false,
  });

  const handleSubmit = async () => {
    setState({ ...state, isLoading: true });

    try {
      const response = await stripe!.confirmSetup({
        elements: elements!,
        redirect: 'if_required',
      });

      if (response.error) {
        if (!mounted.current) return;
        setState({ ...state, isLoading: false });
        enqueueSnackbar(response.error.message, { variant: 'error' });
      } else {
        setTimeout(() => {
          if (!mounted.current) return;
          setState({ ...state, isLoading: false });
          enqueueSnackbar(t('addPrivateCardDialog.messages.cardAddedSuccess'), {
            variant: 'success',
          });
          props.onSuccess();
        }, 2000);
      }
    } catch (error) {
      if (!mounted.current) return;
      setState({ ...state, isLoading: false });
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <DialogContent>
        <PaymentElement
          options={{
            defaultValues: {
              billingDetails: {
                address: {
                  country: organization?.billingAddress.country,
                },
              },
            },
            wallets: {
              applePay: 'never',
              googlePay: 'never',
            },
          }}
          onChange={(event) => {
            setState((prevState) => ({
              ...prevState,
              incompleteForm: event.complete === false,
            }));
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="text"
          children={t('common.button.cancel')}
        />
        <Button
          onClick={handleSubmit}
          disabled={
            !stripe || !elements || state.isLoading || state.incompleteForm
          }
          children={t('common.button.confirm')}
        />
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </>
  );
};

export default Step2;
