import { styled } from '@mui/material';
import { omit } from 'lodash';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { Box, Typography } from 'elements';
import { OnboardingDocument } from 'services/constants';

const BoldDetail = styled('b')`
  font-weight: 600;
`;

const UnorderedList = styled('ul')`
  margin: 0;
  padding-inline-start: {(theme) => theme.spacing(3)};
`;

interface Props {
  altName?: string;
  attributes: OnboardingDocument['attributes'];
  customLabel: OnboardingDocument['customLabel'];
  customLabelLocal?: OnboardingDocument['customLabelLocal'];
  name: OnboardingDocument['type']['name'] | string;
}

const OnboardingDocumentTitle = ({
  altName,
  attributes,
  customLabel,
  customLabelLocal,
  name,
}: Props) => {
  if (customLabel || customLabelLocal) {
    return (
      <Box display="flex" flexDirection="column">
        {customLabelLocal && <Typography>{customLabelLocal}</Typography>}
        {customLabel && (
          <Typography color="grey.500" variant="caption">
            {customLabel}
          </Typography>
        )}
      </Box>
    );
  }
  if (customLabel) return <span>{customLabel}</span>;

  if (!name && altName) return <span>{altName}</span>;

  return (
    <span>
      {
        <Trans
          i18nKey={`onboardingDocumentType.${name}`}
          values={{
            ...omit(attributes, ['fromMonthYear', 'toMonthYear']),
            ...(attributes['fromMonthYear'] && {
              fromMonthYear: moment(attributes.fromMonthYear).format(
                'MMM yyyy'
              ),
            }),
            ...(attributes['toMonthYear'] && {
              toMonthYear: moment(attributes.toMonthYear).format('MMM yyyy'),
            }),
          }}
          components={{
            b: <BoldDetail />,
            li: <li />,
            ul: <UnorderedList />,
          }}
        />
      }
    </span>
  );
};

export default OnboardingDocumentTitle;
