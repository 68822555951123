import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'elements';
import { AmlRiskScoring } from 'services/constants';

interface Props {
  amlRiskScoringDate?: string;
  amlRiskScoringResult?: AmlRiskScoring;
  sanctionListCheckDate?: string;
  sanctionListCheckResult?: 'OK' | null;
}

const ComplianceScreeningItem = ({
  amlRiskScoringDate,
  amlRiskScoringResult,
  sanctionListCheckDate,
  sanctionListCheckResult,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item container>
        <Grid item xs={2} />
        <Grid item xs={2}>
          <Typography variant="caption" color="textSecondary">
            {t('int.complianceScreening.dateLabel')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption" color="textSecondary">
            {t('int.complianceScreening.resultLabel')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={2}>
          <Typography variant="body2" color="textSecondary">
            {t('int.complianceScreening.sanctionLabel')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            {sanctionListCheckDate &&
              moment(sanctionListCheckDate).format('DD.MM.YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            color={
              sanctionListCheckResult
                ? sanctionListCheckResult === 'OK'
                  ? 'success.main'
                  : 'error.main'
                : undefined
            }
            fontWeight="bold"
            variant="body2"
          >
            {sanctionListCheckResult}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={2}>
          <Typography variant="body2" color="textSecondary">
            {t('int.complianceScreening.amlLabel')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            {amlRiskScoringDate &&
              moment(amlRiskScoringDate).format('DD.MM.YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            color={
              amlRiskScoringResult &&
              ([AmlRiskScoring.low].includes(amlRiskScoringResult)
                ? 'success.main'
                : 'error.main')
            }
            fontWeight="bold"
            variant="body2"
          >
            {amlRiskScoringResult}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ComplianceScreeningItem;
