import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectsData } from 'domains/transaction/hooks';
import { Chip, Tooltip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { getFilterChipText } from 'layout';

interface Props {
  projectData: ProjectsData;
  projectIdsParams: string[];
  setParam: SetQueryParam;
}

const ProjectFilterChip = ({
  projectData,
  projectIdsParams,
  setParam,
}: Props) => {
  const { t } = useTranslation();

  const projectValidChipText = useMemo(() => {
    if (projectData.isLoading) return t('common.loading');
    return projectData.value.length ? projectData.value[0].name : '';
  }, [projectIdsParams, projectData]);

  const projectValidChipTooltip = useMemo(() => {
    if (projectData.value.length < 2) return '';
    return projectData.value.map((project) => project.name).join(', ');
  }, [projectIdsParams, projectData]);

  return (
    <>
      {(projectData.isLoading || !!projectData.value.length) && (
        <Tooltip title={projectValidChipTooltip}>
          <Chip
            label={getFilterChipText(
              projectValidChipText,
              projectData.isLoading ? 0 : projectData.value.length,
              t('filters.project')
            )}
            onDelete={() => setParam('projectIds', [])}
          />
        </Tooltip>
      )}
      {projectData.isError && (
        <Chip
          label={getFilterChipText(
            t('filters.notFound'),
            projectData.invalid.length,
            t('filters.project')
          )}
          color="error"
          onDelete={() =>
            setParam(
              'projectIds',
              projectData.value.map((items) => items.id)
            )
          }
        />
      )}
    </>
  );
};

export default ProjectFilterChip;
