import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useGlobalState, useVisibleCardAccounts } from 'context/GlobalState';
import { CardAccountFilter } from 'domains/card/components';
import {
  FlagReasonFilter,
  ReceiptFilter,
  ReviewFilter,
  TransactionSimpleTypeFilter,
} from 'domains/transaction/components';
import {
  MISSING_SUPPLIER_OPTION,
  MISSING_TEAM_OPTION,
  QueryParams,
  visibleTransactionTypes,
} from 'domains/transaction/pages/ExportPage/utils';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import {
  NONE_VALUE,
  PrivateExpenseStatus,
  privateExpenseStatuses,
  ProjectStatus,
  ReviewFlagReason,
  SubcategoryStatus,
  TransactionReceiptStatus,
  TransactionReviewStatus,
  TransactionSimpleType,
  VatRateStatus,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import ProjectFilter from './ProjectFilter';
import SubcategoryFilter from './SubcategoryFilter';
import SupplierFilter from './SupplierFilter';
import TeamsFilter from './TeamsFilter';
import VatRateFilter from './VatRateFilter';
import {
  ProjectData,
  SubcategoryData,
  SupplierData,
  TeamData,
  VatRateData,
} from './useFilters';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  vatRateData: VatRateData;
  supplierData: SupplierData;
  projectData: ProjectData;
  teamData: TeamData;
  subcategoryData: SubcategoryData;
  setParam: SetQueryParam;
  setFilters: ({
    vatRate,
    supplier,
    project,
    team,
    subcategory,
  }: {
    vatRate: { value: VatRateData['value'] };
    supplier: { value: SupplierData['value'] };
    project: { value: ProjectData['value'] };
    team: { value: TeamData['value'] };
    subcategory: { value: SubcategoryData['value'] };
  }) => void;
  onClose: () => void;
}

interface State {
  type: TransactionSimpleType[];
  receipt: TransactionReceiptStatus | '';
  supplier: SupplierData['value'];
  subcategory: SubcategoryData['value'];
  vatRate: VatRateData['value'];
  project: ProjectData['value'];
  team: TeamData['value'];
  fromDate: Moment | null;
  toDate: Moment | null;
  reviewStatus: TransactionReviewStatus | typeof NONE_VALUE | '';
  flagReason: ReviewFlagReason | '';
  privateExpenseStatus: PrivateExpenseStatus[];
  cardAccountId: string;
}

const initialState: State = {
  type: [],
  receipt: '',
  supplier: null,
  subcategory: null,
  vatRate: null,
  project: null,
  team: null,
  fromDate: null,
  toDate: null,
  reviewStatus: '',
  flagReason: '',
  privateExpenseStatus: [],
  cardAccountId: '',
};

const FiltersDrawer = ({
  params,
  vatRateData,
  supplierData,
  projectData,
  teamData,
  subcategoryData,
  setParam,
  setFilters,
  ...props
}: Props) => {
  const { companyReimbursementEnabled, suppliersEnabled } = useFlags();
  const {
    state: { accountingSettings, featureModules },
  } = useGlobalState();
  const visibleCardAccounts = useVisibleCardAccounts();
  const [state, setState] = useState<State>(initialState);
  const accountingFeaturesEnabled = featureModules.ACCOUNTING_FEATURES;

  const areFiltersSelected = !!(
    state.type.length ||
    state.receipt ||
    supplierData.value ||
    subcategoryData.value ||
    vatRateData.value ||
    projectData.value ||
    teamData.value ||
    state.fromDate ||
    state.toDate ||
    state.reviewStatus ||
    state.flagReason ||
    state.privateExpenseStatus.length ||
    state.cardAccountId
  );

  useEffect(() => {
    if (props.open)
      setState({
        type: params.type,
        receipt: params.receipt,
        supplier: supplierData.value,
        subcategory: subcategoryData.value,
        vatRate: vatRateData.value,
        project: projectData.value,
        team: teamData.value,
        fromDate: params.fromDate,
        toDate: params.toDate,
        reviewStatus: params.reviewStatus,
        flagReason: params.flagReason,
        privateExpenseStatus: params.privateExpenseStatus,
        cardAccountId: params.cardAccountId,
      });
  }, [props.open]);

  const onFiltersApply = () => {
    const filtersToApply = {
      ...state,
      fromDate: state.fromDate?.startOf('day')?.format(),
      toDate: state.toDate?.endOf('day')?.format(),
      vatRate:
        state.vatRate === VatRateStatus.missing
          ? VatRateStatus.missing
          : state.vatRate?.id ?? '',
      supplier:
        state.supplier === MISSING_SUPPLIER_OPTION
          ? MISSING_SUPPLIER_OPTION
          : state.supplier?.id ?? '',
      project:
        state.project === ProjectStatus.missing
          ? ProjectStatus.missing
          : state.project?.id ?? '',
      team:
        state.team === MISSING_TEAM_OPTION
          ? MISSING_TEAM_OPTION
          : state.team?.id ?? '',
      subcategory:
        state.subcategory === SubcategoryStatus.missing
          ? SubcategoryStatus.missing
          : state.subcategory?.id ?? '',
    };

    setParam(
      Object.keys(filtersToApply).map((key) => [
        key,
        filtersToApply[key as keyof State],
      ])
    );
    setFilters({
      vatRate: { value: state.vatRate },
      supplier: { value: state.supplier },
      project: { value: state.project },
      team: { value: state.team },
      subcategory: { value: state.subcategory },
    });
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.keys(state).map((key) => [key, '']));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <TransactionSimpleTypeFilter
        value={state.type}
        onChange={(value) =>
          setState((prevState) => ({
            ...prevState,
            type: xor(prevState.type, [value]),
          }))
        }
        options={visibleTransactionTypes}
      />

      <DateFilter
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate: fromDate?.startOf('day') || null,
            toDate: toDate?.endOf('day') || null,
          }))
        }
      />

      {visibleCardAccounts.length > 1 && (
        <CardAccountFilter
          value={state.cardAccountId}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, cardAccountId: value }))
          }
        />
      )}

      {accountingFeaturesEnabled &&
        suppliersEnabled &&
        accountingSettings?.supplierEnabled && (
          <SupplierFilter
            value={state.supplier}
            supplierData={supplierData}
            onChange={(value) =>
              setState((prevState) => ({ ...prevState, supplier: value }))
            }
          />
        )}

      {featureModules.RECEIPT_MANAGEMENT && (
        <ReceiptFilter
          value={state.receipt}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, receipt: value }))
          }
        />
      )}

      <SubcategoryFilter
        value={state.subcategory}
        subcategoryData={subcategoryData}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, subcategory: value }))
        }
      />

      {accountingSettings?.vatRateEnabled && (
        <VatRateFilter
          value={state.vatRate}
          vatRateData={vatRateData}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, vatRate: value }))
          }
        />
      )}

      {featureModules.TEAMS && (
        <TeamsFilter
          value={state.team}
          teamData={teamData}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, team: value }))
          }
        />
      )}

      {accountingFeaturesEnabled && accountingSettings?.projectEnabled && (
        <ProjectFilter
          value={state.project}
          projectData={projectData}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, project: value }))
          }
        />
      )}

      <ReviewFilter
        value={state.reviewStatus}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, reviewStatus: value }))
        }
      />

      <FlagReasonFilter
        value={state.flagReason}
        onChange={(flagReason) =>
          setState((prevState) => ({
            ...prevState,
            flagReason,
            privateExpenseStatus:
              flagReason === ReviewFlagReason.privateExpense &&
              featureModules.COMPANY_REIMBURSEMENT &&
              companyReimbursementEnabled
                ? [...privateExpenseStatuses]
                : [],
          }))
        }
        privateExpenseStatusesValue={state.privateExpenseStatus}
        onPrivateExpenseStatusChange={(values) =>
          setState((prevState) => ({
            ...prevState,
            privateExpenseStatus: xor(prevState.privateExpenseStatus, values),
          }))
        }
      />
    </FilterDrawer>
  );
};

export default FiltersDrawer;
