import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddCustomCashbackAmountDialog } from 'domains/terms/dialogs';
import { Box, Button, Tooltip, Typography } from 'elements';
import { CurrencyCashbackByCurrencyMap } from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  cashbackByCurrency: CurrencyCashbackByCurrencyMap;
}

const CustomCashbackAmountSection = ({ cashbackByCurrency }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Typography variant="h6" mb={1}>
        {t('int.cashbackSettingsPage.customCashbackAmountSection.title')}
      </Typography>
      <Tooltip
        title={
          !canUser('cashback:add') &&
          t(
            'int.cashbackSettingsPage.customCashbackAmountSection.addCashbackTooltip'
          )
        }
      >
        <Box display="inline-block">
          <Button
            onClick={() => setIsDialogOpen(true)}
            disabled={!canUser('cashback:add')}
            variant="outlined"
          >
            {t(
              'int.cashbackSettingsPage.customCashbackAmountSection.addCashback'
            )}
          </Button>
        </Box>
      </Tooltip>
      <AddCustomCashbackAmountDialog
        open={isDialogOpen}
        cashbackByCurrency={cashbackByCurrency}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default CustomCashbackAmountSection;
