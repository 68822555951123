import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Card,
  CardType,
  CardVelocityGroupLimits,
  physicalCardVelocityGroupLimit,
  virtualCardVelocityGroupLimit,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const getOptionsByCardType = (type: string) => {
  switch (type) {
    case CardType.black:
    case CardType.plastic:
      return physicalCardVelocityGroupLimit;
    case CardType.virtual:
      return virtualCardVelocityGroupLimit;
    default:
      return [];
  }
};

interface FormValues {
  group: CardVelocityGroupLimits | null;
}

export interface Props extends DialogProps {
  card: Card;
  onClose: () => void;
  onSuccess: (card: Card) => void;
}

const UpdateCardVelocityGroupDialog = ({
  card,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const formik = useFormik<FormValues>({
    initialValues: {
      group: card.velocityLimitGroup,
    },
    onSubmit: async ({ group }, { setSubmitting }) => {
      try {
        const data = await api.updateCardVelocityGroup(card.cardId, {
          velocityLimitGroup: group!,
        });
        if (!mounted.current) return;
        setSubmitting(false);
        onSuccess(data);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setSubmitting(false);
        logError(error);
      }
    },
  });

  const options = getOptionsByCardType(card.type);

  const isSubmitDisabled = !formik.values.group || formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('updateCardVelocityGroupDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="update-card-velocity-form"
          noValidate
        >
          <FormControl disabled={formik.isSubmitting}>
            <FormLabel>{t('updateCardVelocityGroupDialog.subtitle')}</FormLabel>
            <RadioGroup {...formik.getFieldProps('group')}>
              {options.map((group) => (
                <FormControlLabel
                  key={group.key}
                  value={group.value}
                  control={<Radio />}
                  label={t(`cardVelocityGroupLimit.${group.key}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="update-card-velocity-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(UpdateCardVelocityGroupDialog);
