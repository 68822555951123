import { useFormik } from 'formik';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  MoneyField,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getMoneyObject,
  getNetworkErrorCode,
} from 'services/utils';

interface FormValues {
  fee: number | undefined;
  totalCardsCount: number | undefined;
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
}

const CreatePremiumCardFeeTierDialog = ({ onSuccess, ...props }: Props) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      fee: undefined,
      totalCardsCount: 1,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await api.createPremiumCardFeeTier({
          organizationId: organization!.id,
          fee: getMoneyObject(values.fee! * 100),
          totalCardsCount: values.totalCardsCount!,
        });
        if (!mounted.current) return;
        onSuccess();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        const errorMessageKey = `int.createUpdatePremiumCardFeeTierDialog.errors.${getNetworkErrorCode(
          error
        )}`;
        if (i18n.exists(errorMessageKey)) {
          enqueueSnackbar(t(errorMessageKey), { variant: 'error' });
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const isSubmitDisabled =
    formik.values.fee === undefined ||
    formik.values.totalCardsCount === undefined ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('int.createUpdatePremiumCardFeeTierDialog.titleCreate', {
          cardName: t('cardNames.PLIANT_BLACK'),
        })}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="create-premium-card-fee-tier-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              <MoneyField
                label={t('int.createUpdatePremiumCardFeeTierDialog.annualFee')}
                placeholder={t(
                  'int.createUpdatePremiumCardFeeTierDialog.annualFee'
                )}
                disabled={formik.isSubmitting}
                isAllowed={({ floatValue }) =>
                  floatValue === undefined || floatValue <= 1000
                }
                {...omit(formik.getFieldProps('fee'), 'onChange')}
                onValueChange={({ floatValue }) =>
                  formik.setFieldValue('fee', floatValue)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <NumberFormat
                customInput={TextField}
                label={t('int.createUpdatePremiumCardFeeTierDialog.count')}
                placeholder={t(
                  'int.createUpdatePremiumCardFeeTierDialog.count'
                )}
                disabled={formik.isSubmitting}
                decimalScale={0}
                allowNegative={false}
                isAllowed={({ floatValue }) =>
                  floatValue === undefined ||
                  (floatValue >= 1 && floatValue <= 1000)
                }
                {...omit(formik.getFieldProps('totalCardsCount'), 'onChange')}
                onValueChange={({ floatValue }) => {
                  formik.setFieldValue('totalCardsCount', floatValue);
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="create-premium-card-fee-tier-form"
          type="submit"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(CreatePremiumCardFeeTierDialog);
