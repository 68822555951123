import { useTranslation } from 'react-i18next';
import { OnboardingDocumentStatus } from 'services/constants';
import { StatusBadge } from './StatusBadge';

export const OnboardingDocumentStatusBadge = ({
  status,
}: {
  status: OnboardingDocumentStatus | 'REJECTED';
}) => {
  const { t } = useTranslation();

  switch (status) {
    case OnboardingDocumentStatus.DRAFT:
      return (
        <StatusBadge type="default">
          {t(`onboardingDocumentStatuses.${status}`)}
        </StatusBadge>
      );
    case OnboardingDocumentStatus.REQUESTED:
      return (
        <StatusBadge type="dark">
          {t(`onboardingDocumentStatuses.${status}`)}
        </StatusBadge>
      );
    case OnboardingDocumentStatus.SUBMITTED:
      return (
        <StatusBadge type="primary">
          {t(`onboardingDocumentStatuses.${status}`)}
        </StatusBadge>
      );
    case OnboardingDocumentStatus.ARCHIVED:
    case 'REJECTED':
      return (
        <StatusBadge type="secondary">
          {t(`onboardingDocumentStatuses.${status}`)}
        </StatusBadge>
      );
    default:
      return null;
  }
};
