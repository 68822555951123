import { useCallback, useEffect, useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import {
  isMultiValueMismatched,
  isValueMismatched,
} from 'domains/transaction/utils';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Project, ProjectStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

export interface ProjectData {
  value: Project | ProjectStatus.missing | null;
  invalid: string[] | [];
  isLoading: boolean;
  isError: boolean;
}

export interface ProjectsData {
  value: Project[];
  invalid: string[] | [];
  isLoading: boolean;
  isError: boolean;
}

const getInitialState = <T extends string | string[]>(
  isSingleSelect: boolean
) => {
  return (isSingleSelect
    ? { value: null, invalid: [], isLoading: false, isError: false }
    : {
        value: [],
        invalid: [],
        isLoading: false,
        isError: false,
      }) as T extends string ? ProjectData : ProjectsData;
};

export const useProjectFilter = <T extends string | string[]>(
  projectParam: T
) => {
  const isSingleSelect =
    projectParam === undefined || typeof projectParam === 'string';
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [projectData, setProjectData] = useState(
    getInitialState<T>(isSingleSelect)
  );

  const getData = async () => {
    if (projectParam === ProjectStatus.missing) {
      setProjectData((prevState) => ({
        ...prevState,
        value: ProjectStatus.missing,
      }));
      return;
    }

    setProjectData((prevState) => ({ ...prevState, isLoading: true }));
    try {
      if (!mounted.current) return;
      const { valid, invalid } = await api.checkProjects({
        organizationId: organization!.id,
        projectIds: isSingleSelect ? [projectParam] : projectParam,
      });
      setProjectData((prevState) => ({
        ...prevState,
        value: isSingleSelect ? valid[0] || null : valid,
        invalid: invalid,
        isError: !!invalid.length,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setProjectData((prevState) => ({
        ...prevState,
        isLoading: false,
        isError: true,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), {
        variant: 'error',
      });
      logError(error);
    }
  };

  useEffect(() => {
    // automatically reset state when no param
    if (!projectParam || (!isSingleSelect && !projectParam.length)) {
      setProjectData((prevState) => ({
        ...prevState,
        value: isSingleSelect ? null : [],
        isError: false,
        invalid: [],
      }));
      return;
    }
    // automatically reset error when valid params exist
    else if (
      !isSingleSelect &&
      projectData.invalid.length &&
      !projectParam.includes(projectData.invalid[0])
    ) {
      setProjectData((prevState) => ({
        ...prevState,
        invalid: [],
        isError: false,
      }));
      return;
    }

    const isValueChanged = isSingleSelect
      ? isValueMismatched(projectParam, (projectData as ProjectData).value)
      : isMultiValueMismatched(
          projectParam,
          (projectData as ProjectsData).value
        );
    if (isValueChanged) getData();
  }, [JSON.stringify(projectParam)]);

  const setProjectFilter = useCallback(
    (data: Pick<ProjectData | ProjectsData, 'value'>) => {
      setProjectData((prevState) => ({
        ...prevState,
        ...data,
        isError: false,
        invalid: [],
      }));
    },
    []
  );

  return {
    projectData,
    setProjectFilter,
  };
};
