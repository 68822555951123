import { memo } from 'react';
import { TeamBadge } from 'domains/member/components';
import { Chip, DataGridCell } from 'elements';

const MAX_TEAMS = 2;

interface TeamDetails {
  teamId: string;
  name: string;
  teamManager?: boolean;
}

interface Props {
  teams: TeamDetails[];
}

const TeamsCell = ({ teams }: Props) => {
  return (
    <DataGridCell>
      {teams.slice(0, MAX_TEAMS).map((item) => (
        <TeamBadge
          key={item.teamId}
          name={item.name}
          isManager={item.teamManager}
        />
      ))}
      {teams.length > MAX_TEAMS && (
        <Chip
          label={`+${teams.length - MAX_TEAMS}`}
          size="small"
          color="primary"
        />
      )}
    </DataGridCell>
  );
};

export default memo(TeamsCell);
