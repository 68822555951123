import { useState } from 'react';
import { Box } from '@mui/material';
import { omit } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DownloadButton2Icon from 'assets/icons/interface/downloadButton2.svg?react';
import { Button } from 'components/Button';
import { LoaderWithOverlay } from 'components/Loader';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

export const Heading = styled.h3`
  font-size: 16px;
  line-height: 20px;
  margin: 0 16px 0 0;
`;

const CrmInconsistenciesSection = () => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const downloadAsCsv = async () => {
    try {
      setIsLoading(true);
      const { inconsistencies } = await api.getCrmInconsistencies();

      if (inconsistencies.length === 0) {
        enqueueSnackbar(
          t(
            'int.crmIntegrationPage.crmInconsistenciesSection.noInconsistenciesToast'
          ),
          { variant: 'info' }
        );

        if (!mounted.current) return;
        setIsLoading(false);
        return;
      }

      const items = [];
      for (const record of inconsistencies) {
        for (const diff of record.diffs) {
          items.push({
            ...omit(record, 'diffs'),
            ...diff,
          });
        }
      }

      const csvString = `data:text/csv;chatset=utf-8,${encodeURIComponent(
        [
          Object.keys(items[0]).join(','),
          ...items.map((item) => Object.values(item).join(',')),
        ].join('\n')
      )}`;
      const link = document.createElement('a');
      link.href = csvString;

      link.download = `${moment(new Date()).format(
        'YYYYMMDD'
      )}_crm_inconsistencies.csv`;
      link.click();

      if (!mounted.current) return;
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsLoading(false);
      logError(error);
    }
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="baseline">
      <Heading>
        {t('int.crmIntegrationPage.crmInconsistenciesSection.title')}
      </Heading>

      <Box position="relative">
        <Button variant="outlined" size="small" onClick={downloadAsCsv}>
          <Box component="span" marginRight="8px">
            {t(
              'int.crmIntegrationPage.crmInconsistenciesSection.downloadButton'
            )}
          </Box>
          <DownloadButton2Icon />
        </Button>

        {isLoading && <LoaderWithOverlay size={20} thickness={3} />}
      </Box>
    </Box>
  );
};

export default CrmInconsistenciesSection;
