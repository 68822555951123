import { useEffect, useRef, useState } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import {
  MerchantInfoAccordion,
  MerchantLogo,
  MerchantTransactionsCountAndVolume,
  RelatedMerchants,
} from 'domains/merchant/components';
import TeamsList from 'domains/merchant/pages/OrganizationMerchantDetailsPage/TeamsList/TeamsList';
import { LastTransactionsList } from 'domains/transaction/components';
import { Box, Link, LoaderWithOverlay, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  MerchantCategory,
  MerchantVolumeItem,
  OrganizationMerchant,
  Transaction,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getPath } from 'services/utils';
import AccountingRules from './AccountingRules';
import MerchantVolumeChart from './MerchantVolumeChart';

const MAX_VISIBLE_CATEGORIES = 3;

const getCategoriesList = (
  t: TFunction,
  categories: MerchantCategory[]
): string => {
  return (
    categories
      .slice(0, MAX_VISIBLE_CATEGORIES)
      .map((category) => t(`merchantCategories.${category}`))
      .join(', ') +
    (categories.length > MAX_VISIBLE_CATEGORIES
      ? `, +${categories.length - MAX_VISIBLE_CATEGORIES}`
      : '')
  );
};

const generateSeeAllLink = (orgId: string, q: string) =>
  generatePath(getPath('transactions'), { orgId }) + `?q=` + q;

interface State {
  isLoading: boolean;
  merchant: OrganizationMerchant | null;
  transactions: Transaction[];
  merchantVolumeItems: MerchantVolumeItem[];
  error: unknown;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (merchant: OrganizationMerchant) => void;
}

const OrganizationMerchantDetailsPage = ({ onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const api = useImperativeApi();
  const {
    state: { organization, featureModules },
  } = useGlobalState();
  const { merchantId } = useParams<{ merchantId: string }>();
  const idRef = useRef(merchantId);
  const mounted = useMounted();
  const [state, setState] = useState<State>({
    isLoading: true,
    merchant: null,
    transactions: [],
    merchantVolumeItems: [],
    error: null,
  });
  const orgId = organization!.id;

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const [
        merchant,
        { transactions },
        { items: merchantVolumeItems },
      ] = await Promise.all([
        api.getOrgMerchant(orgId, merchantId),
        api.getTransactions({
          organizationId: orgId,
          limit: 3,
          merchantId,
        }),
        api.getOrgMerchantVolume(orgId, merchantId),
      ]);
      if (!mounted.current || merchantId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        merchant,
        transactions,
        merchantVolumeItems,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || merchantId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error,
        merchant: null,
        merchantVolumeItems: [],
        transactions: [],
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!merchantId) return;
    idRef.current = merchantId;
    getData();
  }, [merchantId]);

  useEffect(() => {
    if (state.merchant) onUpdate(state.merchant);
  }, [state.merchant]);

  return (
    <DetailsDrawer {...props}>
      {state.merchant && (
        <>
          <DetailsDrawerHeader pb={0}>
            <Box display="flex" alignItems="center" mb={3}>
              <MerchantLogo
                size="large"
                url={state.merchant.logoPath}
                name={state.merchant.displayName}
              />
              <Box ml={2}>
                <Typography variant="h5">
                  {state.merchant.displayName}
                </Typography>
                <Typography variant="caption">
                  {getCategoriesList(t, state.merchant.transactionCategories)}
                </Typography>
              </Box>
            </Box>
            <MerchantInfoAccordion merchant={state.merchant} />
          </DetailsDrawerHeader>
          <DetailsDrawerContent>
            <RelatedMerchants
              generateMerchantLink={(merchantId) =>
                generatePath(getPath('merchantDetails'), {
                  orgId: organization!.id,
                  merchantId,
                }) + location.search
              }
              title={t('organizationMerchantDetailsPage.relatedMerchants')}
              merchants={state.merchant.relatedMerchants}
            />
            {featureModules.ACCOUNTING_FEATURES &&
              featureModules.ACCOUNTING_MERCHANT_RULES && (
                <AccountingRules
                  merchant={state.merchant}
                  onChange={(updatedResponse) => {
                    if (!mounted.current) return;
                    setState((prevState) => ({
                      ...prevState,
                      merchant: { ...state.merchant!, ...updatedResponse },
                    }));
                  }}
                />
              )}
            {featureModules.TEAMS && (
              <TeamsList
                title={t('organizationMerchantDetailsPage.teams')}
                teams={state.merchant.teamResponses}
              />
            )}

            <Box
              p={3}
              borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
            >
              <Typography variant="overline" component="div" py={0.5} mb={1}>
                {t('organizationMerchantDetailsPage.analytics')}
              </Typography>
              <MerchantTransactionsCountAndVolume
                transactionsCount={state.merchant.transactionsCount}
                transactionsVolume={state.merchant.transactionsVolume}
              />
              <Typography variant="overline" component="div" sx={{ mt: 3 }}>
                {t('organizationMerchantDetailsPage.merchantVolume')}
              </Typography>
              <MerchantVolumeChart data={state.merchantVolumeItems} />
            </Box>

            <Box p={3}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="overline" component="div" py={0.5}>
                  {t('organizationMerchantDetailsPage.lastTransactions')}
                </Typography>
                <Link
                  variant="body2"
                  component={RouterLink}
                  to={generateSeeAllLink(orgId, state.merchant.displayName)}
                >
                  {t('organizationMerchantDetailsPage.seeAll')}
                </Link>
              </Box>
              {state.transactions.length ? (
                <LastTransactionsList transactions={state.transactions} />
              ) : (
                <Typography my={1} color="textSecondary">
                  {t('organizationMerchantDetailsPage.noTransactions')}
                </Typography>
              )}
            </Box>
          </DetailsDrawerContent>
        </>
      )}
      {state.error && <WidgetError onReload={getData} />}
      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(OrganizationMerchantDetailsPage);
