import { useEffect, useState } from 'react';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LabeledValue,
  MenuItem,
  Select,
  Switch,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, SettingsGroupBox } from 'layout';
import {
  ForwardUnmatchedReceiptsInterval,
  ReceiptsSettings,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import ChangeUnmatchedReceiptForwardingDialog from './ChangeUnmatchedReceiptForwardingDialog';

interface State {
  forwardUnmatchedReceipts: boolean;
  isEmailChangeDialogOpen: boolean;
  isLoading: boolean;
}

interface Props {
  receiptsSettings: ReceiptsSettings;
}

const ReceiptInboxOptionsGroup = ({ receiptsSettings }: Props) => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    forwardUnmatchedReceipts: receiptsSettings.forwardUnmatchedReceipts,
    isEmailChangeDialogOpen: false,
    isLoading: false,
  });

  useEffect(() => {
    if (
      receiptsSettings.forwardUnmatchedReceipts !==
      state.forwardUnmatchedReceipts
    )
      setState((prevState) => ({
        ...prevState,
        forwardUnmatchedReceipts: receiptsSettings.forwardUnmatchedReceipts,
      }));
  }, [receiptsSettings.forwardUnmatchedReceipts]);

  const deactivateUnmatchedForwarding = async () => {
    setState((prevState) => ({
      ...prevState,
      forwardUnmatchedReceipts: false,
      isLoading: true,
    }));
    try {
      const updatedReceiptsSettings = await api.updateReceiptsSettings(
        organization!.id,
        {
          ...omit(receiptsSettings, 'organizationId'),
          forwardUnmatchedReceipts: false,
        }
      );

      dispatch({
        type: 'SET_USER_DATA',
        payload: {
          receiptsSettings: updatedReceiptsSettings,
        },
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        forwardUnmatchedReceipts: true,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const handleToggleChange = () => {
    if (receiptsSettings.forwardUnmatchedReceipts) {
      deactivateUnmatchedForwarding();
    } else {
      setState((prevState) => ({
        ...prevState,
        isEmailChangeDialogOpen: true,
      }));
    }
  };

  return (
    <>
      <SettingsGroupBox>
        <ContentContainer>
          <Typography variant="h6" mb={1}>
            {t('receiptManagementSubPage.receiptInboxOptions.title')}
          </Typography>

          <Box mt={2}>
            <FormControl
              fullWidth
              disabled={state.isLoading || !canUser('receipts-settings:change')}
            >
              <FormControlLabel
                labelPlacement="start"
                label={t(
                  'receiptManagementSubPage.receiptInboxOptions.switchUnmatchedForwarding'
                )}
                checked={state.forwardUnmatchedReceipts}
                onChange={handleToggleChange}
                control={<Switch />}
              />
            </FormControl>
          </Box>

          {receiptsSettings.forwardUnmatchedReceipts && (
            <>
              <Grid container columnSpacing={2} mt={4}>
                <Grid item xs={6}>
                  <LabeledValue
                    label={t(
                      'receiptManagementSubPage.receiptInboxOptions.emailLabel'
                    )}
                    value={receiptsSettings.forwardUnmatchedReceiptsToEmail}
                    onEdit={
                      canUser('receipts-settings:change')
                        ? () =>
                            setState((prevState) => ({
                              ...prevState,
                              isEmailChangeDialogOpen: true,
                            }))
                        : undefined
                    }
                  />
                </Grid>
                <Grid item xs={6} display="flex" alignItems="flex-end">
                  <FormControl
                    disabled={
                      state.isLoading || !canUser('receipts-settings:change')
                    }
                    sx={{ width: '200px' }}
                  >
                    <InputLabel>
                      {t('receiptManagementSubPage.forwardingFrequencyLabel')}
                    </InputLabel>
                    <Select
                      value={receiptsSettings.forwardUnmatchedReceiptsInterval}
                      renderValue={(selected) => {
                        return t(
                          `receiptManagementSubPage.receiptInboxOptions.intervalOption.${selected}`
                        );
                      }}
                    >
                      <MenuItem
                        value={ForwardUnmatchedReceiptsInterval.immediately}
                      >
                        {t('receiptManagementSubPage.immediateFrequencyOption')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
        </ContentContainer>
      </SettingsGroupBox>

      <ChangeUnmatchedReceiptForwardingDialog
        receiptsSettings={receiptsSettings}
        forwardingEmail={
          receiptsSettings.forwardUnmatchedReceiptsToEmail ||
          organization!.billingEmail
        }
        open={state.isEmailChangeDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isEmailChangeDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default ReceiptInboxOptionsGroup;
