import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CardAccount,
  CardAccountCurrency,
  NetworkErrorCode,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';

const MAX_ACCOUNT_NAME_LENGTH = 255;

interface FormValues {
  accountName: string;
  currency: CardAccountCurrency | '';
}

interface Props extends DialogProps {
  availableCurrencies: CardAccountCurrency[];
  onClose: () => void;
  onSuccess: (cardAccount: CardAccount) => void;
}

const CreateCardAccountDialog = ({
  onSuccess,
  availableCurrencies,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, featureModules },
  } = useGlobalState();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      accountName: '',
      currency: '',
    },
    onSubmit: async (values) => {
      try {
        const accountName = featureModules.MULTI_CARD_ACCOUNT
          ? values.accountName.trim()
          : values.currency;
        const currency = featureModules.MULTI_CURRENCY_BILLING
          ? (values.currency as CardAccountCurrency)
          : undefined;

        const cardAccount = await api.createCardAccount(
          organization!.id,
          accountName,
          currency
        );
        if (!mounted.current) return;
        onSuccess(cardAccount);
      } catch (error) {
        if (!mounted.current) return;
        const errorCode = getNetworkErrorCode(error);
        if (errorCode === NetworkErrorCode.bankSubmissionStatusError) {
          enqueueSnackbar(t(`createCardAccountDialog.errors.${errorCode}`), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    },
  });

  const isAccountNameTooLong =
    formik.values.accountName.trim().length > MAX_ACCOUNT_NAME_LENGTH;

  const isSubmitDisabled =
    formik.isSubmitting ||
    (featureModules.MULTI_CARD_ACCOUNT &&
      (!formik.values.accountName.trim() || isAccountNameTooLong)) ||
    (featureModules.MULTI_CURRENCY_BILLING && !formik.values.currency);

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('createCardAccountDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="create-card-account-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            {featureModules.MULTI_CARD_ACCOUNT && (
              <Grid item xs={12}>
                <TextField
                  {...formik.getFieldProps('accountName')}
                  label={t('createCardAccountDialog.accountName')}
                  error={isAccountNameTooLong}
                  helperText={
                    isAccountNameTooLong &&
                    t('createCardAccountDialog.accountNameTooLongError', {
                      limit: MAX_ACCOUNT_NAME_LENGTH,
                    })
                  }
                  autoFocus
                />
              </Grid>
            )}
            {featureModules.MULTI_CURRENCY_BILLING && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="currency-select-label">
                    {t('createCardAccountDialog.currency')}
                  </InputLabel>
                  <Select
                    {...formik.getFieldProps('currency')}
                    labelId="currency-select-label"
                  >
                    {availableCurrencies.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="create-card-account-form"
          type="submit"
        >
          {t('createCardAccountDialog.createAccount')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(CreateCardAccountDialog);
