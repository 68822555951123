import React, { useMemo } from 'react';
import { generatePath } from 'react-router';
import {
  portalOrgPaths,
  PortalOrgPathsKeys,
} from 'components/App/PortalApp/paths';
import withPartnerLogic from 'components/App/PortalApp/withPartnerLogic';
import { Container, ContainerInner, MainContent } from 'components/App/style';
import { PortalMainHeader } from 'components/MainHeader';
import Sidebar from 'components/Sidebar';
import { useGlobalState } from 'context/GlobalState';
import { isPartnerBasedSource } from 'domains/partner/utils';
import { Box } from 'elements';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { OrganizationStatus, PartnerWhitelabelLevel } from 'services/constants';
import MirrorViewAlert, { alertHeight } from './MirrorViewAlert';

const SIDEBAR_TABS: PortalOrgPathsKeys[] = [
  'dashboard',
  'cardAccounts',
  'cards',
  'transactions',
  'transactionsReview',
  'transactionsFlagged',
  'accounting',
  'billingPayments',
  'billingStatements',
  'members',
  'teams',
  'rewards',
  'merchants',
  'pliantEarth',
  'export',
  'exportQueue',
  'exported',
  'settings',
  'settingsIntegrations',
  'settingsFeatureModules',
  'settingsOrganization',
  'settingsAccounting',
  'partner',
];

const PortalOrgLayout = ({ children }: { children: React.ReactNode }) => {
  const { isOrgInOnboarding, isOrgInOnboardingNew } = useIsOrgInOnboarding();
  const {
    state: { organization, featureModules, partnerConfig },
  } = useGlobalState();

  const orgHasSource =
    !!organization && isPartnerBasedSource(organization.partnerId);
  const isEmbeddedPartnerPageUsed = !featureModules.INTEGRATIONS_PAGE;
  const showIntegrationsPage = orgHasSource && !isEmbeddedPartnerPageUsed;

  const onboardingPaths = useMemo(() => {
    if (!organization) return {};
    return {
      onboarding: generatePath(
        isOrgInOnboardingNew
          ? portalOrgPaths.orgOnboarding
          : portalOrgPaths.onboarding,
        {
          orgId: organization!.id,
        }
      ),
      members: generatePath(portalOrgPaths.members, {
        orgId: organization!.id,
      }),
      partner:
        orgHasSource && isEmbeddedPartnerPageUsed
          ? generatePath(portalOrgPaths.partner, {
              orgId: organization.id,
            })
          : undefined,
      settingsIntegrations: showIntegrationsPage
        ? generatePath(portalOrgPaths.settingsIntegrations, {
            orgId: organization.id,
          })
        : undefined,
      settings:
        showIntegrationsPage &&
        partnerConfig?.whitelabelLevel !==
          PartnerWhitelabelLevel.FULLY_WHITELABEL
          ? generatePath(portalOrgPaths.settings, {
              orgId: organization.id,
            })
          : undefined,
    };
  }, [orgHasSource, isEmbeddedPartnerPageUsed, partnerConfig?.whitelabelLevel]);

  const defaultPaths = useMemo(() => {
    if (organization) {
      const sidebarPaths: { [pathId: string]: string } = {};

      if (
        isOrgInOnboarding ||
        organization.status === OrganizationStatus.cancelled
      ) {
        sidebarPaths.onboarding = generatePath(
          isOrgInOnboardingNew
            ? portalOrgPaths.orgOnboarding
            : portalOrgPaths.onboarding,
          {
            orgId: organization.id,
          }
        );
      }
      SIDEBAR_TABS.forEach((key) => {
        sidebarPaths[key] = generatePath(portalOrgPaths[key], {
          orgId: organization.id,
        });
      });
      return sidebarPaths;
    } else {
      return {};
    }
  }, [organization, isOrgInOnboardingNew]);

  return (
    <Container style={{ flexDirection: 'column' }}>
      <MirrorViewAlert />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Sidebar
          paths={isOrgInOnboarding ? onboardingPaths : defaultPaths}
          showOnboarding={isOrgInOnboarding}
          PaperProps={{
            sx: {
              top: `${alertHeight}px`,
              height: `calc(100% - ${alertHeight}px)`,
            },
          }}
        />
        <ContainerInner>
          <PortalMainHeader />
          <MainContent>{children}</MainContent>
        </ContainerInner>
      </Box>
    </Container>
  );
};

export default withPartnerLogic(PortalOrgLayout);
