import {
  orgPath,
  sharedInternalOrgPaths,
  sharedInternalRootPaths,
} from 'components/App/InternalApp/paths';

export const portalRootPaths = {
  ...sharedInternalRootPaths,
  partnerMembers: '/partner-members',
  partnerMemberDetails: '/partner-members/:memberId',
  profile: '/profile',
};
export const portalOrgPaths = {
  ...sharedInternalOrgPaths,
  rewardsCardBenefits: `${orgPath}/rewards/card-benefits`,
};

export const portalPaths = { ...portalRootPaths, ...portalOrgPaths };

export const portalRootPathsArray = Object.values(portalRootPaths);
export const portalOrgPathsArray = Object.values(portalOrgPaths);
export const portalPathsArray = portalRootPathsArray.concat(
  portalOrgPathsArray
);

export type PortalOrgPathsKeys = keyof typeof portalOrgPaths;
export type PortalPathsKeys = keyof typeof portalRootPaths | PortalOrgPathsKeys;
