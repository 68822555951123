export interface Flags {
  accountEntriesOnExportPageEnabled: boolean;
  billPaymentReportDownloadEnabled: boolean;
  bookDemoSupportMenuItemEnabled: boolean;
  changePinEnabled: boolean;
  codatOverrideBookingDateEnabled: boolean;
  companyReimbursementEnabled: boolean;
  confirmationCheckboxOnPartnerAuthPageEnabled: boolean;
  creditApplicationNewOnboardingEnabled: boolean;
  datevBillPaymentsCsvOnStatementsEnabled: boolean;
  datevBillPaymentsXmlOnStatementsEnabled: boolean;
  datevExportEnabled: boolean;
  downloadAccConfirmationStatementEnabled: boolean;
  downloadBalanceStatementOnAccEntriesEnabled: boolean;
  downloadBillingStatementsOnAccEntriesEnabled: boolean;
  downloadCustomCsvWithoutTransactionsEnabled: boolean;
  emailNotificationSettingsEnabled: boolean;
  ereceiptUploadEnabled: boolean;
  merchantDisplayNameEnabled: boolean;
  monthlyCashbackThresholdProgressEnabled: boolean;
  multiCurrencyBillingEnabled: boolean;
  newOnboardingEnabled: boolean;
  oldBillingPageEnabled: boolean;
  onboardingPlatformTacsEnabled: boolean;
  organizationMerchantsPageEnabled: boolean;
  partnerDealsEnabled: boolean;
  privateExpense: boolean;
  receiptsZipOnBillingPageEnabled: boolean;
  reimbursementOnExportEnabled: boolean;
  // This flag is used to hardcode some functionalities for Telekom organization
  requestCardForAnotherMemberEnabled: boolean;
  scopevisioEnabled: boolean;
  securityKeyAuthenticationEnabled: boolean;
  smsDeliveryLogsEnabled: boolean;
  suppliersEnabled: boolean;
  transferMoneyBetweenAccountsEnabled: boolean;
  varengoldBankSubmissionDisabled: boolean;
  viaxoftEnabled: boolean;
  visaCommercialFileEnabled: boolean;
  vismaM2Enabled: boolean;
}

export const defaultFlags: Flags = {
  accountEntriesOnExportPageEnabled: false,
  billPaymentReportDownloadEnabled: false,
  bookDemoSupportMenuItemEnabled: false,
  changePinEnabled: false,
  codatOverrideBookingDateEnabled: false,
  companyReimbursementEnabled: false,
  confirmationCheckboxOnPartnerAuthPageEnabled: false,
  creditApplicationNewOnboardingEnabled: false,
  datevBillPaymentsCsvOnStatementsEnabled: false,
  datevBillPaymentsXmlOnStatementsEnabled: false,
  datevExportEnabled: false,
  downloadAccConfirmationStatementEnabled: false,
  downloadBalanceStatementOnAccEntriesEnabled: false,
  downloadBillingStatementsOnAccEntriesEnabled: false,
  downloadCustomCsvWithoutTransactionsEnabled: false,
  emailNotificationSettingsEnabled: false,
  ereceiptUploadEnabled: false,
  merchantDisplayNameEnabled: false,
  monthlyCashbackThresholdProgressEnabled: false,
  multiCurrencyBillingEnabled: false,
  newOnboardingEnabled: false,
  oldBillingPageEnabled: false,
  onboardingPlatformTacsEnabled: false,
  organizationMerchantsPageEnabled: false,
  partnerDealsEnabled: false,
  privateExpense: false,
  receiptsZipOnBillingPageEnabled: false,
  reimbursementOnExportEnabled: false,
  requestCardForAnotherMemberEnabled: false,
  scopevisioEnabled: false,
  securityKeyAuthenticationEnabled: false,
  smsDeliveryLogsEnabled: false,
  suppliersEnabled: false,
  transferMoneyBetweenAccountsEnabled: false,
  varengoldBankSubmissionDisabled: true,
  viaxoftEnabled: false,
  visaCommercialFileEnabled: false,
  vismaM2Enabled: false,
};
