import { useEffect, useState } from 'react';
import { generatePath, matchPath, useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import { cardholderPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import { LoaderWithOverlay } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import { FeatureModuleKey } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import TeamDetailsSubpage from './TeamDetailsSubpage';

const ManagerTeamsPage = () => {
  const history = useHistory();
  const showPageError = useShowPageError();
  const mounted = useMounted();
  const api = useImperativeApi();
  const canUser = useCanUser();
  const {
    dispatch,
    state: { member, organization },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchTeams = async () => {
    try {
      const { teams } = await api.getTeams({
        organizationId: organization!.id,
        page: 0,
        limit: 300,
        memberId: canUser('team:get-all') ? undefined : member.id,
      });
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { teams },
      });

      if (!mounted.current) return;

      const managedTeams = teams.filter((item) =>
        item.managers.some((manager) => manager.memberId === member.id)
      );

      if (!managedTeams.length) {
        showPageError();
        logError(new Error('No managed teams found for a team manager'));
        return;
      }

      const match = matchPath<{ teamId: string }>(history.location.pathname, {
        path: cardholderPaths.teamDetails,
      });
      const matchedTeam =
        match && managedTeams.find((item) => item.id === match.params.teamId!);
      if (!matchedTeam) {
        history.replace(
          generatePath(cardholderPaths.teamDetails, {
            teamId: managedTeams[0].id,
          })
        );
      }

      setIsLoading(false);
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  return (
    <>
      {!isLoading && (
        <Route
          path={cardholderPaths.teamDetails}
          component={TeamDetailsSubpage}
        />
      )}
      <LoaderWithOverlay loading={isLoading} />
    </>
  );
};

export default withPageConfig(ManagerTeamsPage, {
  permission: 'ch-manager-teams-page:visit',
  featureModule: FeatureModuleKey.teams,
});
