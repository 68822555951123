import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis } from 'recharts';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import { MerchantVolumeItem, Money } from 'services/constants';
import { formatMoney, getMoneyWithOppositeSign } from 'services/utils';

interface ItemValue {
  name: string;
  value: number;
  transactionsVolume: Money;
}

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

const abbreviateNumber = (number: number) => {
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier === 0) return number;
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  return scaled.toFixed(0) + suffix;
};

const xAxisTickFormatter = (date: string) => moment(date).format('MMM');

const yAxisTickFormatter = (value: number) =>
  abbreviateNumber(value).toString();

const BAR_CHART_MARGIN = {
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
};

const TOOLTIP_LABEL_FORMATTER = (label: string) => moment(label).format('MMMM');

const BAR_RADIUS: [number, number, number, number] = [2, 2, 0, 0];

interface Props {
  data: MerchantVolumeItem[];
}

const MerchantVolumeChart = ({ data: merchantVolumeItems }: Props) => {
  const { i18n } = useTranslation();
  const currencyDisplay = useCurrencyDisplayMode();
  const theme = useTheme();

  const data = useMemo<ItemValue[]>(() => {
    return merchantVolumeItems.map((item) => ({
      name: item.date,
      value: (item.transactionsVolume.value * -1) / 100,
      transactionsVolume: getMoneyWithOppositeSign(item.transactionsVolume),
    }));
  }, [merchantVolumeItems]);

  return (
    <BarChart width={552} height={156} data={data} margin={BAR_CHART_MARGIN}>
      <XAxis
        dataKey="name"
        axisLine={false}
        tickLine={false}
        tickFormatter={xAxisTickFormatter}
        style={theme.typography.caption}
        interval={0}
      />
      <YAxis
        dataKey="value"
        axisLine={false}
        tickLine={false}
        tickFormatter={yAxisTickFormatter}
        style={theme.typography.caption}
        interval="preserveStartEnd"
      />
      <Tooltip
        cursor={false}
        separator=""
        offset={12}
        contentStyle={{
          padding: theme.spacing(1),
          borderRadius: 6,
          borderColor: theme.palette.action.disabledBackground,
          background: theme.palette.common.white,
        }}
        labelStyle={theme.typography.caption}
        itemStyle={theme.typography.body2}
        labelFormatter={TOOLTIP_LABEL_FORMATTER}
        formatter={(value, name, { payload }) => [
          formatMoney(payload.transactionsVolume, i18n.language, {
            currencyDisplay,
          }),
          '',
        ]}
      />
      <Bar
        dataKey="value"
        isAnimationActive={false}
        minPointSize={2}
        radius={BAR_RADIUS}
        barSize={20}
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              entry.value
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            }
          />
        ))}
      </Bar>
    </BarChart>
  );
};

export default MerchantVolumeChart;
