import { useGlobalState } from 'context/GlobalState';
import { isCustomLocoiaAccountingSystem } from 'domains/settings/utils';
import {
  AccountingSystem,
  ExportFormat,
  InternalExportFormat,
  internalExportFormats,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { isAccSystemDownloadVisible } from 'services/utils';
import CommonExportFormats from './CommonExportFormats';
import CustomCSVExportFormats from './CustomCSVExportFormats';
import DatevExportFormats from './DatevExportFormats';
import IncludeReceiptsToggle from './IncludeReceiptsToggle';
import LexwareOfficeExportFormats from './LexwareOfficeExportFormats';
import NetvisorExportFormats from './NetvisorExportFormats';
import ProcounterExportFormats from './ProcounterExportFormats';

interface Props {
  isExported: boolean;
  isLoading: boolean;
  isIncludeReceiptsSelected: boolean;
  format: ExportFormat | InternalExportFormat;
  onIncludeReceiptsChange: (checked: boolean) => void;
  onSave: (format: ExportFormat) => void;
}

const ExportFormats = ({
  isExported,
  isLoading,
  isIncludeReceiptsSelected,
  format,
  onIncludeReceiptsChange,
  onSave,
}: Props) => {
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const { datevExportEnabled } = useFlags();

  /**
   * Extra check and a garantee we use correct export formats
   * in child components.
   */
  const isInternalExportFormat = internalExportFormats.includes(
    format as InternalExportFormat
  );

  const isDatevAccSystem =
    datevExportEnabled &&
    accountingSettings!.accountingSystem === AccountingSystem.datev;
  const isDatevExportEnabled =
    isDatevAccSystem && accountingSettings!.useAccountingExport;

  const isLexwareOfficeEnabled = isAccSystemDownloadVisible(
    accountingSettings,
    AccountingSystem.lexOffice
  );

  const isProcountorEnabled = isAccSystemDownloadVisible(
    accountingSettings,
    AccountingSystem.procountor
  );
  const isNetvisorEnabled = isAccSystemDownloadVisible(
    accountingSettings,
    AccountingSystem.netvisor
  );

  if (isDatevExportEnabled && !isInternalExportFormat)
    return (
      <DatevExportFormats
        isExported={isExported}
        format={format as ExportFormat}
        onSave={onSave}
      />
    );

  if (isLexwareOfficeEnabled && !isInternalExportFormat)
    return (
      <LexwareOfficeExportFormats
        isExported={isExported}
        format={format as ExportFormat}
        onSave={onSave}
      />
    );

  if (isProcountorEnabled && !isInternalExportFormat)
    return (
      <ProcounterExportFormats
        format={format as ExportFormat}
        onSave={onSave}
      />
    );

  if (isNetvisorEnabled && !isInternalExportFormat)
    return (
      <NetvisorExportFormats format={format as ExportFormat} onSave={onSave} />
    );

  if (isCustomLocoiaAccountingSystem(accountingSettings))
    return (
      <>
        <CustomCSVExportFormats format={format} onSave={onSave} />
        <IncludeReceiptsToggle
          isLoading={isLoading}
          isSelected={isIncludeReceiptsSelected}
          onChange={onIncludeReceiptsChange}
        />
      </>
    );

  return (
    <CommonExportFormats
      ReceiptsToggle={
        <IncludeReceiptsToggle
          isLoading={isLoading}
          isSelected={isIncludeReceiptsSelected}
          onChange={onIncludeReceiptsChange}
        />
      }
    />
  );
};

export default ExportFormats;
