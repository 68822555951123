import { ChangeEvent, useEffect, useState } from 'react';
import { Box, ListItemText, MenuItem } from '@material-ui/core';
import range from 'lodash/range';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/Form';
import { LoaderWithOverlay } from 'components/Loader';
import { useGlobalState } from 'context/GlobalState';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OrganizationEmission } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import BarChart from './BarChart';
import PieChart from './PieChart';
import SummaryStats from './SummaryStats';
import { Header, StyledInputBase, Wrapper } from './style';

interface State {
  orgEmission: OrganizationEmission | null;
  availableYears: number[];
  emissions: any[];
  isLoading: boolean;
  selectedYear: number;
}

const Dashboard = () => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    orgEmission: null,
    availableYears: [new Date().getFullYear()],
    emissions: [],
    isLoading: true,
    selectedYear: new Date().getFullYear(),
  });

  const onChange = (e: ChangeEvent<{ value: unknown }>) => {
    const year = e.target.value as number;
    setState((prevState) => ({ ...prevState, selectedYear: year }));
  };

  useEffect(() => {
    (async () => {
      try {
        const [orgEmission, orgMonthlyEmission] = await Promise.all([
          api.getTotalOrgEmissionsByYear(organization!.id),
          api.getMonthlyOrgEmissionsByYear(organization!.id, {
            year: state.selectedYear,
          }),
        ]);

        if (!mounted.current) return;
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          orgEmission,
          availableYears: orgMonthlyEmission.availableYears.length
            ? range(
                Math.min(...orgMonthlyEmission.availableYears),
                new Date().getFullYear() + 1
              )
            : prevState.availableYears,
          emissions: orgMonthlyEmission.emissions,
        }));
      } catch (error) {
        if (!mounted.current) return;
        setState((prevState) => ({ ...prevState, isLoading: false }));
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    })();
  }, [state.selectedYear]);

  return (
    <Wrapper>
      <Box display="flex">
        <Box display="flex" width="519px">
          <Header>{t('pliantEarthPage.dashboard.title')}</Header>

          <Select
            disabled={state.availableYears.length <= 1}
            fullWidth={false}
            onChange={onChange}
            value={state.selectedYear}
            input={<StyledInputBase />}
          >
            {state.availableYears.map((year) => (
              <MenuItem key={year} value={year}>
                <ListItemText primary={year} />
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box flexGrow="1" />

        <Box width="380px" paddingLeft="48px">
          <Header>{t('pliantEarthPage.dashboard.co2Balance')}</Header>
        </Box>
      </Box>

      {state.isLoading ? (
        <LoaderWithOverlay size={32} thickness={3} />
      ) : (
        <Box display="flex">
          <Box display="flex" mt="28px" flexGrow="1">
            <BarChart data={state.emissions} year={state.selectedYear} />
          </Box>

          <Box display="flex">
            <SummaryStats orgEmission={state.orgEmission} />
            <PieChart orgEmission={state.orgEmission} />
          </Box>
        </Box>
      )}
    </Wrapper>
  );
};

export default Dashboard;
