import { useState } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { isPartnerBasedSource } from 'domains/partner/utils';
import { Divider, LoaderWithOverlay, Typography } from 'elements';
import withPageErrorWrapper from 'hoc/withPageErrorWrapper';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { PageContent, PageHeader, PageTitle } from 'layout';
import {
  IntegrationNameType,
  PartnerAuthStatus,
  PartnerIdType,
  PartnerOrgAuthDetails,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';
import IntegrationDialog from './IntegrationDialog';
import IntegrationsSection from './IntegrationsSection';
import { useIntegrationsList } from './useIntegrationsList';

const sortIntegrations = (
  items: IntegrationItem[],
  status: PartnerAuthStatus
) => {
  return sortBy(
    items.filter((item) => item.visible && item.status === status),
    (v) => v.name.toLowerCase()
  );
};

const getSortedIntegrations = (items: IntegrationItem[]) => {
  return [
    ...sortIntegrations(items, PartnerAuthStatus.active),
    ...sortIntegrations(items, PartnerAuthStatus.pending),
    ...sortIntegrations(items, PartnerAuthStatus.inactive),
  ];
};

export interface IntegrationItem {
  name: IntegrationNameType;
  partnerId?: PartnerIdType;
  title: string;
  subtitle: string | JSX.Element;
  description: (string | JSX.Element)[];
  subDescription?: JSX.Element;
  type: string[];
  logo: JSX.Element;
  avatar: string;
  status: PartnerAuthStatus;
  visible: boolean;
  partnerDetails: PartnerOrgAuthDetails | null;
  requiresUpgrade: boolean;
  isPremium?: boolean;
}

const IntegrationsPageInner = () => {
  const { t } = useTranslation();
  const { integrations, isLoading, refetchPartner } = useIntegrationsList();
  const [
    selectedIntegration,
    setSelectedIntegration,
  ] = useState<IntegrationItem | null>(null);

  const sortedActiveIntegrations = getSortedIntegrations(integrations);

  return (
    <>
      <PageHeader>
        <PageTitle
          title={t('integrationsPage.title')}
          suptitle={t('navigation.settings.title')}
        />

        <Typography mt={1} variant="body2" color="textSecondary">
          {t('integrationsPage.description')}
        </Typography>
      </PageHeader>

      <Divider sx={{ mx: 5 }} />

      <PageContent pb={5}>
        {!isLoading && !!integrations.length && (
          <IntegrationsSection
            integrations={sortedActiveIntegrations}
            onIntegrationClick={(item) => setSelectedIntegration(item)}
          />
        )}
        <LoaderWithOverlay loading={isLoading} />
      </PageContent>

      <IntegrationDialog
        open={!!selectedIntegration}
        integration={selectedIntegration}
        integrations={integrations}
        onClose={() => setSelectedIntegration(null)}
        onPartnerUnauthorized={(partnerId) => {
          setSelectedIntegration(null);
          refetchPartner(partnerId);
        }}
      />
    </>
  );
};

const IntegrationsPage = () => {
  const canUser = useCanUser();
  const {
    state: { organization, featureModules },
  } = useGlobalState();
  const { isOrgInOnboarding, isOrgInOnboardingNew } = useIsOrgInOnboarding();

  const isEmbeddedPartnerPageUsed = !featureModules.INTEGRATIONS_PAGE;

  const orgHasSource = isPartnerBasedSource(organization!.partnerId);

  const integrationsPageHidden =
    canUser('partners:view') && orgHasSource && isEmbeddedPartnerPageUsed;

  // for pliant orgs -> don't show the page
  if (isOrgInOnboarding && !orgHasSource)
    return (
      <Redirect
        to={generatePath(
          getPath(isOrgInOnboardingNew ? 'orgOnboarding' : 'onboarding'),
          {
            orgId: organization!.id,
          }
        )}
      />
    );

  return integrationsPageHidden ? (
    <Redirect
      to={generatePath(getPath('partner'), {
        orgId: organization!.id,
      })}
    />
  ) : (
    <IntegrationsPageInner />
  );
};

export default withPageErrorWrapper(IntegrationsPage);
