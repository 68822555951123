import ReactDOM from 'react-dom';
import EmailNotVerifiedError from './EmailNotVerifiedError';
import PageNotFoundError from './PageNotFoundError';
import UnauthorizedError from './UnauthorizedError';
import UnexpectedError from './UnexpectedError';

const overlayRoot = document.getElementById('overlay-root');

type ErrorCode = 'UNAUTHORIZED' | 'PAGE_NOT_FOUND' | 'EMAIL_NOT_VERIFIED';

const getComponentByErrorCode = (errorCode?: ErrorCode) => {
  if (errorCode === 'UNAUTHORIZED') return UnauthorizedError;
  if (errorCode === 'PAGE_NOT_FOUND') return PageNotFoundError;
  if (errorCode === 'EMAIL_NOT_VERIFIED') return EmailNotVerifiedError;
  return UnexpectedError;
};

interface Props {
  errorCode?: ErrorCode;
}

const AppErrorOverlay = ({ errorCode }: Props) => {
  const Component = getComponentByErrorCode(errorCode);

  return ReactDOM.createPortal(<Component />, overlayRoot!);
};

export default AppErrorOverlay;
