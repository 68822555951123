import moment from 'moment';
import { useGlobalState } from 'context/GlobalState';
import useCurrentApp from 'hooks/useCurrentApp';
import {
  LegalRepresentative,
  LegalRepresentativeType,
  Member,
  MemberDetails,
  PaymentMethod,
  StripeCardStatus,
} from 'services/constants';
import { Role, useCanUser } from 'services/rbac';

interface Args {
  members?: Member[] | null;
  isInvitingMember?: boolean;
  // isPartnerMember is used for Portal app for partner member invite
  isPartnerMember?: boolean;
}

export const useAvailableRoles = ({
  members,
  isInvitingMember,
  isPartnerMember,
}: Args = {}) => {
  const { isExternalApp } = useCurrentApp();
  const {
    state: { featureModules },
  } = useGlobalState();
  const canUser = useCanUser();

  const defaultRoles = [
    // remove cardholder as pre-selected for some partners
    {
      role: Role.cardholder,
      isAllowed:
        featureModules.CARDHOLDER_MANAGEMENT &&
        canUser('member-cardholder:create'),
    },
    // hide accountant from some partners
    ...(featureModules.ACCOUNTING_FEATURES
      ? [
          {
            role: Role.accountant,
            isAllowed: canUser('member-accountant:create'),
          },
        ]
      : []),
    {
      role: Role.orgAdmin,
      isAllowed: canUser('member-org-admin:create', members),
    },
    {
      role: Role.accountOwner,
      isAllowed: canUser('member-account-owner:create'),
    },
    {
      role: Role.auditor,
      isAllowed: !!isInvitingMember && canUser('member-auditor:create'),
    },
  ];

  const partnerPortalRoles = [
    {
      role: Role.partnerAdmin,
      isAllowed: canUser('partner-member:create'),
    },
    {
      role: Role.partnerOps,
      isAllowed: canUser('partner-member:create'),
    },
  ];

  const availableRoles = isPartnerMember ? partnerPortalRoles : defaultRoles;

  const allowedRoles = availableRoles.filter((role) => role.isAllowed);
  // IN-12078 Forbid external users from inviting members if MEMBER_MANAGEMENT feature is disabled
  const hideInviteButton = isExternalApp && !featureModules.MEMBER_MANAGEMENT;

  return {
    availableRoles,
    allowedRoles,
    isInviteMemberAllowed: !!allowedRoles.length && !hideInviteButton,
  };
};

export const hasEnoughLegalReps = (
  legalReps: LegalRepresentative[],
  member: MemberDetails
) => {
  if (!member.roles.includes(Role.legalRep)) return true;

  const otherLegalReps = legalReps.filter((rep) => rep.memberId !== member.id);

  const soleLegalRepsCount = otherLegalReps.reduce(
    (acc, curr) => (curr.type === LegalRepresentativeType.sole ? ++acc : acc),
    0
  );

  const jointLegalRepsCount = otherLegalReps.reduce(
    (acc, curr) => (curr.type === LegalRepresentativeType.joint ? ++acc : acc),
    0
  );

  return soleLegalRepsCount >= 1 || jointLegalRepsCount >= 2;
};

export const getStripeCardStatus = (paymentMethod: PaymentMethod | null) => {
  if (!paymentMethod) {
    return undefined;
  }

  if (moment(paymentMethod.expiryDate).isBefore(moment())) {
    return StripeCardStatus.expired;
  }

  if (paymentMethod.failedPaymentAttemptsAmount >= 1) {
    return StripeCardStatus.declined;
  }

  return undefined;
};
