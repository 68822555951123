import { useTranslation } from 'react-i18next';
import {
  MemberRoleOverline,
  MemberStatusBadge,
  MemberTypeOverline,
} from 'domains/member/components';
import { Box, StatusBadge, Typography } from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import {
  LegalRepresentativeType,
  MemberDetails,
  PartnerMember,
} from 'services/constants';
import { Role } from 'services/rbac';

interface Props {
  member: MemberDetails | PartnerMember;
}

const MemberDetailsHeader = ({ member }: Props) => {
  const { t } = useTranslation();
  const { isInternalApp } = useCurrentApp();

  const invalidLegalRep =
    'legalRepresentative' in member &&
    ((member.legalRepresentative === null &&
      member.roles.includes(Role.legalRep)) ||
      ((member.legalRepresentative === LegalRepresentativeType.joint ||
        member.legalRepresentative === LegalRepresentativeType.sole) &&
        !member.roles.includes(Role.legalRep)));

  return (
    <>
      <Box>
        <Typography
          variant="h5"
          component="span"
        >{`${member.firstName} ${member.lastName}`}</Typography>

        <MemberRoleOverline
          roles={member.roles}
          ml={1}
          color="textSecondary"
          sx={{
            verticalAlign: 'top',
          }}
        />
        {'embeddedPartnerMembership' in member &&
          member.embeddedPartnerMembership && (
            <MemberTypeOverline
              isNewDesign
              roles={member.roles}
              color="textSecondary"
              sx={{
                verticalAlign: 'top',
              }}
            />
          )}
      </Box>

      <Box mt={1}>
        <MemberStatusBadge status={member.status} />

        {'legalRepresentative' in member &&
          member.legalRepresentative &&
          member.legalRepresentative !== LegalRepresentativeType.none && (
            <StatusBadge
              sx={{ marginLeft: 1 }}
              label={t('memberDetailsHeader.legalRep', {
                type: member.legalRepresentative,
              })}
            />
          )}

        {isInternalApp && invalidLegalRep && (
          <StatusBadge
            sx={{ marginLeft: 1 }}
            label="INVALID LEGAL REP CONFIG"
            color="error"
          />
        )}
      </Box>
    </>
  );
};

export default MemberDetailsHeader;
