import ComplianceInformationRequestSection from './ComplianceInformationRequestSection';
import CustomerDeclarationsSection from './CustomerDeclarationsSection';
import PreCheckSection from './PreCheckSection';

const ComplianceQaSubPage = () => {
  return (
    <>
      <CustomerDeclarationsSection />
      <ComplianceInformationRequestSection />
      <PreCheckSection />
    </>
  );
};

export default ComplianceQaSubPage;
