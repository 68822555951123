import { ComponentType, FC, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import {
  createPartnerTheme,
  whitelabeledPartnerScopes,
} from 'components/App/style/themeMui5';
import HelmetTitleAndFavicons from 'components/HelmetTitleAndFavicons';
import { useGlobalState } from 'context/GlobalState';
import useIdTokenCustomData from 'hooks/useIdTokenCustomData';
import usePartnerName from 'hooks/usePartnerName';
import { PartnerIds, PartnerWhitelabelLevel } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

/**
 * This HOC sets partner information
 * and white label flags in the global state.
 */
const withPartnerLogic = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element | null => {
  const api = useImperativeApi();
  const canUser = useCanUser();
  const partnerName = usePartnerName();
  const {
    dispatch,
    state: { organization, themeId },
  } = useGlobalState();
  const { organizationId } = useIdTokenCustomData();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useMemo(() => createPartnerTheme(themeId), [themeId]);

  useEffect(() => {
    (async () => {
      try {
        const [
          { partners },
          partnerOrgAuthDetails,
          partnerConfig,
        ] = await Promise.all([
          canUser('partners:view')
            ? api.getPartners().catch((error) => {
                logError(error);
                return { partners: null };
              })
            : { partners: null },
          canUser('partners:view')
            ? api
                .getPartnerOrgAuthDetails(
                  organization!.partnerId,
                  organizationId
                )
                .catch((error) => {
                  logError(error);
                  return undefined;
                })
            : null,
          canUser('partner-config:view')
            ? api.getPartnerConfig(organization!.partnerId)
            : undefined,
        ]);

        dispatch({
          type: 'SET_THEME_ID',
          payload:
            partnerConfig &&
            whitelabeledPartnerScopes.includes(partnerConfig.whitelabelLevel)
              ? (organization!.partnerId as string)
              : PartnerIds.pliant,
        });

        dispatch({
          type: 'SET_APP_TYPE',
          payload: {
            isComplianceRiskWhiteLabelApp: Boolean(
              partnerConfig?.whitelabelLevel ===
                PartnerWhitelabelLevel.COMPLIANCE_RISK_WHITELABEL
            ),
          },
        });

        dispatch({
          type: 'SET_PARTNER_DATA',
          payload: {
            partners,
            partnerOrgAuthDetails,
            partnerConfig,
          },
        });

        setIsLoading(false);
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: error });
        logError(error);
      }
    })();
  }, []);

  return isLoading ? null : (
    <ThemeProvider theme={theme}>
      <HelmetTitleAndFavicons title={partnerName} partnerId={themeId} />
      <Component {...props} />
    </ThemeProvider>
  );
};

export default withPartnerLogic;
