import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { DialogProps, withDialogWrapper } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MemberDetails } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  member: MemberDetails;
  onClose: () => void;
  onSuccess: () => void;
}

const ReactivateMemberDialog = ({ member, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const [isLoading, setIsLoading] = useState(false);

  const reactivateMember = async () => {
    try {
      setIsLoading(true);
      await api.reactivateMember(member.organizationId, member.id);
      if (!mounted.current) return;
      setIsLoading(false);
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <ConfirmDialog
      {...props}
      loading={isLoading}
      onSuccess={reactivateMember}
      title={t('reactivateMemberDialog.title')}
      description={t('reactivateMemberDialog.description')}
    />
  );
};

export default withDialogWrapper<Props>(ReactivateMemberDialog);
