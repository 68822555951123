import { FormEvent } from 'react';
import { convert, greaterThan, haveSameCurrency } from 'dinero.js';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { CardAccountSelect, DialogStepper } from 'domains/card/components';
import { MAX_TELEKOM_SINGLE_USE_CARD_LIMIT } from 'domains/card/constants';
import { FormValues, Step } from 'domains/card/dialogs/CardRequestDialog';
import useStepManager from 'domains/card/dialogs/CardRequestDialog/useStepManager';
import { useCardAccountCurrency } from 'domains/card/hooks';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  InputLabelTooltipIcon,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  Select,
  TextField,
} from 'elements';
import useMounted from 'hooks/useMounted';
import {
  CardConfigGroup,
  CardFundingType,
  cardLimitRenewFrequencies,
  CardLimitRenewFrequency,
  cardLoadFrequencies,
  CardLoadFrequency,
  CardNewType,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getDineroScaledAmount,
} from 'services/utils';

export interface Props {
  onClose: () => void;
}

const LimitsAndValidityStep = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { requestCardForAnotherMemberEnabled } = useFlags();
  const stepManager = useStepManager();
  const formik = useFormikContext<FormValues>();
  const currency = useCardAccountCurrency(formik.values.cardAccountId);
  const cardConfigSetting = formik.values.cardConfigSetting!;
  const { expiryPeriodsInMonths: expiryPeriods } = cardConfigSetting;

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (cardConfigSetting.validityPeriodEnabled) {
      const minDate = moment();
      const maxDate = moment().add(
        expiryPeriods[expiryPeriods.length - 1],
        'months'
      );
      const validFrom = moment(formik.values.validFrom, 'YYYY-MM-DD', true);
      const validTo = moment(formik.values.validTo, 'YYYY-MM-DD', true);

      if (
        !validFrom.isValid() ||
        !validTo.isValid() ||
        !validFrom.isBetween(minDate, maxDate, 'day', '[]') ||
        !validTo.isBetween(minDate, maxDate, 'day', '[]') ||
        validFrom.isAfter(validTo)
      ) {
        return formik.setErrors({
          validFrom: t('errors.cardValidityPeriod', {
            minDate: minDate.format('D MMM YYYY'),
            maxDate: maxDate.format('D MMM YYYY'),
          }),
        });
      }

      if (
        typeof cardConfigSetting.validityPeriodMaxInDays === 'number' &&
        validTo.diff(validFrom, 'days') >
          cardConfigSetting.validityPeriodMaxInDays
      ) {
        return formik.setErrors({
          validFrom: t('errors.cardValidityPeriodMax', {
            count: cardConfigSetting.validityPeriodMaxInDays,
          }),
        });
      }
    }

    // Skip the limits validation for load-based cards
    if (cardConfigSetting.fundingType === CardFundingType.loadBasedAccruing) {
      formik.setErrors({});
      stepManager.goNext();
      return;
    }

    const dLimit = dineroFromFloat(formik.values.limit, currency);
    const dTransactionLimit = dineroFromFloat(
      formik.values.transactionLimit,
      currency
    );

    if (greaterThan(dTransactionLimit, dLimit))
      return formik.setErrors({
        transactionLimit: t('errors.cardTransactionLimitHigherThanLimit'),
      });

    formik.setSubmitting(true);

    if (
      requestCardForAnotherMemberEnabled &&
      cardConfigSetting.maxUsage === 1
    ) {
      let dMaxCardLimit = dineroFromMoney(MAX_TELEKOM_SINGLE_USE_CARD_LIMIT);

      if (!haveSameCurrency([dLimit, dMaxCardLimit])) {
        try {
          const { fxRates } = await api.getFxRates({
            source: MAX_TELEKOM_SINGLE_USE_CARD_LIMIT.currency,
            target: currency.code,
          });
          dMaxCardLimit = convert(dMaxCardLimit, currency, {
            [currency.code]: getDineroScaledAmount(fxRates[0].rate),
          });
        } catch (error) {
          logError(error);
        }
      }

      if (
        haveSameCurrency([dLimit, dMaxCardLimit]) &&
        greaterThan(dLimit, dMaxCardLimit)
      ) {
        if (!mounted.current) return;
        formik.setSubmitting(false);
        return formik.setErrors({
          [cardConfigSetting.maxUsage === 1 ? 'transactionLimit' : 'limit']: (
            <Trans
              i18nKey="errors.cardMaxLimitExceeded"
              components={{
                limit: (
                  <FormatMoney
                    value={convertDineroToMoney(dMaxCardLimit)}
                    fractionalPart
                  />
                ),
              }}
            />
          ),
        });
      }
    }

    if (!mounted.current) return;
    formik.setSubmitting(false);
    formik.setErrors({});
    stepManager.goNext();
  };

  const renderValidityPeriodControls = () => {
    if (cardConfigSetting.validityPeriodEnabled)
      return (
        <>
          <Grid item xs={6}>
            <TextField
              type="date"
              label={t('cardRequestDialog_v2.limitsAndValidityStep.validFrom')}
              error={!!formik.errors.validFrom}
              disabled={formik.isSubmitting}
              {...formik.getFieldProps('validFrom')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="date"
              label={t('cardRequestDialog_v2.limitsAndValidityStep.validTo')}
              error={!!formik.errors.validFrom}
              disabled={formik.isSubmitting}
              {...formik.getFieldProps('validTo')}
            />
          </Grid>
          {formik.errors.validFrom && (
            <Grid item xs={12}>
              <FormHelperText error sx={{ mt: -1.5 }}>
                {formik.errors.validFrom}
              </FormHelperText>
            </Grid>
          )}
        </>
      );

    if (cardConfigSetting.cardType === CardNewType.virtual)
      return (
        <Grid item xs={12}>
          <FormControl
            fullWidth
            disabled={expiryPeriods.length === 1 || formik.isSubmitting}
          >
            <InputLabel id="expiry-period-months-select-label">
              {t('cardRequestDialog_v2.limitsAndValidityStep.validityPeriod')}
              {cardConfigSetting.maxUsage === 1 && (
                <InputLabelTooltipIcon
                  title={t(
                    'cardRequestDialog_v2.limitsAndValidityStep.validityPeriodSingleUseCardTooltip',
                    { count: expiryPeriods[0] }
                  )}
                />
              )}
            </InputLabel>
            <Select<number>
              {...formik.getFieldProps('expiryPeriodMonths')}
              renderValue={(selected) =>
                `${selected} ${t(
                  'cardRequestDialog_v2.limitsAndValidityStep.months'
                )}`
              }
              labelId="expiry-period-months-select-label"
            >
              {expiryPeriods.map((period) => (
                <MenuItem key={period} value={period}>
                  {`${period} ${t(
                    'cardRequestDialog_v2.limitsAndValidityStep.months'
                  )}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );

    return null;
  };

  const renderFundingTypeDependentControls = () => {
    if (cardConfigSetting.fundingType === CardFundingType.loadBasedAccruing) {
      return (
        <>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              disabled={
                !!cardConfigSetting.loadBasedSettings?.fixedFrequency ||
                formik.isSubmitting
              }
            >
              <InputLabel id="load-frequency-select-label">
                {t('cardRequestDialog_v2.limitsAndValidityStep.loadFrequency')}
              </InputLabel>
              <Select<CardLoadFrequency>
                {...formik.getFieldProps('loadFrequency')}
                renderValue={(selected) =>
                  t(`cardLoadFrequency.frequencies.${selected}`)
                }
                labelId="load-frequency-select-label"
              >
                {cardLoadFrequencies.map((frequency) => (
                  <MenuItem key={frequency} value={frequency}>
                    {t(`cardLoadFrequency.frequencies.${frequency}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <MoneyField
              name="loadAmount"
              value={formik.values.loadAmount}
              onValueChange={({ value }) =>
                formik.setFieldValue('loadAmount', value)
              }
              isNumericString
              currency={currency.code}
              decimalScale={currency.exponent}
              label={t('cardRequestDialog_v2.limitsAndValidityStep.amount')}
              error={!!formik.errors.loadAmount}
              helperText={formik.errors.loadAmount}
              disabled={
                !!cardConfigSetting.loadBasedSettings?.fixedAmount ||
                formik.isSubmitting
              }
            />
          </Grid>
        </>
      );
    }

    return (
      <>
        {cardConfigSetting.maxUsage !== 1 && (
          <>
            <Grid item xs={6}>
              <FormControl fullWidth disabled={formik.isSubmitting}>
                <InputLabel id="limit-frequency-select-label">
                  {t(
                    'cardRequestDialog_v2.limitsAndValidityStep.limitFrequency'
                  )}
                  <InputLabelTooltipIcon
                    title={t(
                      `cardLimitFrequency.hints.${formik.values.limitRenewFrequency}`
                    )}
                  />
                </InputLabel>
                <Select<CardLimitRenewFrequency>
                  {...formik.getFieldProps('limitRenewFrequency')}
                  renderValue={(selected) =>
                    t(`cardLimitFrequency.frequencies.${selected}`)
                  }
                  labelId="limit-frequency-select-label"
                >
                  {cardLimitRenewFrequencies.map((frequency) => (
                    <MenuItem key={frequency} value={frequency}>
                      {t(`cardLimitFrequency.frequencies.${frequency}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <MoneyField
                name="limit"
                value={formik.values.limit}
                onValueChange={({ value }) => {
                  formik.setFieldValue('limit', value);
                  if (
                    cardConfigSetting?.cardConfigGroup ===
                    CardConfigGroup.pliantVirtualTravel
                  ) {
                    formik.setFieldValue('transactionLimit', value);
                  }
                }}
                isNumericString
                currency={currency.code}
                decimalScale={currency.exponent}
                label={t('cardRequestDialog_v2.limitsAndValidityStep.limit')}
                error={!!formik.errors.limit}
                helperText={formik.errors.limit}
                disabled={formik.isSubmitting}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <MoneyField
            name="transactionLimit"
            value={formik.values.transactionLimit}
            onValueChange={({ value }) => {
              formik.setFieldValue('transactionLimit', value);
              if (cardConfigSetting.maxUsage === 1)
                formik.setFieldValue('limit', value);
            }}
            isNumericString
            currency={currency.code}
            decimalScale={currency.exponent}
            label={
              <>
                {t(
                  'cardRequestDialog_v2.limitsAndValidityStep.transactionLimit'
                )}
                <InputLabelTooltipIcon title={t('tooltips.transactionLimit')} />
              </>
            }
            error={!!formik.errors.transactionLimit}
            helperText={formik.errors.transactionLimit}
            disabled={formik.isSubmitting}
          />
        </Grid>
      </>
    );
  };

  const isSubmitDisabled =
    (cardConfigSetting.fundingType === CardFundingType.loadBasedAccruing
      ? !formik.values.loadAmount
      : !formik.values.limit || !formik.values.transactionLimit) ||
    formik.isSubmitting;

  return (
    <>
      <DialogStepper<Step>
        currentStep={stepManager.currentStep}
        steps={stepManager.steps}
        goTo={stepManager.goTo}
        getStepLabel={stepManager.getStepLabel}
      />
      <DialogTitle>{stepManager.getStepLabel('limitsAndValidity')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={onSubmit}
          noValidate
          id="request-card-limits-and-validity-form"
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            {renderValidityPeriodControls()}

            {cardConfigSetting.cardAccounts.length > 1 && (
              <Grid item xs={12}>
                <CardAccountSelect
                  label={t(
                    'cardRequestDialog_v2.limitsAndValidityStep.cardAccount'
                  )}
                  cardAccountIds={cardConfigSetting.cardAccounts}
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('cardAccountId')}
                />
              </Grid>
            )}

            {renderFundingTypeDependentControls()}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Box flexGrow="1">
          <Button variant="text" onClick={stepManager.goPrev}>
            {t('common.button.back')}
          </Button>
        </Box>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="request-card-limits-and-validity-form"
          type="submit"
        >
          {t('common.button.continue')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default LimitsAndValidityStep;
