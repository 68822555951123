import { useEffect, useState } from 'react';
import { QueryParams } from 'domains/organization/pages/OrganizationsPage/OrganizationsAndNcoSubPage';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Partner, PartnerConfig } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { visiblePartnerScopes } from './index';

export interface PartnerData {
  value: Partner | PartnerConfig | null;
  isLoading: boolean;
  isError: boolean;
}

const useFilters = (params: Pick<QueryParams, 'partnerConfigId'>) => {
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const [partnerData, setPartnerData] = useState<PartnerData>({
    value: null,
    isLoading: false,
    isError: false,
  });

  const getPartnerConfig = async () => {
    setPartnerData((prevState) => ({ ...prevState, isLoading: true }));
    try {
      if (!mounted.current) return;
      const partnerConfig = await api.getPartnerConfig(params.partnerConfigId);
      const isPartnerConfigDataVisible = visiblePartnerScopes.includes(
        partnerConfig.partnerScope
      );
      setPartnerData((prevState) => ({
        ...prevState,
        value: isPartnerConfigDataVisible ? partnerConfig : null,
        error: !isPartnerConfigDataVisible,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setPartnerData((prevState) => ({
        ...prevState,
        isLoading: false,
        isError: true,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), {
        variant: 'error',
      });
      logError(error);
    }
  };

  useEffect(() => {
    // automatically reset state when no param
    if (!params.partnerConfigId) {
      setPartnerData((prevState) => ({
        ...prevState,
        value: null,
        isError: false,
      }));
      return;
    }
    // if values mismatched -> fetch data
    if (
      !partnerData.value ||
      params.partnerConfigId !== partnerData.value.partnerId
    )
      getPartnerConfig();
  }, [params.partnerConfigId]);

  // extend when we have other dynamic filters
  const setDataFilters = ({
    partner,
  }: {
    partner: Pick<PartnerData, 'value'>;
  }) => {
    setPartnerData((prevState) => ({
      ...prevState,
      ...partner,
      isError: false,
    }));
  };

  return {
    partnerData,
    areFiltersLoading: partnerData.isLoading,
    setDataFilters,
  };
};

export default useFilters;
