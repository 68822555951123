import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { organizationOnboardingStatuses } from 'services/constants';

const useIsOrgInOnboarding = () => {
  const {
    state: { organization },
  } = useGlobalState();

  return useMemo(
    () => ({
      isOrgInOnboarding:
        !!organization &&
        organizationOnboardingStatuses.includes(organization.status),
      isOrgInOnboardingNew:
        !!organization &&
        organizationOnboardingStatuses.includes(organization.status) &&
        organization.newOnboardingFlow,
      isOrgCreatedWithNewOnboardingFlow:
        !!organization && organization.newOnboardingFlow,
    }),
    [organization?.status]
  );
};

export default useIsOrgInOnboarding;
