import { useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import {
  DialogStepper,
  IssueRequestCardSummaryContent,
} from 'domains/card/components';
import { FormValues, Step } from 'domains/card/dialogs/IssueCardDialog';
import useStepManager from 'domains/card/dialogs/IssueCardDialog/useStepManager';
import { getCardName } from 'domains/card/utils';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoaderWithOverlay,
} from 'elements';

interface Props {
  onClose: () => void;
}

const SummaryStep = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const stepManager = useStepManager();
  const formik = useFormikContext<FormValues>();

  return (
    <>
      <DialogStepper<Step>
        currentStep={stepManager.currentStep}
        steps={stepManager.steps}
        goTo={stepManager.goTo}
        getStepLabel={stepManager.getStepLabel}
      />
      <DialogTitle>
        <Trans
          i18nKey="issueCardDialog_v2.summaryStep.title"
          values={{
            cardName: getCardName(
              formik.values.cardConfigSetting!.cardConfig,
              true
            ),
          }}
        />
      </DialogTitle>
      <DialogContent>
        <IssueRequestCardSummaryContent values={formik.values} />
      </DialogContent>
      <DialogActions>
        <Box flexGrow="1">
          <Button variant="text" onClick={stepManager.goPrev}>
            {t('common.button.back')}
          </Button>
        </Box>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          onClick={() => formik.handleSubmit()}
          disabled={formik.isSubmitting}
        >
          {t('issueCardDialog_v2.summaryStep.issueCard')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default SummaryStep;
