import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemText, MenuContainer, MenuItem } from 'components/Menu';
import { MerchantLogo } from 'domains/merchant/components';
import MerchantLogoUploadDialog from 'domains/merchant/pages/MerchantDetailsPage/MerchantLogoUploadDialog';
import { Box, PencilSimpleIcon } from 'elements';
import { MerchantDetails } from 'services/constants';

interface Props {
  url: string | null;
  name: string;
  merchantId: string;
  onUpdate: (merchant: MerchantDetails) => void;
  onDelete: () => void;
}

const EditMerchantLogo = ({
  merchantId,
  url,
  name,
  onUpdate,
  onDelete,
}: Props) => {
  const { t } = useTranslation();
  const [uploadLogoDialogOpen, setUploadLogoDialogOpen] = useState(false);

  return (
    <>
      <MenuContainer
        menuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        button={
          <Box position="relative">
            <MerchantLogo url={url} name={name} size="large" />
            <Box
              sx={(theme) => ({
                position: 'absolute',
                top: 0,
                left: 0,
                width: 1,
                height: 1,
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                opacity: 0,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  opacity: 1,
                },
              })}
            >
              <PencilSimpleIcon />
            </Box>
          </Box>
        }
      >
        {url ? (
          <MenuItem onClick={onDelete}>
            <ListItemText primary={t('merchantLogoEdit.removeOption')} />
          </MenuItem>
        ) : (
          <MenuItem onClick={() => setUploadLogoDialogOpen(true)}>
            <ListItemText primary={t('merchantLogoEdit.uploadOption')} />
          </MenuItem>
        )}
      </MenuContainer>
      <MerchantLogoUploadDialog
        merchantId={merchantId!}
        open={uploadLogoDialogOpen}
        onUpdate={onUpdate}
        onClose={() => setUploadLogoDialogOpen(false)}
      />
    </>
  );
};

export default EditMerchantLogo;
