import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  DataGrid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Card,
  CardSimpleStatus,
  CardType,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import useColumns from './useColumns';

interface Props extends DialogProps {
  onClose: () => void;
}

interface State {
  isLoading: boolean;
  cards: Card[];
  hasNextPage: boolean;
}

const BlackCardsListDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const columns = useColumns();
  const pageRef = useRef(0);
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    cards: [],
    hasNextPage: false,
  });

  const getData = async (page: number, isLoadMore = false) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
      }
      const { cards, hasNextPage } = await api.getCards({
        type: CardType.black,
        status: [
          CardSimpleStatus.active,
          CardSimpleStatus.pending,
          CardSimpleStatus.requested,
        ].join(),
        organizationId: organization!.id,
        page,
        limit: DEFAULT_PAGE_LIMIT,
        sort: '+issuingDate',
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        cards: isLoadMore ? [...prevState.cards, ...cards] : cards,
        hasNextPage,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData(pageRef.current);
  }, []);

  const loadMoreItems = () => {
    if (state.isLoading || !state.hasNextPage) return;
    pageRef.current++;
    getData(pageRef.current, true);
  };

  return (
    <Dialog {...props} maxWidth="md">
      <DialogTitle>
        {t('subscriptionPlanSettingsSubPage.blackCardsListDialog.title', {
          cardName: t('cardNames.PLIANT_BLACK'),
        })}
      </DialogTitle>
      <DialogContent>
        <Box height={264}>
          <DataGrid
            getRowId={(row) => row.cardId}
            rows={state.cards}
            columns={columns}
            onRowsScrollEnd={loadMoreItems}
            loading={state.isLoading}
            slots={{ noRowsOverlay: () => <NothingFound /> }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t('common.button.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default withDialogWrapper(BlackCardsListDialog);
