import { ComponentType, FC, useMemo } from 'react';
import { ThemeProvider } from '@mui/material';
import { createPartnerTheme } from 'components/App/style/themeMui5';
import { useGlobalState } from 'context/GlobalState';

const withPartnerLogic = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element | null => {
  const {
    state: { themeId },
  } = useGlobalState();
  const theme = useMemo(() => createPartnerTheme(themeId), [themeId]);

  return (
    <ThemeProvider theme={theme}>
      <Component {...props} />
    </ThemeProvider>
  );
};

export default withPartnerLogic;
