import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PartnerAudit } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  partnersOrganizationId: string;
  onSuccess: (partnerAudit: PartnerAudit) => void;
  onClose: () => void;
}

const ChangePartnerIdDialog = ({
  partnersOrganizationId,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      partnersOrganizationId,
    },
    onSubmit: async ({ partnersOrganizationId }, { setSubmitting }) => {
      try {
        const updatedPartnerAudit = await api.changePartnerOrganizationId(
          organization!.id,
          {
            newPartnersOrganizationId: partnersOrganizationId.trim(),
          }
        );

        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: {
            organization: {
              ...organization!,
              partnerName: updatedPartnerAudit.partnerName,
              partnersOrganizationId: updatedPartnerAudit.partnersOrganizationsAudit!
                .partnersOrganizationId,
            },
          },
        });
        if (!mounted.current) return;
        onSuccess(updatedPartnerAudit);
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const trimmedValue = formik.values.partnersOrganizationId.trim();

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.changePartnerIdDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="change-partner-id-form"
          noValidate
        >
          <TextField
            label={t('int.changePartnerIdDialog.partnerIdlabel')}
            placeholder={t('int.changePartnerIdDialog.partnerIdlabel')}
            inputProps={{ maxLength: 100, readOnly: formik.isSubmitting }}
            {...formik.getFieldProps('partnersOrganizationId')}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={
            !trimmedValue ||
            trimmedValue === partnersOrganizationId ||
            formik.isSubmitting
          }
          form="change-partner-id-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(ChangePartnerIdDialog);
