import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import styled from 'styled-components';
import { StyledInputBase } from './StyledInputBase';

export const StyledInputLabel = styled((props: InputLabelProps) => (
  <InputLabel focused={false} {...props}>
    {props.children}
  </InputLabel>
))`
  z-index: 1;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey100};
  transform-origin: 0 50%;
  transform: translate(14px, 38px);
  transition: transform 0.2s;
  pointer-events: none;

  &.MuiInputLabel-shrink {
    transform: translate(0, 0) scale(0.75);
  }

  &.MuiFormLabel-filled {
    transform: translate(0, 0) scale(0.75);
  }

  &.Mui-error {
    color: ${({ theme }) => theme.colors.financeRed40};
  }

  & + ${StyledInputBase} {
    margin-top: 24px;
  }
`;
