import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  doubleDigitFormat,
  MONTHLY_DATE_FORMAT,
  YEARLY_DATE_FORMAT,
} from 'domains/card/utils';
import {
  Box,
  CalendarBlankIcon,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from 'elements';

const MONTHS = moment.months();
const DAYS = Array.from(Array(31).keys()).map((item) =>
  doubleDigitFormat((item + 1).toString())
);

const isValidDate = (day: string, month: string) => {
  return moment(`${day}.${month}`, YEARLY_DATE_FORMAT).isValid();
};

const isBeforeDate = (fromDate: StateDate, toDate: StateDate) => {
  return moment(`${toDate.day}.${toDate.month}`, YEARLY_DATE_FORMAT).isBefore(
    moment(`${fromDate.day}.${fromDate.month}`, YEARLY_DATE_FORMAT)
  );
};

interface Props {
  testId?: string;
  startDate: string | null;
  endDate: string | null;
  onDatesChange: (from: string, to: string | null) => void;
  disabled?: boolean;
}

interface StateDate {
  day: string | null;
  month: string | null;
}

export const DayAndMonthRangePicker = ({
  testId,
  startDate,
  endDate,
  onDatesChange,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState<StateDate>({
    day: startDate ? moment(startDate, YEARLY_DATE_FORMAT).format('DD') : null,
    month: startDate
      ? moment(startDate, YEARLY_DATE_FORMAT).format('MM')
      : null,
  });
  const [toDate, setToDate] = useState<StateDate>({
    day: endDate ? moment(endDate, YEARLY_DATE_FORMAT).format('DD') : null,
    month: endDate ? moment(endDate, YEARLY_DATE_FORMAT).format('MM') : null,
  });

  const renderDay = (day: string | null) => {
    if (!day) {
      return MONTHLY_DATE_FORMAT;
    }
    return day;
  };

  const renderMonth = (month: string | null) => {
    if (!month) {
      return 'MM';
    }
    return month;
  };

  const isStartMonthDisabled = (month: string) => {
    return (
      !fromDate.day ||
      !isValidDate(fromDate.day || '', moment(month, 'MMMM').format('MM'))
    );
  };

  const isEndMonthDisabled = (month: string) => {
    return (
      !toDate.day ||
      !isValidDate(toDate.day || '', moment(month, 'MMMM').format('MM')) ||
      isBeforeDate(fromDate, {
        day: toDate.day || '',
        month: moment(month, 'MMMM').format('MM'),
      })
    );
  };

  const getMonthFromValue = (value: string | null) => {
    if (!value) return '';
    return moment(value, 'MM').format('MMMM');
  };

  const handleStartDayClick = (day: string) => {
    const formattedDay = doubleDigitFormat(day);
    setFromDate({ day: formattedDay, month: null });
    setToDate({ day: null, month: null });
  };

  const handleStartMonthClick = (month: string) => {
    const formattedMonth = moment(month, 'MMMM').format('MM');
    setFromDate({ ...fromDate, month: formattedMonth });
    const updatedFrom = moment(
      `${fromDate.day}.${formattedMonth}`,
      YEARLY_DATE_FORMAT
    );
    const updatedTo = updatedFrom.isBefore(
      moment(`${toDate.day}.${toDate.month}`, YEARLY_DATE_FORMAT)
    )
      ? `${toDate.day}.${toDate.month}`
      : null;
    if (!updatedTo) {
      setToDate({ day: null, month: null });
    }
    onDatesChange(`${fromDate.day}.${formattedMonth}`, updatedTo);
  };

  const handleEndDayClick = (day: string) => {
    const formattedDay = doubleDigitFormat(day);
    setToDate({ day: formattedDay, month: null });
  };

  const handleEndMonthClick = (month: string) => {
    const formattedMonth = moment(month, 'MMMM').format('MM');
    setToDate({ ...toDate, month: formattedMonth });
    onDatesChange(
      `${fromDate.day}.${fromDate.month}`,
      `${toDate.day}.${formattedMonth}`
    );
  };

  return (
    <Box
      data-test-id={testId}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box flex={1} mr={1}>
        <InputLabel>{t('common.from')}</InputLabel>
        <Box display="flex" alignItems="center" mt={1}>
          <FormControl fullWidth sx={{ marginRight: 0.5 }}>
            <Select
              startAdornment={
                <InputAdornment position="start">
                  <CalendarBlankIcon fontSize="small" />
                </InputAdornment>
              }
              value={fromDate.day || ''}
              renderValue={(value) => renderDay(value)}
              onChange={(e) => handleStartDayClick(e.target.value)}
              disabled={disabled}
              displayEmpty
            >
              {DAYS.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth disabled={disabled || !fromDate.day}>
            <Select
              startAdornment={
                <InputAdornment position="start">
                  <CalendarBlankIcon fontSize="small" />
                </InputAdornment>
              }
              value={getMonthFromValue(fromDate.month)}
              renderValue={(value) => renderMonth(value)}
              onChange={(e) => handleStartMonthClick(e.target.value)}
              displayEmpty
            >
              {MONTHS.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  disabled={isStartMonthDisabled(item)}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box flex={1} ml={1}>
        <InputLabel>{t('common.to')}</InputLabel>
        <Box display="flex" alignItems="center" mt={1}>
          <FormControl fullWidth disabled={disabled} sx={{ marginRight: 0.5 }}>
            <Select
              startAdornment={
                <InputAdornment position="start">
                  <CalendarBlankIcon fontSize="small" />
                </InputAdornment>
              }
              value={toDate.day || ''}
              renderValue={(value) => renderDay(value)}
              onChange={(e) => handleEndDayClick(e.target.value)}
              disabled={disabled || !fromDate.day || !fromDate.month}
              displayEmpty
            >
              {DAYS.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth disabled={disabled || !toDate.day}>
            <Select
              startAdornment={
                <InputAdornment position="start">
                  <CalendarBlankIcon fontSize="small" />
                </InputAdornment>
              }
              value={getMonthFromValue(toDate.month)}
              renderValue={(value) => renderMonth(value)}
              onChange={(e) => handleEndMonthClick(e.target.value)}
              disabled={disabled || !toDate.day}
              displayEmpty
            >
              {MONTHS.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  disabled={isEndMonthDisabled(item)}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
