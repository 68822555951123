import { useTranslation } from 'react-i18next';
import { AccountEntryAvatar } from 'domains/transaction/components';
import { Box, Typography } from 'elements';
import {
  AccountEntry,
  AccountEntryBillPaymentType,
  AccountEntryType,
  ExportAccountEntry,
} from 'services/constants';
import { getFullName } from 'services/utils';

interface Props {
  accountEntry: AccountEntry | ExportAccountEntry;
}

const AccountEntryMerchantCell = ({ accountEntry }: Props) => {
  const { t } = useTranslation();

  const getTypeName = () => {
    if (accountEntry.type === AccountEntryType.other) {
      return t('accountingPage.otherPayment');
    }
    if (accountEntry.type === AccountEntryType.reimbursement) {
      return accountEntry.reimbursementInfo?.reimbursementMerchantName;
    }
    const billPaymentType = accountEntry.billPaymentInfo?.billPaymentType;
    if (billPaymentType === AccountEntryBillPaymentType.internalTransfer) {
      const name = accountEntry.billPaymentInfo?.originalPaymentCardAccountName;
      return accountEntry.amount.value > 0
        ? t('accountingPage.transferFrom', { name })
        : t('accountingPage.transferTo', { name });
    }
    if (billPaymentType) {
      return t(`accountEntryBillPaymentType.${billPaymentType}`);
    }
    return '';
  };

  const renderLabel = () => {
    const purpose = accountEntry.billPaymentInfo?.purpose;
    const label = getTypeName() + (purpose ? `: ${purpose}` : '');
    return (
      <Typography variant="body2" noWrap>
        {label}
      </Typography>
    );
  };

  const renderDescription = () => {
    if (accountEntry.type === AccountEntryType.reimbursement) {
      return (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
        >
          {t('common.badge.companyReimbursement')}
        </Typography>
      );
    }
    const billPaymentType = accountEntry.billPaymentInfo?.billPaymentType;
    const paymentType = accountEntry.billPaymentInfo?.paymentType;
    if (billPaymentType === AccountEntryBillPaymentType.bill)
      return (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
        >
          {t(`billPaymentTypes.${paymentType}`)}
        </Typography>
      );
    if (billPaymentType === AccountEntryBillPaymentType.prefunding)
      return (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
        >
          {t(`billPaymentTypes.${paymentType}`)}
        </Typography>
      );
    if (billPaymentType === AccountEntryBillPaymentType.billPaymentReversal)
      return (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
        >
          {t('accountingEntryDetailsPage.ddReturn')}
        </Typography>
      );
    if (billPaymentType === AccountEntryBillPaymentType.directDebitReversalFee)
      return (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
        >
          {t('accountingEntryDetailsPage.ddReturn')}
        </Typography>
      );
    if (billPaymentType === AccountEntryBillPaymentType.payout)
      return (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
        >
          {t(`billPaymentTypes.${paymentType}`)}
        </Typography>
      );
    return null;
  };

  return (
    <Box display="flex" alignItems="center" overflow="hidden">
      <AccountEntryAvatar
        type={accountEntry.type}
        billPaymentType={accountEntry.billPaymentInfo?.billPaymentType}
        memberName={
          accountEntry.reimbursementInfo
            ? getFullName(
                accountEntry.reimbursementInfo.memberFirstName,
                accountEntry.reimbursementInfo.memberLastName
              )
            : undefined
        }
      />
      <Box pl={2} minWidth={0}>
        {renderLabel()}
        {renderDescription()}
      </Box>
    </Box>
  );
};

export default AccountEntryMerchantCell;
