import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box, FormControlLabel, Grid, Switch, Typography } from 'elements';
import {
  CreateNewRepresentative,
  OrganizationRepresentativeInternalStatus,
} from 'services/constants';
import AddRepresentativeButton from './AddRepresentativeButton';
import Representative from './Representative';
import EditRepresentativeDialog from './Representative/EditRepresentativeDialog';

const RepresentativeSection = () => {
  const { t } = useTranslation();
  const {
    state: { representatives },
  } = useCreditAndComplianceContext();

  const [
    showArchviedRepresentatives,
    setShowArchviedRepresentatives,
  ] = useState(false);

  const [
    newRepresentative,
    setNewRepresentative,
  ] = useState<CreateNewRepresentative | null>(null);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            alignItems="center"
            borderBottom="1px solid"
            borderColor={(theme) => theme.palette.divider}
            display="flex"
            justifyContent="space-between"
            py={1}
          >
            <Typography variant="h6">
              {t('int.representativeSection.title')}
            </Typography>

            <FormControlLabel
              checked={showArchviedRepresentatives}
              control={<Switch size="medium" />}
              label={t('int.representativeSection.showArchived')}
              labelPlacement="start"
              onChange={() =>
                setShowArchviedRepresentatives((prevState) => !prevState)
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          {representatives
            .filter(
              (rep) =>
                showArchviedRepresentatives ||
                rep.internalStatus.value !==
                  OrganizationRepresentativeInternalStatus.archived
            )
            .map((rep) => (
              <Representative key={rep.id.value} representative={rep} />
            ))}
        </Grid>

        <Grid item xs={12}>
          <AddRepresentativeButton onSelect={setNewRepresentative} />
        </Grid>
      </Grid>

      <EditRepresentativeDialog
        mode="create"
        open={!!newRepresentative}
        onClose={() => setNewRepresentative(null)}
        relatedIndividual={newRepresentative!}
        representative={undefined}
      />
    </>
  );
};

export default RepresentativeSection;
