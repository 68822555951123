import { useTranslation } from 'react-i18next';
import { StyledIconButton } from 'components/MainHeader/style';
import { useGlobalState } from 'context/GlobalState';
import { ReceiptInboxDialog } from 'domains/transaction/dialogs';
import { useUnmatchedReceiptsCount } from 'domains/transaction/hooks';
import { Badge, Tooltip, TrayIcon } from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { useCanUser } from 'services/rbac';

const ReceiptInboxButton = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { isOrgInOnboarding } = useIsOrgInOnboarding();
  const { isPortalApp } = useCurrentApp();
  const unmatchedReceiptsCountAppBased = useUnmatchedReceiptsCount();
  const {
    dispatch,
    state: {
      organization,
      featureModules,
      receiptInbox: { isDialogOpen },
    },
  } = useGlobalState();

  const toggleDialog = (open: boolean) => {
    dispatch({
      type: 'SET_RECEIPT_INBOX_DATA',
      payload: {
        isDialogOpen: open,
        flow: null,
        selectedTransationHasReceipt: false,
        receipt: null,
        thumbnail: '',
      },
    });
  };

  if (
    !organization ||
    !canUser('receipt-inbox:view') ||
    isOrgInOnboarding ||
    !featureModules.RECEIPT_MANAGEMENT
  )
    return null;

  return (
    <>
      <Tooltip
        title={
          isPortalApp
            ? t('portal.partnerPortal.disabledMsg')
            : t('header.receiptInbox')
        }
      >
        <div>
          <StyledIconButton
            disabled={isPortalApp}
            sx={{ ml: '0 !important' }}
            onClick={() => toggleDialog(true)}
            data-intercom-target="receipt-inbox-button"
          >
            <Badge
              badgeContent={unmatchedReceiptsCountAppBased}
              color="secondary"
            >
              <TrayIcon />
            </Badge>
          </StyledIconButton>
        </div>
      </Tooltip>

      <ReceiptInboxDialog
        open={isDialogOpen}
        onClose={() => toggleDialog(false)}
      />
    </>
  );
};

export default ReceiptInboxButton;
