import FormatMoney from 'components/FormatMoney';
import { DataGridCell, LinearProgress, Typography } from 'elements';
import { Card, CardSimpleStatus, CardType } from 'services/constants';
import { getLimitPercentage, isCardVoid } from 'services/utils';

interface Props {
  card: Card;
}

const AvailableCell = ({ card }: Props) => {
  if (isCardVoid(card)) {
    return (
      <Typography variant="inherit" noWrap>
        <FormatMoney value={card.limit} />
      </Typography>
    );
  }

  if (card.simpleStatus === CardSimpleStatus.requested) {
    return (
      <Typography variant="inherit" color="info.main" noWrap>
        <FormatMoney value={card.limit} />
      </Typography>
    );
  }

  const { limitPercentage, isPercentageLow } = getLimitPercentage({
    availableLimit: card.availableLimit,
    limit: card.limit,
  });

  return (
    <DataGridCell
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        '& > *': { maxWidth: '100%' },
      }}
    >
      {card.requestedLimit && (
        <Typography variant="caption2" component="div" color="info.main" noWrap>
          <FormatMoney value={card.requestedLimit} />
        </Typography>
      )}
      {card.type === CardType.singleUse ? (
        <Typography variant="inherit" noWrap>
          <FormatMoney value={card.availableLimit} />
        </Typography>
      ) : (
        <>
          <Typography variant="inherit" mb={1} noWrap>
            <Typography
              variant="inherit"
              component="span"
              color={isPercentageLow ? 'warning.main' : 'inherit'}
            >
              <FormatMoney value={card.availableLimit} />
            </Typography>{' '}
            / <FormatMoney value={card.limit} />
          </Typography>
          <LinearProgress
            value={limitPercentage}
            color={isPercentageLow ? 'warning' : 'primary'}
            sx={{ width: 120 }}
            size="small"
          />
        </>
      )}
    </DataGridCell>
  );
};

export default AvailableCell;
