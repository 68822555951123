import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import HelpQuestionCircleIcon from 'assets/icons/interface/helpQuestionCircle.svg?react';
import variables from 'components/App/style/themeMui5/variables.json';

export const CircleQuestionMarkIcon = styled(HelpQuestionCircleIcon)<{
  $m?: string;
  $size?: number;
}>`
  flex-shrink: 0;
  width: ${({ $size = 16 }) => $size + `px`};
  height: ${({ $size = 16 }) => $size + `px`};
  margin: ${({ $m }) => $m || '0 0 0 8px'};
  fill: ${({ theme }) => theme.colors.grey100};
`;

// $isWide prop is not used anymore. This component will be replaced with new MUI5 tooltip.
const Tooltip = styled(({ className, children, ...props }: MuiTooltipProps) => (
  <MuiTooltip arrow classes={{ tooltip: className }} {...props}>
    {children}
  </MuiTooltip>
))<{ $isWide?: boolean }>({
  padding: '8px 12px',
  backgroundColor: variables.tooltip.fill,
  borderRadius: variables.shape.borderRadius,
  ...variables.components['tooltip-label'],
  '.MuiTooltip-arrow': {
    color: variables.tooltip.fill,
  },
  'a, button': {
    font: 'inherit',
    color: 'inherit',
  },
});

export default Tooltip;
