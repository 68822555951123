import { ArrowsSplitIcon, Avatar, AvatarProps } from 'elements';

export const SplitTransactionsAvatar = ({
  sx = [],
  size,
  ...props
}: AvatarProps & { size: 'medium' | 'large' }) => {
  return (
    <Avatar
      variant="square"
      sx={[
        {
          background: 'none',
          ...(size === 'large'
            ? {
                height: 80,
                width: 80,
                borderRadius: 2,
                color: 'text.primary',
                border: (theme) =>
                  `1px solid ${theme.variables.input.outlined.enabledBorder}`,
              }
            : {
                height: 24,
                width: 24,
                color: 'action.active',
              }),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <ArrowsSplitIcon
        sx={{
          transform: 'rotate(-90deg)',
          fontSize: size === 'large' ? 48 : 24,
        }}
      />
    </Avatar>
  );
};
