import FormatMoney from 'components/FormatMoney';
import { DataGridCell, LinearProgress, Typography } from 'elements';
import {
  OrganizationAccountType,
  OrganizationStatus,
  PartialOrganization,
} from 'services/constants';
import { getLimitPercentage } from 'services/utils';
import { didOrgOnboardingStepPassed } from 'services/utils/onboarding';

interface Props {
  organization: PartialOrganization;
}

const OrgBalanceLimitCell = ({ organization }: Props) => {
  const { availableLimit, limit, status, accountType, balance } = organization;
  const didOrgPassOnboardingRisk = didOrgOnboardingStepPassed(
    status,
    OrganizationStatus.onboardingRiskReview
  );

  if (accountType === OrganizationAccountType.prefunded) {
    if (didOrgPassOnboardingRisk && !!balance) {
      return (
        <Typography variant="inherit" noWrap>
          <FormatMoney value={balance} />
        </Typography>
      );
    }
    return <>-</>;
  }

  if (availableLimit && limit && didOrgPassOnboardingRisk) {
    const { limitPercentage, isPercentageLow } = getLimitPercentage({
      availableLimit,
      limit,
    });

    return (
      <DataGridCell
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          '& > *': { maxWidth: '100%' },
        }}
      >
        <Typography variant="inherit" noWrap mb={0.5}>
          <Typography
            variant="inherit"
            component="span"
            color={isPercentageLow ? 'warning.main' : 'inherit'}
          >
            <FormatMoney value={availableLimit} />
          </Typography>{' '}
          / <FormatMoney value={limit} />
        </Typography>
        <LinearProgress
          value={limitPercentage}
          color={isPercentageLow ? 'warning' : 'primary'}
          sx={{ width: 180 }}
          size="small"
        />
      </DataGridCell>
    );
  }
  return <>- / -</>;
};

export default OrgBalanceLimitCell;
