import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { DataItemRow } from 'domains/creditAndCompliance/components';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { parseAndReformatDateStr } from 'domains/creditAndCompliance/utils';
import { Box, EditButton, Grid, Typography } from 'elements';
import { AgencyProfile, OrganizationsStaticData } from 'services/constants';
import CreditReports from './CreditReports';
import EditAgencyProfileDialog from './EditAgencyProfileDialog';

const AgencyProfileSection = () => {
  const { t } = useTranslation();
  const [
    isEditAgencyProfileDialogOpen,
    setIsEditAgencyProfileDialogOpen,
  ] = useState(false);
  const {
    state: { organizationsStaticData },
  } = useGlobalState();
  const {
    state: { creditProfile },
  } = useCreditAndComplianceContext();
  const agencyProfile = creditProfile!.agencyProfile;

  const getSourceField = (
    field: keyof AgencyProfile,
    optionsSource: keyof OrganizationsStaticData = 'agencyProfileSources'
  ) => {
    if (agencyProfile[field].value === null) return '-';

    return (
      organizationsStaticData![optionsSource].find(
        (item) => item.name === agencyProfile[field].value
      )?.label ?? '-'
    );
  };

  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        py={1}
      >
        <Typography mr={1} variant="h6">
          {t('int.agencyProfileSection.title')}
        </Typography>

        <EditButton onClick={() => setIsEditAgencyProfileDialogOpen(true)} />
      </Box>

      <Grid container direction="column" spacing={2} py={3}>
        <DataItemRow
          label={t('int.agencyProfileSection.crefoId')}
          updatedAt={agencyProfile.crefoId.updatedAt}
          updatedBy={agencyProfile.crefoId.updatedBy}
          value={agencyProfile.crefoId.value}
        />
        <DataItemRow
          label={t('int.agencyProfileSection.dnbId')}
          updatedAt={agencyProfile.duns.updatedAt}
          updatedBy={agencyProfile.duns.updatedBy}
          value={agencyProfile.duns.value}
        />
        <DataItemRow
          label={t('int.agencyProfileSection.companyStatus')}
          updatedAt={agencyProfile.companyStatus.updatedAt}
          updatedBy={agencyProfile.companyStatus.updatedBy}
          value={
            agencyProfile.companyStatus.value
              ? `${
                  organizationsStaticData!.companyStatuses.find(
                    (item) => item.name === agencyProfile.companyStatus.value
                  )?.label ?? '-'
                } (${getSourceField('companyStatusSource')})`
              : null
          }
        />
        <DataItemRow
          label={t('int.agencyProfileSection.companyIsInsolvent')}
          updatedAt={agencyProfile.insolvency.updatedAt}
          updatedBy={agencyProfile.insolvency.updatedBy}
          value={
            typeof agencyProfile.insolvency.value === 'boolean'
              ? `${t(
                  `common.${agencyProfile.insolvency.value ? 'yes' : 'no'}`
                )} (${getSourceField('insolvencySource')})`
              : null
          }
        />
        <DataItemRow
          label={t('int.agencyProfileSection.pd')}
          updatedAt={agencyProfile.probabilityOfDefault.updatedAt}
          updatedBy={agencyProfile.probabilityOfDefault.updatedBy}
          value={
            typeof agencyProfile.probabilityOfDefault.value === 'number'
              ? `${agencyProfile.probabilityOfDefault.value}% (${getSourceField(
                  'probabilityOfDefaultSource',
                  'probabilityOfDefaultSources'
                )})`
              : null
          }
        />
        <DataItemRow
          label={t('int.agencyProfileSection.creditRating')}
          updatedAt={agencyProfile.creditRating.updatedAt}
          updatedBy={agencyProfile.creditRating.updatedBy}
          value={agencyProfile.creditRating.value}
        />
        <DataItemRow
          label={t('int.agencyProfileSection.agencyLimit')}
          updatedAt={agencyProfile.agencyLimit.updatedAt}
          updatedBy={agencyProfile.agencyLimit.updatedBy}
          value={
            agencyProfile.agencyLimit.value ? (
              <>
                <FormatMoney value={agencyProfile.agencyLimit.value} />
                <span>{` (${getSourceField('agencyLimitSource')})`}</span>
              </>
            ) : null
          }
        />
        <DataItemRow
          label={t('int.agencyProfileSection.publishedAccounts')}
          updatedAt={agencyProfile.publishedAccounts.updatedAt}
          updatedBy={agencyProfile.publishedAccounts.updatedBy}
          value={
            typeof agencyProfile.publishedAccounts.value === 'boolean'
              ? [
                  t(
                    `common.${
                      agencyProfile.publishedAccounts.value ? 'yes' : 'no'
                    }`
                  ),
                  `(${getSourceField('publishedAccountsSource')})`,
                  `\n${parseAndReformatDateStr(
                    agencyProfile.publishedAccountsDate.value,
                    'DD.MM.YYYY'
                  )}`,
                ].join(' ')
              : null
          }
        />
        <Grid item display="flex" alignItems="flex-start">
          <Typography variant="body2" minWidth="200px">
            {t('int.agencyProfileSection.creditReport.title')}
          </Typography>

          <CreditReports />
        </Grid>
      </Grid>

      <EditAgencyProfileDialog
        open={isEditAgencyProfileDialogOpen}
        onClose={() => setIsEditAgencyProfileDialogOpen(false)}
      />
    </>
  );
};

export default AgencyProfileSection;
