import { ReactNode, useEffect, useState } from 'react';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import FormatMoney from 'components/FormatMoney';
import { CardSensitiveDataValuePci, CardTile } from 'domains/card/components';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link,
  LoaderWithOverlay,
  PaperLabeledValue,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card, CardConfigGroup } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

// Matches the styling of CVV and PAN from the MeaWallet and PCI Widget iframes
// (see the '.mea-data' and '.pliant-field-cvv-value-text' classes in public/css/*).
const FrameTypography = ({ children }: { children: ReactNode }) => (
  <Typography
    sx={{
      font: '400 14px/20px "MaisonNeue", sans-serif',
      letterSpacing: '0.25px',
      color: '#19242a',
    }}
  >
    {children}
  </Typography>
);

interface Props extends DialogProps {
  card: Card;
  onClose: () => void;
}

const CardDetailsDialog = ({ card, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const traceId = uuidv4();
  const [totp, setTotp] = useState<string | null>(null);
  const [isPanLoaded, setIsPanLoaded] = useState(false);
  const [isCvvLoaded, setIsCvvLoaded] = useState(false);

  const getData = async () => {
    try {
      const data = await api.getTotpPci(card.cardId, traceId);
      setTotp(data);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const showValueCopiedMessage = (label: string) =>
    enqueueSnackbar(t('cardDetailsDialog.copiedToClipboard', { label }), {
      autoHideDuration: 1500,
    });

  return (
    <Dialog {...props}>
      <DialogTitle>{t('cardDetailsDialog.title')}</DialogTitle>
      <DialogContent>
        <Box display="flex">
          <Box mr={3}>
            <CardTile
              card={card}
              pan={`**** **** **** ${card.cardRefNum || '****'}`}
              cvv="***"
            />
          </Box>

          <Box flexGrow="1">
            <PaperLabeledValue
              label={t('cardDetailsDialog.cardNumber')}
              value={
                totp && (
                  <CardSensitiveDataValuePci
                    type="pan"
                    cardId={card.cardId}
                    traceId={traceId}
                    totp={totp}
                    textAlign="right"
                    onLoadSuccess={() => setIsPanLoaded(true)}
                    onLoadError={() => {
                      enqueueSnackbar(t('errors.general'), {
                        variant: 'error',
                      });
                      props.onClose();
                    }}
                    onClear={props.onClose}
                    onCopy={() =>
                      showValueCopiedMessage(t('cardDetailsDialog.cardNumber'))
                    }
                  />
                )
              }
            />

            <PaperLabeledValue
              label={t('cardDetailsDialog.expirationDate')}
              value={
                <FrameTypography>
                  <CopyToClipboard
                    text={moment.utc(card.expiryDate).format('MM/YY')}
                    onCopy={() =>
                      showValueCopiedMessage(
                        t('cardDetailsDialog.expirationDate')
                      )
                    }
                  >
                    <Link underline="hover" component="button" color="inherit">
                      {moment.utc(card.expiryDate).format('MM/YY')}
                    </Link>
                  </CopyToClipboard>
                </FrameTypography>
              }
            />

            <PaperLabeledValue
              label={t('cardDetailsDialog.securityCode')}
              value={
                totp && (
                  <CardSensitiveDataValuePci
                    type="cvv"
                    cardId={card.cardId}
                    traceId={traceId}
                    totp={totp}
                    textAlign="right"
                    onLoadSuccess={() => setIsCvvLoaded(true)}
                    onLoadError={() => {
                      enqueueSnackbar(t('errors.general'), {
                        variant: 'error',
                      });
                      props.onClose();
                    }}
                    onClear={props.onClose}
                    onCopy={() =>
                      showValueCopiedMessage(
                        t('cardDetailsDialog.securityCode')
                      )
                    }
                  />
                )
              }
            />

            {card.cardConfigGroup === CardConfigGroup.pliantVirtualTravel &&
              card.cardValidity && (
                <>
                  <PaperLabeledValue
                    label={t('cardDetailsDialog.validityStartDate')}
                    value={
                      <FrameTypography>
                        {moment(card.cardValidity.validFrom).format(
                          'D MMM YYYY'
                        )}
                      </FrameTypography>
                    }
                  />
                  <PaperLabeledValue
                    label={t('cardDetailsDialog.limit')}
                    value={
                      <FrameTypography>
                        <FormatMoney value={card.limit} fractionalPart />
                      </FrameTypography>
                    }
                  />
                </>
              )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t('common.button.close')}</Button>
      </DialogActions>
      <LoaderWithOverlay
        loading={!isPanLoaded || !isCvvLoaded}
        BackdropProps={{
          appear: false,
          sx: (theme) => ({ background: theme.palette.common.white }),
        }}
      />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(CardDetailsDialog);
