import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import withPageConfig from 'hoc/withPageConfig';
import { PageContent, PageHeader, PageTitle, Tab, Tabs } from 'layout';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';
import { getPath, PathsKeys } from 'services/utils';
import CardBenefitsSubpage from './CardBenefitsSubpage';
import CashbackSubpage from './CashbackSubpage';
import PartnerDealsSubpage from './PartnerDealsSubpage';

const useTabs = () => {
  const { t, i18n } = useTranslation();
  const canUser = useCanUser();
  const { partnerDealsEnabled } = useFlags();
  const {
    state: { organization, featureModules },
  } = useGlobalState();

  return useMemo(() => {
    const tabs = [];

    const to = (key: PathsKeys) =>
      generatePath(getPath(key), {
        orgId: organization!.id,
      });

    if (featureModules.CASHBACK && canUser('cashbacks-subpage:visit')) {
      tabs.push({
        label: t('rewardsPage.cashback'),
        path: to('rewardsCashback'),
        Component: CashbackSubpage,
      });
    }

    if (partnerDealsEnabled) {
      tabs.push({
        label: t('rewardsPage.partnerDeals'),
        path: to('rewardsPartnerDeals'),
        Component: PartnerDealsSubpage,
      });
    }

    if (canUser('card-benefits-subpage:visit')) {
      tabs.push({
        label: t('rewardsPage.cardBenefits'),
        path: to('rewardsCardBenefits'),
        Component: CardBenefitsSubpage,
      });
    }

    return tabs;
  }, [
    i18n.language,
    canUser,
    partnerDealsEnabled,
    organization!.id,
    featureModules,
  ]);
};

const RewardsPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const tabs = useTabs();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    contentRef.current?.scrollTo({ top: 0 });
  }, [pathname]);

  return (
    <>
      <PageHeader>
        <PageTitle title={t('rewardsPage.title')} />
      </PageHeader>

      <Tabs
        value={tabs.some((item) => item.path === pathname) ? pathname : false}
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.divider}`,
          mx: 5,
        })}
      >
        {tabs.map(({ path, label }) => (
          <Tab
            key={path}
            component={Link}
            value={path}
            to={path}
            label={label}
          />
        ))}
      </Tabs>

      <PageContent ref={contentRef} pt={5}>
        <Switch>
          {tabs.map(({ path, Component }) => (
            <Route key={path} path={path} component={Component} />
          ))}
          <Redirect to={tabs[0].path} />
        </Switch>
      </PageContent>
    </>
  );
};

export default withPageConfig(RewardsPage, {
  permission: 'rewards-page:visit',
});
