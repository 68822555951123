import { ComponentType, FC, useEffect, useState } from 'react';
import { DetailsDrawerProps } from './index';

/**
 * A simple HOC which unmounts details drawer components when they are closed
 * in order to reset their state and run effects again when they are opened.
 */
export const withDetailsDrawerWrapper = <P extends DetailsDrawerProps>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element | null => {
  const [didExit, setDidExit] = useState(true);

  useEffect(() => {
    if (props.open) setDidExit(false);
  }, [props.open]);

  if (!props.open && didExit) return null;

  return (
    <Component
      {...props}
      SlideProps={{
        ...props.SlideProps,
        onExited: (node) => {
          setDidExit(true);
          if (props.SlideProps?.onExited) {
            props.SlideProps.onExited(node);
          }
        },
      }}
    />
  );
};
