import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormHelperText,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useHeaderBorderOnScroll from 'hooks/useBorderOnScroll';

const MAX_LENGTH_REASON_FOR_ADDITIONAL_INFO_REQUEST = 255;

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (
    reasonForAdditionalInfoRequest: string
  ) => Promise<true | undefined>;
}

function RequestAdditionalInfoReasonDialog({ onSuccess, ...props }: Props) {
  const { t } = useTranslation();
  const { getHeaderStyle, onContentScroll } = useHeaderBorderOnScroll();
  const [state, setState] = useState<{
    value: string;
    isLoading: boolean;
  }>({
    value: '',
    isLoading: false,
  });

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle style={getHeaderStyle()}>
        {t('int.requestAdditionalInfoReasonDialog.title')}
      </DialogTitle>
      <DialogContent onScroll={onContentScroll}>
        <TextField
          label={t('int.requestAdditionalInfoReasonDialog.commentLabel')}
          multiline
          disabled={state.isLoading}
          minRows={5}
          value={state.value}
          onChange={(e) =>
            setState((prevState) => ({
              ...prevState,
              value: e.target.value,
            }))
          }
        />
        <Box display="flex" justifyContent="flex-end" margin={1}>
          <FormHelperText
            error={
              state.value.length > MAX_LENGTH_REASON_FOR_ADDITIONAL_INFO_REQUEST
            }
          >{`${state.value.length} / ${MAX_LENGTH_REASON_FOR_ADDITIONAL_INFO_REQUEST}`}</FormHelperText>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            props.onClose();
          }}
        >
          {t('common.button.cancel')}
        </Button>

        <Button
          disabled={
            state.value.length === 0 ||
            state.value.length > MAX_LENGTH_REASON_FOR_ADDITIONAL_INFO_REQUEST
          }
          onClick={async () => {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            const response = await onSuccess(state.value);
            if (response) {
              props.onClose();
            } else {
              setState((prevState) => ({
                ...prevState,
                isLoading: false,
              }));
            }
          }}
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
}

export default withDialogWrapper<Props>(RequestAdditionalInfoReasonDialog);
