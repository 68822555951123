import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  CircularProgress,
  Divider,
  DownloadIcon,
  ListItemText,
  LoadingButton,
  MenuContainer,
  MenuItem,
  Tooltip,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  GetAccountEntriesParams,
  MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_ZIP_RECEIPTS,
  NetworkErrorCode,
  PartnerAuthStatus,
  PartnerIds,
  TransactionsDownloadFileType,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  downloadResponseAsFile,
  getGenericErrorMsg,
  getNetworkErrorCode,
} from 'services/utils';

const useIsViaxoftIntegrationActive = () => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const [isActive, setIsActive] = useState(false);

  const getData = async () => {
    try {
      const partnerOrgAuthDetails = await api.getPartnerOrgAuthDetails(
        PartnerIds.viaxoft,
        organization!.id
      );
      if (!mounted.current) return;
      setIsActive(partnerOrgAuthDetails.status === PartnerAuthStatus.active);
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 404) return;
      logError(error);
    }
  };

  useEffect(() => {
    if (!canUser('partners:view')) return;
    getData();
  }, []);

  return isActive;
};

interface Props {
  disabled: boolean;
  transactionsCount: number;
  isCamtDisabled: boolean;
  getRequestParams: () => GetAccountEntriesParams;
  cardAccountId: string;
}

const AccountEntriesPageMenu = ({
  disabled,
  transactionsCount,
  isCamtDisabled: isCamtDownloadDisabled,
  getRequestParams,
  cardAccountId,
}: Props) => {
  const {
    downloadBillingStatementsOnAccEntriesEnabled,
    downloadBalanceStatementOnAccEntriesEnabled,
    downloadAccConfirmationStatementEnabled,
  } = useFlags();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { isExternalApp } = useCurrentApp();
  const {
    state: { cardAccounts },
  } = useGlobalState();
  const [isCsvDownloading, setIsCsvDownloading] = useState(false);
  const [isCamtDownloading, setIsCamtDownloading] = useState(false);
  const [isZipDownloading, setIsZipDownloading] = useState(false);
  const [
    isBillingStatementDownloading,
    setIsBillingStatementDownloading,
  ] = useState(false);
  const [
    isAccConfirmationStatementDownloading,
    setIsAccConfirmationStatementDownloading,
  ] = useState(false);
  const [
    isCustomViaxoftCsvDownloading,
    setIsCustomViaxoftCsvDownloading,
  ] = useState(false);
  const [
    isBalanceStatementDownloading,
    setIsBalanceStatementDownloading,
  ] = useState(false);
  const isCustomViaxoftCsvVisible = useIsViaxoftIntegrationActive();
  const activationStatementDocumentId =
    cardAccounts.find((item) => item.id === cardAccountId)
      ?.activationStatementDocumentId?.value || null;

  const downloadCsv = async () => {
    try {
      setIsCsvDownloading(true);
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { page, limit, ...params } = getRequestParams();
      const response = await api.getAccountEntriesFile({
        ...params,
        format: TransactionsDownloadFileType.defaultCsv,
      });
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setIsCsvDownloading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsCsvDownloading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const downloadCustomViaxoftCsv = async () => {
    try {
      setIsCustomViaxoftCsvDownloading(true);
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { page, limit, ...params } = getRequestParams();
      const response = await api.getAccountEntriesFile({
        ...params,
        format: TransactionsDownloadFileType.customViaxoftCsv,
      });
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setIsCustomViaxoftCsvDownloading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsCustomViaxoftCsvDownloading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const downloadCamt = async () => {
    try {
      setIsCamtDownloading(true);
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { page, limit, ...params } = getRequestParams();
      const response = await api.getAccountEntriesFile({
        ...params,
        format: TransactionsDownloadFileType.camt053Xml,
      });
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setIsCamtDownloading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsCamtDownloading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const downloadTransactionsReceipts = async () => {
    try {
      setIsZipDownloading(true);
      const params = getRequestParams();
      const { transactionIds } = await api.getAccountEntriesIds(params);
      if (!mounted.current) return;

      // this is uncommon case, which can happen sometimes
      if (transactionIds.length < 1) {
        setIsZipDownloading(false);
        enqueueSnackbar(t('errors.general'), { variant: 'error' });
        return;
      }

      if (
        transactionIds.length > MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_ZIP_RECEIPTS
      ) {
        setIsZipDownloading(false);
        closeSnackbar();
        enqueueSnackbar(
          <Box display="flex" alignItems="center">
            {t('accountEntriesPageMenu.tooManySelectedTransactionsError', {
              count: MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_ZIP_RECEIPTS,
            })}
          </Box>,
          {
            persist: true,
            variant: 'warning',
          }
        );
        return;
      }
      const response = await api.getTransactionReceipts(
        params.organizationId,
        transactionIds
      );
      if (!mounted.current) return;
      setIsZipDownloading(false);
      downloadResponseAsFile(response);
    } catch (error) {
      if (!mounted.current) return;
      closeSnackbar();
      if (getNetworkErrorCode(error) === NetworkErrorCode.notFound) {
        enqueueSnackbar(t('accountEntriesPageMenu.noUploadedReceiptsError'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
      setIsZipDownloading(false);
    }
  };

  const downloadBillingStatement = async () => {
    try {
      const {
        organizationId,
        cardAccountId,
        fromBookingDate,
        toBookingDate,
      } = getRequestParams();
      if (!fromBookingDate || !toBookingDate) {
        enqueueSnackbar(t('accountEntriesPageMenu.emptyBookingDateError'), {
          variant: 'error',
        });
        return;
      }
      if (
        moment(toBookingDate, 'YYYY-MM-DD').diff(fromBookingDate, 'days') + 1 >
        31
      ) {
        enqueueSnackbar(t('accountEntriesPageMenu.invalidBookingDateError'), {
          variant: 'error',
        });
        return;
      }
      setIsBillingStatementDownloading(true);
      const response = await api.getBillingStmt({
        organizationId,
        cardAccountId,
        fromBookingDate,
        toBookingDate,
      });
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setIsBillingStatementDownloading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsBillingStatementDownloading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const downloadBalanceStatement = async () => {
    try {
      setIsBalanceStatementDownloading(true);
      const { fromBookingDate, toBookingDate } = getRequestParams();
      const response = await api.getBalanceStatement(cardAccountId, {
        fromBookingDate,
        toBookingDate,
      });
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setIsBalanceStatementDownloading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsBalanceStatementDownloading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const downloadAccConfirmationStatement = async () => {
    try {
      setIsAccConfirmationStatementDownloading(true);
      const response = await api.getPublicDocumentContent(
        activationStatementDocumentId!
      );
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setIsAccConfirmationStatementDownloading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsAccConfirmationStatementDownloading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const isLoading =
    isCsvDownloading ||
    isCamtDownloading ||
    isZipDownloading ||
    isAccConfirmationStatementDownloading ||
    isBalanceStatementDownloading ||
    isCustomViaxoftCsvDownloading;

  return (
    <>
      <MenuContainer
        button={
          <LoadingButton
            sx={{ ml: 1 }}
            variant="text"
            startIcon={<DownloadIcon />}
            loadingPosition="start"
            disabled={isExternalApp && disabled}
            loading={isLoading}
          >
            {t('accountEntriesPageMenu.menuBtn')}
            {!!transactionsCount && ` (${transactionsCount})`}
          </LoadingButton>
        }
      >
        <MenuItem disabled={disabled || isCsvDownloading} onClick={downloadCsv}>
          <ListItemText primary={t('accountEntriesPageMenu.csv')} />
          {isCsvDownloading && (
            <Box ml="auto" pl={1} display="inline-flex">
              <CircularProgress size="small" />
            </Box>
          )}
        </MenuItem>

        {canUser('account-entries-zip:download') && (
          <MenuItem
            disabled={disabled || isZipDownloading}
            onClick={downloadTransactionsReceipts}
          >
            <ListItemText
              primary={t('accountEntriesPageMenu.receiptsZipBtn')}
            />
            {isZipDownloading && (
              <Box ml="auto" pl={1} display="inline-flex">
                <CircularProgress size="small" />
              </Box>
            )}
          </MenuItem>
        )}

        <Tooltip
          title={
            isCamtDownloadDisabled &&
            t('accountEntriesPageMenu.camtDisabledTooltip')
          }
        >
          <span>
            <MenuItem
              disabled={disabled || isCamtDownloadDisabled || isCamtDownloading}
              onClick={downloadCamt}
            >
              <ListItemText
                primary={
                  <>
                    {t('accountEntriesPageMenu.camt053Btn')}
                    {isCamtDownloading && (
                      <Box ml="auto" pl={1} display="inline-flex">
                        <CircularProgress size="small" />
                      </Box>
                    )}
                  </>
                }
              />
            </MenuItem>
          </span>
        </Tooltip>

        {downloadBillingStatementsOnAccEntriesEnabled && (
          <MenuItem
            disabled={disabled || isBillingStatementDownloading}
            onClick={downloadBillingStatement}
          >
            <ListItemText
              primary={t('accountEntriesPageMenu.billingStatement')}
            />
            {isBillingStatementDownloading && (
              <Box ml="auto" pl={1} display="inline-flex">
                <CircularProgress size="small" />
              </Box>
            )}
          </MenuItem>
        )}
        {downloadBalanceStatementOnAccEntriesEnabled &&
          canUser('acc-balance-statement:download') && (
            <MenuItem
              disabled={disabled || isBalanceStatementDownloading}
              onClick={downloadBalanceStatement}
            >
              <ListItemText
                primary={t('accountEntriesPageMenu.accBalanceStatement')}
              />
              {isBalanceStatementDownloading && (
                <Box ml="auto" pl={1} display="inline-flex">
                  <CircularProgress size="small" />
                </Box>
              )}
            </MenuItem>
          )}
        {downloadAccConfirmationStatementEnabled &&
          activationStatementDocumentId &&
          canUser('acc-confirmation-statement:download') && (
            <MenuItem
              disabled={disabled || isAccConfirmationStatementDownloading}
              onClick={downloadAccConfirmationStatement}
            >
              <ListItemText
                primary={t('accountEntriesPageMenu.accConfirmationStatement')}
              />
              {isAccConfirmationStatementDownloading && (
                <Box ml="auto" pl={1} display="inline-flex">
                  <CircularProgress size="small" />
                </Box>
              )}
            </MenuItem>
          )}

        {isCustomViaxoftCsvVisible && <Divider />}
        {isCustomViaxoftCsvVisible && (
          <MenuItem
            disabled={disabled || isCustomViaxoftCsvDownloading}
            onClick={downloadCustomViaxoftCsv}
          >
            <ListItemText
              primary={t('accountEntriesPageMenu.customCsvViaxoft')}
            />
            {isCustomViaxoftCsvDownloading && (
              <Box ml="auto" pl={1} display="inline-flex">
                <CircularProgress size="small" />
              </Box>
            )}
          </MenuItem>
        )}
      </MenuContainer>
    </>
  );
};

export default AccountEntriesPageMenu;
