import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Label = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.grey100};
`;

interface Props {
  count: number;
}

export const MatchesCountLabel = ({ count }: Props) => {
  const { t } = useTranslation();

  return <Label>{t('filters.matchesCountLabel', { count })}</Label>;
};
