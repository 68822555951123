import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { PaymentStatusBadge } from 'domains/billing/components';
import { Chip, drawerPlaceholderColumn, GridColDef } from 'elements';
import { ReceivableRepayment } from 'services/constants';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<ReceivableRepayment>[] = [
      {
        headerName: t('int.receivableRepaymentsPage.createdAt'),
        field: 'createdAt',
        flex: 100,
        minWidth: 100,
        valueGetter: ({ row }) => moment(row.createdAt).format('YYYY-MM-DD'),
        sortable: false,
      },
      {
        headerName: t('int.receivableRepaymentsPage.paymentDate'),
        field: 'plannedPaymentDate',
        flex: 100,
        minWidth: 110,
        sortable: false,
      },
      {
        headerName: t('int.receivableRepaymentsPage.accountGroup'),
        field: 'accountGroup',
        flex: 90,
        minWidth: 120,
        renderCell: ({ row }) => (
          <Chip label={t(`accountGroup.${row.accountGroup}`)} size="small" />
        ),
        sortable: false,
      },
      {
        headerName: t('int.receivableRepaymentsPage.currency'),
        field: 'currency',
        flex: 60,
        minWidth: 100,
        sortable: false,
      },
      {
        headerName: t('int.receivableRepaymentsPage.amount'),
        field: 'amount',
        flex: 120,
        minWidth: 120,
        renderCell: ({ row }) => (
          <FormatMoney value={row.amount} fractionalPart />
        ),
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.receivableRepaymentsPage.cardAccountCount'),
        field: 'cardAccountCount',
        flex: 120,
        minWidth: 120,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.receivableRepaymentsPage.status'),
        field: 'status',
        flex: 110,
        minWidth: 120,
        renderCell: ({ row }) => <PaymentStatusBadge status={row.status} />,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
      drawerPlaceholderColumn,
    ];

    return { columns };
  }, [i18n.language]);
};

export default useColumns;
