import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OrganizationProductType } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface State {
  isLoading: boolean;
  productType: OrganizationProductType;
}

const EditProductTypeDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
    dispatch,
  } = useGlobalState();

  const [state, setState] = useState<State>({
    isLoading: false,
    productType: organization!.productType,
  });

  const updateProductType = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const data = await api.updateProductType(
        organization!.id,
        state.productType
      );
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { organization: data },
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const isSubmitDisabled =
    state.isLoading || state.productType === organization!.productType;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('int.creditAndCompliancePageHeader.productTypeSection.title')}
      </DialogTitle>

      <DialogContent>
        <FormControl variant="standard">
          <FormLabel>
            {t(
              'int.creditAndCompliancePageHeader.productTypeSection.selectProductType'
            )}
          </FormLabel>
          <RadioGroup
            row
            value={state.productType}
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                isConfirmDialogOpen: true,
                productType: e.target.value as OrganizationProductType,
              }));
            }}
          >
            <FormControlLabel
              control={<Radio />}
              disabled
              label={OrganizationProductType.CCREU}
              value={OrganizationProductType.CCREU}
            />
            <FormControlLabel
              control={<Radio />}
              disabled={state.isLoading}
              label={OrganizationProductType.MDESD}
              value={OrganizationProductType.MDESD}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={isSubmitDisabled} onClick={updateProductType}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditProductTypeDialog);
