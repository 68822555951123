import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, CloudXIcon, Typography } from 'elements';
import { Content, Overlay } from './style';

const PageNotFoundError = () => {
  const { t } = useTranslation();

  return (
    <Overlay>
      <Content>
        <CloudXIcon
          fontSize="large"
          sx={{ verticalAlign: 'top', mb: 1, color: 'text.secondary' }}
        />
        <Typography variant="h6" mb={1}>
          {t('errorOverlay.pageNotFoundError.title')}
        </Typography>
        <Typography color="text.secondary" mb={2}>
          {t('errorOverlay.pageNotFoundError.description')}
        </Typography>
        <Button component={Link} to="/" size="large">
          {t('errorOverlay.pageNotFoundError.backToHome')}
        </Button>
      </Content>
    </Overlay>
  );
};

export default PageNotFoundError;
