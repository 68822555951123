import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import CheckboxCheckedIcon from 'assets/icons/checkboxCheckedIcon.svg?react';
import CheckboxIndeterminateIcon from 'assets/icons/checkboxIndeterminateIcon.svg?react';

const CheckboxIcon = styled.span`
  border-radius: 4px;
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.colors.neutralVariant100};
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
`;

export const Checkbox = styled((props: CheckboxProps) => (
  <MuiCheckbox
    disableRipple
    color="default"
    checkedIcon={<CheckboxCheckedIcon />}
    indeterminateIcon={<CheckboxIndeterminateIcon />}
    icon={<CheckboxIcon />}
    {...props}
  />
))`
  &:hover {
    background: transparent;

    ${CheckboxIcon} {
      border-color: ${({ theme }) => theme.colors.grey10};
    }
  }

  &.Mui-disabled {
    opacity: 0.3;
  }

  svg {
    fill: ${({ theme }) => theme.colors.grey10};
  }
`;
