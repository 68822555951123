import { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import { Dialog, DialogProps, DialogTitle, withDialogWrapper } from 'elements';
import { env } from 'env';
import { Transaction, TxPrivateExpenseReimbursement } from 'services/constants';
import { formatMoney } from 'services/utils';
import ConfirmPaymentStep from './ConfirmPaymentStep';
import ConfirmPaymentWithNewDetails from './ConfirmPaymentWithNewDetails';

const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLIC_KEY);

const stripeOptions = {
  appearance: {
    variables: {
      fontFamily: 'MaisonNeue, sans-serif',
    },
  },
};

interface State {
  step: 'confirmPayment' | 'confirmPaymentWithNewDetails';
  clientSecret: string | null;
}

interface Props extends DialogProps {
  transaction: Transaction;
  txPrivateExpenseReimbursement: TxPrivateExpenseReimbursement | null;
  showReimburseLaterText?: boolean;
  onClose: () => void;
}

const ReimbursePrivateExpenseDialog = ({
  transaction,
  txPrivateExpenseReimbursement,
  showReimburseLaterText = false,
  ...props
}: Props) => {
  const { t, i18n } = useTranslation();
  const currencyDisplay = useCurrencyDisplayMode();
  const [state, setState] = useState<State>({
    step: 'confirmPayment',
    clientSecret: null,
  });

  return (
    <Dialog {...props}>
      <DialogTitle>
        {t('transactionDetails.reimbursementDialog.title', {
          amount: formatMoney(transaction.transactionAmount, i18n.language, {
            currencyDisplay,
            fractionalPart: true,
            absoluteValue: true,
          }),
        })}
      </DialogTitle>

      {state.step === 'confirmPayment' && (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <ConfirmPaymentStep
            transactionId={transaction.transactionId}
            txPrivateExpenseReimbursement={txPrivateExpenseReimbursement}
            showReimburseLaterText={showReimburseLaterText}
            onPaymentMethodRequire={(clientSecret) => {
              setState((prevState) => ({
                ...prevState,
                clientSecret,
                step: 'confirmPaymentWithNewDetails',
              }));
            }}
            onClose={props.onClose}
          />
        </Elements>
      )}

      {state.step === 'confirmPaymentWithNewDetails' && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: state.clientSecret!, ...stripeOptions }}
        >
          <ConfirmPaymentWithNewDetails
            transactionId={transaction.transactionId}
            showReimburseLaterText={showReimburseLaterText}
            onClose={props.onClose}
          />
        </Elements>
      )}
    </Dialog>
  );
};

export default withDialogWrapper(ReimbursePrivateExpenseDialog);
