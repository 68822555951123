import Mt940Icon from 'assets/icons/appSpecific/mt940.svg?react';
import { IconButton } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { downloadResponseAsFile, getGenericErrorMsg } from 'services/utils';

interface AccountTransactionIdProps {
  accountTransactionId: string;
  paymentId?: never;
}

interface PaymentIdProps {
  paymentId: string;
  accountTransactionId?: never;
}

type Props = AccountTransactionIdProps | PaymentIdProps;

const DownloadMt940Button = ({ accountTransactionId, paymentId }: Props) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();

  const handleDownloadFile = async () => {
    try {
      const response = await api.downloadAccountStatements(
        accountTransactionId,
        paymentId
      );
      if (!mounted) return;
      downloadResponseAsFile(response);
    } catch (error) {
      if (!mounted) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <IconButton
      onClick={handleDownloadFile}
      sx={{ svg: { height: '24px', width: '24px' } }}
    >
      {/* todo: update the icon later */}
      <Mt940Icon />
    </IconButton>
  );
};

export default DownloadMt940Button;
