import { useRef, useState } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { getCardNameWithRefNum } from 'domains/card/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Card,
  CardPremiumProductType,
  CardTerminationReason,
  CardType,
  physicalCardTerminationReasons,
  virtualCardTerminationReasons,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  reason: CardTerminationReason | '';
}

interface Props extends DialogProps {
  card: Card;
  onClose: () => void;
  onSuccess: (card: Card) => void;
}

const TerminateCardDialog = ({ card, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const premiumCardRemainingDayCount = useRef<number | null>(null);
  const didUserConfirmPremiumCardTermination = useRef(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const formik = useFormik<FormValues>({
    initialValues: {
      reason: '',
    },
    onSubmit: async ({ reason }, { setSubmitting }) => {
      try {
        if (
          card.premiumProductType === CardPremiumProductType.visaInfinite &&
          !didUserConfirmPremiumCardTermination.current
        ) {
          const data = await api.getPremiumCardLatestInvoice(card.cardId);

          if (data.fee && data.fee.value > 0 && data.nextChargingDate) {
            premiumCardRemainingDayCount.current = moment(
              data.nextChargingDate
            ).diff(moment(), 'days');

            if (premiumCardRemainingDayCount.current > 0) {
              setIsConfirming(true);
              return;
            }
          }
        }

        const data = await api.terminateCard(
          card.cardId,
          reason as CardTerminationReason
        );
        if (!mounted.current) return;
        setSubmitting(false);
        onSuccess(data);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setSubmitting(false);
        logError(error);
      }
    },
  });

  const terminationReasons =
    card.type === CardType.virtual || card.type === CardType.singleUse
      ? virtualCardTerminationReasons
      : physicalCardTerminationReasons;
  const reasonTranslationGroupKey =
    card.type === CardType.virtual || card.type === CardType.singleUse
      ? 'virtualCardTerminationReasons'
      : 'physicalCardTerminationReasons';
  const isSubmitDisabled = !formik.values.reason || formik.isSubmitting;

  if (isConfirming) {
    return (
      <ConfirmDialog
        {...props}
        title={t('terminateCardDialog.premiumCardConfirmation.title')}
        description={t(
          'terminateCardDialog.premiumCardConfirmation.description',
          { remainingDayCount: premiumCardRemainingDayCount.current }
        )}
        onClose={() => setIsConfirming(false)}
        onSuccess={() => {
          didUserConfirmPremiumCardTermination.current = true;
          formik.handleSubmit();
        }}
        loading={formik.isSubmitting}
      />
    );
  }

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('terminateCardDialog.terminateCard')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="termiate-card-form" noValidate>
          <Box mb={4}>
            <Typography variant="body2">
              {t('terminateCardDialog.selectReason')}{' '}
              <b>{getCardNameWithRefNum(card)}</b>
            </Typography>
          </Box>
          <RadioGroup {...formik.getFieldProps('reason')}>
            {terminationReasons.map((reason) => (
              <FormControlLabel
                key={reason}
                value={reason}
                control={<Radio />}
                disabled={formik.isSubmitting}
                label={t(`${reasonTranslationGroupKey}.${reason}`)}
              />
            ))}
          </RadioGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="termiate-card-form"
          type="submit"
        >
          {t('terminateCardDialog.terminateCard')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(TerminateCardDialog);
