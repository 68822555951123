import { ReactNode, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, matchPath } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import CustomCsvWarningTooltip from 'components/CustomCsvWarningTooltip';
import { useGlobalState } from 'context/GlobalState';
import { Link, Tooltip, WarningIcon } from 'elements';
import useLocoiaMappedName from 'hooks/useLocoiaMappedName';
import {
  AccountingSystem,
  ApiIntegrationStatus,
  ExportFormat,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { getPath, isAccSystemDownloadVisible } from 'services/utils';

export enum MenuButtonDownloadFormat {
  csv = 'CSV',
  xml = 'XML',
}

interface MenuButtonConfig {
  key: string;
  format: MenuButtonDownloadFormat;
  label: ReactNode;
  isEnabled?: boolean;
  disabled?: boolean;
}

const useGetAccountingExportDownloadConfig = (
  orgId: string
): MenuButtonConfig[] => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const {
    state: { accountingSettings, generalInfo },
  } = useGlobalState();
  const {
    datevBillPaymentsCsvOnStatementsEnabled,
    datevBillPaymentsXmlOnStatementsEnabled,
  } = useFlags();
  const locoiaMappedName = useLocoiaMappedName();
  const isStatementsPage = !!matchPath(location.pathname, {
    path: getPath('statements'),
  });

  return useMemo(() => {
    const isDatevXmlEnabled =
      isAccSystemDownloadVisible(accountingSettings, AccountingSystem.datev) &&
      datevBillPaymentsXmlOnStatementsEnabled &&
      accountingSettings?.exportFormats.includes(ExportFormat.xml);
    const isDatevCsvEnabled =
      datevBillPaymentsCsvOnStatementsEnabled &&
      isAccSystemDownloadVisible(accountingSettings, AccountingSystem.datev) &&
      accountingSettings?.exportFormats.includes(ExportFormat.csv);

    const isDatevApiSelected =
      isAccSystemDownloadVisible(accountingSettings, AccountingSystem.datev) &&
      accountingSettings?.exportFormats.includes(ExportFormat.api);

    const isDatevBillPaymentsCsvBtnVisible =
      isDatevCsvEnabled || isDatevApiSelected;
    const isDatevBillPaymentXmlBtnVisible =
      isDatevXmlEnabled || isDatevApiSelected;

    const isBmdBillPaymentCsvButtonVisible = isAccSystemDownloadVisible(
      accountingSettings,
      AccountingSystem.bmd
    );
    const isAeromonBillPaymentCsvButtonVisible = isAccSystemDownloadVisible(
      accountingSettings,
      AccountingSystem.aeromon
    );
    const isAeroviaggiBillPaymentCsvButtonVisible = isAccSystemDownloadVisible(
      accountingSettings,
      AccountingSystem.aeroviaggi
    );
    const isLexwareBillPaymentCsvButtonVisible = isAccSystemDownloadVisible(
      accountingSettings,
      AccountingSystem.lexware
    );
    const isProcountorBillPaymentCsvButtonVisible = isAccSystemDownloadVisible(
      accountingSettings,
      AccountingSystem.procountor
    );
    const isNetvisorBillPaymentCsvButtonVisible = isAccSystemDownloadVisible(
      accountingSettings,
      AccountingSystem.netvisor
    );
    const isBillPaymentCustomCsvButtonVisible =
      accountingSettings!.accountingSystem ===
      AccountingSystem.customBusinessCenral;

    const isCustomAccSystemButtonsVisible =
      isDatevBillPaymentsCsvBtnVisible ||
      isDatevBillPaymentXmlBtnVisible ||
      isBmdBillPaymentCsvButtonVisible ||
      isAeromonBillPaymentCsvButtonVisible ||
      isAeroviaggiBillPaymentCsvButtonVisible ||
      isLexwareBillPaymentCsvButtonVisible ||
      isProcountorBillPaymentCsvButtonVisible ||
      isNetvisorBillPaymentCsvButtonVisible ||
      isBillPaymentCustomCsvButtonVisible;

    if (!isCustomAccSystemButtonsVisible) return [];

    const getAccExportButtonsList = (): Array<MenuButtonConfig> => {
      switch (accountingSettings!.accountingSystem) {
        case AccountingSystem.datev:
          return [
            {
              key: 'datevBillPaymentsCsv',
              isEnabled: isDatevBillPaymentsCsvBtnVisible,
              format: MenuButtonDownloadFormat.csv,
              label: t('statementItemMenu.datevBillPaymentsCsv'),
            },
            {
              key: 'datevBillPaymentXml',
              isEnabled: isDatevBillPaymentsCsvBtnVisible,
              format: MenuButtonDownloadFormat.xml,
              label: t('statementItemMenu.datevBillPaymentXml'),
            },
          ];
        case AccountingSystem.bmd:
          return [
            {
              key: 'bmdBillPaymentCsv',
              isEnabled: isBmdBillPaymentCsvButtonVisible,
              format: MenuButtonDownloadFormat.csv,
              label: t('statementItemMenu.bmdBillPaymentCsv'),
            },
          ];
        case AccountingSystem.aeromon:
          return [
            {
              key: 'aeromonBillPaymentCsv',
              isEnabled: isAeromonBillPaymentCsvButtonVisible,
              format: MenuButtonDownloadFormat.csv,
              label: t('statementItemMenu.customFormatCsv'),
            },
          ];
        case AccountingSystem.aeroviaggi:
          return [
            {
              key: 'aeroviaggiBillPaymentCsv',
              isEnabled: isAeroviaggiBillPaymentCsvButtonVisible,
              format: MenuButtonDownloadFormat.csv,
              label: t('statementItemMenu.aeroviaggiBillPaymentCsv'),
            },
          ];
        case AccountingSystem.lexware:
          return [
            {
              key: 'lexwareBillPaymentCsv',
              isEnabled: isLexwareBillPaymentCsvButtonVisible,
              format: MenuButtonDownloadFormat.csv,
              label: t('statementItemMenu.lexwareBillPaymentCsv'),
            },
          ];
        case AccountingSystem.procountor:
          return [
            {
              key: 'procountorBillPaymentCsv',
              isEnabled: isProcountorBillPaymentCsvButtonVisible,
              format: MenuButtonDownloadFormat.csv,
              label: t('statementItemMenu.procountorBillPaymentCsv'),
            },
          ];
        case AccountingSystem.netvisor:
          return [
            {
              key: 'netvisorBillPaymentCsv',
              isEnabled: isNetvisorBillPaymentCsvButtonVisible,
              format: MenuButtonDownloadFormat.csv,
              label: t('statementItemMenu.netvisorBillPaymentCsv'),
            },
          ];
        case AccountingSystem.customBusinessCenral: {
          const isButtonDisabled =
            accountingSettings!.apiIntegrationStatus !==
            ApiIntegrationStatus.connected;
          return [
            {
              key: 'customBillPaymentCsv',
              isEnabled: isBillPaymentCustomCsvButtonVisible,
              format: MenuButtonDownloadFormat.csv,
              label: isButtonDisabled ? (
                <>
                  {locoiaMappedName}
                  {isStatementsPage ? (
                    <CustomCsvWarningTooltip />
                  ) : (
                    <Tooltip
                      title={
                        <Trans
                          i18nKey="customCsvWarningTooltip.title"
                          components={{
                            linkTo: (
                              <Link
                                component={RouterLink}
                                to={generatePath(getPath('settingsLocoia'), {
                                  orgId,
                                })}
                                onClick={(e: any) => e.stopPropagation()}
                                color="inherit"
                              />
                            ),
                            sup: (
                              <Link
                                href={'mailto:' + generalInfo.supportEmail}
                                rel="noreferrer"
                                onClick={(e) => e.stopPropagation()}
                                color="inherit"
                              />
                            ),
                          }}
                        />
                      }
                    >
                      <WarningIcon
                        fontSize="small"
                        color="error"
                        sx={{
                          verticalAlign: 'middle',
                          pointerEvents: 'auto',
                          ml: 2,
                        }}
                      />
                    </Tooltip>
                  )}
                </>
              ) : (
                locoiaMappedName
              ),
              disabled: isButtonDisabled,
            },
          ];
        }
        default:
          return [];
      }
    };

    return getAccExportButtonsList().filter((item) => item.isEnabled);
  }, [
    i18n.language,
    accountingSettings,
    locoiaMappedName,
    datevBillPaymentsCsvOnStatementsEnabled,
    datevBillPaymentsXmlOnStatementsEnabled,
  ]);
};

export default useGetAccountingExportDownloadConfig;
