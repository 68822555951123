import PainIcon from 'assets/icons/appSpecific/pain.svg?react';
import { IconButton } from 'elements';
import useDownloadFile from 'hooks/useDownloadFile';

interface Props {
  painFileName: string;
}

const DownloadPainFileButton = ({ painFileName }: Props) => {
  const handleDownloadFile = useDownloadFile(
    '/payments/files',
    { fileName: painFileName },
    { responseType: 'arraybuffer' }
  );

  return (
    <IconButton
      onClick={handleDownloadFile}
      sx={{ svg: { height: '24px', width: '24px' } }}
    >
      {/* todo: update the icon later */}
      <PainIcon />
    </IconButton>
  );
};
export default DownloadPainFileButton;
