import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  Redirect,
  Link as RouterLink,
  useHistory,
  useParams,
} from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  ActiveFilterValue,
  AddNewItemMenu,
  StatusFilterSelect,
} from 'domains/settings/components';
import { EditCustomFieldDialog } from 'domains/settings/dialogs';
import {
  FiltersContainer,
  HeaderContainer,
  HeaderTitle,
} from 'domains/settings/layout';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import {
  Box,
  Breadcrumbs,
  Button,
  DataGrid,
  Link,
  LoaderWithOverlay,
  Typography,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  ContentContainer,
  PageContent,
  PageHeader,
  PageSearchInput,
} from 'layout';
import {
  AccountingItemStatus,
  CodatMappingOptionsSummary,
  CustomFieldOption,
  DEFAULT_PAGE_LIMIT,
  FeatureModuleKey,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getPath } from 'services/utils';
import AddDropdownOptionDialog from './AddDropdownOptionDialog';
import CodatSyncDropdownOptionsDialog from './CodatSyncDropdownOptionsDialog';
import EditDropdownOptionDialog from './EditDropdownOptionDialog';
import useColumns from './useColumns';

interface State {
  dropdownOptions: CustomFieldOption[];
  hasNextPage: boolean;
  isLoading: boolean;
  activeFilter: ActiveFilterValue | '';
  search: string;
  isAddDropdownOptionDialogOpen: boolean;
  isEditDropdownOptionDialogOpen: boolean;
  editableCustomFieldOption: CustomFieldOption | null;
  isCustomFieldEditDialogOpen: boolean;
  codatSyncDialogOpen: boolean;
  codatMappingOptionsSummary: CodatMappingOptionsSummary | null;
}

const CustomFieldDropdownOptionsPage = () => {
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { fieldId } = useParams<{ fieldId: string }>();
  const history = useHistory();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, transactionCustomFields, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    dropdownOptions: [],
    hasNextPage: false,
    isLoading: true,
    activeFilter: '',
    search: '',
    isAddDropdownOptionDialogOpen: false,
    isEditDropdownOptionDialogOpen: false,
    editableCustomFieldOption: null,
    isCustomFieldEditDialogOpen: false,
    codatSyncDialogOpen: false,
    codatMappingOptionsSummary: null,
  });
  const customField = useMemo(
    () => transactionCustomFields.find((field) => field.id === fieldId),
    [fieldId, transactionCustomFields]
  );
  const columns = useColumns((item) =>
    setState((prevState) => ({
      ...prevState,
      isEditDropdownOptionDialogOpen: true,
      editableCustomFieldOption: item,
    }))
  );
  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);
  const isCodatSyncButtonShown =
    canUser('codat-dropdown-options-sync:change') &&
    isCodatConnected &&
    state.codatMappingOptionsSummary;

  const getData = async (isLoadMore: boolean, fetchCodatSummary = false) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const [
        { customFieldOptions, hasNextPage },
        codatMappingOptionsSummary,
      ] = await Promise.all([
        api.getCustomFieldOptions({
          transactionCustomFieldId: fieldId,
          organizationId: organization!.id,
          q: state.search,
          status: (state.activeFilter as AccountingItemStatus) || undefined,
          page: pageRef.current,
          limit: DEFAULT_PAGE_LIMIT,
        }),
        fetchCodatSummary && isCodatConnected && canUser('codat-sync:view')
          ? api.getCodatMappingOptionsSummary(organization!.id)
          : state.codatMappingOptionsSummary,
      ]);
      if (!mounted.current) return;

      setState((prevState) => ({
        ...prevState,
        dropdownOptions: isLoadMore
          ? [...prevState.dropdownOptions, ...customFieldOptions]
          : customFieldOptions,
        codatMappingOptionsSummary,
        hasNextPage,
        isLoading: false,
      }));
    } catch (error) {
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    pageRef.current = 0;
    getData(false, !state.codatMappingOptionsSummary);
  }, [state.activeFilter, state.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(true);
  };

  if (!customField)
    return (
      <Redirect
        to={generatePath(getPath('settingsAccounting'), {
          orgId: organization!.id,
        })}
      />
    );

  const noItemsExist =
    !state.activeFilter && !state.search && !state.dropdownOptions.length;

  return (
    <>
      <PageHeader>
        <Breadcrumbs sx={{ mt: 2 }}>
          <Link
            component={RouterLink}
            to={generatePath(getPath('settingsAccounting'), {
              orgId: organization!.id,
            })}
            sx={{ textDecoration: 'none' }}
            color="textSecondary"
          >
            {t('customFieldsDropdownOptionsPage.customFieldsBreadcrumb')}
          </Link>
          <Typography variant="inherit" component="span" color="textPrimary">
            {customField.label}
          </Typography>
        </Breadcrumbs>
      </PageHeader>

      <PageContent pt={5} display="flex" flexDirection="column">
        <HeaderContainer>
          <ContentContainer>
            <HeaderTitle>
              {t('customFieldsDropdownOptionsPage.title')}

              {state.codatMappingOptionsSummary?.lastSynced && (
                <Typography
                  color="textSecondary"
                  variant="body2"
                  component="span"
                  ml={1}
                >
                  (
                  {t('customFieldsDropdownOptionsPage.lastSync', {
                    date: moment(
                      state.codatMappingOptionsSummary.lastSynced
                    ).format('DD MMM YYYY, HH:mm'),
                  })}
                  )
                </Typography>
              )}
            </HeaderTitle>
            <Typography>
              {isCodatConnected
                ? t('customFieldsDropdownOptionsPage.codatDescription', {
                    name: customField.label,
                    accSystemName: accountingSettings!.accountingSystemName,
                  })
                : t('customFieldsDropdownOptionsPage.description', {
                    name: customField.label,
                  })}
            </Typography>
          </ContentContainer>
        </HeaderContainer>

        <FiltersContainer>
          <PageSearchInput
            sx={{ mr: 2 }}
            initialValue={state.search}
            onChange={(value) =>
              setState((prevState) => ({ ...prevState, search: value }))
            }
            disabled={!state.search && !state.dropdownOptions.length}
          />

          <StatusFilterSelect
            filterValue={state.activeFilter}
            disabled={state.isLoading}
            onFilterChange={(activeFilter) =>
              setState((prevState) => ({
                ...prevState,
                activeFilter,
              }))
            }
          />

          {canUser('custom-fields:manage') && (
            <Box ml="auto" display="flex" alignItems="center">
              {!noItemsExist && !state.isLoading && (
                <AddNewItemMenu
                  variant="outlined"
                  title={t('customFieldsDropdownOptionsPage.addButtonTitle')}
                  onAdd={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isAddDropdownOptionDialogOpen: true,
                    }))
                  }
                  onSync={
                    isCodatSyncButtonShown
                      ? () =>
                          setState((prevState) => ({
                            ...prevState,
                            codatSyncDialogOpen: true,
                          }))
                      : undefined
                  }
                />
              )}

              <Button
                sx={{ ml: 2 }}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isCustomFieldEditDialogOpen: true,
                  }))
                }
              >
                {t('customFieldsDropdownOptionsPage.settingsBtn')}
              </Button>
            </Box>
          )}
        </FiltersContainer>

        <Box
          position="relative"
          flex={1}
          maxHeight={(theme) => `calc(100% + ${theme.spacing(5)})`}
        >
          <LoaderWithOverlay loading={state.isLoading} />

          <DataGrid
            apiRef={dataGridRef}
            disableMultipleRowSelection
            keepNonExistentRowsSelected
            loading={state.isLoading}
            rows={state.dropdownOptions}
            columns={columns}
            columnVisibilityModel={{
              actions: canUser('custom-fields:manage'),
            }}
            onRowsScrollEnd={() => {
              if (!state.isLoading && state.hasNextPage) loadMoreItems();
            }}
            slots={{
              noRowsOverlay: () => {
                if (
                  noItemsExist &&
                  !state.isLoading &&
                  canUser('custom-fields:manage')
                )
                  return (
                    <Box textAlign="center" pt={4}>
                      <Typography variant="h5" mb={1}>
                        {t(
                          'customFieldsDropdownOptionsPage.createFirstOptionTitle'
                        )}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" mb={2}>
                        {t(
                          'customFieldsDropdownOptionsPage.createFirstOptionDescription'
                        )}
                      </Typography>
                      <AddNewItemMenu
                        title={t(
                          'customFieldsDropdownOptionsPage.addButtonTitle'
                        )}
                        size="large"
                        onAdd={() =>
                          setState((prevState) => ({
                            ...prevState,
                            isAddDropdownOptionDialogOpen: true,
                          }))
                        }
                        onSync={
                          isCodatSyncButtonShown
                            ? () =>
                                setState((prevState) => ({
                                  ...prevState,
                                  codatSyncDialogOpen: true,
                                }))
                            : undefined
                        }
                      />
                    </Box>
                  );
                return <NothingFound $top={0} $bottom={0} />;
              },
              loadingOverlay: () => null,
            }}
          />
        </Box>
      </PageContent>

      <AddDropdownOptionDialog
        open={state.isAddDropdownOptionDialogOpen}
        transactionCustomFieldId={fieldId}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isAddDropdownOptionDialogOpen: false,
          }))
        }
        onSuccess={() => {
          if (!mounted.current) return;
          setState((prevState) => ({
            ...prevState,
            isAddDropdownOptionDialogOpen: false,
          }));
          if (dataGridRef.current && !state.isLoading)
            dataGridRef.current.scroll({ left: 0, top: 0 });
          pageRef.current = 0;
          getData(false);
        }}
      />

      <EditDropdownOptionDialog
        open={state.isEditDropdownOptionDialogOpen}
        customFieldOption={state.editableCustomFieldOption!}
        isCodatConnected={isCodatConnected}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isEditDropdownOptionDialogOpen: false,
          }))
        }
        onSuccess={() => {
          if (!mounted.current) return;
          if (dataGridRef.current && !state.isLoading)
            dataGridRef.current.scroll({ left: 0, top: 0 });
          pageRef.current = 0;
          getData(false);
          setState((prevState) => ({
            ...prevState,
            isEditDropdownOptionDialogOpen: false,
          }));
        }}
      />

      <EditCustomFieldDialog
        open={state.isCustomFieldEditDialogOpen}
        customField={customField}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isCustomFieldEditDialogOpen: false,
          }))
        }
        onSuccess={(updatedField) => {
          dispatch({
            type: 'SET_CUSTOM_FIELDS',
            payload: {
              transactionCustomFields: updatedField
                ? transactionCustomFields.map((field) =>
                    field.id === updatedField.id ? updatedField : field
                  )
                : transactionCustomFields.filter(
                    (field) => field.id !== customField.id
                  ),
            },
          });
          if (!mounted.current) return;
          if (updatedField) {
            setState((prevState) => ({
              ...prevState,
              isCustomFieldEditDialogOpen: false,
            }));
          } else
            history.push(
              generatePath(getPath('settingsAccounting'), {
                orgId: organization!.id,
              })
            );
        }}
      />

      <CodatSyncDropdownOptionsDialog
        open={state.codatSyncDialogOpen}
        onSuccess={() => {
          pageRef.current = 0;
          getData(false, true);
          setState((prevState) => ({
            ...prevState,
            codatSyncDialogOpen: false,
          }));
        }}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            codatSyncDialogOpen: false,
          }));
        }}
      />
    </>
  );
};

export default withPageConfig(CustomFieldDropdownOptionsPage, {
  permission: 'custom-field-options-page:visit',
  featureModule: FeatureModuleKey.transactionCustomFields,
});
