import { useTranslation } from 'react-i18next';
import DocumentAndQuestionRequestsModule from 'domains/creditAndCompliance/components/DocumentAndQuestionRequestsModule';
import { Box, Grid, Typography } from 'elements';
import { OnboardingDocumentCategory } from 'services/constants';
import Psd2BankConnection from './Psd2BankConnection';

const InformationRequestSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        justifyContent="space-between"
        py={1}
      >
        <Typography variant="h6">
          {t('int.informationRequestSection.title')}
        </Typography>
      </Box>

      <Grid container py={3} spacing={3}>
        <Grid item xs={12}>
          <Psd2BankConnection />
        </Grid>

        <Grid item xs={12}>
          <DocumentAndQuestionRequestsModule
            category={OnboardingDocumentCategory.RISK}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InformationRequestSection;
