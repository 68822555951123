import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MerchantDetails } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import LogoCropStep, { LogoCropStepRef } from './LogoCropStep';
import LogoDropStep from './LogoDropStep';

interface Props extends DialogProps {
  merchantId: string;
  onClose: () => void;
  onUpdate: (merchant: MerchantDetails) => void;
}

const MerchantLogoUploadDialog = ({
  merchantId,
  onUpdate,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const logoCropStepRef = useRef<LogoCropStepRef>(null);

  const uploadImage = async () => {
    try {
      setIsLoading(true);
      const imageBlob = await logoCropStepRef.current?.getImgBlob();
      if (!imageBlob) {
        enqueueSnackbar(t('merchantLogoEdit.imageProcessingError'), {
          variant: 'error',
        });
        props.onClose();
        return;
      }
      const formData = new FormData();
      formData.append('file', imageBlob, file?.name);
      const response = await api.uploadMerchantLogo(merchantId, formData);
      if (!mounted.current) return;
      onUpdate(response);
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {file
          ? t('merchantLogoEdit.adjustLogoLabel')
          : t('merchantLogoEdit.dropImageModalLabel')}
      </DialogTitle>
      <DialogContent>
        {!file ? (
          <LogoDropStep onFileAccept={(file) => setFile(file)} />
        ) : (
          <LogoCropStep
            ref={logoCropStepRef}
            file={file}
            onClose={props.onClose}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        {!!file && (
          <Button disabled={isLoading} onClick={uploadImage}>
            {t('merchantLogoEdit.upload')}
          </Button>
        )}
      </DialogActions>
      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(MerchantLogoUploadDialog);
