import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Box, EditButton, Typography } from 'elements';
import { datevExportFormatsToOption } from 'services/utils';
import ChangeDatevExportFormatDialog from './ChangeDatevExportFormatDialog';

const DatevExportFormatsGroup = () => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const [isExportTypeDialogOpen, setIsExportTypeDialogOpen] = useState(false);

  const renderSelectedExportFormats = () => {
    const value = datevExportFormatsToOption(accountingSettings!.exportFormats);
    return t(`datevExportTypes.${value}`);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">
          {t('datevExportSettingsGroup.exportTypesLabel')}
        </Typography>

        <Box display="flex" alignItems="center">
          <Typography variant="body2">
            {renderSelectedExportFormats()}
          </Typography>
          <EditButton
            onClick={() => {
              setIsExportTypeDialogOpen(true);
            }}
          />
        </Box>
      </Box>

      <ChangeDatevExportFormatDialog
        datevExportFormats={accountingSettings!.exportFormats}
        open={isExportTypeDialogOpen}
        onClose={() => setIsExportTypeDialogOpen(false)}
      />
    </>
  );
};

export default DatevExportFormatsGroup;
