import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from 'elements';
import { Member, MemberDetails } from 'services/constants';

interface Props extends Omit<SelectProps, 'renderValue' | 'labelId'> {
  selectedMember: Member | MemberDetails;
  label: string;
  helperText?: string;
}

const TeamSelect = ({
  selectedMember,
  label,
  error,
  helperText,
  disabled,
  ...props
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth error={error} disabled={disabled}>
      <InputLabel id="card-team-select-label">{label}</InputLabel>
      <Select
        {...props}
        renderValue={(selected) => {
          const team = selectedMember.teams.find(
            (item) => item.teamId === (selected as string)
          );
          return team?.name;
        }}
        labelId="card-team-select-label"
      >
        <MenuItem value="">
          {t('issueCardDialog_v2.attributesStep.noneTeam')}
        </MenuItem>
        {selectedMember.teams
          ? selectedMember.teams.map((item) => (
              <MenuItem key={item.teamId} value={item.teamId}>
                {item.name}
              </MenuItem>
            ))
          : null}
      </Select>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default TeamSelect;
