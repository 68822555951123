import { intersection, pick } from 'lodash';
import moment from 'moment';
import {
  AccountEntry,
  AccountingSettings,
  AccountingTransaction,
  ExportAccountEntry,
  ExportFlowTransaction,
  FeatureModuleValueByKeyMap,
  GetExportAccountEntriesParams,
  NONE_VALUE,
  privateExpenseStatuses,
  ProjectStatus,
  reviewFlagReasons,
  SubcategoryStatus,
  Transaction,
  TransactionExportStatus,
  transactionExportStatuses,
  transactionReceiptStatuses,
  TransactionReviewStatus,
  transactionReviewStatuses,
  TransactionSimpleType,
  VatRateStatus,
} from 'services/constants';
import {
  getValidQueryParamValue,
  getValidQueryParamValues,
} from 'services/utils';

export const MISSING_SUPPLIER_OPTION = 'MISSING';
export const MISSING_TEAM_OPTION = 'MISSING';

// creates an object composed of values that are included in T and U
const getObjWithSameKeys = <T extends object, U extends object>(
  obj1: T,
  obj2: U
): Partial<T & U> => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  const sameKeys = intersection(keys1, keys2);
  return pick(obj1, sameKeys) as Partial<T & U>;
};

export const getSubcategoryFields = (
  accountingTransactions: AccountingTransaction[]
) => {
  const ids = accountingTransactions.map((item) => item.subcategoryId);
  if (ids.some((id) => !id)) {
    return {
      subcategoryId: null,
      subcategoryStatus: SubcategoryStatus.missing,
    };
  }
  if (ids.some((id) => id !== ids[0])) {
    return {
      subcategoryId: null,
      subcategoryStatus: SubcategoryStatus.multiple,
    };
  }
  return {
    subcategoryId: ids[0]!,
    subcategoryStatus: SubcategoryStatus.single,
  };
};

export const getVatRateFields = (
  accountingTransactions: AccountingTransaction[]
) => {
  const ids = accountingTransactions.map((item) => item.vatRateId);
  if (ids.some((id) => !id)) {
    return {
      vatRateId: null,
      vatRateStatus: VatRateStatus.missing,
    };
  }
  if (ids.some((id) => id !== ids[0])) {
    return {
      vatRateId: null,
      vatRateStatus: VatRateStatus.multiple,
    };
  }
  return {
    vatRateId: ids[0]!,
    vatRateStatus: VatRateStatus.single,
  };
};

export const getProjectFields = (
  accountingTransactions: AccountingTransaction[]
) => {
  const ids = accountingTransactions.map((item) => item.projectId);
  if (ids.some((id) => !id)) {
    return { projectId: null, projectStatus: ProjectStatus.missing };
  }
  if (ids.some((id) => id !== ids[0])) {
    return { projectId: null, projectStatus: ProjectStatus.multiple };
  }
  return { projectId: ids[0]!, projectStatus: ProjectStatus.single };
};

export const mergeTransactionDetailsFieldsToExportFlowTransaction = (
  exportFlowTransaction: ExportFlowTransaction,
  transaction: Transaction,
  accountingTransactions: AccountingTransaction[]
): ExportFlowTransaction => ({
  ...exportFlowTransaction,
  ...getObjWithSameKeys(transaction, exportFlowTransaction),
  ...getSubcategoryFields(accountingTransactions),
  ...getVatRateFields(accountingTransactions),
  ...getProjectFields(accountingTransactions),
});

export const mergeAccountEntryFieldsToExportAccountEntry = (
  exportAccountEntry: ExportAccountEntry,
  accountEntry: AccountEntry
): ExportAccountEntry => {
  const sameFields = getObjWithSameKeys(accountEntry, exportAccountEntry);

  return {
    ...exportAccountEntry,
    ...sameFields,
    billPaymentInfo: exportAccountEntry.billPaymentInfo
      ? {
          ...exportAccountEntry.billPaymentInfo,
          ...getObjWithSameKeys(
            accountEntry.billPaymentInfo || {},
            exportAccountEntry.billPaymentInfo
          ),
        }
      : null,
    reimbursementInfo: exportAccountEntry.reimbursementInfo
      ? {
          ...exportAccountEntry.reimbursementInfo,
          ...getObjWithSameKeys(
            accountEntry.reimbursementInfo || {},
            exportAccountEntry.reimbursementInfo
          ),
        }
      : null,
    transactionInfo: exportAccountEntry.transactionInfo
      ? {
          ...exportAccountEntry.transactionInfo,
          ...getObjWithSameKeys(
            accountEntry.transactionInfo || {},
            exportAccountEntry.transactionInfo
          ),
        }
      : null,
  };
};

export const getSubcategoryFilterApiParams = (
  value: string
): Partial<GetExportAccountEntriesParams> => {
  const subcategoryIds =
    !!value && value !== SubcategoryStatus.missing ? value : undefined;
  const subcategoryStatuses =
    value === SubcategoryStatus.missing ? SubcategoryStatus.missing : undefined;
  return { subcategoryIds, subcategoryStatuses };
};

export const getVatRateFilterApiParams = (
  value: string
): Partial<GetExportAccountEntriesParams> => {
  const vatRateIds =
    !!value && value !== VatRateStatus.missing ? value : undefined;
  const vatRateStatuses =
    value === VatRateStatus.missing ? VatRateStatus.missing : undefined;
  return { vatRateIds, vatRateStatuses };
};

export const getProjectFilterApiParams = (
  value: string
): Partial<GetExportAccountEntriesParams> => {
  const projectIds =
    !!value && value !== ProjectStatus.missing ? value : undefined;
  const projectStatuses =
    value === ProjectStatus.missing ? ProjectStatus.missing : undefined;
  return { projectIds, projectStatuses };
};

export const visibleTransactionTypes = [
  TransactionSimpleType.purchase,
  TransactionSimpleType.refund,
  TransactionSimpleType.chargeback,
  TransactionSimpleType.recharge,
];

export const getValidExportStatusParam = (exportStatus: string | null) => {
  return transactionExportStatuses.includes(exportStatus as any)
    ? (exportStatus as TransactionExportStatus)
    : TransactionExportStatus.notExported;
};

export const getQueryParams = (
  qs: string,
  accountingSettings: AccountingSettings | null,
  isSupplierEnabled: boolean,
  isProjectEnabled: boolean,
  isSubcategoryEnabled: boolean,
  cardAccountIds: string[],
  featureModules: FeatureModuleValueByKeyMap
) => {
  const {
    q,
    exportStatus,
    type,
    fromDate,
    toDate,
    receipt,
    supplier,
    subcategory,
    vatRate,
    project,
    team,
    reviewStatus,
    flagReason,
    privateExpenseStatus,
    cardAccountId,
  } = Object.fromEntries(new URLSearchParams(qs).entries());

  const fromDateMoment = moment(fromDate, moment.ISO_8601);
  const toDateMoment = moment(toDate, moment.ISO_8601);

  const visibleReviewStatuses: (
    | TransactionReviewStatus
    | typeof NONE_VALUE
  )[] = featureModules.MANAGER_TX_REVIEWS
    ? [...transactionReviewStatuses, NONE_VALUE]
    : [
        TransactionReviewStatus.flagged,
        TransactionReviewStatus.resolved,
        NONE_VALUE,
      ];

  return {
    q: q ? q.trim() : '',
    exportStatus: getValidExportStatusParam(exportStatus),
    type: getValidQueryParamValues(type, visibleTransactionTypes),
    receipt: getValidQueryParamValue(receipt, transactionReceiptStatuses),
    supplier: supplier && isSupplierEnabled ? supplier : '',
    subcategory: subcategory && isSubcategoryEnabled ? subcategory : '',
    vatRate: vatRate && accountingSettings?.vatRateEnabled ? vatRate : '',
    project: project && isProjectEnabled ? project : '',
    team: featureModules.TEAMS ? team : '',
    fromDate: fromDateMoment.isValid() ? fromDateMoment : null,
    toDate:
      fromDateMoment.isValid() && toDateMoment.isValid() ? toDateMoment : null,
    reviewStatus: getValidQueryParamValue(reviewStatus, visibleReviewStatuses),
    flagReason: featureModules.PRIVATE_EXPENSE
      ? getValidQueryParamValue(flagReason, reviewFlagReasons)
      : '',
    privateExpenseStatus: featureModules.PRIVATE_EXPENSE
      ? getValidQueryParamValues(privateExpenseStatus, privateExpenseStatuses)
      : [],
    cardAccountId: getValidQueryParamValue(cardAccountId, cardAccountIds),
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

export const getSelectedFiltersCount = ({
  type,
  receipt,
  supplier,
  subcategory,
  vatRate,
  project,
  team,
  fromDate,
  reviewStatus,
  flagReason,
  privateExpenseStatus,
  cardAccountId,
}: QueryParams) =>
  +!!supplier +
  +!!subcategory +
  +!!vatRate +
  +!!project +
  +!!team +
  +!!type.length +
  +!!receipt.length +
  +!!fromDate +
  +!!reviewStatus +
  +!!flagReason +
  +!!privateExpenseStatus.length +
  +!!cardAccountId;
