import { useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { SetCashbackThresholdDialog } from 'domains/terms/dialogs';
import { Box, InfoIcon, LabeledValue, Tooltip, Typography } from 'elements';
import { Cashback } from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  cashback: Cashback;
  onUpdate: (cashback: Cashback) => void;
}

const CashbackThresholdSection = ({ cashback, onUpdate }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Typography variant="h6" mb={1}>
        {t('int.cashbackSettingsPage.cashbackThresholdSection.title')}
      </Typography>
      <Typography>
        <Trans
          i18nKey="int.cashbackSettingsPage.cashbackThresholdSection.description"
          components={{
            threshold: (
              <FormatMoney value={cashback.cashbackMonthlyThreshold} />
            ),
          }}
        />
      </Typography>
      <LabeledValue
        label={t('int.cashbackSettingsPage.cashbackThresholdSection.label')}
        value={<FormatMoney value={cashback.cashbackMonthlyThreshold} />}
        onEdit={
          canUser('cashback-settings:update')
            ? () => setIsDialogOpen(true)
            : undefined
        }
      />

      {cashback.upcomingCashbackMonthlyThreshold && (
        <Box display="flex" alignItems="center" mt={1}>
          <Typography variant="caption">
            <Trans
              i18nKey="int.cashbackSettingsPage.cashbackThresholdSection.upcoming"
              components={{
                threshold: (
                  <FormatMoney
                    value={cashback.upcomingCashbackMonthlyThreshold}
                  />
                ),
              }}
            />
          </Typography>
          <Tooltip
            title={
              t(
                'int.cashbackSettingsPage.cashbackThresholdSection.upcomingTooltip',
                {
                  date: moment()
                    .add(1, 'M')
                    .startOf('month')
                    .format('D MMM YYYY'),
                }
              )!
            }
          >
            <InfoIcon
              sx={{
                width: 16,
                height: 16,
                ml: 0.5,
                color: 'text.secondary',
              }}
            />
          </Tooltip>
        </Box>
      )}

      {cashback.cashbackMonthlyThresholdAudit && (
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          mt={1}
        >
          {t('int.common.lastEditedBy', {
            name: cashback.cashbackMonthlyThresholdAudit.email,
            time: moment(
              cashback.cashbackMonthlyThresholdAudit.updatedAt
            ).format('DD MMM YYYY HH:mm'),
          })}
        </Typography>
      )}

      <SetCashbackThresholdDialog
        open={isDialogOpen}
        cashback={cashback}
        onSuccess={(cashback) => {
          onUpdate(cashback);
          setIsDialogOpen(false);
        }}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default CashbackThresholdSection;
