import * as React from "react";
const SvgCo2MoveLogoDarkMode = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 432, height: 49, fill: "none", viewBox: "0 0 432 49", ...props }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#a)" }, /* @__PURE__ */ React.createElement("mask", { id: "b", width: 54, height: 49, x: 118, y: 0, maskUnits: "userSpaceOnUse", style: {
  maskType: "luminance"
} }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M118.666 0h53.333v49h-53.333V0Z" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#b)" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M145.26 48.911h-21.073c-2.037 0-3.682-1.182-4.25-3.083a4.491 4.491 0 0 1-.182-1.26c-.016-3.396-.058-6.782.015-10.183.073-3.411 1.318-6.396 3.563-8.937 1.916-2.172 4.271-3.657 7.067-4.407 1.276-.343 2.579-.479 3.896-.479h21.938c2.099 0 3.786-.838 4.942-2.588 1.214-1.86 1.303-3.834.256-5.781-1.063-1.99-2.802-2.98-5.063-3.058-.302-.01-.599 0-.901 0h-28.224c-1.052 0-2.026-.208-2.875-.849-1.437-1.078-2.052-2.968-1.515-4.718.526-1.74 2.114-2.98 3.895-3.058.162 0 .328-.015.48-.015h28.901c2.932 0 5.661.718 8.099 2.37 3.401 2.307 5.572 5.463 6.276 9.536.734 4.286-.287 8.161-3.026 11.515-2.318 2.834-5.313 4.553-8.954 5.11-.838.135-1.724.146-2.578.146-7.213.015-14.416 0-21.635.015-2.235 0-3.969.933-5.151 2.834a5.22 5.22 0 0 0-.797 2.692c-.016 1.756 0 3.49-.016 5.245 0 .266.094.375.349.344.104-.016.224 0 .328 0h37.167c1.318 0 2.469.36 3.37 1.349 1.255 1.375 1.51 2.979.807 4.656-.719 1.719-2.094 2.604-3.969 2.604-7.036.016-14.088 0-21.14 0Z" })), /* @__PURE__ */ React.createElement("mask", { id: "c", width: 58, height: 49, x: 56, y: 0, maskUnits: "userSpaceOnUse", style: {
  maskType: "luminance"
} }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M56 0h57.333v49H56V0Z" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#c)" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M85.12.495c2.213-.026 4.37.015 6.51.317 5.5.76 10.453 2.724 14.526 6.605 2.516 2.395 4.328 5.27 5.526 8.52 1.484 4.01 1.859 8.146 1.302 12.37-.609 4.672-2.396 8.802-5.552 12.308-2.427 2.697-5.365 4.64-8.703 6.02a28.726 28.726 0 0 1-7.396 1.933c-3.818.463-7.651.432-11.474.104-3.906-.344-7.62-1.406-11.062-3.307-5.256-2.907-8.88-7.193-10.677-12.928-1.021-3.234-1.292-6.572-1.037-9.942.224-3.068.958-6.006 2.323-8.76 1.901-3.803 4.688-6.798 8.281-9.042 3.026-1.891 6.318-3.058 9.808-3.672C80.04.557 82.603.453 85.118.495Zm.312 8.536c-1.901.104-3.49.136-5.063.271-2.484.224-4.848.885-7.067 2.052-3.339 1.766-5.677 4.417-6.844 8.042-.99 3.057-1.062 6.156-.375 9.27.813 3.61 2.74 6.5 5.828 8.548 2.89 1.916 6.11 2.786 9.521 2.953 2.458.12 4.912.088 7.37 0 2.229-.073 4.4-.553 6.484-1.38 4-1.6 6.917-4.297 8.386-8.402 1.151-3.25 1.244-6.573.448-9.911-.704-2.964-2.23-5.453-4.594-7.396C96.979 10.979 94 9.844 90.78 9.422c-1.875-.24-3.776-.287-5.349-.39Z" })), /* @__PURE__ */ React.createElement("mask", { id: "d", width: 52, height: 49, x: 0, y: 0, maskUnits: "userSpaceOnUse", style: {
  maskType: "luminance"
} }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M0 0h52v49H0V0Z" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#d)" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M28.453.495c2.214-.026 4.37.015 6.516.317 5.51.76 10.453 2.756 14.552 6.636 2.25 2.125 1.693 5.87-1.078 7.083-1.693.745-3.354.568-4.729-.719-2.594-2.4-5.646-3.729-9.094-4.317-3.114-.537-6.255-.417-9.4-.313-2.532.089-4.975.584-7.308 1.589-4.646 1.99-7.61 5.437-8.61 10.422-.765 3.849-.421 7.609 1.438 11.125 1.599 3.041 4.13 5.078 7.26 6.38 2.5 1.036 5.12 1.5 7.802 1.557 2.516.063 5.047.136 7.547-.161 3.459-.422 6.667-1.469 9.407-3.703.343-.266.656-.584.984-.855 1.453-1.166 3.401-1.328 4.927-.416 1.589.958 2.411 2.755 2.052 4.583-.177.958-.687 1.75-1.406 2.411-2.651 2.47-5.719 4.193-9.151 5.297-3.01.974-6.11 1.443-9.266 1.47-2.25.03-4.495.046-6.74-.09-5.124-.327-9.9-1.765-14.166-4.718-4.344-3.01-7.266-7.068-8.719-12.14-.896-3.12-1.135-6.292-.88-9.527.27-3.354 1.135-6.515 2.755-9.479 1.948-3.474 4.61-6.198 7.953-8.266 2.995-1.875 6.271-3.026 9.73-3.656 2.546-.448 5.109-.552 7.624-.51Z" })), /* @__PURE__ */ React.createElement("mask", { id: "e", width: 67, height: 49, x: 180, y: 0, maskUnits: "userSpaceOnUse", style: {
  maskType: "luminance"
} }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M180 0h66.667v49H180V0Z" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#e)" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "m237.817 14.995-.63.583a24342.24 24342.24 0 0 0-20.515 19.719c-.761.75-1.615 1.26-2.693 1.396-1.453.177-2.714-.24-3.761-1.26-3.385-3.25-6.765-6.5-10.135-9.745-3.609-3.464-7.219-6.922-10.812-10.396-.105-.104-.214-.193-.329-.313-.135.151-.078.313-.078.448v28.901c0 1.708-.672 3.057-2.156 3.938-2.812 1.677-6.365-.282-6.453-3.547V5.052c0-1.349.391-2.505 1.375-3.432 1.604-1.495 4.151-1.542 5.75-.031 3.536 3.354 7.042 6.74 10.557 10.109 5.047 4.838 10.094 9.677 15.141 14.526.239.24.375.224.614 0 8.417-8.089 16.881-16.188 25.308-24.302.974-.948 2.083-1.484 3.448-1.412 2.125.094 3.89 1.844 4.01 3.97.016.197.016.405.016.598v39.266c0 1.333-.375 2.515-1.375 3.427-1.261 1.14-2.73 1.443-4.318.885-1.557-.536-2.5-1.661-2.844-3.281-.073-.37-.073-.745-.073-1.12V15.5c0-.146.042-.297-.047-.505Z" })), /* @__PURE__ */ React.createElement("mask", { id: "f", width: 58, height: 49, x: 253, y: 0, maskUnits: "userSpaceOnUse", style: {
  maskType: "luminance"
} }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M253.334 0h57.333v49h-57.333V0Z" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#f)" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M282.084 48.88c-1.854.031-3.714.016-5.568-.208-6.036-.72-11.474-2.802-15.891-7.115-2.921-2.844-4.838-6.302-5.838-10.255-.839-3.297-1.005-6.651-.583-10.021.401-3.323 1.421-6.437 3.156-9.297 2.13-3.49 5.047-6.14 8.625-8.088 2.666-1.438 5.5-2.365 8.479-2.891 2.052-.36 4.13-.52 6.214-.52 1.989 0 4-.063 5.989.103 5.828.48 11.172 2.214 15.667 6.094 2.916 2.516 4.969 5.646 6.317 9.255 1.495 4.01 1.855 8.162 1.303 12.396-.464 3.6-1.646 6.922-3.683 9.917-2.635 3.89-6.229 6.604-10.557 8.385-2.37.974-4.839 1.589-7.385 1.933-2.079.296-4.162.343-6.245.312Zm-.058-8.61c1.917.016 3.771-.015 5.631-.286 2.547-.37 4.958-1.104 7.14-2.469 2.849-1.75 4.839-4.177 5.901-7.37 1.037-3.098 1.141-6.255.422-9.432-.797-3.547-2.713-6.396-5.724-8.448-2.812-1.932-6.005-2.812-9.375-3.052-2.901-.198-5.807-.224-8.698.073-2.468.255-4.854.886-7.052 2.052-4 2.125-6.453 5.438-7.338 9.87-.495 2.453-.448 4.912.088 7.354.558 2.531 1.662 4.76 3.459 6.63 2.713 2.834 6.125 4.183 9.916 4.766 1.87.313 3.771.344 5.63.313Z" })), /* @__PURE__ */ React.createElement("mask", { id: "g", width: 61, height: 49, x: 314, y: 0, maskUnits: "userSpaceOnUse", style: {
  maskType: "luminance"
} }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M314.666 0h60v49h-60V0Z" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#g)" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M344.755 48.912c-6.573-.328-11.516-3.308-14.334-9.328-4.385-9.375-8.609-18.823-12.906-28.245-.719-1.573-1.453-3.146-2.156-4.719-1.031-2.318.016-4.927 2.338-5.854 1.943-.776 4.459.12 5.36 1.995 1.453 3.067 2.844 6.166 4.25 9.25 3.656 7.984 7.297 15.979 10.963 23.963 1.183 2.573 3.219 4.042 6.068 4.266 1.974.15 3.682-.521 5.162-1.839 1.067-.948 1.635-2.187 2.203-3.432 4.854-10.615 9.687-21.234 14.557-31.839.807-1.78 2.245-2.661 4.193-2.62 1.843.048 3.083 1.037 3.786 2.683.495 1.151.422 2.307-.104 3.443-1.932 4.24-3.865 8.463-5.797 12.698-3.068 6.74-6.135 13.479-9.224 20.203-1.812 3.968-4.776 6.755-8.833 8.343-1.62.641-3.682 1.016-5.526 1.032Z" })), /* @__PURE__ */ React.createElement("mask", { id: "h", width: 52, height: 11, x: 380, y: 0, maskUnits: "userSpaceOnUse", style: {
  maskType: "luminance"
} }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M380 0h52v10.042h-52V0Z" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#h)" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M427.359 9.078h-42.427a4.298 4.298 0 0 1 0-8.594h42.427a4.298 4.298 0 0 1 4.297 4.297 4.288 4.288 0 0 1-4.297 4.297Z" })), /* @__PURE__ */ React.createElement("mask", { id: "i", width: 52, height: 10, x: 380, y: 39, maskUnits: "userSpaceOnUse", style: {
  maskType: "luminance"
} }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M380 39.375h52V49h-52v-9.625Z" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#i)" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M427.359 48.865h-42.427a4.298 4.298 0 0 1 0-8.594h42.427a4.298 4.298 0 0 1 4.297 4.297 4.288 4.288 0 0 1-4.297 4.297Z" })), /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M427.359 28.964h-42.427a4.298 4.298 0 0 1 0-8.594h42.427a4.298 4.298 0 0 1 4.297 4.297 4.288 4.288 0 0 1-4.297 4.297Z" })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "a" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M0 0h432v49H0z" }))));
export default SvgCo2MoveLogoDarkMode;
