import { useQueryClient } from '@tanstack/react-query';
import { toDecimal } from 'dinero.js';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountCurrency } from 'domains/card/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  PaymentFrequencyType,
  paymentFrequencyTypes,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getGenericErrorMsg,
} from 'services/utils';

export enum DialogMode {
  create = 'CREATE',
  edit = 'EDIT',
}

interface Props extends DialogProps {
  mode: DialogMode;
  onClose: () => void;
}

interface FormValues {
  expectedMonthlySpend: string;
  requestedCreditLimit: string;
  preferredPaymentFrequency: PaymentFrequencyType | null;
  additionalInfo: string;
}

const CreditLineApplicationDialog = ({ mode, ...props }: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { defaultCardAccount, organization },
  } = useGlobalState();
  const currency = useCardAccountCurrency(defaultCardAccount!.id);
  const {
    useGetCreditApplication,
    useCreateCreditApplication,
    useUpdateCreditApplication,
  } = useTanstackQuery();
  const { data: creditApplication } = useGetCreditApplication();
  const { mutate: createCreditApplication } = useCreateCreditApplication({
    onSuccess: (createdCreditApplication) => {
      if (!mounted.current) return;
      queryClient.setQueryData(
        ['getCreditApplication', organization!.id],
        createdCreditApplication
      );
      props.onClose();
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });
  const { mutate: updateCreditApplication } = useUpdateCreditApplication({
    onSuccess: (updatedCreditApplication) => {
      if (!mounted.current) return;
      queryClient.setQueryData(
        ['getCreditApplication', organization!.id],
        updatedCreditApplication
      );
      enqueueSnackbar(t('creditLineApplicationDialog.updatedMessage'), {
        variant: 'success',
      });
      props.onClose();
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues:
      mode === DialogMode.create
        ? {
            expectedMonthlySpend: '',
            requestedCreditLimit: '',
            preferredPaymentFrequency: null,
            additionalInfo: '',
          }
        : {
            expectedMonthlySpend: toDecimal(
              dineroFromMoney(creditApplication!.expectedMonthlySpend)
            ),
            requestedCreditLimit: toDecimal(
              dineroFromMoney(creditApplication!.requestedCreditLimit)
            ),
            preferredPaymentFrequency: creditApplication!
              .preferredPaymentFrequency,
            additionalInfo: creditApplication!.additionalInfo,
          },
    onSubmit: async (values) => {
      const payload = {
        expectedMonthlySpend: convertDineroToMoney(
          dineroFromFloat(values.expectedMonthlySpend, currency)
        ),
        requestedCreditLimit: convertDineroToMoney(
          dineroFromFloat(values.requestedCreditLimit, currency)
        ),
        preferredPaymentFrequency: values.preferredPaymentFrequency!,
        additionalInfo: values.additionalInfo,
      };

      if (mode === DialogMode.create) {
        createCreditApplication(payload);
      } else {
        updateCreditApplication(payload);
      }
    },
  });

  const isSubmitDisabled =
    !formik.values.expectedMonthlySpend ||
    !formik.values.requestedCreditLimit ||
    !formik.values.preferredPaymentFrequency ||
    !formik.values.additionalInfo ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('creditLineApplicationDialog.title')}</DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="credit-line-application"
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MoneyField
                {...omit(
                  formik.getFieldProps('expectedMonthlySpend'),
                  'onChange'
                )}
                onValueChange={({ value }) =>
                  formik.setFieldValue('expectedMonthlySpend', value)
                }
                isNumericString
                currency={currency.code}
                decimalScale={currency.exponent}
                label={t('creditLineApplicationDialog.expectedMonthlySpend')}
                error={!!formik.errors.expectedMonthlySpend}
                helperText={formik.errors.expectedMonthlySpend}
                disabled={formik.isSubmitting}
              />
            </Grid>
            <Grid item xs={6}>
              <MoneyField
                {...omit(
                  formik.getFieldProps('requestedCreditLimit'),
                  'onChange'
                )}
                onValueChange={({ value }) =>
                  formik.setFieldValue('requestedCreditLimit', value)
                }
                isNumericString
                currency={currency.code}
                decimalScale={currency.exponent}
                label={t('creditLineApplicationDialog.requestedCreditLimit')}
                error={!!formik.errors.requestedCreditLimit}
                helperText={formik.errors.requestedCreditLimit}
                disabled={formik.isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth disabled={formik.isSubmitting}>
                <InputLabel id="preferred-payment-frequency-select-label">
                  {t('creditLineApplicationDialog.preferredPaymentFrequency')}
                </InputLabel>
                <Select<PaymentFrequencyType>
                  {...formik.getFieldProps('preferredPaymentFrequency')}
                  value={formik.values.preferredPaymentFrequency ?? ''}
                  renderValue={(selected) =>
                    t(`paymentFrequencyTypes.${selected}`)
                  }
                  labelId="preferred-payment-frequency-select-label"
                >
                  {paymentFrequencyTypes.map((frequency) => (
                    <MenuItem key={frequency} value={frequency}>
                      {t(`paymentFrequencyTypes.${frequency}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('additionalInfo')}
                label={t('creditLineApplicationDialog.additionalInfo')}
                error={!!formik.errors.additionalInfo}
                helperText={formik.errors.additionalInfo}
                multiline
                disabled={formik.isSubmitting}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          form="credit-line-application"
          type="submit"
          disabled={isSubmitDisabled}
        >
          {t(
            mode === DialogMode.create
              ? 'common.button.apply'
              : 'common.button.save'
          )}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(CreditLineApplicationDialog);
