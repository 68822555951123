import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { cardholderPaths } from 'components/App';
import { CardSummary } from 'domains/card/components';
import { Box, Grid, Paper, StatusBadge } from 'elements';
import { Card, CardSimpleStatus } from 'services/constants';
import { getPath } from 'services/utils';

interface Props {
  cards: Card[];
  orgId: string;
  teamId?: string;
}

const MemberCards = ({ cards, orgId, teamId }: Props) => {
  const { t } = useTranslation();

  return (
    <Box my={1}>
      <Grid container spacing={1.5}>
        {cards.map((card) => {
          const cardPath = teamId
            ? generatePath(cardholderPaths.teamCardDetails, {
                teamId,
                cardId: card.cardId,
              })
            : generatePath(getPath('cardDetails'), {
                orgId,
                cardId: card.cardId,
              });

          return (
            <Grid item xs={4} key={card.cardId}>
              <Paper
                component={RouterLink}
                to={cardPath}
                variant="outlined"
                sx={{
                  position: 'relative',
                  display: 'block',
                  p: 2,
                  textDecoration: 'none',
                }}
              >
                {card.limitChangeRequestId && (
                  <StatusBadge
                    label={t('memberCards.limitRequest')}
                    color="warning"
                    sx={{ position: 'absolute', top: '-8px', right: '-8px' }}
                  />
                )}
                {card.simpleStatus === CardSimpleStatus.requested && (
                  <StatusBadge
                    label={t('memberCards.cardRequest')}
                    color="warning"
                    sx={{ position: 'absolute', top: '-8px', right: '-8px' }}
                  />
                )}
                <CardSummary card={card} />
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MemberCards;
