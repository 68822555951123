import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MerchantCategoryIcon } from 'domains/merchant/components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  CaretDownIcon,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  MerchantCategory,
  Subcategory,
  SubcategoryStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { SubcategoryData } from './useFilters';

const MissingInputValue = '—';

type Value = SubcategoryData['value'];

interface Props {
  value: Value;
  subcategoryData: SubcategoryData;
  onChange: (value: Value) => void;
}

interface State {
  isLoading: boolean;
  subcategories: (Subcategory | typeof SubcategoryStatus.missing)[];
}

const SubcategoryFilter = ({ value, subcategoryData, onChange }: Props) => {
  const isFetchAllowed = useRef(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    subcategories: [],
  });
  const [inputValue, setInputValue] = useState('');

  const getData = async () => {
    try {
      const { subcategories } = await api.getSubcategories({
        organizationId: organization!.id,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
        q: inputValue === MissingInputValue ? '' : inputValue.trim(),
        sortBy: '+category,+name',
        status: AccountingItemStatus.active,
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        subcategories: [SubcategoryStatus.missing, ...subcategories],
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (!isFetchAllowed.current || !accountingSettings?.subcategoryEnabled)
      return;

    let active = true;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);
    return () => {
      active = false;
    };
  }, [inputValue.trim()]);

  if (!accountingSettings?.subcategoryEnabled) return null;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-subcategory"
        id="panel-subcategory-header"
      >
        <Typography>{t('filters.subcategory')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Autocomplete<
          Subcategory | typeof SubcategoryStatus.missing,
          false,
          false,
          false
        >
          onOpen={() => {
            if (!isFetchAllowed.current) {
              setState((prevState) => ({ ...prevState, isLoading: true }));
              getData();
              isFetchAllowed.current = true;
            }
          }}
          groupBy={(option) => {
            if (typeof option === 'string') return '';
            return option.category;
          }}
          renderGroup={(params) => {
            if (!params.group) return params.children;
            return (
              <Fragment key={params.group}>
                <Typography
                  ml={2}
                  my={2}
                  component="div"
                  display="flex"
                  alignItems="center"
                  color="textSecondary"
                >
                  <MerchantCategoryIcon
                    fontSize="small"
                    category={params.group as MerchantCategory}
                  />
                  <Typography noWrap ml={1} variant="subtitle2">
                    {t(`merchantCategories.${params.group}`)}
                  </Typography>
                </Typography>
                {params.children}
              </Fragment>
            );
          }}
          inputValue={inputValue}
          loading={state.isLoading}
          size="medium"
          onInputChange={(e, inputValue) => {
            setInputValue(inputValue);
          }}
          error={subcategoryData.isError}
          value={value}
          onChange={(e, value) => {
            onChange(value);
          }}
          options={state.subcategories}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => {
            if (
              option === SubcategoryStatus.missing ||
              value === SubcategoryStatus.missing
            )
              return value === option;
            return option?.id === value?.id;
          }}
          getOptionLabel={(option) => {
            if (!option) return '';
            if (option === SubcategoryStatus.missing) return MissingInputValue;
            return option.name;
          }}
          renderOption={(props, option) => {
            if (!option) return '';
            if (option === SubcategoryStatus.missing)
              return (
                <li {...props} key={option}>
                  —
                </li>
              );
            return (
              <li {...props} key={option.id}>
                <Box
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  ml={3.5}
                >
                  {option.name}
                </Box>
              </li>
            );
          }}
          placeholder={
            subcategoryData.isError
              ? t('filters.notFound')
              : t('filters.subcategory')
          }
          loadingText={t('common.loading')}
          noOptionsText={t('common.nothingFound')}
          searchIcon={false}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default SubcategoryFilter;
