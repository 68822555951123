import {
  TransactionsPageFilterChips,
  TransactionsPageFilters,
} from 'domains/transaction/components';
import { QueryParams as ChTeamsTransactionsPageQueryParams } from 'domains/transaction/pages/CardholderTeamTransactionsPage';
import { QueryParams as TransactionsPageQueryParams } from 'domains/transaction/pages/TransactionsPage';
import { Box } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import useFilters from './useFilters';

interface Props {
  params: TransactionsPageQueryParams | ChTeamsTransactionsPageQueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  disabled: boolean;
  transactionsCount: number;
  missingReceiptsCount: number;
  renderMenu: () => React.ReactNode;
}

const Filters = ({
  params,
  selectedFiltersCount,
  setParam,
  disabled,
  transactionsCount,
  missingReceiptsCount,
  renderMenu,
}: Props) => {
  const {
    projectData,
    teamData,
    areFiltersLoading,
    setDataFilters,
  } = useFilters({
    projectIds: params.projectIds,
    teamId: params.teamId,
  });

  return (
    <>
      <Box mt={2} display="flex" alignItems="center">
        <TransactionsPageFilters
          params={params}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setParam}
          disabled={disabled || areFiltersLoading}
          teamData={teamData}
          projectData={projectData}
          setFilters={setDataFilters}
          missingReceiptsCount={missingReceiptsCount}
          transactionsCount={transactionsCount}
        />
        {renderMenu()}
      </Box>

      <TransactionsPageFilterChips
        params={params}
        selectedFiltersCount={selectedFiltersCount}
        setParam={setParam}
        teamData={teamData}
        projectData={projectData}
      />
    </>
  );
};

export default Filters;
