import { ComponentType, FC } from 'react';
import { Redirect } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import withPageErrorWrapper from 'hoc/withPageErrorWrapper';
import {
  FeatureModuleKey,
  FeatureModuleValueByKeyMap,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { Flags } from 'services/featureflags/flags';
import { useCanUser } from 'services/rbac';
import { PermissionKey } from 'services/rbac/permissions';

const isFeatureModuleEnabled = (
  stateFeatureModules: FeatureModuleValueByKeyMap,
  featureModule:
    | FeatureModuleKey
    | FeatureModuleKey[]
    | ((featureModules: FeatureModuleValueByKeyMap) => boolean)
) => {
  if (typeof featureModule === 'string')
    return stateFeatureModules[featureModule];

  if (Array.isArray(featureModule))
    return featureModule.every((item) => stateFeatureModules[item]);

  return featureModule(stateFeatureModules);
};

interface Config {
  permission?: PermissionKey;
  featureFlag?: keyof Flags | (keyof Flags)[];
  featureModule?:
    | FeatureModuleKey
    | FeatureModuleKey[]
    | ((featureModules: FeatureModuleValueByKeyMap) => boolean);
  errorBoundary?: boolean;
}

const withPageConfig = <P extends object>(
  Component: ComponentType<P>,
  { permission, featureFlag, featureModule, errorBoundary = true }: Config
): FC<P> => {
  const PageComponent = errorBoundary
    ? withPageErrorWrapper(Component)
    : Component;

  return (props: P): JSX.Element => {
    const canUser = useCanUser();
    const allFlags = useFlags();
    const { state: globalState } = useGlobalState();

    if (permission && !canUser(permission)) return <Redirect to="/" />;

    if (featureFlag) {
      const isFeatureFlagEnabled =
        typeof featureFlag === 'string'
          ? allFlags[featureFlag]
          : featureFlag.every((item) => allFlags[item]);

      if (!isFeatureFlagEnabled) return <Redirect to="/" />;
    }

    if (
      featureModule &&
      !isFeatureModuleEnabled(globalState.featureModules, featureModule)
    )
      return <Redirect to="/" />;

    return <PageComponent {...props} />;
  };
};

export default withPageConfig;
