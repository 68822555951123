import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import styled from 'styled-components';
import ValidationCheckIcon from 'assets/icons/interface/validationCheck.svg?react';

export const StyledAccordion = styled(Accordion)`
  border-radius: 10px !important;
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
  box-shadow: none;

  &.Mui-expanded {
    border-color: ${({ theme }) => theme.colors.grey10};
  }

  &.MuiAccordion-root:before {
    position: initial;
  }

  &.MuiPaper-root.MuiAccordion-root {
    margin: 0 0 14px;
  }

  .MuiAccordionSummary-content {
    align-items: center;
  }

  .MuiAccordionSummary-expandIcon {
    color: initial;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)<{
  $isExpandable?: boolean;
}>`
  height: 80px;
  padding: 24px 26px;

  &.MuiAccordionSummary-root:hover:not(.Mui-disabled) {
    cursor: ${({ $isExpandable }) => ($isExpandable ? 'pointer' : 'default')};
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: block;
  padding: 4px 35px 42px;
`;

export const StyledValidationCheckIcon = styled(ValidationCheckIcon)`
  width: 16px;
  height: 16px;
  margin-right: 16px;
`;
