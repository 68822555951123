import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, LinkIcon, Paper, PlusIcon, Typography } from 'elements';
import { OrganizationMerchant } from 'services/constants';
import { useCanUser } from 'services/rbac';
import ChangeVatRateDialog from './ChangeVatRateDialog';

interface Props {
  value: OrganizationMerchant['vatRateResponse'];
  onChange: (value: OrganizationMerchant['vatRateResponse']) => void;
  merchantId: string;
}

const VatRatesSelect = ({ value, onChange, merchantId }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Paper
        variant="outlined"
        onClick={() => {
          if (!canUser('vat-rate:assign')) return;
          setIsOpen(true);
        }}
        sx={{
          height: 58,
          display: 'flex',
          alignItems: 'center',
          mb: 1,
          px: 1.5,
          cursor: canUser('vat-rate:assign') ? 'pointer' : undefined,
        }}
      >
        {value ? (
          <>
            <Box pr={1}>
              <Typography variant="body2" noWrap>
                {t('vatRateSelect.vatRate')}
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
              >
                {t('vatRateSelect.vatCode')}
              </Typography>
            </Box>
            <Box ml="auto" textAlign="right" overflow="hidden">
              <Typography variant="body2" noWrap>
                {value.name}
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
              >
                {value.code || '—'}
              </Typography>
            </Box>
            {canUser('vat-rate:assign') && (
              <LinkIcon sx={{ ml: 2, mr: 0.5, color: 'action.active' }} />
            )}
          </>
        ) : (
          <>
            <Typography variant="body2">
              {t('vatRateSelect.vatRate')}
            </Typography>
            <Typography variant="caption" color="textSecondary" ml="auto">
              —
            </Typography>
            {canUser('vat-rate:assign') && (
              <PlusIcon sx={{ ml: 2, mr: 0.5, color: 'action.active' }} />
            )}
          </>
        )}
      </Paper>
      <ChangeVatRateDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onSuccess={(value) => {
          onChange(value);
          setIsOpen(false);
        }}
        vatRateResponse={value}
        merchantId={merchantId}
      />
    </>
  );
};

export default VatRatesSelect;
