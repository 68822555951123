import { useMemo } from 'react';
import { toDecimal } from 'dinero.js';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountCurrency } from 'domains/card/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormControlLabelTooltipIcon,
  FormLabel,
  Grid,
  LoaderWithOverlay,
  MoneyField,
  Radio,
  RadioGroup,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Team } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getGenericErrorMsg,
} from 'services/utils';

interface FormValues {
  budget: string;
  hasBudget: boolean;
}

interface Props extends DialogProps {
  team: Team | null;
  isEditing?: boolean;
  onSuccess?: (team: Team) => void;
  onClose: () => void;
}

const CreateEditTeamBudgetDialog = ({
  team: teamNullable,
  isEditing = false,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { dispatch } = useGlobalState();
  const currency = useCardAccountCurrency();
  const team = useMemo(() => teamNullable!, []);
  const formik = useFormik<FormValues>({
    initialValues: {
      budget: team.budget ? toDecimal(dineroFromMoney(team.budget)) : '',
      hasBudget: !!team.budget,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const budget = formik.values.hasBudget
          ? convertDineroToMoney(dineroFromFloat(values.budget, currency))
          : null;
        await api.updateTeamBudget(team.id, budget);
        dispatch({
          type: 'UPDATE_TEAM',
          payload: {
            ...team,
            budget,
            availableBudget: budget,
          },
        });
        if (!mounted.current) return;
        if (onSuccess)
          onSuccess({
            ...team,
            budget,
            availableBudget: budget,
          });
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const isSubmitDisabled =
    (formik.values.hasBudget && !formik.values.budget) || formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {isEditing
          ? t('createEditTeamBudgetDialog.editTitle')
          : t('createEditTeamBudgetDialog.addTitle')}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="create-edit-team-budget-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              <FormControl disabled={formik.isSubmitting} variant="standard">
                <Box display="inline-flex" alignItems="center" component="span">
                  <FormLabel>
                    {t('createEditTeamBudgetDialog.setTeamBudget')}
                  </FormLabel>
                  <FormControlLabelTooltipIcon
                    title={t('createEditTeamBudgetDialog.toolTip')}
                  />
                </Box>
                <RadioGroup
                  value={formik.values.hasBudget ? 'yes' : 'no'}
                  onChange={(e) => {
                    formik.setFieldValue('hasBudget', e.target.value === 'yes');
                  }}
                  row
                >
                  {['yes', 'no'].map((option) => (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={t(`common.${option}`)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            {formik.values.hasBudget && (
              <Grid item xs={12}>
                <MoneyField
                  label={t('createEditTeamBudgetDialog.budget')}
                  {...omit(formik.getFieldProps('budget'), 'onChange')}
                  onValueChange={({ value }) =>
                    formik.setFieldValue('budget', value)
                  }
                  isNumericString
                  currency={currency.code}
                  disabled={formik.isSubmitting}
                  autoFocus
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="create-edit-team-budget-form"
          type="submit"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(CreateEditTeamBudgetDialog);
