import { Currency } from '@dinero.js/currencies';
import { dinero, subtract } from 'dinero.js';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import FormatPercent from 'components/FormatPercent';
import { useCardAccountCurrency } from 'domains/card/hooks';
import { Box, LinearProgress, StatusBadge, Typography } from 'elements';
import {
  Cashback,
  MonthlyCashbackProgress,
  MonthlyCashbackProgressList,
  MonthlyCashbackProgressStatus,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import {
  convertDineroToMoney,
  dineroFromMoney,
  getMoneyWithOppositeSign,
} from 'services/utils';

// If there haven't been any transactions during a month,
// BE will not return a progress object for it.
const generateCurrentMonthProgress = (
  currency: Currency<number>
): MonthlyCashbackProgress => ({
  year: moment().year(),
  month: moment().month() + 1,
  cashbackAmount: convertDineroToMoney(dinero({ amount: 0, currency })),
  spendAmount: convertDineroToMoney(dinero({ amount: 0, currency })),
  status: MonthlyCashbackProgressStatus.ongoing,
});

interface Props {
  cashback: Cashback;
  monthlyCashbackProgress: MonthlyCashbackProgressList;
}

const HeaderWithProgressBar = ({
  cashback,
  monthlyCashbackProgress,
}: Props) => {
  const { t } = useTranslation();
  const { monthlyCashbackThresholdProgressEnabled } = useFlags();
  const currency = useCardAccountCurrency();
  const currentMonthProgress =
    monthlyCashbackProgress.cashback.find(
      (item) => item.status === MonthlyCashbackProgressStatus.ongoing
    ) || generateCurrentMonthProgress(currency);
  const didSpendMoreThanThreshold =
    cashback.cashbackMonthlyThreshold.value +
      currentMonthProgress.spendAmount.value <
    0;

  const renderCashbackEarnedAmount = () => {
    if (!monthlyCashbackThresholdProgressEnabled || !didSpendMoreThanThreshold)
      return null;

    return (
      <Box display="flex" alignItems="flex-start" flexShrink={0}>
        <StatusBadge
          label={t('cashbackSubpage.earning')}
          variant="filled"
          color="secondary"
        />
        <Box ml={3} textAlign="right">
          <Typography variant="body2" mb={1}>
            {t('cashbackSubpage.cashbackEarnedThisMonth')}
          </Typography>
          <Typography variant="h4">
            <FormatMoney
              value={currentMonthProgress.cashbackAmount}
              fractionalPart
            />
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderMoneySpentProgressBar = () => {
    if (!monthlyCashbackThresholdProgressEnabled || didSpendMoreThanThreshold)
      return null;

    const positiveSpentAmount = getMoneyWithOppositeSign(
      currentMonthProgress.spendAmount
    );
    const spentPercentage = Math.round(
      (positiveSpentAmount.value / cashback.cashbackMonthlyThreshold.value) *
        100
    );

    return (
      <>
        <Box display="flex" alignItems="center" mt={3} mb={1}>
          <Box flexGrow="1" mr="16px">
            <LinearProgress value={spentPercentage} />
          </Box>
          <StatusBadge
            label={t('cashbackSubpage.startEarning')}
            color="primary"
          />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h6">
              <FormatMoney value={positiveSpentAmount} />
            </Typography>
            <Typography variant="body2">
              {t('cashbackSubpage.transactionVolume', {
                monthName: moment()
                  .month(currentMonthProgress.month - 1)
                  .format('MMMM'),
              })}
            </Typography>
          </Box>
          <Box textAlign="right">
            <Typography variant="h6">
              <FormatMoney
                value={convertDineroToMoney(
                  subtract(
                    dineroFromMoney(cashback.cashbackMonthlyThreshold),
                    dineroFromMoney(positiveSpentAmount)
                  )
                )}
              />
            </Typography>
            <Typography variant="body2">
              {t('cashbackSubpage.leftToActivateCashback')}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box mb={5}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h5" mb={1}>
            {t('cashbackSubpage.cashback')}
          </Typography>
          <Typography variant="body2">
            <Trans
              i18nKey="cashbackSubpage.description"
              components={{
                1: <span />,
                2: <FormatPercent value={cashback.generalRate * 100} />,
              }}
            />
          </Typography>
        </Box>
        {renderCashbackEarnedAmount()}
      </Box>
      {renderMoneySpentProgressBar()}
    </Box>
  );
};

export default HeaderWithProgressBar;
