import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SecurityKeySvgIcon from 'assets/icons/interface/securityKey.svg?react';
import { useGlobalState } from 'context/GlobalState';
import { CardsCell } from 'domains/card/components';
import {
  MemberRoleOverline,
  MemberStatusBadge,
  MemberTypeOverline,
  TeamsCell,
} from 'domains/member/components';
import {
  DataGridCell,
  DeviceMobileCameraIcon,
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  gridUtils,
  Tooltip,
} from 'elements';
import { Member } from 'services/constants';
import { getFullName } from 'services/utils';

const useColumns = () => {
  const { t, i18n } = useTranslation();
  const {
    state: { featureModules, isComplianceRiskWhiteLabelApp },
  } = useGlobalState();

  return useMemo(() => {
    const columns: GridColDef<Member>[] = [
      {
        headerName: t('membersPage.name'),
        field: 'firstName',
        flex: 150,
        renderCell: ({ row }) => (
          <DataGridCell>
            {getFullName(row.firstName, row.lastName)}
            <MemberRoleOverline
              roles={row.roles}
              ml={1}
              color="textSecondary"
              sx={{
                verticalAlign: 'top',
              }}
            />
            {row.embeddedPartnerMembership && (
              <MemberTypeOverline
                isNewDesign
                roles={row.roles}
                color="textSecondary"
                sx={{
                  verticalAlign: 'top',
                }}
              />
            )}
          </DataGridCell>
        ),
      },
      {
        headerName: t('membersPage.status'),
        field: 'status',
        flex: 100,
        renderCell: ({ row }) => <MemberStatusBadge status={row.status} />,
      },
      ...(featureModules.SECURITY_KEY_AUTHENTICATION
        ? [
            {
              headerName: t('membersPage.pairedDevice'),
              field: 'pairedDevice',
              flex: 100,
              align: 'center' as GridAlignment,
              headerAlign: 'center' as GridAlignment,
              sortable: false,
              renderCell: ({ row }: GridRenderCellParams<Member>) => {
                if (row.phoneDevicePaired || row.securityKeyPaired) {
                  return (
                    <DataGridCell>
                      {row.phoneDevicePaired && (
                        <Tooltip title={t('membersPage.phoneDevicePaired')}>
                          <DeviceMobileCameraIcon
                            sx={{ marginRight: row.securityKeyPaired ? 2 : 0 }}
                          />
                        </Tooltip>
                      )}
                      {row.securityKeyPaired && (
                        <Tooltip title={t('membersPage.securityKeyPaired')}>
                          <SecurityKeySvgIcon />
                        </Tooltip>
                      )}
                    </DataGridCell>
                  );
                }
                return '-';
              },
            },
          ]
        : []),
      ...(!isComplianceRiskWhiteLabelApp && featureModules.TEAMS
        ? [
            {
              headerName: t('membersPage.teams'),
              field: 'teams',
              flex: 150,
              sortable: false,
              renderCell: ({ row }: GridRenderCellParams<Member>) => (
                <TeamsCell teams={row.teams} />
              ),
            },
          ]
        : []),
      ...(!isComplianceRiskWhiteLabelApp
        ? [
            {
              headerName: t('membersPage.cards'),
              field: 'cards',
              flex: 120,
              sortable: false,
              renderCell: ({ row }: GridRenderCellParams<Member>) => (
                <CardsCell cards={row.cards} />
              ),
            },
          ]
        : []),
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language, featureModules.TEAMS, isComplianceRiskWhiteLabelApp]);
};

export default useColumns;
