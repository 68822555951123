import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import NoData from 'components/NoData';
import { useGlobalState } from 'context/GlobalState';
import { CardIconWithDetails } from 'domains/card/components';
import {
  Box,
  LinearProgress,
  LoaderWithOverlay,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  VerticalCardsIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { TopCardSpender } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getMoneyWithOppositeSign,
  getPath,
} from 'services/utils';

interface State {
  cards: TopCardSpender[] | null;
  isLoading: boolean;
  maxTotalAmount: number;
}

const TopSpenders = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    cards: null,
    isLoading: true,
    maxTotalAmount: 0,
  });

  const getData = async () => {
    try {
      const { cards } = await api.getOrganizationTopCardSpenders(
        organization!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        cards,
        maxTotalAmount: Math.max(
          ...cards.map((c) => Math.abs(c.totalAmount.value))
        ),
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Paper variant="outlined" sx={{ position: 'relative', height: '100%' }}>
      <Box p={2} pb={1}>
        <Typography variant="subtitle1" mb={1}>
          {t('dashboardPage.topSpenders')}
        </Typography>
        <Typography variant="caption2" component="div" color="text.secondary">
          {t('dashboardPage.topSpendersPeriod')}
        </Typography>
      </Box>
      <TableContainer
        sx={(theme) => ({
          position: 'relative',
          height: 299,
          padding: theme.spacing(1, 2, 2),
        })}
      >
        <Table component="div" size="small">
          <TableBody component="div">
            {state.cards?.map((card, index) => {
              const cardPath = generatePath(getPath('cardDetails'), {
                orgId: organization!.id,
                cardId: card.cardId,
              });
              const percentageOfMaxTotalAmount = !state.maxTotalAmount
                ? 0
                : Math.ceil(
                    (Math.abs(card.totalAmount.value) / state.maxTotalAmount) *
                      100
                  );

              return (
                <TableRow
                  key={card.cardId}
                  component={RouterLink}
                  to={cardPath}
                  sx={(theme) => ({
                    textDecoration: 'none',
                    '&:hover': {
                      background: theme.palette.action.hover,
                    },
                    '.MuiTableCell-root': { px: 1, border: 0 },
                  })}
                >
                  <TableCell
                    component="div"
                    sx={{ paddingRight: '0 !important' }}
                  >
                    {index + 1}.
                  </TableCell>
                  <TableCell
                    component="div"
                    sx={{ overflow: 'hidden', maxWidth: 140 }}
                  >
                    {card.cardType && (
                      <CardIconWithDetails
                        cardConfig={card.cardConfig}
                        cardDesignId={card.cardDesignId}
                        cardRefNum={card.cardRefNum}
                        cardName={card.cardName}
                      />
                    )}
                  </TableCell>
                  <TableCell component="div" sx={{ maxWidth: 140 }}>
                    {card.cardHolderFirstName} {card.cardHolderLastName}
                  </TableCell>
                  <TableCell component="div" sx={{ width: '22%' }}>
                    <LinearProgress
                      value={percentageOfMaxTotalAmount}
                      size="small"
                      sx={{ background: 'none' }}
                    />
                  </TableCell>
                  <TableCell component="div" align="right">
                    <FormatMoney
                      value={getMoneyWithOppositeSign(card.totalAmount)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {!state.isLoading && !state.cards?.length && (
        <NoData
          Icon={VerticalCardsIcon}
          label={t('dashboardPage.noData')}
          isNewDesign
          sx={{ position: 'absolute', top: 0, left: 0 }}
        />
      )}

      <LoaderWithOverlay loading={state.isLoading} size="small" />
    </Paper>
  );
};

export default TopSpenders;
