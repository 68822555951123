import variablesJson from './variables.json';

export const barclaysStyles = {
  ...variablesJson,
  input: {
    outlined: {
      enabledBorder: 'rgba(0, 28, 84, 0.16)',
      disabledBorder: 'rgba(0, 28, 84, 0.12)',
    },
  },
  chip: {
    defaultCloseFill: '#001c54',
    defaultHoverFill: 'rgba(0, 28, 84, 0.12)',
    defaultEnabledBorder: '#003087',
  },
  tooltip: {
    fill: 'rgba(0, 28, 84, 0.75)',
  },
  navigation: {
    ...variablesJson.navigation,
    dark: {
      background: '#001c54',
      selected: '#3696fc',
      hover: 'rgba(54, 150, 252, 0.1)',
      text: {
        primary: '#161b2f',
        selectedPrimary: '#ffffff',
        secondary: 'rgba(22, 27, 47, 0.8)',
        disabled: 'rgba(22, 27, 47, 0.5)',
      },
    },
    light: {
      background: '#ffffff',
      selected: '#3696fc',
      hover: 'rgba(54, 150, 252, 0.1)',
      text: {
        primary: '#161b2f',
        selectedPrimary: '#ffffff',
        secondary: 'rgba(22, 27, 47, 0.8)',
        disabled: 'rgba(22, 27, 47, 0.5)',
      },
    },
  },
  button: {
    ...variablesJson.button,
    filled: {
      default: '#3696fc',
      hover: '#2170d3',
      text: '#ffffff',
    },
    shape: {
      borderRadius: 20,
    },
    textOutlined: {
      hover: '#f5f7fa',
      text: '#3696fc',
      border: '#3696fc',
    },
  },
  text: {
    primary: 'rgba(28, 28, 28, 0.87)',
    secondary: 'rgba(28, 28, 28, 0.8)',
    disabled: 'rgba(28, 28, 28, 0.5)',
  },
  primary: {
    ...variablesJson.primary,
    main: '#3696fc',
    dark: '#2170d3',
    light: '#5cacfd',
  },
  secondary: {
    contrastText: '#001c54',
    main: '#f5f7fa',
    dark: '#e0e5ed',
    light: '#ffffff',
  },
  error: {
    contrastText: '#ffffff',
    main: '#d42114',
    dark: '#b01810',
    light: '#ff3b2a',
  },
  warning: {
    contrastText: '#ffffff',
    main: '#ffa000',
    dark: '#ff8f00',
    light: '#ffc107',
  },
  info: {
    contrastText: '#ffffff',
    main: '#0052c3',
    dark: '#003087',
    light: '#1976d2',
  },
  success: {
    contrastText: '#ffffff',
    main: '#00875a',
    dark: '#006644',
    light: '#00a86b',
  },
  typography: {
    ...variablesJson.typography,
    h1: {
      ...variablesJson.typography.h1,
      fontWeight: 700,
      lineHeight: '120%',
      fontSize: '48px',
      letterSpacing: '-0.5px',
    },
    h2: {
      ...variablesJson.typography.h2,
      fontWeight: 700,
      fontSize: '40px',
    },
    h3: {
      ...variablesJson.typography.h3,
      fontWeight: 600,
      lineHeight: '120%',
      fontSize: '32px',
      letterSpacing: 0,
    },
    h4: {
      ...variablesJson.typography.h4,
      fontWeight: 600,
      lineHeight: '120%',
      fontSize: '24px',
    },
    h5: {
      ...variablesJson.typography.h5,
      fontWeight: 600,
      lineHeight: '120%',
      fontSize: '20px',
      letterSpacing: 0,
    },
    h6: {
      ...variablesJson.typography.h6,
      fontWeight: 600,
      lineHeight: '120%',
      fontSize: '18px',
    },
    body2: {
      ...variablesJson.typography.body2,
      lineHeight: '150%',
      letterSpacing: '0.15px',
    },
  },
  shape: {
    borderRadius: 20,
  },
};
