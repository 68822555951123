import { i18n, TFunction } from 'i18next';
import { Trans } from 'react-i18next';
/** Place Avatar svg/png file into "images/integrationAvatars" folder, when it should be
 *  different from what we have in integration dialog.
 *  Otherwise, use the logo from "svg/integrations" folder in svg format (preferably) for Avatars.
 */
import AgicapAvatar from 'assets/images/integrationAvatars/agicap.png';
import BasAvatar from 'assets/images/integrationAvatars/bas.png';
import BBAvatar from 'assets/images/integrationAvatars/bb.png';
import BezalaAvatar from 'assets/images/integrationAvatars/bezala.png';
import CandisAvatar from 'assets/images/integrationAvatars/candis.png';
import CirculaAvatar from 'assets/images/integrationAvatars/circula.png';
import CommitlyAvatar from 'assets/images/integrationAvatars/commitly.png';
import ConfermaAvatar from 'assets/images/integrationAvatars/conferma.png';
import DatevAvatar from 'assets/images/integrationAvatars/datev.png';
import DomondaAvatar from 'assets/images/integrationAvatars/domonda.png';
import FlowwerAvatar from 'assets/images/integrationAvatars/flowwer.png';
import FreeFinanceAvatar from 'assets/images/integrationAvatars/freeFinance.png';
import GetMyInvoicesAvatar from 'assets/images/integrationAvatars/gmi.png';
import HelloHQAvatar from 'assets/images/integrationAvatars/helloHQ.png';
import KanzleilandAvatar from 'assets/images/integrationAvatars/kanzleiland.png';
import KlippaAvatar from 'assets/images/integrationAvatars/klippa.png';
import MobilexpenseAvatar from 'assets/images/integrationAvatars/mobilexpense.png';
import NetvisorAvatar from 'assets/images/integrationAvatars/netvisor.png';
import OracleNetsuiteAvatar from 'assets/images/integrationAvatars/oracleNetsuite.png';
import PliantAvatar from 'assets/images/integrationAvatars/pliant.png';
import QuickbooksAvatar from 'assets/images/integrationAvatars/quickbooks.png';
import RydooAvatar from 'assets/images/integrationAvatars/rydoo.png';
import TidelyAvatar from 'assets/images/integrationAvatars/tidely.png';
import ViaxoftAvatar from 'assets/images/integrationAvatars/viaxoft.png';
import AgicapLogo from 'assets/svg/integrations/agicapLogo.svg?react';
import BezalaLogo from 'assets/svg/integrations/bezalaLogo.svg?react';
import BookmanAvatar from 'assets/svg/integrations/bookmanLogo.svg';
import BookmanLogo from 'assets/svg/integrations/bookmanLogo.svg?react';
import BuchhaltungsButlerLogo from 'assets/svg/integrations/buchhaltungsbutlerLogo.svg?react';
import CandisLogo from 'assets/svg/integrations/candisLogo.svg?react';
import CirculaLogo from 'assets/svg/integrations/circulaLogo.svg?react';
import CommitlyLogo from 'assets/svg/integrations/commitlyLogo.svg?react';
import ConfermaLogo from 'assets/svg/integrations/confermaLogo.svg?react';
import DatevLogo from 'assets/svg/integrations/datevServiceLogo.svg?react';
import DomondaLogo from 'assets/svg/integrations/domondaLogo.svg?react';
import FlowwerLogo from 'assets/svg/integrations/flowwerLogo.svg?react';
import FreeFinanceLogo from 'assets/svg/integrations/freeFinanceLogo.svg?react';
import GetMyInvoicesLogo from 'assets/svg/integrations/getMyInvoicesLogo.svg?react';
import HelloHQLogo from 'assets/svg/integrations/helloHQLogo.svg?react';
import KanzleilandLogo from 'assets/svg/integrations/kanzleilandLogo.svg?react';
import KlippaLogo from 'assets/svg/integrations/klippaLogo.svg?react';
import LexwareOfficeAvatar from 'assets/svg/integrations/lexwareOfficeLogo.svg';
import LexwareOfficeLogo from 'assets/svg/integrations/lexwareOfficeLogo.svg?react';
import MicrosoftDynamics365Avatar from 'assets/svg/integrations/microsoftDynamics365Logo.svg';
import MicrosoftDynamics365Logo from 'assets/svg/integrations/microsoftDynamics365Logo.svg?react';
import MobilexpenseLogo from 'assets/svg/integrations/mobilexpenseLogo.svg?react';
import MocoappAvatar from 'assets/svg/integrations/mocoappLogo.svg';
import MocoappLogo from 'assets/svg/integrations/mocoappLogo.svg?react';
import NetvisorLogo from 'assets/svg/integrations/netvisorLogo.svg?react';
import OracleNetsuiteLogo from 'assets/svg/integrations/oracleNetsuiteLogo.svg?react';
import ProcountorAvatar from 'assets/svg/integrations/procountorLogo.svg';
import ProcountorLogo from 'assets/svg/integrations/procountorLogo.svg?react';
import QuickbooksLogo from 'assets/svg/integrations/quickbooksLogo.svg?react';
import RydooLogo from 'assets/svg/integrations/rydooLogo.svg?react';
import ScopevisioAvatar from 'assets/svg/integrations/scopevisioLogo.svg';
import ScopevisioLogo from 'assets/svg/integrations/scopevisioLogo.svg?react';
import TidelyLogo from 'assets/svg/integrations/tidelyLogo.svg?react';
import ViaxoftLogo from 'assets/svg/integrations/viaxoftLogo.svg?react';
import VismaM2Avatar from 'assets/svg/integrations/vismaM2Logo.svg';
import VismaM2Logo from 'assets/svg/integrations/vismaM2Logo.svg?react';
import VoxelAvatar from 'assets/svg/integrations/voxelLogo.svg';
import VoxelLogo from 'assets/svg/integrations/voxelLogo.svg?react';
import XeroAvatar from 'assets/svg/integrations/xeroLogo.svg';
import XeroLogo from 'assets/svg/integrations/xeroLogo.svg?react';
import PliantLogo from 'assets/svg/pliantLogo.svg?react';
import { Link } from 'elements';
import { HelpCenterUrlsType } from 'hooks/useUrls';
import { IntegrationName, PartnerIds } from 'services/constants';

const bezalaLinks = {
  email: 'support@bezala.com',
  phone: '+358 45 787 32457',
};

const circulaHelpcenterLink = {
  en: 'https://help.circula.com/en/articles/6339895-pliant-enable-integration',
  de:
    'https://help.circula.com/de/articles/6339895-pliant-integration-aktivieren',
};

interface Props {
  t: TFunction;
  i18n: i18n;
  articles: Partial<HelpCenterUrlsType>;
  supportEmail: string;
  partnerName: string;
  useCustomText?: boolean;
}

export const getStaticIntegrationsData = ({
  t,
  i18n,
  articles,
  supportEmail,
  partnerName,
  useCustomText,
}: Props) => ({
  [PartnerIds.circula]: {
    title: t('integrationsPage.circula.title'),
    subtitle: t('integrationsPage.circula.subtitle'),
    description: [
      <Trans i18nKey="integrationsPage.circula.descriptionA" />,
      <Trans i18nKey="integrationsPage.circula.descriptionB" />,
      <Trans
        i18nKey="integrationsPage.circula.descriptionC"
        components={{
          supportLink: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a href={'mailto:' + supportEmail} rel="noreferrer" />
          ),
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href={
                i18n.language === 'de'
                  ? circulaHelpcenterLink.de
                  : circulaHelpcenterLink.en
              }
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
    ],
    subDescription: (
      <Trans
        i18nKey="integrationsPage.circula.subDescription"
        components={{ b: <b />, div: <div /> }}
      />
    ),
    type: [t('integrationsPage.api')],
    logo: <CirculaLogo />,
    avatar: CirculaAvatar,
    isPremium: true,
  },
  [IntegrationName.datev]: {
    title: t('integrationsPage.datev.title'),
    subtitle: t('integrationsPage.datev.subtitle'),
    description: [
      t('integrationsPage.datev.descriptionA'),
      t('integrationsPage.datev.descriptionB'),
    ],
    type: [t('integrationsPage.exportFileXml'), t('integrationsPage.api')],
    logo: <DatevLogo />,
    avatar: DatevAvatar,
    isPremium: false,
  },
  [IntegrationName.tidely]: {
    title: t('integrationsPage.tidely.title'),
    subtitle: t('integrationsPage.tidely.title'),
    description: [
      <Trans
        i18nKey="integrationsPage.tidely.descriptionA"
        components={{
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://www.tidely.com/"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
      t('integrationsPage.tidely.descriptionB'),
    ],
    type: [t('integrationsPage.apiPsd2')],
    logo: <TidelyLogo />,
    avatar: TidelyAvatar,
    isPremium: false,
  },
  [IntegrationName.buchhaltungsButler]: {
    title: t('integrationsPage.buchhaltungsbutler.title'),
    subtitle: t('integrationsPage.buchhaltungsbutler.subtitle'),
    description: [
      t('integrationsPage.buchhaltungsbutler.descriptionA'),
      t('integrationsPage.buchhaltungsbutler.descriptionB'),
      <Trans
        i18nKey="integrationsPage.buchhaltungsbutler.descriptionC"
        components={{
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://www.buchhaltungsbutler.de/wissensdatenbank/csv-import-bankbewegungen-buchungssaetze-und-debitoren-kreditorenkonten/"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
    ],
    type: [t('integrationsPage.exportFile'), t('integrationsPage.email')],
    logo: <BuchhaltungsButlerLogo />,
    avatar: BBAvatar,
    isPremium: false,
  },
  [PartnerIds.candis]: {
    title: t('integrationsPage.candis.title'),
    subtitle: useCustomText
      ? t('partnerPage.candis.subtitle')
      : t('integrationsPage.candis.subtitle'),
    description: useCustomText
      ? [
          <Trans
            i18nKey="partnerPage.candis.descriptionA"
            components={{
              linkToCandis: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://my.candis.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />,
          <Trans
            i18nKey="partnerPage.candis.descriptionB"
            components={{
              ul: <ul />,
              li: <li />,
            }}
          />,
          <Trans
            i18nKey="partnerPage.candis.descriptionC"
            components={{
              candisHelp: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://hilfe.candis.io/de/articles/6122088-deine-kreditkarte-von-pliant-uber-candis-beantragen-und-managen"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              candisPhone: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a href="tel:+49 30 311 930 40" rel="noreferrer" />
              ),
              candisChat: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://my.candis.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />,
        ]
      : [
          t('integrationsPage.candis.descriptionA'),
          t('integrationsPage.candis.descriptionB'),
          <Trans
            i18nKey="integrationsPage.candis.descriptionC"
            components={{
              supportLink: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a href={'mailto:' + supportEmail} rel="noreferrer" />
              ),
            }}
          />,
        ],
    type: [t('integrationsPage.api')],
    logo: <CandisLogo />,
    avatar: CandisAvatar,
    isPremium: true,
  },
  [IntegrationName.getMyInvoices]: {
    title: t('integrationsPage.getMyInvoices.title'),
    subtitle: t('integrationsPage.getMyInvoices.subtitle'),
    description: [
      t('integrationsPage.getMyInvoices.descriptionA'),
      <Trans
        i18nKey="integrationsPage.getMyInvoices.descriptionB"
        components={{
          videoLink: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://screencast-o-matic.com/watch/c31roJV38Fv"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              // adding lang check just in case of other languages addition
              href={`https://help.getmyinvoices.com/${
                i18n.language === 'de' ? 'de' : 'en'
              }`}
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
    ],
    type: [t('integrationsPage.email')],
    logo: <GetMyInvoicesLogo />,
    avatar: GetMyInvoicesAvatar,
    isPremium: false,
  },
  [IntegrationName.flowwer]: {
    title: t('integrationsPage.flowwer.title'),
    subtitle: t('integrationsPage.flowwer.subtitle'),
    description: [
      t('integrationsPage.flowwer.descriptionA'),
      t('integrationsPage.flowwer.descriptionB'),
      t('integrationsPage.flowwer.descriptionC'),
    ],
    type: [t('integrationsPage.email')],
    logo: <FlowwerLogo />,
    avatar: FlowwerAvatar,
    isPremium: false,
  },
  [IntegrationName.kanzleiland]: {
    title: t('integrationsPage.kanzleiland.title'),
    subtitle: t('integrationsPage.kanzleiland.subtitle'),
    description: [
      <Trans
        i18nKey="integrationsPage.kanzleiland.descriptionA"
        components={{
          b: <b />,
        }}
      />,
      t('integrationsPage.kanzleiland.descriptionB'),
      t('integrationsPage.kanzleiland.descriptionC'),
    ],
    type: [t('integrationsPage.apiPsd2')],
    logo: <KanzleilandLogo />,
    avatar: KanzleilandAvatar,
    isPremium: false,
  },
  [IntegrationName.bookman]: {
    title: t('integrationsPage.bookman.title'),
    subtitle: t('integrationsPage.bookman.subtitle'),
    description: [
      t('integrationsPage.bookman.descriptionA'),
      t('integrationsPage.bookman.descriptionB'),
      <Trans
        i18nKey="integrationsPage.bookman.descriptionC"
        components={{
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://hilfecenter.bookman.de/helpdoc/integrationen/pliant-und-bookman/"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
    ],
    type: [t('integrationsPage.email')],
    logo: <BookmanLogo />,
    avatar: BookmanAvatar,
    isPremium: false,
  },
  [IntegrationName.hellohq]: {
    title: t('integrationsPage.hellohq.title'),
    subtitle: t('integrationsPage.hellohq.subtitle'),
    description: [
      t('integrationsPage.hellohq.descriptionA'),
      t('integrationsPage.hellohq.descriptionB'),
      t('integrationsPage.hellohq.descriptionC'),
    ],
    type: [t('integrationsPage.exportFile')],
    logo: <HelloHQLogo />,
    avatar: HelloHQAvatar,
    isPremium: false,
  },
  [PartnerIds.mobilexpenseDeclaree]: {
    title: t('integrationsPage.mobilexpense.title'),
    subtitle: t('integrationsPage.mobilexpense.subtitle'),
    description: [
      t('integrationsPage.mobilexpense.descriptionA'),
      t('integrationsPage.mobilexpense.descriptionB'),
      t('integrationsPage.mobilexpense.descriptionC'),
    ],
    type: [t('integrationsPage.api')],
    logo: <MobilexpenseLogo />,
    avatar: MobilexpenseAvatar,
    isPremium: true,
  },
  [PartnerIds.mobilexpenseMXP]: {
    title: t('integrationsPage.mobilexpenseMXP.title'),
    subtitle: t('integrationsPage.mobilexpenseMXP.subtitle'),
    description: [
      <Trans
        i18nKey="integrationsPage.mobilexpenseMXP.description"
        components={{
          br: <br />,
        }}
      />,
    ],
    type: [t('integrationsPage.api')],
    logo: <MobilexpenseLogo />,
    avatar: MobilexpenseAvatar,
    isPremium: true,
  },
  [IntegrationName.domonda]: {
    title: t('integrationsPage.domonda.title'),
    subtitle: t('integrationsPage.domonda.subtitle'),
    description: [
      <Trans
        i18nKey="integrationsPage.domonda.descriptionA"
        components={{
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://domonda.com/"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
      t('integrationsPage.domonda.descriptionB'),
      <Trans
        i18nKey="integrationsPage.domonda.descriptionC"
        components={{
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://academy.domonda.com/academy/upload-email-import"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
    ],
    type: [t('integrationsPage.email')],
    logo: <DomondaLogo />,
    avatar: DomondaAvatar,
    isPremium: false,
  },
  [IntegrationName.rydoo]: {
    title: t('integrationsPage.rydoo.title'),
    subtitle: t('integrationsPage.rydoo.subtitle'),
    description: [
      t('integrationsPage.rydoo.descriptionA'),
      t('integrationsPage.rydoo.descriptionB'),
      t('integrationsPage.rydoo.descriptionC'),
      t('integrationsPage.rydoo.descriptionD'),
    ],
    type: [t('integrationsPage.email')],
    logo: <RydooLogo />,
    avatar: RydooAvatar,
    isPremium: false,
  },
  [IntegrationName.commitly]: {
    title: t('integrationsPage.commitly.title'),
    subtitle: t('integrationsPage.commitly.subtitle'),
    description: [
      t('integrationsPage.commitly.descriptionA'),
      t('integrationsPage.commitly.descriptionB'),
    ],
    type: [t('integrationsPage.apiPsd2')],
    logo: <CommitlyLogo />,
    avatar: CommitlyAvatar,
    isPremium: false,
  },
  [IntegrationName.freeFinance]: {
    title: t('integrationsPage.freeFinance.title'),
    subtitle: t('integrationsPage.freeFinance.subtitle'),
    description: [
      t('integrationsPage.freeFinance.descriptionA'),
      t('integrationsPage.freeFinance.descriptionB'),
    ],
    type: [t('integrationsPage.apiPsd2')],
    logo: <FreeFinanceLogo />,
    avatar: FreeFinanceAvatar,
    isPremium: false,
  },
  [IntegrationName.agicap]: {
    title: t('integrationsPage.agicap.title'),
    subtitle: t('integrationsPage.agicap.subtitle'),
    description: [
      t('integrationsPage.agicap.descriptionA'),
      t('integrationsPage.agicap.descriptionB'),
      t('integrationsPage.agicap.descriptionC'),
    ],
    type: [t('integrationsPage.apiPsd2')],
    logo: <AgicapLogo />,
    avatar: AgicapAvatar,
    isPremium: false,
  },
  [PartnerIds.mocoapp]: {
    title: t('integrationsPage.mocoapp.title'),
    subtitle: (
      <Trans
        i18nKey="integrationsPage.mocoapp.subtitle"
        components={{
          div: <div />,
        }}
      />
    ),
    description: [
      t('integrationsPage.mocoapp.descriptionA'),
      t('integrationsPage.mocoapp.descriptionB'),
    ],
    type: [t('integrationsPage.api')],
    logo: <MocoappLogo />,
    avatar: MocoappAvatar,
    isPremium: false,
  },
  [IntegrationName.lexwareOffice]: {
    title: t('integrationsPage.lexwareOffice.title'),
    subtitle: t('integrationsPage.lexwareOffice.subtitle'),
    description: [
      <Trans
        i18nKey="integrationsPage.lexwareOffice.description"
        values={{ partnerName }}
        components={{
          linkTo: (
            <Link
              href={
                articles.HELP_CENTER_EXPORTING_TRANSACTIONS_LEXWARE_OFFICE_URL
              }
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
          br: <br />,
        }}
      />,
    ],
    type: [t('integrationsPage.api')],
    logo: <LexwareOfficeLogo />,
    avatar: LexwareOfficeAvatar,
    isPremium: false,
  },
  [PartnerIds.bezala]: {
    title: t('integrationsPage.bezala.title'),
    subtitle: t('integrationsPage.bezala.subtitle'),
    description: [
      t('integrationsPage.bezala.descriptionA'),
      t('integrationsPage.bezala.descriptionB'),
    ],
    type: [t('integrationsPage.api')],
    logo: <BezalaLogo />,
    avatar: BezalaAvatar,
    isPremium: false,
  },
  [PartnerIds.bezalaWallet]: {
    title: t('integrationsPage.bezalaWallet.title'),
    subtitle: t('integrationsPage.bezalaWallet.subtitle'),
    description: [
      t('integrationsPage.bezalaWallet.descriptionA'),
      <Trans
        i18nKey="integrationsPage.bezalaWallet.descriptionB"
        components={{
          email: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a href={'mailto:' + bezalaLinks.email} rel="noreferrer" />
          ),
          phone: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a href={`tel:${bezalaLinks.phone}`} rel="noreferrer" />
          ),
        }}
        values={bezalaLinks}
      />,
    ],
    type: [t('integrationsPage.api')],
    logo: <BezalaLogo />,
    avatar: BezalaAvatar,
    isPremium: true,
  },
  [PartnerIds.viaxoft]: {
    title: t('integrationsPage.viaxoft.title'),
    subtitle: t('integrationsPage.viaxoft.subtitle'),
    description: [
      t('integrationsPage.viaxoft.descriptionA'),
      t('integrationsPage.viaxoft.descriptionB'),
    ],
    type: [t('integrationsPage.api')],
    logo: <ViaxoftLogo />,
    avatar: ViaxoftAvatar,
    isPremium: false,
  },
  [IntegrationName.customCSV]: {
    title: t('integrationsPage.customCSV.title'),
    subtitle: t('integrationsPage.customCSV.subtitle'),
    description: [
      t('integrationsPage.customCSV.descriptionA'),
      t('integrationsPage.customCSV.descriptionB'),
      t('integrationsPage.customCSV.descriptionC'),
    ],
    type: [t('integrationsPage.exportFile')],
    logo: <PliantLogo />,
    avatar: PliantAvatar,
    isPremium: true,
  },
  [IntegrationName.netvisor]: {
    title: t('integrationsPage.netvisor.title'),
    subtitle: t('integrationsPage.netvisor.subtitle'),
    description: [t('integrationsPage.netvisor.descriptionA')],
    type: [t('integrationsPage.exportFile')],
    logo: <NetvisorLogo />,
    avatar: NetvisorAvatar,
    isPremium: false,
  },
  [IntegrationName.procountor]: {
    title: t('integrationsPage.procountor.title'),
    subtitle: t('integrationsPage.procountor.subtitle'),
    description: [
      t('integrationsPage.procountor.descriptionA'),
      <Trans
        i18nKey="integrationsPage.procountor.descriptionB"
        components={{
          li: <li />,
          ul: <ul />,
        }}
      />,
    ],
    type: [t('integrationsPage.exportFile')],
    logo: <ProcountorLogo />,
    avatar: ProcountorAvatar,
    isPremium: false,
  },
  [IntegrationName.xero]: {
    title: t('integrationsPage.xero.title'),
    subtitle: t('integrationsPage.xero.subtitle'),
    description: [
      t('integrationsPage.xero.descriptionA'),
      t('integrationsPage.xero.descriptionB'),
      t('integrationsPage.xero.descriptionC'),
    ],
    type: [t('integrationsPage.api')],
    logo: <XeroLogo />,
    avatar: XeroAvatar,
    isPremium: true,
  },
  [IntegrationName.quickbooks]: {
    title: t('integrationsPage.quickbooks.title'),
    subtitle: t('integrationsPage.quickbooks.subtitle'),
    description: [
      t('integrationsPage.quickbooks.descriptionA'),
      t('integrationsPage.quickbooks.descriptionB'),
      t('integrationsPage.quickbooks.descriptionC'),
    ],
    type: [t('integrationsPage.api')],
    logo: <QuickbooksLogo />,
    avatar: QuickbooksAvatar,
    isPremium: true,
  },
  [IntegrationName.oracleNetSuite]: {
    title: t('integrationsPage.oracleNetSuite.title'),
    subtitle: t('integrationsPage.oracleNetSuite.subtitle'),
    description: [
      t('integrationsPage.oracleNetSuite.descriptionA'),
      t('integrationsPage.oracleNetSuite.descriptionB'),
      t('integrationsPage.oracleNetSuite.descriptionC'),
    ],
    type: [t('integrationsPage.api')],
    logo: <OracleNetsuiteLogo />,
    avatar: OracleNetsuiteAvatar,
    isPremium: true,
  },
  [IntegrationName.microsoftDynamics365BusinessCentral]: {
    title: t('integrationsPage.microsoftDynamics365BusinessCentral.title'),
    subtitle: t(
      'integrationsPage.microsoftDynamics365BusinessCentral.subtitle'
    ),
    description: [
      t('integrationsPage.microsoftDynamics365BusinessCentral.descriptionA'),
      t('integrationsPage.microsoftDynamics365BusinessCentral.descriptionB'),
    ],
    type: [t('integrationsPage.api')],
    logo: <MicrosoftDynamics365Logo />,
    avatar: MicrosoftDynamics365Avatar,
    isPremium: true,
  },
  [PartnerIds.klippa]: {
    title: t('integrationsPage.klippa.title'),
    subtitle: t('integrationsPage.klippa.subtitle'),
    description: [t('integrationsPage.klippa.descriptionA')],
    type: [t('integrationsPage.api')],
    logo: <KlippaLogo />,
    avatar: KlippaAvatar,
    isPremium: true,
  },
  [PartnerIds.voxel]: {
    title: t('integrationsPage.voxel.title'),
    subtitle: t('integrationsPage.voxel.subtitle'),
    description: [t('integrationsPage.voxel.description')],
    type: [t('integrationsPage.api')],
    logo: <VoxelLogo />,
    avatar: VoxelAvatar,
    isPremium: false,
  },
  [PartnerIds.conferma]: {
    title: t('integrationsPage.conferma.title'),
    subtitle: t('integrationsPage.conferma.subtitle'),
    description: [t('integrationsPage.conferma.description')],
    type: [t('integrationsPage.api')],
    logo: <ConfermaLogo />,
    avatar: ConfermaAvatar,
    isPremium: false,
  },
  [PartnerIds.bas]: {
    title: t('integrationsPage.bas.title'),
    subtitle: t('integrationsPage.bas.subtitle'),
    description: [t('integrationsPage.bas.description')],
    type: [t('integrationsPage.api')],
    // logo should be replaced once we have svg
    logo: <img alt="BAS" src={BasAvatar} height={45} />,
    avatar: BasAvatar,
    isPremium: false,
  },
  [PartnerIds.scopevisio]: {
    title: t('integrationsPage.scopevisio.title'),
    subtitle: t('integrationsPage.scopevisio.subtitle'),
    description: [t('integrationsPage.scopevisio.description')],
    type: [t('integrationsPage.api')],
    logo: <ScopevisioLogo />,
    avatar: ScopevisioAvatar,
    isPremium: false,
  },
  [PartnerIds.vismaM2]: {
    title: t('integrationsPage.vismaM2.title'),
    subtitle: t('integrationsPage.vismaM2.subtitle'),
    description: [
      <Trans
        i18nKey="integrationsPage.vismaM2.description"
        components={{
          br: <br />,
          b: <b />,
        }}
      />,
    ],
    type: [t('integrationsPage.api')],
    logo: <VismaM2Logo />,
    avatar: VismaM2Avatar,
    isPremium: false,
  },
});
