import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { isCustomLocoiaAccountingSystem } from 'domains/settings/utils';
import { AccountingSystem } from 'services/constants';

const useLocoiaMappedName = () => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const isCustomAccountingSystem = isCustomLocoiaAccountingSystem(
    accountingSettings
  );

  return useMemo(() => {
    const accSystem = accountingSettings?.accountingSystem;
    if (
      !accSystem ||
      !(
        accountingSettings!.accountingSystem === AccountingSystem.lexOffice ||
        isCustomAccountingSystem
      )
    )
      return '';

    // custom CSV system
    if (isCustomAccountingSystem)
      return accountingSettings?.accountingSystemName
        ? t('accountingSystem.customLocoiaFormat', {
            name: accountingSettings.accountingSystemName,
          })
        : '';

    // default - lexwareoffice or nothing
    return accountingSettings!.accountingSystemName || '';
  }, [accountingSettings?.accountingSystem]);
};

export default useLocoiaMappedName;
