import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MemberRoleOverline } from 'domains/member/components';
import { ResetPhoneNumberDialog } from 'domains/member/dialogs';
import { Box, Grid, LabeledValue, Typography } from 'elements';
import { MemberDetails } from 'services/constants';
import { useCanUser } from 'services/rbac';
import AddressDialog from './AddressDialog';
import DateOfBirthDialog from './DateOfBirthDialog';
import EditMemberDialog from './EditMemberDialog';

const formatAddress = (address: MemberDetails['address']) => {
  if (!address) {
    return '-';
  }

  return `${address.streetName} ${address.streetNumber}, ${address.postalCode} ${address.city}`;
};

const PersonalDataSection = () => {
  const { t } = useTranslation();
  const {
    state: { featureModules, member },
    dispatch,
  } = useGlobalState();
  const canUser = useCanUser();
  const [isEditMemberDialogOpen, setIsEditMemberDialogOpen] = useState(false);
  const [
    isResetPhoneNumberDialogOpen,
    setIsResetPhoneNumberDialogOpen,
  ] = useState(false);
  const [isDateOfBirthDialogOpen, setIsDateOfBirthDialogOpen] = useState(false);
  const [isAddressDialogOpen, setIsAddressDialogOpen] = useState(false);

  return (
    <Box mb={8}>
      <Typography variant="h6" mb={1}>
        {t('profilePage.personalDataSection.title')}
      </Typography>

      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid item xs={6}>
          <LabeledValue
            label={t('profilePage.personalDataSection.fullName')}
            value={`${member.firstName} ${member.lastName}`}
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledValue
            label={t('profilePage.personalDataSection.role')}
            value={
              <MemberRoleOverline
                roles={member.roles}
                variant="inherit"
                showCardholderRole
              />
            }
          />
        </Grid>

        <Grid item xs={6}>
          <LabeledValue
            label={t('profilePage.personalDataSection.email')}
            value={member.email}
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledValue
            label={t('profilePage.personalDataSection.phoneNumber')}
            value={
              member.securityKeyPaired
                ? t('profilePage.personalDataSection.noPhoneNumber')
                : member.phoneNumber || '-'
            }
            onEdit={
              canUser('profile-page-phone-number:change')
                ? () => setIsResetPhoneNumberDialogOpen(true)
                : undefined
            }
            disabled={member.securityKeyPaired}
            disabledTooltip={t(
              'profilePage.personalDataSection.noPhoneNumberTooltip'
            )}
          />
        </Grid>

        {featureModules.BENEFIT_CARDS && (
          <>
            <Grid item xs={6}>
              <LabeledValue
                label={t('profilePage.personalDataSection.dateOfBirth')}
                value={
                  member.dateOfBirth
                    ? moment(member.dateOfBirth).format('DD.MM.YYYY')
                    : '-'
                }
                onEdit={() => setIsDateOfBirthDialogOpen(true)}
              />
            </Grid>
            <Grid item xs={6}>
              <LabeledValue
                label={t('profilePage.personalDataSection.address')}
                value={formatAddress(member.address)}
                onEdit={() => setIsAddressDialogOpen(true)}
              />
            </Grid>
          </>
        )}
      </Grid>

      {/* todo: should be removed? setIsEditMemberDialogOpen is not triggered from anywhere */}
      <EditMemberDialog
        member={member as MemberDetails}
        open={isEditMemberDialogOpen}
        onClose={() => setIsEditMemberDialogOpen(false)}
        onSuccess={(data) => {
          dispatch({
            type: 'SET_USER_DATA',
            payload: { member: data },
          });
          setIsEditMemberDialogOpen(false);
        }}
      />

      <ResetPhoneNumberDialog
        open={isResetPhoneNumberDialogOpen}
        onClose={() => setIsResetPhoneNumberDialogOpen(false)}
        onSuccess={() => {}}
        member={member as MemberDetails}
      />

      {featureModules.BENEFIT_CARDS && (
        <>
          <DateOfBirthDialog
            open={isDateOfBirthDialogOpen}
            onClose={() => setIsDateOfBirthDialogOpen(false)}
            onSuccess={(data) => {
              dispatch({
                type: 'SET_USER_DATA',
                payload: { member: data },
              });
              setIsDateOfBirthDialogOpen(false);
            }}
            member={member as MemberDetails}
          />

          <AddressDialog
            open={isAddressDialogOpen}
            onClose={() => setIsAddressDialogOpen(false)}
            onSuccess={(data) => {
              dispatch({
                type: 'SET_USER_DATA',
                payload: { member: data },
              });
              setIsAddressDialogOpen(false);
            }}
            member={member as MemberDetails}
          />
        </>
      )}
    </Box>
  );
};

export default PersonalDataSection;
