import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  ActiveFilterValue,
  StatusFilterSelect,
} from 'domains/settings/components';
import {
  AddVatRateDialog,
  CodatSyncSeparatedDialog,
  EditVatRateDialog,
  SyncNameEnum,
} from 'domains/settings/dialogs';
import {
  FiltersContainer,
  HeaderContainer,
  HeaderTitle,
} from 'domains/settings/layout';
import withTabPermission from 'domains/settings/pages/AccountingPage/withTabPermission';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import {
  Badge,
  Box,
  Button,
  DataGrid,
  GridSortModel,
  gridUtils,
  LoaderWithOverlay,
  Typography,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, PageSearchInput } from 'layout';
import {
  AccountingItemStatus,
  CodatMappingOptionsSummary,
  DEFAULT_PAGE_LIMIT,
  VatRate,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import useCanUser from 'services/rbac/useCanUser';
import { getGenericErrorMsg, getPath } from 'services/utils';
import useColumns from './useColumns';

const INITIAL_SORT = '+name';

interface State {
  vatRates: VatRate[];
  hasNextPage: boolean;
  isLoading: boolean;
  activeFilter: ActiveFilterValue | '';
  isAddVatRateDialogOpen: boolean;
  isEditVatRateDialogOpen: boolean;
  editableVatRate: VatRate | null;
  codatMappingOptionsSummary: CodatMappingOptionsSummary | null;
  codatSyncDialogOpen: boolean;
  search: string;
  sort: string;
}

const VatRatesSubPage = () => {
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const history = useHistory();
  const location = useLocation<{ isCodatFlow: boolean }>();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    vatRates: [],
    hasNextPage: false,
    isLoading: true,
    activeFilter: AccountingItemStatus.active,
    isAddVatRateDialogOpen: false,
    isEditVatRateDialogOpen: false,
    editableVatRate: null,
    codatMappingOptionsSummary: null,
    codatSyncDialogOpen: false,
    search: '',
    sort: INITIAL_SORT,
  });
  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);
  const areItemsReadOnly = isCodatConnected;

  const columns = useColumns((item) =>
    setState((prevState) => ({
      ...prevState,
      isEditVatRateDialogOpen: true,
      editableVatRate: item,
    }))
  );

  useEffect(() => {
    if (location.state?.isCodatFlow && canUser('codat-sync:change')) {
      setState((prevState) => ({
        ...prevState,
        codatSyncDialogOpen: true,
      }));
      history.replace({ state: null });
    }
  }, [location.state]);

  const getData = async (isLoadMore = false, fetchCodatSummary = false) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [
        { vatRates, hasNextPage },
        codatMappingOptionsSummary,
      ] = await Promise.all([
        api.getVatRates({
          organizationId: organization!.id,
          page: pageRef.current,
          limit: DEFAULT_PAGE_LIMIT,
          q: state.search || undefined,
          sortBy: state.sort,
          status: (state.activeFilter as AccountingItemStatus) || undefined,
        }),
        fetchCodatSummary && isCodatConnected && canUser('codat-sync:view')
          ? api.getCodatMappingOptionsSummary(organization!.id)
          : state.codatMappingOptionsSummary,
      ]);

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        vatRates: isLoadMore ? [...prevState.vatRates, ...vatRates] : vatRates,
        hasNextPage,
        codatMappingOptionsSummary,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    pageRef.current = 0;
    getData(false, !state.codatMappingOptionsSummary);
  }, [state.activeFilter, state.search, state.sort]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(true);
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.vatRates.length) return;
    setState((prevState) => ({
      ...prevState,
      sort: gridUtils.getNewSortParam(sort) || INITIAL_SORT,
    }));
  };

  const renderTitle = () => {
    if (isCodatConnected)
      return (
        <Trans
          i18nKey="vatRatesSubPage.codatTitle"
          values={{ name: accountingSettings!.accountingSystemName }}
          components={{
            a: (
              <Link
                to={generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })}
              />
            ),
          }}
        />
      );
    return t('vatRatesSubPage.title');
  };

  const renderCustomButton = () => {
    if (isCodatConnected && state.codatMappingOptionsSummary) {
      if (!state.codatMappingOptionsSummary.lastSynced) {
        return (
          <Button
            sx={{ ml: 1 }}
            variant={
              canUser('vat-rate:create') && !areItemsReadOnly
                ? 'outlined'
                : 'contained'
            }
            onClick={() =>
              history.push(
                generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })
              )
            }
          >
            {t('vatRatesSubPage.setupSyncBtn')}
          </Button>
        );
      }

      return (
        <Badge
          color="secondary"
          badgeContent={state.codatMappingOptionsSummary?.taxRates.newItems}
        >
          <Button
            sx={{ ml: 1 }}
            variant={
              canUser('vat-rate:create') && !areItemsReadOnly
                ? 'outlined'
                : 'contained'
            }
            disabled={!canUser('codat-sync:change')}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                codatSyncDialogOpen: true,
              }))
            }
          >
            {t('vatRatesSubPage.editAccSyncBtn')}
          </Button>
        </Badge>
      );
    }

    return null;
  };

  return (
    <>
      <HeaderContainer>
        <ContentContainer>
          <HeaderTitle>
            {t('vatRatesSubPage.mainTitle')}
            {state.codatMappingOptionsSummary?.lastSynced && (
              <Typography
                color="textSecondary"
                variant="body2"
                component="span"
                ml={1}
              >
                (
                {t('vatRatesSubPage.lastSync', {
                  date: moment(
                    state.codatMappingOptionsSummary.lastSynced
                  ).format('DD MMM YYYY, HH:mm'),
                })}
                )
              </Typography>
            )}
          </HeaderTitle>
          <Typography>{renderTitle()}</Typography>
        </ContentContainer>
      </HeaderContainer>

      <FiltersContainer>
        <PageSearchInput
          sx={{ mr: 2 }}
          initialValue={state.search}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, search: value }))
          }
          disabled={!state.search && !state.vatRates.length}
        />

        <StatusFilterSelect
          filterValue={state.activeFilter}
          disabled={state.isLoading}
          onFilterChange={(activeFilter) =>
            setState((prevState) => ({
              ...prevState,
              activeFilter,
            }))
          }
        />

        <Box ml="auto">
          {canUser('vat-rate:create') && !areItemsReadOnly && (
            <Button
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isAddVatRateDialogOpen: true,
                }))
              }
            >
              {t('vatRatesSubPage.addButtonTitle')}
            </Button>
          )}
          {renderCustomButton()}
        </Box>
      </FiltersContainer>

      <Box
        position="relative"
        flex={1}
        maxHeight={(theme) => `calc(100% + ${theme.spacing(5)})`}
      >
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid
          apiRef={dataGridRef}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          loading={state.isLoading}
          rows={state.vatRates}
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(INITIAL_SORT),
            },
          }}
          onSortModelChange={handleSortModelChange}
          columns={columns}
          columnVisibilityModel={{
            actions: canUser('vat-rate:update') && !areItemsReadOnly,
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => <NothingFound $top={0} $bottom={0} />,
            loadingOverlay: () => null,
          }}
        />
      </Box>

      <AddVatRateDialog
        open={state.isAddVatRateDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isAddVatRateDialogOpen: false,
          }))
        }
        onSuccess={() => {
          pageRef.current = 0;
          getData();
          setState((prevState) => ({
            ...prevState,
            isAddVatRateDialogOpen: false,
          }));
        }}
      />

      <EditVatRateDialog
        open={state.isEditVatRateDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isEditVatRateDialogOpen: false,
          }))
        }
        onSuccess={() => {
          pageRef.current = 0;
          getData();
          setState((prevState) => ({
            ...prevState,
            isEditVatRateDialogOpen: false,
          }));
        }}
        vatRate={state.editableVatRate}
      />

      <CodatSyncSeparatedDialog
        name={SyncNameEnum.vatRates}
        open={state.codatSyncDialogOpen}
        onSuccess={() => {
          pageRef.current = 0;
          getData(false, true);
        }}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            codatSyncDialogOpen: false,
          }));
        }}
      />
    </>
  );
};

export default withPageConfig(
  withTabPermission(VatRatesSubPage, 'vatRateEnabled'),
  { permission: 'vat-rates-sub-page:visit' }
);
