import { useFormik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { internalOrgPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import { useOrgDefaultLanguage } from 'domains/organization/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CardAccountCurrency, MemberTitle } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { Role } from 'services/rbac';
import {
  getGenericErrorMsg,
  trimObjValues,
  validateEmail,
} from 'services/utils';

// fixed dummy phone number
const PHONE_NUMBER = '+49160123456789';

const getRandom4Numbers = () =>
  String(Math.floor(1 + Math.random() * 9999)).padStart(4, '0');

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

interface Props extends DialogProps {
  partnerId: string;
  onClose: () => void;
}

const CreateApiCardholderDialog = ({ partnerId, ...props }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const orgDefaultLanguage = useOrgDefaultLanguage();
  const {
    state: { organization },
  } = useGlobalState();

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      firstName: 'API',
      lastName: 'Payments',
      email: `noreply+${moment()
        .utc()
        .format('YYYYMMDDHHmmss')}-${getRandom4Numbers()}@getpliant.com`,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const cardholder = await api.inviteApiCardholder(
          partnerId,
          organization!.id,
          {
            ...trimObjValues(values),
            canCreateCardForSelf: true,
            isPartnerMember: true,
            languageCode: orgDefaultLanguage.code,
            maxMonthlySpendLimitPerCard: {
              value: 0,
              currency: CardAccountCurrency.EUR,
            },
            organizationId: organization!.id,
            phoneNumber: PHONE_NUMBER,
            roles: [Role.cardholder],
            title: MemberTitle.mrs,
          }
        );
        if (!mounted.current) return;
        history.push(
          generatePath(internalOrgPaths.memberDetails, {
            orgId: organization!.id,
            memberId: cardholder.id,
          })
        );
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const { invalidEmailError, emailTooLongError } = validateEmail(
    formik.values.email
  );

  const isSubmitDisabled =
    !formik.values.firstName.trim() ||
    !formik.values.lastName.trim() ||
    !formik.values.email.trim() ||
    !!invalidEmailError ||
    !!emailTooLongError ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('int.createApiCardholderDialog.title')}</DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="create-api-cardholder-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('firstName')}
                label={t('int.createApiCardholderDialog.firstName')}
                inputProps={{ maxLength: 50 }}
                disabled={formik.isSubmitting}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('lastName')}
                label={t('int.createApiCardholderDialog.lastName')}
                inputProps={{ maxLength: 50 }}
                disabled={formik.isSubmitting}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('email')}
                label={t('int.createApiCardholderDialog.email')}
                disabled={formik.isSubmitting}
                error={
                  !!invalidEmailError ||
                  !!emailTooLongError ||
                  !!formik.errors.email
                }
                helperText={
                  invalidEmailError ?? emailTooLongError ?? formik.errors.email
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="create-api-cardholder-form"
          type="submit"
        >
          {t('int.createApiCardholderDialog.registerBtn')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(CreateApiCardholderDialog);
