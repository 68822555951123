import { useCallback } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Dropzone } from 'elements';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import { getImageByFile } from './utils';

const MIN_ALLOWED_IMG_SIZE = 2000;

const dropzoneDefaultProps: DropzoneOptions = {
  multiple: false,
  accept: ['image/jpeg', 'image/png'],
};

interface Props {
  onFileAccept: (file: File) => void;
}

const LogoDropStep = ({ onFileAccept }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
    const [file] = acceptedFiles;
    try {
      const image = await getImageByFile(file);
      if (
        image.width > MIN_ALLOWED_IMG_SIZE ||
        image.height > MIN_ALLOWED_IMG_SIZE
      ) {
        enqueueSnackbar(t('merchantLogoEdit.pxLimitError'), {
          variant: 'error',
        });
        return;
      }
      onFileAccept(file);
    } catch (error) {
      enqueueSnackbar(t('merchantLogoEdit.imageProcessingError'), {
        variant: 'error',
      });
      logError(error);
    }
  }, []);

  return (
    <Dropzone
      file={null}
      isLoading={false}
      onDropAccepted={onDropAccepted}
      dropzoneIdleProps={{
        description: t('merchantLogoEdit.dragzoneLabel'),
        secondaryDescription: t('merchantLogoEdit.limitsLabel'),
      }}
      {...dropzoneDefaultProps}
    />
  );
};

export default LogoDropStep;
