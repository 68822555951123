import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVisibleCardAccounts } from 'context/GlobalState';
import { Box, EditButton, QuestionIcon, Tooltip, Typography } from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { ContentContainer } from 'layout';
import { CodatCompanyConfig } from 'services/constants';
import { useCanUser } from 'services/rbac';
import CodatGeneralAccountsChangeDialog from './CodatGeneralAccountsChangeDialog';

interface Props {
  codatCompanyConfig: CodatCompanyConfig;
  onChange: (codatCompanyConfig: CodatCompanyConfig) => void;
}

const CodatGeneralAccountsSettingsGroup = ({
  codatCompanyConfig,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const visibleCardAccounts = useVisibleCardAccounts();
  const partnerName = usePartnerName();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <ContentContainer mb={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6">
            {t('datevExportSettingsGroup.generalAccounts')}
          </Typography>
          {canUser('codat-general-accounts:change') && (
            <EditButton sx={{ ml: 1 }} onClick={() => setIsDialogOpen(true)} />
          )}
        </Box>

        <Box mt={2}>
          {visibleCardAccounts.length < 2 && (
            <Box display="flex" alignItems="center">
              <Typography variant="body2" mr={1}>
                {t('datevExportSettingsGroup.pliantCreditCardAccount', {
                  partnerName,
                })}
              </Typography>
              <Tooltip
                title={t(
                  'datevExportSettingsGroup.pliantCreditCardAccountTitle',
                  {
                    partnerName,
                  }
                )}
              >
                <QuestionIcon fontSize="small" />
              </Tooltip>

              <Typography ml="auto" pl={1}>
                {codatCompanyConfig.bankAccount.name || '-'}
              </Typography>
            </Box>
          )}

          <Box mt={1.5} display="flex" alignItems="center">
            <Typography variant="body2" mr={1}>
              {t('datevExportSettingsGroup.defaultSupplierAccount')}
            </Typography>
            <Tooltip
              title={t('datevExportSettingsGroup.defaultSupplierAccountTitle', {
                partnerName,
              })}
            >
              <QuestionIcon fontSize="small" />
            </Tooltip>

            <Typography ml="auto" pl={1}>
              {codatCompanyConfig.supplier.name || '-'}
            </Typography>
          </Box>

          <Box mt={1.5} display="flex" alignItems="center">
            <Typography variant="body2" mr={1}>
              {t('datevExportSettingsGroup.pliantCustomer', {
                partnerName,
              })}
            </Typography>
            <Tooltip
              title={t('datevExportSettingsGroup.pliantCustomerTitle', {
                partnerName,
              })}
            >
              <QuestionIcon fontSize="small" />
            </Tooltip>

            <Typography ml="auto" pl={1}>
              {codatCompanyConfig.customer.name || '-'}
            </Typography>
          </Box>
        </Box>
      </ContentContainer>

      <CodatGeneralAccountsChangeDialog
        open={isDialogOpen}
        codatCompanyConfig={codatCompanyConfig}
        onClose={() => setIsDialogOpen(false)}
        onUpdate={onChange}
      />
    </>
  );
};

export default CodatGeneralAccountsSettingsGroup;
