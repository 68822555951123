import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { omit, uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountCurrency } from 'domains/card/hooks';
import { RolePicker } from 'domains/member/components';
import { useAvailableRoles } from 'domains/member/utils';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  FormControlLabelTooltipIcon,
  InputLabelTooltipIcon,
  LoaderWithOverlay,
  MoneyField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import useUrls from 'hooks/useUrls';
import {
  LegalRepresentativeType,
  Member,
  MemberDetails,
  MemberStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getMemberRole, Role, useCanUser } from 'services/rbac';
import {
  convertDineroToMoney,
  dineroFromFloat,
  getGenericErrorMsg,
} from 'services/utils';
import EditLegalRepSection from './EditLegalRepSection';

interface State {
  isLoading: boolean;
  members: null | Member[];
}

export interface FormValues {
  role: Role;
  legalRepresentative: LegalRepresentativeType | null;
  powerOfAttorney: boolean;
  editGeneralSettings: boolean;
  editModules: boolean;
  editIntegrations: boolean;
  maxSpendLimitPerCard: string;
  canCreateCardForSelf: boolean;
  accountingSettings: boolean;
  accountingExport: boolean;
}

interface Props extends DialogProps {
  member: MemberDetails;
  onClose: () => void;
  onSuccess: (member: MemberDetails) => void;
}

const EditMemberRolesAndPermissionsDialog = ({
  member,
  onSuccess,
  ...props
}: Props) => {
  const { isExternalApp } = useCurrentApp();
  const {
    state: { organization },
  } = useGlobalState();
  const { t } = useTranslation();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { HELP_CENTER_MEMBER_ROLES_URL, SUPPORT_REQUEST_URL } = useUrls();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const currency = useCardAccountCurrency();
  const [state, setState] = useState<State>({
    isLoading: false,
    members: null,
  });
  const { allowedRoles } = useAvailableRoles({ members: state.members });
  const formik = useFormik<FormValues>({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      role: getMemberRole(member.roles),
      legalRepresentative: member.legalRepresentative,
      powerOfAttorney: member.powerOfAttorney,
      editGeneralSettings: member.roles.includes(Role.orgSettingsManager),
      editModules: member.roles.includes(Role.orgModulesManager),
      editIntegrations: member.roles.includes(Role.orgIntegrationManager),
      maxSpendLimitPerCard: '',
      canCreateCardForSelf: false,
      accountingSettings: member.roles.includes(Role.accountingSettingsManager),
      accountingExport: member.roles.includes(Role.accountingExportManager),
    },
    onSubmit: async (
      {
        role,
        legalRepresentative,
        powerOfAttorney,
        editGeneralSettings,
        editModules,
        editIntegrations,
        canCreateCardForSelf,
        maxSpendLimitPerCard,
        accountingSettings,
        accountingExport,
      },
      { setSubmitting }
    ) => {
      try {
        const roles = uniq([Role.cardholder, role]);
        if (member.roles.includes(Role.teamManager))
          roles.push(Role.teamManager);
        if (editGeneralSettings && role === Role.orgAdmin)
          roles.push(Role.orgSettingsManager);
        if (editModules && role === Role.orgAdmin)
          roles.push(Role.orgModulesManager);
        if (editIntegrations && role === Role.orgAdmin)
          roles.push(Role.orgIntegrationManager);
        if (accountingExport && role === Role.orgAdmin)
          roles.push(Role.accountingExportManager);
        if (accountingSettings && role !== Role.accountOwner)
          roles.push(Role.accountingSettingsManager);
        // setting Role.legalRep handled by BE on setting LegalRepType
        // unless LegalRepType is changed from SOLE TO JOINT or vice versa
        if (
          (member.legalRepresentative === LegalRepresentativeType.sole &&
            legalRepresentative === LegalRepresentativeType.joint) ||
          (member.legalRepresentative === LegalRepresentativeType.joint &&
            legalRepresentative === LegalRepresentativeType.sole)
        ) {
          roles.push(Role.legalRep);
        }

        const memberLegalRepPayload = {
          legalRepresentative,
          powerOfAttorney,
        };

        const doLegalRepUpdateBeforeRoleUpdate =
          canUser('member-legal-rep-role:change') &&
          (member.legalRepresentative === LegalRepresentativeType.sole ||
            member.legalRepresentative === LegalRepresentativeType.joint);

        const doLegalRepUpdateAfterRoleUpdate =
          canUser('member-legal-rep-role:change') &&
          (member.legalRepresentative === null ||
            member.legalRepresentative === LegalRepresentativeType.none) &&
          (legalRepresentative === LegalRepresentativeType.sole ||
            legalRepresentative === LegalRepresentativeType.joint);

        if (doLegalRepUpdateBeforeRoleUpdate) {
          await api.updateLegalRepType(member.id, {
            organizationId: member.organizationId,
            ...memberLegalRepPayload,
          });
        }

        await api.updateMemberRoles(member.id, {
          organizationId: member.organizationId,
          roles,
        });

        if (doLegalRepUpdateAfterRoleUpdate) {
          await api.updateLegalRepType(member.id, {
            organizationId: member.organizationId,
            ...memberLegalRepPayload,
          });
        }

        if (role === Role.orgAdmin && canUser('member-permissions:change')) {
          await api.updateMemberPermissions(member.id, {
            canCreateCardForSelf,
            maxSpendLimitPerCard: convertDineroToMoney(
              dineroFromFloat(maxSpendLimitPerCard, currency)
            ),
            organizationId: member.organizationId,
          });
        }

        const updatedMemberDetails = await api.getMember(
          organization!.id,
          member.id
        );

        if (!mounted.current) return;
        enqueueSnackbar(t('editMemberPermissionsDialog.successToast'));
        onSuccess(updatedMemberDetails);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  useEffect(() => {
    (async () => {
      if (canUser('member-first:create')) {
        try {
          setState((prevState) => ({ ...prevState, isLoading: true }));
          const { members } = await api.getMembers({
            organizationId: organization!.id,
            status: [MemberStatus.invited, MemberStatus.active].join(),
            limit: 1,
          });
          if (!mounted.current) return;
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            members,
          }));
        } catch (error) {
          if (!mounted.current) return;
          setState((prevState) => ({ ...prevState, isLoading: false }));
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    })();
  }, []);

  const getData = async () => {
    try {
      formik.setSubmitting(true);
      const data = await api.getMemberPermissions(
        member.organizationId,
        member.id
      );
      if (!mounted.current) return;
      formik.setFieldValue('canCreateCardForSelf', !!data.canCreateCardForSelf);
      formik.setFieldValue(
        'maxSpendLimitPerCard',
        data.maxSpendLimitPerCard
          ? data.maxSpendLimitPerCard.value / 100
          : undefined
      );
      formik.setSubmitting(false);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleRoleChange = (role: Role) => {
    if (
      role !== Role.accountOwner &&
      (formik.values.legalRepresentative === LegalRepresentativeType.sole ||
        formik.values.legalRepresentative === LegalRepresentativeType.joint)
    ) {
      formik.setFieldValue('legalRepresentative', LegalRepresentativeType.none);
    }

    formik.setFieldValue('role', role);
    formik.setFieldValue('editGeneralSettings', role === Role.orgAdmin);
    formik.setFieldValue(
      'accountingSettings',
      role === Role.orgAdmin || role === Role.accountant
    );
    formik.setFieldValue('editModules', role === Role.orgAdmin);
    formik.setFieldValue('editIntegrations', role === Role.orgAdmin);
    formik.setFieldValue('accountingExport', role === Role.orgAdmin);
  };

  const isSubmitDisabled =
    (formik.values.role === Role.orgAdmin &&
      canUser('member-permissions:change') &&
      !formik.values.maxSpendLimitPerCard) ||
    formik.isSubmitting;

  const isLegalRepSectionVisible =
    canUser('member-legal-rep-role:change') &&
    formik.values.role === Role.accountOwner;

  const isOrgSettingsSectionVisible =
    (formik.values.role === Role.orgAdmin &&
      (canUser('member-org-settings-manager-role:update') ||
        canUser('member-org-modules-manager-role:update') ||
        canUser('member-org-integration-manager-role:update') ||
        canUser('member-permissions-role:view', member))) ||
    ((formik.values.role === Role.accountant ||
      formik.values.role === Role.orgAdmin) &&
      (canUser('member-accounting-settings-manager-role:update') ||
        canUser('member-permissions-role:view', member)));

  const isCardIssuanceSectionVisible =
    formik.values.role === Role.orgAdmin &&
    (canUser('member-permissions:change') ||
      canUser('member-permissions-role:view', member));

  const isAccountingExportSectionVisible =
    formik.values.role === Role.orgAdmin &&
    (canUser('member-accounting-export-manager-role:update') ||
      canUser('member-permissions-role:view', member));

  // console.log({ allowedRoles });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('editMemberPermissionsDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="edit-member-permissions-form"
          noValidate
        >
          <Box>
            <Typography variant="subtitle2">
              {t('inviteMemberDialog.memberPermissions.roles.title')}
            </Typography>
            <RolePicker
              showLabel={false}
              value={formik.values.role}
              onChange={handleRoleChange}
              allowedRoles={
                canUser('member-permissions-role:update')
                  ? allowedRoles
                  : [
                      {
                        role: formik.values.role,
                        isAllowed: true,
                      },
                    ]
              }
              isDisabled={
                formik.isSubmitting ||
                member.roles.includes(Role.creditOps) ||
                (member.roles.includes(Role.legalRep) && isExternalApp)
              }
              tipKey={
                member.roles.includes(Role.legalRep)
                  ? 'rolePicker.tipLegalRep'
                  : 'rolePicker.tip'
              }
              tipLink={
                member.roles.includes(Role.legalRep)
                  ? SUPPORT_REQUEST_URL
                  : HELP_CENTER_MEMBER_ROLES_URL
              }
            />
          </Box>

          {isLegalRepSectionVisible && (
            <Box mt={4}>
              <EditLegalRepSection
                initialLegalRepresentativeValue={
                  formik.initialValues.legalRepresentative
                }
                legalRepresentativeValue={formik.values.legalRepresentative}
                powerOfAttorneyValue={formik.values.powerOfAttorney}
                onChangeLegalRepresentative={(
                  value: LegalRepresentativeType
                ) => {
                  formik.setFieldValue('legalRepresentative', value);
                  if (value === LegalRepresentativeType.none) {
                    formik.setFieldValue('powerOfAttorney', false);
                  }
                }}
                onChangePowerOfAttorney={(value: boolean) =>
                  formik.setFieldValue('powerOfAttorney', value)
                }
              />
            </Box>
          )}

          {isOrgSettingsSectionVisible && (
            <Box mt={4}>
              <Typography variant="subtitle2">
                {t('inviteMemberDialog.memberPermissions.orgSettings.subtitle')}
              </Typography>
              {formik.values.role === Role.orgAdmin && (
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          canUser('member-permissions-role:view', member) &&
                          !canUser('member-org-settings-manager-role:update')
                        }
                        checked={formik.values.editGeneralSettings}
                        onChange={(e, checked) => {
                          formik.setFieldValue('editGeneralSettings', checked);
                        }}
                      />
                    }
                    label={
                      <Box component="span" display="flex" alignItems="center">
                        {t(
                          'inviteMemberDialog.memberPermissions.orgSettings.editGeneralSettings'
                        )}
                        <FormControlLabelTooltipIcon
                          title={t(
                            'inviteMemberDialog.memberPermissions.orgSettings.editGeneralSettingsTooltip'
                          )}
                        />
                      </Box>
                    }
                  />
                </Box>
              )}
              {(formik.values.role === Role.orgAdmin ||
                formik.values.role === Role.accountant) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={
                        canUser('member-permissions-role:view', member) &&
                        !canUser(
                          'member-accounting-settings-manager-role:update'
                        )
                      }
                      checked={formik.values.accountingSettings}
                      onChange={(e, checked) => {
                        formik.setFieldValue('accountingSettings', checked);
                      }}
                    />
                  }
                  label={
                    <Box component="span" display="flex" alignItems="center">
                      {t(
                        'inviteMemberDialog.memberPermissions.orgSettings.accountingSettings'
                      )}
                      <FormControlLabelTooltipIcon
                        title={t(
                          'inviteMemberDialog.memberPermissions.orgSettings.accountingSettingsTooltip'
                        )}
                      />
                    </Box>
                  }
                />
              )}
              {formik.values.role === Role.orgAdmin && (
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          canUser('member-permissions-role:view', member) &&
                          !canUser('member-org-modules-manager-role:update')
                        }
                        checked={formik.values.editModules}
                        onChange={(e, checked) => {
                          formik.setFieldValue('editModules', checked);
                        }}
                      />
                    }
                    label={
                      <Box component="span" display="flex" alignItems="center">
                        {t(
                          'inviteMemberDialog.memberPermissions.orgSettings.editModules'
                        )}
                        <FormControlLabelTooltipIcon
                          title={t(
                            'inviteMemberDialog.memberPermissions.orgSettings.editModulesTooltip'
                          )}
                        />
                      </Box>
                    }
                  />
                </Box>
              )}
              {formik.values.role === Role.orgAdmin && (
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          canUser('member-permissions-role:view', member) &&
                          !canUser('member-org-integration-manager-role:update')
                        }
                        checked={formik.values.editIntegrations}
                        onChange={(e, checked) => {
                          formik.setFieldValue('editIntegrations', checked);
                        }}
                      />
                    }
                    label={
                      <Box component="span" display="flex" alignItems="center">
                        {t(
                          'inviteMemberDialog.memberPermissions.orgSettings.editIntegrations'
                        )}
                        <FormControlLabelTooltipIcon
                          title={t(
                            'inviteMemberDialog.memberPermissions.orgSettings.editIntegrationsTooltip'
                          )}
                        />
                      </Box>
                    }
                  />
                </Box>
              )}
            </Box>
          )}

          {isCardIssuanceSectionVisible && (
            <Box mt={4}>
              <Typography variant="subtitle2">
                {t(
                  'inviteMemberDialog.memberPermissions.cardIssuance.subtitle'
                )}
              </Typography>
              <Box mb={1}>
                <MoneyField
                  {...omit(
                    formik.getFieldProps('maxSpendLimitPerCard'),
                    'onChange'
                  )}
                  onValueChange={({ value }) =>
                    formik.setFieldValue('maxSpendLimitPerCard', value)
                  }
                  isNumericString
                  currency={currency.code}
                  disabled={
                    (canUser('member-permissions-role:view', member) &&
                      !canUser('member-permissions:change')) ||
                    formik.isSubmitting
                  }
                  label={
                    <>
                      {t(
                        'inviteMemberDialog.memberPermissions.cardIssuance.maxLimitPerCard'
                      )}
                      <InputLabelTooltipIcon
                        title={t(
                          'inviteMemberDialog.memberPermissions.cardIssuance.maxLimitPerCardTooltip'
                        )}
                      />
                    </>
                  }
                />
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      (canUser('member-permissions-role:view', member) &&
                        !canUser('member-permissions:change')) ||
                      formik.isSubmitting
                    }
                    checked={formik.values.canCreateCardForSelf}
                    onChange={(e, checked) => {
                      formik.setFieldValue('canCreateCardForSelf', checked);
                    }}
                  />
                }
                label={t(
                  'inviteMemberDialog.memberPermissions.cardIssuance.canCreateCardForSelf'
                )}
              />
            </Box>
          )}

          {isAccountingExportSectionVisible && (
            <Box mt={4}>
              <Typography variant="subtitle2">
                {t('inviteMemberDialog.memberPermissions.accounting.subtitle')}
              </Typography>
              <FormControlLabel
                disabled={
                  canUser('member-permissions-role:view', member) &&
                  !canUser('member-accounting-export-manager-role:update')
                }
                control={
                  <Checkbox
                    checked={formik.values.accountingExport}
                    onChange={(e, checked) => {
                      formik.setFieldValue('accountingExport', checked);
                    }}
                  />
                }
                label={
                  <Box component="span" display="flex" alignItems="center">
                    {t(
                      'inviteMemberDialog.memberPermissions.accounting.accountingExport'
                    )}
                    <FormControlLabelTooltipIcon
                      title={t(
                        'inviteMemberDialog.memberPermissions.accounting.accountingExportTooltip'
                      )}
                    />
                  </Box>
                }
              />
            </Box>
          )}
        </form>
      </DialogContent>
      {canUser('member-permissions-role:update') && (
        <DialogActions>
          {member.roles.includes(Role.legalRep) &&
          !isLegalRepSectionVisible &&
          !isOrgSettingsSectionVisible &&
          !isCardIssuanceSectionVisible &&
          !isAccountingExportSectionVisible &&
          isExternalApp ? (
            <Button onClick={props.onClose}>{t('common.button.close')}</Button>
          ) : (
            <>
              <Button variant="text" onClick={props.onClose}>
                {t('common.button.cancel')}
              </Button>
              <Button
                disabled={isSubmitDisabled}
                form="edit-member-permissions-form"
                type="submit"
              >
                {t('editMemberPermissionsDialog.save')}
              </Button>
            </>
          )}
        </DialogActions>
      )}
      <LoaderWithOverlay loading={state.isLoading || formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(EditMemberRolesAndPermissionsDialog);
