import { intersection } from 'lodash';

/**
 * Return an array of unique values from query parameter in search string that are included in provided list
 */
export const getValidQueryParamValues = <T,>(
  param: string | undefined,
  list: Array<T>
) => intersection<T>(param?.split(',') as T[], list);

/**
 * Return value from query parameter in search string if it is included in provided list
 * otherwise return an empty string
 */
export const getValidQueryParamValue = <T,>(
  param: string | undefined,
  list: Array<T>
) => (list.includes(param as T) ? (param as T) : ('' as const));
