import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import {
  CurrencyEurIcon,
  GlobeHemisphereWestIcon,
  GridColDef,
  StatusBadge,
} from 'elements';
import {
  HistoryCashbackRedemption,
  HistoryCashbackRedemptionStatus,
  HistoryCashbackRedemptionType,
} from 'services/constants';
import ReceiptCell from './ReceiptCell';

const getStatusBadgeColor = (status: HistoryCashbackRedemptionStatus) => {
  if (status === HistoryCashbackRedemptionStatus.ongoing) return 'primary';
  if (status === HistoryCashbackRedemptionStatus.pending) return 'warning';
  if (status === HistoryCashbackRedemptionStatus.paid) return 'success';
};

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<HistoryCashbackRedemption>[] = [
      {
        headerName: t('historyCashbackRedemptionsDialog.date'),
        field: 'payoutDate',
        flex: 120,
        sortable: false,
        valueGetter: ({ row }) =>
          row.payoutDate ? moment(row.payoutDate).format('D MMM YYYY') : '-',
      },
      {
        headerName: t('historyCashbackRedemptionsDialog.amount'),
        field: 'amount',
        flex: 120,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
        renderCell: ({ row }) => (
          <FormatMoney value={row.amount} fractionalPart />
        ),
      },
      {
        headerName: t('historyCashbackRedemptionsDialog.type'),
        field: 'type',
        flex: 120,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) =>
          row.type === HistoryCashbackRedemptionType.payout ? (
            <CurrencyEurIcon
              fontSize="small"
              sx={{ color: 'text.secondary' }}
            />
          ) : (
            <GlobeHemisphereWestIcon
              fontSize="small"
              sx={{ color: 'text.secondary' }}
            />
          ),
      },
      {
        headerName: t('historyCashbackRedemptionsDialog.state'),
        field: 'state',
        flex: 110,
        sortable: false,
        renderCell: ({ row }) => (
          <StatusBadge
            label={t(`historyCashbackRedemptionStatuses.${row.state}`)}
            color={getStatusBadgeColor(row.state)}
          />
        ),
      },
      {
        headerName: t('historyCashbackRedemptionsDialog.receipt'),
        field: 'receiptId',
        flex: 80,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => <ReceiptCell redemption={row} />,
      },
    ];

    return { columns };
  }, [i18n.language]);
};

export default useColumns;
