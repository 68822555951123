import { useState } from 'react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormControlLabelTooltipIcon,
  Grid,
  InputLabel,
  InputLabelTooltipIcon,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CUSTOM_FIELD,
  CustomField,
  CustomFieldStatus,
  customFieldsVisibilityOptions,
  CustomFieldType,
  NetworkErrorCode,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, trimObjValues } from 'services/utils';
import { getNetworkErrorCode } from 'services/utils/network';

interface State {
  confirmation: 'deactivate' | 'delete' | null;
  isUpdating: boolean;
}

interface Props extends DialogProps {
  customField: CustomField;
  onClose: () => void;
  onSuccess: (updatedCustomField?: CustomField) => void;
}

const EditCustomFieldDialog = ({ customField, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    confirmation: null,
    isUpdating: false,
  });

  const onUpdate = async (values: CustomField) => {
    try {
      const updatedCustomField = await api.updateCustomField(
        customField.id,
        omit(values, 'id')
      );
      onSuccess(updatedCustomField);
    } catch (error) {
      if (!mounted.current) return;
      formik.setSubmitting(false);
      setState((prevState) => ({
        ...prevState,
        confirmation: null,
        isUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const onDelete = async () => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      await api.deleteCustomField(customField.id);
      onSuccess();
      if (!mounted.current) return;
      enqueueSnackbar(
        t('accountingGeneralSubpage.customFieldDeletionDialog.successMsg')
      );
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isUpdating: false }));

      if (
        getNetworkErrorCode(error) ===
        NetworkErrorCode.transactionCustomFieldIsActive
      ) {
        props.onClose();
        enqueueSnackbar(
          t(
            'accountingGeneralSubpage.customFieldDeletionDialog.fieldIsActiveError'
          ),
          {
            variant: 'error',
          }
        );
        return;
      }

      if (
        getNetworkErrorCode(error) ===
        NetworkErrorCode.transactionCustomFieldInUse
      ) {
        props.onClose();
        enqueueSnackbar(
          t(
            'accountingGeneralSubpage.customFieldDeletionDialog.fieldInUseError'
          ),
          {
            variant: 'error',
          }
        );
        return;
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      label: customField.label || '',
      defaultValue:
        customField.type === CustomFieldType.text
          ? customField.defaultValue || ''
          : customField.defaultValue,
      description: customField.description || '',
      mandatoryOnAutomation: customField.mandatoryOnAutomation,
      mandatoryForExport: customField.mandatoryForExport,
      visibility: customField.visibility,
    },
    onSubmit: async (values) => {
      await onUpdate({ ...customField, ...trimObjValues(values) });
    },
  });

  if (state.confirmation === 'deactivate') {
    return (
      <ConfirmDialog
        {...props}
        title={t(
          'accountingGeneralSubpage.customFieldDeactivationDialog.title'
        )}
        description={
          <Trans
            i18nKey="accountingGeneralSubpage.customFieldDeactivationDialog.description"
            components={{ br: <br /> }}
          />
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          color: 'error',
          children: t('common.button.deactivate'),
        }}
        onSuccess={() => {
          setState((prevState) => ({ ...prevState, isUpdating: true }));
          onUpdate({
            ...customField,
            status: CustomFieldStatus.inactive,
            mandatoryForExport: false,
            mandatoryOnAutomation: false,
          });
        }}
        onClose={() =>
          setState((prevState) => ({ ...prevState, confirmation: null }))
        }
      />
    );
  }

  if (state.confirmation === 'delete') {
    return (
      <ConfirmDialog
        {...props}
        title={t('accountingGeneralSubpage.customFieldDeletionDialog.title')}
        description={
          <Trans
            i18nKey="accountingGeneralSubpage.customFieldDeletionDialog.description"
            components={{ br: <br /> }}
          />
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          color: 'error',
          children: t('common.button.delete'),
        }}
        onSuccess={onDelete}
        onClose={() =>
          setState((prevState) => ({ ...prevState, confirmation: null }))
        }
      />
    );
  }

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle display="flex" alignItems="center">
        {t('accountingGeneralSubpage.editCustomFieldDialog.title')}
        {customField.status === CustomFieldStatus.inactive && (
          <Box ml={1} fontSize={0}>
            <Chip
              size="small"
              label={t(
                'accountingGeneralSubpage.editCustomFieldDialog.inactiveBadge'
              )}
              variant="outlined"
            />
          </Box>
        )}
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="edit-custom-field-form"
          noValidate
        >
          {customField.status === CustomFieldStatus.inactive && (
            <Typography variant="body2" mb={3}>
              <Trans
                i18nKey="accountingGeneralSubpage.editCustomFieldDialog.description"
                components={{
                  br: <br />,
                }}
              />
            </Typography>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t(
                  'accountingGeneralSubpage.editCustomFieldDialog.labelLabel'
                )}
                value={customField.label}
                disabled
              />
            </Grid>

            {customField.type === CustomFieldType.text &&
              typeof formik.values.defaultValue === 'string' && (
                <Grid item xs={12}>
                  <TextField
                    label={
                      <>
                        {t(
                          'accountingGeneralSubpage.editCustomFieldDialog.defaultValueLabel'
                        )}
                        <InputLabelTooltipIcon
                          title={t(
                            'accountingGeneralSubpage.editCustomFieldDialog.defaultValueLabelTooltip'
                          )}
                        />
                      </>
                    }
                    {...formik.getFieldProps('defaultValue')}
                    disabled={formik.isSubmitting}
                    inputProps={{
                      maxLength: CUSTOM_FIELD.valueMaxLength,
                    }}
                    multiline
                    maxRows={2}
                    helperText={
                      formik.values.defaultValue.length >
                        CUSTOM_FIELD.valueMaxLength - 10 &&
                      t('errors.maxCharacters', {
                        count: formik.values.defaultValue.length,
                        max: CUSTOM_FIELD.valueMaxLength,
                      })
                    }
                  />
                </Grid>
              )}

            <Grid item xs={12}>
              <TextField
                label={t(
                  'accountingGeneralSubpage.editCustomFieldDialog.descriptionLabel'
                )}
                {...formik.getFieldProps('description')}
                disabled={formik.isSubmitting}
                inputProps={{
                  maxLength: CUSTOM_FIELD.descriptionMaxLength,
                }}
                multiline
                maxRows={2}
                helperText={
                  formik.values.description.length >
                    CUSTOM_FIELD.descriptionMaxLength - 10 &&
                  t('errors.maxCharacters', {
                    count: formik.values.description.length,
                    max: CUSTOM_FIELD.descriptionMaxLength,
                  })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>
                  {t(
                    'accountingGeneralSubpage.editCustomFieldDialog.visibilityLabel'
                  )}
                  <InputLabelTooltipIcon
                    title={t(
                      'accountingGeneralSubpage.editCustomFieldDialog.visibilityLabelTooltip'
                    )}
                  />
                </InputLabel>
                <Select
                  {...formik.getFieldProps('visibility')}
                  renderValue={(value) => t(`customFieldsVisibility.${value}`)}
                >
                  {customFieldsVisibilityOptions.map((item) => (
                    <MenuItem key={item} value={item}>
                      {t(`customFieldsVisibility.${item}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                name="mandatoryOnAutomation"
                checked={formik.values.mandatoryOnAutomation}
                disabled={formik.isSubmitting}
                onChange={(_, checked) =>
                  formik.setFieldValue('mandatoryOnAutomation', checked)
                }
                control={<Checkbox />}
                label={
                  <Box
                    display="inline-flex"
                    alignItems="center"
                    component="span"
                  >
                    {t(
                      'accountingGeneralSubpage.editCustomFieldDialog.mandatoryOnAutomationLabel'
                    )}
                    <FormControlLabelTooltipIcon
                      title={t(
                        'accountingGeneralSubpage.editCustomFieldDialog.mandatoryOnAutomationLabelTooltip'
                      )}
                    />
                  </Box>
                }
              />

              <FormControlLabel
                name="mandatoryForExport"
                checked={formik.values.mandatoryForExport}
                disabled={formik.isSubmitting}
                onChange={(_, checked) =>
                  formik.setFieldValue('mandatoryForExport', checked)
                }
                control={<Checkbox />}
                label={
                  <Box
                    display="inline-flex"
                    alignItems="center"
                    component="span"
                  >
                    {t(
                      'accountingGeneralSubpage.editCustomFieldDialog.mandatoryForExportLabel'
                    )}
                    <FormControlLabelTooltipIcon
                      title={t(
                        'accountingGeneralSubpage.editCustomFieldDialog.mandatoryForExportLabelTooltip'
                      )}
                    />
                  </Box>
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        {customField.status === CustomFieldStatus.active ? (
          <Button
            variant="text"
            color="error"
            sx={{ mr: 'auto' }}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                confirmation: 'deactivate',
              }))
            }
          >
            {t('common.button.deactivate')}
          </Button>
        ) : (
          <Button
            variant="text"
            color="error"
            sx={{ mr: 'auto' }}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                confirmation: 'delete',
              }))
            }
          >
            {t('common.button.delete')}
          </Button>
        )}

        <Button variant="text" onClick={props.onClose}>
          {t('common.button.close')}
        </Button>

        {customField.status === CustomFieldStatus.active || formik.dirty ? (
          <Button
            disabled={formik.isSubmitting || !formik.dirty}
            type="submit"
            form="edit-custom-field-form"
          >
            {t('common.button.save')}
          </Button>
        ) : (
          <Button
            disabled={formik.isSubmitting}
            onClick={() =>
              onUpdate({
                ...customField,
                status: CustomFieldStatus.active,
              })
            }
          >
            {t('common.button.activate')}
          </Button>
        )}
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(EditCustomFieldDialog);
