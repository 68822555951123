import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { InternalBlock } from 'domains/settings/components';
import {
  Box,
  FormControl,
  FormControlLabel,
  QuestionIcon,
  Switch,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer } from 'layout';
import {
  AccountingSystem,
  AccountingSystemIntegrationMethod,
  ExportFormat,
  ReceiptDateExportMode,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import useCanUser from 'services/rbac/useCanUser';
import { getGenericErrorMsg } from 'services/utils';
import AccountingSystemGroup from './AccountingSystemGroup';

interface State {
  useAccountingExport: boolean;
  isUseAccountingExportUpdating: boolean;
  useSupplierAccount: boolean;
  isUseSupplierAccountUpdating: boolean;
}

const InternalAccountingSettings = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, accountingSettings },
    dispatch,
  } = useGlobalState();
  const [state, setState] = useState<State>({
    useAccountingExport: accountingSettings!.useAccountingExport,
    isUseAccountingExportUpdating: false,
    useSupplierAccount: accountingSettings!.useSupplierAccount,
    isUseSupplierAccountUpdating: false,
  });

  const isUseSupplierAccountSwitchVisible =
    canUser('use-supplier-account:change') &&
    accountingSettings!.accountingSystemIntegrationMethod !==
      AccountingSystemIntegrationMethod.codat;

  // when changing accounting system - we need to update state
  // according to Datev/BMD/etc specific settings
  useEffect(() => {
    if (accountingSettings!.useAccountingExport !== state.useAccountingExport) {
      setState((prevState) => ({
        ...prevState,
        useAccountingExport: accountingSettings!.useAccountingExport,
      }));
    }
  }, [accountingSettings!.useAccountingExport]);

  useEffect(() => {
    if (accountingSettings!.useSupplierAccount !== state.useSupplierAccount) {
      setState((prevState) => ({
        ...prevState,
        useSupplierAccount: accountingSettings!.useSupplierAccount,
      }));
    }
  }, [accountingSettings!.useSupplierAccount]);

  if (
    !canUser('accounting-system:view') &&
    !canUser('use-supplier-account:change')
  )
    return null;

  const updateSetting = async (useAccountingExport: boolean) => {
    if (state.isUseAccountingExportUpdating) return;
    try {
      setState((prevState) => ({
        ...prevState,
        isUseAccountingExportUpdating: true,
        useAccountingExport,
      }));
      const data = await api.updateAccountingSettings({
        organizationId: organization!.id,
        vatRateEnabled: accountingSettings!.vatRateEnabled,
        subcategoryEnabled: accountingSettings!.subcategoryEnabled,
        projectEnabled: accountingSettings!.projectEnabled,
        supplierEnabled: accountingSettings!.supplierEnabled,
        costCenterEnabled: accountingSettings!.costCenterEnabled,
        commentEnabled: accountingSettings!.commentEnabled,
        useAccountingExport,
      });
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { accountingSettings: data },
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        useAccountingExport: data.useAccountingExport,
        isUseAccountingExportUpdating: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isUseAccountingExportUpdating: false,
        useAccountingExport: accountingSettings!.useAccountingExport,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const changeUseSupplierAccountVal = async (useSupplierAccount: boolean) => {
    if (state.isUseSupplierAccountUpdating) return;
    try {
      setState((prevState) => ({
        ...prevState,
        isUseSupplierAccountUpdating: true,
        useSupplierAccount,
      }));

      const defaultSupplierAccount =
        useSupplierAccount &&
        accountingSettings!.generalLedgerAccountLength &&
        accountingSettings!.defaultSupplierAccount &&
        accountingSettings!.defaultSupplierAccount.length !==
          accountingSettings!.generalLedgerAccountLength + 1
          ? null
          : accountingSettings!.defaultSupplierAccount;

      const updatedAccountingSettings = await api.updateAccountingGeneralAccounts(
        {
          organizationId: organization!.id,
          pliantCreditCardAccount: accountingSettings!.pliantCreditCardAccount,
          defaultSupplierAccount,
          cashInTransitAccount: accountingSettings!.cashInTransitAccount,
          useSupplierAccount,
        }
      );
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { accountingSettings: updatedAccountingSettings },
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isUseSupplierAccountUpdating: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        useSupplierAccount: accountingSettings!.useSupplierAccount,
        isUseSupplierAccountUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <ContentContainer>
      <InternalBlock sx={{ mb: 4 }}>
        {canUser('accounting-system:view') && <AccountingSystemGroup />}

        {![AccountingSystem.other, null].includes(
          accountingSettings!.accountingSystem
        ) && (
          <FormControl fullWidth sx={{ mb: 1, label: { maxWidth: '100%' } }}>
            <FormControlLabel
              label={
                <Typography variant="body2">
                  {t(
                    'int.accountingGeneralSubpage.internalAccountingSettings.useExportLabel',
                    { name: accountingSettings?.accountingSystemName }
                  )}
                </Typography>
              }
              labelPlacement="start"
              name="useAccountingExport"
              disabled={!canUser('accounting-settings:update')}
              checked={state.useAccountingExport}
              onChange={(e, checked) => {
                updateSetting(checked);
              }}
              control={<Switch />}
            />
          </FormControl>
        )}

        {accountingSettings?.accountingSystem === AccountingSystem.bmd && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            height={(theme) => theme.spacing(5)}
          >
            <Typography variant="body2">
              {t('int.exportSettingsGroup.exportTypesLabel')}
            </Typography>
            <Typography variant="body2" pr={1}>
              {ExportFormat.csv}
            </Typography>
          </Box>
        )}

        {isUseSupplierAccountSwitchVisible && (
          <FormControl fullWidth sx={{ label: { maxWidth: '100%' } }}>
            <FormControlLabel
              label={
                <Box display="flex" alignItems="center">
                  <Typography mr={1} variant="body2">
                    {t('int.exportSettingsGroup.useSupplierAccount')}
                  </Typography>
                  <Tooltip
                    title={
                      accountingSettings!.receiptDateExportMode ===
                      ReceiptDateExportMode.dynamic
                        ? t(
                            'int.exportSettingsGroup.useSupplierAccountChangeSettingTooltip'
                          )
                        : t('int.exportSettingsGroup.useSupplierAccountTitle')
                    }
                  >
                    <QuestionIcon fontSize="small" />
                  </Tooltip>
                </Box>
              }
              disabled={
                accountingSettings!.receiptDateExportMode ===
                ReceiptDateExportMode.dynamic
              }
              labelPlacement="start"
              name="useSupplierAccount"
              checked={state.useSupplierAccount}
              onChange={(e, checked) => changeUseSupplierAccountVal(checked)}
              control={<Switch />}
            />
          </FormControl>
        )}
      </InternalBlock>
    </ContentContainer>
  );
};

export default InternalAccountingSettings;
