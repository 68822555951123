import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  EditButton,
  Link,
  QuestionIcon,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from 'elements';
import useUrls from 'hooks/useUrls';
import { ContentContainer } from 'layout/Page';
import { AccountingSystem } from 'services/constants';
import { useCanUser } from 'services/rbac';
import ExportSettingsDialog from './ExportSettingsDialog';

const ExportSettingsGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const { HELP_CENTER_RECEIPT_DATE_IN_EXPORT_URL } = useUrls();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (
    accountingSettings!.accountingSystem !== AccountingSystem.bmd ||
    !accountingSettings!.useAccountingExport
  )
    return null;

  return (
    <>
      <ContentContainer mt={2} my={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>
                {t('accountingGeneralSubpage.exportSettingsGroup.title')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableRow>
            <TableCell>
              <Box display="flex" alignItems="center">
                {t(
                  'accountingGeneralSubpage.exportSettingsGroup.receiptDateExport'
                )}
                <Tooltip
                  title={
                    <Trans
                      i18nKey="accountingGeneralSubpage.exportSettingsGroup.receiptDateExportTooltip"
                      components={{
                        linkTo: (
                          <Link
                            href={HELP_CENTER_RECEIPT_DATE_IN_EXPORT_URL}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="inherit"
                          />
                        ),
                      }}
                    />
                  }
                >
                  <QuestionIcon fontSize="small" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell>
              {t(
                `accountingGeneralSubpage.exportSettingsGroup.receiptDateExportMode.${
                  accountingSettings!.receiptDateExportMode
                }`
              )}
            </TableCell>
            <TableCell width="72" align="right">
              {canUser('accounting-settings:update') && (
                <EditButton onClick={() => setIsDialogOpen(true)} />
              )}
            </TableCell>
          </TableRow>
        </Table>
      </ContentContainer>

      <ExportSettingsDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default ExportSettingsGroup;
