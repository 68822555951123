import { useEffect, useRef, useState } from 'react';
import { Box } from 'elements';

interface Props {
  data: string;
  scale: number;
}

interface State {
  initialHeight: number;
  initialWidth: number;
  isLoaded: boolean;
}

const ImagePreview = ({ data, scale }: Props) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [state, setState] = useState<State>({
    initialHeight: 0,
    initialWidth: 0,
    isLoaded: false,
  });

  useEffect(() => {
    return () => {
      imgRef.current = null;
    };
  }, []);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      initialHeight: 0,
      initialWidth: 0,
      isLoaded: false,
    }));
  }, [data]);

  const scaledHeight = parseFloat((state.initialHeight * scale).toFixed(2));
  const scaledWidth = parseFloat((state.initialWidth * scale).toFixed(2));

  return (
    <Box height="100%" width="100%" overflow="auto" display="flex">
      <img
        ref={imgRef}
        onLoad={() =>
          setState((prevState) => ({
            ...prevState,
            initialHeight: imgRef?.current?.clientHeight || 0,
            initialWidth: imgRef?.current?.clientWidth || 0,
            isLoaded: true,
          }))
        }
        style={{
          margin: 'auto',
          height: scaledHeight || 'auto',
          width: scaledWidth || 'auto',
        }}
        alt="Preview"
        src={data}
        data-test-id="preview-img"
      />
    </Box>
  );
};

export default ImagePreview;
