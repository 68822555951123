import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NothingFound from 'components/NothingFound';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import NestedTrackingCategoryItems from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/NestedTrackingCategoryItems';
import { getTrackingCategoriesFilterItems } from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/utils';
import {
  ArrowsClockwiseIcon,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withDialogWrapper,
} from 'elements';
import useOptionsSync from './useOptionsSync';

interface Props extends DialogProps {
  onSuccess: () => void;
  onClose: () => void;
}

const CodatSyncDropdownOptionsDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const {
    fetchedTrackingCategories,
    filterTrackingCategories,
    shownCategoriesList,
    shownCategoriesIds,
    selectedCategoryId,
    categoryOptionHashMap,
    selectedIds,
    isLoading,
    isInnerLoading,
    isError,
    getData,
    onCategoryChange,
    onOptionChange,
    onSelect,
    onSave,
    fetchCodatData,
  } = useOptionsSync();

  const filterCategoriesArray = useMemo(
    () =>
      filterTrackingCategories?.length
        ? getTrackingCategoriesFilterItems(filterTrackingCategories)
        : [],
    [filterTrackingCategories]
  );

  return (
    <Dialog
      {...props}
      maxWidth="md"
      PaperProps={{
        sx: {
          height: 800,
        },
      }}
    >
      <DialogTitle>
        {t('codatSyncSetupDialog.dropdownOptions.title', {
          name: accountingSettings!.accountingSystemName,
        })}
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      >
        {!!fetchedTrackingCategories?.length && (
          <>
            <Box my={3} maxWidth={240}>
              <FormControl fullWidth>
                <Select
                  value={selectedCategoryId}
                  renderValue={(selected) => {
                    if (!selected) return '';
                    if (selected === 'all')
                      return t(
                        'codatSyncSetupDialog.dropdownOptions.allEntries'
                      );
                    if (selected === 'all_top_level')
                      return t(
                        'codatSyncSetupDialog.dropdownOptions.allEntriesTopLevelOption'
                      );
                    return (
                      fetchedTrackingCategories!.find(
                        (item) => item.id === selected
                      )?.name || ''
                    );
                  }}
                  onChange={(e) => onCategoryChange(e.target.value)}
                >
                  <MenuItem value="all">
                    {t('codatSyncSetupDialog.dropdownOptions.allEntries')}
                  </MenuItem>
                  <MenuItem
                    value={'all_top_level'}
                    sx={{
                      borderBottom: (theme) =>
                        filterCategoriesArray.length
                          ? `1px solid ${theme.palette.divider}}`
                          : 0,
                    }}
                  >
                    {t(
                      'codatSyncSetupDialog.dropdownOptions.allEntriesTopLevelOption'
                    )}
                  </MenuItem>
                  {filterCategoriesArray}
                </Select>
              </FormControl>
            </Box>

            <Box flex={1} overflow="hidden" position="relative">
              <TableContainer sx={{ height: '100%', overflowY: 'auto' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedIds.length > 0 &&
                            selectedIds.length < shownCategoriesIds.length
                          }
                          checked={
                            shownCategoriesIds.length > 0 &&
                            selectedIds.length === shownCategoriesIds.length
                          }
                          onChange={() =>
                            selectedIds.length
                              ? onSelect([])
                              : onSelect(shownCategoriesIds)
                          }
                          inputProps={{
                            'aria-label': 'select all',
                          }}
                          disabled={!shownCategoriesIds.length}
                        />
                      </TableCell>
                      <TableCell sx={{ pl: 0 }}>
                        {t('codatSyncSetupDialog.dropdownOptions.valuesLabel')}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {t(
                          'codatSyncSetupDialog.dropdownOptions.existingValuesLabel'
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {shownCategoriesList?.length ? (
                      <NestedTrackingCategoryItems
                        type="customFieldOption"
                        isPartialFlow
                        selectedIds={selectedIds}
                        trackingCategories={shownCategoriesList}
                        categoryHashMap={categoryOptionHashMap}
                        onChange={onOptionChange}
                        onSelect={onSelect}
                      />
                    ) : (
                      <NothingFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}

        {isError && !isLoading && (
          <Box margin="auto">
            <WidgetError onReload={getData} />
          </Box>
        )}

        {!isError && !isLoading && !fetchedTrackingCategories?.length && (
          <NothingFound />
        )}

        <LoaderWithOverlay loading={isInnerLoading || isLoading} />
      </DialogContent>

      <DialogActions>
        <Box mr="auto">
          <Button
            variant="text"
            onClick={props.onClose}
            disabled={isInnerLoading || isLoading}
          >
            {t('common.button.close')}
          </Button>

          <Button
            variant="text"
            startIcon={<ArrowsClockwiseIcon />}
            onClick={fetchCodatData}
            disabled={isInnerLoading || isLoading}
          >
            {t('codatSyncSetupDialog.syncNowButton')}
          </Button>
        </Box>

        {!!shownCategoriesList?.length && (
          <Button
            onClick={() => onSave(onSuccess)}
            disabled={isInnerLoading || isLoading || selectedIds.length === 0}
          >
            {t('common.button.save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withDialogWrapper(CodatSyncDropdownOptionsDialog);
