import { MouseEvent, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState, useManagedTeams } from 'context/GlobalState';
import {
  FlagTransactionDialog,
  MarkAsResolvedTransactionDialog,
} from 'domains/transaction/dialogs';
import { useUpdateTransactionsCounters } from 'domains/transaction/hooks';
import { isTxFlaggingFrozenByExport } from 'domains/transaction/utils';
import {
  ArrowCircleDownFilledIcon,
  ArrowsCounterClockwiseIcon,
  CheckCircleIcon,
  Chip,
  ChipProps,
  FlagPennantIcon,
  ListItemIcon,
  MenuContainer,
  MenuItem,
  ThumbsUpIcon,
  Tooltip,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import {
  PrivateExpenseStatus,
  ReviewFlagReason,
  Transaction,
  TransactionReviewStatus,
  TransactionSimpleType,
  TransactionStatus,
} from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  transaction: Transaction;
  onResetReview: () => void;
  onUpdate?: (reviewStatus: TransactionReviewStatus) => void;
}

const TransactionReviewDropdown = ({
  transaction,
  onResetReview,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { isPortalApp } = useCurrentApp();
  const {
    state: { featureModules },
  } = useGlobalState();
  const managedTeams = useManagedTeams();
  const managedTeamsIds = useMemo(() => managedTeams.map((item) => item.id), [
    managedTeams,
  ]);
  const updateTransactionsCounters = useUpdateTransactionsCounters();
  const [
    isFlagTransactionDialogOpen,
    setIsFlagTransactionDialogOpen,
  ] = useState(false);
  const [
    isMarkAsResolvedTransactionDialogOpen,
    setIsMarkAsResolvedTransactionDialogOpen,
  ] = useState(false);
  const {
    transactionId,
    reviewStatus,
    reviewFlagReason,
    privateExpenseStatus,
    reviewedAt,
    reviewComment,
    reviewerFullName,
    hasMultipleAccountingTransactions,
    memberId: transactionMemberId,
  } = transaction;

  const isApproved = reviewStatus === TransactionReviewStatus.approved;

  if (
    ![TransactionStatus.pending, TransactionStatus.confirmed].includes(
      transaction.status
    ) ||
    transaction.simpleType !== TransactionSimpleType.purchase
  ) {
    return null;
  }

  if (
    !isPortalApp &&
    ((!featureModules.MANAGER_TX_REVIEWS &&
      (reviewStatus === TransactionReviewStatus.needsReview ||
        !reviewStatus)) ||
      (featureModules.MANAGER_TX_REVIEWS &&
        ((!reviewStatus && canUser('transaction-review:flag', transaction)) ||
          (reviewStatus === TransactionReviewStatus.needsReview &&
            canUser('transaction-review:flag', transaction) &&
            !canUser(
              'transaction-review:approve',
              transaction,
              managedTeamsIds
            )))))
  ) {
    return (
      <>
        <Chip
          label={t('transactionDetailsHeader.flagTransaction')}
          size="small"
          icon={<FlagPennantIcon />}
          onClick={() => setIsFlagTransactionDialogOpen(true)}
          sx={{
            flexDirection: 'row-reverse',
            '.MuiSvgIcon-root': { mr: 0.5, ml: -0.5 },
          }}
        />
        <FlagTransactionDialog
          open={isFlagTransactionDialogOpen}
          isTxFrozenByExport={isTxFlaggingFrozenByExport(transaction)}
          transactionIds={[transactionId]}
          transactionMemberId={transactionMemberId}
          hasMultipleAccountingTransactions={hasMultipleAccountingTransactions}
          onClose={() => setIsFlagTransactionDialogOpen(false)}
          onSuccess={() => {
            updateTransactionsCounters();
            onUpdate?.(TransactionReviewStatus.flagged);
            setIsFlagTransactionDialogOpen(false);
          }}
        />
      </>
    );
  }

  if (reviewStatus === TransactionReviewStatus.needsReview || !reviewStatus)
    return null;

  const getLabel = () => {
    if (reviewStatus === TransactionReviewStatus.flagged && reviewFlagReason)
      return t(`reviewFlagReasons.${reviewFlagReason}`);

    return t(`transactionReviewStatus.${reviewStatus}`);
  };
  const getColor = (): ChipProps['color'] => {
    if (reviewStatus === TransactionReviewStatus.flagged) return 'error';
    if (reviewStatus === TransactionReviewStatus.resolved) return 'success';
    if (reviewStatus === TransactionReviewStatus.approved) return 'success';

    return undefined;
  };
  const getIcon = () => {
    if (reviewStatus === TransactionReviewStatus.flagged)
      return <FlagPennantIcon />;
    if (reviewStatus === TransactionReviewStatus.resolved)
      return <CheckCircleIcon />;
    if (reviewStatus === TransactionReviewStatus.approved)
      return <CheckCircleIcon />;
  };

  if (
    reviewFlagReason === ReviewFlagReason.privateExpense &&
    (reviewStatus === TransactionReviewStatus.resolved ||
      (reviewStatus === TransactionReviewStatus.flagged &&
        privateExpenseStatus === PrivateExpenseStatus.reimbursementPending))
  ) {
    return (
      <Chip
        label={getLabel()}
        size="small"
        color={getColor()}
        icon={getIcon()}
      />
    );
  }

  const renderMenuItems = () => {
    if (!canUser('transaction-review:resolve', transaction)) return [];

    if (
      reviewStatus === TransactionReviewStatus.flagged &&
      reviewFlagReason === ReviewFlagReason.privateExpense &&
      isTxFlaggingFrozenByExport(transaction)
    ) {
      return [
        <MenuItem
          key="markAsResolved"
          onClick={() => setIsMarkAsResolvedTransactionDialogOpen(true)}
        >
          <ListItemIcon>
            <ThumbsUpIcon />
          </ListItemIcon>
          {t('transactionDetailsHeader.markAsResolved')}
        </MenuItem>,
      ];
    }

    if (reviewStatus === TransactionReviewStatus.flagged) {
      return [
        <MenuItem
          key="markAsResolved"
          onClick={() => setIsMarkAsResolvedTransactionDialogOpen(true)}
        >
          <ListItemIcon>
            <ThumbsUpIcon />
          </ListItemIcon>
          {t('transactionDetailsHeader.markAsResolved')}
        </MenuItem>,
        <MenuItem
          key="changeFlagReason"
          onClick={() => setIsFlagTransactionDialogOpen(true)}
        >
          <ListItemIcon>
            <FlagPennantIcon />
          </ListItemIcon>
          {t('transactionDetailsHeader.changeFlagReason')}
        </MenuItem>,
        <MenuItem key="reset" onClick={() => onResetReview()}>
          <ListItemIcon>
            <ArrowsCounterClockwiseIcon />
          </ListItemIcon>
          {t('transactionDetailsHeader.resetReview')}
        </MenuItem>,
      ];
    }

    return [
      <MenuItem key="reset" onClick={() => onResetReview()}>
        <ListItemIcon>
          <ArrowsCounterClockwiseIcon />
        </ListItemIcon>
        {t('transactionDetailsHeader.resetReview')}
      </MenuItem>,
    ];
  };

  const menuItems = renderMenuItems();
  const isDisabled = !menuItems.length;

  return (
    <>
      <MenuContainer
        button={(openMenu: (e: MouseEvent<HTMLElement>) => void) => (
          <Tooltip
            title={
              isApproved && reviewedAt
                ? t('transactionDetailsHeader.approvedTooltip', {
                    date: moment(reviewedAt).format('DD.MM.YYYY'),
                    name: reviewerFullName,
                  })
                : ''
            }
            placement="bottom-end"
          >
            <Chip
              label={getLabel()}
              size="small"
              color={getColor()}
              icon={getIcon()}
              onClick={isDisabled ? undefined : openMenu}
              deleteIcon={<ArrowCircleDownFilledIcon />}
              onDelete={isDisabled ? undefined : openMenu}
              sx={{ '.MuiChip-deleteIcon': { pointerEvents: 'none' } }}
            />
          </Tooltip>
        )}
      >
        {menuItems}
      </MenuContainer>
      <FlagTransactionDialog
        open={isFlagTransactionDialogOpen}
        isTxFrozenByExport={isTxFlaggingFrozenByExport(transaction)}
        transactionIds={[transactionId]}
        transactionMemberId={transactionMemberId}
        reviewFlagReason={reviewFlagReason!}
        reviewComment={reviewComment}
        hasMultipleAccountingTransactions={hasMultipleAccountingTransactions}
        onClose={() => setIsFlagTransactionDialogOpen(false)}
        onSuccess={() => {
          updateTransactionsCounters();
          onUpdate?.(TransactionReviewStatus.flagged);
          setIsFlagTransactionDialogOpen(false);
        }}
      />
      <MarkAsResolvedTransactionDialog
        open={isMarkAsResolvedTransactionDialogOpen}
        transactionIds={[transactionId]}
        onClose={() => setIsMarkAsResolvedTransactionDialogOpen(false)}
        onSuccess={() => {
          updateTransactionsCounters();
          onUpdate?.(TransactionReviewStatus.resolved);
          setIsMarkAsResolvedTransactionDialogOpen(false);
        }}
      />
    </>
  );
};

export default TransactionReviewDropdown;
