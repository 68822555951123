import { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';

interface Props extends DialogProps {
  title: string;
  description: ReactNode;
  onClose: (e: MouseEvent<HTMLButtonElement>) => void;
  onSuccess: (e: MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  cancelButtonProps?: Omit<ButtonProps, 'onClick'>;
  confirmButtonProps?: Omit<ButtonProps, 'onClick' | 'disabled'>;
}

const ConfirmDialog = ({
  title,
  description,
  onSuccess,
  loading,
  cancelButtonProps,
  confirmButtonProps,
  ...props
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography component="div">{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="text"
          children={t('common.button.cancel')}
          {...cancelButtonProps}
        />
        <Button
          onClick={onSuccess}
          disabled={loading}
          children={t('common.button.confirm')}
          {...confirmButtonProps}
        />
      </DialogActions>
      <LoaderWithOverlay loading={!!loading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(ConfirmDialog);
