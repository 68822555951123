import { memo } from 'react';
import { Avatar, AvatarGroup, Tooltip, Typography } from 'elements';
import { TeamMember } from 'services/constants';

interface Props {
  areManagers?: boolean;
  members: TeamMember[];
  maxItems: number;
}

const TeamMemberList = ({ areManagers = false, members, maxItems }: Props) => {
  return members.length === 0 ? (
    <Typography variant="caption" component="div" py="6px">
      -
    </Typography>
  ) : (
    <AvatarGroup
      total={members.length}
      sx={{ justifyContent: 'flex-end', py: 0.25 }}
      slotProps={{
        additionalAvatar: {
          sx: {
            width: 24,
            height: 24,
            fontSize: 10,
          },
        },
      }}
    >
      {members.slice(0, maxItems).map((item) => {
        const label = (
          item.firstName.substr(0, 1) + item.lastName.substr(0, 1)
        ).toUpperCase();

        return (
          <Tooltip
            key={item.memberId}
            title={`${item.firstName} ${item.lastName}`}
          >
            <Avatar
              sx={(theme) => ({
                width: 24,
                height: 24,
                ...(areManagers && {
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }),
                fontSize: theme.typography.caption.fontSize,
              })}
            >
              {label}
            </Avatar>
          </Tooltip>
        );
      })}
    </AvatarGroup>
  );
};

export default memo(TeamMemberList);
