import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { Button, EnvelopeSimpleIcon, Typography } from 'elements';
import { Content, Overlay } from './style';

const EmailNotVerifiedError = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();

  return (
    <Overlay>
      <Content>
        <EnvelopeSimpleIcon
          fontSize="large"
          sx={{ verticalAlign: 'top', mb: 1, color: 'text.secondary' }}
        />
        <Typography variant="h6" mb={1}>
          {t('errorOverlay.emailNotVerifiedError.heading')}
        </Typography>
        <Typography color="text.secondary" mb={2}>
          {t('errorOverlay.emailNotVerifiedError.subheading')}
        </Typography>
        <Button
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
          size="large"
        >
          {t('errorOverlay.emailNotVerifiedError.buttonText')}
        </Button>
      </Content>
    </Overlay>
  );
};

export default EmailNotVerifiedError;
