import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, PlusIcon, XIcon } from 'elements';
import {
  CardCategoryControl,
  DEFAULT_AUTOCOMPLETE_DELAY,
  MccCategory,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { StyledAutocomplete } from './style';

interface Props {
  disabled: boolean;
  mccGroup: string;
  control: CardCategoryControl;
  onChange: (control: CardCategoryControl) => void;
}

interface State {
  mccInput: string;
  isOpen: boolean;
  isLoadingMccs: boolean;
  mccOptions: MccCategory[];
  preselectedMccs: MccCategory[];
}

const MIN_ALLOWED_CHARS = 3;

const MccCategories = ({
  disabled,
  mccGroup,
  control: { restriction, type, values, displayValues },
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();

  const [state, setState] = useState<State>({
    mccInput: '',
    isOpen: false,
    isLoadingMccs: false,
    mccOptions: [],
    preselectedMccs: [],
  });

  useEffect(() => {
    let active = true;

    if (state.mccInput.length < MIN_ALLOWED_CHARS) return;

    setState((prevState) => ({ ...prevState, isLoadingMccs: true }));

    setTimeout(() => {
      if (active) getMccCategoryCode();
    }, DEFAULT_AUTOCOMPLETE_DELAY);

    return () => {
      active = false;
    };
  }, [state.mccInput.trim()]);

  const getMccCategoryCode = async () => {
    try {
      const { mccList } = await api.getMerchantCategoryCodeSearch(
        mccGroup,
        state.mccInput
      );
      setState((prevState) => ({
        ...prevState,
        mccOptions: mccList.map(({ mcc, mccName }: MccCategory) => ({
          mcc,
          mccName,
        })),
        isLoadingMccs: false,
      }));
    } catch (error) {
      logError(error);
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1} mr={1}>
        <StyledAutocomplete<MccCategory, true, false, false>
          data-test-id="mcc-autocomplete"
          sx={{ backgroundColor: 'transparent' }}
          multiple
          disabled={disabled}
          placeholder={t('cardControlFormElement.category.selectMCCs')}
          noOptionsText={t('common.nothingFound')}
          loadingText={t('common.loading')}
          searchIcon={false}
          disableCloseOnSelect
          loading={state.isLoadingMccs}
          options={state.mccOptions}
          open={state.isOpen}
          onOpen={() =>
            setState((prevState) => ({
              ...prevState,
              isOpen: prevState.mccInput.length >= MIN_ALLOWED_CHARS,
            }))
          }
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isOpen: false,
            }))
          }
          value={state.preselectedMccs}
          onChange={(e, value) =>
            setState((prevState) => ({
              ...prevState,
              preselectedMccs: value,
            }))
          }
          inputValue={state.mccInput}
          onInputChange={(e, value) => {
            setState((prevState) => ({
              ...prevState,
              mccInput: value,
            }));
          }}
          renderTags={() => null}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.mcc}>
              {selected ? (
                <XIcon fontSize="small" />
              ) : (
                <PlusIcon fontSize="small" />
              )}
              <Box ml={2}>
                {option.mcc} {option.mccName}
              </Box>
            </li>
          )}
          filterOptions={(options) =>
            options.filter(
              (item: MccCategory) =>
                values.findIndex((mcc) => item.mcc === mcc) === -1
            )
          }
          isOptionEqualToValue={(option, value) => option.mcc === value.mcc}
          getOptionLabel={(option) => `${option.mcc} ${option.mccName}`}
        />
      </Box>
      <Box flexShrink={0}>
        <Button
          onClick={() => {
            const sortedDisplayValues = sortBy(
              [
                ...displayValues!,
                ...state.preselectedMccs.map((item) => ({
                  code: item.mcc,
                  name: item.mccName,
                })),
              ],
              (item) => item.name.toLowerCase()
            );
            onChange({
              restriction,
              type,
              values: sortedDisplayValues.map((item) => item.code),
              displayValues: sortedDisplayValues,
            });
            setState((prevState) => ({
              ...prevState,
              mccOptions: [],
              preselectedMccs: [],
            }));
          }}
          disabled={!state.preselectedMccs.length || disabled}
          variant="text"
        >
          <Trans
            i18nKey="cardControlFormElement.addToList"
            values={{ count: state.preselectedMccs.length || '' }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default MccCategories;
