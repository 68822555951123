import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import creditLineApplicationImage from 'assets/images/creditLineApplication.png';
import {
  Box,
  Button,
  Chip,
  LoaderWithOverlay,
  Paper,
  Typography,
} from 'elements';
import useSnackbar from 'hooks/useSnackbar';
import { CreditApplicationStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import CreditLineApplicationDialog, {
  DialogMode,
} from './CreditLineApplicationDialog';

const CustomPaper = ({ children }: { children: ReactNode }) => (
  <Paper
    variant="outlined"
    sx={{
      bgcolor: (theme) => theme.variables.warmGrey[50],
      p: 3,
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    }}
  >
    {children}
  </Paper>
);

const CreditLineApplication = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { useGetCreditApplication } = useTanstackQuery();
  const {
    data: creditApplication,
    error,
    isLoading,
  } = useGetCreditApplication();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const canUser = useCanUser();

  const isOpenDialogButtonDisabled =
    isLoading || !canUser('credit-application:update');

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, [error]);

  if (!isLoading && error) return null;

  if (isLoading) {
    return (
      <CustomPaper>
        <Box height="76px" />
        <LoaderWithOverlay loading />
      </CustomPaper>
    );
  }

  if (creditApplication) {
    return (
      <CustomPaper>
        <Box display="flex" alignItems="flex-start" flexGrow={1}>
          <Box display="flex" flexDirection="column" maxWidth="545px" mr="auto">
            <Typography variant="subtitle1" mb={1}>
              {t('creditLineApplication.editTitle')}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {t('creditLineApplication.editSubtitle')}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            disabled={
              isOpenDialogButtonDisabled &&
              CreditApplicationStatus.requested !== creditApplication.status
            }
            onClick={() => setIsDialogOpen(true)}
          >
            Edit application details
          </Button>
        </Box>

        <CreditLineApplicationDialog
          open={isDialogOpen}
          mode={DialogMode.edit}
          onClose={() => setIsDialogOpen(false)}
        />
      </CustomPaper>
    );
  }

  return (
    <CustomPaper>
      <Box
        sx={{
          background: `url('${creditLineApplicationImage}') center no-repeat`,
          backgroundSize: 'contain',
          height: 76,
          width: 95,
          mr: 3,
        }}
      />

      <Box display="flex" flexDirection="column" mr="auto">
        <Box display="flex" alignItems="center" mb={1}>
          <Typography variant="subtitle1" mr={1}>
            {t('creditLineApplication.createTitle')}
          </Typography>
          <Chip label={t('creditLineApplication.optional')} size="small" />
        </Box>

        <Typography variant="body2" color="textSecondary">
          {t('creditLineApplication.createSubtitle')}
        </Typography>
      </Box>

      <Button
        disabled={isOpenDialogButtonDisabled}
        onClick={() => setIsDialogOpen(true)}
      >
        {t('common.button.apply')}
      </Button>

      <CreditLineApplicationDialog
        open={isDialogOpen}
        mode={DialogMode.create}
        onClose={() => setIsDialogOpen(false)}
      />
    </CustomPaper>
  );
};

export default CreditLineApplication;
