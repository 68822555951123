import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Box, Grid, Paper, ThumbsUpIcon, Tooltip, Typography } from 'elements';
import { Team, TeamStatus } from 'services/constants';
import TeamBudget from './TeamBudget';
import TeamMemberList from './TeamMemberList';
import TeamMenu from './TeamMenu';

const getTeamMemberList = (
  managersLength: number,
  isReviewToggleVisible: boolean
) => {
  // need to reduce number of shown members for better UI
  if (isReviewToggleVisible) return managersLength ? 3 : 6;
  return managersLength ? 5 : 8;
};

interface Props {
  team: Team;
  onUpdate: (team: Team) => void;
  onRefetch: () => void;
}

const TeamItem = ({ team, onUpdate, onRefetch }: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();
  const isTeamDeactivated = team.teamStatus === TeamStatus.deactivated;

  return (
    <Grid item key={team.id}>
      <Paper
        variant="outlined"
        sx={{
          width: 350,
          height: 240,
          opacity: isTeamDeactivated ? 0.5 : 1,
        }}
      >
        <Box
          p={2}
          borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box minWidth={0}>
              {team.costCenter && (
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                  noWrap
                  my="-2px"
                >
                  {team.costCenter}
                </Typography>
              )}
              <Typography variant="h6" noWrap>
                {team.name}
              </Typography>
            </Box>

            <TeamMenu team={team} onUpdate={onUpdate} onRefetch={onRefetch} />
          </Box>

          {featureModules.TEAM_BUDGETS &&
            (team.budget?.value ? (
              <TeamBudget balance={team.balance!} budget={team.budget} />
            ) : (
              <Box height={72} />
            ))}
        </Box>

        <Box display="flex" alignItems="center" p={2}>
          {!!team.managers.length && (
            <Box width="40%">
              <Typography variant="subtitle2">
                <Trans
                  i18nKey="teamsPage.managers"
                  values={{ count: team.managers.length }}
                  components={{
                    highlight: (
                      <Typography
                        variant="body2"
                        component="span"
                        color="text.secondary"
                      />
                    ),
                  }}
                />
              </Typography>
              <TeamMemberList
                areManagers
                members={team.managers}
                maxItems={2}
              />
            </Box>
          )}
          <Box flexGrow={1}>
            <Typography variant="subtitle2">
              <Trans
                i18nKey="teamsPage.members"
                values={{ count: team.members.length }}
                components={{
                  highlight: (
                    <Typography
                      variant="body2"
                      component="span"
                      color="text.secondary"
                    />
                  ),
                }}
              />
            </Typography>
            <TeamMemberList
              members={team.members}
              maxItems={getTeamMemberList(
                team.managers.length,
                featureModules.MANAGER_TX_REVIEWS
              )}
            />
          </Box>

          {featureModules.MANAGER_TX_REVIEWS && (
            <Tooltip
              disableHoverListener={isTeamDeactivated}
              disableTouchListener={isTeamDeactivated}
              title={
                team.managerTxReviewRequired
                  ? t('teamsPage.txReviewActivatedTooltip')
                  : t('teamsPage.txReviewDeactivatedTooltip')
              }
            >
              <ThumbsUpIcon
                fontSize="small"
                sx={{
                  mr: 1.5,
                  color: team.managerTxReviewRequired
                    ? 'action.active'
                    : 'action.disabled',
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

export default TeamItem;
