export const isMissingHttpProtocol = (string: string) => {
  if (string.startsWith('http://') || string.startsWith('https://')) {
    return false;
  }
  return true;
};

export const isValidHttpUrl = (string: string) => {
  try {
    const newUrl = new URL(string);

    if (!/[a-z0-9-]+(?<!-)\.[a-z]{2,}$/i.test(newUrl.hostname)) return false;

    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch {
    return false;
  }
};
