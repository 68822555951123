import xor from 'lodash/xor';

// helper for checking whether we should refetch data or not
export const isValueMismatched = <T extends { id?: string }>(
  param: string,
  stateValue: T | 'MISSING' | null
) => {
  // Case 1: Missing status mismatch
  if (param === 'MISSING') return stateValue !== 'MISSING';
  // Case 2: No value exists for the particular parameter
  if (param && !stateValue) return true;
  // Case 3: Parameter represents an ID, and values differ
  if (param) return stateValue === 'MISSING' || param !== stateValue!.id;

  return false;
};

export const isMultiValueMismatched = <T extends { id?: string }>(
  param: string[],
  stateValue: T[]
) => {
  const stateValueIds = stateValue.map((value) => value.id);
  return !!xor(param, stateValueIds).length;
};
