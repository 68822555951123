import { AccountingSystemLogo } from 'domains/settings/components';
import { Box, Dialog, DialogProps, Paper, withDialogWrapper } from 'elements';
import CodatStepper, { DIALOG_STEPPER_WIDTH } from './CodatStepper';
import CostCentersStep from './CostCentersStep';
import CostUnitsStep from './CostUnitsStep';
import FinishStep from './FinishStep';
import GLAccountsStep from './GLAccountsStep';
import SuppliersStep from './SuppliersStep';
import VatRatesStep from './VatRatesStep';
import {
  useCodatStepperContext,
  withCodatStepperContext,
} from './useCodatStepperContext';

/**
 * For stepper context -1 and 0 step are different,
 * but as they should share one state (G/L accoutns context)
 * they are placed in one parent component
 */
const View = {
  '-1': <GLAccountsStep />,
  '0': <GLAccountsStep />,
  '1': <VatRatesStep />,
  '2': <SuppliersStep />,
  '3': <CostCentersStep />,
  '4': <CostUnitsStep />,
  '5': <FinishStep />,
};

export interface SyncSetupDialogProps extends DialogProps {
  onClose: () => void;
}

// eslint-disable-next-line unused-imports/no-unused-vars
const CodatSyncSetupDialog = ({ onClose, ...props }: SyncSetupDialogProps) => {
  const {
    state: { step },
  } = useCodatStepperContext();

  const key = step.toString() as keyof typeof View;

  return (
    <Dialog
      {...props}
      PaperProps={{
        sx: (theme) => ({
          height: 800,
          maxWidth: theme.breakpoints.values.md + DIALOG_STEPPER_WIDTH + 'px',
          paddingLeft: DIALOG_STEPPER_WIDTH + 'px',
        }),
      }}
    >
      <Paper
        square
        variant="tinted"
        sx={(theme) => ({
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          width: DIALOG_STEPPER_WIDTH,
          padding: theme.spacing(4, 3),
        })}
      >
        <CodatStepper />

        <Box
          sx={(theme) => ({
            position: 'absolute',
            padding: theme.spacing(1),
            bottom: theme.spacing(3),
            left: theme.spacing(4),
            right: theme.spacing(4),
            display: 'flex',
            justifyContent: 'center',
            svg: { maxWidth: '100%', maxHeight: 50 },
          })}
        >
          <AccountingSystemLogo />
        </Box>
      </Paper>

      {View[key]}
    </Dialog>
  );
};

export default withDialogWrapper(withCodatStepperContext(CodatSyncSetupDialog));
