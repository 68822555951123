import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PartnerMember } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  member: PartnerMember;
  onClose: () => void;
  onSuccess: (member: PartnerMember) => void;
}

const DeactivatePartnerMemberDialog = ({
  member,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const fullName = `${member.firstName} ${member.lastName}`;

  const deactivateMember = async () => {
    try {
      setIsLoading(true);
      const updatedMember = await api.deactivatePartnerMember(
        member.partnerCompanyId,
        member.id
      );
      if (!mounted.current) return;
      onSuccess(updatedMember);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        {t('portal.deactivatePartnerMemberDialog.heading', { fullName })}
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2">
          {t('portal.deactivatePartnerMemberDialog.subheading')}
        </Typography>
        <Box my={3}>
          <Typography color="textSecondary" mb={1}>
            {fullName}
          </Typography>
        </Box>
        <Typography variant="body2">
          {t('portal.deactivatePartnerMemberDialog.explanation')}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>

        <Button onClick={deactivateMember} disabled={isLoading}>
          {t('common.button.confirm')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(DeactivatePartnerMemberDialog);
