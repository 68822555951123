import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { Box, Grid, LabeledValue, Typography } from 'elements';
import { useCanUser } from 'services/rbac';

const IssuanceLimitSection = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { jwtPayload },
  } = useGlobalState();

  if (!canUser('profile-page-issuance-limits:view')) return null;

  return (
    <Box mb={8}>
      <Typography variant="h6" mb={1}>
        {t('profilePage.issuanceLimits.title')}
      </Typography>
      <Typography mb={3}>
        {t('profilePage.issuanceLimits.contactOwner')}
      </Typography>

      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid item xs={6}>
          <LabeledValue
            label={t('profilePage.issuanceLimits.maxLimitPerCard')}
            value={
              jwtPayload.attr?.maxSpendLimitPerCard ? (
                <FormatMoney value={jwtPayload.attr.maxSpendLimitPerCard} />
              ) : (
                '-'
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledValue
            label={t('profilePage.issuanceLimits.canIssueOwnCard')}
            value={
              typeof jwtPayload.attr?.canCreateCardForSelf === 'boolean'
                ? jwtPayload.attr.canCreateCardForSelf
                  ? t('profilePage.issuanceLimits.yes')
                  : t('profilePage.issuanceLimits.no')
                : '-'
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default IssuanceLimitSection;
