import { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  InputLabelTooltipIcon,
  Link,
  LoaderWithOverlay,
  MenuItem,
  Select,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import useUrls from 'hooks/useUrls';
import {
  ReceiptDateExportMode,
  receiptDateExportModes,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues {
  receiptDateExportMode: ReceiptDateExportMode;
}

const ExportSettingsDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, accountingSettings },
  } = useGlobalState();
  const { HELP_CENTER_RECEIPT_DATE_IN_EXPORT_URL } = useUrls();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(
    false
  );

  const receiptDateExportModesArray = useMemo(
    () =>
      accountingSettings!.useSupplierAccount
        ? receiptDateExportModes
        : receiptDateExportModes.filter(
            (item) => item !== ReceiptDateExportMode.dynamic
          ),
    [accountingSettings!.useSupplierAccount]
  );

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      receiptDateExportMode: accountingSettings!.receiptDateExportMode,
    },
    onSubmit: async ({ receiptDateExportMode }, { setSubmitting }) => {
      try {
        const accountingSettings = await api.updateAccountingExportSettings({
          organizationId: organization!.id,
          receiptDateExportMode,
        });
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { accountingSettings },
        });
        if (!mounted.current) return;
        setIsConfirmationDialogOpen(false);
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        setIsConfirmationDialogOpen(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  if (isConfirmationDialogOpen)
    return (
      <ConfirmDialog
        open
        loading={formik.isSubmitting}
        onClose={() => setIsConfirmationDialogOpen(false)}
        onSuccess={() => formik.handleSubmit()}
        title={t(
          'accountingGeneralSubpage.updateReceiptDateConfirmDialog.title'
        )}
        description={t(
          'accountingGeneralSubpage.updateReceiptDateConfirmDialog.description'
        )}
        confirmButtonProps={{ children: t('common.button.update') }}
      />
    );

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('accountingGeneralSubpage.exportSettingsGroup.title')}
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={() => setIsConfirmationDialogOpen(true)}
          id="export-settings-change"
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth disabled={formik.isSubmitting}>
                <InputLabel id="receipt-date-label">
                  {t(
                    'accountingGeneralSubpage.exportSettingsGroup.receiptDateExport'
                  )}
                  <InputLabelTooltipIcon
                    title={
                      <Trans
                        i18nKey="accountingGeneralSubpage.exportSettingsGroup.receiptDateExportTooltip"
                        components={{
                          linkTo: (
                            <Link
                              href={HELP_CENTER_RECEIPT_DATE_IN_EXPORT_URL}
                              rel="noopener noreferrer"
                              target="_blank"
                              color="inherit"
                            />
                          ),
                        }}
                      />
                    }
                  />
                </InputLabel>
                <Select
                  labelId="receipt-date-label"
                  renderValue={(v) =>
                    t(
                      `accountingGeneralSubpage.exportSettingsGroup.receiptDateExportMode.${v}`
                    )
                  }
                  {...formik.getFieldProps('receiptDateExportMode')}
                >
                  {receiptDateExportModesArray.map((v) => (
                    <MenuItem key={v} value={v}>
                      {t(
                        `accountingGeneralSubpage.exportSettingsGroup.receiptDateExportMode.${v}`
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          form="export-settings-change"
          type="submit"
          disabled={!formik.dirty || formik.isSubmitting}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(ExportSettingsDialog);
