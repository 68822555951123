import { Trans, useTranslation } from 'react-i18next';
import { CardHistoryCardControlEntry } from 'services/constants';

interface Props {
  entry: CardHistoryCardControlEntry;
}

const CardControlEventTitle = ({ entry }: Props) => {
  const { t } = useTranslation();

  if (entry.prevRestrictionType === entry.restrictionType)
    return <>{t(`cardHistory.titleByType.${entry.type}__updated`)}</>;

  return (
    <Trans
      i18nKey={`cardHistory.titleByType.${entry.type}__changed`}
      values={{
        prevRestrictionType: t(
          `cardHistory.cardControlRestrictionTypes.${
            entry.prevRestrictionType || 'NONE'
          }`
        ),
        restrictionType: t(
          `cardHistory.cardControlRestrictionTypes.${
            entry.restrictionType || 'NONE'
          }`
        ),
      }}
      components={{ b: <b /> }}
    />
  );
};

export default CardControlEventTitle;
