import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CardPin } from 'domains/card/components';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
  card: Card;
  onUpdate: (card: Card) => void;
}

const UnlockPinDialog = ({ card, onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const [isLoading, setIsLoading] = useState(true);
  const [pin, setPin] = useState<string>('');

  const getData = async () => {
    try {
      const cardData = await api.unlockCardPin(card.cardId);
      if (!mounted.current) return;
      onUpdate(cardData);
      const { pin } = await api.getCardPin(card.cardId);
      if (!mounted.current) return;
      setPin(pin);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('pinDialog.unlockPinTitle')}</DialogTitle>
      <DialogContent>
        <Alert severity="warning">{t('pinDialog.unlockPinDescription')}</Alert>
        <Box mt={2}>
          <CardPin pin={pin} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t('common.button.close')}</Button>
      </DialogActions>
      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(UnlockPinDialog);
