import { useState } from 'react';
import { Box } from '@material-ui/core';
import { isNil, omitBy } from 'lodash';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import ContentArchiveIcon from 'assets/icons/interface/contentArchive.svg?react';
import ContentFileIcon from 'assets/icons/interface/contentFile.svg?react';
import EditPencilIcon from 'assets/icons/interface/editPencil.svg?react';
import FileDeleteAlternateIcon from 'assets/icons/interface/fileDeleteAlternate.svg?react';
import SettingMenuVerticalIcon from 'assets/icons/interface/settingMenuVertical.svg?react';
import UploadButton2Icon from 'assets/icons/interface/uploadButton2.svg?react';
import ChatBubbleOvalQuestionIcon from 'assets/icons/mail/chatBubbleOvalQuestion.svg?react';
import { OnboardingDocumentStatusBadge } from 'components/Badge';
import { ListItemIcon, ListItemText, MenuItem } from 'components/Menu';
import Tooltip, { CircleQuestionMarkIcon } from 'components/Tooltip';
import { useGlobalState } from 'context/GlobalState';
import usePartnerName from 'hooks/usePartnerName';
import {
  OnboardingDocument,
  OnboardingDocumentStatus,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import AnswerSection from './AnswerSection';
import FileRow from './FileRow';
import OnboardingDocumentDescription from './OnboardingDocumentDescription';
import OnboardingDocumentTitle from './OnboardingDocumentTitle';
import RejectDocumentDialog from './RejectDocumentDialog';
import UploadFilesDialog from './UploadFilesDialog';
import {
  Bold,
  Comment,
  Container,
  DocumentTitleRow,
  FileContainerTitle,
  FileListContainer,
  IconAndTitleWrapper,
  StyledButton,
  StyledIconButton,
  StyledMenuContainer,
} from './style';

interface Props {
  isDisabled?: boolean;
  document: OnboardingDocument;
  onUpdate?: () => Promise<void>;
  isViewOnly?: boolean;
  internalOnly?: {
    archiveDocument: (
      documentId: string,
      setIsDocumentCardLoading: (isLoading: boolean) => void
    ) => Promise<void>;
  };
}

const OnboardingDocumentRow = ({
  document,
  internalOnly,
  isDisabled,
  isViewOnly,
  onUpdate,
}: Props) => {
  const canUser = useCanUser();
  const { t } = useTranslation();
  const partnerName = usePartnerName();
  const {
    state: { organization },
  } = useGlobalState();
  const [isUploadFilesDialogOpen, setIsUploadFilesDialogOpen] = useState(false);
  const [isRejectDocumentDialogOpen, setIsRejectDocumentDialogOpen] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(false);

  const isQuestion = document.type.question;

  const hasUploadButtonInTitleRow =
    !isViewOnly &&
    canUser('org-onboarding-document-files:manage') &&
    document.status === OnboardingDocumentStatus.REQUESTED &&
    document.type.maxNumberOfFiles > 0;

  const documentStatus =
    document.status === OnboardingDocumentStatus.REQUESTED &&
    document.reasonForRequest
      ? 'REJECTED' // FE only state
      : document.status;

  const hasPredefinedDescription = !!t(
    `onboardingDocumentType.description.${document.type.name}`,
    {
      defaultValue: false,
    }
  );
  const hasTooltip = !!t(
    `onboardingDocumentType.fileTypeTooltip.${document.type.name}`,
    { defaultValue: false, partnerName }
  );

  const generateInternalFilename = (fileNumber: number) => {
    const hasNumeration = document.files.length > 1;

    if (!document.type.filenameTemplate) {
      return (
        document.type.name +
        (hasNumeration ? `_${String(fileNumber).padStart(2, '0')}` : '')
      );
    }

    let filename = document.type.filenameTemplate;

    if (filename.startsWith('YYYYMMDD_')) {
      filename = filename.replace('YYYYMMDD', moment().format('YYYYMMDD'));
    }

    if (filename.includes('_XXXX_')) {
      const organizationNameWithoutSpaces = organization!.name.replace(
        /[\s|.]/g,
        ''
      );
      filename = filename.replace('XXXX', organizationNameWithoutSpaces);
    }

    if (filename.includes('_MM"M"YY_')) {
      const [year, month] = document.attributes.toMonthYear.split('-');
      filename = filename.replace('MM"M"YY', `${month}M${year.slice(2)}`);
    }

    return (
      filename +
      (hasNumeration ? `_${String(fileNumber).padStart(2, '0')}` : '')
    );
  };

  return (
    <>
      <Container>
        <Box display="flex" flexDirection="column">
          <DocumentTitleRow>
            <IconAndTitleWrapper
              $isRed={documentStatus === 'REJECTED'}
              $hasMt={hasUploadButtonInTitleRow || !!internalOnly}
            >
              {documentStatus !== 'REJECTED' ? (
                isQuestion ? (
                  <ChatBubbleOvalQuestionIcon />
                ) : (
                  <ContentFileIcon />
                )
              ) : (
                <FileDeleteAlternateIcon />
              )}

              <OnboardingDocumentTitle
                attributes={document.attributes}
                customLabel={document.customLabel}
                customLabelLocal={document.customLabelLocal}
                name={document.type.name}
              />
              {hasTooltip && (
                <Tooltip
                  title={
                    <Trans
                      i18nKey={`onboardingDocumentType.fileTypeTooltip.${document.type.name}`}
                      components={{ b: <Bold />, br: <br /> }}
                      values={{
                        orgName: document.attributes.orgName ?? '',
                        partnerName,
                      }}
                    />
                  }
                >
                  <Box component="span">
                    <CircleQuestionMarkIcon />
                  </Box>
                </Tooltip>
              )}
            </IconAndTitleWrapper>

            <Box minHeight="24px" display="flex" alignItems="center">
              <OnboardingDocumentStatusBadge status={documentStatus} />

              {hasUploadButtonInTitleRow && (
                <StyledButton
                  disabled={isDisabled || isLoading}
                  onClick={() => setIsUploadFilesDialogOpen(true)}
                  variant="outlined"
                >
                  {document.files.length === 0 ? (
                    <>
                      <UploadButton2Icon />
                      <span>{t('common.button.upload')}</span>
                    </>
                  ) : (
                    <>
                      <EditPencilIcon />
                      <span>{t('common.button.edit')}</span>
                    </>
                  )}
                </StyledButton>
              )}

              {internalOnly && (
                <>
                  {document.status === OnboardingDocumentStatus.SUBMITTED && (
                    <StyledButton
                      onClick={() => setIsRejectDocumentDialogOpen(true)}
                      variant="outlined"
                    >
                      <FileDeleteAlternateIcon />
                      <span>{t('common.button.reject')}</span>
                    </StyledButton>
                  )}

                  <StyledMenuContainer
                    button={
                      <StyledIconButton
                        disabled={isDisabled || isLoading}
                        variant="outlined"
                      >
                        <SettingMenuVerticalIcon />
                      </StyledIconButton>
                    }
                  >
                    <MenuItem
                      onClick={() =>
                        internalOnly.archiveDocument(
                          document.id,
                          (isLoading: boolean) => setIsLoading(isLoading)
                        )
                      }
                    >
                      <ListItemIcon>
                        <ContentArchiveIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Archive'} />
                    </MenuItem>
                  </StyledMenuContainer>
                </>
              )}
            </Box>
          </DocumentTitleRow>

          {hasPredefinedDescription ? (
            <OnboardingDocumentDescription
              description={
                <Trans
                  i18nKey={`onboardingDocumentType.description.${document.type.name}`}
                  components={{
                    b: <Bold />,
                    br: <br />,
                    li: <li />,
                    ul: <ul />,
                  }}
                  values={{
                    orgName: organization!.name,
                    ...omitBy(document.attributes, isNil),
                    ...(document.attributes?.date && {
                      date: moment(
                        document.attributes.date,
                        'YYYY-MM-DD'
                      ).format('DD.MM.YYYY'),
                    }),
                  }}
                />
              }
            />
          ) : (
            document.description && (
              <OnboardingDocumentDescription
                description={document.description}
                descriptionLocal={document.descriptionLocal}
              />
            )
          )}

          {isQuestion && (
            <Box mt="16px">
              <AnswerSection document={document} onUpdate={onUpdate!} />
            </Box>
          )}

          {!!document.files.length && (
            <Box mt="16px">
              <FileContainerTitle>
                {t('onboardingDocumentRow.uploadedFiles')}
              </FileContainerTitle>
              <FileListContainer>
                {document.files.map((file, index) => (
                  <FileRow
                    key={file.id}
                    documentId={document.id}
                    file={file}
                    {...(internalOnly && {
                      internalFilename: generateInternalFilename(index + 1),
                    })}
                  />
                ))}
              </FileListContainer>
            </Box>
          )}

          {document.status === OnboardingDocumentStatus.REQUESTED &&
            document.reasonForRequest && (
              <Comment>{document.reasonForRequest}</Comment>
            )}
        </Box>
      </Container>

      <UploadFilesDialog
        document={document}
        onClose={() => setIsUploadFilesDialogOpen(false)}
        onUpdate={onUpdate!}
        open={isUploadFilesDialogOpen}
      />

      <RejectDocumentDialog
        document={document}
        onClose={() => setIsRejectDocumentDialogOpen(false)}
        onUpdate={onUpdate!}
        open={isRejectDocumentDialogOpen}
      />
    </>
  );
};

export default OnboardingDocumentRow;
