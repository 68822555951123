import { useTheme } from 'styled-components';
import ConnectionSignalLoadingIcon from 'assets/icons/computerDevices/connectionSignalLoading.svg?react';
import BezalaIcon from 'assets/icons/partnerLogos/bezala.svg?react';
import CandisIcon from 'assets/icons/partnerLogos/candis.svg?react';
import CirculaIcon from 'assets/icons/partnerLogos/circula.svg?react';
import MobilexpenseIcon from 'assets/icons/partnerLogos/mobilexpense.svg?react';
import PliantRoundLogo from 'assets/svg/pliantRoundLogo.svg?react';
import { PartnerIds, PartnerIdType } from 'services/constants';

interface Props {
  partnerId: PartnerIdType;
}

const PartnerMinorLogo = ({ partnerId }: Props) => {
  const { colors } = useTheme();

  switch (partnerId) {
    case PartnerIds.candis:
      return <CandisIcon color={colors.candis} />;
    case PartnerIds.circula:
    case PartnerIds.circulaDemo:
    case PartnerIds.circulaEmbedded:
    case PartnerIds.circulaFullyEmbedded:
      return <CirculaIcon />;
    case PartnerIds.mobilexpenseDeclaree:
      return <MobilexpenseIcon />;
    case PartnerIds.bezala:
    case PartnerIds.bezalaWallet:
      return <BezalaIcon />;
    case PartnerIds.pliant:
      return <PliantRoundLogo />;
  }

  return <ConnectionSignalLoadingIcon />;
};

export default PartnerMinorLogo;
