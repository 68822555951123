import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeactivatePartnerMemberDialog,
  EditPartnerMemberRolesDialog,
} from 'domains/member/dialogs';
import {
  FadersHorizontalIcon,
  IconButton,
  ListItemIcon,
  MenuContainer,
  MenuItem,
  PencilSimpleIcon,
  XCircleIcon,
} from 'elements';
import { PartnerMember } from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  member: PartnerMember;
  onUpdate: (member: PartnerMember) => void;
}

const PartnerMemberDetailsMenu = ({ member, onUpdate }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const [
    isEditMemberRolesDialogOpen,
    setIsEditMemberRolesDialogOpen,
  ] = useState(false);
  const [
    isDeactivateMemberDialogOpen,
    setIsDeactivateMemberDialogOpen,
  ] = useState(false);

  if (!canUser('partner-member:change', member)) return null;

  const onEditRoleSuccess = (member: PartnerMember) => {
    setIsEditMemberRolesDialogOpen(false);
    onUpdate(member);
  };

  const onDeactivateMemberSuccess = (member: PartnerMember) => {
    setIsDeactivateMemberDialogOpen(false);
    onUpdate(member);
  };

  return (
    <>
      <MenuContainer
        button={
          <IconButton>
            <FadersHorizontalIcon />
          </IconButton>
        }
      >
        <MenuItem onClick={() => setIsEditMemberRolesDialogOpen(true)}>
          <ListItemIcon>
            <PencilSimpleIcon />
          </ListItemIcon>
          {t('portal.partnerMemberDetailsMenu.editMemberPermissions')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            setIsDeactivateMemberDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <XCircleIcon />
          </ListItemIcon>
          {t('portal.partnerMemberDetailsMenu.deactivateMember')}
        </MenuItem>
      </MenuContainer>

      <EditPartnerMemberRolesDialog
        member={member}
        open={isEditMemberRolesDialogOpen}
        onClose={() => setIsEditMemberRolesDialogOpen(false)}
        onSuccess={onEditRoleSuccess}
      />
      <DeactivatePartnerMemberDialog
        open={isDeactivateMemberDialogOpen}
        member={member}
        onClose={() => setIsDeactivateMemberDialogOpen(false)}
        onSuccess={onDeactivateMemberSuccess}
      />
    </>
  );
};

export default PartnerMemberDetailsMenu;
