import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryParams } from 'domains/organization/pages/OrganizationsPage/OrganizationsAndNcoSubPage';
import { Chip, Tooltip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import {
  getFilterChipText,
  PageFilterChips,
  PageFiltersComponent,
} from 'layout';
import { PartnerScope } from 'services/constants';
import FiltersDrawer from './FiltersDrawer';
import useFilters from './useFilters';

export const visiblePartnerScopes = [
  PartnerScope.embeddedWallet,
  PartnerScope.fullyEmbedded,
  PartnerScope.pliantInternal,
  PartnerScope.whitelabel,
  PartnerScope.logoCard,
];

interface Props {
  isNCOPage: boolean;
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
}

const Filters = ({
  isNCOPage,
  params,
  selectedFiltersCount,
  setParam,
}: Props) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { partnerData, areFiltersLoading, setDataFilters } = useFilters({
    partnerConfigId: params.partnerConfigId,
  });
  const isPartnerError = !partnerData.isLoading && partnerData.isError;

  const statusChipTooltip = useMemo(() => {
    if (params.status.length < 2) return '';
    return params.status
      .map((item) => t(`orgStatuses.${item}`).toUpperCase())
      .join(', ');
  }, [params.status]);

  const partnerChipText = useMemo(() => {
    if (partnerData.isLoading) return t('common.loading');
    if (isPartnerError)
      return `${t('int.filters.config')}: ${t('filters.notFound')}`;
    return partnerData.value?.displayName || '';
  }, [params.partnerConfigId, partnerData]);

  return (
    <>
      <PageFiltersComponent
        count={selectedFiltersCount}
        q={params.q}
        setQParam={(value) => setParam('q', value)}
        onOpen={() => setIsFilterOpen(true)}
        disabled={areFiltersLoading}
      />

      {!!selectedFiltersCount && (
        <PageFilterChips>
          {!!params.status.length && (
            <Tooltip title={statusChipTooltip}>
              <Chip
                label={getFilterChipText(
                  t(`orgStatuses.${params.status[0]}`).toUpperCase(),
                  params.status.length
                )}
                onDelete={() => setParam('status', [])}
              />
            </Tooltip>
          )}
          {!!params.partnerConfigId && (
            <Chip
              label={partnerChipText}
              color={isPartnerError ? 'error' : 'default'}
              disabled={partnerData.isLoading}
              onDelete={() => setParam('partnerConfigId', '')}
            />
          )}
          {!!params.country && (
            <Chip
              label={t(`countries.${params.country}`)}
              onDelete={() => setParam('country', '')}
            />
          )}
          {!!params.currency && (
            <Chip
              label={params.currency}
              onDelete={() => setParam('currency', '')}
            />
          )}
          {!!params.accountGroup && (
            <Chip
              label={t(`accountGroup.${params.accountGroup}`)}
              onDelete={() => setParam('accountGroup', '')}
            />
          )}
        </PageFilterChips>
      )}

      <FiltersDrawer
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        params={params}
        setParam={setParam}
        isNCOPage={isNCOPage}
        partnerData={partnerData}
        setDataFilters={setDataFilters}
      />
    </>
  );
};

export default Filters;
