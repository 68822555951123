import { useCallback, useEffect, useState } from 'react';
import { isValueMismatched } from 'domains/transaction/utils';
import useMounted from 'hooks/useMounted';
import { Subcategory, SubcategoryStatus } from 'services/constants';
import useImperativeApi from 'services/network/useImperativeApi';

export interface SubcategoryData {
  value: Subcategory | SubcategoryStatus.missing | null;
  isLoading: boolean;
  isError: boolean;
}

export const useSubcategoryFilter = (subcategoryParam: string) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const [subcategoryData, setSubcategoryData] = useState<SubcategoryData>({
    value: null,
    isLoading: false,
    isError: false,
  });

  const getSubcategory = async () => {
    if (subcategoryParam === SubcategoryStatus.missing) {
      setSubcategoryData((prevState) => ({
        ...prevState,
        value: SubcategoryStatus.missing,
      }));
      return;
    }
    setSubcategoryData((prevState) => ({ ...prevState, isLoading: true }));
    try {
      if (!mounted.current) return;
      const subcategory = await api.getSubcategory(subcategoryParam);
      setSubcategoryData((prevState) => ({
        ...prevState,
        value: subcategory,
        isLoading: false,
      }));
    } catch {
      if (!mounted.current) return;
      setSubcategoryData((prevState) => ({
        ...prevState,
        isLoading: false,
        isError: true,
      }));
    }
  };

  useEffect(() => {
    // automatically reset state when no param
    if (!subcategoryParam) {
      setSubcategoryData((prevState) => ({
        ...prevState,
        value: null,
        isError: false,
      }));
      return;
    }

    if (isValueMismatched(subcategoryParam, subcategoryData.value))
      getSubcategory();
  }, [subcategoryParam]);

  const setSubcategoryFilter = useCallback(
    (subcategory: Pick<SubcategoryData, 'value'>) => {
      setSubcategoryData((prevState) => ({
        ...prevState,
        ...subcategory,
        isError: false,
      }));
    },
    []
  );

  return {
    subcategoryData,
    setSubcategoryFilter,
  };
};
