import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  CategoryFilter,
  FlagReasonFilter,
  ProjectFilter,
  ReceiptFilter,
  ReviewFilter,
  TeamFilter,
} from 'domains/transaction/components';
import { QueryParams } from 'domains/transaction/pages/AccountEntriesPage';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import {
  MerchantCategory,
  NONE_VALUE,
  PrivateExpenseStatus,
  privateExpenseStatuses,
  ReviewFlagReason,
  TransactionReceiptStatus,
  TransactionReviewStatus,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import AccountEntriesSubTypeFilter, {
  AccountEntriesSubTypeFilterOption,
} from './AccountEntriesSubTypeFilter';
import { ProjectsData, TeamsData } from './useFilters';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  teamData: TeamsData;
  projectData: ProjectsData;
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
  setFilters: ({
    projects,
    teams,
  }: {
    projects: { value: ProjectsData['value'] };
    teams: { value: TeamsData['value'] };
  }) => void;
}

interface State {
  subType: AccountEntriesSubTypeFilterOption[];
  category: MerchantCategory[];
  teams: TeamsData['value'];
  projects: ProjectsData['value'];
  receipt: TransactionReceiptStatus | '';
  fromDate: Moment | null;
  toDate: Moment | null;
  reviewStatus: TransactionReviewStatus | typeof NONE_VALUE | '';
  flagReason: ReviewFlagReason | '';
  privateExpenseStatus: PrivateExpenseStatus[];
}

const initialState: State = {
  subType: [],
  category: [],
  teams: [],
  projects: [],
  receipt: '',
  fromDate: null,
  toDate: null,
  reviewStatus: '',
  flagReason: '',
  privateExpenseStatus: [],
};

const FiltersDrawer = ({
  params,
  projectData,
  teamData,
  setParam,
  setFilters,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>(initialState);
  const { companyReimbursementEnabled } = useFlags();

  const areFiltersSelected = !!(
    state.subType.length ||
    state.category.length ||
    teamData.value.length ||
    projectData.value.length ||
    state.receipt ||
    state.fromDate ||
    state.toDate ||
    state.reviewStatus ||
    state.flagReason ||
    state.privateExpenseStatus.length
  );

  useEffect(() => {
    if (props.open)
      setState({
        subType: params.subType,
        category: params.category,
        teams: teamData.value,
        projects: projectData.value,
        receipt: params.receipt,
        fromDate: params.fromDate,
        toDate: params.toDate,
        reviewStatus: params.reviewStatus,
        flagReason: params.flagReason,
        privateExpenseStatus: params.privateExpenseStatus,
      });
  }, [props.open]);

  const onFiltersApply = () => {
    const { projects, teams, ...stateToPass } = state;
    const filtersToApply = {
      ...stateToPass,
      projectIds: projects.map((project) => project.id),
      teamId: teams.map((team) => team.id),
      fromDate: stateToPass.fromDate?.format('YYYY-MM-DD'),
      toDate: stateToPass.toDate?.format('YYYY-MM-DD'),
    };

    setParam(
      Object.keys(filtersToApply).map((key) => [
        key,
        filtersToApply[key as keyof typeof filtersToApply],
      ])
    );
    setFilters({
      projects: { value: projects },
      teams: { value: teams },
    });
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(
      featureModules.MULTI_CARD_ACCOUNT || featureModules.MULTI_CURRENCY_BILLING
        ? [
            ...Object.entries({ ...initialState, projectIds: [], teamId: [] }),
            ['cardAccountId', params.cardAccountId],
          ]
        : Object.entries({ ...initialState, projectIds: [], teamId: [] })
    );
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <AccountEntriesSubTypeFilter
        value={state.subType}
        onChange={(value) =>
          setState((prevState) => ({
            ...prevState,
            subType: xor(prevState.subType, [value]),
          }))
        }
      />

      <DateFilter
        label={t('filters.bookingDate')}
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate,
            toDate,
          }))
        }
      />

      <ReceiptFilter
        value={state.receipt}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, receipt: value }))
        }
      />

      <CategoryFilter
        value={state.category}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, category: value }))
        }
      />

      {featureModules.TEAMS && accountingSettings?.costCenterEnabled && (
        <TeamFilter
          teamData={teamData}
          value={state.teams}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, teams: value }))
          }
        />
      )}

      {featureModules.ACCOUNTING_FEATURES &&
        accountingSettings?.projectEnabled && (
          <ProjectFilter
            projectData={projectData}
            value={state.projects}
            onChange={(value) =>
              setState((prevState) => ({ ...prevState, projects: value }))
            }
          />
        )}

      <ReviewFilter
        value={state.reviewStatus}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, reviewStatus: value }))
        }
      />

      <FlagReasonFilter
        value={state.flagReason}
        onChange={(flagReason) =>
          setState((prevState) => ({
            ...prevState,
            flagReason,
            privateExpenseStatus:
              flagReason === ReviewFlagReason.privateExpense &&
              featureModules.COMPANY_REIMBURSEMENT &&
              companyReimbursementEnabled
                ? [...privateExpenseStatuses]
                : [],
          }))
        }
        privateExpenseStatusesValue={state.privateExpenseStatus}
        onPrivateExpenseStatusChange={(values) =>
          setState((prevState) => ({
            ...prevState,
            privateExpenseStatus: xor(prevState.privateExpenseStatus, values),
          }))
        }
      />
    </FilterDrawer>
  );
};

export default FiltersDrawer;
