import { Box, Link, Tooltip, WarningIcon } from 'elements';
import { isMissingHttpProtocol, isValidHttpUrl } from 'services/utils';

interface Props {
  value: string;
}

const WebsiteValueWithValidation = ({ value }: Props) => {
  const valueWithHttpProtocol = isMissingHttpProtocol(value)
    ? `http://${value}`
    : value;

  if (isValidHttpUrl(valueWithHttpProtocol)) {
    return (
      <Link
        href={valueWithHttpProtocol}
        rel="noopener noreferrer"
        target="_blank"
      >
        {value}
      </Link>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <span>{value}</span>
      <Tooltip title="invalid url">
        <WarningIcon color="warning" sx={{ marginLeft: 1 }} />
      </Tooltip>
    </Box>
  );
};

export default WebsiteValueWithValidation;
