import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { FilterDrawer, FilterDrawerProps } from 'layout';
import { countries } from 'services/constants';
import { QueryParams } from '.././index';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  country: string[];
}

const FiltersDrawer = ({ params, setParam, ...props }: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    country: [],
  });

  const areFiltersSelected = !!state.country.length;

  useEffect(() => {
    if (props.open) setState({ country: params.country });
  }, [params, props.open]);

  const onFiltersApply = () => {
    setParam('country', state.country);
    props.onClose();
  };

  const onFilterReset = () => {
    setParam('country', []);
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-country"
          id="panel-country-header"
        >
          <Typography>{t('filters.country')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth>
            <Select<string[]>
              size="medium"
              multiple
              displayEmpty
              value={state.country}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  country: e.target.value as string[],
                }))
              }
              renderValue={(selected) => {
                if (!selected?.length)
                  return (
                    <Typography color="text.disabled">
                      {t('filters.country')}
                    </Typography>
                  );

                return selected
                  .map((cc) => {
                    return countries.find((item) => item.countryCode === cc)
                      ?.countryName;
                  })
                  .join(', ');
              }}
            >
              {countries.map(({ countryCode, countryName }) => (
                <MenuItem key={countryCode} value={countryCode}>
                  {countryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </FilterDrawer>
  );
};

export default FiltersDrawer;
