import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { getDocumentUrl } from 'domains/document/utils';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControlLabel,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  onClose: () => void;
  onSuccess: (secret: string) => void;
}

interface State {
  isLoading: boolean;
  alreadyAcceptedTermsAndConditions: boolean;
  acceptedTermsAndConditions: boolean;
  isAccepting: boolean;
}

const Step1 = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const {
    state: { member, organization },
  } = useGlobalState();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    isLoading: true,
    alreadyAcceptedTermsAndConditions: false,
    acceptedTermsAndConditions: false,
    isAccepting: false,
  });

  useEffect(() => {
    checkCardholderReimbursementTermsAndConditions();
  }, []);

  const checkCardholderReimbursementTermsAndConditions = async () => {
    try {
      const response = await api.checkCardholderReimbursementTermsAndConditions(
        member.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        alreadyAcceptedTermsAndConditions: response.isAccepted,
        acceptedTermsAndConditions: response.isAccepted,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const acceptTermsAndConditionsOrContinue = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isAccepting: true,
      }));
      if (!state.alreadyAcceptedTermsAndConditions) {
        await api.acceptCardholderReimbursementTermsAndConditions(
          member.id,
          organization!.id
        );
      }
      const response = await api.createStripeSetupIntent(member.id);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isAccepting: false,
      }));
      props.onSuccess(response.clientSecret);
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isAccepting: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  if (state.isLoading)
    return (
      <Box display="flex" justifyContent="center" mb={4}>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <DialogContent>
        <LoaderWithOverlay loading={state.isAccepting} />
        <Typography component="div">
          {t('addPrivateCardDialog.description')}
        </Typography>
        {!state.alreadyAcceptedTermsAndConditions && (
          <Box mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.acceptedTermsAndConditions}
                  onChange={() =>
                    setState((prevState) => ({
                      ...prevState,
                      acceptedTermsAndConditions: !prevState.acceptedTermsAndConditions,
                    }))
                  }
                />
              }
              label={
                <Typography variant="body2" color="text.secondary">
                  <Trans
                    i18nKey="addPrivateCardDialog.termsAndConditions"
                    components={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          style={{ color: 'inherit' }}
                          href={getDocumentUrl(
                            'COMPANY_REIMBURSEMENT_CARDHOLDER.pdf'
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                </Typography>
              }
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="text"
          children={t('common.button.cancel')}
        />
        <Button
          onClick={acceptTermsAndConditionsOrContinue}
          disabled={!state.acceptedTermsAndConditions}
          children={
            !state.isLoading && !state.alreadyAcceptedTermsAndConditions
              ? t('common.button.confirm')
              : t('common.button.continue')
          }
        />
      </DialogActions>
    </>
  );
};

export default Step1;
