import { useState } from 'react';
import { FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGlobalState } from 'context/GlobalState';
import { Box, Switch } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OrganizationStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;

  .MuiSwitch-root {
    margin: 0;
  }

  .MuiFormControlLabel-label {
    font-size: 16px;
    line-height: 20px;
  }
`;

const AutomaticActivationToggle = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    state: { organization },
    dispatch,
  } = useGlobalState();
  const [automaticActivationEnabled, setAutomaticActivationEnabled] = useState(
    organization!.automaticActivationEnabled
  );
  const [
    isAutomaticActivationUpdating,
    setIsAutomaticActivationUpdating,
  ] = useState(false);

  if (!canUser('org:activate')) {
    return null;
  }

  const toggleAutomaticActivation = async (value: boolean) => {
    if (isAutomaticActivationUpdating) return;
    try {
      setIsAutomaticActivationUpdating(true);
      setAutomaticActivationEnabled(value);
      const updatedOrg = await api.toggleOrgAutomaticActivation(
        organization!.id,
        value
      );
      if (!mounted.current) return;
      setIsAutomaticActivationUpdating(false);
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { organization: updatedOrg },
      });
    } catch (error) {
      if (!mounted.current) return;
      setAutomaticActivationEnabled(organization!.automaticActivationEnabled);
      setIsAutomaticActivationUpdating(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Box alignItems="center" display="flex" height="100%">
      <StyledFormControlLabel
        control={
          <Switch
            checked={automaticActivationEnabled}
            onChange={(e, checked) => toggleAutomaticActivation(checked)}
            disabled={
              organization!.status === OrganizationStatus.deactivated ||
              organization!.status === OrganizationStatus.cancelled
            }
          />
        }
        label={t('int.riskAndCompliancePage.automaticActivation')}
        labelPlacement="end"
      />
    </Box>
  );
};

export default AutomaticActivationToggle;
