import VssIcon from 'assets/icons/appSpecific/vss.svg?react';
import { IconButton } from 'elements';
import useDownloadFile from 'hooks/useDownloadFile';

interface Props {
  programSettlementId: string;
}

const DownloadVssFileButton = ({ programSettlementId }: Props) => {
  const handleDownloadFile = useDownloadFile(
    `/program-settlements/${programSettlementId}/vss-file`
  );

  return (
    <IconButton
      onClick={handleDownloadFile}
      sx={{ svg: { height: '24px', width: '24px' } }}
    >
      {/* todo: update the icon later */}
      <VssIcon />
    </IconButton>
  );
};

export default DownloadVssFileButton;
