import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import WidgetError from 'components/WidgetError';
import { AccountEntryAvatar } from 'domains/transaction/components';
import { Box, LoaderWithOverlay, StatusBadge, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  AccountEntry,
  AccountEntryType,
  Transaction,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getFullName } from 'services/utils';
import AccountEntryDetailsPageContent from './AccountEntryDetailsPageContent';
import AccountEntryPaymentTypeBadge from './AccountEntryPaymentTypeBadge';
import BillPaymentInfo from './BillPaymentInfo';
import ReportPreviewDialog from './ReportPreviewDialog';

interface State {
  isLoading: boolean;
  accountEntry: AccountEntry | null;
  transaction: Transaction | null;
  error: unknown;
  isReportPreviewDialogOpen: boolean;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (accountEntry: AccountEntry) => void;
  isExportPage?: boolean;
}

const AccountEntryDetailsPage = ({
  onUpdate,
  isExportPage,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const { entryId } = useParams<{ entryId: string }>();
  const mounted = useMounted();
  const idRef = useRef(entryId);
  const [state, setState] = useState<State>({
    isLoading: false,
    accountEntry: null,
    transaction: null,
    error: null,
    isReportPreviewDialogOpen: false,
  });

  useEffect(() => {
    if (state.accountEntry) onUpdate(state.accountEntry);
  }, [state.accountEntry]);

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const accountEntry = await api.getAccountEntry(entryId);
      let transaction: Transaction | null = null;
      if (accountEntry.type === AccountEntryType.reimbursement) {
        transaction = await api.getTransaction(
          accountEntry.reimbursementInfo!.transactionId
        );
      }
      if (!mounted.current || entryId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        accountEntry,
        transaction,
        error: null,
      }));
    } catch (error) {
      if (!mounted.current || entryId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        accountEntry: null,
        transaction: null,
        error,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!entryId) return;
    idRef.current = entryId;
    getData();
  }, [entryId]);

  const getAmountColor = (accountEntry: AccountEntry) => {
    if (accountEntry.type === AccountEntryType.reimbursement) return undefined;
    return accountEntry.amount.value > 0 ? 'success.main' : 'error.main';
  };

  const renderAccountEntryContent = (
    accountEntry: AccountEntry,
    transaction: Transaction | null,
    isPreviewDialogOpen: boolean
  ) => {
    return (
      <>
        <DetailsDrawerHeader pb={2.5}>
          <Box display="flex" alignItems="center">
            <AccountEntryAvatar
              size="large"
              type={accountEntry.type}
              billPaymentType={accountEntry.billPaymentInfo?.billPaymentType}
              memberName={
                accountEntry.reimbursementInfo
                  ? getFullName(
                      accountEntry.reimbursementInfo.memberFirstName,
                      accountEntry.reimbursementInfo.memberLastName
                    )
                  : undefined
              }
            />

            <Box pl={2} ml="auto" textAlign="right" overflow="hidden">
              <Typography variant="h4" color={getAmountColor(accountEntry)}>
                <FormatMoney
                  value={accountEntry.amount}
                  fractionalPart
                  positiveSign
                />
              </Typography>

              {accountEntry.billPaymentInfo?.billPaymentType && (
                <AccountEntryPaymentTypeBadge
                  billPaymentType={accountEntry.billPaymentInfo.billPaymentType}
                  paymentType={accountEntry.billPaymentInfo.paymentType}
                />
              )}
              {accountEntry.type === AccountEntryType.reimbursement && (
                <Box
                  mt={1}
                  lineHeight={0}
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <StatusBadge
                    variant="filled"
                    color="primary"
                    label={t('common.badge.companyReimbursement')}
                  />
                </Box>
              )}
            </Box>
          </Box>

          <BillPaymentInfo accountEntry={accountEntry} />
        </DetailsDrawerHeader>

        <DetailsDrawerContent pb={1}>
          <AccountEntryDetailsPageContent
            accountEntry={accountEntry}
            transaction={transaction}
            isExportPage={isExportPage}
            onPreviewDialogOpen={
              isPreviewDialogOpen
                ? undefined
                : () =>
                    setState((prevState) => ({
                      ...prevState,
                      isReportPreviewDialogOpen: true,
                    }))
            }
          />
        </DetailsDrawerContent>
      </>
    );
  };

  const renderContent = (
    accountEntry: AccountEntry,
    transaction: Transaction | null
  ) => {
    if (!accountEntry.billPaymentInfo?.reportId)
      return renderAccountEntryContent(accountEntry, transaction, false);

    return (
      <>
        {renderAccountEntryContent(accountEntry, transaction, false)}
        <ReportPreviewDialog
          open={state.isReportPreviewDialogOpen}
          reportId={accountEntry.billPaymentInfo.reportId}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isReportPreviewDialogOpen: false,
            }))
          }
        >
          {renderAccountEntryContent(accountEntry, transaction, true)}
        </ReportPreviewDialog>
      </>
    );
  };

  return (
    <DetailsDrawer {...props}>
      {state.error && <WidgetError onReload={getData} />}
      {state.accountEntry &&
        renderContent(state.accountEntry, state.transaction)}
      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(AccountEntryDetailsPage);
