import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { GeneralTileFooter } from 'domains/settings/components';
import { EditCustomFieldDialog } from 'domains/settings/dialogs';
import {
  StyledAvatar,
  Tile,
} from 'domains/settings/pages/AccountingPage/GeneralSubPage/style';
import {
  Box,
  Button,
  CaretCircleDownIcon,
  FormControlLabel,
  Grid,
  LoaderWithOverlay,
  PlusIcon,
  StatusBadge,
  Switch,
  TextTIcon,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CustomField,
  CustomFieldStatus,
  CustomFieldType,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getPath } from 'services/utils';
import AddCustomFieldDialog from './AddCustomFieldDialog';

interface Props {
  customFields: CustomField[];
  onSuccess: (customFields: CustomField[]) => void;
}

const CustomFieldsGroup = ({ customFields, onSuccess }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [
    customFieldToEdit,
    setCustomFieldToEdit,
  ] = useState<CustomField | null>(null);
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [showInactive, setShowInactive] = useState(false);

  const activeCustomFields = useMemo(
    () =>
      customFields.filter((item) => item.status === CustomFieldStatus.active),
    [customFields]
  );
  const shownCustomFields = showInactive ? customFields : activeCustomFields;

  const fetchCustomFields = async () => {
    setIsLoading(true);
    try {
      const { transactionCustomFields } = await api.getCustomFields({
        organizationId: organization!.id,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
      });
      if (!mounted.current) return;
      onSuccess(transactionCustomFields);
      setIsLoading(false);
      dispatch({
        type: 'SET_CUSTOM_FIELDS',
        payload: {
          transactionCustomFields,
        },
      });
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Box position="relative" my={4}>
      <Box py={2} display="flex" alignItems="center">
        <Typography variant="h6">
          {t('accountingGeneralSubpage.customFields.title')}
        </Typography>

        <Box ml="auto">
          {!!customFields.length &&
            activeCustomFields.length < customFields.length && (
              <FormControlLabel
                sx={{ mr: 0 }}
                checked={showInactive}
                disabled={isLoading}
                control={<Switch />}
                label={t('accountingGeneralSubpage.viewInactiveLabel')}
                onChange={(e, checked: boolean) => setShowInactive(checked)}
              />
            )}

          {canUser('custom-fields:manage') &&
            customFields.length < DEFAULT_PAGE_LIMIT && (
              <Button
                variant="outlined"
                size="large"
                startIcon={<PlusIcon />}
                onClick={() => setIsAddDialogOpen(true)}
                disabled={isLoading}
                sx={{ ml: 3 }}
              >
                {t('accountingGeneralSubpage.customFields.addField')}
              </Button>
            )}
        </Box>
      </Box>

      <Grid container spacing={3}>
        {shownCustomFields.map((field) => {
          const isTileDisabled =
            !field.type ||
            (field.type === CustomFieldType.text &&
              !canUser('custom-fields:manage')) ||
            (field.type === CustomFieldType.select &&
              !canUser('custom-field-options-page:visit'));
          return (
            <Grid item key={field.id}>
              <Tile
                variant="outlined"
                sx={{
                  cursor: !isTileDisabled ? 'pointer' : 'default',
                }}
                onClick={() => {
                  if (isTileDisabled || isLoading) return;
                  // redirect to corresponding page, when the field is a select type
                  if (field.type === CustomFieldType.select) {
                    history.push(
                      generatePath(
                        getPath('settingsAccountingDropdownOptions'),
                        {
                          orgId: organization!.id,
                          fieldId: field.id,
                        }
                      )
                    );
                    return;
                  }

                  setCustomFieldToEdit(field);
                  setIsEditDialogOpen(true);
                }}
              >
                <Box p={3} pb={2}>
                  <StatusBadge
                    sx={(theme) => ({
                      position: 'absolute',
                      top: theme.spacing(3),
                      right: theme.spacing(3),
                    })}
                    label={t(
                      `accountingGeneralSubpage.basicAccountingSettings.${
                        field.status === CustomFieldStatus.active
                          ? 'activeStatus'
                          : 'inactiveStatus'
                      }`
                    )}
                    variant="filled"
                    color={
                      field.status === CustomFieldStatus.active
                        ? 'success'
                        : 'primary'
                    }
                  />

                  <StyledAvatar>
                    {field.type === CustomFieldType.text ? (
                      <TextTIcon fontSize="large" />
                    ) : (
                      <CaretCircleDownIcon fontSize="large" />
                    )}
                  </StyledAvatar>

                  <Box mt={3}>
                    <Typography variant="h6" mb={2}>
                      {field.label}
                    </Typography>

                    <Typography color="textSecondary">
                      {field.description ||
                        t(
                          'accountingGeneralSubpage.customFields.defaultDescription'
                        )}
                    </Typography>
                  </Box>
                </Box>

                <GeneralTileFooter
                  mandatoryForExport={field.mandatoryForExport}
                  automationType={field.automationType}
                  visibility={field.visibility}
                />
              </Tile>
            </Grid>
          );
        })}
      </Grid>

      <AddCustomFieldDialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        onSuccess={(id?: string) => {
          fetchCustomFields();
          if (id && canUser('custom-field-options-page:visit')) {
            history.push({
              pathname: generatePath(
                getPath('settingsAccountingDropdownOptions'),
                {
                  orgId: organization!.id,
                  fieldId: id,
                }
              ),
            });
            return;
          }
          setIsAddDialogOpen(false);
        }}
      />

      <EditCustomFieldDialog
        open={isEditDialogOpen}
        customField={customFieldToEdit!}
        onClose={() => setIsEditDialogOpen(false)}
        onSuccess={() => {
          if (!mounted.current) return;
          fetchCustomFields();
          setIsEditDialogOpen(false);
        }}
      />

      <LoaderWithOverlay loading={isLoading} />
    </Box>
  );
};

export default CustomFieldsGroup;
