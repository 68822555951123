import { useCallback, useEffect, useState } from 'react';
import { MISSING_SUPPLIER_OPTION } from 'domains/transaction/pages/ExportPage/utils';
import { isValueMismatched } from 'domains/transaction/utils';
import useMounted from 'hooks/useMounted';
import { Supplier } from 'services/constants';
import useImperativeApi from 'services/network/useImperativeApi';

export interface SupplierData {
  value: Supplier | typeof MISSING_SUPPLIER_OPTION | null;
  isLoading: boolean;
  isError: boolean;
}

export const useSupplierFilter = (supplierParam: string) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const [supplierData, setSupplierData] = useState<SupplierData>({
    value: null,
    isLoading: false,
    isError: false,
  });

  const getSupplier = async () => {
    if (supplierParam === MISSING_SUPPLIER_OPTION) {
      setSupplierData((prevState) => ({
        ...prevState,
        value: MISSING_SUPPLIER_OPTION,
      }));
      return;
    }
    setSupplierData((prevState) => ({ ...prevState, isLoading: true }));
    try {
      if (!mounted.current) return;
      const supplier = await api.getSupplier(supplierParam);
      setSupplierData((prevState) => ({
        ...prevState,
        value: supplier,
        isLoading: false,
      }));
    } catch {
      if (!mounted.current) return;
      setSupplierData((prevState) => ({
        ...prevState,
        isLoading: false,
        isError: true,
      }));
    }
  };

  useEffect(() => {
    // automatically reset state when no param
    if (!supplierParam) {
      setSupplierData((prevState) => ({
        ...prevState,
        value: null,
        isError: false,
      }));
      return;
    }

    if (isValueMismatched(supplierParam, supplierData.value)) getSupplier();
  }, [supplierParam]);

  const setSupplierFilter = useCallback(
    (supplier: Pick<SupplierData, 'value'>) => {
      setSupplierData((prevState) => ({
        ...prevState,
        ...supplier,
        isError: false,
      }));
    },
    []
  );

  return {
    supplierData,
    setSupplierFilter,
  };
};
