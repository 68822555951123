import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import difference from 'lodash/difference';
import { useGlobalState } from 'context/GlobalState';
import { useCodatStepperContext } from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/useCodatStepperContext';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CodatAccountItem,
  CodatDataItemStatus,
  MerchantCategory,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

export const getAccountsHashMap = (accounts: CodatAccountItem[]) =>
  accounts.reduce(
    (result, item) => ({ ...result, [item.id]: MerchantCategory.other }),
    {}
  );

export const mapCodatSubcategories = (
  accounts: CodatAccountItem[],
  selectedSyncedAccountIds: string[],
  accountCategoryMap: { [accountId: string]: MerchantCategory }
) =>
  accounts!
    .filter((account) => selectedSyncedAccountIds.includes(account.id))
    .map((account) => ({
      id: account.id,
      name: account.name,
      pliantCategory: accountCategoryMap[account.id],
      nominalCode: account.nominalCode,
    }));

export enum GlAccountsStepsEnum {
  initialStep = 'INITIAL_STEP', // for the initial info page (step number -1)
  selectAccountsStep = 'SELECT_ACCOUNTS_STEP',
  mapAccountsStep = 'MAP_ACCOUNTS_STEP',
}

export const GlAccountsSteps = Object.values(GlAccountsStepsEnum);

export interface GLAccountsSyncContextType {
  isPartialFlow?: boolean;
  actions: {
    onInnerStepChange: (innerStep: GlAccountsStepsEnum) => void;
    getSyncedMappingOptions: () => Promise<void>;
    setFilterValue: (value: 'all' | string) => void;
    onSelectedAccountsChange: (
      stateKey: 'selectedAccountIds' | 'selectedSyncedAccountIds',
      ids: string[]
    ) => void;
    onDefaultCategoryChange: (category: MerchantCategory) => void;
    onItemCategoryChange: (
      codatAccountid: string,
      category: MerchantCategory
    ) => void;
    onFinalAccountsSave: () => Promise<void>;
    onPartialFlowCancel?: () => void;
    onClose: () => void;
  };
  state: {
    innerStep: GlAccountsStepsEnum;
    accounts: CodatAccountItem[] | null;
    accountsTypeFilterValue: 'all' | string;
    defaultCategory: MerchantCategory | null;
    selectedAccountIds: string[];
    selectedSyncedAccountIds: string[];
    accountCategoryMap: {
      [accountId: string]: MerchantCategory;
    } | null;
    isLoading: boolean;
  };
}

const INITIAL_STATE: GLAccountsSyncContextType['state'] = {
  innerStep: GlAccountsSteps[0],
  accounts: null,
  accountsTypeFilterValue: 'all',
  defaultCategory: null,
  selectedAccountIds: [], // selected accounts on step 1
  selectedSyncedAccountIds: [], // selected accounts on step 2
  accountCategoryMap: null,
  isLoading: false,
};

const GLAccountsSyncContext = createContext<GLAccountsSyncContextType>({
  actions: {} as GLAccountsSyncContextType['actions'],
  state: INITIAL_STATE,
});

const GLAccountsSyncContextProvider = ({
  children,
  isPartialFlow = false,
  onPartialFlowSuccess,
  onPartialFlowCancel,
  initialState = INITIAL_STATE,
}: {
  children: ReactNode;
  isPartialFlow?: boolean;
  onPartialFlowSuccess?: () => void;
  onPartialFlowCancel?: () => void;
  initialState?: GLAccountsSyncContextType['state'];
}) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    actions: { onNext, onClose },
  } = useCodatStepperContext();

  const {
    state: { organization },
  } = useGlobalState();

  const [state, setState] = useState<GLAccountsSyncContextType['state']>({
    ...initialState,
    innerStep: isPartialFlow
      ? GlAccountsStepsEnum.selectAccountsStep
      : initialState.innerStep,
    isLoading: isPartialFlow ? true : initialState.isLoading,
  });

  const getAccounts = useCallback(
    async (forceUpdate?: boolean) => {
      // don't fetch accounts once again if we already have them
      // this can happen only in case of Codat full sync flow (with Stepper)
      if (!forceUpdate && state.accounts) return;

      try {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));

        const accounts = await api.getCodatAccounts(organization!.id);
        if (!mounted.current) return;
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          accounts,
          accountCategoryMap: getAccountsHashMap(accounts),
        }));
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        logError(error);
      }
    },
    [state]
  );

  // For partial flow fired from G/L accounts subpage
  useEffect(() => {
    if (isPartialFlow) getAccounts();
  }, [isPartialFlow]);

  const onInnerStepChange: GLAccountsSyncContextType['actions']['onInnerStepChange'] = useCallback(
    (type) => setState((prevState) => ({ ...prevState, innerStep: type })),
    [state]
  );

  const getSyncedMappingOptions = useCallback(async () => {
    // don't fire Codat sync if it was already updated
    if (!isPartialFlow && state.accounts) {
      setState((prevState) => ({
        ...prevState,
        innerStep: GlAccountsStepsEnum.selectAccountsStep,
      }));
      onNext();
      return;
    }

    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        // reset everything when user manually syncs the accounts
        accountsTypeFilterValue: 'all',
        defaultCategory: null,
        selectedAccountIds: [],
        selectedSyncedAccountIds: [],
      }));
      // syncing with Codat
      await api.getSyncedCodatMappingOptionsSummary(organization!.id);
      await getAccounts(isPartialFlow);

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        innerStep: GlAccountsStepsEnum.selectAccountsStep,
      }));
      if (!isPartialFlow) onNext();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      logError(error);
    }
  }, [state, onNext]);

  const setFilterValue: GLAccountsSyncContextType['actions']['setFilterValue'] = useCallback(
    (value) => {
      setState((prevState) => ({
        ...prevState,
        accountsTypeFilterValue: value,
        selectedAccountIds: [], // reset selected accounts
        selectedSyncedAccountIds: [],
        defaultCategory: null,
      }));
    },
    [state]
  );

  const getUpdatedStateOnSelect = (
    stateKey: 'selectedAccountIds' | 'selectedSyncedAccountIds',
    prevState: GLAccountsSyncContextType['state'],
    selectedIdsArray: string[]
  ) => {
    // if user selected all accounts
    if (selectedIdsArray.length > 1)
      return {
        defaultCategory: null,
      };

    // If user selected only 1 account:
    // reset default category if user has selected the account with different category
    if (stateKey === 'selectedSyncedAccountIds')
      return {
        defaultCategory:
          prevState.accountCategoryMap![selectedIdsArray[0]] ===
          prevState.defaultCategory
            ? prevState.defaultCategory
            : null,
      };

    return {};
  };

  const getUpdatedStateOnDeselect = (
    stateKey: 'selectedAccountIds' | 'selectedSyncedAccountIds',
    prevState: GLAccountsSyncContextType['state'],
    unSelectedIdsArray: string[]
  ) => {
    // if user unselected all accounts
    if (unSelectedIdsArray.length > 1) {
      return {
        defaultCategory: null,
      };
    }

    // If user unselected 1 account:
    // update state for the next step if user has already selected items to map and default category
    if (stateKey === 'selectedAccountIds') {
      const updatedSelectedSyncedAccountIds = prevState.selectedSyncedAccountIds.filter(
        (val) => val !== unSelectedIdsArray[0]
      );

      return {
        selectedSyncedAccountIds: updatedSelectedSyncedAccountIds,
        defaultCategory: updatedSelectedSyncedAccountIds.length
          ? prevState.defaultCategory
          : null,
      };
    }

    return {};
  };

  const onSelectedAccountsChange: GLAccountsSyncContextType['actions']['onSelectedAccountsChange'] = useCallback(
    (stateKey, selectedIds) => {
      setState((prevState) => {
        // Select flow ->
        const selectedIdsArray = difference(selectedIds, prevState[stateKey]);
        if (selectedIdsArray.length > 0) {
          return {
            ...prevState,
            [stateKey]: selectedIds,
            ...getUpdatedStateOnSelect(stateKey, prevState, selectedIdsArray),
          };
        }

        // Deselect flow ->
        const unSelectedIdsArray = difference(prevState[stateKey], selectedIds);
        return {
          ...prevState,
          [stateKey]: selectedIds,
          ...getUpdatedStateOnDeselect(stateKey, prevState, unSelectedIdsArray),
        };
      });
    },
    [state]
  );

  const onItemCategoryChange: GLAccountsSyncContextType['actions']['onItemCategoryChange'] = useCallback(
    (codatAccountId, category) => {
      setState((prevState) => ({
        ...prevState,
        defaultCategory: prevState.selectedSyncedAccountIds.includes(
          codatAccountId
        )
          ? null
          : prevState.defaultCategory,
        accountCategoryMap: {
          ...prevState.accountCategoryMap,
          [codatAccountId]: category,
        },
      }));
    },
    [state]
  );

  const onDefaultCategoryChange: GLAccountsSyncContextType['actions']['onDefaultCategoryChange'] = useCallback(
    (category) => {
      const updatedPartialAccountCategoryMap = state.selectedSyncedAccountIds.reduce(
        (result, item) => ({ ...result, [item]: category }),
        {}
      );

      setState((prevState) => ({
        ...prevState,
        defaultCategory: category,
        accountCategoryMap: {
          ...prevState.accountCategoryMap,
          ...updatedPartialAccountCategoryMap,
        },
      }));
    },
    [state]
  );

  const onFinalAccountsSave = useCallback(async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      await api.updateCodatAccounts({
        organizationId: organization!.id,
        selectedAccounts: state.accounts!.map((account) => ({
          codatAccountId: account.id,
          status: state.selectedAccountIds.includes(account.id)
            ? CodatDataItemStatus.selected
            : CodatDataItemStatus.unselected,
        })),
      });

      await api.mapCodatSubcategories({
        organizationId: organization!.id,
        codatSubcategories: mapCodatSubcategories(
          state.accounts!,
          state.selectedSyncedAccountIds,
          state.accountCategoryMap!
        ),
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      if (onPartialFlowSuccess) onPartialFlowSuccess();
      else onNext();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, [state]);

  const value = useMemo(
    () => ({
      actions: {
        onInnerStepChange,
        getSyncedMappingOptions,
        setFilterValue,
        onSelectedAccountsChange,
        onDefaultCategoryChange,
        onItemCategoryChange,
        onFinalAccountsSave,
        onPartialFlowCancel,
        onClose,
      },
      state,
      isPartialFlow,
    }),
    [
      state,
      onInnerStepChange,
      getSyncedMappingOptions,
      setFilterValue,
      onSelectedAccountsChange,
      onDefaultCategoryChange,
      onItemCategoryChange,
      onFinalAccountsSave,
    ]
  );

  return (
    <GLAccountsSyncContext.Provider value={value}>
      {children}
    </GLAccountsSyncContext.Provider>
  );
};

const useGLAccountsSyncContext = () => {
  const context = useContext(GLAccountsSyncContext);
  return context;
};

const withGLAccountsSyncContext = (Component: ComponentType) => (props: {
  isPartialFlow?: boolean;
  onPartialFlowSuccess?: () => void;
  onPartialFlowCancel?: () => void;
}) => {
  return (
    <GLAccountsSyncContextProvider {...props}>
      <Component />
    </GLAccountsSyncContextProvider>
  );
};

export {
  GLAccountsSyncContextProvider,
  useGLAccountsSyncContext,
  withGLAccountsSyncContext,
  INITIAL_STATE as CODAT_SYNC_INITIAL_STATE,
};
