import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';

export interface CheckboxProps extends MuiCheckboxProps {
  /**
   * The color of the component.
   * @default 'primary'
   */
  color?: 'primary' | 'default';
}

const Checkbox = (props: CheckboxProps) => <MuiCheckbox {...props} />;

export default Checkbox;
