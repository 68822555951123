import { useEffect, useMemo, useRef, useState } from 'react';
import { intersection, pick } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  generatePath,
  Route,
  Link as RouterLink,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  AccountEntryDetailsPage,
  TransactionDetailsPage,
} from 'domains/transaction/pages';
import {
  Box,
  DataGrid,
  FileXIcon,
  Link,
  LoaderWithOverlay,
  Typography,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  AccountEntry,
  AccountEntryBillPaymentType,
  AccountEntryType,
  CardAccountBalance,
  DEFAULT_PAGE_LIMIT,
  FeatureModuleValueByKeyMap,
  GetAccountEntriesParams,
  merchantCategories,
  NONE_VALUE,
  privateExpenseStatuses,
  reviewFlagReasons,
  Transaction,
  TransactionReceiptStatus,
  transactionReceiptStatuses,
  TransactionReviewStatus,
  transactionReviewStatuses,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  getPath,
  getValidQueryParamValue,
  getValidQueryParamValues,
} from 'services/utils';
import AccountBalanceBox from './AccountBalanceBox';
import AccountEntriesPageMenu from './AccountEntriesPageMenu';
import AccountEntriesRouteRedirect from './AccountEntriesRouteRedirect';
import Filters from './Filters';
import { visibleAccountEntryTypes } from './Filters/AccountEntriesSubTypeFilter';
import useColumns from './useColumns';

const mergeTransactionDetailsFieldsToAccountEntryTransaction = (
  accountEntry: AccountEntry,
  transaction: Transaction
): AccountEntry => {
  const transactionKeys = Object.keys(transaction);
  const transactionInfoKeys = Object.keys(accountEntry.transactionInfo!);
  const sameKeys = intersection(transactionKeys, transactionInfoKeys);
  const sameKeysValues = pick(transaction, sameKeys);
  return {
    ...accountEntry,
    transactionInfo: {
      ...accountEntry.transactionInfo!,
      ...sameKeysValues,
    },
  };
};

const getQueryParams = (
  qs: string,
  areTeamsEnabled: boolean,
  areProjectsEnabled: boolean,
  featureModules: FeatureModuleValueByKeyMap
) => {
  const {
    q,
    subType,
    category,
    teamId,
    projectIds,
    fromDate,
    toDate,
    receipt,
    reviewStatus,
    flagReason,
    privateExpenseStatus,
    cardAccountId,
  } = Object.fromEntries(new URLSearchParams(qs).entries());

  const fromDateMoment = moment(fromDate, moment.ISO_8601);
  const toDateMoment = moment(toDate, moment.ISO_8601);

  const visibleReviewStatuses: (
    | TransactionReviewStatus
    | typeof NONE_VALUE
  )[] = featureModules.MANAGER_TX_REVIEWS
    ? [...transactionReviewStatuses, NONE_VALUE]
    : [
        TransactionReviewStatus.flagged,
        TransactionReviewStatus.resolved,
        NONE_VALUE,
      ];

  const allowedAccEntryTypes = visibleAccountEntryTypes.filter((item) => {
    if (item === AccountEntryType.reimbursement)
      return featureModules.COMPANY_REIMBURSEMENT;
    if (item === AccountEntryBillPaymentType.internalTransfer)
      return featureModules.MULTI_CARD_ACCOUNT;
    return true;
  });

  return {
    q: q?.trim() || '',
    teamId: teamId && areTeamsEnabled ? teamId.split(',') : [],
    projectIds: projectIds && areProjectsEnabled ? projectIds.split(',') : [],
    subType: getValidQueryParamValues(subType, allowedAccEntryTypes),
    category: getValidQueryParamValues(category, merchantCategories),
    fromDate: fromDateMoment.isValid() ? fromDateMoment : null,
    toDate:
      fromDateMoment.isValid() && toDateMoment.isValid() ? toDateMoment : null,
    receipt: getValidQueryParamValue(receipt, transactionReceiptStatuses),
    reviewStatus: getValidQueryParamValue(reviewStatus, visibleReviewStatuses),
    flagReason: featureModules.PRIVATE_EXPENSE
      ? getValidQueryParamValue(flagReason, reviewFlagReasons)
      : '',
    privateExpenseStatus: featureModules.PRIVATE_EXPENSE
      ? getValidQueryParamValues(privateExpenseStatus, privateExpenseStatuses)
      : [],
    cardAccountId: cardAccountId || '',
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({
  subType,
  category,
  teamId,
  projectIds,
  fromDate,
  receipt,
  reviewStatus,
  flagReason,
  privateExpenseStatus,
}: QueryParams) =>
  +!!subType.length +
  +!!category.length +
  +!!teamId.length +
  +!!projectIds.length +
  +!!fromDate +
  +!!receipt.length +
  +!!reviewStatus +
  +!!flagReason +
  +!!privateExpenseStatus.length;

interface State {
  isLoading: boolean;
  accountEntries: AccountEntry[];
  hasNextPage: boolean;
  totalCount: number;
  missingReceiptsCount: number;
  cardAccountBalance: CardAccountBalance | null;
}

const AccountEntriesPage = () => {
  const dataGridRef = useGridApiRef();
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const showPageError = useShowPageError();
  const {
    state: {
      organization,
      featureModules,
      defaultCardAccount,
      accountingSettings,
    },
  } = useGlobalState();
  const {
    isDetailsPageOpen: isEntryDetailsOpen,
    detailsParams: { entryId },
  } = useIsDetailsPageOpen('/entries/:entryId', true);
  const {
    isDetailsPageOpen: isTransactionDetailsOpen,
    detailsParams: { transactionId },
  } = useIsDetailsPageOpen('/transactions/:transactionId', true);
  const isDetailsPageOpen = isEntryDetailsOpen || isTransactionDetailsOpen;
  const areProjectsEnabled =
    featureModules.ACCOUNTING_FEATURES && accountingSettings!.projectEnabled;
  const areTeamsEnabled =
    featureModules.TEAMS && accountingSettings!.costCenterEnabled;
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(
    getQueryParams(
      location.search,
      areTeamsEnabled,
      areProjectsEnabled,
      featureModules
    )
  );
  const columns = useColumns();
  const getCardAccountName = useCardAccountNameGetter();
  const pageRef = useRef(0);
  const [state, setState] = useState<State>({
    isLoading: true,
    accountEntries: [],
    hasNextPage: false,
    totalCount: 0,
    missingReceiptsCount: 0,
    cardAccountBalance: null,
  });
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;
  const isEmptyState = !state.accountEntries.length && !areFiltersApplied;
  const isCamtDownloadDisabled =
    !!paramsRef.current.q.length ||
    selectedFiltersCount > 1 ||
    (selectedFiltersCount === 1 && !paramsRef.current.fromDate);
  const receiptManagementEnabled = featureModules.RECEIPT_MANAGEMENT;
  const currentCardAccountName = useMemo(
    () => getCardAccountName(paramsRef.current.cardAccountId),
    [getCardAccountName]
  );

  const getRequestParams = (): GetAccountEntriesParams => {
    const {
      q,
      subType,
      category,
      teamId,
      projectIds,
      fromDate,
      toDate,
      receipt,
      reviewStatus,
      flagReason,
      privateExpenseStatus,
      cardAccountId,
    } = paramsRef.current;

    return {
      q: q.length ? q : undefined,
      organizationId: organization!.id,
      cardAccountId:
        featureModules.MULTI_CARD_ACCOUNT ||
        featureModules.MULTI_CURRENCY_BILLING
          ? cardAccountId
          : undefined,
      subType: subType.join() || undefined,
      category: category.join() || undefined,
      teamIds: teamId.join() || undefined,
      projectIds: projectIds.join() || undefined,
      fromBookingDate: fromDate?.format('YYYY-MM-DD'),
      toBookingDate: toDate?.format('YYYY-MM-DD'),
      receiptStatus: receipt || undefined,
      reviewStatus: reviewStatus || undefined,
      flagReason: flagReason || undefined,
      privateExpenseStatus: privateExpenseStatus.length
        ? privateExpenseStatus.join()
        : undefined,
    };
  };

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const params = getRequestParams();
      const {
        accountEntries,
        hasNextPage,
        totalCount,
      } = await api.getAccountEntries({ ...params, page, limit });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        accountEntries: isLoadMore
          ? [...prevState.accountEntries, ...accountEntries]
          : accountEntries,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    paramsRef.current = getQueryParams(
      location.search,
      areTeamsEnabled,
      areProjectsEnabled,
      featureModules
    );
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const getMissingReceiptsCount = async () => {
    const { cardAccountId } = paramsRef.current;
    if (
      !receiptManagementEnabled ||
      !organization?.missingReceiptNotificationEnabled
    ) {
      return 0;
    }

    const { totalCount } = await api.getAccountEntries({
      organizationId: organization!.id,
      cardAccountId:
        featureModules.MULTI_CARD_ACCOUNT ||
        featureModules.MULTI_CURRENCY_BILLING
          ? cardAccountId
          : undefined,
      receiptStatus: TransactionReceiptStatus.missing,
      limit: 1,
    });

    return totalCount;
  };

  useEffect(() => {
    (async () => {
      try {
        const [missingReceiptsCount, cardAccountBalance] = await Promise.all([
          getMissingReceiptsCount(),
          api.getCardAccountBalance(
            paramsRef.current.cardAccountId || defaultCardAccount!.id
          ),
        ]);
        if (!mounted.current) return;
        setState((prevState) => ({
          ...prevState,
          missingReceiptsCount,
          cardAccountBalance,
        }));
      } catch (error) {
        logError(error);
      }
    })();
  }, []);

  return (
    <>
      <PageHeader>
        <PageTitle
          title={
            featureModules.MULTI_CARD_ACCOUNT ||
            featureModules.MULTI_CURRENCY_BILLING
              ? t('accountingPage.titleMultiCardAccount')
              : t('accountingPage.title')
          }
          suptitle={
            (featureModules.MULTI_CARD_ACCOUNT ||
              featureModules.MULTI_CURRENCY_BILLING) && (
              <>
                <Link
                  to={generatePath(getPath('cardAccounts'), {
                    orgId: organization!.id,
                  })}
                  component={RouterLink}
                  sx={{ textDecoration: 'none' }}
                  color="textSecondary"
                >
                  {t('accountingPage.titleAccounts')}
                </Link>
                &nbsp;/&nbsp;
                <Typography
                  variant="inherit"
                  component="span"
                  color="textPrimary"
                >
                  {currentCardAccountName}
                </Typography>
              </>
            )
          }
        >
          <AccountBalanceBox cardAccountBalance={state.cardAccountBalance} />
        </PageTitle>

        <Filters
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          disabled={isEmptyState}
          missingReceiptsCount={state.missingReceiptsCount}
          transactionsCount={state.totalCount}
          renderMenu={() => (
            <Box ml="auto">
              <AccountEntriesPageMenu
                disabled={!state.accountEntries.length}
                transactionsCount={areFiltersApplied ? state.totalCount : 0}
                isCamtDisabled={isCamtDownloadDisabled}
                getRequestParams={getRequestParams}
                cardAccountId={
                  paramsRef.current.cardAccountId || defaultCardAccount!.id
                }
              />
            </Box>
          )}
        />
      </PageHeader>

      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid<AccountEntry>
          apiRef={dataGridRef}
          rowHeight={72}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          loading={state.isLoading}
          rows={state.accountEntries}
          columns={columns}
          columnVisibilityModel={{
            receiptNeeded:
              featureModules.RECEIPT_MANAGEMENT &&
              !!organization?.missingReceiptNotificationEnabled,
            disputed: canUser('transaction-dispute:view'),
            _integration_: featureModules.ACCOUNTING_FEATURES,
            card: !isDetailsPageOpen,
            member: !isDetailsPageOpen,
            balanceAfter: !isDetailsPageOpen,
            drawerPlaceholder: isDetailsPageOpen,
          }}
          getRowClassName={({ id, row }) =>
            (transactionId &&
              row.transactionInfo?.transactionId === transactionId) ||
            (entryId && id === entryId)
              ? 'row-details-visible'
              : ''
          }
          onRowClick={({ id, row }) => {
            if (
              (transactionId &&
                row.transactionInfo?.transactionId === transactionId) ||
              (entryId && id === entryId)
            ) {
              history.push(`${url}${location.search}`);
            } else {
              const redirectUrl = row.transactionInfo
                ? `${url}/transactions/${row.transactionInfo.transactionId}${location.search}`
                : `${url}/entries/${row.id}${location.search}`;

              history.push(redirectUrl);
            }
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.accountEntries.length && areFiltersApplied)
                return <NothingFound />;

              return (
                <NoData
                  isNewDesign
                  Icon={FileXIcon}
                  label={t('accountingPage.noData')}
                  $top={90}
                />
              );
            },
            loadingOverlay: () => null,
          }}
        />

        <Route
          path={`${path}/transactions/:transactionId`}
          children={({ match }) => (
            <TransactionDetailsPage
              open={!!match}
              isAdminApp
              onUpdate={(transaction) => {
                setState((prevState) => ({
                  ...prevState,
                  accountEntries: state.accountEntries.map((item) =>
                    item.transactionInfo?.transactionId ===
                    transaction.transactionId
                      ? mergeTransactionDetailsFieldsToAccountEntryTransaction(
                          item,
                          transaction
                        )
                      : item
                  ),
                }));
              }}
            />
          )}
        />

        <Route
          path={`${path}/entries/:entryId`}
          children={({ match }) => (
            <AccountEntryDetailsPage
              open={!!match}
              onUpdate={(accountEntry) => {
                setState((prevState) => ({
                  ...prevState,
                  accountEntries: state.accountEntries.map((item) =>
                    item.id === accountEntry.id ? accountEntry : item
                  ),
                }));
              }}
            />
          )}
        />
      </PageTableContent>

      <AccountEntriesRouteRedirect />
    </>
  );
};

export default withPageConfig(AccountEntriesPage, {
  permission: 'account-entries-page:visit',
});
