import { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  GroupListItem,
  OnboardingBreadcrumbs,
  OnboardingFloatingToast,
  TaskListItem,
} from 'domains/onboarding/components';
import { useGetTasks } from 'domains/onboarding/hooks';
import { getOnboardingKeyTypeFromUrl } from 'domains/onboarding/utils';
import { PartnerLegalDisclaimer } from 'domains/partner/components';
import { List, LoaderWithOverlay, Typography } from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, PageContent, PageHeader, PageTitle } from 'layout';
import {
  OnboardingItemStatus,
  OnboardingSectionType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { onboardingKeys } from 'services/network/queryKeys';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getPath } from 'services/utils';

/**
 * The page is used in two cases: SECTION->TASKS and SECTION(Group)->GROUP_ITEMS.
 * Here SECTION->TASKS is a default structure, when there are no groups in the section.
 * In case of SECTION(Group)->GROUP_ITEMS, groups list page is considered as a Section page,
 * but might show both: groups of this section and tasks of the section (if exist).
 */

const OrganizationOnboardingSectionPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const canUser = useCanUser();
  const { sectionKey } = useParams<{
    sectionKey: string;
  }>();
  const currentSectionType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingSectionType>(sectionKey),
    [sectionKey]
  );
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isPortalApp } = useCurrentApp();
  const {
    state: { organization },
  } = useGlobalState();
  const showPageError = useShowPageError();
  const {
    useGetOnboardingSections,
    useSubmitOnboardingSection,
  } = useTanstackQuery();

  const {
    data: sectionsData,
    isFetching: areSectionsLoading,
  } = useGetOnboardingSections();
  const sections = sectionsData?.sections || [];
  const currentSectionObject = sections.find(
    (item) => item.type === currentSectionType
  );

  const { tasks, areTasksLoading, tasksError } = useGetTasks(
    !!currentSectionObject?.tasks?.length
  );

  const {
    mutate: orgSectionSubmitMutate,
    isLoading: isSectionSubmitting,
  } = useSubmitOnboardingSection({
    onSuccess: async () => {
      if (!mounted.current) return;
      history.push(
        generatePath(getPath('orgOnboarding'), {
          orgId: organization!.id,
        })
      );
      queryClient.invalidateQueries(
        onboardingKeys.tasks(organization!.id, currentSectionType)
      );
      queryClient.invalidateQueries(onboardingKeys.sections(organization!.id));
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    },
  });

  useEffect(() => {
    if (tasksError) {
      logError(tasksError);
      showPageError();
    }
  }, [tasksError]);

  const numberCompletedTasks = useMemo(
    () =>
      tasks.filter(
        (task) =>
          task.status === OnboardingItemStatus.completed ||
          task.status === OnboardingItemStatus.submitted
      ).length,
    [tasks]
  );

  const groups =
    !currentSectionObject?.restricted && currentSectionObject?.groups.length
      ? currentSectionObject.groups
      : [];

  const numberCompletedGroups = useMemo(
    () =>
      groups.filter((group) =>
        [
          OnboardingItemStatus.completed,
          OnboardingItemStatus.submitted,
        ].includes(group.status)
      ).length,
    [groups]
  );

  const isFloatingToastVisible =
    !isPortalApp &&
    !areTasksLoading &&
    !areSectionsLoading &&
    (!!tasks.length || !!groups.length) &&
    !!currentSectionObject?.status &&
    [
      OnboardingItemStatus.completed,
      OnboardingItemStatus.requiresAction,
    ].includes(currentSectionObject.status);

  const taskCompletionFloatingToastProps = tasks.length
    ? {
        isDisabled:
          isSectionSubmitting ||
          !canUser('org-onboarding-section:submit') ||
          tasks.length !== numberCompletedTasks ||
          groups.length !== numberCompletedGroups,
        onSuccess: () =>
          orgSectionSubmitMutate({
            type: currentSectionType,
          }),
      }
    : { isDisabled: true };

  const nothingFound =
    !areTasksLoading && tasks.length === 0 && groups.length === 0;

  return (
    <>
      <PageHeader>
        <ContentContainer mx="auto" size="sm">
          <OnboardingBreadcrumbs />

          <PageTitle
            title={t(`orgOnboardingSectionTitle.${currentSectionType}`)}
          />

          <Typography mt={1} variant="body2" color="textSecondary">
            {t(`orgOnboardingSectionPage.description.${currentSectionType}`)}
          </Typography>
        </ContentContainer>
      </PageHeader>

      <PageContent
        display="flex"
        flexDirection="column"
        pb={isFloatingToastVisible ? 20 : 0}
        position="static"
      >
        <ContentContainer
          mx="auto"
          size="sm"
          width="100%"
          flex={1}
          position={nothingFound ? 'relative' : 'static'}
        >
          {!!groups.length && (
            <List>
              {groups.map((group) => (
                <GroupListItem
                  key={group.id}
                  group={group}
                  sectionKey={sectionKey}
                />
              ))}
            </List>
          )}

          {!!tasks.length && (
            <List>
              {tasks.map((task) => (
                <TaskListItem
                  key={task.id}
                  task={task}
                  sectionKey={sectionKey}
                />
              ))}
            </List>
          )}

          {nothingFound && <NothingFound />}
        </ContentContainer>

        <PartnerLegalDisclaimer />

        {isFloatingToastVisible && (
          <OnboardingFloatingToast
            count={numberCompletedTasks + numberCompletedGroups}
            totalCount={tasks.length + groups.length}
            backLink={generatePath(getPath('orgOnboarding'), {
              orgId: organization!.id,
            })}
            {...taskCompletionFloatingToastProps}
          />
        )}

        <LoaderWithOverlay
          loading={areTasksLoading || areSectionsLoading || isSectionSubmitting}
        />
      </PageContent>
    </>
  );
};

export default OrganizationOnboardingSectionPage;
