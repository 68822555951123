import { useFormik } from 'formik';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MemberDetails, SupportedCountry } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, validateZipCodeByCountry } from 'services/utils';

interface FormValues {
  streetName: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  country: SupportedCountry;
  additionalInformations: string;
}

interface Props extends DialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (member: MemberDetails) => void;
  member: MemberDetails;
}

// For now this is only supported for Germany
const COUNTRIES = [SupportedCountry.de];

const AddressDialog = ({ member, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      streetName: member.address?.streetName || '',
      streetNumber: member.address?.streetNumber || '',
      postalCode: member.address?.postalCode || '',
      city: member.address?.city || '',
      country: SupportedCountry.de,
      additionalInformations: member.address?.additionalInformations || '',
    },
    validate: (values) => {
      const errors: Partial<FormValues> = {};
      if (values.postalCode) {
        const postalCodeError = validateZipCodeByCountry(
          values.country as SupportedCountry,
          values.postalCode.trim(),
          ((key: string) => t(key, { ns: 'translation' })) as TFunction
        );

        if (postalCodeError) errors.postalCode = postalCodeError;
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      const trimmedValues = {
        streetName: values.streetName.trim(),
        streetNumber: values.streetNumber.trim(),
        postalCode: values.postalCode.trim(),
        city: values.city.trim(),
        country: values.country,
        additionalInformations: values.additionalInformations.trim(),
      };

      try {
        await api.updateMemberAddress(member.id, trimmedValues);
        if (!mounted.current) return;
        enqueueSnackbar(t('editMemberAddressDialog.success'));
        onSuccess({
          ...member,
          address: {
            ...trimmedValues,
          },
        });
      } catch (error) {
        logError(error);
        enqueueSnackbar(getGenericErrorMsg(error), {
          variant: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('editMemberAddressDialog.title')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="address-form" noValidate>
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('editMemberAddressDialog.streetName')}
                {...formik.getFieldProps('streetName')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('editMemberAddressDialog.streetNumber')}
                {...formik.getFieldProps('streetNumber')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('editMemberAddressDialog.postalCode')}
                error={!!formik.errors.postalCode}
                helperText={formik.errors.postalCode}
                {...formik.getFieldProps('postalCode')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('editMemberAddressDialog.city')}
                {...formik.getFieldProps('city')}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth error={!!formik.errors.country}>
                <InputLabel id="address-country">
                  {t('editMemberAddressDialog.country')}
                </InputLabel>
                <Select<SupportedCountry>
                  labelId="address-country"
                  renderValue={(selected) => t(`countries.${selected}`)}
                  {...formik.getFieldProps('country')}
                  error={!!formik.errors.country}
                >
                  {COUNTRIES.map((slug) => {
                    return (
                      <MenuItem key={slug} value={slug}>
                        {t(`countries.${slug}`)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('editMemberAddressDialog.additionalInformations')}
                {...formik.getFieldProps('additionalInformations')}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={
            formik.isSubmitting ||
            !formik.values.streetName.trim() ||
            !formik.values.streetNumber.trim() ||
            !formik.values.postalCode.trim() ||
            !formik.values.city.trim()
          }
          form="address-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddressDialog;
