import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MerchantAvatar } from 'domains/merchant/components';
import {
  Box,
  DataGridCell,
  GridColDef,
  IconButton,
  PencilSimpleIcon,
  StatusBadge,
  Typography,
} from 'elements';
import { AccountingItemStatus, Subcategory } from 'services/constants';

const useColumns = (onEdit: (item: Subcategory) => void) => {
  const { t, i18n } = useTranslation();
  const {
    state: { teams },
  } = useGlobalState();

  return useMemo(() => {
    const columns: GridColDef<Subcategory>[] = [
      {
        field: 'name',
        headerName: t('categoriesSubPage.categoriesHeader'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row.organizationId ? (
            <Box display="flex" alignItems="center" overflow="hidden" pl={6}>
              <DataGridCell>{row.name}</DataGridCell>
              {row.status === AccountingItemStatus.inactive && (
                <StatusBadge
                  color="error"
                  sx={{ ml: 1, flexShrink: 0 }}
                  label={t('categoriesSubPage.inactiveBadgeLabel')}
                />
              )}
            </Box>
          ) : (
            <Box display="flex" alignItems="center" overflow="hidden">
              <MerchantAvatar category={row.category} />
              <Typography variant="subtitle1" ml={1}>
                {t(`merchantCategories.${row.category}`)}
              </Typography>
            </Box>
          ),
      },
      {
        field: 'number',
        sortable: false,
        headerName: t('categoriesSubPage.accountNumberHeader'),
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 72,
        getActions: ({ row }) =>
          row.organizationId
            ? [
                <IconButton key={row.id} onClick={() => onEdit(row)}>
                  <PencilSimpleIcon />
                </IconButton>,
              ]
            : [],
      },
    ];

    return columns;
  }, [i18n.language, teams]);
};

export default useColumns;
