import BmdAvatar from 'assets/images/integrationAvatars/bmd.png';
import DatevAvatar from 'assets/images/integrationAvatars/datev.png';
import QuickbooksAvatar from 'assets/images/integrationAvatars/quickbooks.png';
import LexwareOfficeAvatar from 'assets/svg/integrations/lexwareOfficeLogo.svg';
import MicrosoftDynamics365Avatar from 'assets/svg/integrations/microsoftDynamics365Logo.svg?react';
import OracleNetsuiteAvatar from 'assets/svg/integrations/oracleNetsuiteAvatar.svg?react';
import XeroAvatar from 'assets/svg/integrations/xeroLogo.svg?react';
import { useGlobalState } from 'context/GlobalState';
import { Avatar } from 'elements';
import { AccountingSystem, ExportFormat } from 'services/constants';
import { useFlags } from 'services/featureflags';

const AccSystemLogoComponent = () => {
  const { datevExportEnabled } = useFlags();
  const {
    state: { accountingSettings },
  } = useGlobalState();

  if (
    datevExportEnabled &&
    accountingSettings?.useAccountingExport &&
    accountingSettings?.accountingSystem === AccountingSystem.datev &&
    accountingSettings?.exportFormats.includes(ExportFormat.api)
  )
    return (
      <Avatar
        variant="square"
        sx={{ background: 'none', ml: 'auto' }}
        src={DatevAvatar}
      />
    );

  if (
    accountingSettings?.useAccountingExport &&
    accountingSettings?.accountingSystem === AccountingSystem.bmd
  )
    return (
      <Avatar
        variant="square"
        sx={{ background: 'none', ml: 'auto' }}
        src={BmdAvatar}
      />
    );

  if (
    accountingSettings?.accountingSystem === AccountingSystem.lexOffice &&
    accountingSettings?.useAccountingExport
  )
    return (
      <Avatar
        variant="square"
        sx={{
          background: 'none',
          ml: 'auto',
          img: { objectFit: 'contain' },
        }}
        src={LexwareOfficeAvatar}
      />
    );

  if (
    accountingSettings?.accountingSystem ===
      AccountingSystem.microsoftDynamics365BusinessCentral &&
    accountingSettings.useAccountingExport
  )
    return (
      <Avatar variant="square" sx={{ background: 'none', ml: 'auto' }}>
        <MicrosoftDynamics365Avatar />
      </Avatar>
    );

  if (
    accountingSettings?.accountingSystem === AccountingSystem.quickbooks &&
    accountingSettings.useAccountingExport
  )
    return (
      <Avatar
        variant="square"
        sx={{ background: 'none', ml: 'auto' }}
        src={QuickbooksAvatar}
      />
    );

  if (
    accountingSettings?.accountingSystem === AccountingSystem.xero &&
    accountingSettings.useAccountingExport
  )
    return (
      <Avatar variant="square" sx={{ background: 'none', ml: 'auto' }}>
        <XeroAvatar />
      </Avatar>
    );

  if (
    accountingSettings?.accountingSystem === AccountingSystem.oracleNetSuite &&
    accountingSettings?.useAccountingExport
  )
    return (
      <Avatar variant="square" sx={{ background: 'none', ml: 'auto' }}>
        <OracleNetsuiteAvatar />
      </Avatar>
    );

  return null;
};

export default AccSystemLogoComponent;
