import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useGlobalState, useVisibleCardAccounts } from 'context/GlobalState';
import { CardAccountFilter } from 'domains/card/components';
import {
  CategoryFilter,
  FlagReasonFilter,
  ProjectFilter,
  ReceiptFilter,
  StatusFilter,
  TeamFilter,
} from 'domains/transaction/components';
import useCurrentApp from 'hooks/useCurrentApp';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import {
  MerchantCategory,
  PrivateExpenseStatus,
  privateExpenseStatuses,
  ReviewFlagReason,
  TransactionReceiptStatus,
  TransactionStatus,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';
import { ProjectsData, TeamsData } from './useFilters';
import { QueryParams, visibleTransactionStatuses } from '.././index';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  teamData: TeamsData;
  projectData: ProjectsData;
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
  setFilters: ({
    projects,
    teams,
  }: {
    projects: { value: ProjectsData['value'] };
    teams: { value: TeamsData['value'] };
  }) => void;
}

interface State {
  status: TransactionStatus[];
  category: MerchantCategory[];
  teams: TeamsData['value'];
  projects: ProjectsData['value'];
  receipt: TransactionReceiptStatus | '';
  fromDate: Moment | null;
  toDate: Moment | null;
  flagReason: ReviewFlagReason | '';
  privateExpenseStatus: PrivateExpenseStatus[];
  cardAccountId: string;
}

const initialState: State = {
  status: [],
  category: [],
  teams: [],
  projects: [],
  receipt: '',
  fromDate: null,
  toDate: null,
  flagReason: '',
  privateExpenseStatus: [],
  cardAccountId: '',
};

const FiltersDrawer = ({
  teamData,
  projectData,
  params,
  setParam,
  setFilters,
  ...props
}: Props) => {
  const canUser = useCanUser();
  const { isAdminApp } = useCurrentApp();
  const {
    state: { featureModules, accountingSettings },
  } = useGlobalState();
  const visibleCardAccounts = useVisibleCardAccounts();
  const [state, setState] = useState<State>(initialState);
  const { companyReimbursementEnabled } = useFlags();

  const areFiltersSelected = !!(
    state.status.length ||
    state.category.length ||
    teamData.value.length ||
    projectData.value.length ||
    state.fromDate ||
    state.toDate ||
    state.flagReason ||
    state.privateExpenseStatus.length ||
    state.cardAccountId
  );

  useEffect(() => {
    if (props.open)
      setState({
        status: params.status,
        category: params.category,
        teams: teamData.value,
        projects: projectData.value,
        receipt: params.receipt,
        fromDate: params.fromDate,
        toDate: params.toDate,
        flagReason: params.flagReason,
        privateExpenseStatus: params.privateExpenseStatus,
        cardAccountId: params.cardAccountId,
      });
  }, [props.open]);

  const onFiltersApply = () => {
    const { projects, teams, ...stateToPass } = state;
    setParam(
      Object.entries({
        ...stateToPass,
        projectIds: projects.map((project) => project.id),
        teamId: teams.map((team) => team.id),
        fromDate: stateToPass.fromDate?.startOf('day')?.format(),
        toDate: stateToPass.toDate?.endOf('day')?.format(),
      })
    );
    setFilters({
      projects: { value: projects },
      teams: { value: teams },
    });
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.entries({ ...initialState, projectIds: [], teamId: [] }));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <StatusFilter
        value={state.status}
        onChange={(value) =>
          setState((prevState) => ({
            ...prevState,
            status: xor(prevState.status, [value]),
          }))
        }
        options={visibleTransactionStatuses}
      />

      <DateFilter
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate: fromDate?.startOf('day') || null,
            toDate: toDate?.endOf('day') || null,
          }))
        }
      />

      {visibleCardAccounts.length > 1 && (
        <CardAccountFilter
          value={state.cardAccountId}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, cardAccountId: value }))
          }
        />
      )}

      {featureModules.RECEIPT_MANAGEMENT && (
        <ReceiptFilter
          value={state.receipt}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, receipt: value }))
          }
        />
      )}

      <CategoryFilter
        value={state.category}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, category: value }))
        }
      />

      {featureModules.TEAMS &&
        accountingSettings?.costCenterEnabled &&
        (isAdminApp || canUser('team:manage')) && (
          <TeamFilter
            teamData={teamData}
            value={state.teams}
            onChange={(value) =>
              setState((prevState) => ({ ...prevState, teams: value }))
            }
          />
        )}

      {featureModules.ACCOUNTING_FEATURES &&
        accountingSettings?.projectEnabled && (
          <ProjectFilter
            projectData={projectData}
            value={state.projects}
            onChange={(value) =>
              setState((prevState) => ({ ...prevState, projects: value }))
            }
          />
        )}

      <FlagReasonFilter
        value={state.flagReason}
        onChange={(flagReason) =>
          setState((prevState) => ({
            ...prevState,
            flagReason,
            privateExpenseStatus:
              flagReason === ReviewFlagReason.privateExpense &&
              featureModules.COMPANY_REIMBURSEMENT &&
              companyReimbursementEnabled
                ? [...privateExpenseStatuses]
                : [],
          }))
        }
        privateExpenseStatusesValue={state.privateExpenseStatus}
        onPrivateExpenseStatusChange={(values) =>
          setState((prevState) => ({
            ...prevState,
            privateExpenseStatus: xor(prevState.privateExpenseStatus, values),
          }))
        }
      />
    </FilterDrawer>
  );
};

export default FiltersDrawer;
