import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';
import { isAxiosError } from 'axios';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { getDocumentUrl } from 'domains/document/utils';
import { PrivatePaymentCard } from 'domains/member/components';
import { getStripeCardStatus } from 'domains/member/utils';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Link,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  NetworkErrorCode,
  PaymentMethod,
  StripeCardStatus,
  TxPrivateExpenseReimbursement,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import usePaymentIntentFinalStateHandler from './usePaymentIntentFinalStateHandler';

export const Label = styled('label')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  min-height: 76px;
  padding: 0 ${({ theme }) => theme.spacing(3)};
  border: 1px solid
    ${({ theme }) => theme.variables.input.outlined.enabledBorder};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

interface State {
  reimbursement: TxPrivateExpenseReimbursement | null;
  paymentMethod: PaymentMethod | null;
  selectedPaymentMethod: 'existing' | 'new';
  isLoading: boolean;
  isReimbursing: boolean;
  acceptedTermsAndConditions: boolean;
  alreadyAcceptedTermsAndConditions: boolean;
}

interface Props {
  transactionId: string;
  txPrivateExpenseReimbursement: TxPrivateExpenseReimbursement | null;
  showReimburseLaterText: boolean;
  onPaymentMethodRequire: (clientSecret: string) => void;
  onClose: () => void;
}

const ConfirmPaymentStep = ({
  transactionId,
  txPrivateExpenseReimbursement,
  showReimburseLaterText,
  onPaymentMethodRequire,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { organization, member },
  } = useGlobalState();
  const mounted = useMounted();
  const stripe = useStripe();
  const handlePaymentIntentFinalState = usePaymentIntentFinalStateHandler(
    transactionId
  );
  const [state, setState] = useState<State>({
    reimbursement: txPrivateExpenseReimbursement,
    selectedPaymentMethod: 'existing',
    paymentMethod: null,
    isLoading: true,
    isReimbursing: false,
    acceptedTermsAndConditions: false,
    alreadyAcceptedTermsAndConditions: false,
  });

  const getData = async () => {
    try {
      const { paymentMethods } = await api.getPaymentMethods(member.id);
      const { isAccepted } = paymentMethods.length
        ? { isAccepted: true }
        : await api.checkCardholderReimbursementTermsAndConditions(member.id);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        selectedPaymentMethod:
          !paymentMethods[0] ||
          getStripeCardStatus(paymentMethods[0]) === StripeCardStatus.expired
            ? 'new'
            : 'existing',
        paymentMethod: paymentMethods[0] ?? null,
        acceptedTermsAndConditions: isAccepted,
        alreadyAcceptedTermsAndConditions: isAccepted,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      onClose();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handlePaymentIntentInitialState = async (clientSecret: string) => {
    if (!stripe) return;

    const { error, paymentIntent } = await stripe.retrievePaymentIntent(
      clientSecret
    );

    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setState((prevState) => ({ ...prevState, isReimbursing: true }));
    } else {
      switch (paymentIntent.status) {
        case 'requires_payment_method': {
          if (state.selectedPaymentMethod === 'existing') {
            const result = await stripe.confirmPayment({
              clientSecret,
              confirmParams: {
                payment_method: state.paymentMethod!.stripePaymentMethodId,
                return_url: window.location.origin,
              },
              redirect: 'if_required',
            });
            handlePaymentIntentFinalState(result, onClose, () =>
              setState((prevState) => ({
                ...prevState,
                isReimbursing: false,
              }))
            );
          } else {
            onPaymentMethodRequire(clientSecret);
          }
          break;
        }
        case 'requires_confirmation':
        case 'requires_action': {
          const result = await stripe.confirmPayment({
            clientSecret,
            confirmParams: { return_url: window.location.origin },
            redirect: 'if_required',
          });
          handlePaymentIntentFinalState(result, onClose, () =>
            setState((prevState) => ({ ...prevState, isReimbursing: false }))
          );
          break;
        }
        case 'requires_capture':
        case 'succeeded': {
          handlePaymentIntentFinalState({ error, paymentIntent }, onClose);
          break;
        }
        default:
          enqueueSnackbar(t('errors.general'), { variant: 'error' });
          setState((prevState) => ({ ...prevState, isReimbursing: false }));
      }
    }
  };

  const reimbursePrivateExpense = async () => {
    try {
      setState((prevState) => ({ ...prevState, isReimbursing: true }));

      if (!state.alreadyAcceptedTermsAndConditions) {
        await api.acceptCardholderReimbursementTermsAndConditions(
          member.id,
          organization!.id
        );
        if (!mounted.current) return;
        setState((prevState) => ({
          ...prevState,
          alreadyAcceptedTermsAndConditions: true,
        }));
      }

      let clientSecret = '';
      if (state.reimbursement?.clientSecret) {
        clientSecret = state.reimbursement.clientSecret;
      } else {
        const reimbursement = await api
          .initiateTxPrivateExpenseReimbursement(member.id, transactionId)
          .catch((error) => {
            if (
              getNetworkErrorCode(error) ===
              NetworkErrorCode.availablePrivateReimbursementForTrxExists
            ) {
              return api.getTxPrivateExpenseReimbursement(transactionId);
            } else {
              throw error;
            }
          });
        clientSecret = reimbursement.clientSecret!;
        if (!mounted.current) return;
        setState((prevState) => ({ ...prevState, reimbursement }));
      }

      await handlePaymentIntentInitialState(clientSecret);
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isReimbursing: false }));
      const message =
        isAxiosError(error) &&
        error.response?.data.message.startsWith('Stripe:')
          ? t('transactionDetails.reimbursementDialog.errors.stripeError')
          : getGenericErrorMsg(error);
      enqueueSnackbar(message, { variant: 'error' });
      logError(error);
    }
  };

  const renderContent = () => {
    if (state.isLoading) return <Box height={70} />;

    if (!state.paymentMethod)
      return (
        <>
          <Typography variant="body1">
            {t(
              'transactionDetails.reimbursementDialog.descriptions.noPaymentMethod'
            )}
          </Typography>

          {!state.alreadyAcceptedTermsAndConditions && (
            <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.acceptedTermsAndConditions}
                    onChange={() =>
                      setState((prevState) => ({
                        ...prevState,
                        acceptedTermsAndConditions: !prevState.acceptedTermsAndConditions,
                      }))
                    }
                  />
                }
                componentsProps={{
                  typography: { color: 'text.secondary', variant: 'body2' },
                }}
                label={
                  <Trans
                    i18nKey="transactionDetails.reimbursementDialog.termsAndConditions"
                    components={{
                      a: (
                        <Link
                          color="inherit"
                          href={getDocumentUrl(
                            'COMPANY_REIMBURSEMENT_CARDHOLDER.pdf'
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                }
              />
            </Box>
          )}
        </>
      );

    const status = getStripeCardStatus(state.paymentMethod);

    if (status === StripeCardStatus.declined)
      return (
        <>
          <Typography mb={4}>
            {t(
              'transactionDetails.reimbursementDialog.descriptions.declinedPaymentMethod'
            )}
          </Typography>
          <RadioGroup
            value={state.selectedPaymentMethod}
            onChange={(e, value) =>
              setState((prevState) => ({
                ...prevState,
                selectedPaymentMethod: value as State['selectedPaymentMethod'],
              }))
            }
          >
            <Label sx={{ mb: 1.5 }}>
              <PrivatePaymentCard paymentMethod={state.paymentMethod} />
              <Radio value="existing" />
            </Label>
            <Label>
              <Typography>
                {t(
                  'transactionDetails.reimbursementDialog.reimburseWithNewCard'
                )}
              </Typography>
              <Radio value="new" />
            </Label>
          </RadioGroup>
        </>
      );

    return (
      <>
        <Typography mb={3}>
          {t(
            `transactionDetails.reimbursementDialog.descriptions.${
              status === StripeCardStatus.expired
                ? 'expiredPaymentMethod'
                : 'validPaymentMethod'
            }`
          )}
        </Typography>
        <PrivatePaymentCard paymentMethod={state.paymentMethod} />
      </>
    );
  };

  return (
    <>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {showReimburseLaterText
            ? t('transactionDetails.reimbursementDialog.reimburseLater')
            : t('common.button.cancel')}
        </Button>
        <Button
          onClick={reimbursePrivateExpense}
          disabled={
            state.isLoading ||
            state.isReimbursing ||
            !state.acceptedTermsAndConditions ||
            !stripe
          }
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={state.isReimbursing} />
      <LoaderWithOverlay
        loading={state.isLoading}
        BackdropProps={{
          appear: false,
          sx: (theme) => ({ background: theme.palette.common.white }),
        }}
      />
    </>
  );
};

export default ConfirmPaymentStep;
