import {
  IntegrationType,
  Transaction,
  TransactionExportStatus,
} from 'services/constants';

export const isTransactionReadonly = (
  transaction: Transaction,
  isAdminApp: boolean,
  isPortalAppMirrorView: boolean
) => {
  if (isPortalAppMirrorView) return true;

  const isReadonlyForApp = isAdminApp
    ? transaction.exportStatus === TransactionExportStatus.exported
    : [
        TransactionExportStatus.inExportQueue,
        TransactionExportStatus.exported,
      ].includes(transaction.exportStatus);

  return (
    (transaction.integrationType === IntegrationType.circula ||
      transaction.integrationType === IntegrationType.none) &&
    isReadonlyForApp
  );
};

export const isTxFlaggingFrozenByExport = (transaction: Transaction) =>
  [
    TransactionExportStatus.inExportQueue,
    TransactionExportStatus.exported,
    TransactionExportStatus.exporting,
  ].includes(transaction.exportStatus);
