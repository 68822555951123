import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { internalOrgPaths } from 'components/App';
import { Button } from 'components/Button';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OrganizationStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

const ResumeApplicationButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const history = useHistory();
  const {
    state: { organization },
    dispatch,
  } = useGlobalState();
  const { isOrgCreatedWithNewOnboardingFlow } = useIsOrgInOnboarding();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const resumeApplication = async () => {
    try {
      setIsLoading(true);
      const updatedOrg = await api.resumeOnboarding(organization!.id);
      if (!mounted.current) return;
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          organization: {
            ...organization,
            ...updatedOrg,
          },
        },
      });
      if (updatedOrg.status === OrganizationStatus.onboarding) {
        history.push(
          generatePath(internalOrgPaths.orgOnboarding, {
            orgId: organization!.id,
          })
        );
      }
      setIsLoading(false);
      setConfirmDialogOpen(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return canUser('org:resume-onboarding') ? (
    <>
      <Button onClick={() => setConfirmDialogOpen(true)}>
        {t('int.riskAndCompliancePage.resumeOnboardingButton')}
      </Button>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onSuccess={resumeApplication}
        title={t(
          'int.riskAndCompliancePage.resumeOnboardingConfirmationDialogTitle'
        )}
        description={
          !isOrgCreatedWithNewOnboardingFlow && (
            <Trans
              i18nKey="int.riskAndCompliancePage.resumeOnboardingConfirmationDialogText"
              values={organization!}
              components={{ b: <b /> }}
            />
          )
        }
        loading={isLoading}
      />
    </>
  ) : null;
};

export default ResumeApplicationButton;
