import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import TextField, { TextFieldProps } from 'elements/TextField';

const allowedDecimalSeparators = ['.', ','];

export type PercentFieldProps = Omit<
  NumberFormatProps<TextFieldProps>,
  'onChange'
>;

const PercentField = (props: PercentFieldProps) => {
  const { i18n } = useTranslation();
  const decimalSeparator = useMemo(() => (i18n.language === 'de' ? ',' : '.'), [
    i18n.language,
  ]);

  return (
    <NumberFormat
      customInput={TextField}
      allowNegative={false}
      allowedDecimalSeparators={allowedDecimalSeparators}
      decimalSeparator={decimalSeparator}
      decimalScale={2}
      suffix="%"
      {...props}
    />
  );
};

export default PercentField;
