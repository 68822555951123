import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useGlobalState } from 'context/GlobalState';
import useCurrentApp from 'hooks/useCurrentApp';
import {
  AdditionalInfo,
  CreditApplication,
  NetworkErrorCode,
  OnboardingGroup,
  OnboardingSection,
  OnboardingSections,
  OnboardingSectionType,
  OnboardingTask,
  OnboardingTaskAnnualRevenue,
  OnboardingTaskBalanceSheetTotal,
  OnboardingTaskCitizenships,
  OnboardingTaskCompanyType,
  OnboardingTaskDateOfBirth,
  OnboardingTaskDeliveryAddress,
  OnboardingTaskFoundationDate,
  OnboardingTaskIdentification,
  OnboardingTaskOrgName,
  OnboardingTaskPepFatca,
  OnboardingTaskQA,
  OnboardingTaskRegAddress,
  OnboardingTaskRegNumber,
  OnboardingTasks,
  OnboardingTaskStaffHeadcount,
  OnboardingTaskTaxId,
  OnboardingTaskTerms,
  OnboardingTaskVatId,
  ResetTaskPayload,
  UpdateCitizenshipsPayload,
  UpdateCompanyTypePayload,
  UpdateDateOfBirthPayload,
  UpdateIdentificationPayload,
  UpdateOrgAnnualRevenuePayload,
  UpdateOrgBalanceSheetTotalPayload,
  UpdateOrgBasePayload,
  UpdateOrgDeliveryAddressPayload,
  UpdateOrgFoundationDatePayload,
  UpdateOrgNamePayload,
  UpdateOrgRegAddressPayload,
  UpdateOrgRegNumberPayload,
  UpdateOrgStaffHeadcountPayload,
  UpdateOrgVatIdPayload,
  UpdatePepFatcaPayload,
  UpdateTaxIdPayload,
} from 'services/constants';
import { getNetworkErrorCode } from 'services/utils';
import { onboardingKeys } from './queryKeys';
import useRequest from './useRequest';

export const useOnboardingQuery = (request: ReturnType<typeof useRequest>) => {
  const {
    state: { organization },
  } = useGlobalState();
  const { isPortalApp } = useCurrentApp();
  const organizationId = organization!.id;

  const useGetAdditionalInfo = (
    queryOptions?: UseQueryOptions<AdditionalInfo>
  ) =>
    useQuery<AdditionalInfo>({
      queryKey: ['getAdditionalInfo', organizationId],
      queryFn: () =>
        request<AdditionalInfo>(
          `/organizations/${organizationId}/additional-info`
        ),
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    });

  const useGetOnboardingSections = (
    queryOptions?: UseQueryOptions<OnboardingSections>
  ) =>
    useQuery<OnboardingSections>({
      queryKey: onboardingKeys.sections(organizationId),
      queryFn: () =>
        request<OnboardingSections>(
          isPortalApp
            ? `/onboarding/${organizationId}/partner-sections`
            : `/onboarding/${organizationId}/sections`
        ),
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    });

  const useGetCreditApplication = (
    queryOptions?: UseQueryOptions<CreditApplication | null>
  ) =>
    useQuery<CreditApplication | null>({
      queryKey: ['getCreditApplication', organizationId],
      queryFn: async () => {
        try {
          const response = await request<CreditApplication>(
            `/onboarding/${organizationId}/credit-application`
          );
          return response;
        } catch (error) {
          if (
            getNetworkErrorCode(error) ===
            NetworkErrorCode.creditApplicationNotFound
          ) {
            return null;
          }
          throw error;
        }
      },
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    });

  const useGetOnboardingTasks = (
    sectionType: OnboardingSectionType,
    queryOptions?: UseQueryOptions<OnboardingTasks>
  ) =>
    useQuery<OnboardingTasks>({
      queryKey: onboardingKeys.tasks(organizationId, sectionType),
      queryFn: () =>
        request<OnboardingTasks>(
          isPortalApp
            ? `/onboarding/${organizationId}/partner-sections/tasks`
            : `/onboarding/${organizationId}/sections/tasks`,
          { params: { sectionType } }
        ),
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    });

  const useGetOnboardingIdentificationTask = (
    taskId: string,
    queryOptions?: UseQueryOptions<OnboardingTaskIdentification>
  ) =>
    useQuery<OnboardingTaskIdentification>({
      queryKey: onboardingKeys.task(organizationId, taskId),
      queryFn: () =>
        request(`/onboarding/${organizationId}/tasks/identification/${taskId}`),
      ...queryOptions,
    });

  const useGetOnboardingTermsTask = (
    taskId: string,
    queryOptions?: UseQueryOptions<OnboardingTaskTerms>
  ) =>
    useQuery<OnboardingTaskTerms>({
      queryKey: onboardingKeys.task(organizationId, taskId),
      queryFn: () =>
        request(`/onboarding/${organizationId}/tasks/terms/${taskId}`),
      ...queryOptions,
    });

  const useGetOnboardingGroupTasks = (
    taskGroupId: string,
    queryOptions?: UseQueryOptions<OnboardingTasks>
  ) =>
    useQuery<OnboardingTasks>({
      queryKey: onboardingKeys.groupTasks(organizationId, taskGroupId),
      queryFn: () =>
        request<OnboardingTasks>(
          `/onboarding/${organizationId}/task-group/${taskGroupId}/tasks`
        ),
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    });

  const useCreateCreditApplication = (
    mutationOptions?: UseMutationOptions<
      CreditApplication,
      unknown,
      Omit<CreditApplication, 'status'>
    >
  ) =>
    useMutation({
      mutationFn: (data) =>
        request(`/onboarding/${organizationId}/credit-application`, {
          method: 'post',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdateCreditApplication = (
    mutationOptions?: UseMutationOptions<
      CreditApplication,
      unknown,
      Omit<CreditApplication, 'status'>
    >
  ) =>
    useMutation({
      mutationFn: (data) =>
        request(`/onboarding/${organizationId}/credit-application`, {
          method: 'put',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdateOrgName = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskOrgName,
      unknown,
      UpdateOrgNamePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/organization-name/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateRegisteredAddress = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskRegAddress,
      unknown,
      UpdateOrgRegAddressPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/registered-address/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateDeliveryAddress = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskDeliveryAddress,
      unknown,
      UpdateOrgDeliveryAddressPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/delivery-address/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateRegisteredNumber = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskRegNumber,
      unknown,
      UpdateOrgRegNumberPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/registered-number/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateFoundationDate = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskFoundationDate,
      unknown,
      UpdateOrgFoundationDatePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/foundation-date/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateVatId = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskVatId,
      unknown,
      UpdateOrgVatIdPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(`/onboarding/${organizationId}/tasks/vat-id/${taskId}`, {
          method: 'put',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdateAnnualRevenue = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskAnnualRevenue,
      unknown,
      UpdateOrgAnnualRevenuePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/annual-revenue/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateBalanceSheetTotal = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskBalanceSheetTotal,
      unknown,
      UpdateOrgBalanceSheetTotalPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/balance-sheet-total/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateStaffHeadcount = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskStaffHeadcount,
      unknown,
      UpdateOrgStaffHeadcountPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/staff-headcount/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateCompanyType = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskCompanyType,
      unknown,
      UpdateCompanyTypePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(`/onboarding/${organizationId}/tasks/company-type/${taskId}`, {
          method: 'put',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdatePepFatca = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskPepFatca,
      unknown,
      UpdatePepFatcaPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(`/onboarding/${organizationId}/tasks/pep-fatca/${taskId}`, {
          method: 'put',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdateTaxId = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskTaxId,
      unknown,
      UpdateTaxIdPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(`/onboarding/${organizationId}/tasks/tax-id/${taskId}`, {
          method: 'put',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdateIdentification = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskIdentification,
      unknown,
      UpdateIdentificationPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/identification/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateDateOfBirth = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskDateOfBirth,
      unknown,
      UpdateDateOfBirthPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(`/onboarding/${organizationId}/tasks/date-of-birth/${taskId}`, {
          method: 'put',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdateCitizenships = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskCitizenships,
      unknown,
      UpdateCitizenshipsPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(`/onboarding/${organizationId}/tasks/citizenships/${taskId}`, {
          method: 'put',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdateQA = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskQA,
      unknown,
      UpdateOrgBasePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId }) =>
        request(`/onboarding/${organizationId}/tasks/question/${taskId}`, {
          method: 'post',
        }),
      ...mutationOptions,
    });

  const useUpdateTerms = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskTerms,
      unknown,
      UpdateOrgBasePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId }) =>
        request(`/onboarding/${organizationId}/tasks/terms/${taskId}`, {
          method: 'post',
        }),
      ...mutationOptions,
    });

  const useSubmitOnboardingSection = (
    mutationOptions?: UseMutationOptions<
      OnboardingSection,
      unknown,
      { type: OnboardingSectionType }
    >
  ) =>
    useMutation({
      mutationFn: ({ type }) =>
        request(`/onboarding/${organizationId}/sections/submit`, {
          method: 'post',
          data: { type },
        }),
      ...mutationOptions,
    });

  const useSubmitOnboardingTaskGroup = (
    mutationOptions?: UseMutationOptions<
      OnboardingGroup,
      unknown,
      { taskGroupId: string }
    >
  ) =>
    useMutation({
      mutationFn: ({ taskGroupId }) =>
        request(
          `/onboarding/${organizationId}/task-group/${taskGroupId}/submit`,
          { method: 'post' }
        ),
      ...mutationOptions,
    });

  const useResetTask = (
    mutationOptions?: UseMutationOptions<
      OnboardingTask,
      unknown,
      ResetTaskPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ taskId, data }) =>
        request(
          `/onboarding/${organizationId}/task-management/reset-task/${taskId}`,
          {
            method: 'post',
            data,
          }
        ),
      ...mutationOptions,
    });

  return {
    useGetAdditionalInfo,
    useGetOnboardingSections,
    useGetCreditApplication,
    useGetOnboardingTasks,
    useGetOnboardingIdentificationTask,
    useGetOnboardingTermsTask,
    useGetOnboardingGroupTasks,
    useCreateCreditApplication,
    useUpdateCreditApplication,
    useUpdateOrgName,
    useUpdateRegisteredAddress,
    useUpdateDeliveryAddress,
    useUpdateRegisteredNumber,
    useUpdateFoundationDate,
    useUpdateVatId,
    useUpdateAnnualRevenue,
    useUpdateBalanceSheetTotal,
    useUpdateStaffHeadcount,
    useUpdateCompanyType,
    useUpdatePepFatca,
    useUpdateTaxId,
    useUpdateIdentification,
    useUpdateDateOfBirth,
    useUpdateCitizenships,
    useUpdateQA,
    useUpdateTerms,
    useSubmitOnboardingSection,
    useSubmitOnboardingTaskGroup,
    useResetTask,
  };
};
