import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { DialogProps, withDialogWrapper } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card, IntegrationType } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (card: Card) => void;
  card: Card;
}

const ToggleCardSyncInCirculaDialog = ({
  onSuccess,
  card,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const isEnabled = useMemo(
    () => card.integrationType === IntegrationType.circula,
    []
  );

  const toggleIntegration = async () => {
    try {
      setIsLoading(true);
      if (isEnabled) {
        await api.removeCardFromCircula(card.organizationId, card.cardId);
      } else {
        await api.addCardToCircula(card.organizationId, card.cardId);
      }
      if (!mounted.current) return;
      onSuccess({
        ...card,
        integrationType: isEnabled ? null : IntegrationType.circula,
      });
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <ConfirmDialog
      {...props}
      onSuccess={toggleIntegration}
      loading={isLoading}
      title={
        isEnabled
          ? t('toggleCardSyncInCirculaDialog.disable.title')
          : t('toggleCardSyncInCirculaDialog.enable.title')
      }
      description={
        isEnabled
          ? t('toggleCardSyncInCirculaDialog.disable.description')
          : t('toggleCardSyncInCirculaDialog.enable.description')
      }
      cancelButtonProps={{
        children: isEnabled
          ? t('toggleCardSyncInCirculaDialog.disable.noLabel')
          : t('toggleCardSyncInCirculaDialog.enable.noLabel'),
      }}
      confirmButtonProps={
        isEnabled
          ? {
              color: 'error',
              children: t('toggleCardSyncInCirculaDialog.disable.yesLabel'),
            }
          : { children: t('toggleCardSyncInCirculaDialog.enable.yesLabel') }
      }
    />
  );
};

export default withDialogWrapper<Props>(ToggleCardSyncInCirculaDialog);
