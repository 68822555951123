import { useTranslation } from 'react-i18next';
import { Chip } from 'elements';
import { env } from 'env';

// this component will be updated in scope of sidebar redesign
const EnvChip = () => {
  const { t } = useTranslation();

  if (env.REACT_APP_TARGET_ENV === 'prod') return null;

  return (
    <Chip
      label={t(`environment.${env.REACT_APP_TARGET_ENV}`)}
      color="info"
      variant="tinted"
      size="small"
    />
  );
};

export default EnvChip;
