import { ReactNode, useMemo } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AlertWarningCircleIcon from 'assets/icons/interface/alertWarningCircle.svg?react';
import { StatusBadge } from 'components/Badge';
import { Button } from 'components/Button';
import Tooltip from 'components/Tooltip';
import { useGlobalState } from 'context/GlobalState';
import { FIN_API_SUPPORTED_COUNTRIES } from 'domains/billing/constants';
import {
  Box,
  Button as ButtonMui,
  StatusBadge as MuiStatusBadge,
  TableCell,
  Typography,
  WarningCircleIcon,
} from 'elements';
import {
  BankAccount,
  BankAccountType,
  BankConnection,
} from 'services/constants';
import { useCanUser } from 'services/rbac';

const Wrapper = styled.div.attrs({ 'data-test-id': 'connection-box-wrapper' })`
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
`;

const StyledAlertWarningCircleIcon = styled(AlertWarningCircleIcon)`
  width: 24px;
  height: 24px;
  margin: 1px 40px 0 0;
  fill: ${({ theme }) => theme.colors.financeRed40};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 150px;
  margin: 0 0 0 50px;
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey100};
`;

const StyledStatusBadge = styled(StatusBadge)`
  margin: 0;
`;

enum ConnectionStatus {
  active = 'ACTIVE',
  notConnected = 'NOT_CONNECTED',
}

const getExpiresInDaysAndExpiryLabel = ({ expiresAt }: BankConnection) => {
  let expiresInDays: null | number = null;
  let expiryLabel: ReactNode = null;

  if (expiresAt) {
    expiresInDays = moment(expiresAt).diff(moment(), 'days');

    if (expiresInDays > 14) {
      expiryLabel = i18next.t('bankAccountsTable.expiresTime', {
        count: expiresInDays,
      });
    } else {
      if (moment().isAfter(expiresAt)) {
        expiryLabel = i18next.t('bankAccountsTable.expired');
      } else {
        expiryLabel = i18next.t('bankAccountsTable.expiresSoon');
      }
    }
  }

  return { expiresInDays, expiryLabel };
};

const getConnectionStatus = ({
  expiresAt,
  userActionRequired,
}: BankConnection): ConnectionStatus => {
  if ((expiresAt && moment().isAfter(expiresAt)) || userActionRequired)
    return ConnectionStatus.notConnected;

  return ConnectionStatus.active;
};

const ConnectionStatusBadge = ({ status }: { status: ConnectionStatus }) => {
  const { t } = useTranslation();
  const type = status === ConnectionStatus.active ? 'primary' : 'dark';

  return (
    <StyledStatusBadge type={type}>
      {t(`bankAccountsTable.connectionStatuses.${status}`)}
    </StyledStatusBadge>
  );
};

interface Props {
  isNewDesign?: boolean;
  bankAccountsOfConnection: BankAccount[];
  bankConnections: BankConnection[];
  onConnect: (item: BankAccount) => void;
  onReconnect: (item: BankAccount) => void;
  onRemove: (item: BankConnection) => void;
}

const ConnectionBox = ({
  isNewDesign = false,
  bankAccountsOfConnection,
  bankConnections,
  onConnect,
  onReconnect,
  onRemove,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const bankAccount = bankAccountsOfConnection[0];
  const bankConnection = useMemo(
    () =>
      bankConnections.find((item) => item.id === bankAccount.bankConnectionId),
    [bankAccount, bankConnections]
  );

  if (
    bankAccount.detectedAsTransferSender &&
    bankAccount.bankAccountType === BankAccountType.manual
  ) {
    return isNewDesign ? (
      <>
        <TableCell />
        <TableCell />
      </>
    ) : (
      <Wrapper />
    );
  }

  // This block is a copy of the block below.
  // Should replace it in scope of Onboarding redesign
  if (!bankConnection && isNewDesign) {
    return organization?.bankConnectionRequired ? (
      <>
        <TableCell align="right">
          {canUser('bank-account:connect') &&
            FIN_API_SUPPORTED_COUNTRIES.includes(organization.country) && (
              <ButtonMui onClick={() => onConnect(bankAccount)}>
                {t('bankAccountsTable.connectAccount')}
              </ButtonMui>
            )}
        </TableCell>
        <TableCell>
          <MuiStatusBadge
            label={t(
              `bankAccountsTable.connectionStatuses.${ConnectionStatus.notConnected}`
            )}
            color="primary"
          />
        </TableCell>
      </>
    ) : (
      <>
        <TableCell />
        <TableCell />
      </>
    );
  }

  if (!bankConnection) {
    return organization?.bankConnectionRequired ? (
      <Wrapper>
        {canUser('bank-account:connect') &&
          FIN_API_SUPPORTED_COUNTRIES.includes(organization.country) && (
            <Button
              onClick={() => onConnect(bankAccount)}
              $px={16}
              $minWidth="215px"
            >
              {t('bankAccountsTable.connectAccount')}
            </Button>
          )}
        <InfoWrapper>
          <ConnectionStatusBadge status={ConnectionStatus.notConnected} />
        </InfoWrapper>
      </Wrapper>
    ) : (
      <Wrapper />
    );
  }

  const status = getConnectionStatus(bankConnection);
  const { expiresInDays, expiryLabel } = getExpiresInDaysAndExpiryLabel(
    bankConnection
  );

  // This block is a copy of the block below.
  // Should replace it in scope of Onboarding redesign
  if (isNewDesign)
    return (
      <>
        <TableCell align="right">
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {canUser('bank-connection:view-error') &&
              !!bankConnection.dataUpdateError && (
                <Tooltip
                  title={bankConnection.dataUpdateError}
                  $isWide
                  interactive
                >
                  <WarningCircleIcon color="error" sx={{ mr: 3 }} />
                </Tooltip>
              )}
            {canUser(
              'bank-connection:remove',
              bankConnection,
              bankAccountsOfConnection
            ) && (
              <ButtonMui
                variant="outlined"
                onClick={() => onRemove(bankConnection)}
              >
                {t('bankAccountsTable.removeConnection')}
              </ButtonMui>
            )}
            {canUser('bank-account:connect') &&
              ((typeof expiresInDays === 'number' && expiresInDays <= 14) ||
                status === ConnectionStatus.notConnected) &&
              FIN_API_SUPPORTED_COUNTRIES.includes(organization!.country) && (
                <ButtonMui onClick={() => onReconnect(bankAccount)}>
                  {t('bankAccountsTable.renewConnection')}
                </ButtonMui>
              )}
          </Box>
        </TableCell>
        <TableCell>
          <MuiStatusBadge
            label={t(`bankAccountsTable.connectionStatuses.${status}`)}
            color="primary"
          />
          <Typography mt={0.5} variant="body2" color="textSecondary">
            {expiryLabel}
          </Typography>
        </TableCell>
      </>
    );

  return (
    <Wrapper>
      {canUser('bank-connection:view-error') &&
        !!bankConnection.dataUpdateError && (
          <Tooltip title={bankConnection.dataUpdateError} $isWide interactive>
            <StyledAlertWarningCircleIcon />
          </Tooltip>
        )}
      {canUser(
        'bank-connection:remove',
        bankConnection,
        bankAccountsOfConnection
      ) && (
        <Button
          variant="outlined"
          onClick={() => onRemove(bankConnection)}
          $px={16}
          $minWidth="215px"
        >
          {t('bankAccountsTable.removeConnection')}
        </Button>
      )}
      {canUser('bank-account:connect') &&
        ((typeof expiresInDays === 'number' && expiresInDays <= 14) ||
          status === ConnectionStatus.notConnected) &&
        FIN_API_SUPPORTED_COUNTRIES.includes(organization!.country) && (
          <Button
            onClick={() => onReconnect(bankAccount)}
            $px={16}
            $minWidth="215px"
          >
            {t('bankAccountsTable.renewConnection')}
          </Button>
        )}
      <InfoWrapper>
        <ConnectionStatusBadge status={status} />
        <Label>{expiryLabel}</Label>
      </InfoWrapper>
    </Wrapper>
  );
};

export default ConnectionBox;
