import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
  withDialogWrapper,
} from 'elements';
import useUrls from 'hooks/useUrls';
import {
  MobileAppPlatform,
  PartnerIds,
  PartnerIdType,
} from 'services/constants';

interface Props extends DialogProps {
  onClose: () => void;
}

const PairMobileDeviceDialog = (props: Props) => {
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    COMMERZBANK_APP_STORE_URL,
    COMMERZBANK_GOOGLE_PLAY_URL,
    PLIANT_APP_STORE_URL,
    PLIANT_GOOGLE_PLAY_URL,
  } = useUrls();

  const getMobileAppUrl = (
    platform: MobileAppPlatform,
    partnerId?: PartnerIdType
  ) => {
    if (
      partnerId === PartnerIds.commerzbankSme ||
      partnerId === PartnerIds.commerzbankFk
    ) {
      return platform === MobileAppPlatform.ios
        ? COMMERZBANK_APP_STORE_URL
        : COMMERZBANK_GOOGLE_PLAY_URL;
    }

    return platform === MobileAppPlatform.ios
      ? PLIANT_APP_STORE_URL
      : PLIANT_GOOGLE_PLAY_URL;
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        {t('profilePage.devicePairing.pairMobileDeviceDialog.title')}
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="body1">
            {t('profilePage.devicePairing.pairMobileDeviceDialog.description')}
          </Typography>
        </Box>

        {[1, 2, 3].map((stepIndex) => (
          <Box
            key={stepIndex}
            display="flex"
            flexDirection="row"
            alignItems="center"
            mb={3}
          >
            <Box minWidth={32} mr={2}>
              <Typography variant="h5">{stepIndex}</Typography>
            </Box>
            <Typography variant="body1">
              {t(
                `profilePage.devicePairing.pairMobileDeviceDialog.step${stepIndex}`
              )}
            </Typography>
          </Box>
        ))}

        <Box display="flex" flexDirection="row" justifyContent="center">
          {Object.values(MobileAppPlatform).map((platform) => (
            <Box
              key={platform}
              borderRadius={1}
              padding={1.5}
              mr={3}
              sx={{
                background: (theme) => theme.variables.background.contrast,
              }}
            >
              <QRCode
                value={getMobileAppUrl(platform, organization?.partnerId)}
                size={121}
              />
              <Typography
                variant="subtitle2"
                color="text.secondary"
                textAlign="center"
              >
                {t(
                  `profilePage.devicePairing.pairMobileDeviceDialog.${platform}`
                )}
              </Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t('common.button.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default withDialogWrapper(PairMobileDeviceDialog);
