import { ChangeEvent, useMemo } from 'react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LoaderWithOverlay,
  PercentField,
  Radio,
  RadioGroup,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Cashback, CashbackType } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const MAX_CASHBACK_RATE = 3;
const YES = 'yes';
const NO = 'no';

interface FormValues {
  rate: number | undefined;
  hasValidDate: string;
  expirationDate: string;
}

interface Props extends DialogProps {
  cashbackType: CashbackType | null;
  currentRate: number;
  expirationDate: string | null;
  onSuccess: (cashback: Cashback) => void;
  onClose: () => void;
}

const SetCustomCashbackRateDialog = ({
  cashbackType: cashbackTypeNullable,
  currentRate,
  expirationDate,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const cashbackType = useMemo(() => cashbackTypeNullable!, []);
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      rate: +(currentRate * 100).toFixed(2),
      hasValidDate: expirationDate ? YES : NO,
      expirationDate: expirationDate
        ? moment(expirationDate).format('YYYY-MM-DD')
        : moment().add(1, 'd').format('YYYY-MM-DD'),
    },
    onSubmit: async ({ rate, expirationDate }, { setSubmitting }) => {
      try {
        const newRate = +(rate! / 100).toFixed(4);
        const cashback = await api.setCustomCashbackRate(organization!.id, {
          type: cashbackType,
          rate: newRate,
          expirationDate:
            formik.values.hasValidDate === YES ? expirationDate : null,
        });
        if (!mounted.current) return;
        onSuccess(cashback);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const onRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('hasValidDate', e.target.value, false);
  };

  const isMaxLimitExceededError =
    formik.values.rate !== undefined && formik.values.rate > MAX_CASHBACK_RATE;
  const isSubmitDisabled =
    formik.values.rate === undefined ||
    isMaxLimitExceededError ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.setCustomCashbackRateDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="set-custom-cashback-rate-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              <PercentField
                label={t(
                  `int.setCustomCashbackRateDialog.cashbackRateLabels.${cashbackType}`
                )}
                placeholder={t(
                  `int.setCustomCashbackRateDialog.cashbackRateLabels.${cashbackType}`
                )}
                autoFocus
                disabled={formik.isSubmitting}
                error={isMaxLimitExceededError}
                helperText={
                  isMaxLimitExceededError && (
                    <Trans
                      i18nKey="int.setCustomCashbackRateDialog.maxLimitExceededError"
                      components={{
                        rate: <FormatPercent value={MAX_CASHBACK_RATE} />,
                      }}
                    />
                  )
                }
                {...omit(formik.getFieldProps('rate'), 'onChange')}
                onValueChange={({ floatValue }) =>
                  formik.setFieldValue('rate', floatValue)
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Box mt={2}>
                <FormControl disabled={formik.isSubmitting} variant="standard">
                  <FormLabel>
                    {t('int.setCustomCashbackRateDialog.radioLabel')}
                  </FormLabel>
                  <RadioGroup
                    row
                    value={formik.values.hasValidDate}
                    onChange={onRadioChange}
                  >
                    <FormControlLabel
                      value={YES}
                      control={<Radio />}
                      label={t('common.yes')}
                    />
                    <FormControlLabel
                      value={NO}
                      control={<Radio />}
                      label={t('common.no')}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>

            {formik.values.hasValidDate === YES && (
              <Grid item xs={12}>
                <TextField
                  type="date"
                  label={t('int.setCustomCashbackRateDialog.dateFieldLabel')}
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('expirationDate')}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="set-custom-cashback-rate-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(SetCustomCashbackRateDialog);
