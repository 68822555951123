import { useTranslation } from 'react-i18next';
import { PageContent, PageHeader, PageHeadingLine } from 'components/Page';
import useHeaderBorderOnScroll from 'hooks/useBorderOnScroll';
import CrmInconsistenciesSection from './crmInconsistenciesSection';
import { Divider } from './style';

const CrmIntegrationPage = () => {
  const { t } = useTranslation();
  const { getHeaderStyle, onContentScroll } = useHeaderBorderOnScroll();

  return (
    <>
      <PageHeader style={getHeaderStyle()}>
        <PageHeadingLine
          heading={t('int.crmIntegrationPage.title')}
        ></PageHeadingLine>
      </PageHeader>
      <PageContent onScroll={onContentScroll}>
        <CrmInconsistenciesSection />

        <Divider />
      </PageContent>
    </>
  );
};

export default CrmIntegrationPage;
