import { styled, Theme } from '@mui/material';
import { Button, Drawer, IconButton, ListItemButton } from 'elements';

export const drawerWidth = 264;
const collapsedDrawerWidth = 24;

const openedTransitionMixin = (theme: Theme) =>
  theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  });

const closedTransitionMixin = (theme: Theme) =>
  theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  });

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: collapsedDrawerWidth,
  overflow: 'visible',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  transition: closedTransitionMixin(theme),
  '.MuiDrawer-paper': {
    width: collapsedDrawerWidth,
    overflow: 'visible',
    border: 'none',
    background: theme.variables.navigation[theme.navigationMode].background,
    transition: closedTransitionMixin(theme),
  },
  '&.drawer-open': {
    width: drawerWidth,
    transition: openedTransitionMixin(theme),
    '.MuiDrawer-paper': {
      width: drawerWidth,
      transition: openedTransitionMixin(theme),
    },
  },
  '&.drawer-hovered:not(.drawer-open) .MuiDrawer-paper': {
    width: drawerWidth,
    transition: openedTransitionMixin(theme),
  },
}));

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 29px;
  right: -14px;
  z-index: 1;
  padding: 3px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.background.default};
  transition: opacity, ${({ theme }) => theme.transitions.duration.short}ms;

  .drawer-open & {
    opacity: 0;
  }

  .drawer-open:hover & {
    opacity: 1;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.background.default};
    box-shadow: ${({ theme }) => theme.shadows[4]};
  }
`;

export const ContainerWrapper = styled('div')`
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity
    ${({ theme }) => theme.transitions.duration.leavingScreen}ms step-end;

  .drawer-open &,
  .drawer-hovered & {
    opacity: 1;
    transition: none;
  }
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: ${drawerWidth}px;
  height: 100%;
  color: ${({ theme }) =>
    theme.variables.navigation[theme.navigationMode].text.secondary};
`;

export const Header = styled('div')`
  flex-shrink: 0;
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;

export const Footer = styled('div')`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: ${({ theme }) => theme.spacing(2, 4, 4)};
`;

export const Content = styled('div')`
  overflow: auto;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(3, 2)};
`;

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(0.5, 2),
  marginBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius + 'px',
  '&:hover': {
    background: theme.variables.navigation[theme.navigationMode].hover,
  },
  '&.active': {
    background: theme.variables.navigation[theme.navigationMode].selected,
    '.MuiListItemIcon-root, .MuiTypography-root': {
      color:
        theme.variables.navigation[theme.navigationMode].text.selectedPrimary,
    },
  },
  '.MuiListItemIcon-root': {
    minWidth: 36,
    color: theme.variables.navigation[theme.navigationMode].text.secondary,
  },
  '.MuiTypography-root': {
    ...theme.variables.components['menu-item-dense'],
    color: theme.variables.navigation[theme.navigationMode].text.secondary,
  },
})) as typeof ListItemButton;

export const StyledSubListItemButton = styled(StyledListItemButton)(
  ({ theme }) => ({
    padding: theme.spacing(0.25, 2),
    marginBottom: theme.spacing(0.5),
    '.MuiTypography-root': {
      marginLeft: theme.spacing(4.5),
    },
  })
) as typeof ListItemButton;

export const SidebarMenuButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5),
  textAlign: 'left',
  justifyContent: 'space-between',
  border: '1px solid',
  borderColor: theme.variables.navigation[theme.navigationMode].selected,
  borderRadius: theme.shape.borderRadius + 'px',
  '&:hover': {
    background: theme.variables.navigation[theme.navigationMode].hover,
    borderColor: theme.variables.navigation[theme.navigationMode].selected,
  },
  '&.active': {
    background: theme.variables.navigation[theme.navigationMode].selected,
  },
  '.MuiButton-endIcon': {
    color: theme.variables.navigation[theme.navigationMode].text.secondary,
  },
  '.MuiTypography-root': {
    fontWeight: 600,
    color:
      theme.variables.navigation[theme.navigationMode].text.selectedPrimary,
  },
})) as typeof Button;
