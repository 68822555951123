import { useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  CaretDownIcon,
  FormControl,
  StatusBadge,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Partner, PartnerConfig, PartnerStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { visiblePartnerScopes } from './index';
import { PartnerData } from './useFilters';

interface Props {
  value: PartnerData['value'];
  partnerData: PartnerData;
  onChange: (value: PartnerData['value']) => void;
}

interface State {
  partners: Partner[];
  isLoading: boolean;
  error: boolean;
}

// This component should be refactored, once BE is done: IN-14969.
// We should pass partnerScope as param and receive filtered partners from BE.
const PartnerFilter = ({ value, partnerData, onChange }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const [state, setState] = useState<State>({
    partners: [],
    isLoading: false,
    error: false,
  });

  const onOpen = async () => {
    if (state.partners.length || state.isLoading) return;

    setState((prevState) => ({ ...prevState, isLoading: true, error: false }));
    try {
      const { partners } = await api.getPartners();
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        partners,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      logError(error);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const sortedPartners = useMemo(() => {
    const visiblePartners = state.partners.filter((item) =>
      visiblePartnerScopes.includes(item.partnerScope)
    );
    return sortBy(visiblePartners!, (v) => v.name.toLowerCase());
  }, [state.partners]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-config"
        id="panel-config-header"
      >
        <Typography>{t('int.filters.config')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Autocomplete<Partner | PartnerConfig, false, false, false>
            size="medium"
            loading={state.isLoading}
            onOpen={onOpen}
            value={value}
            onChange={(e, value) => onChange(value)}
            options={sortedPartners}
            isOptionEqualToValue={(option, value) =>
              option.partnerId === value.partnerId
            }
            getOptionLabel={(option) => option.name.toUpperCase()}
            renderOption={(props, option) => {
              if (!option) return '';
              return (
                <li {...props} key={option.partnerId as string}>
                  <Box
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    sx={{
                      color:
                        option.status === PartnerStatus.inactive
                          ? 'text.disabled'
                          : 'textPrimary',
                    }}
                  >
                    {option.name.toUpperCase()}
                    {option.status === PartnerStatus.inactive && (
                      <StatusBadge
                        label={t('common.badge.archived')}
                        color="default"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Box>
                </li>
              );
            }}
            error={partnerData.isError}
            placeholder={
              partnerData.isError
                ? t('filters.notFound')
                : t('int.filters.config')
            }
            noOptionsText={t('common.nothingFound')}
            loadingText={t('common.loading')}
            searchIcon={false}
          />
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default PartnerFilter;
