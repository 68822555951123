import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Button';
import { TextField } from 'components/Form';
import { useGlobalState } from 'context/GlobalState';
import { Box } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  OnboardingDocument,
  OnboardingDocumentStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

interface FormValues {
  answer: string | null;
  answerLocal: string | null;
}

interface Props {
  document: OnboardingDocument;
  onUpdate: () => Promise<void>;
}

const AnswerSection = ({ document, onUpdate }: Props) => {
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      answer: document.answer,
      answerLocal: document.answerLocal,
    },
    onSubmit: async (values) => {
      try {
        if (canUser('org-onboarding-questions:internal-view')) {
          await api.updateOnboardingDocument(
            document.organizationId,
            document.id,
            {
              attributes: document.attributes,
              customLabel: document.customLabel ?? '',
              customLabelLocal: document.customLabelLocal ?? '',
              description: document.description ?? '',
              descriptionLocal: document.descriptionLocal ?? '',
              answer: values.answer!,
              answerLocal: document.answerLocal ?? '',
            }
          );
        } else {
          await api.submitOnboardingAnswer(
            document.organizationId,
            document.id,
            {
              answerLocal: values.answerLocal!,
            }
          );
        }

        await onUpdate();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(t('errors.general'), { variant: 'error' });
        logError(error);
      }
    },
  });

  const isAnswerLocalEditable =
    document.status === OnboardingDocumentStatus.REQUESTED &&
    !canUser('org-onboarding-questions:internal-view');

  const showAnswer =
    [
      OnboardingDocumentStatus.ARCHIVED,
      OnboardingDocumentStatus.SUBMITTED,
    ].includes(document.status) &&
    canUser('org-onboarding-questions:internal-view');
  const isAnswerEditable =
    document.status === OnboardingDocumentStatus.SUBMITTED &&
    canUser('org-onboarding-questions:internal-view');

  const showSubmitButton = isAnswerLocalEditable || isAnswerEditable;

  const canBeSubmitted =
    (!!formik.values.answerLocal &&
      formik.values.answerLocal !== document.answerLocal) ||
    (!!formik.values.answer && formik.values.answer !== document.answer);

  if (
    document.status !== OnboardingDocumentStatus.REQUESTED &&
    !document.answerLocal
  ) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box mb="16px">
        <TextField
          shrink
          disabled={!isAnswerLocalEditable || formik.isSubmitting}
          label={
            canUser('org-onboarding-questions:internal-view')
              ? t('int.onboardingDocumentCard.answerLocal', {
                  country: organization!.country,
                })
              : t('onboardingDocumentRow.answer')
          }
          multiline
          {...formik.getFieldProps('answerLocal')}
        />
      </Box>

      {showAnswer && (
        <Box mb="16px">
          <TextField
            shrink
            disabled={!isAnswerEditable || formik.isSubmitting}
            label={t('int.onboardingDocumentCard.answer')}
            multiline
            {...formik.getFieldProps('answer')}
          />
        </Box>
      )}

      {showSubmitButton && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            disabled={!canBeSubmitted || formik.isSubmitting}
            onClick={formik.submitForm}
            variant="outlined"
          >
            {t('common.button.save')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AnswerSection;
