import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { isTransactionReadonly } from 'domains/transaction/utils';
import {
  Box,
  Button,
  IconButton,
  Paper,
  PencilSimpleIcon,
  TextField,
  Typography,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import { Transaction } from 'services/constants';
import { useCanUser } from 'services/rbac';

interface State {
  comment: string;
  commentError: string;
  transaction: Transaction;
}

interface Props {
  transaction: Transaction;
  isEditing: boolean;
  onEditChange: (isEditing: boolean) => void;
  onUpdate: (comment: string) => void;
}

const Comment = ({ transaction, isEditing, onEditChange, onUpdate }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { isAdminApp, isPortalAppMirrorView } = useCurrentApp();
  const {
    state: { featureModules, accountingSettings },
  } = useGlobalState();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [state, setState] = useState<State>({
    comment: transaction.comment || '',
    commentError: '',
    transaction,
  });

  const isCommentReadOnly =
    isTransactionReadonly(transaction, isAdminApp, isPortalAppMirrorView) ||
    !featureModules.ACCOUNTING_FEATURES;

  useEffect(() => {
    if (!transaction) return;
    if (transaction.transactionId !== state.transaction.transactionId) {
      setState(() => ({
        comment: transaction.comment || '',
        commentError: '',
        transaction,
      }));
    } else if (!isEditing) {
      setState((state) => ({ ...state, comment: transaction.comment || '' }));
    }
  }, [transaction, state.transaction, isEditing]);

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.focus();
      // Moving cursor to the end
      textareaRef.current.selectionStart = textareaRef.current.value.length;
      textareaRef.current.selectionEnd = textareaRef.current.value.length;
    }
  }, [isEditing]);

  const onSave = () => {
    const trimmedComment = state.comment.trim();
    if ((transaction.comment || '') === trimmedComment) {
      onEditChange(false);
      return;
    }

    const symbolCount = trimmedComment.replace(/\s/g, '').length;

    if (accountingSettings?.commentExportMandatory && symbolCount < 3) {
      setState((prevState) => ({
        ...prevState,
        commentError: t('transactionDetails.noteLengthError', { count: 3 }),
      }));
      return;
    }

    onUpdate(trimmedComment);
  };

  const isEditButtonShown =
    !isEditing && !isCommentReadOnly && canUser('transaction-comment:update');

  if (isEditing)
    return (
      <Box mt={2}>
        <TextField
          size="medium"
          multiline
          label={t('transactionDetails.note')}
          inputRef={textareaRef}
          inputProps={{
            maxLength: 200,
          }}
          rows={4}
          value={state.comment}
          placeholder={t('transactionDetails.writeComment')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { value: comment } = e.target;
            setState((state) => ({ ...state, comment, commentError: '' }));
          }}
          error={!!state.commentError}
          helperText={state.commentError}
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mt={1}
        >
          <Button
            onClick={() => {
              onEditChange(false);
              setState((state) => ({
                ...state,
                comment: state.transaction!.comment || '',
                commentError: '',
              }));
            }}
            variant="text"
          >
            {t('common.button.cancel')}
          </Button>
          <Button onClick={onSave} sx={{ ml: 1 }}>
            {t('common.button.save')}
          </Button>
        </Box>
      </Box>
    );

  return (
    <Paper
      sx={{
        px: 1.5,
        py: 2,
        mt: 1,
      }}
      variant="outlined"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="body2">
            {t('transactionDetails.note')}
          </Typography>
          {isCommentReadOnly && !transaction.comment ? (
            <Typography variant="caption">—</Typography>
          ) : (
            <Typography
              variant="caption"
              sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
              color={!state.comment ? 'textSecondary' : undefined}
            >
              {state.comment || t('transactionDetails.writeComment')}
            </Typography>
          )}
        </Box>

        {isEditButtonShown && (
          <IconButton
            sx={{ ml: 1 }}
            size="small"
            data-test-id="tx-edit-comment"
            onClick={() => {
              onEditChange(true);
            }}
          >
            <PencilSimpleIcon />
          </IconButton>
        )}
      </Box>
    </Paper>
  );
};

export default Comment;
