import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MerchantCategoryIcon } from 'domains/merchant/components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  merchantCategories,
  MerchantCategory,
  NetworkErrorCode,
  SUBCATEGORY,
  Subcategory,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
} from 'services/utils';

interface Props extends DialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (subcategory: Subcategory) => void;
}

const AddSubCategoryDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: '',
      number: '',
      status: AccountingItemStatus.active,
      category: '' as MerchantCategory,
      organizationId: organization!.id,
    },
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        const data = await api.addSubcategory(trimObjValues(values));
        dispatch({ type: 'ADD_SUBCATEGORIES', payload: [data] });
        if (!mounted.current) return;
        onSuccess(data);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.nonUniqueSubcategoryName
        ) {
          setFieldError('name', t('addSubCategoryDialog.nonUniqNameError'));
          return;
        }
        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.invalidSubcategoryNumber
        ) {
          setFieldError('number', t('addSubCategoryDialog.invalidNumberError'));
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const hasEmptyField = !formik.values.name.trim() || !formik.values.category;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('addSubCategoryDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="add-subcategory-form"
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('name')}
                label={t('addSubCategoryDialog.nameTitle')}
                inputProps={{
                  maxLength: SUBCATEGORY.nameMaxLength,
                }}
                disabled={formik.isSubmitting}
                onChange={(e) => {
                  formik.setFieldValue('name', e.target.value, true);
                }}
                error={!!formik.errors.name}
                helperText={formik.errors.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('number')}
                label={t('addSubCategoryDialog.numberTitle')}
                inputProps={{
                  maxLength: SUBCATEGORY.numberMaxLength,
                }}
                disabled={formik.isSubmitting}
                error={!!formik.errors.number}
                helperText={formik.errors.number}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="merchant-select-label">
                  {t('addSubCategoryDialog.categoryTitle')}
                </InputLabel>
                <Select
                  {...formik.getFieldProps('category')}
                  disabled={formik.isSubmitting}
                  labelId="merchant-select-label"
                  renderValue={(selected) => {
                    if (!selected) return '';
                    return t(`merchantCategories.${selected}`);
                  }}
                >
                  {merchantCategories.map((category) => (
                    <MenuItem key={category} value={category}>
                      <ListItemIcon>
                        <MerchantCategoryIcon category={category} />
                      </ListItemIcon>
                      {t(`merchantCategories.${category}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={hasEmptyField || formik.isSubmitting}
          form="add-subcategory-form"
          type="submit"
        >
          {t('addSubCategoryDialog.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(AddSubCategoryDialog);
