import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Box, LoaderWithOverlay, Paper, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  CardAccountList,
  CardAccountStatus,
  OrganizationAccountType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import CashbackSummary from './CashbackSummary';
import EmissionSummary from './EmissionSummary';
import MultiAccountsMultiCurrenciesContent from './MultiAccountsMultiCurrenciesContent';
import MultiAccountsSingleCurrencyContent from './MultiAccountsSingleCurrencyContent';
import SingleCreditAccountContent from './SingleCreditAccountContent';
import SinglePrefundedAccountContent from './SinglePrefundedAccountContent';

interface State {
  data: CardAccountList | null;
  isLoading: boolean;
}

const FinancialOverview = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    dispatch,
    state: { defaultCardAccount, featureModules, organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    data: null,
    isLoading: true,
  });

  const getData = async () => {
    try {
      const data = await api.getCardAccounts(organization!.id);
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          cardAccounts: data.cardAccounts,
          defaultCardAccount: data.cardAccounts.find(
            (item) => item.defaultAccount.value
          )!,
        },
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        data: {
          ...data,
          cardAccounts: data.cardAccounts.filter(
            (item) => !item.benefitCardAccount.value
          ),
        },
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const renderFooter = () => {
    if (featureModules.CASHBACK && featureModules.PLIANT_EARTH) {
      return (
        <Box display="flex" mx={-2} mb={-2}>
          <CashbackSummary size="sm" />
          <EmissionSummary />
        </Box>
      );
    }

    if (featureModules.CASHBACK) {
      return (
        <Box mx={-2} mb={-2}>
          <CashbackSummary size="lg" />
        </Box>
      );
    }

    return null;
  };

  const renderContent = (data: CardAccountList) => {
    const activeCardAccounts = data.cardAccounts.filter(
      (item) => item.status.value === CardAccountStatus.active
    );

    if (activeCardAccounts.length > 1) {
      return featureModules.MULTI_CURRENCY_BILLING ? (
        <MultiAccountsMultiCurrenciesContent cardAccountList={data} />
      ) : (
        <MultiAccountsSingleCurrencyContent
          activeCardAccounts={activeCardAccounts}
        />
      );
    }

    if (
      defaultCardAccount!.accountType.value ===
      OrganizationAccountType.prefunded
    )
      return <SinglePrefundedAccountContent />;

    return <SingleCreditAccountContent />;
  };

  return (
    <Paper
      data-intercom-target="financial-overview-widget"
      variant="outlined"
      sx={{ height: '100%', p: 2 }}
    >
      {!state.data && (
        <Typography variant="subtitle1" flexGrow={1} py="1px" mb={2}>
          {t('dashboardPage.availableFundsAndLimit')}
        </Typography>
      )}

      {state.data && renderContent(state.data)}

      {!state.isLoading && !state.data && (
        <Box minHeight={60} mb={2}>
          <Typography variant="caption" color="error.main">
            {t('errors.loadData')}
          </Typography>
        </Box>
      )}

      {state.isLoading && (
        <Box position="relative" minHeight={60} mb={2}>
          <LoaderWithOverlay loading size="small" />
        </Box>
      )}

      {renderFooter()}
    </Paper>
  );
};

export default FinancialOverview;
