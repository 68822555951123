import { ComponentType, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLinkProps } from 'react-router-dom';
import PartnerMinorLogo from 'components/PartnerMinorLogo';
import { useGlobalState } from 'context/GlobalState';
import { isPartnerBasedSource } from 'domains/partner/utils';
import {
  useGetTransactionsFlaggedCounter,
  useGetTransactionsNeedsReviewCounter,
} from 'domains/transaction/hooks';
import {
  ArrowsLeftRightIcon,
  Badge,
  BankIcon,
  createSvgIcon,
  ExportIcon,
  FilesIcon,
  GlobeHemisphereWestIcon,
  SquaresFourIcon,
  StarIcon,
  StorefrontIcon,
  SvgIconProps,
  UsersIcon,
  VerticalCardsIcon,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import { CirculaConnectionType } from 'services/constants';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';

export interface Tab {
  label: ReactNode;
  pathId: string;
  testId: string;
  intercomTarget?: string;
  Icon: ComponentType<SvgIconProps>;
  children?: ReactNode;
  isActive?: NavLinkProps['isActive'];
  items?: Omit<Tab, 'Icon' | 'items'>[];
}

const useTabs = (): Tab[] => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: {
      organization,
      organizationIntegrations,
      featureModules,
      partnerConfig,
    },
  } = useGlobalState();
  const { isExternalApp } = useCurrentApp();
  const { partnerDealsEnabled, organizationMerchantsPageEnabled } = useFlags();
  const transactionsNeedsReviewCount = useGetTransactionsNeedsReviewCounter(
    true
  );
  const transactionsFlaggedCount = useGetTransactionsFlaggedCounter();

  const isCirculaFullSyncEnabled =
    isExternalApp &&
    organizationIntegrations?.syncSettings?.orgConnectionType ===
      CirculaConnectionType.full;
  const isEmbeddedPartnerPageUsed = !featureModules.INTEGRATIONS_PAGE;
  const showCashback = featureModules.CASHBACK;
  const hideExportPage =
    !featureModules.ACCOUNTING_FEATURES || isCirculaFullSyncEnabled;

  const orgHasSourcePartner =
    !!organization && isPartnerBasedSource(organization.partnerId);

  const showPartnerPage =
    canUser('partners:view') &&
    orgHasSourcePartner &&
    isEmbeddedPartnerPageUsed;

  const getMembersTab = (): Tab => {
    if (featureModules.TEAMS && canUser('teams-page:visit'))
      return {
        label: t('navigation.membersAndTeams'),
        pathId: 'members',
        testId: 'members-tab',
        intercomTarget: 'sidebar-members-button',
        Icon: UsersIcon,
        items: [
          {
            label: t('navigation.members'),
            pathId: 'members',
            testId: 'members-sub-tab',
          },
          {
            label: t('navigation.teams'),
            pathId: 'teams',
            testId: 'teams-sub-tab',
            intercomTarget: 'teams-tab',
          },
        ],
      };

    return {
      label: t('navigation.members'),
      pathId: 'members',
      testId: 'members-tab',
      intercomTarget: 'sidebar-members-button',
      Icon: UsersIcon,
    };
  };

  const getBillingTab = (): Tab => {
    const tab: Tab = {
      label: t('navigation.billing'),
      pathId: 'billingPayments',
      testId: 'billing-tab',
      intercomTarget: 'billing-tab',
      Icon: FilesIcon,
      items: [
        {
          label: t('navigation.billingTab.payments'),
          pathId: 'billingPayments',
          testId: 'billing-payments-sub-tab',
          intercomTarget: 'billing-payments-button',
        },
        {
          label: t('navigation.billingTab.statements'),
          pathId: 'billingStatements',
          testId: 'billing-statements-sub-tab',
          intercomTarget: 'billing-statements-button',
        },
      ],
    };
    if (
      !featureModules.MULTI_CARD_ACCOUNT &&
      !featureModules.MULTI_CURRENCY_BILLING
    ) {
      tab.items!.push({
        label: t('navigation.billingTab.accounting'),
        pathId: 'accounting',
        testId: 'accounting-sub-tab',
        intercomTarget: 'accounting-button',
      });
    }

    return tab;
  };

  const getExportTransactionsTabs = (): Tab[] => {
    if (hideExportPage) return [];

    return [
      {
        label: t('navigation.export'),
        pathId: 'export',
        testId: 'export-tab',
        intercomTarget: 'sidebar-export-button',
        Icon: ExportIcon,
        items: [
          {
            label: t('navigation.exportTab.notExported'),
            pathId: 'export',
            testId: 'not-exported-sub-tab',
            intercomTarget: 'not-exported-button',
          },
          {
            label: t('navigation.exportTab.exportQueue'),
            pathId: 'exportQueue',
            testId: 'export-queue-sub-tab',
            intercomTarget: 'export-queue-button',
          },
          {
            label: t('navigation.exportTab.exported'),
            pathId: 'exported',
            testId: 'exported-sub-tab',
            intercomTarget: 'exported-button',
          },
        ],
      },
    ];
  };

  const getTransactionsItems = (): Tab['items'] => {
    const items: Tab['items'] = [
      {
        label: t('navigation.transactionTab.all'),
        pathId: 'transactions',
        testId: 'all-transactions-sub-tab',
        intercomTarget: 'all-transactions-button',
      },
    ];

    if (
      canUser('transaction-review:view') &&
      featureModules.MANAGER_TX_REVIEWS
    ) {
      items.push({
        label: t('navigation.transactionTab.review'),
        children: (
          <Badge
            color="secondary"
            badgeContent={transactionsNeedsReviewCount}
            max={99}
            sx={{ right: transactionsNeedsReviewCount > 99 ? 4 : undefined }}
          />
        ),
        pathId: 'transactionsReview',
        testId: 'needs-review-transactions-sub-tab',
        intercomTarget: 'review-transactions-button',
      });
    }
    if (
      canUser('transaction-review:view') &&
      (featureModules.MANAGER_TX_REVIEWS || featureModules.PRIVATE_EXPENSE)
    ) {
      items.push({
        label: t('navigation.transactionTab.flagged'),
        children: (
          <Badge
            color="secondary"
            badgeContent={transactionsFlaggedCount}
            max={99}
            sx={{ right: transactionsFlaggedCount > 99 ? 4 : undefined }}
          />
        ),
        pathId: 'transactionsFlagged',
        testId: 'flagged-transactions-sub-tab',
        intercomTarget: 'flagged-transactions-button',
      });
    }

    return items.length === 1 ? undefined : items;
  };

  return [
    {
      label: t('navigation.dashboard'),
      pathId: 'dashboard',
      testId: 'dashboard-tab',
      intercomTarget: 'sidebar-dashboard-button',
      Icon: SquaresFourIcon,
    },
    getMembersTab(),
    ...(featureModules.MULTI_CARD_ACCOUNT ||
    featureModules.MULTI_CURRENCY_BILLING
      ? [
          {
            label: t('navigation.cardAccounts'),
            pathId: 'cardAccounts',
            testId: 'card-accounts-tab',
            Icon: BankIcon,
          },
        ]
      : []),
    {
      label: t('navigation.cards'),
      pathId: 'cards',
      testId: 'cards-tab',
      Icon: VerticalCardsIcon,
    },
    {
      label: t('navigation.transactions'),
      pathId: 'transactions',
      testId: 'transactions-tab',
      intercomTarget: 'transactions-tab',
      Icon: ArrowsLeftRightIcon,
      items: getTransactionsItems(),
    },
    ...getExportTransactionsTabs(),
    getBillingTab(),
    ...(canUser('rewards-page:visit') && (showCashback || partnerDealsEnabled)
      ? [
          {
            label: t('navigation.rewards'),
            pathId: 'rewards',
            testId: 'rewards-tab',
            Icon: StarIcon,
          },
        ]
      : []),
    ...(organizationMerchantsPageEnabled
      ? [
          {
            label: t('navigation.merchants'),
            pathId: 'merchants',
            testId: 'merchants-tab',
            Icon: StorefrontIcon,
          },
        ]
      : []),
    ...(featureModules.PLIANT_EARTH
      ? [
          {
            label: t('navigation.pliantEarth'),
            pathId: 'pliantEarth',
            testId: 'pliantEarth-tab',
            Icon: GlobeHemisphereWestIcon,
          },
        ]
      : []),
    ...(showPartnerPage
      ? [
          {
            label: partnerConfig?.displayName || organization!.partnerName,
            pathId: 'partner',
            testId: 'partner-tab',
            Icon: createSvgIcon(
              <PartnerMinorLogo partnerId={organization!.partnerId!} />
            ),
          },
        ]
      : []),
  ];
};

export default useTabs;
