import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useGlobalState } from 'context/GlobalState';
import ConnectionBlock from 'domains/settings/pages/AccountingPage/CodatSubPage/ConnectionBlock';
import { LoaderWithOverlay } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ApiIntegrationStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import SyncOptionsGroup from './SyncOptionsGroup';

const CodatConnection = () => {
  const history = useHistory();
  const { refresh } = Object.fromEntries(
    new URLSearchParams(history.location.search).entries()
  );
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, accountingSettings },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);

  const isCodatConnected =
    accountingSettings!.apiIntegrationStatus === ApiIntegrationStatus.connected;

  const getData = async () => {
    try {
      setIsLoading(true);
      history.replace({
        search: '',
      });

      const codatData = await api.getCodatDataRefreshed(organization!.id);

      const isStatusConnected = !codatData.dataConnections.find(
        (item) => item.status !== 'Linked'
      );
      if (isStatusConnected) {
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: {
            accountingSettings: {
              ...accountingSettings!,
              apiIntegrationStatus: ApiIntegrationStatus.connected,
            },
          },
        });
      }

      if (!mounted.current) return;
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsLoading(false);
      logError(error);
    }
  };

  useEffect(() => {
    if (refresh && canUser('codat-settings:change', accountingSettings))
      getData();
  }, [refresh]);

  const onConnectionChange = async () => {
    try {
      setIsLoading(true);
      // disconnection flow
      if (
        accountingSettings!.apiIntegrationStatus ===
        ApiIntegrationStatus.connected
      ) {
        await api.disconnectCodat(organization!.id);
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: {
            accountingSettings: {
              ...accountingSettings!,
              apiIntegrationStatus: ApiIntegrationStatus.notConnected,
            },
          },
        });

        if (!mounted.current) return;
        setIsLoading(false);
        return;
      }
      // connection flow
      const { dataConnections } = await api.connectCodat(organization!.id, {
        accountingSystem: accountingSettings!.accountingSystem!,
      });

      if (!mounted.current) return;
      // source type Expense is created on partner side and
      // should be ignored on FE
      const linkUrl = dataConnections.find(
        (item) => item.sourceType !== 'Expense'
      )?.linkUrl;

      if (!linkUrl) throw new Error('No link found');
      window.location.href = `${linkUrl}?params=refresh=true&base_url=${window.location.origin}`;
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsLoading(false);
      logError(error);
    }
  };

  return (
    <>
      <ConnectionBlock onConnectionChange={onConnectionChange} />

      {isCodatConnected && canUser('codat-sync:view') && <SyncOptionsGroup />}

      <LoaderWithOverlay loading={isLoading} />
    </>
  );
};

export default CodatConnection;
